import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogTitle from '../../core/DialogTitle';
import ResponsiveButton from '../../core/ResponsiveButton';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const NewReactComponent = (props) => {

//////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={props.open}
        >
            <DialogTitle
                onClose={props.onContinue}
            >
                {"You are no longer assigned to this loading job"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'Your current assignment to this loading job has timed out, or has been removed. Do you want to continue working on this loading job?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={props.onLeave}
                >
                    Leave
                </Button>
                <ResponsiveButton
                    color='primary'
                    loading={props.dbUpdateInProgress}
                    onClick={props.onContinue}
                >
                    Continue
                </ResponsiveButton>
            </DialogActions>
        </Dialog>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
});


export default withStyles(styles)(NewReactComponent);
