import React, { useEffect } from 'react'

import { signOutAndRedirect } from '../api'

/**
 * Component that only purpose is to sign the user out
 * @returns
 */
const SignOut = (props) => {
    useEffect(() => {
        signOutAndRedirect()
    }, [])

    return <div />
}

export default SignOut
