import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import ResponsiveButton from '../../core/ResponsiveButton';
import { solutionPackingStatus, lmcStatus } from '../Constants';

import { withStyles } from '@material-ui/core/styles';


// Initialize the different states actionButtonContainer can be in
const actionButtonContainerStates = {
    none: 0,
    loadingPlanFinished: 1,
    loadingPlanNotSelected: 2,
    parcelSelectedForPacking: 3,
    unknown: 4
}

//////////////////////////////////////////////////////////////////////////////

const NewReactComponent = (props) => {

    const { classes } = props;

    // State that controls which action buttons are shown at any time
    const [actionButtonContainerState, setActionButtonContainerState] = useState(actionButtonContainerStates.none);

    /**
     * When ever given props values change, change actionButtonContainerState accordingly.
     */
    useEffect(() => {

        // In these cases, we don't want to show any action buttons
        if (
            props.cargoSpace == null ||
            props.loadingPlanBeingReOptimized ||
            props.nextPackableBoxCardsVisible ||
            props.editModeState
        ) {
            setActionButtonContainerState(actionButtonContainerStates.none);
            return;
        };

        // If loading plan has finished, show only the 'Close'-button
        if (props.loadingPlanFinished) {
            setActionButtonContainerState(actionButtonContainerStates.loadingPlanFinished);
            return;
        };

        // If user hasn't entered loading job yet, show controls on the preview of the loading plan.
        if (!props.loadingPlanSelected) {
            setActionButtonContainerState(actionButtonContainerStates.loadingPlanNotSelected);
            return;
        };

        // If user has selected a parcel for packing, show confirmation controls
        if (Boolean(props.parcelSelectedForPacking)) {
            setActionButtonContainerState(actionButtonContainerStates.parcelSelectedForPacking);
            return;
        };

        // In a case where actionButtonContainer ends up in a state that is not recognized, log an error to console
        console.error('ActionButtonContainer has ended up in an unhandled state.');
        setActionButtonContainerState(actionButtonContainerStates.unknown);
        return;

    }, [
        props.cargoSpace,
        props.loadingPlanBeingReOptimized,
        props.nextPackableBoxCardsVisible,
        props.editModeState,
        props.loadingPlanFinished,
        props.loadingPlanSelected,
        props.parcelSelectedForPacking,
    ]);

//////////////////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            {/* Don't show components */}
            {
                (
                    actionButtonContainerState === actionButtonContainerStates.none ||
                    actionButtonContainerState === actionButtonContainerStates.unknown
                ) &&
                <></>
            }

            {/* Loading plan has been completed. Show close-button only. */}
            { actionButtonContainerState === actionButtonContainerStates.loadingPlanFinished &&
                <Button
                    className={`${classes.xlButton} ${classes.btnRight}`}
                    size='large'
                    variant='contained'
                    color='primary'
                    onClick={props.onClose}
                >
                    Close
                </Button>
            }

            {/* Loading job hasn't been started yet, show controls to either join/start job, or cancel */}
            { actionButtonContainerState === actionButtonContainerStates.loadingPlanNotSelected &&
                <React.Fragment>
                    <Button
                        className={`${classes.xlButton} ${classes.btnLeft}`}
                        size='large'
                        variant='contained'
                        onClick={props.onCancelJoiningLoadingJob}
                    >
                        Cancel
                    </Button>
                    <ResponsiveButton
                        className={`${classes.xlButton} ${classes.btnRight}`}
                        size='large'
                        disabled={props.cargoSpace.packingStatus === solutionPackingStatus.packed}
                        variant='contained'
                        color='primary'
                        loading={props.dbUpdateInProgress}
                        onClick={props.onStartLoadingJob}
                    >
                        {props.cargoSpace.packingStatus === solutionPackingStatus.readyToPack ? 'Start' : 'Continue'}
                    </ResponsiveButton>
                </React.Fragment>
            }

            {/* User has selected a parcel for packing, show confirmation-controls */}
            { actionButtonContainerState === actionButtonContainerStates.parcelSelectedForPacking &&
                <React.Fragment>
                    <Button
                        className={`${classes.xlButton} ${classes.btnLeft}`}
                        size='large'
                        variant='contained'
                        onClick={props.onCancelParcelLoading}
                    >
                        Cancel
                    </Button>
                    <ResponsiveButton
                        loading={props.dbUpdateInProgress}
                        className={`${classes.xlButton} ${classes.btnRight}`}
                        disabled={
                            props.cargoSpace.lastMinuteChange?.status > lmcStatus.none ||
                            props.cargoSpace.packingStatus === solutionPackingStatus.packed
                        }
                        size='large'
                        variant='contained'
                        color='primary'
                        onClick={props.onConfirmParcelPacking}
                    >
                        Confirm
                    </ResponsiveButton>
                </React.Fragment>
            }

        </React.Fragment>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    btnRight: {
        position: "absolute",
        width: "120px",
        bottom: "5%",
        right: "3%"
    },
    btnLeft: {
        position: "absolute",
        width: "120px",
        bottom: "5%",
        left: "3%"
    },
    xlButton: theme.xlButton,
});


export default withStyles(styles)(NewReactComponent);
