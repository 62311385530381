import * as THREE from 'three';

const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

//TODO: Constructor parameters here should not be optional but instead required. Something should be done about this!
//TODO: Why are we not creating material inside here?
export default (pos, dim, material, parcel, parcelColor) => {
    if (!parcel || !isObject(parcel) || !parcel.id || !parcel.id || !parcel.packedDimensions)
        throw new Error('Expecting a parcel object here.');
    if (parcel.packedDimensions.r > 0)
        throw new Error('Not expecting a cylinder here.');

    let box = new THREE.BoxBufferGeometry(dim.x, dim.y, dim.z);
    let mesh = new THREE.Mesh(box, material);

    mesh.position.x = pos.x;
    mesh.position.y = pos.y;
    mesh.position.z = pos.z;

    mesh.parcel = parcel;
    mesh.parcelId = parcel.id; // TODO We don't need both parcelId and parcel properties

    //This is used when the parcel color is required outside the Threer (e.g. step-by-step PDF export)
    //TODO: Should this also be required?
    mesh.parcelColor = parcelColor;

    function getMesh() {
        return mesh;
    }

    return {
        getMesh,
    };
}
