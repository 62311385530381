import React, { useEffect, useState, useRef } from 'react'

import Octagon from '../core/Octagon'
import Toolbar from '../core/Toolbar'
import OrganizationFilterMenu from '../core/OrganizationFilterMenu'
import { notificationDialogSeverity } from '../core/Constants'

import SolutionsTableWrapper from './SolutionsTable/SolutionsTableWrapper'

import { colors } from '../theme'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'

import { setSolutions } from './SolutionActions'
import { setOrganizations } from '../admin/AdminActions'
import { setUnits } from '../core/Units/UnitActions'
import { showNotificationDialog } from '../app/AppActions'
import { setSavedSolution } from '../optimize/OptimizeActions'
import { connect } from 'react-redux'

import { solutions as solutionsService, organizations } from '../api'
import { browserHistory } from 'react-router'



//////////////////////////////////////////////////////////////////////////////


const Solutions = (props) => {

    const {
        classes,
        user,
        onFetchOrganizations,
        onFetchSolutions,
        onNavigate,
        onViewLoadingPlan,
        onSolutionsDelete,
        organizations,
        solutions,
    } = props;

    const anchorRef = useRef(null)

    const [orgFilterOpen, setOrgFilterOpen] = useState(false)
    const [orgFilterList, setOrgFilterList] = useState([]);

    const [searchTerm, setSearchTerm] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);



    /**
     * On the initial render, set up event listeners for events that happen in solutionsService.
     */
    useEffect(() => {
        setSolutionEventListeners();
        return () => {
            clearSolutionEventListeners();
        }
    }, [])


    /**
     * When user enters the loading plans-page, fetch all organizations for the organizations-filter.
     * This is only done for superadmins. Other users cannot see all organizations, and they don't
     * have access to the organization filter.
     */
    useEffect(() => {
        if (user.roles.includes('superadmin')) {
            onFetchOrganizations(user)
        } else {
            return;
        }
    }, [user, onFetchOrganizations])



    // Intialize solutionsService listeners
    const setSolutionEventListeners = () => {
        solutionsService.service().on('patched', onSolutionsPatched.current);
        solutionsService.service().on('removed', onSolutionRemoved.current);
        solutionsService.service().on('created', onSolutionCreated.current);
    };


    // Clear solutionsService listeners
    const clearSolutionEventListeners = () => {
        solutionsService.service().removeListener("patched", onSolutionsPatched.current);
        solutionsService.service().removeListener('removed', onSolutionRemoved.current);
        solutionsService.service().removeListener('created', onSolutionCreated.current);
    }


    /**
     * When a patch-event is heard in solutions-collection, fetch all solutions.
     * This keeps the information in solutions-table up-to-date.
     */
    const onSolutionsPatched = useRef(async function (patchedSolution) {

        if (patchedSolution == null) {
            return;
        };

        onFetchSolutions();
    });


    /**
     * When a solution is removed, fetch all solutions again
     */
    const onSolutionRemoved = useRef(async function () {
        onFetchSolutions();
    })


    /**
     * When a new solution is created, fetch all solutions again
     */
    const onSolutionCreated = useRef(async function () {
        onFetchSolutions();
    })



    /**
     * Event handler for the searchbar InputBase.
     * Use timeout to make sure that the searchTerm is set only after user stops typing
     * @param {String} e is the InputBase input
     */
    const handleSearch = (e) => {
        clearTimeout(searchTimeout);
        setSearchTimeout(
            setTimeout(() => {
                setSearchTerm(e);
            }, 600)
        );
    };


//////////////////////////////////////////////////////////////////////////////


    return (
        <Octagon w='90rem' color={colors.octagon}>
            <Toolbar
                title='Loading Plans'
            >
                {/* "Create new loading plan" -button */}
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => onNavigate('/optimizer')}
                >
                    create new loading plan
                </Button>

                {/* "Filter organizations" -button */}
                <Button
                    style={{
                        display: user.roles.includes('superadmin') ? '' : 'none'
                    }}
                    startIcon={<FilterListIcon />}
                    onClick={() => setOrgFilterOpen(!orgFilterOpen)}
                    ref={anchorRef}
                    aria-controls={orgFilterOpen ? 'menu-list' : undefined}
                    aria-haspopup='true'
                >
                    organizations
                </Button>
                <OrganizationFilterMenu
                    open={orgFilterOpen}
                    setOpen={setOrgFilterOpen}
                    anchorRef={anchorRef}
                    organizations={organizations}
                    setOrgFilterList={setOrgFilterList}
                />

                {/* Divider between buttons and search bar */}
                <Divider
                    orientation='vertical'
                    variant='middle'
                    style={{ marginRight: 50 }}
                />

                {/* Search bar */}
                <Box className={classes.searchContainer}>
                    <TextField
                        placeholder='Search by name'
                        onChange={(event) => handleSearch(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon style={{ opacity: 0.54 }}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
            </Toolbar>

            <SolutionsTableWrapper
                solutions={solutions}
                onGetSolutions={query => onFetchSolutions(query)}
                onSolutionsDelete={solutions => onSolutionsDelete(solutions)}
                onRowSelect={(sol) => onViewLoadingPlan(sol)}
                isSuperAdmin={user.roles.includes('superadmin')}
                orgFilterList={orgFilterList}
                searchTerm={searchTerm}
            />
        </Octagon>
    )
}


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
    return {
        user: state.user,
        organizations: state.admin.organizations.data,
        solutions: state.solutions,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSolutions: query => {
            return solutionsService.list(query)
                .then(res => {
                    dispatch(setSolutions(res))
                    return res
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onSolutionsDelete: (solutions) => {
            const solutionIds = solutions.map(sol => sol._id);
            return solutionsService.removeMany(solutionIds)
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onFetchOrganizations: (user) => {
            if (!user || !user.roles.includes("superadmin")) return
            organizations.query({})
                .then(result => {
                    dispatch(setOrganizations(result))
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onNavigate: (pathname) => {
            browserHistory.push(pathname)
        },
        onViewLoadingPlan: (solution) => {
            dispatch(setSavedSolution(solution))
            dispatch(setUnits(solution.viewModel.units))
            browserHistory.push({
                pathname: `/optimizer/${solution._id}`,
                state: { preventReset: true }
            })
        }
    }
}


const styles = theme => ({
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    searchicon: {
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Solutions))
