import { connect } from 'react-redux'

/**
* Shows or hides child components based on organization's enabled features
*/
const FeatureEnabler = (props) => {
    const { featureId, children, user } = props;
    const show = user?.organization?.enabledFeatures.includes(featureId);
    return show ? children : null;
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(FeatureEnabler);
