import moment from 'moment';

// Create a new object every time to NOT alter the original object
const emptyData = () => ({
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
})

export const defaultOrganization = () => ({
    name: '',
    officeUserCountLimit: 0,
    loadingOperationsUserCountLimit: 0,
    allowedRoles: ['officeUser'],
    enabledFeatures: [],
    allowSuperadmin: false,
    allowGoogleLogin: false,
    // Trial information
    isTrial: false,
    trialExpires: moment().add(14, 'days').endOf('day')
})

export const defaultUser = () => ({
    email: '',
    password: '',
    passwordConfirmation: null,
    sendEmail: true,
    manualSet: false,
    roles: ['officeUser'],
    name: '',
    organization: {},
    tabletMode: false
})

const adminState = {
    deleteDialogOpen: false,
    backDialogOpen: false,
    editUser: defaultUser(),
    users: emptyData(),
    editOrganization: defaultOrganization(),
    organizations: emptyData(),
    organizationUsers: emptyData(),
    editCargoPreset: {},
    cargoPresets: emptyData(),
    assignments: emptyData()
}

export default (state = adminState, action) => {
    switch (action.type) {
        case 'OPEN_DELETE_DIALOG':
            return Object.assign({}, state, { deleteDialogOpen: true })
        case 'CLOSE_DELETE_DIALOG':
            return Object.assign({}, state, { deleteDialogOpen: false })
        case 'OPEN_BACK_DIALOG':
            return Object.assign({}, state, { backDialogOpen: true })
        case 'CLOSE_BACK_DIALOG':
            return Object.assign({}, state, { backDialogOpen: false })
        case 'SET_EDIT_USER':
            if (action.user && action.user.google) {
                return Object.assign({}, state, {
                    editUser: Object.assign({
                        _id: action.user._id,
                        oauth: true,
                        email: action.user.email,
                        roles: action.user.roles,
                        name: action.user.google.displayName,
                        organization: action.user.organization
                    })
                })
            } else {
                return Object.assign({}, state, { editUser: Object.assign({}, action.user) })
            }
        case 'SET_USERS':
            return Object.assign({}, state, { users: action.users || emptyData() })
        case 'SET_ORGANIZATIONS':
            return Object.assign({}, state, { organizations: action.organizations || emptyData() })
        case 'SET_EDIT_ORGANIZATION':
            // Make sure the trialExpires is moment type
            if (action?.organization?.trialExpires)
                action.organization.trialExpires = moment(action.organization.trialExpires);

            return Object.assign({}, state, { editOrganization: Object.assign({}, action.organization || {}) })
        case 'SET_EDIT_CARGOPRESET':
            return Object.assign({}, state, { editCargoPreset: action.preset || {} })
        case 'SET_CARGOPRESETS':
            return Object.assign({}, state, { cargoPresets: action.presets || emptyData() })
        case 'SET_ORGANIZATION_USERS':
            return Object.assign({}, state, { organizationUsers: action.users || emptyData() })
    case 'SET_ASSIGNMENTS':
      return Object.assign({}, state, { assignments: action.assignments || emptyData()})
        default:
            return state
    }
}
