import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';

import GeneralDataTable from '../core/GeneralDataTable/GeneralDataTable';
import Octagon from '../core/Octagon';
import Toolbar from '../core/Toolbar';

import { colors } from '../theme';
import { emailConfirmation } from '../api';
import { showNotificationDialog } from '../app/AppActions';
import { notificationDialogSeverity } from '../core/Constants';

import { startCase } from 'lodash';
import moment from 'moment';

//////////////////////////////////////////////////////////////////////////////


const SuperamdinLogsEmailConfirmations = (props) => {
    const { reduxDispatch, classes } = props;


    // State for the email-confirmation data
    const [confirmations, setConfirmations] = useState([]);

    // State-object for table pagination
    const [paginationState, setPaginationState] = useState({
        rowsPerPage: 25,
        page: 0,
    });

    // State-object for table column sorting
    const [columnSortingOptions, setColumnSortingOptions] = useState({
        direction: 'desc',
        column: 'createdAt'
    })


    /**
     * Fetch data using paginationState information
     */
    useEffect(() => {
        const order = columnSortingOptions.direction === 'desc' ? -1 : 0 ;
        const orderObj = {}
        orderObj[columnSortingOptions.column] = order;

        emailConfirmation.find({ $sort: orderObj, $limit: paginationState.rowsPerPage, $skip: paginationState.rowsPerPage * paginationState.page })
            .then(res => {
                setConfirmations(res);
            })
            .catch((err) => {
                reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error)
            })
    }, [
        paginationState.rowsPerPage,
        paginationState.page,
        columnSortingOptions.direction,
        columnSortingOptions.column,
        reduxDispatch
    ])


    // Table columns
    const columns = [
        { label: 'Email', field: 'email', sortable: true },
        { label: 'Organization', field: 'organization.name' }, //Sorting would require aggregation involving subdocuments
        { label: 'Created', field: 'createdAt', conversion: date => moment(date).format('DD.MM.YYYY HH:mm:ss'), sortable: true },
        { label: 'Email expiration', field: 'expiresAt', conversion: date => moment(date).format('DD.MM.YYYY HH:mm:ss'), sortable: true },
        { label: 'Trial expiration', field: 'organization.trialExpires', conversion: date => moment(date).format('DD.MM.YYYY HH:mm:ss') }, //Sorting would require aggregation involving subdocuments
        { label: 'Used', field: 'used', conversion: value => startCase(value.toString()), sortable: true }
    ];


//////////////////////////////////////////////////////////////////////////////


    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title='Email confirmations' onGoBack={() => browserHistory.push('/admin/logs')} />
            <Paper className={classes.table} elevation={4}>
                <GeneralDataTable
                    columns={columns}
                    data={confirmations}
                    dense
                    columnSortingOptions={columnSortingOptions}
                    onChangeColumnSortingOptions={(newOptions) => setColumnSortingOptions(newOptions)}
                    paginationState={paginationState}
                    onChangePagination={(state) => setPaginationState(state)}
                />
            </Paper>
        </Octagon >
    )
}


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => ({
    reduxState: {}
});


const mapDispatchToProps = (dispatch) => ({
    reduxDispatch: {
        showMessage: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity))
        }
    }
})


const styles = theme => ({
    table: theme.containers.dataTable
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuperamdinLogsEmailConfirmations))
