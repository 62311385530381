import { loadingDirections } from '../core/Constants'
import { arrowDirections } from './Arrow';

const cameraDirections = {
    undefined: 0,
    default: 1,
    ySide: 2,
    zSide: 3
}

const zeroDim = { x: 0, y: 0, z: 0 }

export default (options) => {
    let ANIMATION_STEPS = 5;
    let { origin, loadingDirection } = options;
    let cameraTarget, controlTarget, defaultCameraView;

    function clearTargets() {
        cameraTarget = null;
        controlTarget = null;
        ANIMATION_STEPS = 5;
    }

    function setDefaultCameraView(position) {
        defaultCameraView = {
            x: position.x,
            y: position.y,
            z: position.z,
        }
    }

    function handleCameraDirectionFocus(pos, dim, containerInfo) {
        if (loadingDirection === loadingDirections.side) {
            cameraTarget = {
                x: pos.x + containerInfo.renderedDimensions.z * 0.1,
                y: containerInfo.renderedDimensions.y * 2,
                z: pos.z + dim.depth * 2
            }
            return;
        }

        // Close view for parcel to be packed
        cameraTarget = {
            x: pos.x + dim.width * 2,
            y: containerInfo.renderedDimensions.y * 2,
            z: pos.z + dim.depth / 2 + containerInfo.renderedDimensions.z * 0.1
        }
    }

    function handleCameraDirectionDefault(pos, dim, containerInfo) {
        if (loadingDirection === loadingDirections.side) {
            cameraTarget = {
                x: pos.x + Math.min(dim.width * 5, containerInfo.renderedDimensions.x * 0.33),
                y: containerInfo.renderedDimensions.y * 2,
                z: defaultCameraView.z
            }
            return;
        }
        // No need to change for packing direction
        cameraTarget = {
            x: pos.x + dim.width * 2,
            y: containerInfo.renderedDimensions.y * 2,
            z: defaultCameraView.z
        }
    }

    function handleCameraDirectionYSide(pos, dim, containerInfo) {
        if (loadingDirection === loadingDirections.side) {
            cameraTarget = {
                x: pos.x - Math.min(dim.width * 5, containerInfo.renderedDimensions.x * 0.33),
                y: containerInfo.renderedDimensions.y * 2,
                z: defaultCameraView.z
            }
            return;
        }

        cameraTarget = {
            x: pos.x + dim.width * 2,
            y: containerInfo.renderedDimensions.y * 2,
            z: -defaultCameraView.z
        }
    }

    function handleCameraDirectionZSide(pos, dim, containerInfo) {
        if (loadingDirection === loadingDirections.side) {
            cameraTarget = {
                x: pos.x,
                y: containerInfo.renderedDimensions.y * 4,
                z: pos.z + dim.depth * 2
            }
            return;
        }
        cameraTarget = {
            x: pos.x + dim.width * 4,
            y: containerInfo.renderedDimensions.y * 4,
            z: pos.z + dim.depth / 2
        }
    }

    function setCameraForStepByStep() {
        // Set camera to change immediately
        ANIMATION_STEPS = 1;
    }

    function zoomToParcel(sceneChild, direction) {
        ANIMATION_STEPS = 25;
        const pos = sceneChild.position;
        const dim = sceneChild.geometry.parameters;

        // Set camera focus target in center of the parcel
        controlTarget = {
            x: pos.x,
            y: pos.y,
            z: pos.z,
        }

        // Define magic numbers - these can be modified so that zoom looks nice
        const DEFAULT_ZOOMED_MULTIPLIER = 1.5;
        const SECONDARY_ZOOMED_MULTIPLIER = 0.4;

        // Set the camera target location based on direction
        switch (direction) {
            case arrowDirections.xMinus:
                cameraTarget = {
                    x: pos.x - dim.width * DEFAULT_ZOOMED_MULTIPLIER,
                    y: pos.y + dim.height * DEFAULT_ZOOMED_MULTIPLIER,
                    z: pos.z + dim.depth + defaultCameraView.z * SECONDARY_ZOOMED_MULTIPLIER,
                }
                break;
            case arrowDirections.xPlus:
            case arrowDirections.zPlus:
                cameraTarget = {
                    x: pos.x + dim.width * DEFAULT_ZOOMED_MULTIPLIER,
                    y: pos.y + dim.height * DEFAULT_ZOOMED_MULTIPLIER,
                    z: pos.z + dim.depth + defaultCameraView.z * SECONDARY_ZOOMED_MULTIPLIER,
                }
                break;
            case arrowDirections.yPlus:
                cameraTarget = {
                    x: pos.x + dim.width + defaultCameraView.z * SECONDARY_ZOOMED_MULTIPLIER,
                    y: pos.y + dim.height * DEFAULT_ZOOMED_MULTIPLIER,
                    z: pos.z + dim.depth * DEFAULT_ZOOMED_MULTIPLIER,
                }
                break;
            case arrowDirections.yMinus:
                cameraTarget = {
                    x: pos.x + dim.width + defaultCameraView.z * SECONDARY_ZOOMED_MULTIPLIER,
                    y: pos.y + dim.height * DEFAULT_ZOOMED_MULTIPLIER,
                    z: pos.x - dim.depth * DEFAULT_ZOOMED_MULTIPLIER,
                }
                break;
            case undefined:
                // HACK - ugly way to zoom to the "default" view close to box where no arrow is yet clicked
                // New magic numbers!! Modify these multipliers as you please if you don't like the zoom
                cameraTarget = {
                    x: pos.x + dim.width * 2,
                    y: pos.y + dim.height * 3,
                    z: pos.z + dim.depth * 4,
                }
                break;
            default:
                console.error("CameraHelper received unsupported direction: ", direction)
        }
    }

    function updateCameraView(packingParcel, highLighted, pos, dim, containerInfo) {
        // Set camera focus target in center of the parcel
        controlTarget = {
            x: pos.x,
            y: pos.y,
            z: pos.z,
        }

        // Simulate the camera directions in loading assistant to ease debugging, good camera position lookup
        let debug = false;
        if (debug) {
            ANIMATION_STEPS = 25;
            cameraTarget = {
                x: pos.x + dim.width * 2, // / 2 + defaultCameraView.z * 0.1, // * 2,
                y: pos.y + dim.height * 2, // / 2 + defaultCameraView.z * 0.1, // + containerInfo.renderedDimensions.y * 2,
                z: pos.z + dim.depth * 3, // + defaultCameraView.z * 0.1
            }
            console.log("Camera target: ", cameraTarget)
            return;
        }



        if (packingParcel) {
            ANIMATION_STEPS = 5;
            return handleCameraDirectionFocus(pos, dim, containerInfo)
        }

        if (highLighted) {
            ANIMATION_STEPS = 25;
            // Further view for parcels to be picked next
            if (highLighted.cameraDirection === cameraDirections.default) {
                return handleCameraDirectionDefault(pos, dim, containerInfo);
            }

            if (highLighted.cameraDirection === cameraDirections.ySide) {
                return handleCameraDirectionYSide(pos, dim, containerInfo);
            }

            if (highLighted.cameraDirection === cameraDirections.zSide) {
                ANIMATION_STEPS = 12.5;
                return handleCameraDirectionZSide(pos, dim, containerInfo)
            }
        }
    }

    function renderUpdatedControlView(target) {
        if (!cameraTarget || !controlTarget)
            return zeroDim;

        const xd = controlTarget.x - target.x;
        const yd = controlTarget.y - target.y;
        const zd = controlTarget.z - target.z;

        return {
            x: xd / ANIMATION_STEPS,
            y: yd / ANIMATION_STEPS,
            z: zd / ANIMATION_STEPS
        }
    }

    function renderUpdatedCameraView(position) {
        if (!cameraTarget)
            return zeroDim;

        const xDiff = cameraTarget.x - position.x;
        const yDiff = cameraTarget.y - position.y;
        const zDiff = cameraTarget.z - position.z;

        let moveBy = {
            x: xDiff / ANIMATION_STEPS,
            y: yDiff / ANIMATION_STEPS,
            z: zDiff / ANIMATION_STEPS
        }

        if (Math.abs(xDiff) + Math.abs(yDiff) + Math.abs(zDiff) < 10)
            clearTargets();

        return moveBy;
    }

    function setTargetsToDefault() {
        cameraTarget = Object.assign({}, defaultCameraView)
        controlTarget = origin;
    }

    function setLoadingDirection(ld) {
        loadingDirection = ld;
    }

    return {
        clearTargets,
        setDefaultCameraView,
        updateCameraView,
        renderUpdatedControlView,
        renderUpdatedCameraView,
        setLoadingDirection,
        setTargetsToDefault,
        setCameraForStepByStep,
        zoomToParcel
    }
}
