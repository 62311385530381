import React, { useEffect } from "react";

import Paper from '@material-ui/core/Paper'
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link'
import LaunchIcon from '@material-ui/icons/Launch'
import { withStyles } from '@material-ui/core/styles'

import RegisterForm from "./RegisterForm";

//////////////////////////////////////////////////////////////////////////////

const SignUp = props => {
    const { classes } = props;

    // HACK main element doesn't have Y-axis scroll by default, which is INTENDED
    // This is the only view that needs y-axis scroll, so we alter it only when this component is mounted
    useEffect(() => {
        const mainEl = document.getElementById('main');
        // Get the original overflow value
        const originalOverflow = mainEl.style.overflow;
        // Overwrite the overflow
        mainEl.style.overflow = 'auto auto';
        return () => {
            // Reset the overflow to the original value
            mainEl.style.overflow = originalOverflow;
        }
    }, [])

    return (
        <div className={classes.container} style={{ marginTop: '4.8em' }}>
            <Paper elevation={4} className={classes.paper} style={{ maxWidth: '34' }}>
                <div className={classes.logoContainer} style={{ height: '100%', flexDirection: 'column', paddingTop: '2rem' }}>
                    <img src='/assets/boxbot.svg' alt=" " className={classes.signInLogo} />
                    <div style={{ textAlign: 'center' }}>
                        <Typography>
                            Sign up for 14 day free trial with full license:
                        </Typography>
                        <Typography className={classes.checkMarkLabel} style={{ fontWeight: 'bold', maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
                            Truck &amp; Trailer &#43; Loading Operations
                        </Typography>
                        <br />
                        <div className={classes.checkMarkLabel}>
                            <Typography>
                                More information about licensing:
                            </Typography>
                            <Link component='button' className={classes.checkMarkLabel} color="primary" variant="body1" onClick={() => window.location.assign('https://boxbot.fi/pricing/')} >
                                <LaunchIcon /> &nbsp; {'BOXBOT Pricing'}
                            </Link>
                        </div>
                    </div>
                </div>
            </Paper>
            <Paper elevation={4} className={classes.paper} style={{ maxWidth: '28rem' }}>
                <RegisterForm />
            </Paper>
        </div>
    )
}

const styles = theme => ({
    container: theme.containers.twoPaperFlex,
    logoContainer: theme.containers.logo,
    paper: theme.containers.flexPaper,
    signInLogo: {
        ...theme.icons.signInLogo,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    checkMarkLabel: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1)
    },
    checkMark: {
        marginRight: theme.spacing(1)
    }
})

export default withStyles(styles)(SignUp);
