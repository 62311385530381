import React, { useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DoneIcon from '@material-ui/icons/Done'

import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotificationDialog, showNotification, updateUser } from '../../app/AppActions'
import { openDeleteDialog, closeDeleteDialog, setEditOrganization } from '../AdminActions'
import { browserHistory } from 'react-router'
import { organizations, users, cargoSpacePreset } from '../../api'
import { notificationDialogSeverity } from '../../core/Constants'

//////////////////////////////////////////////////////////////////////////////

const DeleteDialog = ({ editOrganization, deleteDialogOpen, onDeleteOrganization, onCloseDeleteDialog }) => (
    <Dialog open={deleteDialogOpen} onClose={onCloseDeleteDialog}>
        <DialogTitle onClose={onCloseDeleteDialog}>{'Delete organization'}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {'This will completely delete the organization.'}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onDeleteOrganization(editOrganization)}>Delete</Button>
        </DialogActions>
    </Dialog>
)

const RestrictDeleteDialog = ({ open, onClose, title, removeText, deleteDisabled, removableCargoPreset, removableUsers, onDeleteUsers, setRemovableUsers, onDeleteCargoPresets, setRemovableCargoPreset, onOpenDeleteDialog }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle onClose={onClose}>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {deleteDisabled ? `Organization has ${removeText} that need to be deleted or assigned to another organization before organization can be deleted` : ''}
            </DialogContentText>
            {removableUsers && removableUsers.length ?
                <div>
                    <DialogContent>
                        <Button startIcon={<DeleteForeverIcon />} variant="contained" color="primary" onClick={() => {
                            onDeleteUsers(removableUsers.map(user => user._id))
                            setRemovableUsers(null)
                        }}>Delete All Users</Button>
                    </DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{"User"}</TableCell>
                                <TableCell >{"Email"}</TableCell>
                                <TableCell >{"Roles"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {removableUsers.map(user => (
                                <TableRow key={user._id}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.roles.join(", ")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                : null}
            {removableCargoPreset && removableCargoPreset.length ?
                <div>
                    <DialogContent>
                        <Button startIcon={<DeleteForeverIcon />} variant="contained" color="primary" onClick={() => {
                            onDeleteCargoPresets(removableCargoPreset.map(preset => preset._id))
                            setRemovableCargoPreset(null)
                        }}>Delete All Presets</Button>
                    </DialogContent>
                    <div>
                        {`Cargo Space Preset: ${removableCargoPreset.map(x => x.text).join(", ")}`}
                    </div>
                </div>
                : null}
        </DialogContent>
        <DialogActions>
            <Button
                color="primary"
                disabled={deleteDisabled}
                onClick={() => {
                    onOpenDeleteDialog();
                    onClose();
                }}>Ok</Button>
        </DialogActions>
    </Dialog>
)

const RestrictPatchDialog = ({ open, onClose, title, text, user, editOrganization, allowedRoles, notAllowedRoleUsers, onDeleteUserRoles, setNotAllowedRoleUsers, onUpdateOrganization }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle onClose={onClose}>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {text}
            </DialogContentText>
            {notAllowedRoleUsers && notAllowedRoleUsers.length ?
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {["User email", "Not allowed roles", "Remove roles"].map(x => (
                                <TableCell key={x}>{x}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notAllowedRoleUsers.map(user => (
                            <TableRow key={user._id}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.roles.filter(x => !allowedRoles.includes(x)).join(", ")}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" startIcon={<DeleteForeverIcon />} onClick={() => {
                                        const deletingRoles = user.roles.filter(x => !allowedRoles.includes(x)).map(x => `'${x}'`).join(", ")
                                        user.roles = user.roles.filter(x => allowedRoles.includes(x))
                                        onDeleteUserRoles(user, deletingRoles)
                                        setNotAllowedRoleUsers(notAllowedRoleUsers.filter(r => r._id !== user._id))
                                    }}>
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                : null}
        </DialogContent>
        <DialogActions>
            <Button color="primary" disabled={notAllowedRoleUsers && notAllowedRoleUsers.length > 0} onClick={() => {
                onUpdateOrganization(user, editOrganization)
                onClose();
            }}>Ok</Button>
        </DialogActions>
    </Dialog>
)

const OrganizationsEditHeader = (props) => {
    const { user, editOrganization, deleteDialogOpen, classes } = props
    const { onCreateOrganization, onDeleteUserRoles } = props
    const { onOpenDeleteDialog, onCloseDeleteDialog } = props
    const { checkOrganizationDelete, onDeleteOrganization } = props
    const { checkOrganizationUpdate, onUpdateOrganization, onDeleteUsers, onDeleteCargoPresets } = props

    const [notAllowedRoleUsers, setNotAllowedRoleUsers] = useState(null)
    const [patchDialogOpen, setPatchDialogOpen] = useState(false)

    const [restrictDeleteDialogOpen, setRestrictDeleteDialogOpen] = useState(false)
    const [removableUsers, setRemovableUsers] = useState(null)
    const [removableCargoPreset, setRemovableCargoPreset] = useState(null)


    if (editOrganization.name == null) {
        return (
            <div />
        )
    }
    const deleteDisabled = (removableCargoPreset && removableCargoPreset.length > 0) || (removableUsers && removableUsers.length > 0)
    const userRemoveText = removableUsers ? `user${removableUsers.length > 1 ? "'s" : ''}` : ''
    const presetRemoveText = removableCargoPreset ? `cargo space preset${removableCargoPreset.length > 1 ? "'s" : ''}` : ''
    const removeText = userRemoveText !== '' && presetRemoveText !== '' ? `${userRemoveText} and ${presetRemoveText}` : `${userRemoveText}${presetRemoveText}`

    let allowedRoles = editOrganization.allowedRoles.slice();
    if (editOrganization.allowSuperadmin) allowedRoles.push("superadmin")

    const onStoreButton = () =>
        checkOrganizationUpdate(editOrganization)
            .then(res => {
                if (!res) {
                    onUpdateOrganization(user, editOrganization)
                } else {
                    setPatchDialogOpen(true)
                    setNotAllowedRoleUsers(res)
                }
            })

    const onDeleteButton = () =>
        checkOrganizationDelete(editOrganization)
            .then(res => {
                if (!res) {
                    onOpenDeleteDialog()
                } else {
                    setRestrictDeleteDialogOpen(true)
                    if (res.users) setRemovableUsers(res.users)
                    if (res.cargoPresets) setRemovableCargoPreset(res.cargoPresets)
                }
            })

    let deleteButton, storeButton, deleteButton2, storeButton2
    if (editOrganization._id == null) {
        storeButton = (
            <Button startIcon={<DoneIcon />} onClick={() => onCreateOrganization(editOrganization)} >Create</Button>
        )
        storeButton2 = (
            <IconButton onClick={() => onCreateOrganization(editOrganization)} >
                <DoneIcon />
            </IconButton>
        )
    } else {
        deleteButton = (
            <Button startIcon={<DeleteForeverIcon />} onClick={onDeleteButton} disabled={!user.roles.includes('superadmin')}  >
                Delete
            </Button>
        )
        deleteButton2 = (
            <IconButton onClick={onDeleteButton} disabled={!user.roles.includes('superadmin')} >
                <DeleteForeverIcon />
            </IconButton>
        )
        storeButton = (
            <Button startIcon={<DoneIcon />} onClick={onStoreButton}>
                Save
            </Button>
        )
        storeButton2 = (
            <IconButton onClick={onStoreButton}>
                <DoneIcon />
            </IconButton>
        )
    }

    return (
        <Toolbar>
            <div className={classes.desktop}>
                {deleteButton}
                {storeButton}
            </div>
            <div className={classes.tablet}>
                {deleteButton2}
                {storeButton2}
            </div>
            <DeleteDialog editOrganization={editOrganization} deleteDialogOpen={deleteDialogOpen} onDeleteOrganization={onDeleteOrganization} onCloseDeleteDialog={onCloseDeleteDialog} />
            <RestrictDeleteDialog
                open={restrictDeleteDialogOpen}
                onClose={() => setRestrictDeleteDialogOpen(false)}
                title={deleteDisabled ? "Cannot delete organization" : "Organization can now be deleted"}
                removeText={removeText}
                deleteDisabled={deleteDisabled}
                removableCargoPreset={removableCargoPreset}
                setRemovableCargoPreset={setRemovableCargoPreset}
                removableUsers={removableUsers}
                onDeleteUsers={onDeleteUsers}
                setRemovableUsers={setRemovableUsers}
                onDeleteCargoPresets={onDeleteCargoPresets}
                onOpenDeleteDialog={onOpenDeleteDialog}
            />
            <RestrictPatchDialog
                open={patchDialogOpen}
                onClose={() => setPatchDialogOpen(false)}
                title={notAllowedRoleUsers && notAllowedRoleUsers.length ? "Cannot update organization" : "Organization can now be updated"}
                text={notAllowedRoleUsers && notAllowedRoleUsers.length ? `These user${notAllowedRoleUsers && notAllowedRoleUsers.length > 1 ? "'s" : ''} roles need to be changed before organization can be updated:` : ''}
                allowedRoles={allowedRoles}
                notAllowedRoleUsers={notAllowedRoleUsers}
                onDeleteUserRoles={onDeleteUserRoles}
                setNotAllowedRoleUsers={setNotAllowedRoleUsers}
                onUpdateOrganization={onUpdateOrganization}
                editOrganization={editOrganization}
                user={user}
            />
        </Toolbar>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        user: state.user,
        editOrganization: state.admin.editOrganization,
        deleteDialogOpen: state.admin.deleteDialogOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    const validateOrganization = async (organization) => {
        delete organization.error;
        if (organization.name.length < 1)
            return 1

        let query = { name: organization.name };
        if (organization._id) {
            query._id = { $ne: organization._id }
        }

        const sameName = await organizations.query(query);
        if (sameName.data.length)
            return 2

        if (!Number.isInteger(organization.officeUserCountLimit)) {
            const userCountNumber = Number.parseInt(organization.officeUserCountLimit)
            const userCountIsValid = organization.officeUserCountLimit.match(/[^0-9]*/g).every(x => x === '') && userCountNumber >= 0;
            if (!userCountIsValid)
                return 3

            organization.officeUserCountLimit = userCountNumber
        }

        return false
    }

    const stripAndAddNecessaryFields = org => {
        // In case of organization already exists AND it doesn't have email verified - the user has registered through form and hasn't even logged into the account
        org.emailVerified = org._id ? org.emailVerified : true;

        // Always delete organization modified field
        delete org.modified;

        if (!org.isTrial) {
            // trial expiry date is not needed for non-trial organizations
            org.trialExpires = null;
        }

        return org;
    }


    return {
        onCreateOrganization: async (organization) => {
            const notValid = await validateOrganization(organization);
            if (notValid) {
                organization.error = notValid;
                dispatch(setEditOrganization(organization))
                return
            }

            organization = stripAndAddNecessaryFields(organization);
            organizations.create(organization)
                .then(() => {
                    dispatch(showNotification(`${organization.name} created`));
                    browserHistory.push('/admin/organizations#0');
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                });
        },
        checkOrganizationUpdate: (organization) => {
            return new Promise((resolve, reject) => {
                users.query({ organization: organization._id })
                    .then(res => {
                        let allowRoles = organization.allowedRoles.slice();
                        if (organization.allowSuperadmin) allowRoles.push('superadmin')
                        const usersToAlter = res.data.filter(x => x.roles.find(role => !allowRoles.includes(role)))
                        if (usersToAlter.length) {
                            resolve(usersToAlter)
                        } else {
                            resolve(null)
                        }
                    })
                    .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
            })
        },
        onUpdateOrganization: async (user, organization) => {
            const notValid = await validateOrganization(organization);
            if (notValid) {
                organization.error = notValid;
                dispatch(setEditOrganization(organization))
                return
            }

            organization = stripAndAddNecessaryFields(organization);
            organizations.patch(organization._id, organization)
                .then(res => {
                    dispatch(showNotification(`${organization.name} updated`))

                    // If the user's own organization was updated, update user state to match it
                    if (user.organization._id === organization._id) {
                        user.organization = res;
                        dispatch(updateUser(user))
                    }
                    browserHistory.push('/admin/organizations#0')
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        checkOrganizationDelete: (organization) => {
            return new Promise((resolve, reject) => {
                let resDict = {}
                users.query({ organization: organization._id })
                    .then(res => {
                        if (res.data.length) resDict.users = res.data
                        cargoSpacePreset.query({ organization: organization._id })
                            .then(cargoRes => {
                                if (cargoRes.data.length) resDict.cargoPresets = cargoRes.data
                                if (resDict.cargoPresets || resDict.users) resolve(resDict)
                                else resolve(null)
                            })
                    })
                    .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
            })
        },
        onDeleteOrganization: (organization) => {
            organizations.remove(organization._id)
                .then(() => {
                    dispatch(closeDeleteDialog())
                    dispatch(showNotification(`${organization.name} deleted`))
                    browserHistory.push('/admin/organizations#0')
                })
                .catch(err => {
                    dispatch(closeDeleteDialog())
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onOpenDeleteDialog: () => {
            dispatch(openDeleteDialog())
        },
        onCloseDeleteDialog: () => {
            dispatch(closeDeleteDialog())
        },
        onDeleteUserRoles: (user, deletingRoles) => {
            users.patch(user._id, user)
                .then(() => dispatch(showNotification(`${user.email} roles ${deletingRoles} removed`)))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onDeleteUsers: (userIds) => {
            users.removeMany(userIds)
                .then(() => dispatch(showNotification(`users deleted`)))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onDeleteCargoPresets: (presets) => {
            cargoSpacePreset.removeMany(presets)
                .then(() => dispatch(showNotification(`presets deleted`)))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
    }
}

const styles = theme => ({
    desktop: theme.desktop,
    tablet: theme.tablet
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationsEditHeader))

//////////////////////////////////////////////////////////////////////////////
