import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { get, isEmpty } from 'lodash'

//////////////////////////////////////////////////////////////////////////////

const GeneralDataTableBody = (props) => {
    const { classes } = props;

    // Checks if row has been selected. True if yes, false if no.
    const isRowSelected = (row) => props.selectedRows != null && props.selectedRows.map(sr => sr._id).indexOf(row._id) !== -1

//////////////////////////////////////////////////////////////////////////////

    return (
        <TableBody>

            {/* No data overlay */}
            {(props.data == null || isEmpty(props.data) || props.data.data?.length === 0) &&
                <TableRow>
                    <TableCell colSpan={props.columns.length} style={{ border: 'none' }}>
                        <Typography
                            className={classes.noDataText}
                            variant='h6'
                        >
                            No entries
                        </Typography>
                    </TableCell>
                </TableRow>
            }

            {/* Normal data rows */}
            {props.data.data?.map((row, i) => {
                return (
                    <TableRow
                        key={i}
                        hover
                        selected={isRowSelected(row)}
                        onClick={() => props.onClickRow(row)}
                    >
                        {/* If props.selectedRows has been given to GeneralDataTable, show checkboxes on each row */}
                        {props.selectedRows != null &&
                            <TableCell>
                                <Checkbox
                                    checked={props.selectedRows.find(sr => sr._id === row._id) != null}
                                    onChange={() => props.onSelectRow(row)}
                                    onClick={(e) => e.stopPropagation()} // This onClick prevents the click-event "going through" to TableRow
                                />
                            </TableCell>
                        }

                        {/* Cells for each column */}
                        {props.columns.map((col, j) => {

                            const cellValue = col.conversion ?
                                col.conversion(get(row, col.field))
                                :
                                get(row, col.field)

                            return (
                                <TableCell key={j} className={classes.tableCell}>
                                    {cellValue}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                )
            })}
        </TableBody>
    );

};

//////////////////////////////////////////////////////////////////////////////

GeneralDataTableBody.defaultProps = {
    columns: [],
    data: {}
}

const styles = (theme) => ({
    noDataText: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tableCell: {
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal'
    }
});


export default withStyles(styles)(GeneralDataTableBody);
