import React from 'react';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const PackedParcelsProgressBar = (props) => {
    const { classes } = props;


//////////////////////////////////////////////////////////////////////////////

    return (
        <Grid
            container
            spacing={2}
            className={classes.progressBarContainer}
            alignItems='center'
        >

            {/* Progress Bar */}
            <Grid xs item>
                <LinearProgress
                    style={{ height: '12px' }}
                    color="secondary"
                    variant="determinate"
                    value={props.progressValue}
                />
            </Grid>

            {/* Parcel count */}
            <Grid item>
                <div style={{ fontSize: '1rem' }}>
                    {`${props.packed} / ${props.cargoSpace ? props.solutionParcels.length : 0}`}
                </div>
            </Grid>

        </Grid>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    progressBarContainer: {
        position: 'absolute',
        height: '50px',
        width: '90%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    progressBar: {
        height: '100%',
        width: '95%',
        backgroundColor: 'cyan'

    },
    packedParcelsCounter: {
        display: 'flex',
    },
});


export default withStyles(styles)(PackedParcelsProgressBar);
