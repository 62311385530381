import app, { store } from '.'
import { browserHistory } from 'react-router'
import { deauthUser, showNotification, showNotificationDialog } from './app/AppActions'
import { notificationDialogSeverity } from './core/Constants'

let isChrome = navigator.userAgent.indexOf('Chrome') > -1
let isSafari = navigator.userAgent.indexOf("Safari") > -1
let isOpera = navigator.userAgent.toLowerCase().indexOf("op") > -1
if (isChrome && isSafari) isSafari = false
if (isChrome && isOpera) isChrome = false

export const checkAuth = async (callbackIfValid) => {
    const loggedUser = await users.get();
    if (loggedUser && loggedUser.accessToken) {
        // User is logged in
        const isTokenValid = await app.authenticate({ strategy: "jwt", accessToken: loggedUser.accessToken })
        if (!isTokenValid)
            signOutAndRedirect()
        else if (callbackIfValid)
            callbackIfValid()
    } else {
        // No one is logged in
        signOutAndRedirect()
    }
}

export const signOutAndRedirect = (message) => {
    const timeout = setTimeout(() => {
        store.dispatch(showNotificationDialog('Connection timeout during sign out.', 'Connection timed out', notificationDialogSeverity.warning))
    }, 5000)

    app.logout()
        .then(() => {
            // First, redirect the user to /signing and set the state as `deauthenticated` to explicitly state why this was done
            browserHistory.push({ pathname: '/signin', state: { deauthenticated: true }})
            // Then deauth user from redux
            store.dispatch(deauthUser())
            clearTimeout(timeout)
            store.dispatch(showNotification(message || 'Signed out.'))
        })
        .catch(err => {
            clearTimeout(timeout)
            store.dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
        })
}

const redirectHandler = err => {
    if (err.code === 401 && ((err.data && err.data.name === "TokenExpiredError") || err.name === "NotAuthenticated")) {
        signOutAndRedirect('Login authentication time expired, please sign in again.')
    } else {
        // All other errors must be handled elsewhere.
        throw err
    }
}

const passwordChangeService = () => app.service('v1/password-change')
const passwordResetRequestService = () => app.service('v1/password-requestreset')
const passwordResetRequestAdminService = () => app.service('v1/password-requestreset-admin')
const passwordResetService = () => app.service('v1/password-reset')
const userService = () => app.service('v1/users')
export const users = {
    list: (page, user) => {
        // const user = app.get('user')
        const query = {
            $limit: 25,
            $skip: ((page - 1) || 0) * 25,
        }
        return userService().find({ query: query }).catch(redirectHandler)
    },
    query: query => {
        return userService().find({ query: query }).catch(redirectHandler);
    },
    get: id => {
        if (!id) {
            return new Promise(resolve => {
                try {
                    // Eventhough Feathers documentation states that this should return Promise
                    // when there's no user logged in, app.get('authentication') returns null instead of Promise which causes error.
                    // Catching the error here is done to reduce repetition accross the app when using users.get()
                    //
                    // https://docs.feathersjs.com/api/authentication/client.html#app-authenticate-data
                    app.get('authentication').then(res => resolve(res))
                } catch (error) {
                    signOutAndRedirect()
                    resolve(null)
                }
            })
        }
        return userService().get(id).catch(redirectHandler)
    },
    create: user => userService().create(user).catch(redirectHandler),
    patch: (id, user) => userService().patch(id, user).catch(redirectHandler),
    remove: id => userService().remove(id).catch(redirectHandler),
    removeMany: ids => userService().remove(null, { query: { _id: { $in: ids } } }).catch(redirectHandler),
    authenticate: () => app.authentication.reAuthenticate(true),
    signIn: (email, password) => app.authenticate({ strategy: 'local', email, password }),
    signInGoogle: () => {
        window.location.href = process.env.REACT_APP_OAUTH_URL + '/google';
        return Promise.resolve(null)
    },
    signOut: () => app.logout(),
    changePassword: (oldPassword, newPassword) => passwordChangeService().create({ oldPassword, newPassword }),
    getResetRequest: (id) => passwordResetRequestService().get(id),
    findResetRequests: (query) => passwordResetRequestService().find({ query: query}),
    resetPassword: (id, token, password) => passwordResetService().create({ id, token, password }),
    requestPasswordReset: body => passwordResetRequestService().create(body),
    requestPasswordResetAdmin: email => passwordResetRequestAdminService().create({ email }),
}

const excelService = () => app.service('v1/parse-excel');
export const excel = {
    create: data => excelService().create(data, {}).catch(redirectHandler),
};

const optimizeService = () => app.service('v1/optimize');
export const optimize = {
    create: data => optimizeService().create(data, {}).catch(redirectHandler),
    cancel: (id) => optimizeService().remove(id, { query: { cancel: true } }).catch(redirectHandler),
    service: optimizeService,
};

const cargoSpacePresetService = () => app.service('v1/cargo-space-preset');
export const cargoSpacePreset = {
    list: (page, limit = 25, organizationId) => {
        const query = {
            $limit: limit,
            $skip: page > 0 ? (page * limit) : 0,
            organization: organizationId
        }
        return cargoSpacePresetService().find({ query: query }).catch(redirectHandler)
    },
    query: query => cargoSpacePresetService().find({ query: query }).catch(redirectHandler),
    get: (id) => cargoSpacePresetService().get(id).catch(redirectHandler),
    create: preset => cargoSpacePresetService().create(preset).catch(redirectHandler),
    patch: (id, preset) => cargoSpacePresetService().patch(id, preset).catch(redirectHandler),
    remove: id => cargoSpacePresetService().remove(id).catch(redirectHandler),
    removeMany: ids => cargoSpacePresetService().remove(null, { query: { _id: { $in: ids } } }).catch(redirectHandler),
}

const organizationsService = () => app.service('v1/organizations');
export const organizations = {
    list: (page) => {
        // const user = app.get('user')
        const query = {
            $limit: 25,
            $skip: ((page - 1) || 0) * 25,
        }
        return organizationsService().find({ query: query }).catch(redirectHandler)
    },
    query: query => {
        return organizationsService().find({ query: query }).catch(redirectHandler);
    },
    get: id => {
        return organizationsService().get(id).catch(redirectHandler)
    },
    create: user => organizationsService().create(user).catch(redirectHandler),
    patch: (id, user) => organizationsService().patch(id, user).catch(redirectHandler),
    remove: id => organizationsService().remove(id).catch(redirectHandler)
}

const solutionService = () => app.service('v1/solutions');
export const solutions = {
    list: (query = {
        $sort: { createdAt: -1 }
    }) => {
        return solutionService().find({ query: query }).catch(redirectHandler)
    },
    get: id => solutionService().get(id).catch(redirectHandler),
    find: params => solutionService().find(params).catch(redirectHandler),
    create: solution => {
        delete solution.createdAt;
        delete solution.updatedAt;
        return solutionService().create(solution).catch(redirectHandler)
    },
    remove: id => solutionService().remove(id).catch(redirectHandler),
    removeMany: ids => solutionService().remove(null, { query: { _id: { $in: ids }}}).catch(redirectHandler),
    update: (id, solution) => {
        delete solution.createdAt
        delete solution.updatedAt
        return solutionService().update(id, solution).catch(redirectHandler)
    },
    patch: (id, data, params) => {
        return solutionService().patch(id, data, params).catch(redirectHandler)
    },
    service: solutionService,
}

const loadingAssistantService = () => app.service('v1/loading-assistant');
const loadingAssistantConfirmParcelService = () => app.service('v1/loading-assistant/confirm-parcel');
const loadingAssistantAssignUserService = () => app.service('v1/loading-assistant/assign-user');
export const loadingAssistantApi = {
    list: (q) => {
        const query = {
            ...q,
            $sort: { createdAt: -1 }
        }
        return loadingAssistantService().find({ query: query }).catch(redirectHandler)
    },
    get: (id, cargoSpaceIndex) => {
            return loadingAssistantService().get(id, { query: { $cargoSpaceIndex: cargoSpaceIndex } })
            .catch(redirectHandler)
    },
    create: (solution) => {
        return loadingAssistantService().create(solution).catch(redirectHandler);
    },
    /**
     * q = { $updateType, [$parcelId], [$cargoSpaceStatus] }
     */
    patch: (solution, q) => {
        const query = {
            $cargoSpaceIndex: solution.cargoSpaceIndex,
            ...q
        }
        return loadingAssistantService().patch(solution._id, {}, { query: query }).catch(redirectHandler)
    },
    update: (solution) => {
        return loadingAssistantService().update(solution._id, solution, {
            query: {
                $cargoSpaceIndex: solution.cargoSpaceIndex
            }
        }).catch(redirectHandler)
    },
    setParcelLoaded: (solutionId, data, query) => {
        return loadingAssistantConfirmParcelService().patch(solutionId, data, { query }).catch(redirectHandler)
    },
    assignUser: (solution, params) => {
        return loadingAssistantAssignUserService().create(solution, params).catch(redirectHandler)
    },
    findAssignment: (params) => {
        return loadingAssistantAssignUserService().find(params).catch(redirectHandler)
    },
    removeAssignment: (assignmentId, params) => {
        return loadingAssistantAssignUserService().remove(assignmentId, params).catch(redirectHandler)
    },
    patchAssignment: (assignmentId, newData, params) => {
        return loadingAssistantAssignUserService().patch(assignmentId, newData, params).catch(redirectHandler)
    },
    assignmentService: loadingAssistantAssignUserService
}

const LMCService = () => app.service('v1/loading-assistant/last-minute-change');
const LMCCancelService = () => app.service('v1/loading-assistant/last-minute-change/cancel');
const LMCConfirmService = () => app.service('v1/loading-assistant/last-minute-change/confirm');
const LMCForceCompletionService = () => app.service('v1/loading-assistant/last-minute-change/force-completion');
export const LMC = {
    initializeLMCObject: (solution, data) => {
        const query = {
            solutionId: solution._id,
            cargoSpaceIndex: solution.cargoSpaceIndex,
        }
        return LMCService().create(data, { query: query }).catch(redirectHandler);
    },
    selectParcelInEditing: (solution, parcel) => {
        const query = {
            solutionId: solution._id,
            cargoSpaceIndex: solution.cargoSpaceIndex
        }
        return LMCService().update(solution._id, parcel, { query: query }).catch(redirectHandler)
    },
    patch: (parcelId, parcelData, solution, lmcStrategy) => {
        const query = {
            cargoSpaceIndex: solution.cargoSpaceIndex,
            solutionId: solution._id,
            lmcStrategy: lmcStrategy
        };
        return LMCService().patch(parcelId, parcelData, { query: query }).catch(redirectHandler);
    },
    remove: (parcelId, solution) => {
        const query = {
            cargoSpaceIndex: solution.cargoSpaceIndex,
            solutionId: solution._id,
        };
        return LMCService().remove(parcelId, { query: query }).catch(redirectHandler);
    },
    cancel: (solutionId, cargoSpaceIndex) => {
        return LMCCancelService().create({ solutionId, cargoSpaceIndex }).catch(redirectHandler);
    },
    confirm: (solutionId, cargoSpaceIndex, selectedArrangements) => {
        return LMCConfirmService().create({ solutionId, cargoSpaceIndex, selectedArrangements }).catch(redirectHandler);
    },
    forceCompletion: (solution) => {
        const query = {
            cargoSpaceIndex: solution.cargoSpaceIndex
        }
        return LMCForceCompletionService().patch(solution._id, { packingStatus: 3 }, { query: query });
    }
}

const signupService = () => app.service('v1/signup');
export const signup = {
    create: (body) => signupService().create(body),
    find: (query) => {
        return signupService().find({ query: query })
    }
}

const emailConfirmationService = () => app.service('v1/emailConfirmation')
const emailValidationService = () => app.service('v1/emailConfirmation/validate');
const resendEmailConfirmationService = () => app.service('v1/emailConfirmation/resend');
export const emailConfirmation = {
    validate: (token) => emailValidationService().create({ token }),
    resend: (email) => resendEmailConfirmationService().create({ email }),
    find: (query) => {
        return emailConfirmationService().find({ query: query })
    },
}


const optimizationTasksLoggerService = () => app.service('v1/optimizationTaskLogger');
const loadingPlanCreationLoggerService = () => app.service('v1/loadingPlanCreationLogger');
export const loggerService = {
    findOptimizationTaskLogs: (query) => {
        return optimizationTasksLoggerService().find({ query: query });
    },
    findLoadindPlanCreationLogs: (query) => {
        return loadingPlanCreationLoggerService().find({ query: query })
    }
}
