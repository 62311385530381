import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'


//////////////////////////////////////////////////////////////////////////////


const CargoDataTableHeader = (props) => {

    const { classes } = props


//////////////////////////////////////////////////////////////////////////////


    return (
        <TableHead>
            <TableRow enabled="true" >

                {/* Add empty cell to header for the action-button column */}
                <TableCell className={classes.headerCell} />

                {props.columns && props.columns.map((column, i) => {

                    return (
                        <TableCell
                            className={classes.headerCell}
                            key={i}
                            align={column.align ? column.align : 'inherit'}
                        >
                            {column.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
};


//////////////////////////////////////////////////////////////////////////////


const styles = theme => ({
    headerCell: {
        width: '56px',
        whiteSpace: 'pre-wrap',
        padding: '6px 12px'
    }
})


export default withStyles(styles)(CargoDataTableHeader)
