import { defaultLoadingPlanRuleObject, cargoSpaceTypes, progressSteps, planStates, sendModes } from "../core/Constants"
import { isEqual, cloneDeep } from "lodash"
const debug = 0
const defaults = {
    progress: debug ? 0 : progressSteps.cargoData,
    planState: planStates.clear,
    parcels: [],
    deletedParcels: [],
    storages: [{
        priority: 1,
        id: "PRIMARY",
        dimensions: {
            x: 1200,
            y: 800,
            z: 1400,
        },
        weightLimit: 0,
        loadingDirection: null,
        negativeSpaces: [],
        visualAids: [],
        cargoSpaceType: cargoSpaceTypes.container,
        axles: [],
        maxAvailableCount: 0,
        useWeightDistributionFitness: false,
        presetName: null,
    }],
    solution: null,
    rules: defaultLoadingPlanRuleObject(),
    selectedParcel: null,
    name: '',
    autoSave: true,
    newJobId: null,
    unsaved: false,
    calculated: false,
    basicInfoOpen: debug ? false : true,
    disableChanges: false,
    sendMode: sendModes.ready,
    showCalculationOverlay: false
};

export default (state = defaults, action) => {
    switch (action.type) {
        case "SET_PROGRESS":
            return Object.assign({}, state, { progress: action.progress })
        case "SET_PLANSTATE":
            return Object.assign({}, state, { planState: action.value })
        case "SET_PARCELS":
            if (!isEqual(state.parcels, action.parcels)) {
                return Object.assign({}, state, { parcels: action.parcels || [], unsaved: true, calculated: false })
            }
            return Object.assign({}, state, { parcels: action.parcels || [] })
        case "SET_STORAGES":
            if (!isEqual(state.storages, action.storages))
                return Object.assign({}, state, { storages: action.storages, unsaved: true, calculated: false })
            return Object.assign({}, state, { storages: action.storages })
        case "SET_RULES":
            if (isEqual(state.rules, action.rules))
                return Object.assign({}, state, { rules: action.rules || {} })

            const newRules = Object.assign({}, state.rules, action.rules)
            let newStorages
            if (newRules.storageSelectionStrategy === 'SimpleSingle' && state.storages.length > 0)
                newStorages = state.storages.slice(0, 1)
            else if (newRules.storageSelectionStrategy === 'UseSecondaryOnLast' && state.storages.length < 2) {
                newStorages = state.storages.concat(cloneDeep(defaults.storages[0]))
                newStorages[1].priority = 0
                newStorages[1].id = "SECONDARY"
                newStorages[1].maxAvailableCount = 1
            }

            return Object.assign({}, state, { rules: newRules, storages: newStorages, unsaved: true, calculated: false })
        case "SET_SOLUTION":
            if (action.solution == null) {
                return Object.assign({}, state, { solution: action.solution, unsaved: true, calculated: false })
            }
            return Object.assign({}, state, { solution: action.solution, unsaved: false })
        case "SET_SAVED_SOLUTION":
            const planState = action.solution.cargoSpaces.some(cs => cs.packingStatus && cs.packingStatus > 0) ? planStates.loading : planStates.inspect
            const viewModel = action.solution.viewModel;
            return Object.assign({}, state, {
                progress: planState === planStates.loading ? progressSteps.loading : progressSteps.optimize,
                planState: planState,
                solution: action.solution,
                parcels: viewModel.parcels.map((x, i) => {
                    x.tableData = { id: i };
                    return x
                }),
                deletedParcels: viewModel.deletedParcels.map((x, i) => {
                    x.tableData = { id: i };
                    return x
                }),
                rules: viewModel.rules,
                storages: JSON.parse(JSON.stringify(viewModel.storages)),
                selectedParcel: null,
                name: action.solution.name,
                autoSave: false,
                unsaved: false,
                calculated: true // Saved solution has already been calculated
            })
        case "SET_SELECTED_PARCEL":
            return Object.assign({}, state, { selectedParcel: action.parcel })
        case "SET_DELETED_PARCELS":
            if (!isEqual(state.deletedParcels, action.deletedParcels)) {
                return Object.assign({}, state, { deletedParcels: action.deletedParcels, unsaved: true })
            }
            return Object.assign({}, state, { deletedParcels: action.deletedParcels })
        case "SET_AUTOSAVE":
            return Object.assign({}, state, { autoSave: action.value })
        case "SET_INIT_INFO":
            return Object.assign({}, state, { name: action.name, autoSave: action.autoSave, unsaved: true })
        case "SET_NEW_JOB_ID":
            return Object.assign({}, state, { newJobId: action.id, waitLoop: 1 })
        case "SET_UNSAVED":
            return Object.assign({}, state, { unsaved: action.value })
        case "SET_CALCULATED":
            return Object.assign({}, state, { calculated: action.value })
        case "SET_BASIC_INFO_OPEN":
            return Object.assign({}, state, { basicInfoOpen: action.value })
        case "SET_DISABLE_CHANGES":
            if (state.planState === planStates.loading) {
                return Object.assign({}, state, { disableChanges: true })
            }
            return Object.assign({}, state, { disableChanges: action.value })
        case "SET_SEND_MODE":
            return Object.assign({}, state, { sendMode: action.value })
        case "SET_SHOW_CALC_OVERLAY":
            return Object.assign({}, state, { showCalculationOverlay: action.value })
        case "RESET_OPTIMIZER":
            // Deepclone default state - otherwise the default state can be overwritten at the components !
            return cloneDeep(defaults)
        default:
            return state
    }
}
