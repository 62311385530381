import React from 'react'
import CookieConsent from 'react-cookie-consent'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

const CookieDisclaimer = ({ user }) => {
    return !user.email ? null : (
        <CookieConsent
            cookieName="boxbotCookieDisclaimerCookie"
            buttonText="ACCEPT COOKIES"
            style={{
                'backgroundColor': 'black', 'opacity': 0.85, marginBottom: "8px", minHeight: '33vh', padding: '2em'
            }}
            buttonStyle={{
                'backgroundColor': 'white', 'color': 'black', 'fontWeight': 'bold',
                'fontSize': '1.225em', 'padding': '0.75em 1.5em'
            }}>
            <>
                <Typography variant='h3'>This website uses cookies</Typography>
                <Typography variant='subtitle1'>
                    BOXBOT uses cookies to provide smooth and seamless user experience. By using the service, you accept the use of cookies.
                </Typography>
            </>
        </CookieConsent>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieDisclaimer)
