import { useEffect, useState } from 'react';
import threeEntryPoint from './threeEntryPoint';
import { solutionPackingStatus } from '../loadingAssistant/Constants';

/**
 * Renders threer, creates an image out of it and disposes itself
 * @returns image
 */
const solutionPreviewThreer = (three, solution, index) => {
    const loadSolution = (solution, index) => {
        if (!three || !solution) {
            return;
        }
        three.clear();
        three.drawCargoSpace(solution, index)
        three.updateParcels(null, Number.MAX_SAFE_INTEGER, false)

        if (solution.cargoSpaces[index]?.packingStatus === solutionPackingStatus.packed) {
            three.applyCompletedHighlight()
        }

        const imgData = three.exportThree()
        return imgData;
    }
    const img = loadSolution(solution, index);
    return img;
}

/**
 * Custom hook that gathers previewImages from the solutions cargoSpaces without freezing UI
 * @param {*} solution
 * @returns imageDatas
 */
const usePreviewThreer = (solution) => {
    const [three, setThree] = useState(null)
    const [state, setState] = useState({
        previewImages: {},
        previewImgIndex: 0,
        preventLoad: false
    })
    const { previewImages, previewImgIndex, preventLoad } = state;


    // Dispose three when unmounting
    useEffect(() => {
        // Initialize three only once !
        const imgStyle = { width: 532, height: 287 }
        const zoomFactor = 0.717
        let entryPoint = threeEntryPoint({
            containerElement: null,
            preventResize: true,
            noBorders: true,
            zoomFactor: zoomFactor,
            style: imgStyle,
        });
        setThree(entryPoint);

        return () => {
            entryPoint.dispose();
        }
    }, [])


    // Reset loaded preview images when solution changes
    useEffect(() => {
        setState({
            previewImages: [],
            previewImgIndex: 0,
            preventLoad: false
        })
    }, [solution])


    // Non-blocking rendering of preview three images
    useEffect(() => {
        let timeOut
        if (!preventLoad && solution && solution.cargoSpaces?.length && three) {
            previewImages[previewImgIndex] = solutionPreviewThreer(three, solution, previewImgIndex)
            timeOut = setTimeout(() => {
                if (previewImgIndex === solution.cargoSpaces.length - 1) {
                    setState({ ...state, previewImages, preventLoad: true })
                } else {
                    setState({ ...state, previewImgIndex: previewImgIndex + 1, previewImages })
                }
            }, 0)
        }

        // This prevents the component to update an unmounted component
        return () => {
            clearTimeout(timeOut)
        }
    }, [solution, state, previewImages, previewImgIndex, preventLoad, three]);

    return previewImages
}

export { usePreviewThreer }
