import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import DialogTitle from '../../core/DialogTitle';
import Threer from '../../three/Threer';

import { cloneDeep } from 'lodash';
import PropTypes from "prop-types";
import { lmcStatus, lmcTypes } from '../Constants';
import { cargoSpaceArrangement } from '../../core/Constants';

import { convertSolutionToDisplaySolution } from '../../core/common-helpers';



//////////////////////////////////////////////////////////////////////////////

const LMCPreviewDialog = (props) => {
    const { classes } = props;

    const [previewSolution, setPreviewSolution] = useState(null);

    const lmc = props.solution?.cargoSpaces[props.solution?.cargoSpaceIndex].lastMinuteChange;
    const newCargoSpaceCount = Object.keys(lmc?.cargoSpaceChanges || {})?.filter(key => lmc.cargoSpaceChanges[key].type === "New").length;
    const cargoSpaceCount = props.solution?.cargoSpaces.length + newCargoSpaceCount;

    const noPreviewPossible = !props.solution || lmc?.lmcType === lmcTypes.single || lmc?.status !== lmcStatus.ready;


    /**
     * Due to the way Threer works, we cannot just give a cargoSpace-object to it,
     * we need to make a cop of the whole solution and then replace cargo spaces
     * with the new cargo spaces we get from the LMC.
     */
    useEffect(() => {

        if (noPreviewPossible) {
            return;
        }

        let solCopy = cloneDeep(props.solution);

        // Find and replace modified cargo space if such exists (might not exist if it's fully packed - but we need to keep because of the fucking indexing breaks otherwise)
        solCopy.cargoSpaces = solCopy.cargoSpaces.map(cs => {
            const newCargoSpace = lmc?.newCargoSpaces?.find(x => x.cargoSpaceId === cs._id.toString());
            return newCargoSpace || cs;
        })

        // Add any new cargo spaces
        solCopy.cargoSpaces = solCopy.cargoSpaces.concat(Object.keys(lmc.cargoSpaceChanges)
            .filter(id => lmc.cargoSpaceChanges[id].type === "New")
            .map(x => lmc.newCargoSpaces.find(cs => cs.cargoSpaceId === x))
        );

        setPreviewSolution(convertSolutionToDisplaySolution(solCopy));

    }, [props.solution, lmc, noPreviewPossible])


    /**
     * Take the newly added cargo space that LMC has created, and check if the different arrangements
     * of that cargo space are invalid. If yes, then the arrangement selector for that arrangement
     * must be disabled.
     * @param {Number} arrangementId
     * @returns true/false depending on if arrangement is invalid or not.
     */
    const isArrangementDisabled = (arrangementId) => {

        if (props.solution == null || !props.open) {
            return;
        }

        // Get the correct cargo space from cs.lmc.newCargoSpaces
        const cargoSpace = props.solution?.cargoSpaces[props.solution.cargoSpaceIndex];
        const lmc = cargoSpace.lastMinuteChange;
        if (lmc == null || lmc.newCargoSpaces == null) return;
        const newCargoSpace = lmc.newCargoSpaces[props.index];

        // If cargo space doesn't have alternate arrangments, then only enable selector for the existing arrangement.
        if (
            !newCargoSpace.alternateArrangements ||
            newCargoSpace.alternateArrangements.length === 0
        ) {
            // If given arrangement isn't the one cargo space is using
            if (arrangementId !== newCargoSpace.selectedArrangement) {
                return true;
            }
            return false;
        };

        // Selector for standard arrangement is disabled when cargoSpace.packedParcels is empty or when cargoSpace isn't using "Standard" packingOptimizationGoal.
        if (arrangementId === 0) {
            if (
                newCargoSpace.packedParcels.length === 0 ||
                newCargoSpace.packingOptimizationGoal !== 'Standard'
            ) {
                return true;
            }
            return false;
        }

        // Selectors for alternate arrangements are disabled if altArrangement.packedParcels is null or empty.
        const altArrangement = newCargoSpace.alternateArrangements.find(aa => aa.arrangementId === arrangementId);
        if (
            !altArrangement ||
            !altArrangement.packedParcels ||
            altArrangement.packedParcels.length === 0
        ) {
            return true;
        }
        return false;
    }


    /**
     * This function checks the packingOptimizationGoal of a selected arrangement.
     * @param {Object} newCargoSpace
     * @returns cargo spaces selected arrangements packingOptimizationGoal.
     */
    const checkToggleButtonGroupValue = (newCargoSpace) => {
        if (!newCargoSpace.selectedArrangement) {
            return newCargoSpace.packingOptimizationGoal;
        } else {
            if (newCargoSpace.selectedArrangement > 0) {
                const altArrangement = newCargoSpace.alternateArrangements.find(aa => aa.arrangementId === newCargoSpace.selectedArrangement);
                return altArrangement.packingOptimizationGoal;
            }
            return newCargoSpace.packingOptimizationGoal;
        }
    }


    //////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog
            open={props.open && !noPreviewPossible}
            onClose={props.onClose}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle
                onClose={props.onClose}
            >
                {`${previewSolution?.name} cargo space ${props.index + 1}/${cargoSpaceCount} preview`}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {(props.index + 1 <= props.solution?.cargoSpaceCount) ?
                    <Paper
                        elevation={4}
                        className={classes.threerPaper}
                    >
                        <Typography
                            className={classes.threerPaperHeader}
                            variant='h6'
                        >
                            BEFORE
                        </Typography>
                        <Threer
                            solution={props.solution}
                            index={props.index}
                            slider={Number.MAX_SAFE_INTEGER}
                            zoomFactor={0.75}
                            noBorders
                        />
                    </Paper>
                    :
                    <></>
                }
                <Paper
                    elevation={4}
                    className={classes.threerPaper}
                >
                    <Typography
                        className={classes.threerPaperHeader}
                        variant='h6'
                    >
                        {
                        //TODO: This should rely on some other information because the card display (affected-new-unaffected) uses some other logic to basically identify this same situation.
                        (props.index + 1 > props.solution?.cargoSpaceCount) ?
                            'NEW CARGO SPACE' : 'AFTER'
                        }
                    </Typography>
                    <Box className={classes.threerContainer}>
                        {/* If newCargoSpace is a new one, add packing goal selector to the preview window. */}
                        {
                            (props.index + 1 > props.solution?.cargoSpaceCount) &&
                                <Box className={classes.toggleButtonContainer}>
                                    <Paper style={{ padding: '6px' }} elevation={2}>
                                        <FormHelperText style={{ marginLeft: '6px'}}>
                                            Packing optimization goal
                                        </FormHelperText>
                                        <ToggleButtonGroup
                                            value={
                                                (lmc?.newCargoSpaces && lmc?.newCargoSpaces.length > 0) ?
                                                    checkToggleButtonGroupValue(lmc?.newCargoSpaces.find(ncs => ncs.cargoSpaceId === 'NewCargoSpace')) : null
                                            }
                                        >
                                            <ToggleButton
                                                value={'Standard'}
                                                onClick={() => props.changeLMCNewCargoSpaceSelectedArrangement(props.index, cargoSpaceArrangement.standard)}
                                                disabled={isArrangementDisabled(cargoSpaceArrangement.standard)}
                                            >
                                                <Box className={classes.toggleButtonTextContainer}>
                                                    <ControlCameraIcon className={classes.toggleButtonIcon} />
                                                    Standard
                                                </Box>
                                            </ToggleButton>
                                            <ToggleButton
                                                value={'Spread'}
                                                onClick={() => props.changeLMCNewCargoSpaceSelectedArrangement(props.index, cargoSpaceArrangement.spread)}
                                                disabled={isArrangementDisabled(cargoSpaceArrangement.spread)}
                                            >
                                                <Box className={classes.toggleButtonTextContainer}>
                                                    <ZoomOutMapIcon className={classes.toggleButtonIcon} />
                                                    Spread
                                                </Box>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Paper>
                                </Box>
                        }
                        <Threer
                            solution={previewSolution}
                            parcelUpdate={previewSolution?.cargoSpaces[props.index].packedParcels}
                            index={props.index == null ? 0 : props.index}
                            slider={Number.MAX_SAFE_INTEGER}
                            zoomFactor={0.75}
                            noBorders
                            style={{ widht: '100%' }}
                        />
                    </Box>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={props.onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

};

//////////////////////////////////////////////////////////////////////////////

LMCPreviewDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    solution: PropTypes.object,
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '550px',

    },
    threerPaper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '95%',
        width: '50%'
    },
    threerPaperHeader: {
        margin: '20px'
    },
    toggleButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'fixed',
        margin: '8px'
    },
    toggleButtonTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    toggleButtonIcon: {
        fontSize: '20px',
        marginRight: '4px'
    },
    threerContainer: {
        width: '100%',
        height: '100%',
    }
});


export default withStyles(styles)(LMCPreviewDialog);
