import React from 'react'

// import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Octagon from '../core/Octagon'
import { colors } from '../theme'
import { browserHistory } from 'react-router'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'

const Home = (props) => {
    const { user, classes } = props;

    if (user.tabletMode) {
        browserHistory.push('/loadingAssistant')
    }

    return (
        <div className={classes.homeContainer}>
            <Octagon w='60rem' color={colors.octagon}>
                <div style={{ height: '5rem' }} />
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography style={{ marginBottom: '-60px', marginLeft: '75px' }} variant="h4" color="secondary">WELCOME TO</Typography>
                    <div className={classes.logoContainer} >
                        <img src='/assets/boxbot.svg' alt=" " className={classes.signInLogo} />
                    </div>
                    {user.roles.length > 0 &&
                        <div style={{ marginTop: "30px" }}>
                            <Typography variant="h6">Create a new loading plan or search from saved loading plans </Typography>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button className={classes.bigButton} color="primary" variant="contained" onClick={() => browserHistory.push('/optimizer')} >New</Button>
                                <Button className={classes.bigButton} color="secondary" variant="contained" onClick={() => browserHistory.push('/solutions')} >Saved plans</Button>
                            </div>
                        </div>
                    }
                </div>
            </Octagon>
        </div>
    )
}

const styles = theme => ({
    logoContainer: theme.containers.logo,
    signInLogo: theme.icons.signInLogo,
    bigButton: {
        width: "8rem",
        height: "3rem",
        margin: "1rem"
    },
    homeContainer: {
        height: "100%",
        maxWidth: "8rem",
        display: "inline-block"
    },
    textStyle: {
        fontSize: '1rem',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em'
    }
})


const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))

//////////////////////////////////////////////////////////////////////////////
