import React from 'react'

import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import Octagon from '../../core/Octagon'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { colors } from '../../theme'
import { resetOptimizer } from '../../optimize/OptimizeActions'
import {
    Home,
    NewPlan,
    Admin,
    LoadingPlans,
} from './NavBardComponents';

//////////////////////////////////////////////////////////////////////////////

const DesktopNav = (props) => {
    const { classes, location } = props
    const { user, unsaved, onNavigate, onResetOptimizer } = props

    let menuItems = [];
    if (user._id) {
        const getMenuItems = () => {
            const commonProps = { onNavigate };
            const itemList = [];

            itemList.push(<Home key="home" {...commonProps} />)

            // No roles
            if (!user.roles.length)
                return itemList;

            itemList.push(<NewPlan key="newPlan" onNavigate={onNavigate} onResetOptimizer={onResetOptimizer} location={location} unsaved={unsaved} />)
            itemList.push(<LoadingPlans key="loadingPlans" {...commonProps} />)

            if (user.roles?.includes('officeAdmin') || user.roles?.includes('superadmin')) {
                itemList.push(<Admin key="admin" {...commonProps} />)
            }

            return itemList;
        }

        menuItems = getMenuItems();
    }

    return (
        <div className={`${classes.container}`} >
            <Octagon z={32} color={colors.nav} contentDivStyle={{ marginLeft: "86px", marginRight: "16px" }} disableSlide>
                <img src='/assets/boxbot_hex.png' className={classes.logo} alt='' />
                <List>
                    {menuItems}
                </List>
            </Octagon>
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.drawer.open,
        user: state.user,
        unsaved: state.optimizer.unsaved
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onNavigate: pathname => {
            browserHistory.push(pathname)
        },
        onResetOptimizer: () => dispatch(resetOptimizer())
    }
}

const styles = theme => ({
    menuDivider: theme.navBar.menuDivider,
    logo: theme.navBar.logo,
    container: {
        display: 'inline-block',
        height: '100%',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DesktopNav))

//////////////////////////////////////////////////////////////////////////////
