import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Collapse from '@material-ui/core/Collapse';
import InfoIcon from '@material-ui/icons/Info';
import Threer from '../../three/Threer';
import { colors } from '../../theme';
import { Info, infoTexts } from '../InfoDialog';
import { cargoSpaceTypes } from '../../core/Constants';
import { getLoadingDirectionObj, Input, loadingDirections } from '../cargoSpace/OptimizerStorageView';
import NumberTextField from '../../core/NumberTextField';

const boldSearched = (text, search) => {
    if (!text || !search) return text;
    const reg = new RegExp(search, 'i')
    let idx = 0
    const splitted = text.split(reg).map((noMatch, i) => {
        idx += noMatch.length + search.length
        return (<span key={text + i}>{noMatch}<b>{text.substring(idx - search.length, idx)}</b></span>)
    })
    return (
        <span>
            {splitted}
        </span>
    )
}

const RenderAutoCompleteText = ({ option, search, unit, presetImages }) => {
    let img;
    if (presetImages && presetImages[option.text]) {
        img = <img src={presetImages[option.text]} alt=" " style={{ width: "auto", height: "80px" }} />
    } else {
        img = <CircularProgress color="primary" />
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span key={option} style={{ whiteSpace: "normal" }}>
                {boldSearched(option.text, search)}
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "120px", height: "80px" }}>
                    {img}
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>
                        {" "}<span>{option.x / unit.value}</span>{" "}&times;{" "}
                        <span>{option.y / unit.value}</span>{" "}&times;{" "}
                        <span>{option.z / unit.value}</span>
                    </div>
                    <div>
                        {option.weightLimit ? `${option.weightLimit} kg` : ''}
                    </div>
                </div>
                <img src={getLoadingDirectionObj(option.loadingDirection).img} alt=" " style={{ height: '50px', display: 'block', margin: '0px 0px 0px 32px' }} />
            </div>
        </div>
    )
}

/**
 * Custom RadioButton
 * @returns React.Component
 */
const CardRadioButton = ({ storage, packingDirection, onChange, disabled }) => (
    <Button
        key={packingDirection.value}
        variant="contained"
        disabled={disabled}
        value={packingDirection.value}
        style={{
            margin: '10px',
            height: '110px',
            backgroundColor: getLoadingDirectionObj(storage.loadingDirection)?.value === packingDirection.value ? colors.bbTealLighter : null
        }}
        onClick={() => onChange(packingDirection)}>
        <div>
            {packingDirection.text}
            <br />
            <img src={packingDirection.img} alt=" " style={{ height: '70px', display: 'block', margin: '10px', marginTop: '0px' }} />
        </div>
    </Button>
)
const times = <span style={{ display: 'inline-block', width: '5%', textAlign: 'center', marginTop: "27px" }}>&times;</span>;

export const CargoSpaceSelection = (props) => {
    const {
        storage,
        onChange,
        cargoSpaceUnit,
        cargoPresets,
        presetImages,
        setInfoDialog,
        openStorageDetails,
        disableChanges,
        closeButton,
        checkUnpackableParcels,
        classes,
    } = props;

    const [resetNumberFieldValues, setResetNumberFieldValues] = useState(false)
    const [validateUnpackableParclesTimeout, setValidateUnpackableParclesTimeout] = useState(null)
    const [search, setSearch] = useState("");
    const [isWeightLimited, setIsWeightLimited] = useState(storage.weightLimit > 0);
    const [treeSol, setTreeSol] = useState(null);

    useEffect(() => {
        // Clear the timeout when component is unmounted
        return () => clearTimeout(validateUnpackableParclesTimeout);
        // Intentionally only once to create "onComponentUnmount" effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTreeSol(null);
        let timeout = setTimeout(() => {
            const sol = {
                solutionId: "Cargo Space selection",
                cargoSpaces: [
                    {
                        storages: [
                            {
                                dimensions: storage.dimensions,
                                location: { x: 0, y: 0, z: 0 },
                                visualAids: storage.visualAids,
                                negativeSpaces: storage.negativeSpaces,
                                axles: storage.axles
                            }
                        ],
                        packedParcels: [],
                    }
                ]
            };
            setTreeSol(<Threer solution={sol} index={0} noBorders zoomFactor={0.8} />);
        }, 0);

        return () => clearTimeout(timeout);
    }, [storage.dimensions, storage.visualAids, storage.negativeSpaces, storage.axles]);


    const onSetPresetDimension = (v) => {
        if (!v) return;

        const storage =
        {
            dimensions: { x: v.x, y: v.y, z: v.z },
            weightLimit: v.weightLimit,
            loadingDirection: v.loadingDirection,
            negativeSpaces: v.negativeSpaces || [],
            visualAids: v.visualAids || [],
            axles: v.axles || [],
            cargoSpaceType: v.cargoSpaceType || cargoSpaceTypes.container,
            useWeightDistributionFitness: v.useWeightDistributionFitness || false,
            presetName: v.text || null
        }
        onChange(storage)
        setResetNumberFieldValues(!resetNumberFieldValues)
        setIsWeightLimited(v.weightLimit > 0);
        setSearch(v.text);
        checkUnpackableParcels(storage)
    };

    const onDimensionChange = (value, dimension) => {
        // Value is already in valid mm value
        let newStorage = {
            ...storage,
            presetName: null,
            dimensions: {
                ...storage.dimensions,
                [dimension]: value
            },
        };
        onChange(newStorage)

        // Clear previous timeouts first
        clearTimeout(validateUnpackableParclesTimeout)
        setValidateUnpackableParclesTimeout(setTimeout(() => {
            checkUnpackableParcels(newStorage);
        }, 500))
    };

    let presets;
    if (cargoPresets.length) {
        presets = (
            <Autocomplete
                // debug
                disabled={disableChanges}
                options={cargoPresets}
                inputValue={search}
                noOptionsText={`No preset found`}
                filterOptions={(options, state) => options.filter(option => option.text.toLowerCase().includes(state.inputValue.toLowerCase()))}
                renderOption={option => <RenderAutoCompleteText option={option} search={search} unit={cargoSpaceUnit} presetImages={presetImages} />}
                renderInput={params => (
                    <TextField
                        {...params}
                        className={classes.autoCompleteTxtField}
                        label="Search Presets"
                        fullWidth={true}
                        value={search}
                        onChange={e => setSearch(e.target.value)} />
                )}
                getOptionLabel={option => option.text}
                onChange={(e, value) => onSetPresetDimension(value)}
                className={classes.autoComplete} />
        );
    }

    const weightLimitSection = (
        <div>
            <div className={classes.subheader}>Weight limit:</div>
            <Info info={infoTexts.weightLimit} onClick={setInfoDialog} />
            <br />
            <div className={classes.listItem}>
                <FormGroup className={classes.noLeftPadding}>
                    <FormControlLabel
                        style={{ marginLeft: "1px", color: colors.grey }}
                        disabled={disableChanges}
                        control={<Switch
                            checked={isWeightLimited}
                            color="primary"
                            onChange={() => {
                                setIsWeightLimited(!isWeightLimited);
                                storage.weightLimit = 0;
                                onChange(storage);
                            }} />}
                        label={"Enable Weight Limit"} />
                    <Collapse in={isWeightLimited} style={{ marginBottom: "8px" }}>
                        <NumberTextField
                            disabled={disableChanges}
                            unit={1}
                            label="Weight Limit (kg)"
                            value={storage.weightLimit || ''}
                            onChange={value => onChange({ ...storage, weightLimit: value, presetName: null })}
                            resetValue={resetNumberFieldValues}
                            acceptZero
                        />
                    </Collapse>
                </FormGroup>
            </div>
        </div>
    );


    const onLoadingDirectionChange = newValue => {
        let newStorage = { ...storage, loadingDirection: newValue, presetName: null };

        // Validate unpackable parcles only when the first loadingDirection is set
        if (!storage.loadingDirection) {
            checkUnpackableParcels(newStorage)
        }

        onChange(newStorage);
    }

    return (<>
        <Paper elevation={4}>
            <Input label='Presets:' info={<Info info={infoTexts.presets} onClick={setInfoDialog} />}>
                {presets}
            </Input>
        </Paper>
        <Paper elevation={4}>
            <Input label='Dimensions:'
                info={<IconButton onClick={() => setInfoDialog({ content: <div><img src='/assets/container_dimensions-alt.png' alt=" " style={{ paddingLeft: '4.5rem', paddingBottom: '3.25rem' }} /></div> })}>
                    <InfoIcon style={{ color: colors.bbTeal, paddingLeft: "0px" }} />
                </IconButton>}>
                <fieldset disabled={disableChanges} className={classes.fieldset}>
                    <NumberTextField unit={cargoSpaceUnit.value} resetValue={resetNumberFieldValues} label={`Length (${cargoSpaceUnit.text})`} value={storage.dimensions.x} onChange={value => onDimensionChange(value, "x")} style={{ width: '7rem' }} />{times}
                    <NumberTextField unit={cargoSpaceUnit.value} resetValue={resetNumberFieldValues} label={`Width (${cargoSpaceUnit.text})`} value={storage.dimensions.y} onChange={value => onDimensionChange(value, "y")} style={{ width: '7rem' }} />{times}
                    <NumberTextField unit={cargoSpaceUnit.value} resetValue={resetNumberFieldValues} label={`Height (${cargoSpaceUnit.text})`} value={storage.dimensions.z} onChange={value => onDimensionChange(value, "z")} style={{ width: '7rem' }} />
                </fieldset>
            </Input>
            <div style={{ marginTop: '-7rem', marginRight: "1rem", float: 'right' }}>
                <Button variant="outlined" color="secondary" onClick={openStorageDetails}>Details</Button>
            </div>
            <div style={{ float: "right", width: "350px", height: "150px", margin: "0px 0px -10px 0px" }}>
                {treeSol}
            </div>
            {weightLimitSection}
            <Input label='Packing Direction:'>
                {Object.keys(loadingDirections).map(ld =>
                    <CardRadioButton key={ld} storage={storage} packingDirection={loadingDirections[ld]} disabled={disableChanges}
                        onChange={(pd) => onLoadingDirectionChange(pd.strValue)}
                    />
                )}
            </Input>
        </Paper>
        {closeButton || null}
    </>);
};
