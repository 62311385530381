import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import DialogTitle from '../core/DialogTitle'
import Octagon from '../core/Octagon'

import { withStyles } from '@material-ui/core/styles'

import FeatureEnabler from "../app/FeatureEnabler"
import InfoDialog, { infoTexts, Info } from './InfoDialog'
import OptimizerHeader from './OptimizerHeader'
import { usePreviewThreer } from '../three/SolutionPreviewThreer'
import { solutions as solutionsApi } from '../api'
import { colors } from '../theme'


const packingStatusLookup = {
    0: 'Undefined', //TODO add Initialized status
    1: 'Ready for loading',
    2: 'In Progress',
    3: 'Loaded'
}

const OptimizerLoadingView = (props) => {

    const {
        solution,
        onSendToLoading,
        setSolution,
        classes
    } = props

    const [dialogOpen, setDialogOpen] = useState(false)
    const [infoText, setInfoText] = useState(null);


    const previewImages = usePreviewThreer(solution)

    const allSentToLoading = solution && solution.cargoSpaces.every(cs => cs.packingStatus && cs.packingStatus > 0)
    const allPacked = solution && solution.cargoSpaces.every(cs => cs.packingStatus && cs.packingStatus === 3)

    const loadingPlanStatus = allPacked ? "Completed" : "In progress";

    const id = solution._id


    useEffect(() => {
        const service = solutionsApi.service()
        const onPatchedListener = data => {
            if (data._id === id) {
                setSolution(data)
            }
        }
        // Subscribe to loadingAssistant 'patched' event
        // 'patched' event is triggered because this user is joined in the organization channel
        service.on('patched', onPatchedListener)
        return () => {
            // Remove event listener
            service.removeListener('patched', onPatchedListener)
        }
        // Only once on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const sendToLoading = () => {
        onSendToLoading(solution)
        setDialogOpen(false)
    }


    const Preview = ({ index }) => {
        let img;
        if (previewImages && previewImages[index]) {
            img = <img src={previewImages[index]} alt={`solution preview ${index}`} style={{ height: "100%", width: "auto", alignSelf: "center" }} />
        } else {
            img = <CircularProgress disableShrink color="primary" style={{ alignSelf: "center" }} />
        }
        return <div style={{ height: "100%", width: "100%", display: "flex", alignContent: "center", justifyContent: "center" }}>
            {img}
        </div>
    }


    return (
        <Octagon z={props.z} w='65rem' color={colors.octagon} isOpen={props.octagonOpen}>
            <div className={classes.octagonContainer}>
                <div className={classes.viewContainer}>
                    <OptimizerHeader progress={props.progress} />
                    <FeatureEnabler featureId='loadingAssistant'>
                        {(allSentToLoading || allPacked) &&
                            <Typography variant="h6" style={{ display: "flex", margin: "8px", justifyContent: "center" }} className={classes.bolded}>
                                {`Loading status: ${loadingPlanStatus}`}
                            </Typography>}
                        <Paper style={{ width: "100%", margin: '32px 0 32px', height: `calc(100% - 143px)` }}>
                            {solution && (
                                !allSentToLoading ?
                                    <div className={classes.centered}>
                                        <div className={classes.innerCenter}>
                                            <h3 className={classes.headerLoadingPlanText}>Loading Assistant</h3>
                                            <Button size="large" variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>Send to Loading</Button>
                                        </div>
                                    </div>
                                    : <TableContainer style={{ height: "100%" }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell className={classes.header} colSpan={2}>{'Cargo Spaces'}</TableCell>
                                                    <TableCell className={classes.header}>Status</TableCell>
                                                    <TableCell className={classes.header} colSpan={2} >Loading Progress</TableCell>
                                                    <TableCell className={classes.header} style={{ padding: '0 2rem' }} align='right' >Parcels</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {solution.cargoSpaces.map((cs, i) => (
                                                    <TableRow key={i} hover>
                                                        <TableCell >{`${i + 1} / ${solution.cargoSpaces.length}`}</TableCell>
                                                        <TableCell padding="none" style={{ width: "9rem", height: "7rem" }}>
                                                            <div style={{ position: "relative", height: "7rem" }}>
                                                                {cs.storages && <Preview index={i} />}
                                                            </div>
                                                        </TableCell >
                                                        <TableCell >{packingStatusLookup[cs.packingStatus]}</TableCell>
                                                        <TableCell align='right' width='5%' style={{ padding: '16px 0px 16px 16px' }} >{`${cs.progress || 0} %`}</TableCell>
                                                        <TableCell width='50%'>
                                                            <div style={{ position: 'relative' }}>
                                                                < LinearProgress
                                                                    className={classes.progress}
                                                                    color="secondary"
                                                                    variant="determinate"
                                                                    value={cs.progress || 0}
                                                                    hidden={cs.progress === 100 || cs.forcedToCompletion}
                                                                />
                                                                {cs.progress === 100 &&
                                                                    <Typography className={`${classes.progressDone} ${classes.completedTextStyle}`}>
                                                                        COMPLETED
                                                                    </Typography>
                                                                }
                                                                {cs.forcedToCompletion &&
                                                                    <Typography className={`${classes.progressForcedDone} ${classes.completedTextStyle}`} >
                                                                        FORCED TO COMPLETE
                                                                        <Info
                                                                            info={infoTexts.forcedToCompletion}
                                                                            onClick={setInfoText}
                                                                            style={{ color: colors.bbOrange }}
                                                                        />
                                                                    </Typography>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.bolded} style={{ padding: '0 2rem' }} align='right' >
                                                            {`${cs.packedParcels.reduce((tot, p) => tot + p.loaded, 0) || 0}/${cs.packedParcels.length}`}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            )}
                        </Paper>
                        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                            <DialogTitle onClose={() => setDialogOpen(false)}>Send plan to loading?</DialogTitle>
                            <DialogContent>
                                <DialogContentText component='div'>
                                    <ul>
                                        <li>The loading plan will be locked. Only last minute changes will be allowed.</li>
                                        <li>The loading tasks will be sent forward and the loading operations can be started.</li>
                                    </ul>
                                    <br/>
                                </DialogContentText>
                                < DialogContentText className={classes.bolded} >This action can not be undone!</ DialogContentText>
                            </DialogContent>
                            <br />
                            <DialogActions>
                                <Button onClick={sendToLoading} color='primary' variant='contained'>OK</Button>
                            </DialogActions>
                        </Dialog>
                        <InfoDialog
                            info={infoText}
                            onClose={() => setInfoText(null)}
                        />
                    </FeatureEnabler>
                </div>
            </div>
        </Octagon >
    )
}

const styles = theme => ({
    octagonContainer: theme.containers.octagonChild,
    viewContainer: theme.optimizer.viewContainer,
    header: {
        fontWeight: "bold",
        background: theme.palette.common.white,
    },
    bolded: { fontWeight: "bold" },
    completedTextStyle: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    progress: { height: "1.5rem" },
    progressDone: {
        color: colors.green,
        fontWeight: "bold"
    },
    progressForcedDone: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    centered: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    innerCenter: {
        width: "300px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    headerLoadingPlanText: theme.optimizer.headerLoadingPlanText,
})

export default withStyles(styles)(OptimizerLoadingView)
