import SceneManager from './SceneManager';
import { colors } from '../theme'
import { convertYZ } from '../core/Constants';


/**
 * @param {ref} [containerElement] - Reference to the container element that the three canvas will be added. If not provided, the three canvas is added to document body.
 * @param {boolean} [noBorders] - Whether to add border to the three canvas.
 * @param {{ height: number, width: number}} [style] - When style is given, canvas is set to fixed size. Otherwise canvas is set to use 100% of available width and height and is adjusted when window resizes. When style is given, preventResize must also be set to true.
 * @param {boolean} [preventResize] - Explicit boolean to prevent canvas from being resized when window resizes.
 *
 * @param {number} [zoomFactor] - Adjusts the camera default zoom. Passed directly to SceneManager. Smaller value means camera is closer. Defaults to 1.
 * @param {number} [loadingDirection] - Loading direction information passed to SceneManager.CameraHelper
 */
export default (options) => {
    const { containerElement, noBorders, style, preventResize = false } = options;
    const canvas = createCanvas(document, containerElement, noBorders, style);
    const sceneManager = new SceneManager({
        ...options,
        canvas: canvas,
    });

    if (options.style && !options.preventResize) {
        console.error(
            "ThreeEntryPoint is given both style and preventResize arguments." +
            "If Three usage is for creating images, preventResize should be set to true to avoid view port changes." +
            "PreventResize shouldn't be used in other use cases than image creation."
        )
    }

    let presetCanvasId;

    bindEventListeners();
    render();

    function createCanvas(document, containerElement, noBorders, style) {
        const canvas = document.createElement('canvas');
        if (!noBorders) {
            canvas.style.border = '4px solid ' + colors.bbTealLighter
        }
        canvas.style.outline = "none"

        // document.addEventListener('mousedown', onMouseMove, false) // NOTE: this causes issues with Input elements focus !
        // canvas.onclick = (e) => {
        //     console.log("CANVAS CLICK ", e)
        // }
        // canvas.addEventListener("click", (e) => {
        //     console.log("CLICK ", e)
        //     onMouseMove(e)
        // })

        if (style) {
            if (typeof style.width !== "number" || typeof style.height !== "number")
                throw new Error("Canvas style requires both width and height to be integers");

            canvas.style.width = style.width;
            canvas.style.height = style.height;
            canvas.width = style.width;
            canvas.height = style.height;
            canvas.style.zIndex = -1000;
            canvas.style.marginLeft = "-5000px";
            canvas.style.position = "fixed";
        }

        // No container element, create one!
        if (!containerElement) {
            presetCanvasId = `ThreeCanvas${new Date().valueOf() * 1000 + Math.random() * 1000}`;
            canvas.id = presetCanvasId;

            document.body.appendChild(canvas)
        } else {
            containerElement.appendChild(canvas);
        }

        return canvas;
    }

    function bindEventListeners() {
        if (preventResize) return;
        window.onresize = resizeCanvas;
        resizeCanvas();
    }

    // function onMouseMove(event) {
    //     event.preventDefault();
    //     if (event.ctrlKey) sceneManager.swapCamera();
    //     sceneManager.onMouseMove(event);
    // }

    function resizeCanvas({ width, height } = {}) {
        if (options.style && (!width || !height)) {
            throw new Error(
                "Do not call resizeCanvas when canvas is given a fixed size - this is a no-op.");
        }

        canvas.style.width = width ? width : '100%';
        canvas.style.height = height ? height : '100%';
        canvas.width = width ? width : canvas.offsetWidth;
        canvas.height = height ? height : canvas.offsetHeight;
        sceneManager.onWindowResize();
    }

    function clear() {
        return sceneManager.clear();
    }

    function addBox(pos, dim, spaceDim, parcel) {
        sceneManager.addBox(pos, dim, spaceDim, parcel);
    }

    function addSpaceDimensions(pos, dim, expandedDim) {
        sceneManager.addSpaceDimensions(pos, dim, expandedDim)
    }

    function setViewByBounds(x, y, z) {
        sceneManager.setViewByBounds(x, y, z);
    }

    function drawCargoSpace(solution, index, previewCargoSpace) {
        if (!solution.cargoSpaces[index]) return

        const cargoSpace = solution.cargoSpaces[index];

        let xMax = 0;
        let zMax = 0;
        let yMax = 0;
        cargoSpace.storages.forEach(storage => {
            const dimFactor = 1.0078125;
            let expandedDimensions = Object.assign({}, storage.dimensions);
            expandedDimensions.x *= dimFactor;
            expandedDimensions.y *= dimFactor;
            expandedDimensions.z *= dimFactor;

            let xHalf = 0.5 * storage.dimensions.x;
            let zHalf = 0.5 * storage.dimensions.z;
            let yHalf = 0.5 * storage.dimensions.y;
            xMax = Math.max(xMax, storage.location.x - xHalf, storage.location.x + xHalf);
            zMax = Math.max(zMax, storage.location.z - zHalf, storage.location.z + zHalf);
            yMax = Math.max(yMax, storage.location.y - yHalf, storage.location.y + yHalf);

            expandedDimensions = convertYZ(expandedDimensions);
            addSpaceDimensions(convertYZ(storage.location), convertYZ(storage.dimensions), expandedDimensions);

            if (previewCargoSpace) {
                sceneManager.createPresetSpace(convertYZ(storage.location), expandedDimensions);
            } else {
                sceneManager.addSpace(
                    convertYZ(storage.location),
                    expandedDimensions,
                    storage.negativeSpaces || [],
                    storage.visualAids || [],
                    storage.axles || []
                );
            }

            cargoSpace.packedParcels
                .forEach(parcel => { // Parcels are sorted in loading order by default
                    if (parcel.storageId !== storage.id) {
                        return;
                    }
                    addBox(parcel.location, parcel.packedDimensions, storage.dimensions, parcel);
                });
        });
        setViewByBounds(2 * xMax, 2 * yMax, 2 * zMax);
    }

    function render(time) {
        //HACK: This timeout is a temporary hack, see issue #664
        setTimeout(() => {
            requestAnimationFrame(render);
            sceneManager.update();
        }, 15)
    }

    function updateParcels(current, slider, loadingAssistantSol) {
        return sceneManager.updateParcels(current, slider, loadingAssistantSol)
    }

    function exportThree(clearScene = true) {
        sceneManager.update()

        const name = "image/png"
        const imgData = canvas.toDataURL(name, 1)

        if (clearScene)
            sceneManager.clear()

        return imgData
    }

    function setCameraFoVForExport(aspectRatio, axles) {
        let fov = 22
        // Adapt field of view and aspect based on cargo space dimensions
        if (aspectRatio < 3) fov = 30
        if (aspectRatio < 1.5) {
            fov = 37
            resizeCanvas({ width: 940, height: 1200 })
        }
        sceneManager.setFieldOfView(fov)

        if (aspectRatio > 1.5) sceneManager.translateCamera({ x: 1000 })
        if (axles.length > 0) sceneManager.translateCamera({ y: -420 })
        sceneManager.update()
    }

    function exportThree2(aspectRatio = 5, axles = []) {
        setCameraFoVForExport(aspectRatio, axles)
        const imgData1 = canvasToDataURL(canvas)

        sceneManager.swapCamera()
        sceneManager.update()
        const imgData2 = canvasToDataURL(canvas)

        sceneManager.clear()

        return [imgData1, imgData2]
    }

    // TODO Check for disposals !!!
    // Export canvas as jpeg data url, adding a background color.
    // https://stackoverflow.com/questions/34508639/canvas-with-png-alpha-channel-to-jpeg-dataurl
    function canvasToDataURL(canvas) {
        // use cloneNode(true) to get the same width/height as your previous canvas
        var exporter = canvas.cloneNode(true);
        var ctx = exporter.getContext('2d');
        // first fill set our background
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, exporter.width, exporter.height);
        // yes you can draw a canvas onto a canvas
        ctx.drawImage(canvas, 0, 0);
        var dataURL = exporter.toDataURL('image/jpeg');
        return dataURL;
    }

    function dispose() {
        // document.removeEventListener('mousedown', onMouseMove)
        // Remove the canvas from document.body, otherwise these would stack whenever new preset threer images are created
        if (presetCanvasId)
            document.body.removeChild(document.getElementById(presetCanvasId));
        sceneManager.dispose()
    }

    function updateCameraView(parcel, highLighted) {
        sceneManager.updateCameraView(parcel, highLighted)
    }

    function reconstructParcels(parcels) {
        sceneManager.reconstructParcels(parcels)
    }

    function addParcelsStepByStep(parcels) {
        return sceneManager.addParcelsStepByStep(parcels);
    }

    function grayScaleParcels(parcels) {
        sceneManager.grayScaleParcels(parcels);
    }

    function setCameraForStepByStep(aspectRatio = 5, axles = []) {
        setCameraFoVForExport(aspectRatio, axles);
        if (aspectRatio > 1.5) sceneManager.translateCamera({ x: 1000 })
        sceneManager.setCameraForStepByStep();
    }

    function resetCamera() {
        // TODO Does the camera reset for overView + stepByStep for multiple cargo spaces ?
        sceneManager.resetCamera();
    }

    function enableManipulationControlsOnParcel(id, direction) {
        sceneManager.addArrowsToParcel(id, direction);
    }

    function applyParcelEditModeHighlight(id) {
        sceneManager.applyParcelEditModeHighlight(id);
    }

    function updateParcelInfos(parcels) {
        sceneManager.updateParcelInfos(parcels);
    }

    function zoomToParcel(parcelId, direction) {
        sceneManager.zoomToParcel(parcelId, direction);
    }

    function applyCompletedHighlight() {
        sceneManager.applyCompletedHighlight();
    }

    return {
        updateParcelInfos,
        applyParcelEditModeHighlight,
        enableManipulationControlsOnParcel,
        applyCompletedHighlight,
        clear,
        addBox,
        addSpaceDimensions,
        setViewByBounds,
        updateParcels,
        resizeCanvas,
        exportThree,
        exportThree2,
        updateCameraView,
        reconstructParcels,
        drawCargoSpace,
        addParcelsStepByStep,
        grayScaleParcels,
        setCameraForStepByStep,
        resetCamera,
        dispose,
        zoomToParcel
    };
}
