import React, { useEffect, useState } from 'react';

import startCase from 'lodash.startcase';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import EventIcon from '@material-ui/icons/Event';
import WarningIcon from '@material-ui/icons/Warning';

import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

//////////////////////////////////////////////////////////////////////////////

import Toolbar from '../../core/Toolbar';
import Octagon from '../../core/Octagon';
import Checkbox from '../../core/Checkbox';
import BackDialog from '../../core/BackDialog';
import { colors } from '../../theme';
import { notificationDialogSeverity } from '../../core/Constants';

import AdminOrganizationEditHeader from './AdminOrganizationEditHeader';
import { showNotificationDialog } from '../../app/AppActions';
import { setEditOrganization, closeBackDialog, openBackDialog } from '../AdminActions';
import { organizations } from '../../api';
import { defaultOrganization } from '../adminReducer';
import { momentDateFormat } from '../../core/dateUtils';
import SignUpEnabler from '../../core/SignUpFeature';

//////////////////////////////////////////////////////////////////////////////

const roleItems = [
    {
        name: 'officeUser',
        description: 'Organization',
    },
    {
        name: 'officeAdmin',
        description: 'Manage Users',
    },
    {
        name: 'loadingOperationsUser',
        description: 'Tablet user'
    },
    {
        name: 'superadmin',
        description: 'All Capabilities',
    },
]

const featureItems = ['loadingAssistant', 'cargoSpaceType', 'optimizerRules']


const OrganizationsEdit = (props) => {
    const { user, editOrganization, backDialogOpen, onConfirmGoBack, onCloseBackDialog, onGoBack, onSetOrganization, onUpdateOrganization, params, classes } = props
    const [showWarning, setShowWarning] = useState(false)

    /**
     * Former componentWillMount / DidMount etc are replaced with reacts useEffect function
     * See: https://reactjs.org/docs/hooks-effect.html for further information about using the Effect Hook
    */
    useEffect(() => {
        onSetOrganization(params.id)
    }, [onSetOrganization, params.id]) // useEffect will be called at the initial rendering and whenever 'onSetOrganization' or 'params.id' change



    const generalSectionHelperText = editOrganization.error === 1 ? "Name cannot be empty" : editOrganization.error === 2 ? "An organization with an identical name already exists" : null;
    const GeneralSection = (
        <form onSubmit={event => event.preventDefault()}>
            <Typography variant={'subtitle1'} >
                <List ></List>
            </Typography>
            <div className={classes.textFieldWrapper} >
                <TextField
                    error={editOrganization.error === 1 || editOrganization.error === 2}
                    id='name'
                    label='Name'
                    fullWidth={true}
                    value={editOrganization.name}
                    onChange={e => {
                        editOrganization.name = e.target.value;
                        editOrganization.modified = true;
                        delete editOrganization.error
                        onUpdateOrganization(editOrganization)
                    }}
                    type='text'
                    helperText={generalSectionHelperText}
                />
            </div>
        </form>
    )

    const roleListItems = roleItems.filter(role => role.name !== 'superadmin');
    const roles = (
        <List subheader={<ListSubheader disableSticky>Allowed User Roles</ListSubheader>}>
            {roleListItems.map(role => {
                let disabled = user.email === editOrganization.email && user.roles?.includes('superadmin') && role.name === 'superadmin';
                // If organization doesn't have 'loadingAssistant' as enabled feature, disable the toggling
                if (!editOrganization.enabledFeatures.includes('loadingAssistant'))
                    disabled = disabled || role.name === 'loadingOperationsUser';

                const checked = editOrganization.allowedRoles.includes(role.name)
                return <Checkbox key={role.name} disabled={disabled} checked={checked} label={startCase(role.name)} secondary={role.description}
                    onChange={event => {
                        const roles = editOrganization.allowedRoles.slice()
                        if (event.target.checked) {
                            roles.push(role.name)
                        } else {
                            roles.splice(roles.indexOf(role.name), 1);
                        }
                        editOrganization.allowedRoles = roles
                        editOrganization.modified = true
                        onUpdateOrganization(editOrganization)
                    }} />
            })}
        </List>
    )

    const googleDisabled = true;
    const allowGoogle = user.roles.includes('superadmin') && (
        <List subheader={<ListSubheader disableSticky>Google Login</ListSubheader>}>
            <Checkbox key='allowGoogleLogin' disabled={googleDisabled} checked={editOrganization.allowGoogleLogin} label={'Allow Google Login'} secondary={'Enable Signing In With Google Account'}
                onChange={() => {
                    editOrganization.allowGoogleLogin = !editOrganization.allowGoogleLogin
                    editOrganization.modified = true
                    onUpdateOrganization(editOrganization)
                }} />
            {editOrganization.allowGoogleLogin &&
                <ListItem disabled={googleDisabled}>
                    <TextField
                        id='domain'
                        label='Google Domain'
                        value={editOrganization.googleDomain || ""}
                        disabled={googleDisabled}
                        type='text'
                        onChange={e => {
                            editOrganization.googleDomain = e.target.value
                            editOrganization.modified = true;
                            onUpdateOrganization(editOrganization)
                        }}
                    />
                </ListItem>}
        </List>
    )


    const isUserCountLimitError = editOrganization.error === 3;
    const userCountHelperText = isUserCountLimitError ? "Must be a number" : "0 is interpreted as unlimited"
    const userCountDisabled = !user.roles.includes('superadmin');
    const userCountLimit = (
        <List subheader={<ListSubheader disableSticky>User Count Limit</ListSubheader>}>
            <ListItem>
                <TextField
                    className={classes.numberTextField}
                    id='officeUserCountLimit'
                    label='Office User Count Limit'
                    error={isUserCountLimitError}
                    disabled={userCountDisabled}
                    value={editOrganization.officeUserCountLimit}
                    type='number'
                    helperText={userCountHelperText}
                    onChange={e => {
                        if (isUserCountLimitError)
                            delete editOrganization.error;

                        editOrganization.officeUserCountLimit = e.target.value;
                        editOrganization.modified = true;
                        onUpdateOrganization(editOrganization);
                    }}
                />
                <TextField
                    className={classes.numberTextField}
                    style={{ marginLeft: 30 }}
                    id='loadingOperationsUserCountLimit'
                    label='Loading Operations User Count Limit'
                    error={isUserCountLimitError}
                    disabled={userCountDisabled}
                    value={editOrganization.loadingOperationsUserCountLimit}
                    type='number'
                    helperText={userCountHelperText}
                    onChange={e => {
                        if (isUserCountLimitError)
                            delete editOrganization.error;

                        editOrganization.loadingOperationsUserCountLimit = e.target.value;
                        editOrganization.modified = true;
                        onUpdateOrganization(editOrganization);
                    }}
                />
            </ListItem>
        </List>
    )


    const superAdminDisabled = user.organization._id === editOrganization._id;
    const warnText = showWarning ? 'Superadmins have FULL access to all organizations and their details.' : null
    const secondWarnText = showWarning ? 'Check this only if you know what you are doing.' : null
    const superadmin = user.roles.includes('superadmin') && (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <List subheader={<ListSubheader disableSticky>Superadmin</ListSubheader>}>
                <Checkbox key={'AllowSuperAdminPrivileges'} disabled={superAdminDisabled} checked={editOrganization.allowSuperadmin} label={startCase('Allow superadmin privileges')} secondary={startCase('All capabilities')}
                    onChange={() => {
                        if (!editOrganization.allowSuperadmin) setShowWarning(true)
                        else setShowWarning(false)
                        editOrganization.allowSuperadmin = !editOrganization.allowSuperadmin
                        editOrganization.modified = true
                        onUpdateOrganization(editOrganization)
                    }} />
            </List>
            <List style={{ color: colors.red }}>
                {editOrganization.allowSuperadmin && editOrganization.modified && !superAdminDisabled
                    &&
                    <ListItem>
                        <ListItemIcon >
                            <WarningIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary={warnText} secondary={secondWarnText} secondaryTypographyProps={{ color: "error" }} />
                    </ListItem>
                }
            </List>
        </div>
    )


    const features = user.roles.includes('superadmin') && (
        <List subheader={<ListSubheader disableSticky>Enabled Features</ListSubheader>}>
            {featureItems.map(feature => {
                const disabled = false
                const checked = editOrganization.enabledFeatures ? editOrganization.enabledFeatures.includes(feature) : false
                return <Checkbox key={feature} disabled={disabled} checked={checked} label={startCase(feature)}
                    onChange={event => {
                        const features = editOrganization.enabledFeatures ? editOrganization.enabledFeatures.slice() : []
                        if (event.target.checked) {
                            features.push(feature)
                        } else {
                            features.splice(features.indexOf(feature), 1);

                            // Remove loadingOperationsUser if loadingAssistantFeature was removed
                            if (feature === 'loadingAssistant')
                                editOrganization.allowedRoles = editOrganization.allowedRoles.filter(x => x !== 'loadingOperationsUser')
                        }
                        editOrganization.enabledFeatures = features
                        editOrganization.modified = true
                        onUpdateOrganization(editOrganization)
                    }} />
            })}
        </List>
    )

    const freeTrial = user.roles.includes('superadmin') && (
        <SignUpEnabler>
            <List subheader={<ListSubheader disableSticky>Free Trial</ListSubheader>}>
                <Checkbox key={'freeTrial'} checked={editOrganization.isTrial} label={startCase('Trial')}
                    onChange={event => {
                        editOrganization.isTrial = event.target.checked;
                        // When trial is toggled as True, set the trial to default value
                        if (editOrganization.isTrial) {
                            editOrganization.trialExpires = moment().add(14, 'days')
                        }
                        // When trial is toggled as False, reset expiration date to the current date
                        if (!editOrganization.isTrial) {
                            editOrganization.trialExpires = moment();
                        }

                        editOrganization.modified = true;
                        onUpdateOrganization(editOrganization);
                    }} />
                <div style={{ display: 'flex' }}>
                    <ListItem style={{ width: '33%' }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                disabled={!editOrganization.isTrial}
                                label={startCase('Trial Expires')}
                                // moment format
                                format={momentDateFormat}
                                value={editOrganization.trialExpires}
                                onChange={(newDate, _) => {
                                    editOrganization.trialExpires = newDate.endOf('day');
                                    editOrganization.modified = true;
                                    onUpdateOrganization(editOrganization);
                                }}
                                InputProps={{
                                    endAdornment: <EventIcon />
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </ListItem>
                    {/* Show a warning about the trial expiry date being in the past */}
                    <List style={{ color: colors.red, alignSelf: 'flex-end' }}>
                        {editOrganization.isTrial && editOrganization.trialExpires.isBefore(moment()) &&
                            <ListItem>
                                <ListItemIcon >
                                    <WarningIcon color='error' />
                                </ListItemIcon>
                                <ListItemText primary={'Trial expiration date is in the past'} />
                            </ListItem>
                        }
                    </List>
                </div>
            </List>
        </SignUpEnabler>
    )


    var toolBarTitle = editOrganization._id == null ? 'Create Organization' : 'Edit Organization'
    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title={toolBarTitle}
                onGoBack={() => onGoBack(editOrganization.modified)}>
                <BackDialog backDialogOpen={backDialogOpen} onConfirmGoBack={onConfirmGoBack} onCloseBackDialog={onCloseBackDialog} />
                <AdminOrganizationEditHeader />
            </Toolbar>
            <div className={classes.adminContainer} >
                <Paper elevation={4}>
                    {GeneralSection}
                    <Divider />
                    {roles}
                    <Divider />
                    {superadmin}
                    <Divider />
                    {allowGoogle}
                    <Divider />
                    {userCountLimit}
                    <Divider />
                    {features}
                    <Divider />
                    {freeTrial}
                </Paper>
            </div>
        </Octagon>
    )

}

//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
    return {
        user: state.user,
        editOrganization: state.admin.editOrganization,
        backDialogOpen: state.admin.backDialogOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetOrganization: (id) => {
            if (!id) {
                const defaultOrg = defaultOrganization();
                dispatch(setEditOrganization(defaultOrg));
                return;
            }

            organizations.get(id)
                .then(result => {
                    dispatch(setEditOrganization(result))
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })

        },
        onUpdateOrganization: (organization) => {
            dispatch(setEditOrganization(organization))
        },
        onConfirmGoBack: () => {
            dispatch(closeBackDialog())
            browserHistory.push('/admin/organizations')
        },
        onCloseBackDialog: () => {
            dispatch(closeBackDialog())
        },
        onGoBack: (modified) => {
            if (modified) {

                dispatch(openBackDialog())
            } else {
                browserHistory.push('/admin/organizations')
            }
        },
    }
}

const styles = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    numberTextField: theme.numberTextField,
    adminContainer: theme.containers.admin,
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationsEdit))

//////////////////////////////////////////////////////////////////////////////
