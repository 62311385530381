import React from 'react'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { hideOverlay } from './AppActions'

//////////////////////////////////////////////////////////////////////////////
const styles = {
    backdrop: {
        zIndex: 1201,
        color: '#fff',
        flexDirection: 'column'
    }
}
export function Overlay(props) {
    const { text, progress, open } = props;

    const hasProgressValue = typeof progress === 'number';

    return <Backdrop style={styles.backdrop} open={open}>
        <p>{text}</p>
        <Box position="relative" display="inline-flex">
            <CircularProgress color="inherit" variant={hasProgressValue ? 'static' : 'indeterminate'} value={progress} />
            {hasProgressValue &&
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" >{`${Math.round(
                        progress,
                    )}%`}</Typography>
                </Box>}
        </Box>
    </Backdrop>
}



const mapStateToProps = (state) => {
    return {
        text: state.overlay.text,
        progress: state.overlay.progress,
        open: state.overlay.open,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onHide: () => dispatch(hideOverlay()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay)
