import React from 'react'

import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';

import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import WarningIcon from '@material-ui/icons/Warning'

import DialogTitle from '../../core/DialogTitle'
import { tabletTheme } from '../../theme'

import PropTypes from 'prop-types'

//////////////////////////////////////////////////////////////////////////////

export const LMCFailedDialog = (props) => {

    const { classes } = props;


    return (
        <Dialog open={props.open} maxWidth='md' fullWidth >
            <div style={{ display: "flex", alignItems: "center" }}>
                <WarningIcon style={{ color: "orange", height: "40px", width: "40px", marginRight: '4px', marginLeft: "16px" }} />
                <DialogTitle onClose={props.onClose} style={{ paddingLeft: '0px'}}>
                    All cargo items did not fit into this cargo space
                </DialogTitle>
            </div>


            <DialogContent>
                <DialogContentText>
                    Do you want to allow changes to other cargo spaces in order to find a placement for all cargo items?
                </DialogContentText>
                <DialogContentText component='div'>
                    <ul>
                        <li>Cargo items will be moved between cargo spaces.</li>
                        <li>Already loaded cargo items will remain untouched.</li>
                        <li>A completely new cargo space may also be added automatically if required.</li>
                    </ul>
                </DialogContentText>
                <DialogContentText>
                    Once the replanning is completed a summary with previews will be available.
                    You will be prompted to confirm or reject the updated loading plan.
                    No changes will be saved before the updated loading plan is confirmed.
                </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                {/* CANCEL-BUTTON */}
                <ButtonBase
                    className={classes.paperButtonContainer}
                    onClick={props.onClose}
                >
                    <Paper
                        className={classes.paperButton}
                        elevation={6}
                    >
                        <Typography className={classes.paperButtonHeader + ' ' + classes.turquoise}>
                            Cancel
                        </Typography>
                        <Typography className={classes.paperButtonText}>
                            Reset all changes.
                        </Typography>
                    </Paper>
                </ButtonBase>

                {/* OR-text between the buttons */}
                <Paper
                    className={classes.orContainer}
                    elevation={0}
                >
                    <Typography className={classes.orContainerText}>OR</Typography>
                    <SwapHorizIcon fontSize='large' classes={{ fontSizeLarge: classes.orContainerIcon }}
                    />
                </Paper>

                {/* RECALCULATE-BUTTON */}
                <ButtonBase
                    className={classes.paperButtonContainer}
                    onClick={props.onCalculate}
                >
                    <Paper
                        className={classes.paperButton}
                        elevation={6}
                    >
                        <Typography className={classes.paperButtonHeader + ' ' + classes.orange}>
                            Allow
                        </Typography>
                        <Typography className={classes.paperButtonText}>
                            Allow changes to other cargo spaces.
                        </Typography>
                    </Paper>
                </ButtonBase>
            </DialogActions>
        </Dialog>
    )
}

LMCFailedDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCalculate: PropTypes.func.isRequired,

}


//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '25px',
        height: '180px'
    },
    paperButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '275px',
        height: '100%',
        margin: '0px ! important',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    paperButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        margin: '0px ! important',
        textAlign: 'center',
    },
    paperButtonHeader: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '15px',
        fontSize: '1.5rem',
        marginTop: '15px',
        textTransform: 'uppercase',
    },
    paperButtonText: {
        fontSize: '1rem',
        margin: '15px'
    },
    orContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
        // height: '175px',
        margin: '0px ! important'
    },
    orContainerText: {
        fontSize: '2rem',
    },
    orContainerIcon: {
        fontSize: '4rem',
    },
    orange: {
        color: tabletTheme.palette.primary.main,
    },
    turquoise: {
        color: tabletTheme.palette.secondary.main,
    },
});

export default withStyles(styles)(LMCFailedDialog);
