const overlayState = { text: '', open: false }

export default (state = overlayState, action) => {
    switch (action.type) {
        case 'SHOW_OVERLAY':
            return { text: action.text || '', progress: action.progress, open: true }
        case 'HIDE_OVERLAY':
            return { text: '', open: false }
        default:
            return state
    }
}
