import React, { useState } from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx'
import { saveAs } from 'file-saver';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { lashingLookup, stackabilityLookup } from './Constants';


export const downloadTemplate = (unit, close) => {
    var a = document.createElement("a"); //Create <a>
    a.href = `${process.env.REACT_APP_API_URL}/api/input templates/input template ${unit}.xlsx`;
    a.download = `${unit} excel template.xlsx`; //File name Here
    a.click(); //Downloaded file
    if (close) close()
}

export const exportParcelExcel = (parcels, fileName, unit, close) => {
    let workbook = XLSX.utils.book_new();

    // Additional info
    workbook.Props = {
        Title: "Cargo List",
        Subject: "Cargo List",
        Author: "BOXBOT",
        CreatedDate: new Date()
    }

    // Add Sheet
    workbook.SheetNames.push("Cargo List")

    // Data is an Array of Arrays:
    // [['hello' , 'world']];  a row with 2 columns
    // [['hello'], ['world']]; 2 row's with 1 column each
    const columns = ["id", `length (${unit.text})`, `width (${unit.text})`, `height (${unit.text})`, `weight (kg)`, `quantity`, `stackable`, `reserve fullwidth`, `description`]
    const data = [columns].concat(parcels.map(p =>
        [p.id, p.dimensions.x / unit.value, p.dimensions.y / unit.value, p.dimensions.z / unit.value, p.weight, p.quantity, stackabilityLookup[p.stackability], lashingLookup[p.lashing], p.description]
    ))

    // Create a worksheet from array
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    // Assing worksheet into workbook's Sheet
    workbook.Sheets["Cargo List"] = worksheet;

    let wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);

    if (close) close()
}

export default (props) => {
    const [anchorEl, setAnchorEl] = useState(null)

    return (
        <div>
            <Button variant="contained" color="primary" startIcon={<GetAppIcon />} onClick={event => {
                setAnchorEl(event.currentTarget)
                return
            }}>Excel Template</Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => downloadTemplate("mm", setAnchorEl)}>
                    <ListItemText primary={"mm template"} />
                </MenuItem>
                <MenuItem onClick={() => downloadTemplate("cm", setAnchorEl)}>
                    <ListItemText primary={"cm template"} />
                </MenuItem>
            </Menu>
        </div>
    )
}
