/**
 * Dictionary of Units in form of:
 *  name: { value: <int>, text: <string>}
 */
const Units = {
    mm: { value: 1, text: 'mm'},
    cm: { value: 10, text: 'cm'},
    m: { value: 1000, text: 'm'}
}

export default Units