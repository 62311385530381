import { createMuiTheme } from '@material-ui/core/styles';
import { green, red, grey } from '@material-ui/core/colors'

export const colors = {
    bbTeal: '#348ba2',
    bbTealLight: '#50a8c0',
    bbTealLighter: '#b2dbe6',
    bbOrange: '#f3740e',
    bbOrangeLight: '#ffa05b',
    green: green[600],
    grey: grey[800],
    red: red[500],
    octagon: '#f0f8f8',
    nav: '#e0e4e8',
    yellow: '#f9a602'
}


// Create default theme in order or to access spacing and palette in defining boxbot theme
let theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.bbOrange,
            light: colors.bbOrangeLight,
            contrastText: "#ffffff"
        },
        secondary: {
            main: colors.bbTeal,
            light: colors.bbTealLight,
        },
        error: red
    },
});


const defaultMargin = theme.spacing(1) * 0.6125; // '0.35rem'
const drawerWidth = 200
const mobileDrawerWidth = 274

const boxbotTheme = createMuiTheme({
    ...theme,
    mixins: {
        ...theme.mixins,
        // By default toolbar would change the minHeight dynamically - we don't want/need that
        toolbar: {
            minHeight: 64
        }
    },
    overrides: {
        MuiButton: {
            root: {
                margin: defaultMargin,
            },
            contained: {
                margin: '0.175rem',
                backgroundColor: theme.palette.common.white
            },
        },
        MuiSelect: {
            select: {
                minWidth: '4.28rem'
            }
        },
        MuiTypography: {
            h6: {
                margin: defaultMargin
            }
        },
        MuiPaper: {
            elevation1: {
                flex: 1,
                flexBasis: 'auto',
                paddingTop: defaultMargin
            },
            elevation4: {
                flex: 1,
                flexBasis: 'auto',
                margin: '0.35rem 1rem',
                [theme.breakpoints.down('xs')]: {
                    marginLeft: '0px',
                    marginRight: '0px'
                }
            }
        },
        MuiFormControl: {
            root: {
                margin: defaultMargin
            }
        },

        // Because we're using Material UI v4, we've had to make special styles for ToggleButton and ToggleButtonGroup.
        // in v4, these components are part of MUI Lab, and they don't have dedicated 'color'-prop.
        // TODO: When updating Material UI to version > 4, evaluate the need for these stylings.
        MuiToggleButtonGroup: {
            root: {
                margin: defaultMargin,
                flex: 1,
                flexBasis: "auto",
                display: "block"
            },
            grouped: {
                padding: '0px'
            }
        },
        MuiToggleButton: {
            root: {
                minWidth: '3rem',
                height: '2.3rem',
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.text.primary,
                '&$selected': {
                    backgroundColor: colors.bbOrange,
                    color: theme.palette.common.white,
                    "&:hover": {
                        backgroundColor: colors.bbOrangeLight
                    }
                },
            },
            label: {
                padding: '10px'
            }
        },

        MuiDivider: {
            vertical: {
                marginLeft: theme.spacing(1) * 3.75,
                width: theme.spacing(1) / 8,
                height: theme.spacing(1) * 4.35
            }
        },
        MuiFormGroup: {
            root: {
                margin: '8px 16px 8px',
            }
        },
        MuiRadio: {
            root: {
                marginRight: "20.9px"
            }
        },
        MuiFormLabel: {
            root: {
                color: theme.palette.text.primary
            }
        },
        MuiFormControlLabel: {
            root: {
                color: theme.palette.text.secondary
            }
        },
        MuiDialog: {
            paperWidthMd: {
                maxWidth: '760px'
            },
            paperWidthXs: {
                maxWidth: '350px'
            }
        },
        MuiStepIcon: {
            text: {
                fill: theme.palette.common.white
            }
        },
        MuiDropzoneArea: {
            root: {
                height: '100%',
                borderWidth: '2px',
            },
            textContainer: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            text: {
                fontSize: '1.25rem'
            },
            icon: {
                margin: '0 auto',
                color: colors.bbTeal,
            }
        },
    },
    // Used by AdminEditHeaders (Delete / Save buttons)
    desktop: {
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    // Used by AdminEditHeaders (Delete / Save buttons)
    tablet: {
        [theme.breakpoints.up("lg")]: {
            display: "none"
        }
    },
    footer: {
        zIndex: 1201,
        height: '8px',
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        left: 0,
        width: '100vw',
        margin: 0
    },
    containers: {
        admin: {
            height: 'calc(100% - 112px)',
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        twoPaperFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        flexPaper: {
            margin: '8px 8px',
            minWidth: '26rem',
            maxWidth: '40rem'
        },
        logo: {
            padding: '2rem',
            paddingTop: '4rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        octagonChild: {
            position: 'relative',
            top: '-64px',
            height: 'calc(100% + 128px)',
            paddingBottom: '0.5rem'
        },
        dataTable: {
            maxHeight: 'calc(100% - 101px)',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            margin: 0
        }
    },
    // Used by userless components that doesn't require user authentication
    userlessPage: {
        titleText: {
            margin: theme.spacing(4),
            marginBottom: theme.spacing(2)
        },
        infoText: {
            textAlign: 'center',
            whiteSpace: 'pre-line',
            paddingTop: theme.spacing(1),
        },
        backNavigationContainer: {
            margin: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center'
        },
        linkHintText: {
            color: 'rgba(0, 0, 0, 0.77)',
            marginRight: '0.35rem',
            marginBottom: '0px'
        }
    },
    icons: {
        signInLogo: {
            margin: 'auto',
            maxWidth: '100%',
            maxHeight: '8rem'
        },
        mobileNavBarIcon: {
            margin: '0.5rem 0.8rem',
            padding: '9px 6px 3px',
            height: '4.0rem',
        },
    },
    app: {
        bottomDivider: {
            height: theme.spacing(1)
        }
    },
    navBar: {
        menuDivider: {
            background: 'transparent',
            marginTop: '1em'
        },
        logo: {
            padding: '0px 32px 48px',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        mobile: {
            width: mobileDrawerWidth,
            display: 'block'
        }
    },
    textFieldWrapper: {
        margin: theme.spacing(1),
        padding: '0 0.5rem',
        paddingRight: '1rem'
    },
    numberTextField: {
        // All of this is to disable the arrow controls that are added to number-type textfield by default
        '& .MuiInput-input': {
            '&[type=number]': {
                '-moz-appearance': 'textfield',
                margin: 0
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    },
    raisedButton: {
        margin: '4px'
    },
    textStyle: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontFamily: 'monospace, monospace',
        fontSize: '0.9175em',
        fontWeight: '500',
        letterSpacing: '1px',
    },
    errorTextStyle: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontFamily: 'monospace, monospace',
        fontSize: '0.9175em',
        letterSpacing: '1px',
        whiteSpace: 'initial'
    },
    labelTextStyle: {
        display: 'inline-block',
        fontSize: '0.75rem',
    },
    optimizer: {
        headerContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
            width: "100%"
        },
        headerLoadingPlanText: {

        },
        headerTextContainer: {
            height: "110px",
            position: "relative",
        },
        stepHeaderStyle: {
            width: "23rem",
            maxHeight: "68px",
            padding: "0px",
            margin: "0px",
            whiteSpace: "break-spaces",
            textOverflow: "ellipsis",
            lineHeight: "normal",
            overflow: "hidden"
        },
        viewContainer: {
            height: 'calc(100% - 200px)'
        }
    },
    textOverflow: {
        width: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    subheader: {
        display: "inline-flex",
        margin: theme.spacing(1)
    },
    xlButton: {
        fontSize: '2.5rem',
        minWidth: 'fit-content'
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        })
    },
    expandedOpen: {
        transform: 'rotate(180deg)'
    },
    errorButton: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    },
    radioGroup: {
        paddingLeft: theme.spacing(0.6125)
    },
    superScript: {
        verticalAlign: 0,
        position: 'relative',
        bottom: '1ex'
    },
    fieldset: { border: 'none', margin: '0', padding: '0' }
})

export const tabletTheme = createMuiTheme({
    ...boxbotTheme,
    overrides: {
        ...boxbotTheme.overrides,
        MuiButton: {
            ...boxbotTheme.overrides.MuiButton,
            root: {
                ...boxbotTheme.overrides.MuiButton.root,
                fontSize: '1.125rem', // 'Normal' size
            },
            sizeSmall: {
                fontSize: '0.875rem'
            },
            sizeLarge: {
                fontSize: '1.25rem',
                margin: theme.spacing(1)
            },
        }
    }
})

export default boxbotTheme;
