import React, { useState, useEffect } from 'react'

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import moment from 'moment'
import { startCase, capitalize } from 'lodash'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'

import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core'

import GeneralDataTable from '../../core/GeneralDataTable/GeneralDataTable'
import Octagon from '../../core/Octagon'
import Toolbar from '../../core/Toolbar'
import { colors } from '../../theme'
import { IsSignUpEnabled } from '../../core/SignUpFeature'
import { momentDateFormat } from '../../core/dateUtils'
import { notificationDialogSeverity } from '../../core/Constants'
import { organizations } from '../../api'
import { setOrganizations } from '../AdminActions'
import { showNotificationDialog } from '../../app/AppActions'


//////////////////////////////////////////////////////////////////////////////


const AdminOrganizations = (props) => {

    const {
        classes,
        organizations,
        onFetchOrganizations,
        onSelectOrganization,
        onAddOrganization,
        onGoBack,
    } = props


    // Pagination state. Take values from URL-params if they exist
    const [paginationState, setPaginationState] = useState({
        rowsPerPage: parseInt(props.location.query?.rowsPerPage ?? 25),
        page: parseInt(props.location.query?.page ?? 0)
    });


    /**
     * On the initial render, and when ever pagination parameters are changed, fetch organization-data.
     */
    useEffect(() => {
        onFetchOrganizations(
            paginationState.page,
            paginationState.rowsPerPage,
            {
                $limit: paginationState.rowsPerPage,
                $skip: paginationState.rowsPerPage * paginationState.page
            }
        )
    }, [
        paginationState.rowsPerPage,
        paginationState.page,
        onFetchOrganizations
    ])


    /**
     * Format given Boolean values in to strings
     * @param {Boolean} boo is the given Boolean-value
     * @returns either 'True' or 'False' -strings depending on boo
     */
    const formatBooleanValues = (boo) => {
        return startCase(boo.toString());
    }


    /**
     * Format given user count limit.
     * If limit is 0, then return an infinity symbol, otherwise return the given value as is
     * @param {Number} limit
     * @returns a formatted user count limit
     */
    const formatUserCountLimit = (limit) => {
        if (limit === 0) {
            return '\u221e' // Infinity symbol
        } else {
            return limit;
        }
    }


    /**
     * Format organizations trial expiration date.
     * If expiration date has passed, add a red error-icon at the end of the date.
     * @param {Object} organization
     * @returns a formatted date
     */
    const formatTrialExpiryDate = (expirationDate) => {

        if (expirationDate == null) {
            return;
        }

        let expires = moment(expirationDate);

        // If expiration date is in the future, return date formatted with momentDateFormat
        if (expires.isAfter(moment())) {
            return expires.format(momentDateFormat);
        }

        // Else, we can assume that the date is in the past, so return date formatted and with an error icon
        else {
            return (
                <span style={{ display: 'flex', fontFamily: 'inherit', alignItems: 'center' }}>
                    <span style={{ fontFamily: 'inherit', marginRight: '0.5rem' }}>{expires.format(momentDateFormat)}</span>
                    <ErrorIcon style={{ color: 'red' }} />
                </span>
            )
        }
    }


    // Table columns
    const columns = [
        { label: 'Name', field: 'name' },
        { label: 'Allowed Roles', field: 'allowedRoles', conversion: roles => roles.map(r => capitalize(startCase(r))).join(', ') },
        { label: 'Allow Superadmin', field: 'allowSuperadmin', conversion: a => formatBooleanValues(a) },
        { label: 'Google Login', field: 'allowGoogleLogin', conversion: b => formatBooleanValues(b) },
        { label: 'Office User Limit', field: 'officeUserCountLimit', conversion: limit => formatUserCountLimit(limit) },
        { label: 'Loading Operators Limit', field: 'loadingOperationsUserCountLimit', conversion: limit => formatUserCountLimit(limit) },
        { label: 'Enabled Features', field: 'enabledFeatures', conversion: features => features.map(f => capitalize(startCase(f))).join(', ') },
    ];


    // Add trial expiry date column only if signup i.e. trial feature is enabled
    if (IsSignUpEnabled) {
        columns.push({ label: 'Trial Expiration Date', field: 'trialExpires', conversion: (date) => { return formatTrialExpiryDate(date)} })
    }


//////////////////////////////////////////////////////////////////////////////


    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title='Organizations' onGoBack={onGoBack}>
                <Button startIcon={<AddIcon />} onClick={onAddOrganization} >Add organization</Button>
            </Toolbar>

            <Paper className={classes.table} elevation={4}>
                <GeneralDataTable
                    columns={columns}
                    data={organizations}
                    paginationState={paginationState}
                    onChangePagination={(state) => setPaginationState(state)}
                    onClickRow={(org) => onSelectOrganization(org)}
                />
            </Paper>
        </Octagon>
    )

}


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
    return {
        organizations: state.admin.organizations,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onSelectOrganization: (org) => {
            browserHistory.push('/admin/organizations/' + org._id)
        },
        onFetchOrganizations: (page, rowsPerPage, query) => {
            browserHistory.replace({
                pathname: '/admin/organizations',
                query: { page, rowsPerPage }
            })
            organizations.query(query)
                .then(result => {
                    dispatch(setOrganizations(result))
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onAddOrganization: () => {
            browserHistory.push('/admin/organizations/new')
        },
        onGoBack: () => {
            browserHistory.push('/admin')
        },
    }
}


const styles = theme => ({
    table: theme.containers.dataTable
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminOrganizations))
