//////////////////////////////////////////////////////////////////////////////

export const showNotificationDialog = (text, title, severity) => {
    return { type: 'SHOW_NOTIFICATION_DIALOG', text, title, severity }
}

export const hideNotificationDialog = () => {
    return { type: 'HIDE_NOTIFICATION_DIALOG' }
}

//////////////////////////////////////////////////////////////////////////////

export const showNotification = text => {
    return { type: 'SHOW_NOTIFICATION', text }
}

export const hideNotification = () => {
    return { type: 'HIDE_NOTIFICATION' }
}

//////////////////////////////////////////////////////////////////////////////

export const showOverlay = (text, progress) => {
    return { type: 'SHOW_OVERLAY', text, progress }
}

export const hideOverlay = () => {
    return { type: 'HIDE_OVERLAY' }
}

//////////////////////////////////////////////////////////////////////////////

export const openDrawer = () => {
    return { type: 'OPEN_DRAWER' }
}

export const closeDrawer = () => {
    return { type: 'CLOSE_DRAWER' }
}

export const openProfileMenu = (target) => {
    return { type: "OPEN_PROFILE_MENU", target }
}

export const closeProfileMenu = () => {
    return { type: "CLOSE_PROFILE_MENU" }
}

export const authUser = user => {
    return { type: 'AUTH', user }
}

export const updateUser = user => {
    return { type: 'UPDATE', user }
}

export const deauthUser = () => {
    return { type: 'DEAUTH' }
}

export const toggleTabletMode = (value) => {
    return { type: 'TOGGLE_TABLET_MODE', value }
}
