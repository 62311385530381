import React, { useState } from 'react'

import Octagon from '../../../core/Octagon'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SwipeableViews from 'react-swipeable-views'
import Tooltip from '@material-ui/core/Tooltip'

import WarningIcon from '@material-ui/icons/Warning';
import GetAppIcon from '@material-ui/icons/GetApp';

import { colors } from '../../../theme'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setSelectedParcel } from '../../OptimizeActions'
import { exportParcelExcel } from '../../../core/DownloadExcelTemplate'

import ParcelTable from './ParcelTable'
import PdfExportModal from '../../../pdf/PdfExportModal'

import { isEqual } from 'lodash'
import { convertSolutionToDisplaySolution } from '../../../core/common-helpers'


//////////////////////////////////////////////////////////////////////////////


const toCubicMeter = (value) => (value / ((10 * 10 * 10) * (10 * 10 * 10) * (10 * 10 * 10)))

const m3 = <span>m<sup>3</sup></span>

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index &&
                <Box
                    style={{ padding: "0px", height: "100%" }}
                    p={3}
                >
                    {children}
                </Box>
            }
        </div>
    );
}


//////////////////////////////////////////////////////////////////////////////


const CargoSpaceDetails = (props) => {

    const { solution, name, index, classes, selectedParcel, onSetSelectedParcel, onClose, cargoDataUnit } = props;
    const [pdfExportModal, setPdfExportModal] = useState({ open: false, cargoSpaceIndexes: [] })
    const [selectedRow, setSelectedRow] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)


    if (!solution) return <div />


    // Get the cargo space we want to display on ParcelTable. If an alternate arrangement has been selected, use that instead.
    const displaySolution = convertSolutionToDisplaySolution(solution);
    const currCargoSpace = displaySolution.cargoSpaces[index];

    const notPackedParcels = displaySolution.notPackedParcels;
    const packedParcels = currCargoSpace.packedParcels;

    // Depending on the tab user has selected, show either packedParcels or notPackedParcels.
    const data = tabIndex === 0 ? packedParcels : notPackedParcels

    // Calculate information for the labels on top of ParcelTable.
    const packedVolume = toCubicMeter(currCargoSpace.packedVolume)
    const totalVolume = currCargoSpace.storages.reduce((a, b) => a + b.volume, 0);
    const maxVolume = toCubicMeter(totalVolume)
    const volumePercent = maxVolume && packedVolume ? (packedVolume / maxVolume) * 100 : 0

    const packedWeight = currCargoSpace.packedWeight || 0
    const maxWeight = currCargoSpace.storages[0].weightLimit || 0
    const weightPercent = maxWeight && packedWeight ? (packedWeight / maxWeight) * 100 : 0


    /**
     * Check if parcel has been selected from ParcelTable.
     * This function is used on the 'selected'-prop on ParcelTable rows.
     * @param {Number} i
     * @returns true / false
     */
    const isThisRowSelected = (i) => isEqual(selectedParcel, data[i]);


    /**
     * Event handler for selecting a row on ParcelTable.
     * @param {Number} index
     */
    const onRowSelected = (index) => {
        if ((index || index === 0) && index !== selectedRow) {
            setSelectedRow(index)
            onSetSelectedParcel(data[index])
        } else {
            setSelectedRow(null)
            onSetSelectedParcel(null)
        }
    }


//////////////////////////////////////////////////////////////////////////////


    const table = (
        <div style={{ height: "100%", overflow: 'auto' }}>
            <ParcelTable
                parcels={data}
                isThisRowSelected={(i) => isThisRowSelected(i)}
                onRowSelected={(i) => onRowSelected(i)}
            />
        </div>
    )

    const notPackedTabLabel = (
        <div style={{ display: "flex" }}>
            {notPackedParcels.length ?
                <WarningIcon style={{ marginRight: "10px", color: colors.yellow }} />
                :
                null
            }
            {`Not Packed (${notPackedParcels.length})`}
        </div>
    )


    return (
        <Octagon z={1} w='45rem' color={colors.octagon}>
            <div className={classes.headerContainer} style={{ display: "inline-grid" }}>
                <h1>{"Loading Plan Details"}{` - Cargo Space ${index + 1} / ${solution.cargoSpaces.length}`}</h1>
                <Tooltip title={name.length >= 50 ? name || '' : ''}>
                    <h1 className={classes.textOverflow} style={{ marginTop: "0px" }}>{name.length ? `${name}` : 'Unnamed'}</h1>
                </Tooltip>
            </div>
            <div className={classes.textStyle} style={{ display: "flex" }}>
                <div style={{ marginTop: "-5px" }}>
                    {/* Negative top margin because of superscript */}
                    <h3 style={{ margin: "0px" }}><span>Total Volume: </span><span> {`${packedVolume.toFixed(1)} `}</span>{m3}<span>{` / ${maxVolume.toFixed(1)} `}</span>{m3}<span>{` (${volumePercent.toFixed(0)} %)`}</span></h3>
                    <h3 style={{ margin: "0px" }}><span>Total Cargo Items: </span><span>{`${packedParcels.length}`}</span></h3>
                </div>
                <div style={{ marginLeft: "50px" }}>
                    <h3 style={{ margin: "0px" }}><span>Total Weight: </span><span>{`${packedWeight} kg / ${maxWeight} kg (${weightPercent.toFixed(0)} %)`}</span></h3>
                    <h3 style={{ margin: "0px" }}><span>Used Length: </span><span>{`${(currCargoSpace.usedLength / 1000).toFixed(2)} m`}</span></h3>
                </div>
            </div>
            <Paper style={{ height: 'calc(100% - 248px)', margin: "32px 0px" }}>
                <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={(e, value) => {
                        setTabIndex(value)
                        setSelectedRow(null)
                        if (selectedParcel) onSetSelectedParcel(null)
                    }}
                >
                    <Tab label={`Packed (${currCargoSpace.packedParcels.length})`} value={0} />
                    <Tab label={notPackedTabLabel} value={1} disabled={!notPackedParcels.length} />
                </Tabs>
                <div className={classes.textStyle} style={{ height: "calc(100% - 48px)", overflow: "hidden auto", width: "100%" }}>
                    <SwipeableViews
                        style={{ height: "100%" }}
                        containerStyle={{ height: "100%" }}
                        axis={tabIndex === 1 ? 'x' : 'x-reverse'}
                        index={tabIndex}
                        onChangeIndex={i => {
                            setTabIndex(i)
                            setSelectedRow(null)
                            if (selectedParcel) onSetSelectedParcel(null)
                        }}>
                        <TabPanel value={tabIndex} index={0}>{table}</TabPanel>
                        <TabPanel value={tabIndex} index={1}>{table}</TabPanel>
                    </SwipeableViews>
                </div>
            </Paper>
            <Box display='flex' justifyContent='space-between'>
                <Box display='flex' alignItems='left'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onClose()}
                    >
                        close
                    </ Button>
                </Box>
                <Box display='flex' alignItems='right'>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<GetAppIcon />}
                        onClick={() => setPdfExportModal({ open: true, cargoSpaceIndexes: [index] })}
                        visibility='none'
                    >
                        Export PDF
                    </Button>
                    <Button
                        startIcon={<GetAppIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            exportParcelExcel(currCargoSpace.packedParcels, `${name} cargo list ${index + 1} of ${solution.cargoSpaces.length}`, cargoDataUnit)
                        }}
                    >
                        Download cargo list
                    </Button>
                </Box>
            </Box>
            <PdfExportModal
                open={pdfExportModal.open}
                onClose={() => setPdfExportModal({ open: false, cargoSpaceIndexes: [] })}
                solution={solution}
                cargoSpaceIndexes={pdfExportModal.cargoSpaceIndexes}
            />
        </Octagon >
    )
}


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
    return {
        selectedParcel: state.optimizer.selectedParcel,
        cargoDataUnit: state.unit.cargoData,
        allParcels: state.optimizer.parcels
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedParcel: parcel => {
            dispatch(setSelectedParcel(parcel));
        },
    };
}


const styles = theme => ({
    headerContainer: theme.optimizer.headerContainer,
    textStyle: theme.textStyle,
    textOverflow: theme.textOverflow,
    noPadding: { padding: "0px", height: "100%" }
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CargoSpaceDetails))
