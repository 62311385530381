import * as THREE from 'three';

const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

//TODO: Constructor parameters here should not be optional but instead required. Something should be done about this!
export default (pos, dim, material, parcel, parcelColor) => {
    if (!parcel || !isObject(parcel) || !parcel.id || !parcel.packedDimensions)
        throw new Error('Expecting a parcel object here.');
    if (parcel.packedDimensions.r === 0)
        throw new Error('Expecting a cylinder here.');

    const SEGMENTS = 32;
    let cyl = new THREE.CylinderBufferGeometry(dim.r, dim.r, dim.y, SEGMENTS);
    let mesh = new THREE.Mesh(cyl, material);
    mesh.position.x = pos.x;
    mesh.position.y = pos.y;
    mesh.position.z = pos.z;

    mesh.parcel = parcel;
    mesh.parcelId = parcel.id; // TODO We don't need both parcelId and parcel properties
    
    //This is used when the parcel color is required outside the Threer (e.g. step-by-step PDF export)
    //TODO: Should this also be required?
    mesh.parcelColor = parcelColor;

    function getMesh() {
        return mesh;
    }

    return {
        getMesh,
    };
}
