import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import CounterButtonGroup from '../../../core/CounterButtonGroup';

import { validateParcelInput, toUint, toNumber, getFirstInvalidParcel } from '../../../core/Validations';
import { stackabilityLookup, lashingLookup } from '../../../core/Constants';

import { cloneDeep } from 'lodash';


//////////////////////////////////////////////////////////////////////////////


const mapNameToLabel = name => {
    if (name === "dimensions.x") return 'Length'
    if (name === "dimensions.y") return "Width"
    if (name === "dimensions.z") return 'Height'
    return name.substring(0, 1).toUpperCase() + name.substring(1).toLowerCase()
}

const smallPadding = {
    paddingLeft: "3px",
    paddingRight: "3px",
}


//////////////////////////////////////////////////////////////////////////////


const TableEditRow = (props) => {

    const { classes } = props

    const [newData, setNewData] = useState(props.rowData)


    const onRowEdited = (newData) => {

        // Clone the array of parcels. Replace the parcel we're editing with the edited newData
        let cloneData = cloneDeep(props.data);
        const idx = cloneData.map(cd => cd.tableData.id).indexOf(newData.tableData.id);
        cloneData[idx] = newData;

        // Check if cloneData includes duplicate ids
        let ids = cloneData.map(x => x.id);
        let oldData = props.data.find(x => x.tableData.editing)
        const errors = validateParcelInput(newData, newData.dimensions, cloneData.filter(x => ids.filter(y => y === x.id).length > 1), true, props.cargoDataUnit.value)

        if (errors.length) {
            newData.errors = errors
            setNewData(Object.assign({}, newData))
            return
        }

        delete newData.errors;
        delete newData.tableData.editing;
        delete newData.tableData.duplicate;

        newData.weight = toUint(newData.weight)
        const dims = ["x", "y", "z"]
        dims.forEach(d => {
            if (typeof newData.dimensions[d] === "string")
                newData.dimensions[d] = toNumber(newData.dimensions[d]) * props.cargoDataUnit.value
        })
        newData.fromExcel = false

        let parcs = Array.from(props.data)
        const index = parcs.indexOf(oldData);
        parcs[index] = newData;

        props.checkUnpackableParcels(parcs, newData) // Uses setData internally

        // Update error message if one was present previously
        if (props.errorMessage) {
            const invalidParcel = getFirstInvalidParcel(parcs)
            if (invalidParcel) {
                props.setErrorMessage(`Parcel with id ${invalidParcel.id} has invalid data: ${invalidParcel.field} ${invalidParcel.message}`)
            } else {
                props.setErrorMessage(null)
            }
        }
    }



    const handleQuantityChange = (newValue) => {
        const value = parseInt(newValue)
        if (value > 0) setNewData({
            ...newData,
            quantity: value
        })
    }

    const defaultProps = {
        size: "small",
        margin: "dense",
        onChange: e => {
            if (!e.target.name) return
            const len = e.target.name.split(".")
            delete newData.tableData.focus
            if (len.length === 1) {
                setNewData({
                    ...newData,
                    [e.target.name]: e.target.value
                })
            } else {
                setNewData({
                    ...newData,
                    dimensions: {
                        ...newData.dimensions,
                        [len[1]]: e.target.value
                    }
                })
            }
        }
    }

    const errorProps = label => {
        let error, helperText;
        if (!newData.errors) {
            error = false;
            helperText = ''
        } else {
            const err = newData.errors.find(x => x.field === label);
            error = Boolean(err);
            helperText = error ? err.message : '';
        }
        return { error: error, helperText: helperText }
    }

    const lookupProps = (name) => {
        return {
            open: newData.tableData.focus === name,
            onOpen: e => {
                newData.tableData.focus = name;
                setNewData({ ...newData });
            },
            onClose: e => {
                delete newData.tableData.focus;
                setNewData({ ...newData });
            }
        }
    }

    const additionalProps = (name, required, lookup) => {
        const errs = required ? errorProps(mapNameToLabel(name)) : {}

        const selectionProps = lookup ? lookupProps(name) : {};

        const ret = {
            ...errs,
            ...selectionProps,
            required: required,
            id: name,
            name: name,
            autoFocus: newData.tableData.focus === name,
        }
        return ret
    }

    const toDimValue = value => typeof value === "string" ? value : value / props.cargoDataUnit.value


//////////////////////////////////////////////////////////////////////////////


    return (
        <ClickAwayListener key={props.rowData.id} onClickAway={() => onRowEdited(newData)}>
            <TableRow key={props.rowData.id} >
                <TableCell style={{ justifyContent: 'left' }}>
                    <Tooltip title={'Confirm'}>
                        <IconButton
                            onClick={() => onRowEdited(newData)}
                            size='small'
                        >
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Cancel'}>
                        <IconButton
                            onClick={() => props.onCancelEdit()}
                            size='small'
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell style={smallPadding}>
                    <TextField
                        {...defaultProps}
                        {...additionalProps("id", true)}
                        value={newData.id}
                    />
                </TableCell>
                <TableCell style={smallPadding}>
                    <TextField
                        {...defaultProps}
                        {...additionalProps("dimensions.x", true)}
                        value={toDimValue(newData.dimensions.x)}
                        className={classes.numberTextFieldStyle}
                        type='number'
                    />
                </TableCell>
                <TableCell style={smallPadding}>
                    <TextField
                        {...defaultProps}
                        {...additionalProps("dimensions.y", true)}
                        value={toDimValue(newData.dimensions.y)}
                        className={classes.numberTextFieldStyle}
                        type='number'
                    />
                </TableCell>
                <TableCell style={smallPadding}>
                    <TextField
                        {...defaultProps}
                        {...additionalProps("dimensions.z", true)}
                        value={toDimValue(newData.dimensions.z)}
                        className={classes.numberTextFieldStyle}
                        type='number'
                    />
                </TableCell>
                <TableCell style={smallPadding}>
                    <TextField
                        {...defaultProps}
                        {...additionalProps("weight")}
                        {...errorProps(mapNameToLabel("weight"))}
                        className={classes.numberTextFieldStyle}
                        value={newData.weight}
                        type='number'
                    />
                </TableCell>
                <TableCell style={{ ...smallPadding, minWidth: '6rem'}}>
                    <CounterButtonGroup
                        containerStyle={{ width: "3rem" }}
                        count={newData.quantity}
                        onChange={handleQuantityChange}
                        hideLabel
                        textFieldInputProps={{ style: { fontSize: "13px" } }}
                    />
                </TableCell>
                <TableCell style={smallPadding} >
                    <Select
                        {...defaultProps}
                        {...additionalProps("stackability", false, true)}
                        value={newData.stackability}
                        MenuProps={{ disablePortal: true }}
                    >
                        {Object.keys(stackabilityLookup).map(key => (
                            <MenuItem key={key} value={key}>
                                {stackabilityLookup[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell style={smallPadding}>
                    <Select
                        {...defaultProps}
                        {...additionalProps("lashing", false, true)}
                        value={newData.lashing}
                        MenuProps={{ disablePortal: true }}
                    >
                        {Object.keys(lashingLookup).map(key => (
                            <MenuItem key={key} value={key}>
                                {lashingLookup[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell style={smallPadding}>
                    <TextField {...defaultProps} value={newData.description || ''}  {...additionalProps("description")} />
                </TableCell>
            </TableRow >
        </ClickAwayListener>
    )
}


//////////////////////////////////////////////////////////////////////////////


const styles = theme => ({
    smallSelect: { maxWidth: "2px" },
    menuPaper: {
        left: `${theme.spacing(2)}px`,
        maxHeight: 'none',
        top: `${theme.spacing(2)}px`
    },
    numberTextFieldStyle: theme.numberTextField
})


export default withStyles(styles)(TableEditRow)
