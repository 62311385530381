import * as THREE from 'three';

import Arrow, { arrowDirections } from './Arrow';
import Box from './Box';
import Cylinder from './Cylinder';
import { convertYZ, getShrunkenDimensions } from '../core/Constants'
import { getNextColor, getNextStepColor, getNextGrayColor, cBoxbotOrange, cBoxBlue } from './threeColors';
import { MeshLambertMaterial } from 'three';
import BoxShape from './BoxShape';


// This is loaded only once - no need to dispose, from docs: "Dispose(): Frees the GPU related resources allocated by a texture. Call this method whenever a texture is no longer used in your app."
// This used always when rendering containers
const containerTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/assets/container_side_grey.png'); // Texture for the containerspace
const containertextureImage = new Image();
containertextureImage.src = process.env.PUBLIC_URL + '/assets/container_side_grey.png';
const boxbotLogo = new Image();
boxbotLogo.src = process.env.PUBLIC_URL + '/assets/boxbot-w.png';

// Initialize the icons that are going to be shown on the box with the ID-number
const dnsIcon = new Image();
const upIcon = new Image();
const overIcon = new Image();
dnsIcon.src = process.env.PUBLIC_URL + '/assets/box_do_not_stack_icon.png'; // Get icons from public/assets -folder
upIcon.src = process.env.PUBLIC_URL + '/assets/box_keep_upright_icon.png';
overIcon.src = process.env.PUBLIC_URL + '/assets/box_overstow_icon.png';

let iconList = [];

export default scene => {

    function getBoxPositionInSpace(pos, dim, spaceDim) {
        const pos_new = Object.assign({}, pos);
        pos_new.x = -0.5 * spaceDim.x + pos.x + 0.5 * dim.x;
        pos_new.y = -0.5 * spaceDim.y + pos.y + 0.5 * dim.y;
        pos_new.z = -0.5 * spaceDim.z + pos.z + 0.5 * dim.z;

        // Change to Y-axis direction +/- direction to match the BoxOracles Y-axis direction.
        // Reason why the Y-axis change is done on position.Z, is for THREE.BoxBuffer constructor taking arguments (width, height, depth)
        // and the height dimension actually corresponding to Three.js Y-axis.
        // pos_new.z *= -1

        // Note that the Y-direction change is now in BoxOracle instead here. The reason is the required change in using "Left" instead of "Right" when using loadingDirection "Side".
        // "Right" -> "Left" change was done for not needing to mirror Y-axis for GA, which otherwise resulted in faulty scenarios due to FreeAxis Y.
        return pos_new;
    }

    function getCylinderPositionInSpace(pos, dim, spaceDim) {
        const pos_new = Object.assign({}, pos);
        pos_new.x = -0.5 * spaceDim.x + pos.x;
        pos_new.y = -0.5 * spaceDim.y + pos.y + 0.5 * dim.y; // Y and Z have been already swapped, hence Y
        pos_new.z = -0.5 * spaceDim.z + pos.z;

        return pos_new;
    }


    /**
     * Create the canvas that is used for a box side
     * @param {string} id Text to be written
     * @param {int} sideWidth Canvas width
     * @param {int} sideHeight Canvas height
     * @param {boolean} top Is this canvas the top/bottom of the box
     * @param {string} Stackability
     * @param {int} boxColor
     */
    const getMaterial = (id, sideWidth, sideHeight, top, stack, boxColor) => {

        // Determine which icons are going to be draw on the box
        if (stack === 'Stackable') {
            if (top === false) { // Side, stackable
                iconList = [upIcon];
            } else { // Top, stackable
                iconList = [];
            }
        }
        else if (stack === 'NonStackable') {
            if (top === false) { // Side, non-stackable
                iconList = [dnsIcon, upIcon];
            } else { // Top, non-stackable
                iconList = [dnsIcon];
            }
        }
        else {
            if (top === false) { // Side, overstow
                iconList = [overIcon, upIcon];
            } else { // Top, overstow
                iconList = [overIcon];
            }
        };


        const ctx = document.createElement('canvas').getContext('2d'); // Initialize canvas

        // Set canvas size
        ctx.canvas.width = sideWidth / 4;
        ctx.canvas.height = sideHeight / 4;

        const asp_ratio = ctx.canvas.width / ctx.canvas.height; // Aspect ratio of the canvas

        const min_canvas_size = 512;
        const max_canvas_size = 1024;

        // Set minimum canvas size
        if (ctx.canvas.height <= min_canvas_size) {
            ctx.canvas.width = min_canvas_size * asp_ratio;
            ctx.canvas.height = min_canvas_size;
        };
        // Set maximum canvas size
        if (ctx.canvas.height >= max_canvas_size) {
            ctx.canvas.width = max_canvas_size * asp_ratio;
            ctx.canvas.height = max_canvas_size;
        }

        // ID-number text font
        let FONT_SIZE = Math.min(ctx.canvas.width, ctx.canvas.height) * 0.5;
        const FONT_BORDER_X = FONT_SIZE * 0.1;
        const FONT_BORDER_Y = FONT_SIZE + FONT_BORDER_X;
        const FONT = `${FONT_SIZE}pt Arial`;

        // Limit font size if font is way too tall on the canvas
        if (FONT_SIZE >= (ctx.canvas.height * 0.75)) {
            FONT_SIZE = ctx.canvas.height * 0.6;
        };

        ctx.font = FONT; // Initialize text font

        // Initialize the canvas color by converting decimal value of color to hex and pad necessary zeros to the beginning
        ctx.fillStyle = '#' + boxColor.toString(16).padStart(6, '0');
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.fill();

        // Create the text onto the canvas
        ctx.font = FONT; // Font needs to be initialized again so it can be shown on the canvas
        ctx.fillStyle = 'black'; // Text color

        // Add text and icons to the canvas depending on the canvas aspect ratio
        if (asp_ratio < 3) { // Narrow to semi-wide canvas
            ctx.scale(0.8, 0.5);

            if (iconList.length > 0) {
                ctx.fillText(id, FONT_BORDER_X, (FONT_SIZE * 2), ctx.canvas.width); // (text-content, text-location-x, text-location-y, text-max-width)
            } else {
                ctx.fillText(id, FONT_BORDER_X, FONT_BORDER_Y, ctx.canvas.width); // If there's no icons, no need to leave the space for them
            };

            for (let i = 0; i < iconList.length; i++) {
                ctx.drawImage(iconList[i], 0, 0, 200, 200, ((FONT_SIZE / 2) * i), 0, (FONT_SIZE / 2), FONT_SIZE); // (icon, icon-location-x, icon-location-y, icon-size-x, icon-size-y)
            }
        }
        else if (asp_ratio >= 3) { // Super-wide canvas
            ctx.scale(1, 0.8);
            let numberOfIcons = 0;
            for (let i = 0; i < iconList.length; i++) {
                ctx.drawImage(iconList[i], 0, 0, 200, 200, (FONT_SIZE * i), 0, FONT_SIZE, FONT_SIZE); // (icon, icon-location-x, icon-location-y, icon-size-x, icon-size-y)
                numberOfIcons++;
            };
            ctx.fillText(id, (FONT_SIZE * numberOfIcons), FONT_BORDER_Y, ctx.canvas.width); // When we have a super-wide canvas, draw icons first and then add ID behind them
        };

        ctx.fill();

        const texture = new THREE.CanvasTexture(ctx.canvas);
        texture.minFilter = THREE.LinearFilter;
        texture.needsUpdate = true;

        return new THREE.MeshLambertMaterial({ map: texture });
    }

    function createBox(parcelLocation, parcelPackedDimensions, spaceDim, parcel, noGrayScales = false) {
        const id = parcel.id;
        const stackability = parcel.stackability;

        const pos_new = convertYZ(getBoxPositionInSpace(parcelLocation, parcelPackedDimensions, spaceDim));
        const shrunkenDimensions = convertYZ(getShrunkenDimensions(parcelPackedDimensions));

        const boxColor = getNextColor(id, noGrayScales);
        const textureZY = getMaterial(id, shrunkenDimensions.z, shrunkenDimensions.y, false, stackability, boxColor)
        const textureXZ = getMaterial(id, shrunkenDimensions.x, shrunkenDimensions.z, true, stackability, boxColor) // top/bottom
        const textureXY = getMaterial(id, shrunkenDimensions.x, shrunkenDimensions.y, false, stackability, boxColor)


        const materials = [
            textureZY, textureZY,
            textureXZ, textureXZ,
            textureXY, textureXY
        ]

        let colorString = '#' + boxColor.toString(16).padStart(6, '0');
        let box = new Box(pos_new, shrunkenDimensions, materials, parcel, colorString);
        scene.add(box.getMesh());
        return box;
    }

    // Create boxes for each step in the Step-By-Step PDF
    function createStepBox(pos, dim, spaceDim, parcel, parcelNumberInStep) {

        if (dim.r)
            throw new Error('Cylinders are not supported by this function (yet).');

        const id = parcel.id;
        const stackability = parcel.stackability;

        const pos_new = getBoxPositionInSpace(pos, dim, spaceDim)

        // boxColor gets a [boxColor, tableColor]-pair. boxColor is the boxes color and the tableColor is each boxes parcel table color.
        // These colors are separate, because the 3D material makes the colors brighter, so we have to use 2 colors for each box to have the same color both in the box and in the table
        const boxColor = getNextStepColor(parcelNumberInStep);

        const textureZY = getMaterial(id, dim.z, dim.y, false, stackability, boxColor[0])
        const textureXZ = getMaterial(id, dim.x, dim.z, true, stackability, boxColor[0]) // top/bottom
        const textureXY = getMaterial(id, dim.x, dim.y, false, stackability, boxColor[0])

        const materials = [
            textureZY, textureZY,
            textureXZ, textureXZ,
            textureXY, textureXY
        ]

        let colorString = '#' + boxColor[1].toString(16).padStart(6, '0'); // Color for the boxes parcel table row
        let box = new Box(pos_new, dim, materials, parcel, colorString);
        scene.add(box.getMesh());
        return box;
    }


    function grayScaleBox(threeBox, parcel) {
        const grayColor = getNextGrayColor();
        const dim = convertYZ(getShrunkenDimensions(parcel.packedDimensions));

        const textureZY = getMaterial(parcel.id, dim.z, dim.y, false, parcel.stackability, grayColor)
        const textureXZ = getMaterial(parcel.id, dim.x, dim.z, true, parcel.stackability, grayColor) // top/bottom
        const textureXY = getMaterial(parcel.id, dim.x, dim.y, false, parcel.stackability, grayColor)

        const newMaterials = [
            textureZY, textureZY,
            textureXZ, textureXZ,
            textureXY, textureXY
        ]

        for (let idx = 0; idx < threeBox.material.length; idx++) {
            let mat = threeBox.material[idx];
            // Dispose previous material
            mat.dispose();
            // Create new gray material
            threeBox.material[idx] = newMaterials[idx];
        }
    }

    function createArrows(parcel, direction) {

        if (parcel.parcel == null) {
            return
        }

        // Find the parcel that was clicked in the scene
        const id = parcel.parcelId;
        if (id === undefined || id === null)
            throw new Error('"parcel.parcelId must be defined and not null');

        let mesh = scene.children.find(child => child.parcelId === id);
        if (!mesh)
            throw new Error(`Mesh not found in the scene with parcel id: "${id}"`);

        // Materials for arrows
        const redArrowMaterial = new MeshLambertMaterial({
            color: '#BC0000',
        });

        const blueArrowMaterial = new MeshLambertMaterial({
            color: '#002DBC'
        });

        const greenArrowMaterial = new MeshLambertMaterial({
            color: '#07BC00'
        });

        // Arrows physical dimensions
        const parcelDims = parcel.parcel.packedDimensions;
        const arrowDims = {
            r: Math.min(parcelDims.x, parcelDims.y, parcelDims.z) / 10, // Arrow stem cylinder radius
            height: Math.min(parcelDims.x, parcelDims.y, parcelDims.z), // Height of the whole arrow
        };

        const parcelPosition = mesh.position; // Get the position of the clicked parcel
        const parcelDimensions = mesh.geometry.parameters; // Get the dimensions of the clicked parcel

        // Arrow pointing Up
        let upArrowPosition = { ...parcelPosition, y: parcelPosition.y + (parcelDimensions.height / 2) }
        let upArrow = new Arrow(upArrowPosition, arrowDims, redArrowMaterial, arrowDirections.zPlus);
        const upArrowMesh = upArrow.getMesh();

        // Arrow pointing South
        const rotationSouth = { direction: 'x', amount: 0.5 }
        let southArrowPosition = { ...parcelPosition, z: parcelPosition.z + (parcelDimensions.depth / 2) }
        let southArrow = new Arrow(southArrowPosition, arrowDims, blueArrowMaterial, arrowDirections.yPlus, rotationSouth);
        const southArrowMesh = southArrow.getMesh();

        // Arrow pointing North
        const rotationNorth = { direction: 'x', amount: 1.5 }
        let northArrowPosition = { ...parcelPosition, z: parcelPosition.z - (parcelDimensions.depth / 2) }
        let northArrow = new Arrow(northArrowPosition, arrowDims, blueArrowMaterial, arrowDirections.yMinus, rotationNorth);
        const northArrowMesh = northArrow.getMesh();

        // Arrow pointing West
        const rotationWest = { direction: 'z', amount: 0.5 }
        let westArrowPosition = { ...parcelPosition, x: parcelPosition.x - (parcelDimensions.width / 2) }
        let westArrow = new Arrow(westArrowPosition, arrowDims, greenArrowMaterial, arrowDirections.xMinus, rotationWest);
        const westArrowMesh = westArrow.getMesh();

        // Arrow pointing East
        const rotationEast = { direction: 'z', amount: 1.5 }
        let eastArrowPosition = { ...parcelPosition, x: parcelPosition.x + (parcelDimensions.width / 2) }
        let eastArrow = new Arrow(eastArrowPosition, arrowDims, greenArrowMaterial, arrowDirections.xPlus, rotationEast);
        const eastArrowMesh = eastArrow.getMesh();

        switch (direction) {
            case "all":
                scene.add(
                    upArrowMesh[0], // Arrow stem
                    upArrowMesh[1], // Arrow head
                    southArrowMesh[0],
                    southArrowMesh[1],
                    northArrowMesh[0],
                    northArrowMesh[1],
                    westArrowMesh[0],
                    westArrowMesh[1],
                    eastArrowMesh[0],
                    eastArrowMesh[1]
                );
                break;
            case "zPlus":
                scene.add(
                    upArrowMesh[0], // Arrow stem
                    upArrowMesh[1], // Arrow head
                )
                break;
            case "xPlus":
                scene.add(
                    eastArrowMesh[0], // Arrow stem
                    eastArrowMesh[1], // Arrow head
                )
                break;
            case "xMinus":
                scene.add(
                    westArrowMesh[0], // Arrow stem
                    westArrowMesh[1], // Arrow head
                )
                break;
            case "yPlus":
                scene.add(
                    southArrowMesh[0], // Arrow stem
                    southArrowMesh[1], // Arrow head
                )
                break;
            case "yMinus":
                scene.add(
                    northArrowMesh[0], // Arrow stem
                    northArrowMesh[1], // Arrow head
                )
                break;

            default:
                throw new Error('Unsupported arrow defition inside createArrows(): ' + direction)
        }
    }

    function createStepBoxFromRawData(box, parcelIndexInStep, spaceDim) {
        const shrunkenDimensions = getShrunkenDimensions(box.packedDimensions)
        return createStepBox(convertYZ(box.location), convertYZ(shrunkenDimensions), spaceDim, box, parcelIndexInStep);
    }

    function createParcelFromMaterial(box, material, spaceDim) {
        const shrunkenDimensions = convertYZ(getShrunkenDimensions(box.packedDimensions))
        let newParcel, pos_new;
        if (box.packedDimensions.r > 0) {
            pos_new = getCylinderPositionInSpace(convertYZ(box.location), shrunkenDimensions, spaceDim)
            newParcel = new Cylinder(pos_new, shrunkenDimensions, material, box);
        } else {
            pos_new = getBoxPositionInSpace(convertYZ(box.location), shrunkenDimensions, spaceDim)
            newParcel = new Box(pos_new, shrunkenDimensions, material, box);
        }
        scene.add(newParcel.getMesh());
        return newParcel;
    }

    function getCylinderMaterial(sideWidth, sideHeight, id, repeat, textY, rotate) {
        const FONT_SIZE = 30;
        const FONT_BORDER_X = 15;
        const FONT_BORDER_Y = 30;
        const CANVAS_SIZE = 128;
        const FONT = `${FONT_SIZE}px Arial`;

        const boxColor = getNextColor(id);

        const ctx = document.createElement('canvas').getContext('2d');
        ctx.font = FONT;

        const textWidth = ctx.measureText(id).width;

        // Make canvas at least the textwidth so it won't stretch long text from end
        const canvasW = Math.max(CANVAS_SIZE, textWidth + 2 * FONT_BORDER_X);
        // Resize canvas if cylinder is wide so text won't squeeze
        const canvasH = sideWidth > sideHeight * 3.13 ? canvasW * sideHeight / sideWidth : canvasW
        // const canvasH = canvasW
        ctx.canvas.width = canvasW;
        ctx.canvas.height = canvasH;
        //Convert decimal value of color to hex and pad necessary zeros the beginning.
        ctx.fillStyle = '#' + boxColor.toString(16).padStart(6, '0');
        ctx.fillRect(0, 0, canvasW, canvasW);
        ctx.fill();

        // Need to set again after resizing canvas
        ctx.font = FONT;
        // Centering cap text vertically
        if (textY) ctx.textBaseline = "middle"
        const fontY = textY ? canvasH / 2 : FONT_BORDER_Y

        ctx.textAlign = "center";
        ctx.fillStyle = 'black';
        ctx.fillText(id, canvasW / 2, fontY);
        ctx.fill();

        // Scale the canvas a square size based on the smaller side
        const smallerSide = Math.min(sideWidth, sideHeight);
        const texture = new THREE.CanvasTexture(ctx.canvas);
        texture.minFilter = THREE.LinearFilter;

        let repeatX = sideWidth / smallerSide;
        let repeatY = 1;

        // Repeat horizontally
        if (repeat) {
            texture.wrapS = THREE.RepeatWrapping;
            repeatX = 3
            texture.offset.x = repeatX / 4
        }

        texture.repeat.set(repeatX, repeatY);

        // Rotate
        if (rotate) {
            texture.center.x = 0.5
            texture.center.y = 0.5
            texture.rotation = rotate
        }

        texture.needsUpdate = true;

        return new THREE.MeshLambertMaterial({ map: texture });
    }

    function createCylinder(pos, dim, spaceDim, parcel) {
        const pos_new = getCylinderPositionInSpace(pos, dim, spaceDim)
        const textureSide = getCylinderMaterial(dim.r * 2 * Math.PI, dim.z, parcel.id, true)
        const textureCap = getCylinderMaterial(dim.r * 2, dim.r * 2, parcel.id, false, 0.33, Math.PI / 2)

        const materials = [
            textureSide,
            textureCap,
            textureCap
        ]

        let cylinder = new Cylinder(pos_new, dim, materials, parcel)
        scene.add(cylinder.getMesh())
        return cylinder
    }

    function createPresetSpace(pos, dim) {
        let boxMaterial = new THREE.MeshLambertMaterial({ color: cBoxBlue })
        let box = new BoxShape(pos, dim, boxMaterial)
        scene.add(box.getMesh());
        return box;
    }

    function createSpace(pos, dim, negativeSpaces, visualAids, axles) {

        const h = 25;
        let boxMaterial, wireMaterial, cargoSpaceFloorTexture

        // Cargo space box
        boxMaterial = new THREE.MeshLambertMaterial({ transparent: true, opacity: 1, side: THREE.BackSide, wireframe: false, map: containerTexture });

        // Floor of the cargo space box
        wireMaterial = new THREE.MeshLambertMaterial({ transparent: true, opacity: 0.8, color: '#B1B1B1' });


        const createTextureWithLogo = (sideWidth, sideHeight) => {

            const ctx = document.createElement('canvas').getContext('2d'); // Initialize canvas

            // Set canvas dimensions
            const canvasAspectRatio = sideWidth / sideHeight;
            ctx.canvas.width = 2048 * canvasAspectRatio;
            ctx.canvas.height = 2048;



            // Initialize the canvas 'color'. We want the canvas to be transparent
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.fill();

            ctx.translate(ctx.canvas.width, 0);
            ctx.scale(-1, 1); // Flip the canvas vertically, so that the logo doesn't appear inverted

            // Make the container space side with two images.
            // First image is the container texture, that is going to be draw from 0, 0 to the canvas edges
            // Second is the logo that is drawn to the middle of the canvas
            ctx.drawImage(containertextureImage, 0, 0, ctx.canvas.width, ctx.canvas.height)


            // Set the width of the logo
            let logoWidth = ctx.canvas.width / 2;

            // If logo is wider than the canvas, shrink the logoWidth to be max 80% of the canvas width
            if (logoWidth > ctx.canvas.width) {
                logoWidth = ctx.canvas.width * 0.8;
            }
            // If canvas is square or square-esque, logoWidth is 60% of the canvas width
            else if ((canvasAspectRatio <= 1.5) && (canvasAspectRatio >= 1)) {
                logoWidth = ctx.canvas.width * 0.6
            }


            // Set the height of the logo
            let logoHeight = logoWidth / 3;

            // If logo goes over the edges of the canvas, shrink the logoHeight to be max 75% of the canvas height
            if (logoHeight >= ctx.canvas.height * 0.75) {
                logoHeight = ctx.canvas.height * 0.66;
                logoWidth = logoHeight * 3;
            };

            // If canvas is square or close to square
            if ((canvasAspectRatio <= 1.8) && (canvasAspectRatio >= 1)) {
                ctx.drawImage(boxbotLogo, ((ctx.canvas.width / 2) - (logoWidth / 2)), ((ctx.canvas.height / 3.2) - (logoHeight / 2)), logoWidth, logoHeight);
            }

            // If canvas is more of a wider, or taller, rectangle
            else {
                if (canvasAspectRatio >= 2.5) { // Wide canvas
                    ctx.drawImage(boxbotLogo, (ctx.canvas.width / 2) - (logoWidth / 2), (ctx.canvas.height / 2) - (logoHeight / 2), logoWidth, logoHeight);
                } else { // Semi-wide or tall canvas
                    ctx.drawImage(boxbotLogo, (ctx.canvas.width / 2) - (logoWidth / 2), (ctx.canvas.height / 2.7) - (logoHeight / 2), logoWidth, logoHeight);
                };
            }



            const logoTexture = new THREE.CanvasTexture(ctx.canvas);
            logoTexture.minFilter = THREE.LinearFilter;
            logoTexture.needsUpdate = true;

            return new THREE.MeshLambertMaterial({ map: logoTexture, side: THREE.BackSide });
        }

        cargoSpaceFloorTexture = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 }) // Cargo space floor is transparent so it doesn't crate a visual glitching with the cargo space floor box

        let containerSideWithLogo
        let cargoSpaceMaterials = [];

        // Draw the logo on the longer side of the cargo space
        if (dim.x >= dim.z) {
            containerSideWithLogo = createTextureWithLogo(dim.x, dim.y);
            cargoSpaceMaterials = [
                boxMaterial, boxMaterial, // Ends of the box
                boxMaterial, cargoSpaceFloorTexture, // Top and bottom of the box.
                containerSideWithLogo, containerSideWithLogo // Sides of the box
            ];
        } else {
            containerSideWithLogo = createTextureWithLogo(dim.z, dim.y);
            cargoSpaceMaterials = [
                containerSideWithLogo, containerSideWithLogo, // Sides of the box
                boxMaterial, cargoSpaceFloorTexture, // Top and bottom of the box.
                boxMaterial, boxMaterial // Ends of the box
            ];
        };

        let box = new BoxShape(pos, dim, cargoSpaceMaterials);
        scene.add(box.getMesh());



        // Add fake boxes to illustrate space division VisualAid
        const createCargoSpaceBox = (wallDim, position, wireFrame, color) => {
            let frontBoxMaterial = new THREE.MeshBasicMaterial({
                wireframe: wireFrame,
                color: color.hex,
                transparent: true,
                opacity: color.rgb.a,
                depthWrite: false
            });

            const posFactor = 1.0078125;
            const pos = Object.assign({}, position);
            pos.x = -0.5 * dim.x + position.x * posFactor + 0.5 * wallDim.x;
            pos.y = -0.5 * dim.y + position.y * posFactor + 0.5 * wallDim.y;
            pos.z = -0.5 * dim.z + position.z * posFactor + 0.5 * wallDim.z;

            let frontBox = new BoxShape(pos, wallDim, frontBoxMaterial)
            scene.add(frontBox.getMesh())
        }

        visualAids.forEach(n => {
            createCargoSpaceBox(convertYZ(n.dimensions), convertYZ(n.location), n.wireframe, n.color || { hex: cBoxbotOrange, rgb: { a: 0.8 } })
        })
        negativeSpaces.forEach(n => {
            createCargoSpaceBox(convertYZ(n.dimensions), convertYZ(n.location), n.wireframe, n.color || { hex: cBoxbotOrange, rgb: { a: 0.8 } })
        })

        const createAxle = (axleLocation) => {
            const tireSize = 500;
            const tireThickness = 300;
            const rimSize = 285; // Not actual diameter, don't change

            const drawTire = (location, size, thickness, mult, yFixForMiddlePart = 0, zFixForMiddlePart = 0) => {
                const geometry = new THREE.CylinderBufferGeometry(size, size, thickness, 50);
                const material = new THREE.MeshStandardMaterial({ color: 0x000000, transparent: false, opacity: 0.8, metalness: 0.8, roughness: 0.4 });
                const cylinder = new THREE.Mesh(geometry, material);

                cylinder.position.x = -0.5 * dim.x + location;
                cylinder.position.y = -0.5 * dim.y - 2 * h - tireSize + yFixForMiddlePart;
                cylinder.position.z = (-0.5 * dim.z + thickness / 2 + zFixForMiddlePart) * mult;

                cylinder.rotateX(0.5 * Math.PI)

                scene.add(cylinder)
            }
            const drawMiddlePart = (location, size, thickness) => {
                drawTire(location, size, thickness, 1, 0, tireThickness)
            }

            const drawRims = (location) => {
                const geometry = new THREE.SphereGeometry(rimSize * 4.5, 32, 8, 0, 6.3, 0, 0.3)
                const material = new THREE.MeshStandardMaterial({ color: 0xf5f5f5, metalness: 1.0, roughness: 0.9 })
                const sphere = new THREE.Mesh(geometry, material)
                const sphere2 = new THREE.Mesh(geometry, material)
                const x = -0.5 * dim.x + location;
                const y = -0.5 * dim.y - 2 * h - tireSize;
                sphere.position.set(x, y, -0.5 * (2480 - dim.z)) // Right tire
                sphere.rotateX(0.5 * Math.PI)
                scene.add(sphere)
                sphere2.position.set(x, y, 0.5 * (2480 - dim.z)) // Left tire
                sphere2.rotateX(-0.5 * Math.PI)
                scene.add(sphere2)
            }

            drawTire(axleLocation, tireSize, tireThickness, 1) // Right tire
            drawTire(axleLocation, tireSize, tireThickness, -1) // Left tire
            drawMiddlePart(axleLocation, tireSize * 0.2, dim.z - 2 * tireThickness)
            drawRims(axleLocation, rimSize, 10)
        }

        axles.forEach(n => createAxle(n.location))

        const bPos = Object.assign({}, pos);
        bPos.y = -0.5 * dim.y - h;
        const bDim = Object.assign({}, dim);
        bDim.y = 2 * h;

        let base = new BoxShape(bPos, bDim, wireMaterial);
        scene.add(base.getMesh());

        return box;
    }

    function update() {
    }

    return {
        createBox,
        createStepBox,
        createParcelFromMaterial,
        createStepBoxFromRawData,
        createCylinder,
        createSpace,
        createPresetSpace,
        grayScaleBox,
        update,
        createArrows,
    };
}
