import { cargoSpaceTypes } from './Constants';

const IMG_SIZE = 25;
const TIRE_HEIGHT_MM = 1020;
const TIRE_WIDTH_MM = 315;
const TIRE_MARGIN = 5;
const CABIN_HEIGHT_MM = 2700;
const CABIN_WIDTH_MM = 3000;

const margin = 50;
const fontSpacing = 24;
const centerCrossHairSize = 40;
const measurementLineDistance = 30;

const defaultGray = "rgba(0, 0, 0, 0.25)";
const defaultWhite = "rgba(255, 255, 255, 1)";

export default class CanvasDrawHelper {
    constructor({ context, storageDims, cargoSpaceType, cog, cogDiff, relativeCoG, images }) {
        this.ctx = context;
        this.storageDims = storageDims;
        this.cargoSpaceType = cargoSpaceType;
        this.cog = cog;
        this.cogDiff = cogDiff;
        this.relativeCoG = relativeCoG;
        // Due to change to Y packing direction, Threer render and canvas drawing are mirrored when drawing side -> mirror also CoG!
        // RelativeCoG is the only one used for drawing.
        this.relativeCoG.y = 1 - relativeCoG.y;

        this.images = images || [];

        this.cogIsNotNaN = Object.keys(cog).find(k => isNaN(cog[k])) == null;
        this.isTrailer = this.cargoSpaceType === cargoSpaceTypes.trailer;

        let marginLeft = margin * (this.isTrailer ? 1 : 2);
        let marginBetween = margin * (this.isTrailer ? 1 : 1);
        let marginRight = margin * (this.isTrailer ? 2 : 2);
        let marginTop = margin + 10;
        let marginBottom = margin + 20;

        let widthLeftForStorage = context.canvas.width - marginLeft - marginBetween - marginRight;
        let heightLeftForStorage = context.canvas.height - marginTop - marginBottom;

        let addedWidth = this.isTrailer ? CABIN_WIDTH_MM : 0;
        let addedHeight = this.isTrailer ? TIRE_HEIGHT_MM + TIRE_MARGIN : 0;
        let pixelsFromWidth = widthLeftForStorage / (this.storageDims.x + this.storageDims.y + addedWidth);
        let pixelsFromHeight = heightLeftForStorage / (this.storageDims.z + addedHeight);

        this.mmToPixel = Math.min(pixelsFromWidth, pixelsFromHeight);
        this.storageX = this.storageDims.x * this.mmToPixel;
        this.storageY = this.storageDims.y * this.mmToPixel;
        this.storageZ = this.storageDims.z * this.mmToPixel;

        this.tire_height = TIRE_HEIGHT_MM * this.mmToPixel;
        this.tire_width = TIRE_WIDTH_MM * this.mmToPixel;
        this.cabin_height = CABIN_HEIGHT_MM * this.mmToPixel;
        this.cabin_width = CABIN_WIDTH_MM * this.mmToPixel;

        this.sideViewXStart = marginLeft + (this.isTrailer ? this.cabin_width : 0);
        this.sideViewYStart = marginTop;
        this.rearViewXStart = this.sideViewXStart + this.storageX + marginBetween;
        this.rearViewYStart = marginTop;
        this.storageBottomY = this.sideViewYStart + this.storageZ

        // Div of this canvas is centered - alter the width/height for centering to work properly
        if (pixelsFromWidth < pixelsFromHeight) {
            // Alter the height of the canvas to avoid large margin between canvas and tables
            let newCanvasHeight = marginTop + marginBottom + this.storageZ + addedHeight * this.mmToPixel;
            this.ctx.canvas.height = newCanvasHeight;
        } else {
            // Alter the width of the canvas to avoid large margin on canvas right
            let newCanvasWidth = marginLeft + marginBetween + marginRight + this.storageX + this.storageY + addedWidth * this.mmToPixel;
            this.ctx.canvas.width = newCanvasWidth;
        }


    }

    drawContainer(parcels) {
        this.drawParcelsSideView(parcels);
        this.drawParcelsRearView(parcels);

        this.loadSideCargoImage('container_side_blue')
        this.loadRearCargoImage('container_rear_blue', () => {
            // Drawing everything as 'callback' after cargoImage loading to actually draw on top of the image
            this.drawGeometricCoG()

            if (this.cogIsNotNaN) {
                this.drawCoGMeasureLinesX()
                this.drawCoGMeasureLinesY()
                this.drawCoGMeasureLinesZ()

                // Draw marks to CoG's
                this.drawCrosshairSide();
                this.drawCrosshairRear();
            }

            this.drawStorageDimensionLines()
        })
    }

    drawTrailer(parcels, axles, axleWeights) {
        this.drawCabin(axles);

        this.drawAxlesSideView(axles);
        this.drawAxlesRearView()

        this.drawParcelsSideView(parcels);
        this.drawParcelsRearView(parcels);

        this.drawTrailerSide()
        this.drawTrailerRear()

        this.ctx.font = "14px sans-serif";
        if (this.cogIsNotNaN) {
            this.drawAxleWeightArrows(axles, axleWeights)
            this.drawCoGMeasureLinesY()
            this.drawCoGMeasureLinesZ()
        }

        this.drawStorageDimensionLines()

        if (this.cogIsNotNaN) {
            // Draw marks to CoG's
            this.drawCrosshairSide();
            this.drawCrosshairRear();

            const cogtext = "Center of gravity"
            this.drawTrailerCoGTexts(cogtext)
        }
    }

    drawGeometricCoG() {
        this.ctx.beginPath();
        this.ctx.lineWidth = "1"

        // draw geometric CoG into sideView
        this.ctx.moveTo(this.sideViewXStart + this.storageX / 2, this.sideViewYStart + this.storageZ / 2 - centerCrossHairSize)
        this.ctx.lineTo(this.sideViewXStart + this.storageX / 2, this.sideViewYStart + this.storageZ / 2 + centerCrossHairSize)
        this.ctx.moveTo(this.sideViewXStart + this.storageX / 2 - centerCrossHairSize, this.sideViewYStart + this.storageZ / 2)
        this.ctx.lineTo(this.sideViewXStart + this.storageX / 2 + centerCrossHairSize, this.sideViewYStart + this.storageZ / 2)

        // draw geometric CoG into rearView
        this.ctx.moveTo(this.rearViewXStart + this.storageY / 2, this.rearViewYStart + this.storageZ / 2 - centerCrossHairSize)
        this.ctx.lineTo(this.rearViewXStart + this.storageY / 2, this.rearViewYStart + this.storageZ / 2 + centerCrossHairSize)
        this.ctx.moveTo(this.rearViewXStart + this.storageY / 2 - centerCrossHairSize, this.rearViewYStart + this.storageZ / 2)
        this.ctx.lineTo(this.rearViewXStart + this.storageY / 2 + centerCrossHairSize, this.rearViewYStart + this.storageZ / 2)
        this.ctx.stroke();
    }

    drawCoGMeasureLinesX() {
        let x = this.relativeCoG.x;
        // draw vertical line from CoG X to side view
        this.ctx.beginPath();
        this.ctx.setLineDash([5, 5]);
        this.ctx.moveTo(this.sideViewXStart + x * this.storageX, this.sideViewYStart - measurementLineDistance / 2)
        this.ctx.lineTo(this.sideViewXStart + x * this.storageX, this.storageBottomY + measurementLineDistance / 2)
        this.ctx.stroke();

        // draw horizontal measuring line for CoG X difference
        this.drawMeasurementLine(
            this.sideViewXStart + this.storageX / 2,
            this.storageBottomY + measurementLineDistance,
            this.sideViewXStart + x * this.storageX,
            this.storageBottomY + measurementLineDistance
        )

        // draw text to show the CoG X difference for side view
        this.ctx.font = "14px sans-serif";
        const xDiffLoc = this.sideViewXStart + this.storageX / 2 + ((x - 0.5) / 2) * this.storageX - 15;
        this.ctx.fillText(`${this.cogDiff.x.toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`, xDiffLoc, this.storageBottomY + measurementLineDistance + fontSpacing);
    }

    drawCoGMeasureLinesY() {
        let y = this.relativeCoG.y;
        // draw vertical line from CoG Y to rear view
        this.ctx.beginPath();
        this.ctx.setLineDash([5, 5]);
        this.ctx.moveTo(this.rearViewXStart + y * this.storageY, this.sideViewYStart - measurementLineDistance / 2)
        this.ctx.lineTo(this.rearViewXStart + y * this.storageY, this.storageBottomY + measurementLineDistance / 2)
        this.ctx.stroke();

        // draw horizontal measuring line for CoG Y difference
        this.drawMeasurementLine(
            this.rearViewXStart + this.storageY / 2,
            this.storageBottomY + measurementLineDistance,
            this.rearViewXStart + y * this.storageY,
            this.storageBottomY + measurementLineDistance,
        )

        // draw text to show the CoG Y difference for rear view
        if (this.isTrailer) {
            const yDiffLoc = this.rearViewXStart + this.storageY / 2 + ((y - 0.5) / 2) * this.storageY - 15;
            const origFillStyle = this.ctx.fillStyle;
            this.ctx.fillStyle = "rgba(255, 255, 255, 1)";
            const cogDiffYText = `${this.cogDiff.y.toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`
            this.ctx.fillRect(yDiffLoc - 3, this.storageBottomY + measurementLineDistance + fontSpacing / 2, this.ctx.measureText(cogDiffYText).width + 6, 16)
            this.ctx.fillStyle = origFillStyle;
            this.ctx.fillText(cogDiffYText, yDiffLoc, this.storageBottomY + measurementLineDistance + fontSpacing);
        } else {
            const yDiffLoc = this.rearViewXStart + this.storageY / 2 + ((y - 0.5) / 2) * this.storageY - 15;
            this.ctx.fillText(`${this.cogDiff.y.toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`, yDiffLoc, this.storageBottomY + measurementLineDistance + fontSpacing);
        }
    }

    drawCoGMeasureLinesZ() {
        let z = this.relativeCoG.z;
        // draw horizontal measuring line for Z CoG difference
        let lineStartX = this.isTrailer ? this.rearViewXStart : this.sideViewXStart;
        this.ctx.beginPath();
        this.ctx.setLineDash([5, 5]);
        this.ctx.moveTo(lineStartX - fontSpacing / 2, this.sideViewYStart + (1 - z) * this.storageZ)
        this.ctx.lineTo(this.rearViewXStart + this.storageY + fontSpacing / 2, this.sideViewYStart + (1 - z) * this.storageZ)
        this.ctx.stroke();

        let zStartX = this.isTrailer ? this.rearViewXStart + this.storageY + measurementLineDistance * 1.5 : this.rearViewXStart + this.storageY + measurementLineDistance;
        this.drawMeasurementLine(
            zStartX,
            this.rearViewYStart + (1 - z) * this.storageZ,
            zStartX,
            this.storageBottomY
        )

        // draw text to show the CoG Z difference for rear view
        let zDiffLocX = zStartX + fontSpacing / 2;
        let zDiffLocY = this.storageBottomY - (this.storageZ * z) / 2;
        if (this.isTrailer) {
            zDiffLocY += measurementLineDistance / 2;
        }
        this.ctx.fillText(`${(this.cog.z / 1000).toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`, zDiffLocX, zDiffLocY);
    }

    drawStorageDimensionLines() {
        // Draw container dimensions lines
        const dimensionsTextY = this.sideViewYStart - measurementLineDistance - fontSpacing * 0.5;
        // ================== X ==================
        this.drawMeasurementLine(
            this.sideViewXStart,
            this.sideViewYStart - measurementLineDistance,
            this.sideViewXStart + this.storageX,
            this.sideViewYStart - measurementLineDistance
        )
        this.ctx.fillText(`${(this.storageDims.x / 1000).toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`, this.sideViewXStart + this.storageX / 2 - 15, dimensionsTextY)


        // ================== Y ==================
        this.drawMeasurementLine(
            this.rearViewXStart,
            this.sideViewYStart - measurementLineDistance,
            this.rearViewXStart + this.storageY,
            this.sideViewYStart - measurementLineDistance,
        )
        this.ctx.fillText(`${(this.storageDims.y / 1000).toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`, this.rearViewXStart + this.storageY / 2 - 15, dimensionsTextY)

        // ================== Z ==================
        let zStartX = this.isTrailer ? this.rearViewXStart + this.storageY + measurementLineDistance : this.sideViewXStart - measurementLineDistance;
        this.drawMeasurementLine(
            zStartX,
            this.rearViewYStart,
            zStartX,
            this.rearViewYStart + this.storageZ,
        )
        let storageDimZText = `${(this.storageDims.z / 1000).toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})} m`;
        let textX, textY;
        if (this.isTrailer) {
            textX = this.rearViewXStart + this.storageY + measurementLineDistance * 1.5
            textY = this.rearViewYStart + this.storageZ / 4 + 5;
        } else {
            textX = this.sideViewXStart - measurementLineDistance - fontSpacing / 2 - this.ctx.measureText(storageDimZText).width;;
            textY = this.rearViewYStart + this.storageZ / 2 + 5;
        }
        this.ctx.fillText(storageDimZText, textX, textY)
    }

    drawAxleWeightArrows(axles, axleWeights) {
        const axleWeightsTextBoxY = this.storageBottomY - 40
        axles.forEach((axle, i) => {
            const w = axleWeights[i];
            const axleX = this.sideViewXStart + axle * this.storageX

            this._drawArrow(axleX, this.storageBottomY - 30, axleX, this.storageBottomY - 5);
            this._addTextBox(`${w.toLocaleString("fi-FI", {maximumFractionDigits: 0})} kg`, axleX, axleWeightsTextBoxY);
        })
    }

    drawCrosshairSide() {
        this._drawCrosshair(this.sideViewXStart + this.relativeCoG.x * this.storageX, this.sideViewYStart + (1 - this.relativeCoG.z) * this.storageZ)
    }

    drawCrosshairRear() {
        this._drawCrosshair(this.rearViewXStart + this.relativeCoG.y * this.storageY, this.rearViewYStart + (1 - this.relativeCoG.z) * this.storageZ)
    }

    drawTrailerCoGTexts(cogtext) {
        const axleWeightsTextBoxY = this.storageBottomY - 40
        const sideViewCoGTextBoxY = Math.min(this.sideViewYStart + (1 - this.relativeCoG.z) * this.storageZ - 30, axleWeightsTextBoxY - 20)
        const rearViewCoGTextBoxY = Math.min(this.rearViewYStart + (1 - this.relativeCoG.z) * this.storageZ - 30, axleWeightsTextBoxY - 20)
        this._addTextBox(cogtext, this.sideViewXStart + this.relativeCoG.x * this.storageX, sideViewCoGTextBoxY)
        this._addTextBox(cogtext, this.rearViewXStart + this.relativeCoG.y * this.storageY, rearViewCoGTextBoxY)
    }
    // =================================

    loadSideCargoImage(src) {
        this._loadCargoImage(this.sideViewXStart - 4, this.sideViewYStart - 9, this.storageX + 13, this.storageZ + 18, src)
    }

    loadRearCargoImage(src, callBack) {
        this._loadCargoImage(this.rearViewXStart - 4, this.rearViewYStart - 9, this.storageY + 11, this.storageZ + 18, src, callBack)
    }

    drawTrailerSide(){
        this._drawTrailer(this.sideViewXStart, this.sideViewYStart, this.storageX, this.storageZ);
    }

    drawTrailerRear(){
        this._drawTrailer(this.rearViewXStart, this.rearViewYStart, this.storageY, this.storageZ);
    }

    drawTier(x, yEnd) {
        const r1 = this.tire_height / 2;
        const r2 = this.tire_height / 4;
        const y = yEnd + r1

        const origFill = this.ctx.fillStyle;

        // Clear background with white background
        this.ctx.fillStyle = "rgba(255, 255, 255, 1)";
        this.ctx.beginPath();
        this.ctx.arc(x, y, r1 + 1, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.fillStyle = origFill;

        // Outer circle
        this.ctx.beginPath();
        this.ctx.arc(x, y, r1, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.stroke();

        // Inner circle
        this.ctx.beginPath();
        this.ctx.arc(x, y, r2, 0, 2 * Math.PI);
        this.ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.fillStyle = origFill;
    }

    drawAxlesSideView(axles) {
        let xStart = this.sideViewXStart;
        let yEnd = this.storageBottomY;
        let storageWidth = this.storageX;

        const originalFillStyle = this.ctx.fillStyle;
        const originalStrokeStyle = this.ctx.strokeStyle;
        const frontAxle = axles[0];
        const rearAxle = axles[1];

        this.ctx.fillStyle = defaultGray;
        this.ctx.strokeStyle = defaultGray;

        const frontAxleX = xStart + frontAxle * storageWidth;
        const rearAxleX1 = xStart + rearAxle * storageWidth - (TIRE_MARGIN + this.tire_height / 2);
        const rearAxleX2 = xStart + rearAxle * storageWidth + (TIRE_MARGIN + this.tire_height / 2);

        // boggie (teli)
        this.ctx.beginPath();
        this.ctx.rect(rearAxleX1 - 30, yEnd, rearAxleX2 - rearAxleX1 + 2 * 30, 15)
        this.ctx.stroke();

        this.drawTier(frontAxleX, yEnd + TIRE_MARGIN);
        this.drawTier(rearAxleX1, yEnd + TIRE_MARGIN);
        this.drawTier(rearAxleX2, yEnd + TIRE_MARGIN);

        this.ctx.fillStyle = originalFillStyle;
        this.ctx.strokeStyle = originalStrokeStyle;
    }

    drawAxlesRearView() {
        let xStart = this.rearViewXStart;
        let yEnd = this.storageBottomY;
        let storageWidth = this.storageY;

        const originalFillStyle = this.ctx.fillStyle;
        const originalStrokeStyle = this.ctx.strokeStyle;

        // Draw white background in case side view axels are longer than the storage itself..
        this.ctx.beginPath();
        this.ctx.fillStyle = defaultWhite;
        this.ctx.fillRect(xStart - margin, yEnd, storageWidth + margin, this.tire_height + TIRE_MARGIN * 2);
        this.ctx.stroke();

        this.ctx.fillStyle = defaultGray;
        this.ctx.strokeStyle = defaultGray;

        // Left tier
        this.ctx.beginPath();
        this.ctx.rect(xStart, yEnd + TIRE_MARGIN, this.tire_width, this.tire_height)
        this.ctx.fill();
        this.ctx.stroke();

        // Right tier
        this.ctx.beginPath();
        this.ctx.rect(xStart + storageWidth - this.tire_width, yEnd + TIRE_MARGIN, this.tire_width, this.tire_height)
        this.ctx.fill();
        this.ctx.stroke();

        // Middle part
        this.ctx.beginPath();
        this.ctx.rect(xStart + this.tire_width, yEnd + this.tire_height / 2 - 5, storageWidth - 2 * this.tire_width, 10)
        this.ctx.stroke();

        this.ctx.fillStyle = originalFillStyle;
        this.ctx.strokeStyle = originalStrokeStyle;
    }

    drawMeasurementLine(startX, startY, endX, endY, endLineHeight = 5) {
        this.ctx.beginPath();
        this.ctx.lineWidth = "1.5"
        this.ctx.setLineDash([]);
        this.ctx.moveTo(startX, startY)
        this.ctx.lineTo(endX, endY)

        // Line is vertical
        let xChange = endLineHeight;
        let yChange = 0;
        if (startY === endY) {
            // Line is horizontal
            xChange = 0;
            yChange = endLineHeight;
        }

        // Draw the endpoint lines
        this.ctx.moveTo(startX - xChange, startY - yChange)
        this.ctx.lineTo(startX + xChange, startY + yChange)

        this.ctx.moveTo(endX - xChange, endY - yChange)
        this.ctx.lineTo(endX + xChange, endY + yChange)
        this.ctx.stroke();
    }

    drawCabin(axles) {
        let storageStartX = this.sideViewXStart;
        let storageBottomY = this.storageBottomY;
        let cabinWidth = this.cabin_width;
        let cabinHeight = this.cabin_height;
        let maxX = this.sideViewXStart + axles[0] * this.storageX;
        let spaceToStorage = 10;

        const origFill = this.ctx.fillStyle;
        const origStrokeStyle = this.ctx.strokeStyle;

        this.ctx.fillStyle = defaultGray;
        this.ctx.strokeStyle = defaultGray;
        const bottomHeight = 1.5 * spaceToStorage;

        const topCabinTopLeftX = storageStartX - spaceToStorage - cabinWidth * 0.5;
        const topCabinTopRightX = storageStartX - spaceToStorage * 2;
        const topCabinTopY = storageBottomY - cabinHeight;

        const bottomCabinTopRightX = storageStartX - spaceToStorage * 2 - cabinWidth * 0.7;
        const bottomCabinTopLeftX = storageStartX - spaceToStorage - cabinWidth;
        const bottomCabinTopY = storageBottomY - cabinHeight * 0.55;
        const bottomCabinBottomY = storageBottomY + spaceToStorage

        const bottomLineTopLeftX = storageStartX - spaceToStorage - cabinWidth - spaceToStorage;
        const bottomLineTopRightX = bottomLineTopLeftX + maxX - spaceToStorage * 2
        const bottomLineTopY = storageBottomY + spaceToStorage;
        const bottomLineBottomY = bottomLineTopY + bottomHeight

        this.ctx.beginPath();

        this.ctx.moveTo(topCabinTopRightX, topCabinTopY);
        this.ctx.lineTo(topCabinTopLeftX, topCabinTopY); // 1
        this.ctx.lineTo(bottomCabinTopRightX, bottomCabinTopY); // 2

        this.ctx.lineTo(bottomCabinTopLeftX, bottomCabinTopY)

        this.ctx.lineTo(bottomCabinTopLeftX, bottomCabinBottomY); // 3
        this.ctx.lineTo(bottomLineTopLeftX, bottomCabinBottomY); // 4
        this.ctx.lineTo(bottomLineTopLeftX, bottomLineBottomY); // 5
        this.ctx.lineTo(bottomLineTopRightX, bottomLineBottomY); // 6
        this.ctx.lineTo(bottomLineTopRightX, bottomLineTopY); // 7
        this.ctx.lineTo(topCabinTopRightX, bottomLineTopY); // 8
        this.ctx.lineTo(topCabinTopRightX, topCabinTopY); // 9
        this.ctx.stroke();

        this.ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        // "Pipe"
        this.ctx.rect(
            topCabinTopRightX + 1,
            storageBottomY - cabinHeight - 20,
            10,
            bottomCabinBottomY - (storageBottomY - cabinHeight - 20)
        );
        this.ctx.stroke();
        this.ctx.beginPath();

        this.ctx.fillStyle = defaultGray;

        // On top of front axle
        const topFrontAxleX1 = Math.max(maxX - 50, topCabinTopRightX)
        const topFrontAxleX2 = Math.max(maxX + 25, topCabinTopRightX);
        this.ctx.rect(
            topFrontAxleX1,
            storageBottomY,
            topFrontAxleX2 - topFrontAxleX1,
            spaceToStorage
        );

        this.ctx.stroke();
        this.drawTier(storageStartX - spaceToStorage - cabinWidth / 2, storageBottomY + 5);
        this.ctx.fillStyle = origFill;
        this.ctx.strokeStyle = origStrokeStyle;
    }

    drawParcelsSideView(parcels) {
        let xStart = this.sideViewXStart;
        let yStart = this.storageBottomY;
        let width = this.storageX;
        let height = this.storageZ;
        let storageDims = this.storageDims;

        const toRelativeDim = dim => ({
            x: ((dim.r > 0 ? 2 * dim.r : dim.x) / storageDims.x) * width,
            z: (dim.z / storageDims.z) * height
        })
        const relativePosFunc = (dim, loc) => ({
            x: ((dim.r > 0 ?  (loc.x - dim.r) : loc.x) / storageDims.x) * width,
            z: (loc.z / storageDims.z) * height
        })
        parcels.sort((a, b) => (a.location.y + (a.packedDimensions.r > 0 ? a.packedDimensions.r : a.packedDimensions.y)) - (b.location.y + (b.packedDimensions.r > 0 ? b.packedDimensions.r : b.packedDimensions.y)))
        drawParcels(this.ctx, parcels, xStart, yStart, toRelativeDim, relativePosFunc)
    }

    drawParcelsRearView(parcels) {
        let xStart = this.rearViewXStart;
        let yStart = this.storageBottomY;
        let width = this.storageY;
        let height = this.storageZ;
        let storageDims = this.storageDims;

        // Due to change to Y packing direction, Threer render and canvas drawing were mirrored -> mirror these to match threer!
        let mirroredParcels = parcels.map(x => {
            let p = JSON.parse(JSON.stringify(x));
            const mirroredLocation = storageDims.y - (p.location.y + (p.packedDimensions.r > 0 ? 0 : p.packedDimensions.y));
            p.location.y = mirroredLocation
            return p;
        })

        const toRelativeDim = dim => ({
            x: ((dim.r > 0 ? 2 * dim.r : dim.y) / storageDims.y) * width,
            z: (dim.z / storageDims.z) * height
        })
        const relativePosFunc = (dim, loc) => ({
            x: ((dim.r > 0 ? (loc.y - dim.r) : loc.y) / storageDims.y) * width,
            z: (loc.z / storageDims.z) * height
        })
        mirroredParcels.sort((a, b) => (a.location.x + (a.packedDimensions.r > 0 ? a.packedDimensions.r : a.packedDimensions.x)) - (b.location.x + (b.packedDimensions.r > 0 ? b.packedDimensions.r : b.packedDimensions.x)))
        drawParcels(this.ctx, mirroredParcels, xStart, yStart, toRelativeDim, relativePosFunc)
    }

    _drawCrosshair(x, y) {
        let ctx = this.ctx;

        const onLoad = (img) => {
            ctx.drawImage(img, x - IMG_SIZE / 2, y - IMG_SIZE / 2, IMG_SIZE, IMG_SIZE);
            ctx.stroke();
        }

        // Images are already loaded when this is called from PDFExport - otherwise create the image on fly
        let existingImage = this.images.find(x => x.id === 'boxbot_hex');
        if (existingImage) {
            onLoad(existingImage)
        } else {
            var img = new Image();
            img.onload = () => onLoad(img)
            img.src = '/assets/boxbot_hex.png'
        }
    }

    _loadCargoImage(x, y, w, h, src, callBack) {
        this.ctx.beginPath();

        const onLoad = img => {
            const originalAlpha = this.ctx.globalAlpha;

            // Global alpha sets the images transparency
            this.ctx.globalAlpha = 0.25;
            this.ctx.drawImage(img, x, y, w, h);
            this.ctx.stroke();

            this.ctx.globalAlpha = originalAlpha;

            if (callBack) callBack()
        }

        // Images are already loaded when this is called from PDFExport - otherwise create the image on fly
        let existingImage = this.images.find(x => x.id === src);
        if (existingImage) {
            onLoad(existingImage)
        } else {
            var img = new Image();
            img.onload = () => onLoad(img);
            img.src = '/assets/' + src + '.png';
        }
    }

    _drawTrailer(x, y, w, h) {
        const originalAlpha = this.ctx.globalAlpha;
        this.ctx.beginPath();
        this.ctx.globalAlpha = 0.25;

        this.ctx.rect(x, y, w, h);
        this.ctx.stroke()
        this.ctx.globalAlpha = originalAlpha;
    }

    // Modified from https://stackoverflow.com/questions/808826/draw-arrow-on-canvas-tag
    _drawArrow(fromX, fromY, toX, toY, lineWidth = 5) {
        const headlen = 15; // length of head in pixels
        const dx = toX - fromX;
        const dy = toY - fromY;
        const angle = Math.atan2(dy, dx);
        const origLineWidth = this.ctx.lineWidth;
        this.ctx.lineWidth = lineWidth;

        this.ctx.beginPath();

        this.ctx.moveTo(fromX, fromY);
        this.ctx.lineTo(toX, toY - headlen * Math.sin(angle - Math.PI / 6));
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(toX, toY)
        this.ctx.lineTo(toX - headlen * Math.cos(angle - Math.PI / 6), toY - headlen * Math.sin(angle - Math.PI / 6));
        this.ctx.lineTo(toX - headlen * Math.cos(angle + Math.PI / 6), toY - headlen * Math.sin(angle + Math.PI / 6));
        this.ctx.fill();

        this.ctx.lineWidth = origLineWidth;

        this.ctx.stroke();
    }

    _addTextBox(text, xCenter, yStart) {
        const origFill = this.ctx.fillStyle;
        const origStyle = this.ctx.strokeStyle;

        var textWidth = this.ctx.measureText(text).width;

        this.ctx.beginPath();
        this.ctx.strokeStyle = "rgba(0, 0, 0, 1)";
        this.ctx.fillStyle = "rgba(255, 255, 255, 1)";
        this.ctx.rect(xCenter - textWidth / 2 - 5, yStart - 15, textWidth + 10, 20);
        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.fillStyle = "rgba(0, 0, 0, 1)";
        this.ctx.beginPath();
        this.ctx.fillText(text, xCenter - textWidth / 2, yStart)
        this.ctx.stroke();

        this.ctx.fillStyle = origFill;
        this.ctx.strokeStyle = origStyle;
    }




}

const drawParcels = (ctx, parcels, xStart, yStart, relativeDimFunc, relativePosFunc) => {
    const origFill = ctx.fillStyle;
    const origStyle = ctx.strokeStyle;

    ctx.beginPath();
    ctx.strokeStyle = "rgba(0, 0, 0, 0.3)";

    parcels.forEach(parcel => {
        const relDim = relativeDimFunc(parcel.packedDimensions);
        const relPos = relativePosFunc(parcel.packedDimensions, parcel.location);

        // Draw white background to hide the overlab
        ctx.beginPath();
        ctx.fillStyle = defaultWhite;
        ctx.fillRect(xStart + relPos.x, yStart - relPos.z - relDim.z, relDim.x, relDim.z);
        ctx.stroke();

        // Draw the actual parcel
        ctx.beginPath();
        ctx.fillStyle = "rgba(255, 160, 91, 0.6)"; // light orange
        ctx.rect(xStart + relPos.x, yStart - relPos.z - relDim.z, relDim.x, relDim.z);
        ctx.fill();
        ctx.stroke();
    })

    ctx.fillStyle = origFill;
    ctx.strokeStyle = origStyle;
}
