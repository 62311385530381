import React from 'react'

import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormLabel from '@material-ui/core/FormLabel'
import Paper from '@material-ui/core/Paper'

import DialogTitle from '../core/DialogTitle'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { asyncPdfExport } from './PdfExport'
import { showOverlay, hideOverlay, showNotification, showNotificationDialog } from '../app/AppActions'
import { colors } from '../theme'
import { notificationDialogSeverity } from '../core/Constants'

/////////////////////////////////////////////////////////////////////

const imageWidth = 250;
const simpleExportImage = <img width={imageWidth} src={process.env.PUBLIC_URL + '/assets/pdf_export_modal_simple_icon.png'} alt='' />
const fullExportImage = <img width={imageWidth} src={process.env.PUBLIC_URL + '/assets/pdf_export_modal_full_icon.png'} alt='' />
const stepByStepExportImage = <img width={imageWidth} src={process.env.PUBLIC_URL + '/assets/pdf_export_modal_step_icon.png'} alt='' />

const PdfExportModal = (props) => {
    const { solution, cargoSpaceIndexes, open, onClose, reduxState, reduxDispatch, classes } = props;
    const { onShowOverlay, onHideOverlay, onShowNotificationDialog } = reduxDispatch;
    const { user } = reduxState;

    // Single function call not to copy-paste 3 times
    const doExport = (options) => {
        onClose();
        const exportMessage = "Exporting PDF...";
        const errorMessage = 'Something went wrong';
        asyncPdfExport({
            ...options,
            solution,
            cargoSpaceIndexes,
            organization: user.organization,
            onProgressUpdate: progress => onShowOverlay(exportMessage, progress),
            onFinish: () => {
                onHideOverlay();
                // onClose();
            }
        }).catch(e => {
            console.error(e)
            onShowNotificationDialog(
                errorMessage,
                'Error',
                notificationDialogSeverity.error
            )
        })
    }

    // Named function to ease reading and apply the export options
    const onSimpleExport = () => doExport({ overViewPage: true });
    const onFullExport = () => doExport({ cargoList: true, overViewPage: true, weightPage: true });
    const onStepByStepExport = () => doExport({ stepByStepPage: true });

    let simpleExport, fullExport, stepByStepExport;
    if (open) {

        const PaperButton = ({ className = classes.paperStyle, onClick, children }) => (
            <Paper
                className={className}
                elevation={4}
            >
                <ButtonBase
                    onClick={onClick}
                    className={classes.buttonBaseStyle}
                >
                    <Box className={classes.flexColumn}>
                        {children}
                    </Box>
                </ButtonBase>
            </Paper>
        )

        simpleExport = (
            <PaperButton onClick={onSimpleExport}>
                <FormLabel className={classes.primaryTextStyle}>
                    Simple
                </FormLabel>
                <div className={classes.imageContainer}>
                    {simpleExportImage}
                </div>
                <FormLabel className={classes.secondaryTextStyle}>
                    - 3D Model
                </FormLabel>
            </PaperButton>
        )

        fullExport = (
            <PaperButton onClick={onFullExport}>
                <FormLabel className={classes.primaryTextStyle}>
                    Full
                </FormLabel>
                <div className={classes.imageContainer}>
                    {fullExportImage}
                </div>
                <FormLabel className={classes.secondaryTextStyle}>
                    - 3D Model
                    <br></br>
                    - Cargo List
                    <br></br>
                    - Weight distribution
                </FormLabel>
            </PaperButton>
        )

        stepByStepExport = (
            <PaperButton onClick={onStepByStepExport}>
                <FormLabel className={classes.primaryTextStyle}>
                    Step-by-Step
                </FormLabel>
                <div className={classes.imageContainer}>
                    {stepByStepExportImage}
                </div>
                <FormLabel className={classes.secondaryTextStyle}>
                    - Loading steps
                </FormLabel>
            </PaperButton>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={false}
            maxWidth={'lg'}
        >
            <DialogTitle onClose={onClose} id='pdf-export-dialog-title'>{'Export PDF'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='pdf-export-dialog-description'>
                    Choose export type:
                </DialogContentText>
                <Box style={{ margin: 10 }} display='flex' justifyContent='center'>
                    {simpleExport}
                    {fullExport}
                    {stepByStepExport}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    reduxState: {
        user: state.user,
        autoSave: state.optimizer.autoSave
    }
})

const mapDispatchToProps = dispatch => ({
    reduxDispatch: {
        onShowOverlay: (message, progress) => dispatch(showOverlay(message, progress)),
        onHideOverlay: (message) => {
            dispatch(hideOverlay());
            if (message)
                dispatch(showNotification(message));
        },
        onShowNotificationDialog: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity));
        }
    }
})

const styles = theme => ({
    primaryTextStyle: {
        marginTop: 25, fontSize: 20, textTransform: 'none', textAlign: 'center', fontWeight: 500
    },
    secondaryTextStyle: {
        marginTop: 5, fontSize: 14, textTransform: 'none', textAlign: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(3)
    },
    paperStyle: {
        width: '330px',
        height: '380px',
        margin: theme.spacing(2),
        transition: "all 300ms ease",
        '&:hover': {
            border: `2px solid ${colors.bbOrange}`,
            transform: 'scale(1.05)',
            transition: "all 300ms ease",
        }
    },
    buttonBaseStyle: {
        height: '100%',
        width: '100%',
        alignItems: 'flex-start'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PdfExportModal))
