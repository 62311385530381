/**
 * Disposes material or an array of material and all it's associated textures
 * @param {Three.material} material
 */
export const disposeMaterialAndTextures = (material) => {
    if (Array.isArray(material)) {
        material.forEach(disposeSingleMaterial);
    } else {
        disposeSingleMaterial(material);
    }
}

const disposeSingleMaterial = (material) => {
    if (material.map) material.map.dispose();
    if (material.lightMap) material.lightMap.dispose();
    if (material.bumpMap) material.bumpMap.dispose();
    if (material.normalMap) material.normalMap.dispose();
    if (material.specularMap) material.specularMap.dispose();
    if (material.envMap) material.envMap.dispose();
    if (material.alphaMap) material.alphaMap.dispose();
    if (material.aoMap) material.aoMap.dispose();
    if (material.displacementMap) material.displacementMap.dispose();
    if (material.emissiveMap) material.emissiveMap.dispose();
    if (material.gradientMap) material.gradientMap.dispose();
    if (material.metalnessMap) material.metalnessMap.dispose();
    if (material.roughnessMap) material.roughnessMap.dispose();

    material.dispose();   // disposes any programs associated with the material
}
