import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const WorkersDrawer = (props) => {
    const { classes } = props;

//////////////////////////////////////////////////////////////////////////////

    return (
        <Drawer
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor={'left'}
            open={props.open}
            onClose={props.onClose}
        >
            {/* Drawer header */}
            <Typography variant='h5' className={classes.drawerHeader}>
                All users working on this loading job:
            </Typography>

            <Divider />

            {/* Drawer content */}
            <List>
                {props.currentWorkers.map((name, i) => {
                    return (
                        <ListItem key={i}>
                            <Chip label={name} />
                        </ListItem>
                    )
                })}
            </List>

            <Divider />
        </Drawer>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    drawer: theme.navBar.drawer,
    drawerPaper: theme.navBar.mobile,
    drawerHeader: {
        padding: 10,
    },
    chip: {
        overflow: 'auto'
    },
});


export default withStyles(styles)(WorkersDrawer);
