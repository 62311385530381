
/**
 * Available rules for Loading plan
 */
export const loadingPlanRules = {
    storageSelectionStrategy: {
        name: 'storageSelectionStrategy',
        description: '',
        defaultValue: 'SimpleSingle',
        values: {
            SimpleSingle: {
                name: 'SimpleSingle',
                displayName: 'Single Cargo Space Type',
                img: '/assets/StorageStrategy-Single.png'
            },
            UseSecondaryOnLast: {
                name: 'UseSecondaryOnLast',
                displayName: 'Two Cargo Space Types',
                img: '/assets/StorageStrategy-Secondary.png'
            }
        },
    }
}

/**
 * Creates a copy of the available loadingPlanRules with their default values
 * @returns {Object}
 */
export const defaultLoadingPlanRuleObject = () => {
    return Object.keys(loadingPlanRules).reduce((dict, rule) => ({ ...dict, [loadingPlanRules[rule].name]: loadingPlanRules[rule].defaultValue }), {});
};

/**
 * Available rules for cargo space
 */
export const cargoSpaceRules = {
    useWeightDistributionFitness: {
        name: 'useWeightDistributionFitness',
        defaultValue: false,
        displayName: 'Optimize Weight Distribution',
        description: 'Weight distribution is taken account when loading plan is optimized.',
    }
}

/**
 * Creates a copy of the available CargoSpaceRules with their default values
 * @returns {Object}
 */
export const defaultCargoSpaceRulesObject = () => {
    return Object.keys(cargoSpaceRules).reduce((dict, rule) => ({ ...dict, [cargoSpaceRules[rule].name]: cargoSpaceRules[rule].defaultValue }), {});
}

/**
 * Available user roles with properties: name, displayName and description
 */
export const roleObjects = {
    officeUser: {
        name: 'officeUser',
        displayName: 'officeUser',
        description: 'User'
    },
    loadingOperationsUser: {
        name: 'loadingOperationsUser',
        displayName: 'loadingOperationsUser',
        description: 'Only tablet'
    },
    officeAdmin: {
        name: 'officeAdmin',
        displayName: 'officeAdmin',
        description: 'Manage users'
    },
    superadmin: {
        name: 'superadmin',
        displayName: 'superadmin',
        description: 'All capabilities'
    }
}

export const stackabilityLookup = {
    NonStackable: "Non-Stackable",
    Stackable: "Stackable",
    Overstow: "Overstow"
}

export const lashingLookup = {
    No: "No",
    YesReserveSpace: "Yes"
}

export const convertYZ = dim => {
    let newDim = Object.assign({}, dim)
    newDim.y = dim.z
    newDim.z = dim.y
    return newDim
}

export const getShrunkenDimensions = dim => {
    const shrinkConstant = 4;
    let shrunkenDimensions = Object.assign({}, dim);

    shrunkenDimensions.x -= shrinkConstant;
    shrunkenDimensions.y -= shrinkConstant;
    shrunkenDimensions.z -= shrinkConstant;
    shrunkenDimensions.r -= shrinkConstant;

    return shrunkenDimensions;
}

export const jobStatus = {
    unknown: 0,
    created: 1,
    started: 2,
    completed: 3,
    canceled: 4,
    failed: 5,
};

export const sendModes = {
    ready: 0,
    active: 1,
    standby: 2,
    error: 3,
    canceled: 4,
};

export const replaceCommas = text => text.toString().split(",").join(".").replace(/(.*)\./, x => x.replace(/\./g, '') + '.')

export const cargoSpaceTypes = {
    undefined: 0,
    container: 'Container',
    trailer: 'Trailer',
}

export const progressSteps = {
    cargoData: 0,
    cargoSpace: 1,
    optimize: 2,
    loading: 3
}

export const planStates = {
    clear: 0,
    optimize: 3,
    inspect: 4,
    loading: 5
}

export const loadingDirections = {
    top: "Top",
    back: "Back",
    side: "Right",
};

export const importModes = {
    append: 0,
    overWrite: 1
}

// Message severity for notificationDialog
export const notificationDialogSeverity = {
    none: 0,
    success: 1,
    info: 2,
    warning: 3,
    error: 4
}

/**
 * This value is used when selecting a new cargo space arrangement.
 * This value represents the arrangementId of an arrangement. 0 is the Standard arrangement
 * of a cargo space, and 1 is given to the spread arrangement, that is found in
 * cs.alternateArrangements.
 * NOTE: This value WILL BREAK when/if we have a cargo space with more than 1 alternative arrangement.
 */
export const cargoSpaceArrangement = {
    standard: 0,
    spread: 1
}
