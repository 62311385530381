import * as THREE from 'three';

export default (pos, dim, material)  => {
    let box = new THREE.BoxBufferGeometry(dim.x, dim.y, dim.z);
    let mesh = new THREE.Mesh(box, material);
    mesh.position.x = pos.x;
    mesh.position.y = pos.y;
    mesh.position.z = pos.z;

    function getMesh() {
        return mesh;
    }

    return {
        getMesh,
    };
}