import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { stackabilityLookup, lashingLookup } from "../../../core/Constants";

import { connect } from "react-redux";

//////////////////////////////////////////////////////////////////////////////

const ParcelTable = (props) => {

    const { parcels, isThisRowSelected, onRowSelected } = props;

    const columns = [
        "",
        "Id",
        `Length (mm)`,
        `Width (mm)`,
        `Height (mm)`,
        "Weight (kg)",
        "Stackability",
        "Lashing",
        "Description",
    ];

    return (
        <TableContainer style={{ height: "100%" }}>
            <Table
                stickyHeader
                style={{ width: "100%", height: "100%" }}
                padding="checkbox"
            >

                <TableHead>
                    <TableRow>
                        {columns.map((x) => (
                            <TableCell
                                key={x}
                                style={{
                                    whiteSpace: "break-spaces",
                                    backgroundColor: "#fff",
                                }}
                            >
                                {x}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {parcels.map((parcel, i) => (
                        <TableRow
                            key={i}
                            hover
                            selected={isThisRowSelected(i)}
                            onClick={() => onRowSelected(i)}
                        >
                            <TableCell>
                                <Checkbox checked={isThisRowSelected(i)} />
                            </TableCell>
                            <TableCell>{parcel.id}</TableCell>
                            {["x", "y", "z"].map((dim) => (
                                <TableCell key={dim}>
                                    {parcel.dimensions[dim]}
                                </TableCell>
                            ))}
                            <TableCell>{parcel.weight || ""}</TableCell>
                            <TableCell>
                                {stackabilityLookup[parcel.stackability] || ""}{" "}
                            </TableCell>
                            <TableCell>
                                {lashingLookup[parcel.lashing] || ""}{" "}
                            </TableCell>
                            <TableCell>{parcel.description || ""}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
    );
};

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        cargoDataUnit: state.unit.cargoData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelTable);
