import { cloneDeep } from 'lodash';


/**
 * Crate a display solution that is used display the correct cargo space arrangements
 * without making changes to the original solution. This function selects the correct
 * arrangements for each cargo space and then returns a solution that has replaced each
 * cargo space with the arrangement that has been selected for that cargo space.
 * // NOTE: Never save the display solution to database! Display solutions are for
 * displaying information only!
 * @param {Object} originalSolution
 * @returns copy of solution with the cargo spaces converted to use the correct arrangements.
 */
export const convertSolutionToDisplaySolution = (originalSolution) => {

    if (originalSolution == null) {
        return;
    }

    let sol = cloneDeep(originalSolution);
    const originalCargoSpaces = sol.cargoSpaces;

    /**
     * If one, or more, cargo spaces are using alternate arrangements, then
     * take each relevant cargo space and replace the cargo space with the
     * alternative arrangement.
     */
    let alternativeCargoSpaces = originalCargoSpaces.map(cs => {
        return convertCargoSpaceToDisplayCargoSpace(cs);
    });

    sol.cargoSpaces = alternativeCargoSpaces;
    return sol;
}


/**
 * Take cargo space and check if it's using an alternative arrangement.
 * If cargo space is using an alternative arrangement, replace cargo space information
 * with information from the selected arrangement. Also, null alternateArrangments
 * for simplicity, and when using an alternative arrangment, remove storages, because
 * all arrangements use the same storages.
 * @param {Object} originalCargoSpace
 * @returns
 */
export const convertCargoSpaceToDisplayCargoSpace = (originalCargoSpace) => {

    if (originalCargoSpace == null) {
        return;
    };

    let cs = cloneDeep(originalCargoSpace);

    if (
        cs.alternateArrangements &&
        cs.selectedArrangement > 0 && // selectedArrangement > 0 ==> an alternative arrangement has been chosen
        cs.alternateArrangements.length > 0
    ) {
        let alternativeArrangement = cs.alternateArrangements.find(aa => aa.arrangementId === cs.selectedArrangement);
        // Remove all arrangement information, because we don't need this in a displaySolution.
        alternativeArrangement.alternateArrangements = null;
        alternativeArrangement.arrangementId = null;
        alternativeArrangement.selectedArrangement = null;
        // Remove storages from an alternative arrangement, because each arrangement of a cargo space uses same storages.
        delete alternativeArrangement.storages;
        return { ...cs, ...alternativeArrangement };
    } else {
        cs.alternateArrangements = null;
        cs.arrangementId = null;
        cs.selectedArrangement = null;
        return cs;
    }
}
