import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core/styles';

import DialogTitle from '../../core/DialogTitle';
import { notificationDialogSeverity } from '../../core/Constants';

import { connect } from 'react-redux';
import { showNotification, showNotificationDialog } from '../../app/AppActions';
import { setSolutions } from '../../solutions/SolutionActions';
import { solutions } from '../../api';

import { isEqual, cloneDeep } from 'lodash';


//////////////////////////////////////////////////////////////////////////////


const SaveSolutionDialog = (props) => {
    const { classes } = props;

    const { onSaveSolution, onUpdateSolution } = props.reduxDispatch;

    const [inputText, setInputText] = useState('');
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [nameError, setNameError] = useState({ error: false, message: '', solutionInLoading: false }); // If user has inputted a name of a solution that is already in loading, we don't let user overwrite the solution.
    const [nameValidation, setNameValidation] = useState({ typing: false, valid: false });
    const [overwriteDialogOpen, setOverwriteDialogOpen] = useState(false);

    // const [saveButtonText, setSaveButtonText] = useState('SAVE');
    // const [saveButtonTooltip, setSaveButtonTooltip] = useState('Save loading plan');
    const [saveButtonInfo, setSaveButtonInfo] = useState({ text: 'SAVE', tooltip: 'Save loading plan' });

    const [allSolutionNames, setAllSolutionNames] = useState([]);
    const [validationTimeout, setValidationTimeout] = useState(null);



    /**s
     * When user opens the dialog, set the text input on the textfield
     * automatically to props.solution.name.
     * When user closes the dialog, close the overwrite-dialog and reset
     * name-error.
     */
    useEffect(() => {

        // Dialog is closed, reset components inside dialog.
        if (!props.open) {
            if (overwriteDialogOpen) setOverwriteDialogOpen(false); // Close overwrite-dialog
            clearTimeout(validationTimeout);
        }

    }, [overwriteDialogOpen, props.open, validationTimeout]);

    /**
     * Map out the names of all solutions.
     * The resulting array will be used to check if the name user is trying to input already exists.
     */
    useEffect(() => {
        if (props.allSolutions && props.allSolutions.length > 0) setAllSolutionNames(props.allSolutions.map(sol => sol.name));
        else setAllSolutionNames([]);
    }, [props.allSolutions]);



    /**
     * Enable save button, if user has stopped typing, and the name is valid.
     */
    useEffect(() => {
        if (!nameValidation.typing && nameValidation.valid) {
            setDisableSaveButton(false);
        }
    }, [nameValidation]);



    /**
     * Event handler for textfield onChange-events.
     * When ever user types a name on the textfield, we want to check the inputted name against
     * all existing solution names that the user has access to. If user has inputted an already
     * existing name, then we change the save-button to an overwrite button. To limit the number
     * of checks that are made, we validate the name only when user has stopped typing for long
     * enough.
     * @param {String} e is the inputted string.
     */
    const handleInputChange = (e) => {

        clearTimeout(validationTimeout); // With each key stroke, clear the timeout function, so we aren't calling the API after each letter

        // User is typing
        setNameValidation({ typing: true, valid: false });

        setDisableSaveButton(true); // Disable Save-button while user is typing
        setInputText(e); // Set inputName to be what ever the user is typing

        // Clear exiting nameError when typing
        if (nameError) {
            setNameError(s => ({ ...s, error: false, message: '', solutionInLoading: false }));
        };

        // Check if given input is empty
        if (!e) {
            setNameError(s => ({ ...s, error: true, message: 'Name cannot be empty' }));
        };


        // User has stopped typing, so send a API-request to get solutions with the name e
        const validateName = () => {

            setNameValidation({ typing: false, valid: false });

            if (allSolutionNames.includes(e)) { // Inputted name already exists
                // If there's an existing solution with the name e, check if that solution has been sent to the loading assistant or not
                const exSolution = props.allSolutions.filter(sol => sol.name === e); // Get the existing solution with name e
                const exSolutionInLoading = exSolution[0].cargoSpaces.some(cargospace => cargospace.packingStatus && cargospace.packingStatus > 0); // Check if the existing solution has been sent to loading assistant

                let nameErrorMsg = 'Name already exists';
                if (exSolutionInLoading) nameErrorMsg = 'Name already in use';

                setNameError(s => ({ ...s, error: true, message: nameErrorMsg, solutionInLoading: exSolutionInLoading }))
                setSaveButtonInfo({ text: 'OVERWRITE', tooltip: 'Overwrite existing loading plan' }); // If we're overwriting a solution, change the save button text to reflect that
                setNameValidation((previousNameValidation) => ({ ...previousNameValidation, valid: true }));
            }
            else { // Inputted names doesn't exists yet
                setNameValidation((previousNameValidation) => ({ ...previousNameValidation, valid: true }));
                setSaveButtonInfo({ text: 'SAVE', tooltip: 'Save loading plan' });
            }
        };

        setValidationTimeout(setTimeout(validateName, 750));
    };




    /**
     * Handle saving a new copy of the current solution using a different name.
     */
    const handleSave = () => {

        if (props.solution == null) {
            showNotificationDialog("Cannot save a solution that doesn't exist!", "Error", notificationDialogSeverity.error);
            return;
        }

        // If user tries to save solution with an empty name, show notification about this.
        // NOTE: This should never happen. The save button is disabled if inputText is empty, but this is here to catch any erronious situations.
        if (inputText.length === 0) {
            showNotification('Cannot save solution with empty name');
            return;
        };

        // If name error is in effect, open the overwrite dialog.
        if (nameError.error) {
            setOverwriteDialogOpen(true);
            return;
        }

        // Make a copy of the solution-object and replace the name.
        let newSolution = cloneDeep(props.solution);

        // If user is saving a copy of a solution that is in loading, then remove loadingAssistant specific fields from solution.
        if (newSolution.cargoSpaces.some(cs => cs.packingStatus && cs.packingStatus > 0)) {
            newSolution.cargoSpaces = newSolution.cargoSpaces.map(cs => {
                delete cs.lastMinuteChange;
                delete cs.forcedToCompletion;
                delete cs.loadingCompletedDate;
                return {
                    ...cs,
                    packingStatus: 0,
                    progress: 0,
                    packedParcels: cs.packedParcels.map(p => { p.loaded = false; return p }),
                    assignments: [],
                };
            });
        };

        // Replace the existing solution name with the inputted one.
        newSolution.name = inputText;

        // Delete the _id field to make sure that the new solution gets a new mongo-id.
        delete newSolution._id;

        // Save newSolution
        onSaveSolution(newSolution)
            .then(res => {
                if (res) {
                    newSolution._id = res;
                    props.onSolutionSaved(newSolution);
                }
            })
            .catch(err => {
                if (err.code === 409) { // Conflict
                    setNameError(s => ({ ...s, error: true, message: 'Name already exists' }));
                    setOverwriteDialogOpen(true);
                }
            });
    };




    const handleOverwrite = () => {
        if (props.solution == null) {
            showNotificationDialog('Cannot overwrite solution with non-existent solution.', 'Error', notificationDialogSeverity.error);
            return;
        }

        // If user tries to save solution with an empty name, show notification about this.
        // NOTE: This should never happen. The save button is disabled if inputText is empty, but this is here to catch any erronious situations.
        if (inputText.length === 0) {
            showNotification('Cannot save solution with empty name');
            return;
        };

        let sol = cloneDeep(props.solution);
        sol.name = inputText;
        onUpdateSolution(inputText, sol)
            .then(res => {
                props.onSolutionSaved(res);
            })
            .catch(err => {
                showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error);
            })
    }



//////////////////////////////////////////////////////////////////////////////


    const saveDialog = (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            onEnter={() => {
                if (props.allSolutions && props.allSolutions.map(s => s.name).includes(props.solution?.name)) {
                    setNameError(s => ({ ...s, error: true, message: 'Name already exists', solutionInLoading: false}))
                    setSaveButtonInfo({ text: 'OVERWRITE', tooltip: 'Overwrite existing loading plan' });
                }
            }}
            onEntering={() => {
                if (props.solution != null) setInputText(props.solution.name)
                if (props.allSolutions && props.allSolutions.length > 0) {
                    const allNames = props.allSolutions.map(sol => sol.name);
                    if (!isEqual(allNames, allSolutionNames)) setAllSolutionNames(allNames);
                };
            }}
        >
            <DialogTitle onClose={props.onClose}>
                Save loading plan?
            </DialogTitle>
            <DialogContent>
                {props.disableChanges &&
                    <>
                        <DialogContentText>
                            Enter another name to save a copy of the loading plan for editing. <br/>
                            The saved copy will only include the loadin plan, and any loading status related information will be reset.
                        </DialogContentText>
                    </>
                }
                <TextField
                    label='Name'
                    value={inputText}
                    helperText={nameError.message}
                    onChange={(e) => handleInputChange(e.target.value)}
                    error={inputText === ''}
                    style={{ height: '75px' }} // To stop the dialog height jumping around when the helperText is hidden/displayed.
                />
            </DialogContent>
            <DialogActions>
                <Box className={classes.saveButtonContainer}>
                    <CircularProgress
                        size={26}
                        style={{ display: disableSaveButton && !nameError.error ? '' : 'none' }}
                    />
                    <Tooltip
                        title={saveButtonInfo.tooltip}
                        placement='top-start'
                    >
                        <span>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={
                                    !props.solution ||
                                    (nameError.error && props.disableChanges) ||
                                    disableSaveButton ||
                                    inputText === ''
                                }
                                onClick={() => handleSave()}
                                style={{ width: 100 }}
                            >
                                {saveButtonInfo.text}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </DialogActions>
        </Dialog>
    );


    const overwriteDialog = (
        <Dialog
            open={overwriteDialogOpen}
            onClose={() => setOverwriteDialogOpen(false)}
        >
            <DialogTitle onClose={() => setOverwriteDialogOpen(false)}>
                {nameError.solutionInLoading ?
                    'Cannot overwrite loading plan'
                    :
                    'Overwrite existing loading plan?'
                }
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {nameError.solutionInLoading ?
                        'Cannot overwrite loading plan that has already been sent to loading.'
                        :
                        <span style={{ font: 'inherit' }}>
                            A loading plan with name
                            <b style={{ fontFamily: 'inherit' }}> {inputText} </b>
                            already exists. Overwrite loading plan?
                        </span>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {nameError.solutionInLoading ?
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => setOverwriteDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    :
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => handleOverwrite()}
                    >
                        Overwrite
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )


    return (
        <>
            {saveDialog}
            {overwriteDialog}
        </>
    );
};


//////////////////////////////////////////////////////////////////////////////


SaveSolutionDialog.propTypes = {
    solution: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    disableChanges: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            onSaveSolution: solution => {
                return new Promise((resolve, reject) => {
                    solutions.create(solution)
                        .then(res => {
                            if (!res) {
                                resolve(null);
                            } else {
                                dispatch(showNotification(`Loading plan saved as ${solution.name}`));
                                resolve(res._id);
                                solutions.list()
                                    .then(res => dispatch(setSolutions(res)))
                                    .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
                            }
                        })
                        .catch(err => {
                            dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error));
                            reject(err.message);
                        })
                })
            },
            onUpdateSolution: (name, solution) => {
                return new Promise((resolve, reject) => {
                    solutions.find({ query: { name: name } })
                        .then(solutionToUpdate => {
                            solutions.update(solutionToUpdate.data[0]._id, solution)
                                .then((res) => {
                                    dispatch(showNotification(`Loading plan saved as ${res.name} (overwritten)`))
                                    resolve(res)
                                })
                                .catch(err => {
                                    if (err.code === 409) {
                                        reject(err)
                                    } else {
                                        dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error));
                                    }
                                    resolve(solution)
                                })
                        })
                        .catch(err => {
                            dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.warning));
                            reject(err)
                        })
                })
            }
        }
    }
}


const styles = (theme) => ({
    saveButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: '10px'
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SaveSolutionDialog));
