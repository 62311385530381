import React, { useState } from 'react';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography';

import BarChartIcon from '@material-ui/icons/BarChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';

import { lmcStatus } from '../Constants'
import { colors } from '../../theme';

import { isEqual } from 'lodash';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const FabButtonContainer = (props) => {
    const { classes } = props;

    // State that controls opening/closing of the 'more options'-Fab button menu
    const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = useState(null);

    const SHOW_BETA_PHASE_FABS = true;


//////////////////////////////////////////////////////////////////////////////

    return (
        <Box className={classes.fabButtonContainer}>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <Badge badgeContent={props.currentWorkers.length} color='primary' classes={{ badge: classes.badgeTransform }}>
                        <Fab
                            disabled={props.currentWorkers.length === 0 || !props.loadingPlanSelected}
                            className={classes.fabButton}
                            onClick={props.onDrawerButtonClick}
                        >
                            <PersonIcon />
                        </Fab>
                    </Badge>
                </Grid>
                <Grid item>
                    <Fab
                        className={classes.fabButton}
                        onClick={props.onWeightDistributionButtonClick}
                    >
                        <BarChartIcon />
                    </Fab>
                </Grid>
                <Grid
                    item
                    className={SHOW_BETA_PHASE_FABS ? classes.fabButtonGridItemWithLabel : classes.hiddenFabButton}
                >
                    <Fab
                        style={{ backgroundColor: props.editModeState ? 'lightGreen' : 'red' }}
                        onClick={props.onEditModeButtonClick}
                        disabled={
                            // If loading plan hasn't been selected, or when loading plan has been completed, disable edit mode -button
                            !props.loadingPlanSelected ||
                            props.solution.cargoSpaces[props.solution.cargoSpaceIndex].packingStatus === 3 ||
                            (props.solution.cargoSpaces[props.solution.cargoSpaceIndex].lastMinuteChange?.status > lmcStatus.none &&
                                !isEqual(props.solution.cargoSpaces[props.solution.cargoSpaceIndex].lastMinuteChange?.user, props.user._id))
                        }
                    >
                        <EditIcon />
                    </Fab>
                    <Typography>
                        {`${props.editModeState ? 'Edit mode enabled' : 'Edit mode disabled'}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Fab
                        className={SHOW_BETA_PHASE_FABS ? classes.fabButton : classes.hiddenFabButton}
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        disabled={!props.loadingPlanSelected}
                        onClick={(event) => {
                            setMoreOptionsMenuAnchorEl(event.currentTarget)
                        }}
                    >
                        <MoreVertIcon />
                    </Fab>
                </Grid>
                <Grid
                    item
                    className={SHOW_BETA_PHASE_FABS ? '' : classes.hiddenFabButton}
                >
                    <Menu
                        PaperProps={{
                            className: classes.moreOptionsFabButtonsPaper
                        }}
                        MenuListProps={{
                            className: classes.moreOptionsFabButtonsMenuList
                        }}
                        /**
                         * getContentAnchorEl has to be set to null for the anchorOrigin.vertical to work properly.
                         * This is an issue with material-ui, not with our program itself.
                         */
                        getContentAnchorEl={null}
                        id='more-options'
                        anchorEl={moreOptionsMenuAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        elevation={0}
                        open={Boolean(moreOptionsMenuAnchorEl)}
                        onClose={() => setMoreOptionsMenuAnchorEl(null)}
                    >
                        <Grid container spacing={2} direction='column'>
                            <Grid item className={classes.fabButtonGridItemWithLabel}>
                                <Fab
                                    className={classes.fabButton}
                                    size='small'
                                    onClick={props.onForceCompletionButtonClick}
                                    disabled={
                                        // If loading plan hasn't been selected, or when loading plan has been completed, disable force completion -button
                                        !props.loadingPlanSelected ||
                                        props.solution.cargoSpaces[props.solution.cargoSpaceIndex].packingStatus === 3 ||
                                        (props.solution.cargoSpaces[props.solution.cargoSpaceIndex].lastMinuteChange?.status > lmcStatus.none &&
                                            !isEqual(props.solution.cargoSpaces[props.solution.cargoSpaceIndex].lastMinuteChange?.user, props.user._id))
                                    }
                                >
                                    <CheckCircleIcon />
                                </Fab>
                                <Typography>
                                    Force completion
                                </Typography>
                            </Grid>
                        </Grid>
                    </Menu>
                </Grid>
            </Grid>
        </Box>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    fabButtonContainer: {
        position: 'absolute',
        display: 'flex',
        left: 0,
        justifyContent: 'flex-start',
        marginTop: '60px',
        marginLeft: '10px'
    },
    fabButton: {
        background: "white"
    },
    hiddenFabButton: {
        display: 'none'
    },
    badgeTransform: {
        transform: 'translate(25%, -25%)'
    },
    fabButtonGridItemWithLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '10px'
    },
    moreOptionsFabButtonsMenuList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20px',
        marginRight: '10px',
        marginBottom: '10px',
        backgroundColor: colors.octagon,
    },
    moreOptionsFabButtonsPaper: {
        backgroundColor: colors.octagon,
        marginTop: '10px',
        left: '0px ! important'
    },
});


export default withStyles(styles)(FabButtonContainer);
