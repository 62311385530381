import React from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../../core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';





const LoadingAssistantTabletTableInfoDialog = props => {


    const {
        classes
    } = props;


    const cargoSpace = props.infoDialogContent?.cargoSpaces[props.infoDialogContent?.cargoSpaceIndex];
    const dims = cargoSpace?.storages[0].dimensions;


    return (
        <Dialog
            open={props.infoDialogOpen}
            onClose={props.closeInfoDialog}
        >
            <DialogTitle onClose={props.closeInfoDialog}>
                {props.infoDialogContent?.name}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography variant='body1'>
                    {`Cargo Space: ${props.infoDialogContent?.cargoSpaceIndex + 1} / ${props.infoDialogContent?.cargoSpaces.length}`}

                    <br></br>
                    {`Dimensions (mm): ${dims?.x} × ${dims?.y} × ${dims?.z}`}

                    <br></br>
                    {`Total weight: ${cargoSpace?.packedWeight} kg`}

                    <br></br>
                    {`Total parcels: ${cargoSpace?.packedParcels.length} parcels`}
                </Typography>
            </DialogContent>

        </Dialog>
    )
};





const styles = theme => ({
    content: {
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 20,
    }
});





export default withStyles(styles)(LoadingAssistantTabletTableInfoDialog)
