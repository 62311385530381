import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { withStyles } from '@material-ui/core/styles';

import GeneralDataTableBody from './GeneralDataTableComponents/GeneralDataTableBody';
import GeneralDataTableFooter from './GeneralDataTableComponents/GeneralDataTableFooter';
import GeneralDataTableHeader from './GeneralDataTableComponents/GeneralDataTableHeader';

import { cloneDeep } from 'lodash';

//////////////////////////////////////////////////////////////////////////////

const GeneralDataTable = (props) => {
    // const { classes } = props;

    /**
     * Event handler for selecting a row using the checkbox on a data row.
     * When checking a row, find that row in the data and push it to selectedRowsCopy.
     * When un-checking a row, filter the clicked row out of selectedRowsCopy.
     * @param {Object} row
     */
    const onSelectSingleRow = (row) => {
        let selectedRowsCopy = cloneDeep(props.selectedRows);

        if (selectedRowsCopy.find(sr => sr._id === row._id) == null) { // Row hasn't been selected yet
            selectedRowsCopy.push(row)
        } else { // Row has already been selected
            selectedRowsCopy = selectedRowsCopy.filter(sr => sr._id !== row._id)
        }

        props.updateSelectedRows(selectedRowsCopy);
    };


    /**
     * When clicking the checkbox on the tableheader, we either de-select all rows or select all rows
     * depending on if user already had rows selected or not.
     * If user had some, or all, rows selected, then clicking the checkbox de-selects all rows.
     * If user didn't have any rows selected, then we can select all rows on a page.
     * // NOTE: Selecting ALL data-entries isn't possible with the current implementation. Data is usually
     * fethced for a specific page only, and this function selects all available data-entries, so this isn't
     * selecting every single row, unless user has selected 'All' in the rows-per-page selector.
     */
    const onSelectAllRows = () => {
        let selectedRowsCopy = cloneDeep(props.selectedRows);

        // User has selected some, or all, of the rows --> reset selections
        if (selectedRowsCopy.length > 0 && selectedRowsCopy.length <= props.data.data.length) {
            selectedRowsCopy = [];
        }

        // User has selected no rows --> select all rows
        else if (selectedRowsCopy.length === 0) {
            selectedRowsCopy = props.data.data
        }

        // Unknown situation
        else {
            console.error('GeneralDataTable, onSelectAllRows(), got into an unhandled state');
        }

        props.updateSelectedRows(selectedRowsCopy);
    }

//////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <TableContainer
                style={{ height: '100%' }}
            >
                <Table
                    stickyHeader
                    style={{ tableLayout: 'auto' }}
                    size={props.dense ? 'small' : 'medium'}
                >

                    <GeneralDataTableHeader
                        columns={props.columns}
                        data={props.data}
                        selectedRows={props.selectedRows}
                        onSelectAllRows={() => onSelectAllRows()}
                        columnSortingOptions={props.columnSortingOptions}
                        onChangeColumnSortingOptions={props.onChangeColumnSortingOptions}
                    />

                    <GeneralDataTableBody
                        columns={props.columns}
                        data={props.data}
                        selectedRows={props.selectedRows}
                        onSelectRow={(row) => onSelectSingleRow(row)}
                        onClickRow={(row) => props.onClickRow(row)}
                    />

                </Table>
            </TableContainer>

            <Divider />

            {props.paginationState != null &&
                <GeneralDataTableFooter
                    paginationState={props.paginationState}
                    onChangePagination={props.onChangePagination}
                    total={props.data?.total}
                />
            }
        </>
    );

};

//////////////////////////////////////////////////////////////////////////////

/**
 * @prop {Array} columns - Array of { label: '', field, '', conversion: () => {}, sortable: true/false } -objects
 * @prop {Object} columnSortingOptions - { direction: '', column: '' } - object.
 * @prop {Object} data - Raw data object fetched using api.
 * @prop {Boolean} dense - Controls table size. True ==> small, false ==> medium.
 * @prop {Function} onChangeColumnSortingOptions - Function that sets new column sorting options
 * @prop {Function} onChangePagination - Function that sets new pagination state.
 * @prop {Function} onClickRow - Function that is called when a row is clicked
 * @prop {Function} updateSelectedRows - Function that sets selected rows to selectedRows-state.
 * @prop {Object} paginationState - { page: 0, rowsPerPage: 0 } - object.
 * @prop {Array} selectedRows - Array of data-entries that have been selected with checkboxes.
 * @prop {Object} style - Material-ui style-object.
 * @prop {Number} tableElevation - Number controlling elevation of the Paper-component
 */

GeneralDataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    columnSortingOptions: PropTypes.object,
    data: PropTypes.any.isRequired,
    dense: PropTypes.bool,
    paginationState: PropTypes.object,
    selectedRows: PropTypes.array,
}

GeneralDataTable.defaultProps = {
    columnSortingOptions: {},
    dense: false,
    onChangeColumnSortingOptions: () => { return },
    onChangePagination: () => { return },
    onClickRow: () => { return },
    updateSelectedRows: () => { return },
}

const styles = (theme) => ({
});


export default withStyles(styles)(GeneralDataTable);
