import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import PdfExportModal from '../../../pdf/PdfExportModal';
import { usePreviewThreer } from '../../../three/SolutionPreviewThreer';

import { solutionPackingStatus } from '../../../loadingAssistant/Constants';

//////////////////////////////////////////////////////////////////////////////

const LoadingPlansTableRowInnerContent = (props) => {
    const { classes } = props;

    const [pdfModalOptions, setPdfModalOptions] = useState({
        open: false,
        cargoSpaceIndices: [],
    });


    /**
     * Create the preview images for each cargo space
     */
    const previewImages = usePreviewThreer(props.displaySolution);

    /**
     * Format cargo space dimensions to meters. Dimensions are in cm by default.
     * @param {Number} d
     * @returns formatted cargo space dimension.
     */
    const meters = d => (d / 1000).toFixed(1)

    /**
     * This function formats the solution packing status into a more understandable label.
     * @param {Number} status
     * @returns a string label.
     */
    const formatPackingStatus = (cargoSpace) => {
        let statusString;
        switch(cargoSpace.packingStatus) {
            case(solutionPackingStatus.readyToPack):
                statusString = 'Ready for loading';
                break;
            case(solutionPackingStatus.inProgress):
                statusString = 'Loading in progress';
                break;
            case(solutionPackingStatus.packed):
                statusString = cargoSpace.forcedToCompletion ? 'Loading forced to complete' : 'Loading completed'
                break;
            case(solutionPackingStatus.recalculating):
                statusString = 'Loading plan is being recalculated';
                break;
            default:
                statusString = 'Loading status unknown';
                break;
        }

        return <span>{statusString}</span>;
    };

    /**
     * This function formats the cargo space storage presetName.
     * If no presetName can be found, then return formatted cargo space dimensions
     * @param {Object} cargoSpace
     * @returns either cargo spaces presetName, or cargo spaces dimensions formatted into a span-component.
     */
    const formatCargoSpaceName = (cargoSpace) => {
        const storage = cargoSpace.storages[0];

        if (storage.presetName) {
            return <span>{storage.presetName}</span>
        } else {
            return <span>{meters(storage.dimensions.x)} &times; {meters(storage.dimensions.y)} &times; {meters(storage.dimensions.z)}</span>
        }
    }


    /**
     * Event handler for closing the PDF export modal.
     */
    const handlePdfExportModalClose = () => {
        setPdfModalOptions(s => ({
            ...s,
            open: false,
            cargoSpaceIndices: []
        }))
    };

    /**
     * Event handler for the "Export PDF"-buttons for each cargo space.
     * This exports a PDF for the selected cargo space only.
     * @param {Number} index
     */
    const handleCargoSpacePdfExport = (index) => {
        setPdfModalOptions(s => ({
            ...s,
            open: true,
            cargoSpaceIndices: [index]
        }))
    };

    /**
     * Event handler for "Export PDF (all)"-button.
     * This exports a PDF for the whole loading plan.
     */
    const handleSolutionPdfExport = () => {
        setPdfModalOptions(s => ({
            ...s,
            open: true,
            cargoSpaceIndices: props.solution.cargoSpaces.map((cs, i) => i)
        }))
    }


//////////////////////////////////////////////////////////////////////////////


    const actionButtons = (
        <Box className={classes.actionButtonContainer}>
            <Button
                color='primary'
                variant='outlined'
                onClick={() => props.onViewLoadingPlanClick(props.solution)}
            >
                View loading plan
            </Button>
            <Button
                color='secondary'
                variant='outlined'
                startIcon={<GetAppIcon/>}
                onClick={() => handleSolutionPdfExport()}
            >
                Export PDF (all)
            </Button>
        </Box>
    )


    const previewTable = (
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>

                {/* PREVIEW TABLE BODY */}
                <TableBody>
                    {props.displaySolution.cargoSpaces.map((cargoSpace, i) => {
                        return (
                            <TableRow key={i} className={classes.tablerow} hover>

                                {/* Preview image */}
                                <TableCell padding='none' className={classes.previewImageCell}>
                                    {previewImages.length > 0 && previewImages[i] != null ?
                                        <img
                                            src={previewImages[i]}
                                            alt={`Solution preview ${i}`}
                                            style={{
                                                height: '100%',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }}
                                        />
                                        :
                                        <CircularProgress
                                            disableShrink
                                            color='primary'
                                            style={{ alignSelf: 'center' }}
                                        />
                                    }
                                </TableCell>

                                {/* Cargo space information */}
                                <TableCell padding='none' style={{ border: 'none' }}>
                                    <Grid container style={{ width: '300px' }} spacing={1}>
                                        <Grid item xs={12}>
                                            {formatCargoSpaceName(cargoSpace)}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span>Num. of parcels: {cargoSpace.packedParcels.length}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span>Used length: {(cargoSpace.usedLength / 1000).toFixed(2)} m</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span>Total weight: {cargoSpace.packedWeight} kg</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span>Parcel volume: {(cargoSpace.packedVolume / (1000 * 1000 * 1000)).toFixed(2)} m<sup className={classes.superScript}>3</sup> </span>
                                        </Grid>
                                        <Grid item x={12}>
                                            <span>Selected packing goal: {cargoSpace.packingOptimizationGoal}</span>
                                        </Grid>
                                    </Grid>
                                </TableCell>

                                {/* Cargo space packing status and progress */}
                                <TableCell padding='none' style={{ border: 'none' }}>
                                    <Grid
                                        container
                                        style={{ width: '300px' }}
                                        spacing={1}
                                        alignItems={'center'}
                                    >
                                        {cargoSpace.packingStatus > solutionPackingStatus.undefined &&
                                        <>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    {formatPackingStatus(cargoSpace)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <LinearProgress
                                                    color={cargoSpace.forcedToCompletion ? 'primary' : 'secondary'}
                                                    variant='determinate'
                                                    value={cargoSpace.progress}
                                                    style={{ height: '12px' }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <span>
                                                    {`${cargoSpace.forcedToCompletion ? '100' : cargoSpace.progress} %`}
                                                </span>
                                            </Grid>
                                        </>
                                        }
                                    </Grid>
                                </TableCell>

                                {/* Export PDF -button */}
                                <TableCell style={{ width: 'auto', border: 'none' }} padding='none'>
                                    <Box>
                                        <Button
                                            color='secondary'
                                            variant='outlined'
                                            startIcon={<GetAppIcon/>}
                                            onClick={() => handleCargoSpacePdfExport(i)}
                                        >
                                            Export pdf
                                        </Button>
                                    </Box>
                                </TableCell>

                            </TableRow>
                        )
                    })}
                </TableBody>

            </Table>
        </TableContainer>
    )


    const pdfExportModal = (
        <PdfExportModal
            open={pdfModalOptions.open}
            onClose={() => handlePdfExportModalClose()}
            solution={props.solution}
            cargoSpaceIndexes={pdfModalOptions.cargoSpaceIndices}
        />
    )




    return (
        <>
            {actionButtons}
            {previewTable}
            {pdfExportModal}
        </>
    );
};


//////////////////////////////////////////////////////////////////////////////


const styles = (theme) => ({
    tableContainer: {
        width: 'fill-available'
    },
    tablerow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid lightGray',
        padding: '12px 24px'
    },
    previewImageDiv: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    previewHeaderContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '60px'
    },
    superScript: theme.superScript,
    previewImageCell: {
        height: '10rem',
        width: 'auto',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        border: 'none'
    },
    actionButtonContainer: {
        padding: '12px 24px',
        display: 'flex',
        justifyContent: 'space-between'
    }
});


export default withStyles(styles)(LoadingPlansTableRowInnerContent);
