import React, { useState, useRef } from "react";

import DialogTitle from "../core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";

const InitialInfoDialog = (props) => {

    const {
        open,
        onConfirm,
        onClose,
        onCheckSolutionName,
        classes,
        name,
        setName,
        user,
    } = props;

    const [autoSave, setAutoSave] = useState(true);
    const [nameError, setNameError] = useState(null);
    const inputRef = useRef(null);


    const handleOnClick = () => {
        if (name.length === 0) {
            setNameError("Loading plan name cannot be empty");
            return;
        }
        onCheckSolutionName(name, user).then((isUnique) => {
            if (isUnique) {
                setNameError(null);
                onConfirm(autoSave, name);
            } else {
                setNameError(`Loading plan with name ${name} already exists`);
            }
        });
    };


    return (
        <Dialog open={open}>
            <DialogTitle onClose={onClose}>New Loading Plan</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div className={classes.margin} style={{ width: "18rem" }}>
                        <TextField
                            fullWidth
                            label="Name"
                            autoFocus
                            value={name}
                            inputRef={inputRef}
                            onChange={(e) => {
                                setName(e.target.value);
                                inputRef.current.focus();
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleOnClick();
                                }
                            }}
                            error={Boolean(nameError)}
                            helperText={nameError ? nameError : ""}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={autoSave}
                                    color="secondary"
                                    onChange={() => {
                                        inputRef.current.focus();
                                        setAutoSave(!autoSave);
                                    }}
                                />
                            }
                            label="Autosave Loading Plan"
                        />
                    </div>
                    <div
                        className={classes.margin}
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "100%",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOnClick()}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const styles = (theme) => ({
    margin: {
        margin: theme.spacing(2),
    },
    rightMargin: { marginRight: theme.spacing(4) },
    leftMargin: { marginLeft: theme.spacing(4) },
});

export default withStyles(styles)(InitialInfoDialog);
