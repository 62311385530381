import React from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core';

//////////////////////////////////////////////////////////////////////////////

/**
 *
 * @param {String} title - Page title
 * @param {String} infoText - More precise information´about the page to be shown
 * @param {String} linkLabel - Text displayed before the link button
 * @param {String} link - Text displayed as the link button
 * @param {fn() -> void} onLinkClick - callback when the link is pressed
 * @returns
 */
const UserlessPage = props => {
    const { title, infoText, link, linkLabel, onLinkClick, classes } = props;

    return (
        <div className={classes.container}>
            <Paper elevation={4} className={classes.paper} style={{ marginTop: '4.8em' }}>
                <Typography className={`${classes.centered} ${classes.titleText}`} variant='h6' gutterBottom >
                    {title}
                </Typography>
                <Typography className={`${classes.centered} ${classes.infoText}`} variant='body1' gutterBottom >
                    {infoText}
                </Typography>
                <div className={classes.centered} style={{ width: '100%' }}>
                    {props.children}
                </div>
                <div className={classes.backNavigationContainer}>
                    <Typography className={classes.linkHintText} variant='body1' gutterBottom>
                        {linkLabel}
                    </Typography >
                    <Link component='button' color="primary" variant="body1" onClick={onLinkClick} >
                        {link}
                    </Link>
                </div>
            </Paper>
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////

const styles = theme => ({
    container: theme.containers.twoPaperFlex,
    paper: {
        ...theme.containers.flexPaper,
        maxWidth: '28rem',
        paddingLeft: '4em',
        paddingRight: '4em'
    },
    centered: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleText: theme.userlessPage.titleText,
    infoText: theme.userlessPage.infoText,
    backNavigationContainer: theme.userlessPage.backNavigationContainer,
    linkHintText: theme.userlessPage.linkHintText,
})

export default withStyles(styles)(UserlessPage)
