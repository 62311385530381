import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogTitle from '../../core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const CancelLoadingJobDialog = (props) => {

//////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle
                onClose={props.onClose}
            >
                Leave loading job?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Loading job is not finished. Are you sure you want to leave this job?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    // If user decides to continue loading job, just close the dialog
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    onClick={props.onLeave}
                >
                    Leave
                </Button>
            </DialogActions>
        </Dialog>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    dialog: {
        height: 'auto',
        width: 550,
    },
    dialogContentText: {
        whiteSpace: 'pre-line'
    },
});


export default withStyles(styles)(CancelLoadingJobDialog);
