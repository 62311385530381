import React from "react";

import Nav from './Nav';
import DesktopNav from "./DesktopNav";
import { useMediaQuery, useTheme } from "@material-ui/core";

//////////////////////////////////////////////////////////////////////////////

/**
 * Dynamic NavBar that contains both DeskTop and Mobile nav bars that are rendered conditionally based on screen width
 * @returns
 */
const DynamicNav = props => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return isDesktop ? <DesktopNav {...props} /> : <Nav {...props} />
}

//////////////////////////////////////////////////////////////////////////////

export default DynamicNav;
