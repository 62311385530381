import React from 'react'
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { withStyles } from '@material-ui/core/styles';

const DropzoneArea = (props) => {
    return <DropzoneAreaBase {...props} showAlerts={false} filesLimit={1} />
}

const styles = theme => {
    return {}
}

export default withStyles(styles)(DropzoneArea)
