import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { withStyles } from '@material-ui/core/styles';

import { cloneDeep, isEmpty } from 'lodash';

//////////////////////////////////////////////////////////////////////////////

const GeneralDataTableHeader = (props) => {
    const { classes } = props;


    /**
     * Handler for clicking the column header to change table sorting.
     * @param {Object} column
     */
     const handleHeaderClick = (column) => {
        if (
            column.sortable &&
            props.columnSortingOptions != null &&
            !isEmpty(props.columnSortingOptions)
        ) {

            let newColumnSortingOptions = cloneDeep(props.columnSortingOptions);

            // User clicks on the sorting arrow on a column --> Change sorting direction
            if (newColumnSortingOptions.column === column.field) {
                newColumnSortingOptions.direction = newColumnSortingOptions.direction === 'asc' ? 'desc' : 'asc'
            }

            // User clicks on another column --> change sorting column
            else {
                newColumnSortingOptions.column = column.field
                newColumnSortingOptions.direction = 'asc' // Reset sorting direction
            }

            props.onChangeColumnSortingOptions(newColumnSortingOptions);
        }
    }


//////////////////////////////////////////////////////////////////////////////

    return (
        <TableHead>
            <TableRow>

                {/* If props.selectedRows has been given to GeneralDataTable, show checkbox on the header */}
                {props.selectedRows != null && props.data.data?.length > 0 &&
                    <TableCell className={classes.checkboxCell}>
                        <Checkbox
                            indeterminate={props.selectedRows.length > 0 && props.selectedRows.length < props.data.total}
                            checked={props.data.total > 0 && props.selectedRows.length === props.data.total}
                            onChange={props.onSelectAllRows}
                        />
                    </TableCell>
                }

                {/* Header for each column */}
                {props.columns.map((column, i) => {
                    return (
                        <TableCell
                            key={i}
                            className={classes.cellStyle}
                            style={{
                                width: column.label === '' ? '0px': ''
                            }}

                        >
                            {column.sortable && !isEmpty(props.columnSortingOptions) ?
                                <TableSortLabel
                                    active={props.columnSortingOptions.column === column.field}
                                    direction={props.columnSortingOptions.direction}
                                    onClick={() => handleHeaderClick(column)}
                                    style={{ fontFamily: 'inherit' }}
                                >
                                    {column.label}
                                </TableSortLabel>
                                :
                                <>
                                    {column.label}
                                </>
                            }
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>

    );

};

//////////////////////////////////////////////////////////////////////////////

GeneralDataTableHeader.defaultProps = {
    columns: [],
    data: {}
}

const styles = (theme) => ({
    cellStyle: {
        maxWidth: '150px',
        whiteSpace: 'pre-wrap',
    },
    checkboxCell: {
        width: '75px'
    }
});


export default withStyles(styles)(GeneralDataTableHeader);
