import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles, useTheme } from '@material-ui/core'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import BackDialog from '../../core/BackDialog';
import { dateToFormattedString } from '../../core/dateUtils';
import { solutionPackingStatus } from '../../loadingAssistant/Constants';

import SolutionsTable from './SolutionsTable'


//////////////////////////////////////////////////////////////////////////////


const SolutionsTableWrapper = (props) => {
    const theme = useTheme();
    const { classes } = props;

    const [mounted, setMounted] = useState(false);
    const [paginationState, setPaginationState] = useState({
        page: 0,
        rowsPerPage: 25
    })
    const [columnSortingOptions, setColumnSortingOptions] = useState({
        direction: 'desc',
        column: 'createdAt'
    })
    const [selectedRows, setSelectedRows] = useState([]);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);


    /**
     * Fetch all saved solutions when initially rendering the loading plans -table
     */
    useEffect(() => {
        props.onGetSolutions({
            $sort: { createdAt: -1 },
            $limit: paginationState.rowsPerPage,
            $skip: paginationState.page * paginationState.rowsPerPage,
        })
        setMounted(true);
    // Exhaustive-deps disabled in this hook, becuase we want to execute this one ONLY ONCE at the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // If organization filter or name search are applied, reset pagination page back to 0
    useEffect(() => {
        setPaginationState(s => ({ ...s, page: 0 }))
    }, [props.orgFilterList, props.searchTerm])


    /**
     * After the initial render, when ever user changes pagination, column sorting, organization filter
     * or search-by-name, fetch solutions based on the given parameters.
     */
    useEffect(() => {

        // Component has to be mounted before any filters/pagination can be applied
        if (!mounted) {
            return;
        }

        props.onGetSolutions({
            $sort: { [columnSortingOptions.column]: columnSortingOptions.direction === 'asc' ? 0 : -1 },
            $limit: paginationState.rowsPerPage,
            $skip: paginationState.page * paginationState.rowsPerPage,
            $search: props.searchTerm ? props.searchTerm : undefined,
            organization: props.orgFilterList.length > 0 ? props.orgFilterList.map(o => o._id) : undefined
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orgFilterList, props.searchTerm, paginationState, columnSortingOptions, mounted])


    /**
     * Format cargo space preset name(s).
     * Take the preset name from the first storage in the cargospaces, if there's no preset name, show storage dimensions instead
     * @param {Object} cargoSpaces are the cargo spaces for this solution
     * @returns formatted preset name
     */
    const formatPresetName = (cargoSpaces) => {
        let names = [];

        cargoSpaces.forEach(cs => {
            const storage = cs.storages[0];
            if (storage.presetName) {
                if (!names.includes(storage.presetName)) {
                    names.push(storage.presetName);
                };
            } else {
                const dim = storage.dimensions;
                const meters = d => (d / 1000).toFixed(1);
                const dims = `${meters(dim.x)} × ${meters(dim.y)} × ${meters(dim.z)}`
                if (!names.includes(dims)) {
                    names.push(dims)
                }
            }
        })

        return (
            <span>
                {names.join(', ')}
            </span>
        )
    }


    /**
     * Format the given weight information into String with a 'kg' at the end.
     * E.g. 90,5 --> '90.5 kg'
     * @param {Number} weight
     * @returns formatted weight information
     */
    const formatWeight = (weight) => {
        const formattedWeight = `${weight.toString().replace(',', '.')} kg`;
        return (
            <span>
                {formattedWeight}
            </span>
        )
    };


    /**
     * This function formats a loading status -label for each table row based
     * on the packingStatus-field of the set of given cargo spaces.
     * @param {Array} cargoSpaces - Cargo spaces from a single loading plan
     * @returns a formatted loading status -string.
     */
    const formatLoadingStatus = (cargoSpaces) => {

        let statusString;
        let stringColor = 'black';

        const loadingStatuses = cargoSpaces.map(cs => cs.packingStatus);

        // If all cargo spaces have packingStatus 0 (undefined), then loading plan is still being planned.
        if (loadingStatuses.every(status => status === solutionPackingStatus.undefined)) {
            statusString = 'Planned';
            stringColor = '#2196f3'
        };

        // If cargo spaces are in loading, but not all of them are finished, then loading plan is in loading.
        if (
            loadingStatuses.every(status => status >= solutionPackingStatus.readyToPack && status <= solutionPackingStatus.packed) &&
            loadingStatuses.some(status => status < solutionPackingStatus.packed)
        ) {
            statusString = 'In loading';
            stringColor = theme.palette.primary.main
        };

        // If all cargo spaces have been loaded, then loading plan is completed.
        if (loadingStatuses.every(status => status === solutionPackingStatus.packed)) {
            statusString = 'Completed'
            stringColor='green'
        }

        return (
            <span
                style={{
                    color: stringColor,
                    fontWeight: 'bold',
                }}
            >
                {statusString}
            </span>
        )
    }



    /**
     * Initialize columns for the loading plans table
     */
    const columns = [
        // { title: 'ID', field: '_id' },
        { label: 'Date', field: 'createdAt', conversion: date => <span>{dateToFormattedString(date)}</span>, sortable: true },
        { label: 'Name', field: 'name' },
        { label: 'Cargo space name(s)', field: 'cargoSpaces', conversion: cargoSpaces => formatPresetName(cargoSpaces) },
        { label: 'Cargo spaces', field: 'cargoSpaceCount' },
        { label: 'Total parcels', field: 'packedCount' },
        { label: 'Total weight', field: 'totalPackedWeight', conversion: weight => formatWeight(weight) },
        { label: 'Loading status', field: 'cargoSpaces', conversion: cargoSpaces => formatLoadingStatus(cargoSpaces) },
    ];


    /**
     * Set up a organization column
     * If user is not superadmin, don't show the column
     */
    if (props.isSuperAdmin) {
        columns.push({ label: 'Organization', field: 'organization.name' })
    };



     /**
     * This function deletes all loading plans that have been selected by user.
     * @param {Array} solutions - Array of solutions user wants to remove
     */
    const handleSolutionsDelete = (solutions) => {
        if (solutions.length === 0) {
            return;
        }

        props.onSolutionsDelete(solutions);
        setSelectedRows([]) // Reset selected rows
    }


//////////////////////////////////////////////////////////////////////////////


    return (
        <>
            {/* Remove selected loading plans confirmation dialog */}
            <BackDialog
                backDialogOpen={deleteConfirmationDialogOpen}
                onConfirmGoBack={() => {
                    handleSolutionsDelete(selectedRows);
                    setDeleteConfirmationDialogOpen(false);
                }}
                onCloseBackDialog={() => setDeleteConfirmationDialogOpen(false)}
                confirmButtonText={'Remove'}
                title={'Remove selected loading plans?'}
                text={'Are you sure you want to remove selected loading plans? This action cannot be undone.'}
            />

            {/* Table component */}
            <Paper className={classes.table} elevation={4}>

                <Toolbar
                    className={classes.multiselectToolbar}
                    style={{
                        backgroundColor: selectedRows.length > 0 ? 'rgba(52, 139, 162, 0.2)' : ''
                    }}
                >
                    <Collapse in={selectedRows.length > 0} className={classes.collapse}>
                        <Box className={classes.multiselectToolbarContent}>
                            <Typography variant='subtitle1'>
                                {selectedRows.length} selected
                            </Typography>
                            <Tooltip title='Remove selected'>
                                <span>
                                    <IconButton
                                        disabled={selectedRows.length === 0}
                                        onClick={() => setDeleteConfirmationDialogOpen(true)}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    </Collapse>
                </Toolbar>

                <Divider />

                <SolutionsTable
                    columns={columns}
                    data={props.solutions}
                    paginationState={paginationState}
                    onChangePagination={(ps) => setPaginationState(ps)}
                    onViewLoadingPlanClick={props.onRowSelect}
                    selectedRows={selectedRows}
                    updateSelectedRows={(selected) => setSelectedRows(selected)}
                    columnSortingOptions={columnSortingOptions}
                    onChangeColumnSortingOptions={(options) => setColumnSortingOptions(options)}
                />
            </Paper>
        </>
    )

};


//////////////////////////////////////////////////////////////////////////////


const styles = theme => ({
    table: theme.containers.dataTable,
    multiselectToolbar: {
        padding: '0px 32px'
    },
    collapse: {
        width: '100%'
    },
    multiselectToolbarContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
})


export default withStyles(styles)(SolutionsTableWrapper)
