import { useRef, useEffect } from 'react'

// The code has minor refactoring, but the main credits go to
// https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f

const makeCancelable = (promise, { silentCancel }) => {
    let isCanceled = false;
    const wrappedPromise =
        new Promise((resolve, reject) => {
            promise
                .then(result => {
                    // Promise was cancelled, don't resolve
                    if (isCanceled) {
                        // When silentCancel option is used, don't even reject, just return
                        return silentCancel ? null : reject({ isCanceled });
                    }
                    // Resolve the promise with the result
                    resolve(result);
                })
                .catch(error => {
                    // Promise was cancelled, don't reject
                    if (isCanceled) {
                        // When silentCancel option is used, don't even reject, just return
                        return silentCancel ? null : reject({ isCanceled });
                    }
                    // Reject the promise with the error
                    reject(error);
                });
        });

    return {
        promise: wrappedPromise,
        // function for the call to be able to cancel the promise
        cancel() {
            isCanceled = true;
        },
    };
}

/**
 * Hook for automatic promise cancellation when component unmounts
 *
 * options:
 *   - silentCancel <bool> : default = true
 *      When false, rejects the promise when component is unmounted - USER MUST CATCH THESE
 *      When true, does NOT reject the promise when unmounted
 *
 * Usage:
 *      const [createPromise] = useCancellablePromise();
 *      const apiResult = await createPromise(apiCall());
 *
 * @param {Bool} silentCancel
 * @returns
 */
export default (silentCancel = true) => {
    // Keep all promises made in the reference
    const promises = useRef();

    useEffect(() => {
        // Initialize the promise array
        promises.current = promises.current || [];

        // When parent component unmounts, this hook is also unmounted
        // and when unmounted, cancel all promises
        return () => {
            promises.current.forEach(p => p.cancel());
            promises.current = [];
        };
    }, []);

    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise that are cancelled automatically
    const cancellablePromise = (promise) => {
        const cancelablePromise = makeCancelable(promise, { silentCancel });
        promises.current.push(cancelablePromise);
        return cancelablePromise.promise;
    }

    return [cancellablePromise];
}
