const notificationDialogState = {
    text: null,
    title: null,
    severity: null
}

export default (state = notificationDialogState, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION_DIALOG':
        return Object.assign({}, state, {
            text: action.text,
            title: action.title,
            severity: action.severity
        })
    case 'HIDE_NOTIFICATION_DIALOG':
        return Object.assign({}, state, {
            text: null,
            title: null,
            severity: null
        })
    default:
      return state
  }
}
