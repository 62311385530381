import React, { useState, forwardRef } from 'react'

import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'

import MaterialTable, { MTableEditField, MTableCell } from 'material-table'
import { ChromePicker } from 'react-color'

import set from 'lodash.set'

import { withStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExplicitIcon from '@material-ui/icons/Explicit';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteForeverIcon {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Excel: forwardRef((props, ref) => <ExplicitIcon {...props} ref={ref} />), // TODO: figure out why changing actions doesn't have effect
}

const DEFAULT_COORD = { x: 0, y: 0, z: 0 }
const booleanLookup = {
    false: 'No',
    true: 'Yes',
}
const typeLookupTable = {
    0: "Negative Space",
    1: "Visual Aid"
}

const parseStringsToTypes = data => {
    ["x", "y", "z"].forEach(dim => {
        data.dimensions[dim] = parseInt(data.dimensions[dim]);
        data.location[dim] = parseInt(data.location[dim]);
    })
    data.type = parseInt(data.type);
    data.allowStacking = data.allowStacking === "true";
    data.wireframe = data.wireframe === "true";
    return data;
}

const CargoSpaceVisualAid = props => {
    const [state, setState] = useState({ colorAnchorEl: null, colorCellProps: null, oldRowData: null })
    const [tempColor, setTempColor] = useState(null)
    const { colorAnchorEl, colorCellProps, oldRowData } = state;
    const { data, setData, classes } = props

    const updateData = (rowData) => {
        const dataUpdate = [...data];
        parseStringsToTypes(rowData);
        dataUpdate[rowData.tableData.id] = rowData;
        setData(dataUpdate)
    }

    const updateDataColor = (rowData) => {
        const editing = data.find(x => x.tableData && x.tableData.editing === "update")
        if (editing) {
            // Don't update the rowData into visualAids while it's in editing
            setTempColor(rowData.color)
        } else {
            updateData(rowData)
        }
        setState({ colorAnchorEl: null, colorCellProps: null, oldRowData: null })
    }

    const colorMenu = Boolean(colorAnchorEl) && (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={colorAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={Boolean(colorCellProps)}
            onClose={() => setState({ colorAnchorEl: null, colorCellProps: null })}
        >
            {colorCellProps &&
                <div style={{ outline: 'none' }}>
                    <ChromePicker
                        color={colorCellProps.rowData.color.rgb}
                        onChange={(color, event) => {
                            let newProps = Object.assign({}, colorCellProps)
                            newProps.rowData.color.hex = color.hex
                            newProps.rowData.color.rgb = color.rgb
                            setState({ ...state, colorCellProps: newProps })
                        }}
                    />
                    <Button onClick={() => updateDataColor(oldRowData)}>Cancel</Button>
                    <Button onClick={() => updateDataColor(colorCellProps.rowData)}>Ok</Button>
                </ div>
            }
        </Menu>
    )

    const colorCell = props => (
        <div onClick={e =>
            setState({
                colorAnchorEl: { top: e.clientY, left: e.clientX },
                colorCellProps: Object.assign({}, props),
                oldRowData: JSON.parse(JSON.stringify(props.rowData))
            })
        }>
            {props.value}
        </div>
    )

    const numberColumnProps = {
        required: true,
        type: "numeric",
        align: "left",
    }

    return (
        <div className={classes.textFieldWrapper}>
            <MaterialTable
                options={{
                    showTitle: false,
                    paging: false,
                    showSelectAllCheckbox: false,
                    padding: 'dense',
                    toolbar: true,
                    search: false,
                    draggable: false,
                    sorting: false,
                }}
                icons={tableIcons}
                data={data}
                columns={[
                    { title: 'Type', field: 'type', required: true, lookup: typeLookupTable, emptyValue: 1},
                    { title: 'Length', field: 'dimensions.x', ...numberColumnProps },
                    { title: 'Width', field: 'dimensions.y', ...numberColumnProps },
                    { title: 'Height', field: 'dimensions.z', ...numberColumnProps },
                    { title: 'Loc X', field: 'location.x', ...numberColumnProps },
                    { title: 'Loc Y', field: 'location.y', ...numberColumnProps },
                    { title: 'Loc Z', field: 'location.z', ...numberColumnProps },
                    { title: 'Allow Stacking', field: 'allowStacking', required: true, lookup: booleanLookup, emptyValue: 'false' },
                    { title: 'Color', field: 'color.hex', required: true, editComponent: props => colorCell(props) },
                    { title: 'Opacity', field: 'color.rgb.a', required: true, editComponent: props => colorCell(props) },
                    { title: 'Wireframe', field: 'wireframe', required: true, lookup: booleanLookup, emptyValue: 'false' }
                ]}
                actions={[
                    {
                        icon: () => <AddIcon />,
                        toolTip: 'Add Visual Aid',
                        isFreeAction: true,
                        onClick: (event) => {
                            const newAid = {
                                type: 1,
                                dimensions: Object.assign({}, DEFAULT_COORD),
                                location: Object.assign({}, DEFAULT_COORD),
                                allowStacking: false,
                                color: {
                                    hex: '#f3740e',
                                    rgb: { r: 243, g: 116, b: 14, a: 0.2 }
                                },
                                wireframe: false,
                            }
                            data.push(newAid)
                            setData(data)
                        }
                    },
                    rowData => ({
                        icon: (props) => <AddToPhotosIcon style={{ display: rowData.tableData.editing ? "none" : "inherit" }} {...props} />,
                        tooltip: "Duplicate",
                        hidden: Boolean(rowData.tableData.editing),
                        onClick: () => {
                            const visAid = JSON.parse(JSON.stringify(rowData))
                            data.push(visAid)
                            setData(data)
                        }
                    })
                ]}
                editable={{
                    onRowDelete: async oldData => {
                        const dataDelete = [...data];
                        dataDelete.splice(oldData.tableData.id, 1);
                        setData(dataDelete)
                    },
                    onRowUpdate: async (newData, oldData) => {
                        const dataUpdate = [...data];
                        parseStringsToTypes(newData);
                        if (tempColor){
                            newData.color = tempColor;
                            setTempColor(null);
                        }
                        dataUpdate[oldData.tableData.id] = newData;
                        setData(dataUpdate);
                    }
                }}
                cellEditable={{
                    cellStyle: { width: "50px" },
                    onCellEditApproved: async (newValue, oldValue, rowData, columnDef) => {
                        set(rowData, columnDef.field, newValue)
                        updateData(rowData)
                    }
                }}
                components={{
                    EditField: props => {
                        return <div style={{ width: "100px" }}> <MTableEditField {...props} /></div>
                    },
                    Cell: props => {
                        if (props.columnDef.title === "Color" || props.columnDef.title === "Opacity") {
                            return <td style={{ cursor: 'pointer', borderBottom: '1px solid rgba(224, 224, 224, 1)', lineHeight: '1.43', padding: '6px 24px 6px 16px', width: '100px', textAlign: 'left' }}>
                                {colorCell(props)}
                                {props.columnDef.title === "Color" &&
                                    <div style={{
                                        float: 'right',
                                        marginTop: '-15px',
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: '100%',
                                        background: `${props.rowData.color.hex}`,
                                        opacity: `${props.rowData.color.rgb.a}`
                                    }} />
                                }
                            </td>
                        }
                        return <MTableCell {...props} />
                    }
                }}
            />
            {colorMenu}
        </div>
    )
}

const styles = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    noTopMargins: {
        marginTop: "-12px",
        marginBottom: "0px",
        width: "100%"
    },
})

export default withStyles(styles)(CargoSpaceVisualAid)