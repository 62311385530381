import React, { useEffect, useRef } from 'react'

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import boxbotTheme, { tabletTheme } from '../theme'
import NotificationDialog from './NotificationDialog'
import Notification from './Notification'
import Overlay from './Overlay';

import CookieDisclaimer from '../home/CookieDisclaimer'

//////////////////////////////////////////////////////////////////////////////

const themeMap = {
    'default': boxbotTheme,
    'tabletTheme': tabletTheme
}

const App = (props) => {
    // Router changes components to React.Components, theme is declared as "ThemeName"
    const theme = props.theme?.type || 'default';

    return (
        <ThemeProvider theme={themeMap[theme]}>
            <div style={{ height: "100vh", backgroundColor: '#e2e2e2', whiteSpace: "nowrap", }}>
                <CssBaseline />
                {props.header}

                {/* Theme is provided to child components - wouldn't be accessible here  */}
                <ThemedAppContent {...props} />
                <NotificationDialog />
                <Notification />
                <Overlay />
                {props.footer}
            </div>
        </ThemeProvider>
    )
}

const AppContent = props => {
    const main = useRef(null)
    const { classes } = props;

    // HACK Scroll main container to top after every route navigation.
    // Workaround for rare bug #315, unintended scrolling of main contents after back navigation.
    useEffect(() => {
        let timeout = setTimeout(() => {
            if (main.current) {
                main.current.scrollTop = 0;
            }
        });
        return () => clearTimeout(timeout);
    }, [props]);

    return (
        <main id="main" ref={main} className={`${classes.mainContent} ${props.footer ? classes.mainContentWithFooter : ''}`}>
            <CookieDisclaimer />
            {props.navBar}
            {props.component}
            {props.children}
        </main>
    )
}

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    mainContent: {
        width: '100vw',
        backgroundImage: 'url(\'/assets/boxbot_tessel.png\')',
        overflow: "auto hidden",
        position: 'fixed',
        bottom: 0,
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    },
    mainContentWithFooter: {
        bottom: theme.footer.height,
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - ${theme.footer.height})`
    },
})

const ThemedAppContent = withStyles(styles)(AppContent)
export default App
