import React from 'react';

import Typography from '@material-ui/core/Typography';
import RotatingSettingsIcon from '../RotatingSettingsIcon';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const LMCLoadingScreen = (props) => {
    const { classes } = props;

//////////////////////////////////////////////////////////////////////////////

    return (
        <div className={classes.loadingDiv}>
            <div className={classes.loadingInnerDiv}>
                <RotatingSettingsIcon />
                <Typography
                    variant='h2'
                    color='primary'
                    style={{ marginTop: '16px '}}
                >
                    Re-calculating&hellip;
                </Typography>
            </div>
        </div>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    loadingDiv: {
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        zIndex: "10",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.8)"
    },
    loadingInnerDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
});


export default withStyles(styles)(LMCLoadingScreen);
