import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import SolutionsTableRowInnerContent from './SolutionsTableRowInnerContent';

import { get } from 'lodash'

//////////////////////////////////////////////////////////////////////////////

const SolutionsTableRow = (props) => {
    const { classes } = props;

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [rowId, setRowId] = useState(null);


    /**
     * If props.row swaps to another solution, then close the collapse-component.
     * This happens when solutions are either created or removed.
     */
    useEffect(() => {
        if (rowId == null) {
            setRowId(props.row._id);
        };

        if (rowId !== props.row._id) {
            setCollapseOpen(false);
        } else {
            return;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.row]);




//////////////////////////////////////////////////////////////////////////////

    return (
        <>
            {/* OUTER ELEMENT OF THE ROW */}
            <TableRow
                key={props.key}
                hover
                onClick={() => setCollapseOpen(co => !co)}
                className={classes.tableRow}
                selected={props.selected}
            >
                {/* Each row includes a icon button that opens the collapse component */}
                <TableCell>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setCollapseOpen(co => !co)
                        }}
                    >
                        {collapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {props.selectedRows != null &&
                        <Checkbox
                            checked={props.selectedRows.find(sr => sr._id === props.row._id) != null}
                            onChange={props.onSelectRow}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                </TableCell>

                {/* Outer cells displayed on the collapse component */}
                {props.columns?.map((col, i) => {

                    const cellValue = col.conversion ?
                        col.conversion(get(props.displayRow, col.field))
                        :
                        get(props.displayRow, col.field)

                    return (
                        <TableCell key={i} className={classes.tableCell}>
                            {cellValue}
                        </TableCell>
                    )
                })}

                {/* View loading plan -button */}
                <TableCell>
                    <Button
                        endIcon={<ArrowForwardIcon/>}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onViewLoadingPlanClick(props.row)
                        }}
                        key={props.columns.length + 1}
                        color='primary'
                    >
                        View
                    </Button>
                </TableCell>
            </TableRow>

            {/* INNER ELEMENT OF THE ROW */}
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={props.columns?.length + 2}>
                    <Collapse
                        in={collapseOpen}
                        unmountOnExit
                    >
                        <SolutionsTableRowInnerContent
                            solution={props.row} // "solution" is used in "view loading plan"- and "export pdf"-button clicks.
                            displaySolution={props.displayRow} // "displaySolution" is used to display correct information about each cargo space of solution.
                            onViewLoadingPlanClick={props.onViewLoadingPlanClick}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    tableRow: {
        '& > *': {
            borderBottom: 'unset'
        }
    },
    tableCell: {
        maxWidth: '300px',
        overFlow: 'hidden',
        textOverFlow: 'ellipsis',
        whiteSpace: 'normal'
    },
});


export default withStyles(styles)(SolutionsTableRow);
