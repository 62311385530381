// import { convertYZ, getShrunkenDimensions } from '../core/Constants';

export const boxThreerImage = (box, three) => {
    three.clear()

    let dim = box.packedDimensions;
    let loc = { x: 0, y: 0, z: 0 }
    if (dim.r > 0) {
        dim = { ...dim, x: 2 * dim.r, z: 2 * dim.r }
        loc = { x: dim.r, y: 0, z: dim.r } // threer axis (y = height, z = depth) ...
    }

    three.addBox(loc, dim, dim, box)
    const viewMultiplier = 0.8
    three.setViewByBounds(viewMultiplier * dim.x, viewMultiplier * dim.y, viewMultiplier * dim.z);

    const imgData = three.exportThree()
    return imgData;
}
