import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'


export default ({disabled, checked, label, secondary, onChange, ...other}) => {
    const checkBox =
      <FormControl disabled={disabled}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={onChange}
              label={label} />
          } />
      </FormControl>

    return (
      <ListItem disabled={disabled} {...other} >
        <ListItemIcon>{checkBox}</ListItemIcon>
        <ListItemText primary={label} secondary={secondary} />
      </ListItem>
    )
  }
