import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/styles";

//////////////////////////////////////////////////////////////////////////////

/**
 *
 * Simple wrapper for default Footer element
 * @returns
 */
const Footer = props => {
    const { classes } = props;

    return <AppBar position="sticky" color="secondary" className={classes.footer} />
}

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    footer: theme.footer
})

export default withStyles(styles)(Footer)
