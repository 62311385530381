import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogTitle from "../../core/DialogTitle";
import ResponsiveButton from "../../core/ResponsiveButton";

import { withStyles } from "@material-ui/core/styles";

//////////////////////////////////////////////////////////////////////////////

const JoinLoadingJobDialog = (props) => {

//////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle onClose={props.onClose}>
                {"Join loading job?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Someone is already working on this loading job. Do you want to join this loading job?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    Cancel
                </Button>
                <ResponsiveButton
                    color="primary"
                    loading={props.dbUpdateInProgress}
                    onClick={props.joinLoadingJob}
                >
                    Join
                </ResponsiveButton>
            </DialogActions>
        </Dialog>
    );
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
});

export default withStyles(styles)(JoinLoadingJobDialog);
