export const toNumber = str => {
    if (str === "")
        return 0;

    const x = parseFloat(typeof str === "string" ? str.replace(",", ".") : str)
    if (!Number.isFinite(x)) {
        return null;
    }
    return x;
}

export function toUint(str) {
    if (str === "") {
        return 0;
    }
    const x = parseInt(str);
    if (!Number.isInteger(x)) {
        return null;
    }
    return x;
}

const checkEmpty = (text, field) => {
    if (text !== 0 && (!text || text.length === 0)) return { message: `can't be empty`, field: field }
}

const checkId = (text, field, allParcels, addingNewId) => {
    if (!allParcels) return
    const maxCount = addingNewId ? 0 : 1
    const count = allParcels.filter(x => x.id && x.id.toString() === text).length
    if (count > maxCount) return { message: `must be unique`, field: field }
}

export const validateNumber = (v, field, unit = 1) => {
    const value = unit === 1 ? toUint(v) : toUint(toNumber(v) * unit)
    if (!value || value < 0) {
        let message;
        if (isNaN(v)) message = `must be a number`
        else if (value === 0) message = `can't be 0`
        else if (v === '') message = `can't be empty`
        else if (value < 0) message = `can't be negative`
        else if (!value) message = `must be a number`
        return {
            message: message,
            field: field
        }
    }
}

export const validateId = (text, field, allParcels, addingNewId) => {
    const errorResult = [
        checkEmpty(text, field),
        checkId(text, field, allParcels, addingNewId)
    ]
    // Filter out non-errors and return first - both error can't be happening at the same time
    return errorResult.find(x => x)
}

export const validateParcelInput = (parcel, dimensions, allParcels, addingNewId = false, unit = 1) => {
    const errorResult = [
        ...validateDimensions(dimensions, unit),
        validateId(parcel.id, "Id", allParcels, addingNewId),
        validateNullableNumber(parcel.weight, "Weight")
    ]
    var res = errorResult.filter(x => x); // Remove not errors, e.q. undefined
    return res
}

const validateDimensions = (dimensions, unit) => {
    if (dimensions.r > 0)
        return [
            validateNumber(dimensions.r, "Radius", unit),
            validateNumber(dimensions.z, "Height", unit),
        ]

    return [
        validateNumber(dimensions.x, "Length", unit),
        validateNumber(dimensions.y, "Width", unit),
        validateNumber(dimensions.z, "Height", unit)
    ]
}

const validateNullableNumber = (v, field) => {
    const value = toUint(v);
    if (value === 0)
        return;
    if (!value || value < 0) {
        let message;
        if (value < 0) message = `can't be negative`
        else message = `must be a number`
        return {
            message: message,
            field: field
        }
    }
}

export const getFirstInvalidParcel = (parcels) => {
    if (!parcels.length)
        return false

    let invalidData
    for (let i = 0; i < parcels.length; i++) {
        invalidData = validateParcelInput(parcels[i], parcels[i].dimensions, parcels)
        if (invalidData.length) {
            invalidData[0].id = parcels[i].id
            return invalidData[0];
        }
    }
    return false;
}

export const validateStorage = (storage, unitValue = 1) => {
    if (!storage.loadingDirection)
        return false;

    const tempDimensions = storage.dimensions
    const invalidDimensions = Object.keys(tempDimensions)
        .filter(x => x !== "_id") // TODO !!!
        .map(dim => validateNumber(tempDimensions[dim], dim, unitValue))
        .filter(x => x !== undefined)

    if (invalidDimensions.length) {
        return false;
    }

    return true;
}

export const validateStorages = (storages, unitValue = 1) => {
    return storages.every(s => validateStorage(s, unitValue));
}
