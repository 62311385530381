import { combineReducers } from 'redux'
import admin from './admin/adminReducer'
import user from './app/userReducer'
import drawer from './app/drawerReducer'
import notificationDialog from './app/notificationDialogReducer'
import notification from './app/notificationReducer'
import overlay from './app/overlayReducer'
import profileMenu from './app/profileMenuReducer'
import optimizer from './optimize/OptimizerReducer'
import solutions from './solutions/SolutionReducer'
import unit from './core/Units/UnitReducer'

export default combineReducers({
    admin,
    user,
    drawer,
    notificationDialog,
    overlay,
    profileMenu,
    notification,
    optimizer,
    solutions,
    unit,
})
