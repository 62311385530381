import { planStates } from "../core/Constants"
import { validateStorages } from "../core/Validations";

export const addButtonPropsDisabledAndTooltips = (buttonProps, solution, planState, parcels, unpackableParcels, rules, unsaved, storages, disableChanges) => {
    const isCargoSpaceValid = validateStorages(storages)

    // Next button is always disabled if the previous button is already disabled
    buttonProps[1].disabled = !parcels.length; // Cargo space
    buttonProps[2].disabled = buttonProps[1].disabled || !isCargoSpaceValid || (unpackableParcels && rules.storageSelectionStrategy === 'UseSecondaryOnLast'); // Optimize & Inspect
    buttonProps[3].disabled = buttonProps[2].disabled || unsaved || planState < planStates.inspect || !solution._id; // Loading

    const disabledTextLookup = {
        1: "Cargo data can't be empty",
        2: (unpackableParcels?.buttonProps?.errorLabel || "Cargo space can't be empty"),
        3: !solution ? "Calculate and save loading plan first" : "Loading plan have to be saved"
    }

    // Set the first disabled button tooltip
    for (let idx = 1; idx < 4; idx++) {
        if (buttonProps[idx].disabled) {
            buttonProps[idx].tooltip = disabledTextLookup[idx];
            break;
        }
    }

    return buttonProps;
}
