export const openDeleteDialog = () => {
  return { type: 'OPEN_DELETE_DIALOG' }
}

export const closeDeleteDialog = () => {
  return { type: 'CLOSE_DELETE_DIALOG' }
}

export const openBackDialog = () => {
  return { type: 'OPEN_BACK_DIALOG' }
}

export const closeBackDialog = () => {
  return { type: 'CLOSE_BACK_DIALOG' }
}

export const setEditUser = user => {
  return { type: 'SET_EDIT_USER', user }
}

export const setUsers = users => {
  return { type: 'SET_USERS', users }
}

export const setOrganizations = organizations => {
  return { type: 'SET_ORGANIZATIONS', organizations }
}

export const setEditOrganization = organization => {
  return { type: 'SET_EDIT_ORGANIZATION', organization }
}

export const setEditPreset = preset => {
  return { type: 'SET_EDIT_CARGOPRESET', preset }
}

export const setPresets = presets => {
  return { type: 'SET_CARGOPRESETS', presets }
}

export const setOrganizationUsers = users => {
    return { type: 'SET_ORGANIZATION_USERS', users }
}

export const setAssignments = assignments => {
    return { type: 'SET_ASSIGNMENTS', assignments}
}
