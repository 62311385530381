import React, { useState, useEffect } from 'react'

import threeEntryPoint from './threeEntryPoint';

import { solutionPackingStatus } from '../loadingAssistant/Constants'

const Threer = (props) => {
    const [three, setThree] = useState(null);
    const [refToThree, setRefToThree] = useState(null);
    const [state, setState] = useState({ initialized: false, currentIndex: 0 })
    const {
        index,
        solution,
        exportIndexes,
        zoomFactor,
        noBorders,
        slider,
        selectedParcel,
        previewCargoSpace,
        parcelUpdate
    } = props;

    const { selectedParcelToPack } = props.loadingAssistantProps || {};
    const isLoadingAssistantSol = Boolean(props.loadingAssistantProps);


    const direction = props.loadingAssistantProps?.manipulateDirection;
    // This effects zooms the camera to the parcel
    useEffect(() => {
        if (!props.parcelBeingManipulated || !three)
            return;

        // HACK our way to update all parcels in Threer just to update the one that's changed...
        const parcels = [...solution.cargoSpaces[solution.cargoSpaceIndex].packedParcels];
        const idx = parcels.findIndex(x => x.id === props.parcelBeingManipulated.parcel.id);
        parcels[idx] = props.parcelBeingManipulated.parcel;
        // NOTE: reconstruction is not needed when only direction is changed
        // BUT: zoomToParcel NEEDS to called AFTER reconstruction, otherwise it zooms based on old dimensions...
        three.reconstructParcels(parcels)

        if (props.parcelBeingManipulated.parcel != null) {
            three.zoomToParcel(props.parcelBeingManipulated.parcel.id, direction);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [three, direction, props.parcelBeingManipulated])


    useEffect(() => {
        if (refToThree) {
            setThree(threeEntryPoint({
                containerElement: refToThree,
                noBorders,
                zoomFactor,
                loadingDirection: solution.cargoSpaces[index].storages[0].loadingDirection,
                parcelManipulationCallbacks: props.parcelManipulationCallbacks, // Callbacks for parcel click and arrow click
            }))
        }

        // threeEntryPoint is set only once on purpose.
        // The correct way would include logic here that would **update** the threeEntryPoint props when three is already initialized,
        // and we could listen all dependency updates. The only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refToThree, noBorders, zoomFactor])

    // When ever we pass down a new id as props.parcelBeingManipulated, call three.setClickedParcel(id), which add arrows to that particular parcel
    useEffect(() => {
        if (!three) {
            return;
        }

        three.enableManipulationControlsOnParcel(props.parcelBeingManipulated?.parcel.id, props.parcelBeingManipulated?.showArrows);
    }, [props.parcelBeingManipulated, three])

    // When ever the solution is updated, or changed, update each scene child parcel-field
    useEffect(() => {
        if (!three || !solution || !isLoadingAssistantSol) {
            return;
        }

        const parcels = solution.cargoSpaces[solution.cargoSpaceIndex].packedParcels;
        three.updateParcelInfos(parcels);

        /**
         * Got console warning to add three to this depencendy array.
         * Adding three to dependencies crashes the program when trying to create a new loading plan at the cargo space selection page.
         * Therefor -> disable warning
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [solution])


    // Update Camera view for Loading Assistant -- HighLight's both previewParcels and parcel selected to be packed next
    const nextPackableParcels = isLoadingAssistantSol ? solution?.nextPackableParcels : [];
    const highLighted = selectedParcel?.id ? nextPackableParcels.find(x => x.id === selectedParcel?.id) : null;
    useEffect(() => {
        if (isLoadingAssistantSol && three) {
            const inPacking = selectedParcelToPack;
            three.updateCameraView(inPacking, highLighted)
        }
    }, [highLighted, selectedParcel, selectedParcelToPack, isLoadingAssistantSol, three])


    // Dispose Three when unmounting
    useEffect(() => {
        return () => {
            // Equivalent to previous 'ComponentWillUnmount' method
            if (three) three.dispose()
        }
    }, [three])

    /**
     * Reconstruct parcels when user makes changes to cargo spaces packed parcels.
     */
    useEffect(() => {
        if (three && parcelUpdate) {
            three.reconstructParcels(parcelUpdate)
        }
    }, [three, parcelUpdate])

    const loadSolution = (solution, index) => {
        if (!three || !solution) {
            return;
        }

        if (index !== state.currentIndex) {
            setState({ ...state, currentIndex: index, initialized: false })
            return
        }

        if (!state.initialized) {
            three.clear();
            three.drawCargoSpace(solution, index, previewCargoSpace);
            setState({ ...state, initialized: true })
        } else {
            // Check if updates are needed
            let currId = null;
            if (selectedParcel)
                currId = selectedParcel.id;
            else if (selectedParcelToPack)
                currId = selectedParcelToPack.id;

            three.updateParcels(currId, slider, isLoadingAssistantSol ? solution.cargoSpaces[index] : null); // TODO Simplify
        }


        // Change parcel opacities, visibilities, etc. when in loading assistant edit mode
        if (props.editModeState == null) {
            return;
        }
        if (props.editModeState) {
            three.applyParcelEditModeHighlight(props.parcelBeingManipulated?.parcel.id)
        }
        if (!props.editModeState) {
            three.applyParcelEditModeHighlight(null, null);
        }
        if (props.solution.cargoSpaces[props.solution.cargoSpaceIndex].packingStatus === solutionPackingStatus.packed) {
            three.applyCompletedHighlight();
        }
    }

    loadSolution(solution, index);

    const size = 595
    const divStyle = exportIndexes ? { height: `${size * 2}px`, width: `${size * 2}px`, position: "fixed", zIndex: "-1000", marginLeft: "-5000px" } : { width: '100%', height: '100%' }
    return (
        <div ref={elem => setRefToThree(elem)} style={divStyle} />
    );
}

export default Threer
