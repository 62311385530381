import React from 'react';

import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import Toolbar from '../core/Toolbar'
import Octagon from '../core/Octagon'
import { colors } from '../theme'
import { notificationDialogSeverity } from '../core/Constants';

import PasswordChange from './PasswordChange'

import { showNotification, showNotificationDialog } from '../app/AppActions';
import { users } from '../api';





const Profile = (props) => {

    const {
        classes,
    } = props;


    const userInfoItems = (
        <Paper elevation={4}>
            <List>
                {'googleId' in props.user ?
                    <>
                        <ListItem key={'email'}>
                            <ListItemText primary={'Email (Google Login)'} secondary={props.user.email} />
                        </ListItem>
                        <ListItem key={'name'}>
                            <ListItemText primary={'Name'} secondary={props.user.google.displayName} />
                        </ListItem>
                    </>
                    :
                    <>
                        <ListItem key={'email'}>
                            <ListItemText primary={'Email'} secondary={props.user.email} />
                        </ListItem>
                        <ListItem key={'name'}>
                            <ListItemText primary={'Name'} secondary={props.user.name} />
                        </ListItem>
                    </>
                }
                <ListItem key={'organization'}>
                    <ListItemText primary={'Organization'} secondary={props.user.organization.name} />
                </ListItem>
                <ListItem key={'roles'}>
                    <ListItemText primary={`${props.user.roles.length < 2 ? 'Role' : 'Roles'}`} secondary={`${props.user.roles.join(', ')}`} />
                </ListItem>
            </List>
        </Paper>
    )


    const passwordChange = (
        <Paper elevation={4}>
            <PasswordChange onPasswordChange={props.onPasswordChange} />
        </Paper>
    )


    return (
        props.user.tabletMode === true ?
            <Paper className={classes.tabletPaper}>
                <Box className={classes.tabletBox}>
                    <Toolbar title='Profile' onGoBack={() => browserHistory.push('/loadingAssistant')} />
                    <div className={classes.container} style={{ columnGap: 30 }}>
                        {userInfoItems}
                        {passwordChange}
                    </div>
                </Box>
            </Paper>
            :
            <Octagon w='80rem' color={colors.octagon}>
                <Toolbar title='Profile'></Toolbar>
                <div className={classes.container}>
                    {userInfoItems}
                    {passwordChange}
                </div>
            </Octagon>

    )
}





const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onPasswordChange: (oldPassword, newPassword, passwordConfirmation) => {
            if (oldPassword.length === 0) {
                dispatch(showNotificationDialog('Old password is required', 'Old password invalid', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            if (newPassword.length === 0) {
                dispatch(showNotificationDialog('New password is required', 'New password invalid', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            if (passwordConfirmation.length === 0) {
                dispatch(showNotificationDialog('Password confirmation is required', 'Confirmation invalid', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            if (newPassword !== passwordConfirmation) {
                dispatch(showNotificationDialog('New password and password confirmation do not match', 'Password and confirmation invalid', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            if (oldPassword === newPassword) {
                dispatch(showNotificationDialog('Old password and new password are the same', 'Old and new passwords invalid', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            return users.changePassword(oldPassword, newPassword)
                .then(() => {
                    dispatch(showNotification('Password changed'))
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
    }
}



const styles = theme => ({
    container: theme.containers.twoPaperFlex,
    tabletPaper: {
        height: '100vh',
        width: '100vw',
        backgroundColor: colors.octagon
    },
    tabletBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 50,
        marginRight: 50
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))
