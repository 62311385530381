import blobStream from 'blob-stream';
import fs from 'fs';
import PDFDocument from 'pdfkit';
import SVGtoPDF from 'svg-to-pdfkit';

import './register-files';
import { getWeightDistributionData } from '../core/WeightDistributionDialog/weightDistributionCalculcations'
import { cargoSpaceTypes } from '../core/Constants'



export const replaceAll = (text, replaceFrom, replaceTo) => text.split(replaceFrom).join(replaceTo)

export const createPdf = (cargoSpaceIndexes, cargoList, overViewImageDatas, stepByStepDatas, weightImageData, solution, organization) => {

    return new Promise(resolve => {
        let doc = new PDFDocument({ autoFirstPage: false })
        doc.font('assets/RobotoCondensed-Regular.ttf')

        const stream = doc.pipe(blobStream())

        // Initialize page number information for this PDF-document
        const pageNumberInfo = {
            currentPageNumber: 0,
            totalNumberOfPages: 0
        };

        cargoSpaceIndexes.forEach(index => {
            let overViewImageData = overViewImageDatas[index];
            let stepByStepData = stepByStepDatas[index];
            let weightData = weightImageData[index];

            const cargoSpace = solution.cargoSpaces[index];

            // Count the total number of pages for this cargo space
            pageNumberInfo.totalNumberOfPages = cargoSpaceIndexPageCount(overViewImageData, cargoList, cargoSpace, stepByStepData, weightData);

            if (overViewImageData) add3DOverviewPage(doc, overViewImageData, solution, index + 1, organization, pageNumberInfo);
            if (cargoList) addCargoListPages(doc, solution, cargoSpace, index + 1, organization, pageNumberInfo);
            if (stepByStepData) addStepByStepPages(doc, solution, stepByStepData, index + 1, organization, pageNumberInfo);
            if (weightData) addWeightDistributionPage(doc, solution, cargoSpace, index + 1, weightData, organization, pageNumberInfo)

            pageNumberInfo.currentPageNumber = 0; // Reset currentPageNumber with each cargo space index
        })

        doc.end();
        stream.on('finish', function () {
            var pdfData = stream.toBlobURL('application/pdf');
            resolve(pdfData);
        });
    })
}


// Add page to the PDF document, and add +1 to current page number
const addPage = (doc, addPageParameters, pageNumberInfo) => {
    pageNumberInfo.currentPageNumber++;
    doc.addPage(addPageParameters);
}


// Count the number of pages this cargo space index will need
const cargoSpaceIndexPageCount = (overViewImageData, cargoList, cargoSpace, stepByStepData, weightData) => {

    let maxPageNumber = 0; // Reset maxPageNumber with each new cargoSpaceIndex

    if (overViewImageData) maxPageNumber = maxPageNumber + 1; // Each cargospace gets 1 3D overview page
    if (weightData) maxPageNumber = maxPageNumber + 1; // Cargo space gets 1 weight distribution page

    // Each step-by-step page has 4 steps, so divide stepByStepData.length by 4 and round up
    if (stepByStepData) {
        maxPageNumber = Math.ceil(stepByStepData.length / 4);
    };

    // Cargolist can be multiple pages, so we need to calculate how many pages the cargolist needs
    // The calculation is the same as in the start of addCargoListPages()
    if (cargoList) {
        const maxRowsPerPage = 18; // Maximum number of rows per cargolist page

        // Copy original list, so we don't alter it
        let allParcels = Array.from(cargoSpace.packedParcels);

        // Split the list into pages and count the pages
        let pages = [];
        while (allParcels.length > 0) {
            pages.push(allParcels.splice(0, maxRowsPerPage));
        }
        const totalPages = pages.length;

        maxPageNumber = maxPageNumber + totalPages;
    }

    return maxPageNumber;
};


const add3DOverviewPage = function(doc, imageData, solution, index, organization, pageNumberInfo) {
    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')

    addPage(doc, { size: 'A4', layout: 'landscape', margin: 0 }, pageNumberInfo)

    const base64Data1 = imageData.images[0].replace(/^data:image\/(png|jpeg|svg\+xml);base64,/, "");
    const buffer1 = Buffer.from(base64Data1, 'base64')
    const imgR = doc.openImage(buffer1)
    const base64Data2 = imageData.images[1].replace(/^data:image\/(png|jpeg);base64,/, "");
    const buffer2 = Buffer.from(base64Data2, 'base64')
    const imgL = doc.openImage(buffer2)


    if (imageData.aspectRatio < 1.5) {
        // Images horizontal
        const imageWidth = doc.page.width / 2;
        const imageHeight = (doc.page.height - 30 - 30);
        const imageY = 31;
        doc.image(imgL, 0, imageY, { cover: [imageWidth, imageHeight], align: 'center', valign: 'center' })
        doc.image(imgR, (doc.page.width / 2) + 1, imageY, { cover: [imageWidth, imageHeight], align: 'center', valign: 'center' });
    }
    else {
        //Images vertical
        const imageWidth = doc.page.width;
        const imageHeight = (doc.page.height / 2) - 30;
        const image2X = 25;
        const image2Y = (doc.page.height / 2) - 8;
        doc.image(imgR, image2X, image2Y, { fit: [imageWidth, imageHeight], align: 'center', valign: 'center' })
        // .rect(image2X, image2Y, imageWidth, imageHeight).stroke(); // layout helper
        const imageX = -25;
        const imageY = 31;
        doc.image(imgL, imageX, imageY, { fit: [imageWidth, imageHeight], align: 'center', valign: 'center' })
        // .rect(imageX, imageY, imageWidth, imageHeight).stroke(); // layout helper
    }

    if (organization.isTrial)
        addTrialWatermark(doc);

    //Header
    addHeader(doc, index, solution, organization, pageNumberInfo);

    //Footer
    addFooter(doc, solution, organization);

    return;
}

const addCargoListPages = function(doc, solution, cargoSpace, index, organization, pageNumberInfo) {
    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')

    const maxRowsPerPage = 18;

    //Create a copy so that we do no alter the original list.
    let allParcels = Array.from(cargoSpace.packedParcels);

    //Split the list into pages and count the total number of pages without any possibility of rounding errors.
    let pages = [];
    while (allParcels.length > 0) {
        pages.push(allParcels.splice(0, maxRowsPerPage));
    }
    const totalPages = pages.length;

    //Column information
    const totalColumns = 7;
    const columnSpacing = 40;
    const columnHeaders = ['ID','Length','Width','Height','Weight','Stackability','Description'];
    //Convert all content to strings below, use toString() format for possible floats.
    const columnContentSelectors= [
        function(n) { return n.id; },
        function(n) { return n.dimensions.x.toString(); },
        function(n) { return n.dimensions.y.toString(); },
        function(n) { return n.dimensions.z.toString(); },
        function(n) { return n.weight.toString(); },
        function(n) { return n.stackability; },
        function(n) { return n.description; },
    ]

    //Max width for each column. 0 = Unlimited, no reason to limit.
    const columnMaxWidth = [200, 0, 0, 0, 0, 0, 0];

    //Calculate column positions for all cargo list pages for this cargo space.

    //Initialize the first column position
    const columnPositions = [20];

    //Iterate through all columns. Ignore last column since we are calculating the column start location only.
    for (let column = 0; column < totalColumns - 1; column++) {
        //The function doc.widthOfString() uses the font size set to the document so it must be set here inside the loop.

        //The actual width of the header.
        doc.fontSize(12);
        const headerWidth = doc.widthOfString(columnHeaders[column]);

        //The actual width of the longest content.
        doc.fontSize(10);
        const maxContentWidth = getMax(Array.from(cargoSpace.packedParcels), function (n) {return doc.widthOfString(columnContentSelectors[column](n).toString())});

        //Take maximum of both and add to column list.
        let columnWidth = Math.max(headerWidth, maxContentWidth) + columnSpacing;
        if (columnMaxWidth[column] !== 0 && columnWidth > columnMaxWidth[column])
            columnWidth = columnMaxWidth[column];
        columnPositions.push(columnPositions[columnPositions.length-1]+columnWidth);
    }

    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
        var parcels = pages[pageNumber];

        addPage(doc, { size: 'A4', layout: 'landscape', margin: 0 }, pageNumberInfo);
        doc.fill('#212121');

        //Header above the table
        doc.fontSize(16);
        doc.text(`Cargo List Page: ${pageNumber+1} / ${totalPages}`, 10, 40, { width: 500, align: 'left'});

        //Table

        //Table header
        const firstRowHeight = 80;
        doc.fontSize(12);
        doc.text("ID", columnPositions[0], firstRowHeight, { width: 500, align: 'left' });
        doc.text("Length", columnPositions[1], firstRowHeight, { width: 500, align: 'left' });
        doc.text("Width", columnPositions[2], firstRowHeight, { width: 500, align: 'left' });
        doc.text("Height", columnPositions[3], firstRowHeight, { width: 500, align: 'left' });
        doc.text("Weight", columnPositions[4], firstRowHeight, { width: 500, align: 'left' });
        doc.text("Stackability", columnPositions[5], firstRowHeight, { width: 500, align: 'left' });
        doc.text("Description", columnPositions[6], firstRowHeight, { width: 500, align: 'left' });
        doc.moveTo(10,firstRowHeight+15).lineTo(doc.page.width-10, firstRowHeight+15).stroke();

        //Table content
        const rowHeight = 25;
        let currentRowHeight = firstRowHeight + 2;
        doc.fontSize(10);
        parcels.forEach(parcel => {
            currentRowHeight += rowHeight;

            //All but last column
            for (let column = 0; column < totalColumns-1; column++) {
                const columnWidth = columnPositions[column+1] - columnPositions[column];
                doc.text(cutAndAddEllipsis(doc, columnContentSelectors[column](parcel), columnWidth), columnPositions[column], currentRowHeight, { width: columnWidth, align: 'left' });
            }

            //Last column
            const lastColumnIndex = totalColumns-1;
            const lastColumnWidth = doc.page.width - columnPositions[lastColumnIndex] - 20;
            doc.text(cutAndAddEllipsis(doc, columnContentSelectors[lastColumnIndex](parcel), lastColumnWidth), columnPositions[lastColumnIndex], currentRowHeight, { width: lastColumnWidth, align: 'left' });
            doc.moveTo(10,currentRowHeight+15).lineTo(doc.page.width-10, currentRowHeight+15).lineWidth(1).stroke('#999999');
        });

        if (organization.isTrial)
            addTrialWatermark(doc);

        //Header
        addHeader(doc, index, solution, organization, pageNumberInfo);

        //Footer
        addFooter(doc, solution, organization);
    }
}

const addStepByStepPages = (doc, solution, stepByStepData, cargoSpaceIndex, organization, pageNumberInfo) => {
    // No step by step requested
    if (stepByStepData.length === 0)
        return;

    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')

    const stepImageVerticalMargin = 10; // as pixels
    const numStepsPerPage = 4;

    for (let index = 0; index < stepByStepData.length; index += numStepsPerPage) {
        // Add new page
        addPage(doc, { size: 'A4', layout: 'portrait', margin: 0 }, pageNumberInfo);
        doc.fill('#212121');

        for (let idx = index; idx < stepByStepData.length && idx < index + numStepsPerPage; idx++) {
            const { imgData, parcels, colorDictionary } = stepByStepData[idx];
            const stepNumberofThisPage = idx - index;
            const stepContentTotalHeight = doc.page.height / numStepsPerPage;

            // Step's 3D image
            const base64Data = imgData.replace(/^data:image\/(png|jpeg);base64,/, "");
            const buffer = Buffer.from(base64Data, 'base64')
            const img = doc.openImage(buffer)
            const imageWidth = 290;
            const imageHeight = stepContentTotalHeight - stepImageVerticalMargin;
            const stepGap = 80 // Space between steps
            const firstStepY = 30 // Y-coordinate of the first step. Header is 30 high, so start steps from 60
            const imageX = 10;
            const imageY = (stepNumberofThisPage * ((doc.page.height - stepGap) / numStepsPerPage) + stepImageVerticalMargin / 2) + firstStepY;

            doc.image(img, imageX, imageY + 20, { fit: [imageWidth, imageHeight - 20], align: 'center', valign: 'top' })


            // Step's parcel information table

            // Table variables
            const stepTotalColumns = 2 // Determine which columns are included in the table. When changing this value, remember to include/exclude necessary headers
            const stepColumnSpacing = 20;


            // Convert all content to strings
            const stepColumnContentSelectors = [
                function(parcels) { return parcels.dimensions.x.toString() + ' × ' + parcels.dimensions.y.toString() + ' × ' + parcels.dimensions.z.toString() + ' mm'; },
                function(parcels) { return parcels.weight.toString() + ' kg'; },
            ];

            // Max width of each column. 0 === unlimited
            //HACK: We are assuming that the two columns (dimensions and weight) always fit without ellipsis implementation.
            const stepColumnMaxWidth = [0, 0];

            const tableStartX = imageWidth + 15;
            const tableTextContentStartX = tableStartX + 30;

            // Calculate column positions

            // Position of the first column on the x-axis
            const stepColumnPositions = [tableTextContentStartX];


            // Iterate through all columns. Ignore last column, we are only calculating the starting position of each column
            for (let stepColumn = 0; stepColumn < stepTotalColumns - 1; stepColumn++) {

                // Initialize font size inside loop, because doc.widthOfString() uses the font size set to the document

                //TODO: Should the calculation for column widths be done outside the loop completely?
                //The widths should be calculated for all parcels, regardless of step to get an uniform tables.

                // Width of the longest content
                doc.fontSize(10);
                // console.log(Array.from(parcels));
                const stepMaxContentWidth = getMax(Array.from(parcels), function(n) { return doc.widthOfString(stepColumnContentSelectors[stepColumn](n).toString())});

                // Take maximum of both and add to column list
                let stepColumnWidth = stepMaxContentWidth + stepColumnSpacing;
                if (stepColumnMaxWidth[stepColumn] !== 0 && stepColumnWidth > stepColumnMaxWidth[stepColumn]) {
                    stepColumnWidth = stepColumnMaxWidth[stepColumn];
                };

                stepColumnPositions.push(stepColumnPositions[stepColumnPositions.length - 1] + stepColumnWidth);
            }

            const stepFirstRowHeight = imageY; // Table position on the y-axis. Table position is based on the image position, so we don't have to change this when moving the images around

            // Table contents
            const stepRowHeight = 15;
            let currentStepRowHeight = stepFirstRowHeight + 2;
            doc.fontSize(10);
            //TODO: Maybe convert to for-loop
            let parcelNumberOfThisStep = 0;
            parcels.forEach(parcel => {
                currentStepRowHeight += stepRowHeight;

                // Parcel color icon
                //HACK: No idea why +5.
                const iconCoordinates = [tableStartX, currentStepRowHeight+stepRowHeight+5]; // x, y -coordinates
                doc.rect(iconCoordinates[0], iconCoordinates[1] - 5, 15, 15).lineWidth(1).fillAndStroke(colorDictionary[parcel.id], 'black') // rectangle(x, y-5, width, height).lineWidth(width of the outline).fillAndStroke(color of the rectangle, color of the outline)
                // TODO: If parcels is cylinder, draw a colored circle instead of rectangle
                doc.fill('#212121');

                // ID Row
                doc.fontSize(12);
                const idColumnWidth = 200;
                doc.text(
                    cutAndAddEllipsis(doc, parcel.id, idColumnWidth),
                    tableTextContentStartX, currentStepRowHeight,
                    { width: idColumnWidth, align: 'left' }
                );

                // Second row of parcel table. Parcel dimensions and weight go here.
                //A bit of extra vertical spacing to separate the ID row
                currentStepRowHeight += stepRowHeight + 2;
                doc.fontSize(10);
                for (let stepCol = 0; stepCol < stepTotalColumns; stepCol++) {
                    doc.text(stepColumnContentSelectors[stepCol](parcel), stepColumnPositions[stepCol], currentStepRowHeight, { width: 200, align: 'left' });
                }

                // Icons, aligned to left side
                const parcelIconX = doc.page.width - 40;
                const parcelIconY = currentStepRowHeight - 10;

                doc.image('/assets/box_keep_upright_icon.png', parcelIconX - 15 , parcelIconY, { width: 15, height: 15 });

                if (parcel.stackability.toString() === 'NonStackable') {
                    doc.image('/assets/box_do_not_stack_icon.png', parcelIconX, parcelIconY, { width: 15, height: 15 });
                } else if (parcel.stackability.toString() === 'Overstow') {
                    doc.image('/assets/box_overstow_icon.png', parcelIconX, parcelIconY, { width: 15, height: 15 });
                }

                // Third row parcel table. Parcel description go here.
                currentStepRowHeight += stepRowHeight;
                doc.text(cutAndAddEllipsis(doc, parcel.description, 250), tableTextContentStartX, currentStepRowHeight, { width: 250, align: 'left' });


                //Horizontal line separating parcels
                if (parcelNumberOfThisStep < parcels.length - 1)
                    doc.moveTo(tableStartX, currentStepRowHeight + 18).lineTo(doc.page.width - 10, currentStepRowHeight + 18).lineWidth(1).stroke('#999999');

                //Vertical spacing to separate parcels
                currentStepRowHeight += 10;

                //TODO: This is here because of parcels.forEach() instead of using a for-loop.
                parcelNumberOfThisStep += 1;
            });

            //Step numbering
            if (idx === stepByStepData.length - 1) { // Last step of this cargo space
                doc.text('Final step', 10, imageY + 10);
            } else {
                doc.text(`Step #${idx+1}`, 10 , imageY + 10);
            };

            // Horizontal line separating the steps.
            if (stepNumberofThisPage > 0) {
                doc.moveTo(10, imageY)
                    .lineTo(doc.page.width - 10, imageY)
                    .lineWidth(1).stroke('#999999');
            }
        }

        if (organization.isTrial)
            addTrialWatermark(doc);

        // Page header
        addHeader(doc, cargoSpaceIndex, solution, organization, pageNumberInfo);

        // Page footer
        addFooter(doc, solution, organization);
    }
}

const addWeightDistributionPage = function(doc, solution, cargoSpace, index, imageData, organization, pageNumberInfo) {
    const weightData = getWeightDistributionData(cargoSpace);

    addPage(doc, { size: 'A4', layout: 'landscape', margin: 0 }, pageNumberInfo)
    doc.fill('#212121');
    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')

    //Weight distribution image
    const base64Data = imageData.replace(/^data:image\/(png|jpeg);base64,/, "");
    const buffer = Buffer.from(base64Data, 'base64')
    const img = doc.openImage(buffer)
    const imageWidth = doc.page.width - 20;
    const imageHeight = doc.page.height / 2;
    const imageX = 10;
    const imageY = 50;
    doc.image(img, imageX, imageY, { fit: [imageWidth, imageHeight], align: 'center', valign: 'center' })

    //Sample rectangle for image layout testing
    // doc.rect(10, 50, doc.page.width-20, doc.page.height / 2).stroke('#f3740e');

    //TODO: What to do about a cargo space with no type? The line below just defaults to 'container'.
    const cargoSpaceType = cargoSpace.storages[0].cargoSpaceType || cargoSpaceTypes.container;
    if (cargoSpaceType === cargoSpaceTypes.container) {
        //Left Table
        addContainerWeightDistributionTable(doc, weightData);
        //Right Table
         addPayloadSummaryTable(doc, weightData);
    }
    else if (cargoSpaceType === cargoSpaceTypes.trailer){
        addTrailerWeightDistributionTable(doc, cargoSpace, weightData);
    }

    if (organization.isTrial)
        addTrialWatermark(doc);

    //Header
    addHeader(doc, index, solution, organization, pageNumberInfo);

    //Footer
    addFooter(doc, solution, organization);
}

const addTrailerWeightDistributionTable  = function(doc, cargoSpace, weightData) {
    //Note: building the table render location from CENTER.
    let tableStartX = doc.page.width / 2;
    const tableStartY = doc.page.height / 2 + 80;
    const tableRowHeight = 30;
    const cellHorizontalSpacing = 15;

    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.fill('#212121').stroke('#212121').fontSize(12).lineWidth(1);
    const totalRows = 4;
    const totalColumns = 3;
    const tableHeaders = ['Total', 'Front', 'Rear'];
    const tableLabels = ['Cargo Payload', 'Permissible', 'Load'];
    const tableContents = [
        //Column #1
        [
            cargoSpace.packedWeight.toLocaleString("fi-FI", {maximumFractionDigits: 0}) + ' kg',
            cargoSpace.storages[0].weightLimit.toLocaleString("fi-FI", {maximumFractionDigits: 0}) + ' kg',
            cargoSpace.storages[0].weightLimit
            ? ((cargoSpace.packedWeight / cargoSpace.storages[0].weightLimit) * 100).toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' %'
            : '--'
        ],
        //Column #2
        [
            weightData.axlesInfo[0].payload.toLocaleString("fi-FI", {maximumFractionDigits: 0}) + ' kg',
            weightData.axlesInfo[0].weightLimit.toLocaleString("fi-FI", {maximumFractionDigits: 0}) + ' kg',
            weightData.axlesInfo[0].payloadPercentage.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' %',
        ],
        //Column #3
        [
            weightData.axlesInfo[1].payload.toLocaleString("fi-FI", {maximumFractionDigits: 0}) + ' kg',
            weightData.axlesInfo[1].weightLimit.toLocaleString("fi-FI", {maximumFractionDigits: 0}) + ' kg',
            weightData.axlesInfo[1].payloadPercentage.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' %',
        ]
    ];

    //Calculate table width based on content.
    //Note: The table structure is as follows:
    /*
        ---------------------------------------
        |       | Header  |  Header |  Header |
        ---------------------------------------
        | Label | content | content | content |
        ---------------------------------------
        | Label | content | content | content |
        ---------------------------------------
        | Label | content | content | content |
        ---------------------------------------
    */

    //Change bold font when calculating the text length.
    // doc.font('assets/RobotoCondensed-Bold.ttf')
    doc.font('assets/Roboto-Medium.ttf')
    // doc.fontSize(12);
    const headerWidths = tableHeaders.map(n => doc.widthOfString(n));
    const maxLabelWidth = getMax(Array.from(tableLabels), function (n) {return doc.widthOfString(n.toString());});
    doc.font('assets/Roboto-Regular.ttf')
    // doc.font('assets/RobotoCondensed-Regular.ttf')
    // doc.fontSize(10);

    let columnContentWidths = [];
    for (let column = 0; column < totalColumns; column++) {
        columnContentWidths.push(getMax(Array.from(tableContents[column]), function (n) {return doc.widthOfString(n.toString());}));
    }

    const columnWidths = headerWidths.map((n, i) => Math.max(n, columnContentWidths[i]));

    //Adjust table location so that we can start drawing everything from LEFT to RIGHT.
    const tableWidth = maxLabelWidth + columnWidths.reduce((a,b) => a+b) + (2+totalColumns*2)*cellHorizontalSpacing;
    tableStartX -= tableWidth/2;

    let currentTableY = tableStartY;
    currentTableY += tableRowHeight;
    //Draw table borders
    //Row separators
    for (let row = 1; row < totalRows; row++) {
        if (row === 1)
            doc.moveTo(tableStartX,currentTableY).lineTo(tableStartX + tableWidth, currentTableY).stroke('#212121');
        else
            doc.moveTo(tableStartX,currentTableY).lineTo(tableStartX + tableWidth, currentTableY).stroke('#999999');

        currentTableY += tableRowHeight;
    }
    doc.stroke('#212121');

    //Column separators
    let currentColumnX = tableStartX;
    // currentColumnX += maxLabelWidth + 2*cellHorizontalSpacing;
    // doc.moveTo(currentColumnX, tableStartY).lineTo(currentColumnX, currentTableY).stroke();

    // for (let column = 0; column < totalColumns-1; column++) {
    //     currentColumnX += columnWidths[column] + 2*cellHorizontalSpacing;
    //     doc.moveTo(currentColumnX, tableStartY).lineTo(currentColumnX, currentTableY).stroke();
    // }

    //Outlining border
    // doc.moveTo(tableStartX, tableStartY)
    //     .lineTo(tableStartX, currentTableY)
    //     .lineTo(tableStartX + tableWidth, currentTableY)
    //     .lineTo(tableStartX + tableWidth, tableStartY)
    //     .lineTo(tableStartX, tableStartY)
    //     .stroke();

    //Table contents
    //Reset table height
    currentTableY = tableStartY;

    //Header row
    currentColumnX = tableStartX + maxLabelWidth + 3*cellHorizontalSpacing;
    // doc.font('assets/RobotoCondensed-Bold.ttf')
    doc.font('assets/Roboto-Medium.ttf')
    for (let column = 0; column < totalColumns; column++) {
        doc.text(tableHeaders[column], currentColumnX, currentTableY + 8, { width: columnWidths[column], align: 'left', })
        currentColumnX += columnWidths[column] + 2*cellHorizontalSpacing;
    }
    // doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.font('assets/Roboto-Regular.ttf')
    currentTableY += tableRowHeight;

    for (let row = 0; row < totalRows; row++) {
        //Label
        currentColumnX = tableStartX + cellHorizontalSpacing;
        // doc.font('assets/RobotoCondensed-Bold.ttf')
        doc.font('assets/Roboto-Medium.ttf')
        doc.text(tableLabels[row], currentColumnX, currentTableY + 8, { width: maxLabelWidth, align: 'left', })
        // doc.font('assets/RobotoCondensed-Regular.ttf')
        doc.font('assets/Roboto-Regular.ttf')

        currentColumnX += maxLabelWidth + 2*cellHorizontalSpacing;

        //Content
        for (let column = 0; column < totalColumns; column++) {
            doc.text(tableContents[column][row], currentColumnX, currentTableY + 8, { width: columnWidths[column], align: 'left', })
            currentColumnX += columnWidths[column] + 2*cellHorizontalSpacing;
        }

        currentTableY += tableRowHeight;
    }
}

const addContainerWeightDistributionTable = function(doc, weightData) {
    //Note: building the table render location from RIGHT to LEFT.
    let tableStartX = doc.page.width / 2 - 50;
    const tableStartY = doc.page.height / 2 + 100;
    const tableRowHeight = 25;
    const cellHorizontalSpacing = 15;

    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.fill('#212121').stroke('#212121').fontSize(12).lineWidth(1);
    const totalRows = 4;
    const tableHeaders = ['Center of gravity offsets', 'Max offset']
    const tableLabels = ['Lengthwise', 'Crosswise', 'Vertical'];
    const tableContents = [
        [
            weightData.cogDiff.x.toLocaleString("fi-FI", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' m',
            weightData.cogDiff.y.toLocaleString("fi-FI", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' m',
            weightData.cogDiff.z.toLocaleString("fi-FI", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' m',
        ],[
            weightData.permissibleCogDiff.x
                ? '+/- ' + weightData.permissibleCogDiff.x.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 2}) + ' m'
                : '--',
            weightData.permissibleCogDiff.y
                ? '+/- ' + weightData.permissibleCogDiff.y.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 2}) + ' m'
                : '--',
            weightData.permissibleCogDiff.z
                ? '+/- ' + weightData.containerInfo.permissibleCogDiff.z.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 2}) + ' m'
                : '--',
        ]
    ];

    //Calculate table width based on content.
    //Note: The table structure is as follows:
    /*
        -----------------------------
        |      Header     |  Header |
        -----------------------------
        | Label | content | content |
        -----------------------------
        | Label | content | content |
        -----------------------------
        | Label | content | content |
        -----------------------------
    */

    //Change bold font when calculating the text length.
    // doc.font('assets/RobotoCondensed-Bold.ttf')
    doc.font('assets/Roboto-Medium.ttf')
    const firstHeaderWidth = doc.widthOfString(tableHeaders[0]);
    const secondHeaderWidth = doc.widthOfString(tableHeaders[1]);
    const maxLabelWidth = getMax(Array.from(tableLabels), function (n) {return doc.widthOfString(n.toString());});
    // doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.font('assets/Roboto-Regular.ttf')

    //First content column
    const column1MaxContentWidth = getMax(Array.from(tableContents[0]), function (n) {return doc.widthOfString(n.toString());});

    //Second content column
    const column2MaxContentWidth = getMax(Array.from(tableContents[1]), function (n) {return doc.widthOfString(n.toString());});

    //Maximum of two-column-spanning header and the two columns under it
    const firstTwoColumnTotalWidth = Math.max(firstHeaderWidth, maxLabelWidth + column1MaxContentWidth) + 4*cellHorizontalSpacing;

    //Final column width
    const thirdColumnTotalWidth = Math.max(secondHeaderWidth, column2MaxContentWidth) + 2*cellHorizontalSpacing;

    //Adjust table location so that we can start drawing everything from LEFT to RIGHT.
    const tableWidth = firstTwoColumnTotalWidth + thirdColumnTotalWidth;
    tableStartX -= tableWidth;

    let currentTableY = tableStartY;
    currentTableY += tableRowHeight;

    //Draw table borders
    for (let row = 1; row < totalRows; row++) {
        //Horizontal lines
        doc.moveTo(tableStartX,currentTableY).lineTo(tableStartX + tableWidth, currentTableY).stroke("#999999");

        currentTableY += tableRowHeight;
    }
    doc.stroke('#212121');

    //First column separator
    // const firstColumnX = tableStartX + maxLabelWidth + 2*cellHorizontalSpacing;
    // doc.moveTo(firstColumnX,tableStartY + tableRowHeight).lineTo(firstColumnX, currentTableY).stroke();

    //Second column separator
    // const secondColumnX = tableStartX + firstTwoColumnTotalWidth;
    // doc.moveTo(secondColumnX,tableStartY).lineTo(secondColumnX, currentTableY).stroke();

    //Outlining border
    // doc.moveTo(tableStartX, tableStartY)
    //     .lineTo(tableStartX, currentTableY)
    //     .lineTo(tableStartX + tableWidth, currentTableY)
    //     .lineTo(tableStartX + tableWidth, tableStartY)
    //     .lineTo(tableStartX, tableStartY)
    //     .stroke();

    //Table contents
    //Reset table height
    currentTableY = tableStartY;

    //Header row
    // doc.font('assets/RobotoCondensed-Bold.ttf');
    doc.font('assets/Roboto-Medium.ttf');
    doc.text(tableHeaders[0], tableStartX + cellHorizontalSpacing, currentTableY + 6, { width: firstTwoColumnTotalWidth, align: 'left', });
    doc.text(tableHeaders[1], tableStartX + firstTwoColumnTotalWidth + cellHorizontalSpacing, currentTableY + 6, { width: maxLabelWidth, align: 'left', });
    // doc.font('assets/RobotoCondensed-Regular.ttf');
    doc.font('assets/Roboto-Regular.ttf');
    currentTableY += tableRowHeight;

    for (let row = 0; row < totalRows; row++) {
        //Label
        // doc.font('assets/RobotoCondensed-Bold.ttf');
        doc.font('assets/Roboto-Medium.ttf');
        doc.text(tableLabels[row], tableStartX + cellHorizontalSpacing, currentTableY + 6, { width: maxLabelWidth, align: 'left', });
        doc.font('assets/RobotoCondensed-Regular.ttf');
        doc.font('assets/Roboto-Regular.ttf');

        //Content
        doc.text(tableContents[0][row], tableStartX + maxLabelWidth + 3*cellHorizontalSpacing, currentTableY + 6, { width: column1MaxContentWidth, align: 'left', })
        doc.text(tableContents[1][row], tableStartX + firstTwoColumnTotalWidth + cellHorizontalSpacing, currentTableY + 6, { width: thirdColumnTotalWidth, align: 'left', })

        currentTableY += tableRowHeight;
    }
}

const addPayloadSummaryTable = function (doc, weightData) {
    const tableStartX = doc.page.width / 2 + 50;
    const tableStartY = doc.page.height / 2 + 100;
    const tableRowHeight = 25;
    const cellHorizontalSpacing = 15;

    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.fill('#212121').stroke('#212121').fontSize(12).lineWidth(1);

    const tableLabels = [
        'Max payload',
        'Total used length',
        'Corrected max payload',
        'Packed payload',
        'Packed payload'];
    const tableContents = [
        weightData.maxPayload.toLocaleString("fi-FI") +' kg',
        weightData.totalUsedLength.toLocaleString("fi-FI") +' m',
        weightData.correctedMaxPayload.toLocaleString("fi-FI",{maximumFractionDigits: 0}) +' kg',
        weightData.packedPayload.toLocaleString("fi-FI") + ' kg',
        weightData.packedPayloadPercentage.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' %',
    ]
    const totalRows = tableLabels.length;

    //Change bold font when calculating the text length
    // doc.font('assets/RobotoCondensed-Bold.ttf')
    doc.font('assets/Roboto-Medium.ttf')
    const maxLabelWidth = getMax(Array.from(tableLabels), function (n) {return doc.widthOfString(n.toString());});
    // doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.font('assets/Roboto-Regular.ttf')
    const maxContentWidth = getMax(Array.from(tableContents), function (n) {return doc.widthOfString(n.toString());});
    const tableWidth = maxLabelWidth + maxContentWidth + 4*cellHorizontalSpacing;

    let currentTableY = tableStartY;
    currentTableY += tableRowHeight;
    //Draw table borders
    for (let row = 1; row < totalRows; row++) {
        //Horizontal lines
        // if (row === 1)
        //     doc.moveTo(tableStartX,currentTableY).lineTo(tableStartX + tableWidth, currentTableY).stroke('#212121');
        // else
        doc.moveTo(tableStartX,currentTableY).lineTo(tableStartX + tableWidth, currentTableY).stroke('#999999');


        currentTableY += tableRowHeight;
    }
    doc.stroke('#212121');

    //Column separator
    // const columnX = tableStartX + maxLabelWidth + 2*cellHorizontalSpacing;
    // doc.moveTo(columnX,tableStartY).lineTo(columnX, currentTableY).stroke();

    //Outlining border
    // doc.moveTo(tableStartX,tableStartY)
    //     .lineTo(tableStartX, currentTableY)
    //     .lineTo(tableStartX + tableWidth, currentTableY)
    //     .lineTo(tableStartX + tableWidth,tableStartY)
    //     .stroke();

    //Table contents
    //Reset table height
    currentTableY = tableStartY;
    for (let row = 0; row < totalRows; row++) {
        //Label
        // doc.font('assets/RobotoCondensed-Bold.ttf')
        doc.font('assets/Roboto-Medium.ttf')
        doc.text(tableLabels[row], tableStartX + cellHorizontalSpacing, currentTableY + 6, { width: maxLabelWidth, align: 'left', })
        // doc.font('assets/RobotoCondensed-Regular.ttf')
        doc.font('assets/Roboto-Regular.ttf')

        //Content
        doc.text(tableContents[row], tableStartX + maxLabelWidth + 3*cellHorizontalSpacing, currentTableY + 6, { width: maxContentWidth, align: 'left', })

        currentTableY += tableRowHeight;
    }
}

const addHeader = function(doc, index, solution, organization, pageNumberInfo) {
    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')

    // Build around the fixed size logo area so that both portrait and landscape work with this same implementation.
    const logoAreaWidth = 180;

    // Width of the orange polygons
    const polygonWidth = doc.page.width/2 - logoAreaWidth/2;

    // Left orange header polygon
    doc.fillColor('#f3740e').polygon(
        [0, 0], // top left corner
        [0, 30], // bottom left corner
        [polygonWidth - 50, 30], // bottom right corner
        [polygonWidth, 0] // top right corner
        ).fill('non-zero');

    // Right orange header polygon
    doc.fillColor('#f3740e').polygon(
        [doc.page.width - (polygonWidth), 0], // top left corner
        [doc.page.width - polygonWidth + 50, 30], // bottom left corner
        [doc.page.width, 30], // bottom right corner
        [doc.page.width, 0] // top right corner
    ).fill('non-zero');

    //Color to white
    doc.fillColor('#FFF');

    //Left side content of the header bar
    doc.fontSize(8);
    doc.text("Loading plan", 10, 2, { width: 500, align: 'left' });
    doc.fontSize(16);
    const maxLengthForName = polygonWidth - 20 - 50;
    //Can't use pdfkit built-in ellipsis because it doesn't break words. Since the ID is typically a single slug, it just needs to be broken.
    //TODO: Should we think about automatic font downscaling before using the ellipsis? There is not that much space in the portrait orientation.
    doc.text(cutAndAddEllipsis(doc, solution.name, maxLengthForName), 20, 10, { width: maxLengthForName, align: 'left'});
    doc.fontSize(12);

    //Right side content of the header bar
    doc.fontSize(8);
    doc.text("Cargo space", doc.page.width - 80 - 10 , 2, { width: 40, align: 'center' });
    doc.text("Page", doc.page.width - 40 - 10, 2, { width: 40, align: 'center' });
    doc.fontSize(16);
    doc.text(`${index}/${solution.cargoSpaces.length}`, doc.page.width - 80 - 10, 10, { width: 40, align: 'center' });
    doc.text(`${pageNumberInfo.currentPageNumber}/${pageNumberInfo.totalNumberOfPages}`, doc.page.width - 40 - 10, 10, { width: 40, align: 'center' })
    doc.fontSize(12);

    //Client logo in the middle

    // User organization logo is available
    //HACK: To lay on top of the 3D render background, addHeader is called after the page content!
    const logoWidth = logoAreaWidth - 0;
    const logoStartX = doc.page.width/2 - logoWidth/2;
    const logoStartY = 5;
    if (organization.logo) {
        const base64Data = organization.logo.replace(/^data:image\/(png|jpeg|svg\+xml);base64,/, "");
        const buffer = Buffer.from(base64Data, 'base64')

        // Check if the image is of a type SVG.
        // Check if it exists before the base64 part which is always present ("data:image/XXX;base64")
        // because the base64 data could theoretically contain 'svg' string which would be identified incorrectly.
        const splitted = organization.logo.split("base64");
        if (splitted.length > 1 && splitted[0].includes("svg")) {
            const logoSvg = buffer.toString();

            SVGtoPDF(doc, logoSvg, logoStartX, logoStartY, { width: logoWidth, height: 30, preserveAspectRatio: 'xMidYMid meet' });
        }
        // Image is of a type jpg or png
        else {
            const logoImage = doc.openImage(buffer);
            doc.image(logoImage, logoStartX, logoStartY, {fit: [logoWidth, 30], align: 'center', valign: 'center'});
        }
    } else {
        // Organization logo is not defined, use boxbot logo as default
        const svg = fs.readFileSync('assets/boxbot.svg').toString();
        SVGtoPDF(doc, svg, logoStartX, logoStartY, { width: logoWidth, height: 30, preserveAspectRatio: 'xMidYMid meet' });
    }
}

const addFooter = function(doc, solution, organization) {
    // Reset font back to normal
    doc.font('assets/RobotoCondensed-Regular.ttf')

    //Footer bar
    doc.rect(0, doc.page.height - 30, doc.page.width, 30).fill('#348ba2');

    //Color to white
    doc.fill('#FFF');

    //Left side content of the footer bar
    const svg = fs.readFileSync('assets/boxbot-white_vector_roboto.svg').toString();
    SVGtoPDF(doc, svg, 15, doc.page.height - 27, { height: 24, preserveAspectRatio: 'xMinYMin meet' });

    //Middle content of the footer bar
    doc.fontSize(8);
    doc.text('PDF Export from BOXBOT', 0, doc.page.height - 14, { align: 'center' })

    //Right side content of the footer bar
    const createdDate = solution.createdAt ? new Date(Date.parse(solution.createdAt)) : new Date();
    const date = createdDate.toISOString().split("T")[0];
    doc.fontSize(16);
    doc.text(date, doc.page.width - 500 - 10, doc.page.height - 22, { width: 500, align: 'right' });
}

const addTrialWatermark = function (doc) {
    doc.font('assets/RobotoCondensed-Regular.ttf')
    doc.fill('#212121').opacity(0.1);
    doc.fontSize(80);
    let tileX = -140;
    // let tileY = -180;
    let tileY = 100;
    doc.rotate(-30);
    for (let row = 0; row <= 3; row++) {
        tileX = -140 - row*120;
        for (let column = 0; column <= 4; column++) {
            doc.text(`TRIAL`, tileX, tileY, {lineBreak: false});
            tileX += 300;
        }
        tileY += 200;
    }
    doc.rotate(30);
    doc.fill('#212121').opacity(1);

    doc.fontSize(12);
    doc.text('This print-out was generated with a trial version of BOXBOT.', 0, 37, {width: doc.page.width, align: 'center'});
    doc.text('This print-out was generated with a trial version of BOXBOT.', 0, doc.page.height - 50, {width: doc.page.width, align: 'center'});
}

const cutAndAddEllipsis = function(doc, text, maxLength) {
    if (!text) {
        return null;
    }

    let textLength = doc.widthOfString(text);
    if (isNaN(textLength))
        //TODO: What is a proper way of throwing and handling errors?
        throw new Error('Text length is NaN. Text is probably not a string, check the data type.');

    if (textLength <= maxLength)
        return text;

    const ellipsis = "\u2026";
    const ellipsisLength = doc.widthOfString(ellipsis);

    //Reduce the text length character by character until the total length is short enough.
    while (textLength + ellipsisLength > maxLength) {
        text = text.slice(0, -1);
        textLength = doc.widthOfString(text);
    }

    return text + ellipsis;
}

//Source: https://codeburst.io/javascript-finding-minimum-and-maximum-values-in-an-array-of-objects-329c5c7e22a2
const getMax = function(array, selector) {
    return array.reduce((max, p) =>  selector(p) > max ? selector(p) : max, selector(array[0]));
}
