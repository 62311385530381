import React from 'react'

import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import NotesIcon from '@material-ui/icons/Notes';
import { browserHistory } from 'react-router'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Toolbar from '../core/Toolbar'
import Octagon from '../core/Octagon'
import { colors } from '../theme'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import MyOrganization from './MyOrganization'

//////////////////////////////////////////////////////////////////////////////

const onNavigate = pathname => browserHistory.push(pathname)

const Admin = ({ user }) => {
    const menuItems = [];

    menuItems.push((
        <ListItem button key='users' onClick={() => onNavigate('/admin/users')}>
            <ListItemIcon>{<AccountBoxIcon />}</ListItemIcon>
            <ListItemText primary={'Users'} />
        </ListItem>
    ))

    menuItems.push((
        <ListItem button key='CargoSpacePresets' onClick={() => onNavigate('/admin/cargospacepresets')}>
            <ListItemIcon>{<ViewCompactIcon />}</ListItemIcon>
            <ListItemText primary={'Cargo Space Presets'} />
        </ListItem>
    ))

    if (user.roles?.includes('superadmin')) {
        menuItems.push((
            <ListItem button key='organizations' onClick={() => onNavigate('/admin/organizations')}>
                <ListItemIcon>{<AccountTreeIcon />}</ListItemIcon>
                <ListItemText primary={'Organizations'} />
            </ListItem>
        ))
        menuItems.push((
            <ListItem button key='logs' onClick={() => onNavigate('/admin/logs')}>
                <ListItemIcon>{<NotesIcon />}</ListItemIcon>
                <ListItemText primary='Logs' />
            </ListItem>
        ))
    }

    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title='Admin' />
            <MyOrganization />
            <Paper elevation={4}>
                <List dense={menuItems.length > 3}>
                    {menuItems}
                </List>
            </Paper>
        </Octagon>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)

//////////////////////////////////////////////////////////////////////////////
