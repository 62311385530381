import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Redirect, browserHistory } from 'react-router'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import authentication from '@feathersjs/authentication-client'

import './index.css';
import App from './app/App';
import SignIn from './signin/SignIn'
import SignOut from './signin/SignOut'
import SignUp from './signup/SignUp';
import EmailConfirmation from './emailConfirmation/EmailConfirmation';
import ResendEmailConfirmation from './ResendEmailConfirmation/ResendEmailConfirmation';
import Home from './home/Home'
import Optimize from './optimize/Optimize'
import Solutions from './solutions/Solutions'
import LoadingAssistant from './loadingAssistant/LoadingAssistant'
import LoadingAssistantTabletCompletedJobs from './loadingAssistant/tablet/LoadingAssistantTabletCompletedJobs'
import LoadingAssistantTabletLoadingOperationsView from './loadingAssistant/tablet/LoadingAssistantTabletLoadingOperationsView';
import Profile from './profile/Profile'
import Admin from './admin/Admin'
import AdminUsers from './admin/Users/AdminUsers'
import AdminUserEdit from './admin/Users/AdminUserEdit'
import AdminOrganizations from './admin/Organizations/AdminOrganizations'
import AdminOrganizationEdit from './admin/Organizations/AdminOrganizationEdit'
import CargoSpacePresets from './admin/CargoSpacePresets/CargoSpacePresets'
import CargoSpacePresetEdit from './admin/CargoSpacePresets/CargoSpacePresetEdit'
import SignUpEnabler, { IsSignUpEnabled } from './core/SignUpFeature'
import SuperadminLogsHome from './superadminLogs/SuperadminLogsHome'
import SuperadminLogsEmailConfirmations from './superadminLogs/SuperadminLogsEmailConfirmations';
import SuperadminLogsTrialRegistrations from './superadminLogs/SuperadminLogsTrialRegistrations';
import SuperadminLogsPasswordResets from './superadminLogs/SuperadminLogsPasswordResets';
import SuperadminLogsOptimizationTasks from './superadminLogs/SuperadminLogsOptimizationTasks';
import SuperadminLogsLoadingPlanCreations from './superadminLogs/SuperadminLogsLoadingPlanCreations';
import PasswordReset from './passwordreset/PasswordReset'

import Header from './app/Header';
import Footer from './app/Footer';
import Nav from './app/NavBar/Nav';
import DynamicNav from './app/NavBar/DynamicNav';

import reducers from './reducers'
import { authUser, deauthUser, showNotificationDialog } from './app/AppActions'
import { notificationDialogSeverity } from './core/Constants';

export const store = createStore(reducers)
// export const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

if (process.env.NODE_ENV === "development") {
    console.log('This app is using DEVELOPMENT environment');
    console.log('REACT_APP_API_URL = ' + process.env.REACT_APP_API_URL);
    console.log('REACT_APP_OAUTH_URL = ' + process.env.REACT_APP_OAUTH_URL);
}
if (process.env.NODE_ENV === "production") {
    if (!process.env.REACT_APP_API_URL)
        console.error('REACT_APP_API_URL is not set!')
    if (!process.env.REACT_APP_OAUTH_URL)
        console.error('REACT_APP_OAUTH_URL is not set!')
}

const socket = io(process.env.REACT_APP_API_URL)
// Use the row below to use a custom path for the socket-io. See also Caddyfile.
//const socket = io(process.env.REACT_APP_API_URL, { path: '/custom-path/'})

const app = feathers()
    // .configure(rest(process.env.REACT_APP_API_URL).superagent(superagent))
    .configure(socketio(socket)) // socket.io client
    .configure(authentication({ storage: window.localStorage }))

// TODO is this the right place for this ??
const allowedUserlessPaths = ['/signin', '/passwordreset'];
// Add the sign up related paths only when it's enabled --> when it's not enabled, these paths are not accessible
if (IsSignUpEnabled) {
    allowedUserlessPaths.push(...['/signup', '/emailConfirmation', '/resendConfirmation'])
}

/**
 * Matches the given pathname to configured paths that doesn't require authentication
 * @param {String} pathname
 * @returns Boolean
 */
export const pathRequiresAuthentication = (pathname) => {

    // If the pathname doesn't start with some of allowedUserlessPaths, authentication is required
    return !allowedUserlessPaths.some(userlessPath => pathname.indexOf(userlessPath) === 0)
}


/**
 * When entering a route, run a validator, handlePath(), that checks if user is allowed to navigate to a particular page.
 * nextState and replace are parameters from <route> onEnter-prop.
 * More info: https://knowbody.github.io/react-router-docs/api/Route.html
 * @param {Object} nextState is the router state for the route that is about to be entered
 * @param {Function} replace is a function to redirect user to another page
 */
function enterHandler(nextState, replace) {
    const pathname = nextState.location.pathname;

    //Get user from Redux state
    const user = store.getState().user;
    //If we have no user, none is logged in.
    if (!user || !user._id) {
        // User is in one of the allowed userless paths - no need to redirect
        if (!pathRequiresAuthentication(pathname))
            return;

        replace('/signin')
        return;
    }


    try {
        if (pathname.startsWith('/auth')) {
            replace('/')
            return;
        }

        if (pathname === '/signout' || (pathname === '/signin' && nextState.location?.state?.deauthenticated))
            return; // Do nothing

        if (pathname === '/' || pathname === '' || !pathRequiresAuthentication(pathname)) {
            replace('/home')
            return;
        }

        if (user.roles) {
            if (pathname.startsWith('/admin') && !user.roles.includes('officeAdmin') && !user.roles.includes('superadmin')) {
                replace('/home')
                return;
            }
            if (pathname.startsWith('/admin/logs') && !user.roles.includes('superadmin')) {
                replace('/home')
                return;
            }
            if (pathname.startsWith('/admin/organizations') && !user.roles.includes('superadmin')) {
                replace('/home')
                return;
            }
            if (pathname.startsWith('/loadingAssistant') && (!user.organization.enabledFeatures || !user.organization.enabledFeatures.includes('loadingAssistant'))) {
                replace('/home')
                return;
            }

            if (!user.tabletMode) {
                // No user roles --> only Home page available
                if (user.roles.length === 0 && !(pathname === '/home' || pathname === '/profile')) {
                    replace('/home');
                    return;
                }

                // Redirect from loadingAssistant (tablet) to desktop view
                if (pathname === '/loadingAssistant') {
                    replace('/loadingAssistantDesktop');
                    return;
                }
                // loadingAssistantDesktop is the only LA path for non-tablet user
                if (pathname.startsWith('/loadingAssistant') && pathname !== '/loadingAssistantDesktop') {
                    replace('/home');
                    return;
                }
                return;
            } else {
                // Redirect from loadingAssistantDesktop to tablet view
                if (pathname === '/loadingAssistantDesktop') {
                    replace('/loadingAssistant');
                    return;
                }

                // Allowed, non-loadingAssistant related paths for tablet user
                if (pathname.startsWith('/profile') || pathname.startsWith('/signout')) {
                    return; // Do nothing
                }

                // Redirect user to loadingAssistant
                if (!pathname.startsWith('/loadingAssistant')) {
                    replace('/loadingAssistant');
                    return;
                }
                return;
            }
        }
    }
    catch (err) {
        console.error(err)
        //Get user from Redux state
        const user = store.getState().user;
        //If we have no user, none is logged in.
        if (user && user._id) {
            store.dispatch(deauthUser());
            app.logout();
        }
        replace('/signin');
    }
}

const getJsonFromUrl = str => {
    const query = str.substr(1);
    const result = {};

    query.split('&').forEach(function (part) {
        const item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
}

// Check for failed OAuth authentication error in URL hash
const hash = window.location.hash
const { error } = getJsonFromUrl(hash)
if (error) {
    store.dispatch(showNotificationDialog(`Unable to sign in. Invalid domain.`, 'Invalid domain', notificationDialogSeverity.error))
}

app.reAuthenticate()
    .then(r => {
        //Populate Redux state with user information if authenticated.
        store.dispatch(authUser(r.user))
        initRender()
    })
    .catch(err => {
        initRender()
    })

const userLessComponents = {
    header: Header
}

const desktopComponents = {
    header: Header,
    navBar: DynamicNav,
    footer: Footer
}

const tabletComponents = {
    header: Header,
    navBar: Nav,
    theme: "tabletTheme"
}

function initRender() {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={browserHistory} >
                <Route path="/" component={App} onEnter={enterHandler} onChange={(prevState, nextState, replace) => enterHandler(nextState, replace)} >
                    {/* Userless paths */}
                    <Route path="signin" components={{ component: SignIn, ...userLessComponents }} />
                    <Route path="signout" components={{ component: SignOut, ...userLessComponents }} />
                    <SignUpEnabler>
                        <Route path="signup" components={{ component: SignUp, ...userLessComponents }} />
                        <Route path="emailConfirmation/:token" components={{ component: EmailConfirmation, ...userLessComponents }} />
                        <Route path="resendConfirmation" components={{ component: ResendEmailConfirmation, ...userLessComponents }} />
                    </SignUpEnabler>
                    <Route path="passwordreset" components={{ component: PasswordReset, ...userLessComponents }} />

                    {/* Desktop paths */}
                    <Route path="home" components={{ component: Home, ...desktopComponents }} />
                    <Route path="optimizer" components={{ component: Optimize, ...desktopComponents }} />
                    <Route path="optimizer/:id" components={{ component: Optimize, ...desktopComponents }} />
                    <Route path="solutions" components={{ component: Solutions, ...desktopComponents }} />

                    {/* Hacky way to split the same page for desktop / tablet */}
                    <Route path="profile" getComponents={(nextState, cb) => {
                        const components = store.getState().user?.tabletMode ? tabletComponents : desktopComponents;
                        cb(null, { component: Profile, ...components });
                    }} />

                    <Route path="admin" components={{ component: Admin, ...desktopComponents }} />
                    <Route path="admin/users" components={{ component: AdminUsers, ...desktopComponents }} />
                    <Route path="admin/users/new" components={{ component: AdminUserEdit, ...desktopComponents }} />
                    <Route path="admin/users/:id" components={{ component: AdminUserEdit, ...desktopComponents }} />
                    <Route path="admin/organizations" components={{ component: AdminOrganizations, ...desktopComponents }} />
                    <Route path="admin/organizations/new" components={{ component: AdminOrganizationEdit, ...desktopComponents }} />
                    <Route path="admin/organizations/:id" components={{ component: AdminOrganizationEdit, ...desktopComponents }} />
                    <Route path="admin/cargospacepresets" components={{ component: CargoSpacePresets, ...desktopComponents }} />
                    <Route path="admin/cargospacepresets/new" components={{ component: CargoSpacePresetEdit, ...desktopComponents }} />
                    <Route path="admin/cargospacepresets/:id" components={{ component: CargoSpacePresetEdit, ...desktopComponents }} />
                    <Route path="admin/logs" components={{ component: SuperadminLogsHome, ...desktopComponents }} />
                    <Route path="admin/logs/emailConfirmations" components={{ component: SuperadminLogsEmailConfirmations, ...desktopComponents }} />
                    <Route path="admin/logs/trialRegistrations" components={{ component: SuperadminLogsTrialRegistrations, ...desktopComponents }} />
                    <Route path="admin/logs/passwordResets" components={{ component: SuperadminLogsPasswordResets, ...desktopComponents }} />
                    <Route path="admin/logs/optimizationTasks" components={{ component: SuperadminLogsOptimizationTasks, ...desktopComponents }} />
                    <Route path="admin/logs/loadingPlanCreations" components={{ component: SuperadminLogsLoadingPlanCreations, ...desktopComponents }} />

                    {/* Tablet paths */}
                    <Route path="loadingAssistant" components={{ component: LoadingAssistant, ...tabletComponents }} />
                    <Route path="loadingAssistant/loading/:id/:cargoSpaceIndex" components={{
                        component: LoadingAssistantTabletLoadingOperationsView,
                        ...tabletComponents,
                        header: null // Remove normal app header from loadingOperationsView. LoadingOperationsView uses it's own special header, loadingOperationsViewHeader.
                    }} />
                    <Route path="loadingAssistant/completedJobs" components={{ component: LoadingAssistantTabletCompletedJobs, ...tabletComponents }} />
                    <Redirect from="*" to="/" />
                </Route>
            </Router>
        </Provider>,
        document.getElementById('root'))
}

export default app
