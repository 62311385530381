import React from "react";

import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const LoadingAssistantTabletLoadingOperationsViewHeader = (props) => {
    const { classes } = props;

    return (
        <AppBar className={classes.appbar} color="primary">
            <Toolbar className={classes.toolbar}>
                <img
                    src="/assets/BOXBOT_white.svg"
                    className={classes.logo}
                    alt=" "
                />
                <Typography className={classes.title} variant="h6">
                    {props.title}
                </Typography>
                <IconButton
                    className={classes.closeButton}
                    onClick={props.navigateHome}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

const styles = (theme) => ({
    appbar: {
        zIndex: theme.zIndex.drawer + 1,
        top: 0,
        left: 0,
        width: "100vw",
        margin: 0,
        height: "4.0rem",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    logo: {
        height: "2.0rem",
        marginLeft: "0.4rem",
    },
    title: {
        color: theme.palette.common.white,
    },
    closeButton: {
        color: theme.palette.common.white,
    },
});

export default withStyles(styles)(
    LoadingAssistantTabletLoadingOperationsViewHeader
);
