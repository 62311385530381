
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/core/styles'

import LMCLoadingScreen from '../LMCComponents/LMCLoadingScreen'
import LMCNotAllowedDialog from '../LMCComponents/LMCNotAlloweDialog'
import LMCParcelDimensionTransformCard from '../LMCComponents/LMCParcelDimensionTransformCard'
import LMCResultHandler from './LMCResultHandler'
import LMCSelectedParcelCard from '../LMCComponents/LMCSelectedParcelCard'

import LoadingAssistantBoxCards from '../LoadingAssistantBoxCards'
import LoadingAssistantEditModal from '../LoadingAssistantEditModal'
import LoadingAssistantTabletLoadingOperationsViewHeader from '../../app/LoadingAssistantTabletLoadingOperationsViewHeader'
import Threer from '../../three/Threer'

import ActionButtonContainer from '../LOVComponents/ActionButtonContainer'
import CancelLoadingJobDialog from '../LOVComponents/CancelLoadingJobDialog'
import ContinueTimedOutLoadingJobDialog from '../LOVComponents/ContinueTimedOutLoadingJobDialog'
import FabButtonContainer from '../LOVComponents/FabButtonContainer'
import ForceCompletionDialog from '../LOVComponents/ForceCompletionDialog'
import JoinLoadingJobDialog from '../LOVComponents/JoinLoadingJobDialog'
import LOVErrorDialog from '../LOVComponents/LOVErrorDialog'
import PackedParcelsProgressBar from '../LOVComponents/PackedParcelsProgressBar'
import WorkersDrawer from '../LOVComponents/WorkersDrawer'

import { colors } from '../../theme'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { isEqual, cloneDeep } from 'lodash'

import { loadingAssistantApi, checkAuth, users, solutions as solutionsApi, LMC as LMCapi, optimize as optimizeApi } from '../../api'
import { showNotificationDialog } from '../../app/AppActions'
import { solutionPackingStatus, parcelPackingStatus, lmcStatus, lmcStrategies } from '../Constants';
import { notificationDialogSeverity } from '../../core/Constants'
import { useCancellablePromise } from '../../hooks';
import WeightDistributionDialog from '../../core/WeightDistributionDialog/weightDistributionDialog'

//////////////////////////////////////////////////////////////////////////////


const LoadingAssistantTabletLoadingOperationsView = props => {

    const {
        reduxState,
        reduxDispatch,
        classes
    } = props;



    // State-object that includes states related to the current loading plan
    const [solutionState, setSolutionState] = useState({
        solution: null, // Solution that includes the current cargo space
        componentHasMounted: false, // Has component mounted on the page?
        dbUpdateInProgress: false, // Are we updating the database currently?
        currentWorkers: []
    });

    // States for parcels
    const [parcelState, setParcelState] = useState({
        previewParcel: null, // Parcel that is shown when clicking a card. Also controls which card is highlighted
        selectedParcels: [], // Array of parcels that have been selected in this cargo space.
        threeParcelsUpdate: [],
        selectedParcelToPack: null
    });

    // State-object that controls the LMC-dialogs
    const [lmcDialogState, setLmcDialogState] = useState({
        box: null,
        open: false
    });

    // States for miscellaneous dialogs
    const [miscDialogState, setMiscDialogState] = useState({
        joinLoadingJobDialogOpen: false,
        cancelLoadingJobDialogOpen: false,
        continueTimedOutLoadingJobDialogOpen: false,
        closingPage: false, // Is the user in the process of closing the page? When user is closing the page we don't want to process further heard patch-emits from solutionsService.
        forceCompletionDialogOpen: false,
    });

    // State for weight distribution dialog
    const [weightDialogOpen, setWeightDialogOpen] = useState(false)

    // State-object that controls errorDialog
    const [errorDialogState, setErrorDialogState] = useState({
        errorDialogOpen: false, // Is dialog open or not?
        errorTitle: null, // Title for the error dialog
        errorMessage: null // errorMessage is shown on the dialogs DialogContentText-field
    });

    // loadingPlanSelected indicates if user is inspecting the loading job, or has "selected" it and started working on the job.
    // loadingPlanSelected is set to true when user presses either the 'join' in joinLoadingJobDialog, or 'continue/start' in the inspect view
    const [loadingPlanSelected, setLoadingPlanSelected] = useState(false);

    // State that controls opening/closing the drawer
    const [drawerOpen, setDrawerOpen] = useState(false);

    // State-object for the dialog that is shown when clicking an arrow on a Threer parcel
    const [parcelTransformControlsState, setParcelTransformControlsState] = useState({
        open: false,
        direction: undefined,
    })

    // State that controls enabling/disabling of the edit mode
    const [editModeState, setEditModeState] = useState(false)

    // State-object for clicked object on the screen
    const [manipulatedObjectState, setManipulatedObjectState] = useState({
        manipulatedParcel: null,
        showArrows: null, // Tells which arrows should be displayed. 'all' shows all arrows on a parcel
    });

    // State to determine wether to show not allowed dialog
    const [LMCNotAllowedOpen, setLMCNotAllowedOpen] = useState(false);

    // State to store all users
    const [allUsers, setAllUsers] = useState([]);



    const cargoSpace = solutionState.solution ? solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex] : null;
    const solutionParcels = cargoSpace?.packedParcels || [];
    const loadingPlanFinished = cargoSpace?.packingStatus === solutionPackingStatus.packed;
    const packed = cargoSpace ? solutionParcels.filter(x => x.loaded).length : 0
    const progressValue = cargoSpace ? Math.round((packed / solutionParcels.length) * 100) : 0

    let loadingPlanBeingReOptimized, nextPackableBoxCardsVisible, selectedParcelCardVisible, lmcInProgressThisUser, lmcInProgressOtherUser;
    if (cargoSpace) {
        loadingPlanBeingReOptimized = [lmcStatus.recalculating, lmcStatus.ready, lmcStatus.locked].includes(cargoSpace.lastMinuteChange?.status);
        let isThisUserLMC = cargoSpace.lastMinuteChange?.user?.toString() === reduxState.user._id.toString();
        lmcInProgressThisUser = cargoSpace.lastMinuteChange?.status === lmcStatus.recalculating && isThisUserLMC;
        lmcInProgressOtherUser = loadingPlanSelected && loadingPlanBeingReOptimized && !isThisUserLMC;

        nextPackableBoxCardsVisible = !loadingPlanBeingReOptimized && !loadingPlanFinished && loadingPlanSelected && !Boolean(parcelState.selectedParcelToPack) && !editModeState
        selectedParcelCardVisible = !nextPackableBoxCardsVisible && !parcelTransformControlsState.open && (editModeState || (loadingPlanFinished && cargoSpace.progress < 100));
    }



    // Put solutionState.solution into useRef for all listeners
    let solutionRef = useRef(null);
    solutionRef.current = solutionState.solution;


    // Put loadingPlanSelected into useRef for optimization-service error listener
    let loadingPlanSelectedRef = useRef(null);
    loadingPlanSelectedRef.current = loadingPlanSelected;


    // Put parcelState.previewParcel into useRef for assign-user patch listener
    let previewParcelRef = useRef(null);
    previewParcelRef.current = parcelState.previewParcel;

    // Put parcelState.selectedParcelToPack into useRef for assign-user patch listener
    let selectedParcelToPackRef = useRef(null);
    selectedParcelToPackRef.current = parcelState.selectedParcelToPack;


    // Put currentClickedObjects into useRef for 3D-object click functions
    let currentManipulatedParcel = useRef(null);
    currentManipulatedParcel.current = manipulatedObjectState.manipulatedParcel;

    // Put editModeState into useRef for parcel/arrow click handlers
    let currentEditModeState = useRef(null);
    currentEditModeState.current = editModeState;

    let currentParcelTransformControlsState = useRef(null);
    currentParcelTransformControlsState.current = parcelTransformControlsState



    // Set up a special useCancellablePromise hook for the patch listener.
    // The patch listener should stop the api call when the component unmounts and this hook does that.
    const [cancellablePromise] = useCancellablePromise();



    // Using the 'useCallback' returns always the same function as long as the dependencies doesn't change
    // According to React docs: "React guarantees that setState function identity is stable and won't change on re-renders. This is why it's safe to omit from the useEffect or useCallback dependency list."
    //      --> all needed dependencies for the functions below are React state setter functions which can be omitted
    const navigateBackToLoadingAssistantHome = useCallback(() => browserHistory.push('/loadingAssistant'), [])
    const setPreviewParcel = useCallback(parcel => setParcelState(s => ({ ...s, previewParcel: parcel })), []);


    const cancelLmcCallback = useCallback(() => {
        //TODO: Proper error handling instead of console.error()
        // console.log('setting arrows off')
        LMCapi.cancel(solutionState.solution._id, solutionState.solution.cargoSpaceIndex).catch(console.error);
    }, [solutionState.solution])


    const confirmLmcCallback = useCallback(() => {

        // Get the selectedArrangements from each newCargoSpace in cargoSpace.lmc.newCargoSpaces
        let selectedArrangements = {};
        const lmc = solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].lastMinuteChange;
        if (lmc && lmc.newCargoSpaces && lmc.newCargoSpaces.length > 0) {
            lmc.newCargoSpaces.forEach(newCs => {
                Object.assign(selectedArrangements, { [newCs.cargoSpaceId]: newCs.selectedArrangement });
            })
        };

        //TODO: Proper error handling instead of console.error()
        LMCapi.confirm(solutionState.solution._id, solutionState.solution.cargoSpaceIndex, selectedArrangements).catch(console.error);
    }, [solutionState.solution])



    /**
     * If user refreshes the loading operations view while working on a loading job, the page should automatically
     * go back to the cards. When user enters a loading job where they already have an assignment,
     * then there's no previewParcel selected.
     * We need to select a previewParcel for user immediately when user enters the page, IF loadingPlanSelected
     * is true. This is going to be done only once when solutionState.solution is set for the first time.
     * loadingPlanSelected is going to be true when the component mounts, if user already has an
     * assignment in the solution.
     */
    useEffect(() => {

        if (solutionState.solution == null) {
            return;
        }

        // Get the LMC-object from cargo space
        const lmc = solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].lastMinuteChange;

        // Get all assignments from the current solution
        const allAssignments = solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].assignments

        // Find users assignment from allAssignments
        const usersNewAssignment = allAssignments.find(a => a.user._id === reduxState.user._id)

        // Get the names of all workers working on this loading job
        const currentWorkersNames = allAssignments.map(a => a.user.name === reduxState.user.name ? `${a.user.name} (you)` : a.user.name);

        // Initialize default previewParcel and selectedParcelToPack
        let previewParcel = null;
        let selectedParcel = null;

        // Initialize default manipulatedSolution and manipulatedParcel
        let manipulatedParcel = manipulatedObjectState.manipulatedParcel;

        // Find all parcels that have been selected in this loading job
        const allSelectedParcels = allAssignments.map(a => ({ id: a.parcelSelectedForPacking, user: a.user.name }));

        /**
         * If user doesn't have an assignment when coming into the loading job, or if the assignments gets removed,
         * we must make sure that no LMC-controls are being showed for the user.
         */
        if (usersNewAssignment == null) {

            // If users assignment is removed due to inactivity, open dialog where user can select to continue job.
            if (
                loadingPlanSelected &&
                solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].packingStatus !== solutionPackingStatus.packed
            ) { // If user is inside the loading job when we detect no more assignment
                setMiscDialogState(s => ({ ...s, continueTimedOutLoadingJobDialogOpen: true }))
            }

            enableParcelManipulations(null); // Remove selected parcel from manipulations. This removes the arrows and closes the parcelTransformDialog.
            setParcelTransformControlsState(s => ({ ...s, open: false })) // Close dimension transform card
            setEditModeState(false); // Set edit-mode to false. Edit-mode shouldn't be on when user is inspecting a loading job
            setLmcDialogState({ box: null, open: false }); // Close LMC-dialog.
            setLoadingPlanSelected(false); // Take user back to inspecting the loading job.
            manipulatedParcel = null;
        }


        if (usersNewAssignment != null) {

            setLoadingPlanSelected(true)

            // By default set previewParcel to the first available parcel
            previewParcel = solutionState.solution.nextPackableParcels[0]

            // If user had a parcel in LMC, then set previewParcel to that parcel
            if (
                lmc?.parcelInEditing != null &&
                lmc?.user === reduxState.user._id
            ) {
                previewParcel = lmc?.parcelInEditing;
            };

            // If user had already selected a parcel for packing, then set it to previewParcel and selectedParcel
            if (usersNewAssignment.parcelSelectedForPacking != null) {
                selectedParcel = solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].packedParcels.find(p => p._id === usersNewAssignment.parcelSelectedForPacking);
                previewParcel = selectedParcel;
            };

            // If other user(s) in the loading job had selected parcels for packing, then set previewParcel to the first available parcel
            if (allSelectedParcels.length > 0 && usersNewAssignment.parcelSelectedForPacking == null) {
                if (allSelectedParcels.length > 0) {
                    const selectedParcelsIds = allSelectedParcels.map(sp => sp.id);
                    const filteredNextPackableParcels = solutionState.solution.nextPackableParcels.filter(nextAvailableParcel => !(selectedParcelsIds.includes(nextAvailableParcel._id)));
                    previewParcel = filteredNextPackableParcels[0];
                }
            };


            /**
             * If cargospace.lastMinuteChange.status is 'preparing', then set the page to editMode, or show the LMC dialog depending on the situation.
             * If LMC.parcelInEditing is null => edit-mode on, no selected parcel.
             * If LMC.parcelInEditing not null and parcel is loaded => edit-mode on, select parcel.
             * If LMC.parcelInEditing not null and parcel not loaded => open LMC-dialog with selected parcel.
             */
            if (
                lmc != null &&
                lmc.user === reduxState.user._id
            ) {

                /**
                 * LMC is off.
                 * Set all LMC controls to off.
                 */
                if (lmc.status === lmcStatus.none) {
                    setParcelTransformControlsState(s => ({ ...s, open: false }))
                    setEditModeState(false);
                    setLmcDialogState({ box: null, open: false });
                    manipulatedParcel = null;
                }

                /**
                 * LMC has been initiated
                 */
                if (lmc.status === lmcStatus.preparing) {

                    /**
                     * If lmc doesn't have a parcel, then open up edit-mode without
                     * any parcels in manipulation.
                     */
                    if (lmc.parcelInEditing == null) {
                        setEditModeState(true);
                        previewParcel = null;
                    };

                    /**
                     * If lmc has a parcel that is being edited.
                     * Open edit-mode or LMC-dialog depending on the parcel.loaded-field.
                     * Use lmc.parcelInEditing to get the original information of the parcel. This resets the LMC-controls
                     * after a refresh.
                     */
                    if (lmc.parcelInEditing != null) {
                        const parcel = cargoSpace.packedParcels.find(p => p._id === lmc.parcelInEditing.originalId);

                        /**
                         * If lmc.parcelInEditing is loaded, open edit-mode and set that parcel with manipulation controls
                         */
                        if (parcel != null) {
                            if (parcel.loaded) {
                                setEditModeState(true);
                                manipulatedParcel = parcel;
                                previewParcel = null;
                            };

                            /**
                             * If lmc.parcelInEditing is not loaded, open up LMC-dialog for that parcel
                             */
                            if (!parcel.loaded) {
                                setLmcDialogState({ box: parcel, open: true });
                                previewParcel = parcel;
                            }
                        };
                    };
                };

                /**
                 * If lmc.status is recalculating/ready/locked.
                 * Set manipulatedParcel to parcelInEditing so that the LMCSelectedParcelCard keeps being displayed on the screen
                 * even when the re-calculation overlay is on.
                 * This also returns the page to the right state if user refreshes the page while recalculation in in progress.
                 */
                if (lmc.status > lmcStatus.preparing) {
                    previewParcel = null;
                    setLmcDialogState({ box: null, open: false });

                    if (lmc.parcelInEditing != null && manipulatedParcel == null) {
                        manipulatedParcel = lmc.parcelInEditing;
                    }

                    if (lmc.parcelInEditing != null && lmc.parcelInEditing.loaded) {
                        setEditModeState(true);
                    };
                };
            }


            /**
             * For other users in the loading job, take previewParcel away from them
             * to make the UI work in a more sensible way.
             * The parcel user had highlighted might not even exist after the LMC is done,
             * so not highlighting any of them makes sense.
             */
            if (
                lmc != null &&
                lmc.user !== reduxState.user._id
            ) {

                if (lmc.status === lmcStatus.none) {
                    setParcelTransformControlsState(s => ({ ...s, open: false }))
                    setEditModeState(false);
                    setLmcDialogState({ box: null, open: false });
                    manipulatedParcel = null;
                }

                if (lmc.status > lmcStatus.none) {
                    // If LMC wasn't initiated by the user, then remove previewParcel, because we don't want to have a previewParcel when the cards are locked.
                    previewParcel = null;

                    // If user didn't initiate LMC and LMC goes into locked-state, return user to the default packing state of the loading operations view
                    if (lmc.status === lmcStatus.locked) {
                        setParcelTransformControlsState(s => ({ ...s, open: false }));
                        setEditModeState(false);
                        setLmcDialogState({ box: null, open: false });
                        manipulatedParcel = null;
                    }
                    // NOTE: if there's more lmc.status checks, we should add a warning message / error handling for situations where the lmc.status might be erronous.
                }
            }


            if (lmc == null) {
                setParcelTransformControlsState(s => ({ ...s, open: false }))
                setEditModeState(false);
                setLmcDialogState({ box: null, open: false });
                manipulatedParcel = null;
            }
        };


        /**
         * If loading plan is completed, we want to make sure that previewParcel is set to null.
         * This is required because when user forces loading plan to complete, then the loading plan still has nextPackableParcels that the program tries to highlight.
         */
        if (solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].packingStatus === 3) {
            previewParcel = null;
            selectedParcel = null;
        };


        /**
         * Establish new threeParcelsUpdate.
         * threeParcelsUpdate reconstructs the given parcels.
         * If the changed parcel is a loaded one, we need to insert the parcel into solutions packedParcels-array.
         * This fixes the problem, where loaded parcels dimensions change back to original ones when LMC is recalculating the loading plan
         */
        let parcelUpdate;
        if (manipulatedParcel != null && manipulatedParcel.loaded) {
            parcelUpdate = cloneDeep(solutionState.solution);
            const idx = parcelUpdate.cargoSpaces[parcelUpdate.cargoSpaceIndex].packedParcels.findIndex(p => p._id === manipulatedParcel._id);
            parcelUpdate.cargoSpaces[parcelUpdate.cargoSpaceIndex].packedParcels[idx] = manipulatedParcel
        } else {
            parcelUpdate = solutionState.solution
        }


        setManipulatedObjectState(s => ({
            ...s,
            parcelId: manipulatedParcel?.id,
            manipulatedParcel: manipulatedParcel,
            showArrows: "all",
        }))

        setSolutionState(s => ({
            ...s,
            currentWorkers: currentWorkersNames != null ? currentWorkersNames : []
        }))

        setParcelState(s => ({
            ...s,
            previewParcel: previewParcel,
            selectedParcelToPack: selectedParcel,
            selectedParcels: allSelectedParcels,
            threeParcelsUpdate: parcelUpdate?.cargoSpaces[parcelUpdate.cargoSpaceIndex].packedParcels
        }))


        /**
         * The point of this useEffect is to listen ONLY to changes in solutionState.solution.
         * We don't want to listen to changes in other states, therefor we silence the warning.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [solutionState.solution])



    /**
     * On the initial render, set up the solutions service patch listener.
     * When unmounting this component, clear listener.
     */
    useEffect(() => {
        const solutionPatchedFunc = onSolutionsPatched.current;
        const solutionRemovedFunc = onSolutionsRemoved.current;
        const assignmentPatchedFunc = onAssignmentsPatched.current;
        const optimizeErrorFunc = onOptimizeServiceError.current;

        solutionsApi.service().on('patched', solutionPatchedFunc);
        solutionsApi.service().on('removed', solutionRemovedFunc)
        loadingAssistantApi.assignmentService().on('patched', assignmentPatchedFunc);
        optimizeApi.service().on('error', optimizeErrorFunc);
        return () => {
            solutionsApi.service().removeListener('patched', solutionPatchedFunc);
            solutionsApi.service().removeListener('removed', solutionRemovedFunc)
            loadingAssistantApi.assignmentService().removeListener('patched', assignmentPatchedFunc);
            optimizeApi.service().removeListener('error', optimizeErrorFunc)
        }
    }, [])



    /**
     * Set up a setRouteLeaveHook, that is fired when user tries to navigate away from /loadingAssistant/loading/:id/:cargospaceindex
     */
    useEffect(() => {
        props.router.setRouteLeaveHook(props.route, (nextLocation) => {

            // This check is made to check if user tries to use browsers own 'go back' functionality. If this is the case, go forward to ensure that the URL stays the same.
            const currentLocation = props.router.getCurrentLocation();
            const location = props.router.location;

            // If user tries to use browsers own 'go back'-navigation the URL will change to /loadingAssistant, which messes things up if user then decides to refresh the page.
            // Handle this by using browserHistory.goForward() to get the URL to change back to /loadingAssistant/loading/:id/:cargoSpaceIndex
            if (currentLocation.pathname !== location.pathname && loadingPlanSelected) {
                browserHistory.goForward();
            }

            const usersAssignment = solutionState.solution?.cargoSpaces[solutionState.solution.cargoSpaceIndex].assignments.find(a => a.user._id === reduxState.user._id)

            // In all of these cases where we don't want to show the cancelLoadingJobDialog, remove users existing assignment and then continue navigation
            if (
                miscDialogState.cancelLoadingJobDialogOpen ||
                miscDialogState.continueTimedOutLoadingJobDialogOpen ||
                !loadingPlanSelected ||
                solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].packingStatus === solutionPackingStatus.packed ||
                usersAssignment == null
            ) {
                return true;
            }


            // If user pressed the X-button after pressing the 'continue'-button, show the cancelLoadingJobDialog
            setMiscDialogState(s => ({ ...s, closingPage: true, cancelLoadingJobDialogOpen: true }));
            return false; // If user presses cancel on the dialog, don't leave the page

        })
    }, [
        props.router,
        props.route,
        miscDialogState.cancelLoadingJobDialogOpen,
        loadingPlanSelected,
        solutionState.solution,
        miscDialogState.continueTimedOutLoadingJobDialogOpen,
        reduxState.user._id
    ])


    /**
     * Get the loadingAssistant and populate the appropriate cargo spaces assignments with assignments fetched from /loading-assistant/assign-user -service.
     * This is done because loadingAssistant doesn't by default have assignment user-information populated, and we need it to be populated
     * @param {String} id
     * @param {Number} cargoSpaceIndex
     * @returns
     */
    const fetchLoadingAssistant = useCallback(async function (id, cargoSpaceIndex) {

        let populatedLoadingAssistant;

        // Use cancellablePromise to ensure that the .get-call is stopped when the component unmounts
        await cancellablePromise(
            loadingAssistantApi.get(id, cargoSpaceIndex)

                // In each loadingAssistant, the assignments don't have user information populated, so we need to fetch all assignments from /assign-user -service, and replace each assignment in each loadingAssistant with a fetched assignment
                .then(res => {

                    const currentLoadingAssistant = res;

                    // Get all assignment from currentLoadingAssistant
                    const allAssignmentsInLoadingAssistant = currentLoadingAssistant.cargoSpaces[currentLoadingAssistant.cargoSpaceIndex].assignments;

                    // Map out all assignment ids
                    const assignmentIds = allAssignmentsInLoadingAssistant.map(assig => assig._id)

                    // Return _ids of all assignments and the loadingAssistant
                    return { assignmentIds, currentLoadingAssistant };
                })
                .then(async (res) => {

                    // Take the assignment _id's and fetch the assignments from /loading-assistant/assign-user service
                    const assignments = await findAssignments(res.assignmentIds)

                    return { assignments, currentLoadingAssistant: res.currentLoadingAssistant }
                })
                .then(res => {

                    // For each assignment, find the old assignment and update it with the new one
                    res.assignments.forEach(assig => {
                        // In loadingAssistant, find the assignment we want to update
                        const assignmentToUpdate = res.currentLoadingAssistant.cargoSpaces[res.currentLoadingAssistant.cargoSpaceIndex].assignments.findIndex(a => a._id === assig._id);
                        // Update the assignment with assig
                        res.currentLoadingAssistant.cargoSpaces[res.currentLoadingAssistant.cargoSpaceIndex].assignments[assignmentToUpdate] = assig;
                    })

                    return res.currentLoadingAssistant;
                })
                .then(res => {
                    populatedLoadingAssistant = res;
                })
                .catch(() => {
                    navigateBackToLoadingAssistantHome();
                    reduxDispatch.onShowNotificationDialog("Loading job not found", 'Error', notificationDialogSeverity.error);
                })
        );
        return populatedLoadingAssistant;

        // Putting cancellablePromise into dependencies results in cancellablepromise being fired repeatedly, but eslinter gives a warning when it's not in dependencies and when removing all dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateBackToLoadingAssistantHome, reduxDispatch]);


    /**
     * Fetch the current solution from loadingAssistantsApi.
     */
    useEffect(() => {
        const { id, cargoSpaceIndex } = props.params;
        if (!id || !cargoSpaceIndex)
            return;

        fetchLoadingAssistant(id, cargoSpaceIndex)
            .then(res => setSolutionState(s => ({ ...s, solution: res })))
    }, [props.params, fetchLoadingAssistant, reduxDispatch])


    /**
     * Fetch all users only once
     */
    useEffect(() => {
        users.query()
            .then(res => setAllUsers(res.data))
            .catch(console.error);
    }, []);


    /**
     * Fetch the assignments with the given array of assignment._id's
     * @param {Array} ids
     * @returns an array of assignment-objects
     */
    const findAssignments = async function (ids) {
        let listOfAssignments = [];
        for (let i = 0; i < ids.length; i++) {
            const assigs = await loadingAssistantApi.findAssignment({ query: { _id: ids[i] } });
            assigs.data.forEach(a => listOfAssignments.push(a));
        }
        return listOfAssignments;
    }




    /**
     * Handle the event when users selects a parcel for loading.
     * When user selects a parcel, patch the current assignment to include the selected parcels _id so we know which user has selected which parcels.
     * Selected parcels should be locked from other users, so multiple users cannot select the same parcel.
     * // TODO: Rethink the implementation, when backend refactoring (issue #589) is done.
     * @param {Object} parcel
     */
    const handleSelectingParcelToPacking = async (parcel) => {

        // Get users current assignment
        const usersCurrentAssignment = solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].assignments.find(a => a.user._id === reduxState.user._id);

        // Parcel is null when de-selecting a parcel
        const parcelId = (parcel == null) ? null : parcel._id

        // Patch users assignments parcelSelectedForPacking-field
        await loadingAssistantApi.patchAssignment(usersCurrentAssignment._id, { parcelSelectedForPacking: parcelId }, {});

        setParcelState(s => ({ ...s, selectedParcelToPack: parcel }));
    }



    /**
     * Handle the event when user presses the edit-icon on a card.
     * The parcel should be selected and selected parcels inEdit-field should be turned to true.
     * // TODO: Rethink this after backend refactoring has been done (issue #589).
     */
    const handleLastMinuteChangeModalOpen = async (parcel) => {

        if (!solutionState.solution) {
            return;
        }

        // Get the parcel _id. parcel is null when de-selecting a parcel
        const parcelId = parcel == null ? null : parcel._id;
        if (parcelId != null) {
            try {
                await LMCapi.initializeLMCObject(solutionState.solution, { parcelId: parcel._id })
            } catch (error) {
                reduxDispatch.onShowNotificationDialog(error.message, 'Error', notificationDialogSeverity.error);
                console.error("Error at opening handleLastMinuteChangeModalOpen(): ", error);
            }
        } else {
            try {
                await LMCapi.cancel(solutionState.solution._id, solutionState.solution.cargoSpaceIndex)
            } catch (error) {
                reduxDispatch.onShowNotificationDialog(error.message, 'Error', notificationDialogSeverity.error);
                console.error("Error at closing handleLastMinuteChangeModalOpen(): ", error);
            }
        }
    }


    /**
     * Listener for 'patched' emits from solutions service.
     * First check if patchedSolution is the current solution we're working on. We don't want to listen to patches on other solutions.
     * Then we have to use fetchLoadingAssistnat()-function to get the up-to-date loadingAssistant.
     * We need to use the function because otherwise some of the more nested fields in the object, like assignments, wouldn't be populated.
     */
    const onSolutionsPatched = useRef(async function (patchedSolution) {

        // If current solution doesn't exists skip checking the patch message we heard
        if (solutionRef.current == null || patchedSolution == null) {
            return;
        };

        // No need to handle patch-emits for other solutions
        if (solutionRef.current._id !== patchedSolution._id) {
            return;
        };

        // Fetch up to date solution using loadingAssistantsApi
        const upToDateSolution = await fetchLoadingAssistant(patchedSolution._id, solutionRef.current.cargoSpaceIndex);

        /**
         * Update solution if current cargo space was changed.
         * This makes sure that other cargo spaces aren't affected by actions that aren't supposed to affect them, like packing parcels, or single cargo space LMC, etc.
         */
        if (
            !(isEqual(solutionRef.current.cargoSpaces[solutionRef.current.cargoSpaceIndex], upToDateSolution.cargoSpaces[upToDateSolution.cargoSpaceIndex]))
        ) {
            setSolutionState(s => ({ ...s, solution: upToDateSolution }));
        }

    })



    /**
     * Handler for solution remove events.
     * If the solution user is working in gets removed, then:
     * 1. Set loadingPlanSelected as false so that when user is navigated back to tablet-home, the cancelLoadingJobDialog isn't shown.
     * 2. Show notification dialog that tells user what happened.
     * 3. Automatically navigate user to tablet home -page.
     */
    const onSolutionsRemoved = useRef(async function (removedSolution) {

        // Only listen to current solution getting removed, all other remove-emits can be ignored.
        if (removedSolution == null || solutionRef.current == null) {
            return;
        };

        if (removedSolution._id === solutionRef.current._id) {
            setLoadingPlanSelected(false);
            reduxDispatch.onShowNotificationDialog(
                'The loading plan you have been working on has been deleted by another user.',
                'Loading plan deleted',
                notificationDialogSeverity.warning
            )
            navigateBackToLoadingAssistantHome();
        }
    });




    /**
     * Listener for 'patched' emits from assign user service.
     * Listen to all assignment patches in the current loading job, even assignments other than users own.
     */
    const onAssignmentsPatched = useRef(async function (patchedAssignment) {

        // Get users current assignment from cargo space
        const usersCurrentAssignment = solutionRef.current.cargoSpaces[solutionRef.current.cargoSpaceIndex].assignments.find(a => a.user._id === reduxState.user._id);

        // If the users current assignment or the patched assignment is null/undefined/etc. skip execution
        if (
            usersCurrentAssignment == null ||
            patchedAssignment == null
        ) {
            return;
        }

        // Don't listen to patch-emits for other solutions/cargo spaces
        if (
            patchedAssignment.solutionId !== usersCurrentAssignment.solutionId ||
            patchedAssignment.cargoSpaceIndex !== usersCurrentAssignment.cargoSpaceIndex
        ) {
            return;
        };

        // Find all assignments from this cargo space and map selected parcels from each assignment to selectedParcels-array state
        const allAssignments = await loadingAssistantApi.findAssignment({ query: { solutionId: solutionRef.current._id, cargoSpaceIndex: solutionRef.current.cargoSpaceIndex } });
        const usersNewAssignment = allAssignments.data.find(a => a.user._id === reduxState.user._id)
        const selectedParcelsInThisSolution = allAssignments.data.map(a => ({ id: a.parcelSelectedForPacking, user: a.user.name }));
        const selectedParcelsIdsInThisSolution = selectedParcelsInThisSolution.filter(p => p.id != null).map(p => p.id) // Map out parcels selected for packing, then filter out all nulls/undefined/etc.

        // If user has a card highlighted, then change the highlighted card if someone selects that particular parcel for loading
        let newPreviewParcel = previewParcelRef.current;
        if (previewParcelRef.current != null && usersNewAssignment.parcelSelectedForPacking === null) {
            if (selectedParcelsIdsInThisSolution.includes(previewParcelRef.current._id.toString())) {
                const filteredParcelsInThisSolution = solutionRef.current.nextPackableParcels.filter(p => !(selectedParcelsIdsInThisSolution.includes(p._id.toString())))
                newPreviewParcel = filteredParcelsInThisSolution[0];
            }
        }

        // If there's only 1 available parcel left and no-one has selected it yet, set previewParcel to that parcel
        if (
            solutionRef.current.nextPackableParcels.length === 1 &&
            selectedParcelsIdsInThisSolution.length === 0
        ) {
            newPreviewParcel = solutionRef.current.nextPackableParcels[0];
        }

        // If all available parcels have been selected for packing, set previewParcel to null
        if (
            solutionRef.current.nextPackableParcels.length === 1 &&
            selectedParcelsIdsInThisSolution.length === 1 &&
            selectedParcelsIdsInThisSolution[0] === solutionRef.current.nextPackableParcels[0]._id
        ) {
            newPreviewParcel = null;
        }

        // Re-set parcelState with the new information
        setParcelState(s => ({ ...s, selectedParcels: selectedParcelsInThisSolution, previewParcel: newPreviewParcel }))
    })



    /**
     * Listener for optimize-service error emits.
     * If user is recalculating the loading plan via LMC, and the connection to optimization-service is lost,
     * show an error message using notificationDialog
     * Show this error message only to the user who initiated the LMC.
     */
    const onOptimizeServiceError = useRef(async function (optimizerError) {
        // Make sure that solution exists, and user is actually in the loading job
        if (
            solutionRef.current === null ||
            !loadingPlanSelectedRef.current
        ) {
            return;
        };

        // LMC-object has to exist.
        // Only show error message to the user who initiated the LMC
        const lmc = solutionRef.current.cargoSpaces[solutionRef.current.cargoSpaceIndex].lastMinuteChange;
        if (
            lmc == null ||
            lmc.user !== reduxState.user._id
        ) {
            return;
        };

        // If connection to optimize-service is cut in the middle of recalculating the LMC, cancel LMC and show an error-message
        if (lmc.status === lmcStatus.recalculating) {
            reduxDispatch.onShowNotificationDialog('Connection to BOXBOT optimization service was lost.', 'Connection lost', notificationDialogSeverity.error)
            try {
                LMCapi.cancel(solutionRef.current._id, solutionRef.current.cargoSpaceIndex);
            } catch (error) {
                reduxDispatch.onShowNotificationDialog(error.message, 'Error', notificationDialogSeverity.error);
            }
        };
    })



    /**
     * // TODO: This function does work, but the general idea of the function isn't reflected in the current implementation.
     * We can change this function in either of two ways:
     * 1. Parcels loaded info should be a boolean, and this function uses setParcelLoaded() with a given boolean value.
     * 2. Parcels loaded info could be an object that includes a loaded-field that has a packedState 0-2 and a user-field that includes the user who did the change.
     * For now this function will stay as is, because it works.
     */
    const updatePackingBox = async (newPackingStatus) => {
        if (!parcelState.selectedParcelToPack && !parcelState.previewParcel)
            return;

        const id = parcelState.selectedParcelToPack?.id || parcelState.previewParcel.id;
        const boxAtm = solutionParcels.find(x => x.id === id)
        if (!boxAtm) {
            return;
        }

        if (newPackingStatus !== parcelPackingStatus.packed) {
            handleSelectingParcelToPacking(null);
            return;
        }

        if (newPackingStatus === parcelPackingStatus.packed) {
            boxAtm.loaded = true;
            setSolutionState(s => ({ ...s, dbUpdateInProgress: true }))

            setTimeout(() => {
                // Always scroll the boxChooserDiv to leftmost position after parcel is loaded
                const el = document.getElementById("boxChooserDiv")
                if (el) el.scroll({ left: 0 })
            }, 100)

            try {
                let sol = await loadingAssistantApi.setParcelLoaded(solutionState.solution._id, { loaded: true }, { parcelId: boxAtm._id, cargoSpaceIndex: solutionState.solution.cargoSpaceIndex })

                /**
                 * Call handleSeletingParcelToPacking(null) if cargo space hasn't been packed yet.
                 * All assignments get removed automatically when cargo space has finished packing, so handleSelectingParcelToPacking() would
                 * try to patch an assignment that doesn't exist anymore.
                 */
                if (sol.cargoSpaces[sol.cargoSpaceIndex].packingStatus !== solutionPackingStatus.packed) {
                    handleSelectingParcelToPacking(null);
                }

                const cp = sol.cargoSpaces[sol.cargoSpaceIndex];

                const selectedParcels = parcelState.selectedParcels.filter(p => p.id != null).map(p => p.id);
                const filteredNextPackableParcels = sol.nextPackableParcels.filter(p => !(selectedParcels.includes(p._id))); // Filter out all the parcels selected for packing from nextPackableParcels-array
                const previewParcel = cp.packingStatus === solutionPackingStatus.packed ? null : filteredNextPackableParcels[0];

                // Use fetchLoadingAssistant()-function to get the solution with populated assignments
                const populatedSol = await fetchLoadingAssistant(sol._id, sol.cargoSpaceIndex)

                setSolutionState(s => ({ ...s, solution: populatedSol, dbUpdateInProgress: false }));
                setParcelState(s => ({ ...s, previewParcel: previewParcel }))

            } catch (error) {
                reduxDispatch.onShowNotificationDialog(error.message, 'Error', notificationDialogSeverity.error);
                console.error("Error updating: ", error)
            }
        }
    }


    /**
     * Handler function for unloading a selected parcel.
     * This function is passed to LMCSelectedParcelCard.
     *
     * Take parcel in manipulatedObjectState.manipulatedParcel and use loadingAssistantApi.setParcelLoaded() -function to
     * set parcels loaded-field from true to false.
     */
    const unloadSelectedParcel = async () => {

        if (manipulatedObjectState.manipulatedParcel == null) {
            return;
        };

        try {
            await LMCapi.cancel(solutionState.solution._id, solutionState.solution.cargoSpaceIndex)
            await loadingAssistantApi.setParcelLoaded(solutionState.solution._id, { loaded: false }, { parcelId: manipulatedObjectState.manipulatedParcel._id, cargoSpaceIndex: solutionState.solution.cargoSpaceIndex })
        } catch (error) {
            if (error.name === 'UnloadingBlockedError') {
                reduxDispatch.onShowNotificationDialog(
                    `Unloading of this cargo item is blocked by other cargo items: \r\n - ${error.data.join('\r\n - ')}`,
                    'This cargo item cannot be unloaded',
                    notificationDialogSeverity.warning
                );
            } else {
                reduxDispatch.onShowNotificationDialog(
                    error.message,
                    'Error',
                    notificationDialogSeverity.warning
                );
                console.error("Error updating: ", error);
            }
        }
    }


    const onLMCJobReceived = (res) => {
        if (!res)
            return;

        if (res.errorMessage || res.errorCode) {
            let message = res.errorMessage || "Unknown error - no error message received.";
            reduxDispatch.onShowNotificationDialog(
                message,
                'Error',
                notificationDialogSeverity.error
            );
            return;
        }

        if (!res.id) {
            reduxDispatch.onShowNotificationDialog(
                "Unknown error when starting a new optimize job - no job id received",
                'Error',
                notificationDialogSeverity.error
            )
            return;
        }
    }


    /**
     * Delete box from cargo space.
     * After removing the parcel, cargo space is recalculated using LMC-single.
     */
    const onDeleteBox = () => {
        if (lmcDialogState.box === null || !solutionState.solution)
            return;

        // Check and DON'T SEND the last parcel to backend service - deny it !!!
        if (solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].packedParcels.length === 1) {
            setLMCNotAllowedOpen(true);
            return;
        }

        checkAuth(() => {
            users.authenticate();
            LMCapi.remove(lmcDialogState.box._id, solutionState.solution)
                .then(onLMCJobReceived)
                .catch(err => reduxDispatch.onShowNotificationDialog(
                    err.message,
                    'Error',
                    notificationDialogSeverity.error
                ))
        })
    }


    /**
     * Apply LMC to a selected parcel
     * @param {Object} editedBox - Edited box/parcel
     * @param {String} lmcStrategy - Which lmc strategy to use ('RestrictToSingleCargoSpace' | 'DistributeFreely')
     * @returns
     */
    const onBoxUpdate = (editedBox, lmcStrategy = lmcStrategies.restrictToSingle) => {
        if (!solutionState.solution)
            return;

        // Validate lmcStrategy
        if (!(lmcStrategy === lmcStrategies.restrictToSingle || lmcStrategy === lmcStrategies.distributeFreely))
            throw new Error(`Unsupported lmcStrategy: ${lmcStrategy}`);

        setManipulatedObjectState(s => ({ ...s, manipulatedParcel: editedBox }));

        // lmc.parcelInEditing has 'originalId'-field that refers to the parcels original _id, if editedBox has that field, use it instead of editedBox._id.
        const boxId = editedBox.originalId ? editedBox.originalId : editedBox._id;

        // Make sure user is authenticated
        checkAuth(() => {
            // Update the jwt expiry date
            // TODO Are both of these really needed?
            users.authenticate();
            LMCapi.patch(boxId, editedBox, solutionState.solution, lmcStrategy)
                .then(onLMCJobReceived)
                .catch(err => reduxDispatch.onShowNotificationDialog(err.message, 'Error', notificationDialogSeverity.error));
        })
    }


    /**
     * Receive information from SceneManager that a parcel has been clicked.
     * Take the parcelId, set it to manipulatedParcelId, and pass it to Threer, which calls threeEntryPoint to add the arrows to the parcel.
     * This function can also be called from inside this file, if we know the parcels parcelId.
     */
    async function enableParcelManipulations(id) {

        // Don't allow parcel clicks when edit mode is false, or when parcelTransformControls are open
        if (!currentEditModeState.current || currentParcelTransformControlsState.current.open) {
            return;
        }

        // Find the parcel that has been selected and set it to manipulatedObjectState.manipulatedParcel. This info is passed down to LMCParcelTransformVisualizationWrapper.
        const allParcels = solutionRef.current.cargoSpaces[solutionRef.current.cargoSpaceIndex].packedParcels;
        const selectedParcel = allParcels.find(parcel => parcel.id === id);

        // If id is null, then set manipulatedObjectState.parcelId and -.manipulatedParcel both to null
        if (id == null) {
            // Update cargo spaces LMC.parcelInEditing-field with an empty object. This will turn LMC.parcelInEditing to null.
            setParcelTransformControlsState(s => ({ ...s, open: false, direction: undefined }));
            setManipulatedObjectState(s => ({ ...s, manipulatedParcel: null, showArrows: null }));
            await LMCapi.selectParcelInEditing(solutionRef.current, {});
            return;
        };

        // Update cargo spaces LMC.parcelInEditing-field with clicked parcels _id.
        await LMCapi.selectParcelInEditing(solutionRef.current, selectedParcel)
        setManipulatedObjectState(s => ({ ...s, manipulatedParcel: selectedParcel, showArrows: "all" }))
    };


    /**
     * Receive information from SceneManager that an arrow has been clicked and open a dialog to transform the parcel that the arrows belong to.
     * SceneManager only sends the direction information of the arrow. The parcel id is got from manipulatedParcelId.
     * SceneManager doesn't need to send the parcelId, because we already have the id saved from when the parcel was clicked.
     */
    const openParcelTransformControls = (direction) => {

        // Don't allow arrow clicks when manipulatedParcelId null, or edit mode is false
        if (currentManipulatedParcel.current == null || !currentEditModeState.current || solutionRef.current == null) {
            return;
        }

        // Open transformControlsDialog with the appropriate information and reset the dialog information
        setParcelTransformControlsState(s => ({
            ...s,
            open: true,
            direction: direction
        }));

        setManipulatedObjectState(s => ({
            ...s,
            showArrows: direction
        }))
    }


    /**
     * This function handles the edit mode -button click event.
     * NOTE: parcel cards don't need to be set to hidden here, because nextPackableBoxCardsVisible already has a conditional that hides the cards when editModeState is true.
     */
    async function handleEditModeButtonClick() {
        // Set up LMC-object to cargo space
        try {
            if (!editModeState) { // Turn on edit-mode
                await LMCapi.initializeLMCObject(solutionState.solution, { parcelId: null })
            } else if (editModeState) { // Turn off edit-mode
                enableParcelManipulations(null); // Set clicked parcel to null to dispose any existing arrows
                await LMCapi.cancel(solutionState.solution._id, solutionState.solution.cargoSpaceIndex);
            }
        } catch (error) {
            reduxDispatch.onShowNotificationDialog(error.message, 'Error', notificationDialogSeverity.error);
            console.error("Error at handleEditModeButtonClick(): ", error);
        }
    };


    /**
     * Handler function for forcing completed status for cargo space.
     * Take a clone of the current solution, set cargo spaces packingStatus to solutionPackingStatus.packed,
     * and then update the solution with the new packing status.
     */
    async function handleForceLoadingAssistantCompletion() {

        // Patch new information to the solution
        await LMCapi.forceCompletion(solutionState.solution);

        // Make sure to turn off editmode when forcing completion
        enableParcelManipulations(null);
        setEditModeState(false);

        // Close Force Completion -dialog
        setMiscDialogState(s => ({ ...s, forceCompletionDialogOpen: false }))

    };


    /**
     * Handler function passed to ContinueTimedOutLoadingJobDialog.
     * When users assignment in the current loading job has timed out, user is presented with a dialog where
     * user can choose to continue the timed out loading job.
     * This function makes a new assignment for user to the current loading job.
     */
    const continueTimedOutLoadingJob = async () => {
        setSolutionState(s => ({ ...s, dbUpdateInProgress: true }));
        const newState = { dbUpdateInProgress: false };
        try {
            // User doesn't have active assignment for this loading job anymore --> create one
            const assignment = { solutionId: solutionState.solution._id, cargoSpaceIndex: solutionState.solution.cargoSpaceIndex }
            const assig = await loadingAssistantApi.assignUser(assignment, {});
            newState.currentAssignment = assig;
        } catch (error) {
            setErrorDialogState({ errorDialogOpen: true, errorMessage: error.message, errorTitle: error.name });
        };
        setSolutionState(s => ({ ...s, ...newState }));
        setMiscDialogState(s => ({ ...s, cancelLoadingJobDialogOpen: false, continueTimedOutLoadingJobDialogOpen: false }));
    }


    /**
     * Handler function passed for JoinLoadingJobDialog.
     * This function is called when user selects to join a loading job that already has someone working on it.
     */
    const joinLoadingJob = async () => {

        // Set the databaseUpdateState
        setSolutionState(s => ({ ...s, dbUpdateInProgress: true }))

        const newState = {
            dbUpdateInProgress: false,
        };

        try {
            // Assign current user to the selected cargo space
            const assignment = {
                solutionId: solutionState.solution._id,
                cargoSpaceIndex: solutionState.solution.cargoSpaceIndex,
            }
            const assig = await loadingAssistantApi.assignUser(assignment, {});
            newState.currentAssignment = assig;
        } catch (error) {
            setErrorDialogState({ errorDialogOpen: true, errorMessage: error.message, errorTitle: error.name })
        };

        setSolutionState(s => ({ ...s, ...newState }));
        setParcelState(s => ({ ...s, previewParcel: solutionState.solution?.nextPackableParcels[0] }))
        setLoadingPlanSelected(true);
        setMiscDialogState(s => ({ ...s, joinLoadingJobDialogOpen: false }));
    };


    /**
     * Handler function passed to CancelLoadingJobDialog.
     * When this function is called, users assignment from current loading job is removed and user is automatically navigated
     * back to tablet home-page.
     */
    const cancelLoadingJob = async () => {
        const usersAssignment = solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].assignments.find(a => a.user._id === reduxState.user._id)
        await loadingAssistantApi.removeAssignment(usersAssignment._id, {});
        navigateBackToLoadingAssistantHome();
    }


    /**
     * Change packing goal for a new cargo space that has been returned from LMC.
     * Find the right cargo space that LMC has returned, and change cargo spaces
     * selectedArrangement info.
     * @param {Object} cargoSpace
     * @param {Number} index index of the new cargo space in cargoSpace.lmc.newCargoSpaces
     * @param {Number} newPackingGoal
     */
    const changeLMCNewCargoSpaceSelectedArrangement = (cargoSpace, index, newPackingGoal) => {

        const cargoSpaceIndex = solutionState.solution?.cargoSpaces.findIndex(cs => cs._id === cargoSpace._id);

        // Take copies of solutionState.solution and the given cargo space
        let solCopy = cloneDeep(solutionState.solution);
        let cs = cloneDeep(cargoSpace);
        let lmc = cs.lastMinuteChange;

        // Get keys from lmc.cargoSpaceChanges and sort them. This makes sure that we get the correct newCargoSpace with index.
        let keys = Object.keys(lmc.cargoSpaceChanges);
        keys = keys.sort();

        // Get the correct newCargoSpace and replace the selectedArrangement-value
        let newCargoSpace = lmc.newCargoSpaces.find(ncs => ncs.cargoSpaceId === keys[index]);
        newCargoSpace.selectedArrangement = newPackingGoal;

        // Insert changed lmc to cargoSpace and cargoSpace to solution.
        lmc.newCargoSpaces[index] = newCargoSpace;
        cs.lastMinuteChange = lmc;
        solCopy.cargoSpaces[cargoSpaceIndex] = cs;

        setSolutionState(s => ({ ...s, solution: solCopy }))
    }


    // ---------- MAIN COMPONENT ----------
    return (
        <div>

            {/* ----- PAGE HEADER ----- */}
            <LoadingAssistantTabletLoadingOperationsViewHeader
                title={solutionState.solution == null ? '' : `${solutionState.solution?.name}, cargo space ${solutionState.solution?.cargoSpaceIndex + 1}/${solutionState.solution?.cargoSpaces.length}`}
                navigateHome={() => navigateBackToLoadingAssistantHome()}
            />

            {/* ----- DIALOGS ----- */}

            {/* Cancel loading job -dialog */}
            <CancelLoadingJobDialog
                open={miscDialogState.cancelLoadingJobDialogOpen}
                onClose={() => setMiscDialogState(s => ({ ...s, cancelLoadingJobDialogOpen: false, closingPage: false }))}
                onLeave={async () => await cancelLoadingJob()}
            />

            {/* Continue loading job that has timed out -dialog */}
            <ContinueTimedOutLoadingJobDialog
                open={miscDialogState.continueTimedOutLoadingJobDialogOpen}
                onContinue={async () => await continueTimedOutLoadingJob()}
                onLeave={() => navigateBackToLoadingAssistantHome()}
                dbUpdateInProgress={solutionState.dbUpdateInProgress}
            />

            {/* Join currently ongoing loading job -dialog */}
            <JoinLoadingJobDialog
                open={miscDialogState.joinLoadingJobDialogOpen}
                onClose={() => setMiscDialogState(s => ({ ...s, joinLoadingJobDialogOpen: false }))}
                joinLoadingJob={async () => await joinLoadingJob()}
                dbUpdateInProgress={solutionState.dbUpdateInProgress}
            />

            {/* Error dialog */}
            <LOVErrorDialog
                open={errorDialogState.errorDialogOpen}
                errorTitle={errorDialogState.errorTitle}
                errorMessage={errorDialogState.errorMessage}
                onClose={() => navigateBackToLoadingAssistantHome()}
            />

            {/* Force loading job completion -dialog */}
            <ForceCompletionDialog
                open={miscDialogState.forceCompletionDialogOpen}
                onClose={() => setMiscDialogState(s => ({ ...s, forceCompletionDialogOpen: false }))}
                onConfirm={() => handleForceLoadingAssistantCompletion()}
            />

            {/* Unpacked parcels LMC -dialog */}
            {lmcDialogState.box &&
                <LoadingAssistantEditModal
                    open={lmcDialogState.open}
                    onClose={() => handleLastMinuteChangeModalOpen(null)}
                    editBox={lmcDialogState.box}
                    solution={solutionState.solution}
                    onBoxUpdate={box => onBoxUpdate(box)}
                    onDeleteBox={() => onDeleteBox()}
                />
            }

            {/* Handler component for LMC result dialogs */}
            <LMCResultHandler
                user={reduxState.user}
                solution={solutionState.solution}
                onRecalculate={() => onBoxUpdate(manipulatedObjectState.manipulatedParcel, lmcStrategies.distributeFreely)}
                confirmCallback={confirmLmcCallback}
                cancelCallback={cancelLmcCallback}
                changeLMCNewCargoSpaceSelectedArrangement={changeLMCNewCargoSpaceSelectedArrangement}
            />

            {/* LMC not allowed -notification dialog */}
            <LMCNotAllowedDialog
                open={LMCNotAllowedOpen}
                onClose={() => setLMCNotAllowedOpen(false)}
                onForceComplete={() => {
                    setLMCNotAllowedOpen(false);
                    handleForceLoadingAssistantCompletion();
                }}
            />

            {/* Weight distribution informatioon -dialog */}
            <WeightDistributionDialog
                cargoSpace={cargoSpace}
                open={weightDialogOpen}
                onClose={() => setWeightDialogOpen(false)}
            />

            {/* Current workers -drawer */}
            <WorkersDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                currentWorkers={solutionState.currentWorkers}
            />

            {/* ----- MAIN PAGE ----- */}
            {solutionState.solution !== null &&
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    {/* Progress bar */}
                    <PackedParcelsProgressBar
                        progressValue={progressValue}
                        packed={packed}
                        cargoSpace={cargoSpace}
                        solutionParcels={solutionParcels}
                    />

                    {/* Fab button container */}
                    <FabButtonContainer
                        solution={solutionRef.current}
                        currentWorkers={solutionState.currentWorkers}
                        loadingPlanSelected={loadingPlanSelected}
                        editModeState={editModeState}
                        user={reduxState.user}
                        onDrawerButtonClick={() => setDrawerOpen(true)}
                        onWeightDistributionButtonClick={() => setWeightDialogOpen(true)}
                        onEditModeButtonClick={() => handleEditModeButtonClick()}
                        onForceCompletionButtonClick={() => setMiscDialogState(s => ({ ...s, forceCompletionDialogOpen: true }))}
                    />

                    {/* Loading plan threer */}
                    <Box
                        className={classes.threerDivStyle}
                        style={{ marginTop: (!loadingPlanSelected || parcelTransformControlsState.open) ? 0 : '-10%' }}
                    >
                        {solutionState.solution &&
                            <Threer
                                solution={solutionState.solution}
                                parcelUpdate={parcelState.threeParcelsUpdate}
                                loadingAssistantProps={{
                                    selectedParcelToPack: parcelState.selectedParcelToPack,
                                    manipulateDirection: parcelTransformControlsState.direction
                                }}
                                selectedParcel={parcelState.previewParcel}
                                index={solutionState.solution.cargoSpaceIndex}
                                slider={solutionParcels.length}
                                noBorders
                                parcelManipulationCallbacks={{
                                    parcelFilter: (x) => x.parcel?.loaded, // Filter out all un-loaded parcels
                                    onClickParcel: enableParcelManipulations,
                                    onClickArrow: openParcelTransformControls
                                }}
                                parcelBeingManipulated={
                                    manipulatedObjectState.manipulatedParcel && manipulatedObjectState.manipulatedParcel.loaded ?
                                        {
                                            parcel: manipulatedObjectState.manipulatedParcel,
                                            showArrows: manipulatedObjectState.showArrows
                                        }
                                        :
                                        null
                                }
                                editModeState={editModeState}
                            />
                        }
                    </Box>

                    {/* Parcel cards -container */}
                    <Slide
                        direction='up'
                        in={(nextPackableBoxCardsVisible || lmcInProgressOtherUser)}
                    >
                        <div id="boxChooserDiv" className={classes.boxChooserDiv}>
                            <LoadingAssistantBoxCards
                                unit={solutionState.solution?.viewModel?.units?.parcels || { value: 1 }}
                                nextPackableParcels={solutionState.solution?.nextPackableParcels}
                                previewParcel={parcelState.previewParcel}
                                setPreviewParcel={setPreviewParcel}
                                onBoxEdit={handleLastMinuteChangeModalOpen}
                                onSelectParcelToPack={handleSelectingParcelToPacking}
                                selectedParcels={parcelState.selectedParcels}
                                lmcUser={allUsers.find(u => u._id.toString() === cargoSpace.lastMinuteChange?.user)}
                                enableLmcLock={(
                                    cargoSpace.lastMinuteChange?.user !== reduxState.user._id &&
                                    cargoSpace.lastMinuteChange?.status > lmcStatus.none
                                )}
                            />
                        </div >
                    </Slide>

                    {/* Card for editing loaded parcel */}
                    <Slide
                        direction='up'
                        in={selectedParcelCardVisible}
                    >
                        <div className={classes.LMCSelectedParcelCardDiv}>
                            <LMCSelectedParcelCard
                                selectedParcel={manipulatedObjectState.manipulatedParcel}
                                solution={solutionRef.current}
                                updateParcel={parcel => setManipulatedObjectState(s => ({ ...s, manipulatedParcel: parcel }))}
                                openParcelTransformDialog={(direction) => openParcelTransformControls(direction)}
                                onBoxUpdate={(box) => {
                                    onBoxUpdate(box)
                                }}f
                                unloadParcel={() => unloadSelectedParcel()}
                            />
                        </div>
                    </Slide>

                    {/* Card that allows user to edit dimension of a packed parcel */}
                    <Slide
                        direction='up'
                        in={parcelTransformControlsState.open}
                    >
                        <div className={classes.LMCSelectedParcelCardDiv}>
                            <LMCParcelDimensionTransformCard
                                open={parcelTransformControlsState.open}
                                selectedParcel={manipulatedObjectState.manipulatedParcel}
                                solution={solutionState.solution}
                                direction={parcelTransformControlsState.direction}
                                onClose={() => {
                                    setParcelTransformControlsState(s => ({ ...s, open: false, direction: undefined }))
                                    setManipulatedObjectState(s => ({ ...s, showArrows: "all" }))
                                }}
                                setManipulatedObjectState={(newState) => {
                                    setManipulatedObjectState(s => ({ ...s, ...newState }))
                                }}
                            />
                        </div>
                    </Slide>

                    {/* Container for the loading-assistant action-buttons ('join', 'continue', 'cancel', etc.) */}
                    <ActionButtonContainer
                        dbUpdateInProgress={solutionState.dbUpdateInProgress}
                        cargoSpace={cargoSpace}
                        loadingPlanBeingReOptimized={loadingPlanBeingReOptimized}
                        nextPackableBoxCardsVisible={nextPackableBoxCardsVisible}
                        editModeState={editModeState}
                        loadingPlanFinished={loadingPlanFinished}
                        loadingPlanSelected={loadingPlanSelected}
                        parcelSelectedForPacking={parcelState.selectedParcelToPack}
                        onClose={() => navigateBackToLoadingAssistantHome()}
                        onCancelJoiningLoadingJob={() => {
                            setMiscDialogState(s => ({ ...s, closingPage: false }));
                            navigateBackToLoadingAssistantHome();
                        }}
                        onStartLoadingJob={async () => {
                            if (solutionState.solution.cargoSpaces[solutionState.solution.cargoSpaceIndex].assignments.length > 0) {
                                setMiscDialogState(s => ({ ...s, joinLoadingJobDialogOpen: true }))
                                return;
                            }
                            await joinLoadingJob();
                        }}
                        onCancelParcelLoading={async () => await updatePackingBox(parcelPackingStatus.notPacked)}
                        onConfirmParcelPacking={async () => await updatePackingBox(parcelPackingStatus.packed)}
                    />

                    {/* LMC 'Re-calculating'-overlay */}
                    {lmcInProgressThisUser &&
                        <LMCLoadingScreen />
                    }

                </Paper>
            }
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    reduxState: {
        user: state.user
    }
})

const mapDispatchToProps = (dispatch) => ({
    reduxDispatch: {
        onShowNotificationDialog: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity));
        },
    }
})

const styles = theme => ({

    // ----- MAIN PAGE -----
    paper: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column', // column direction also changes the direction of justifyContent and alignItems
        justifyContent: 'flex-start', // x-axis alignment
        alignItems: 'center', // y-axis alignment
        backgroundColor: colors.octagon,
    },

    // ----- THREER -----
    threerDivStyle: {
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        transition: "all 400ms ease",
    },

    // ----- BOX CARDS CONTAINER-----
    boxChooserDiv: {
        position: 'fixed',
        display: "flex",
        justifyContent: 'center',
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        bottom: 0,

        '&::-webkit-scrollbar': {
            height: '1.5vh', // vh keeps the scrollbar at consistent size regardless of screen size
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'insert 0 0 6px rgba(0, 0, 0, 0.1)',
            webkitBoxShadow: 'insert 0 0 6px rgba(0, 0, 0, 0.1)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 10,
        }
    },

    // ----- SELECTED PARCEL ADDITIONAL INFORMATION CARD -----
    LMCSelectedParcelCardDiv: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        margin: '20px',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoadingAssistantTabletLoadingOperationsView))
