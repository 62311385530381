import React, { useRef, useEffect } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';
import CanvasDrawHelper from '../CanvasDrawHelpers'
import { cargoSpaceTypes } from '../Constants'

import { getWeightDistributionData } from './weightDistributionCalculcations'
import { convertSolutionToDisplaySolution, convertCargoSpaceToDisplayCargoSpace } from '../common-helpers'


//TODO: Move or keep here?
const getRelativeCoG = (coG, storageDim) => {
    const dims = ['x', 'y', 'z'];
    const relativeCoG = dims.reduce((a, b) => {
        const rel = coG[b] / storageDim[b];
        return { ...a, [b]: rel }
    }, {})
    return relativeCoG
}


export const addWeightDistributionImage = (solution, idx, images) => {
    // Create the canvas element
    let canvas = document.createElement("canvas");
    canvas.id = "WeightDistCanvas";
    canvas.width = 1200;
    canvas.height = 400;
    document.body.appendChild(canvas);

    // Convert solution into displaySolution.
    const displaySolution = convertSolutionToDisplaySolution(solution);

    // Get the cargo space to draw.
    const cargoSpace = displaySolution.cargoSpaces[idx];

    // Draw weight distribution into the canvas
    const weightData = getWeightDistributionData(cargoSpace);
    drawWeightDistributionToCanvas({ canvas: canvas, cargoSpace: cargoSpace, images: images, weightData: weightData });

    var exporter = canvas.cloneNode(true);
    var ctx = exporter.getContext('2d');
    // first fill set our background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, exporter.width, exporter.height);
    // yes you can draw a canvas onto a canvas
    ctx.drawImage(canvas, 0, 0);
    var imageData = exporter.toDataURL('image/jpeg');

    // Remove the canvas from document
    document.body.removeChild(canvas);
    return imageData;
}


const drawWeightDistributionToCanvas = ({ canvas, images, width, height, cargoSpace, weightData }) => {
    const storageDim = cargoSpace.storages[0].dimensions;
    const { cargoSpaceType, axles } = cargoSpace.storages[0];

    const relativeCoG = getRelativeCoG(weightData.cog, storageDim);

    const parcels = cargoSpace.packedParcels;

    const w = width ?? 1200;
    const h = height ?? 400;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, w, h)

    const canvasDrawer = new CanvasDrawHelper({
        context: ctx,
        storageDims: storageDim,
        cargoSpaceType: cargoSpaceType,
        cog: weightData.cog,
        cogDiff: weightData.cogDiff,
        relativeCoG: relativeCoG,
        images: images
    });

    // Parcels are sorted before they are drawn. Copy parcels so that their
    // original order won't change.
    // Only location and packedDimensions are needed
    let parcelsCopy = parcels.map(x =>
        ({
            location: Object.assign({}, x.location),
            packedDimensions: Object.assign({}, x.packedDimensions)
        })
    );

    switch (cargoSpaceType) {
        case cargoSpaceTypes.container:
            canvasDrawer.drawContainer(parcelsCopy);
            break;
        case cargoSpaceTypes.trailer:
            // const axleWeights = calculateAxleWeights(cog, axles.map(x => x.location), cargoSpace.packedWeight)
            const relativeAxles = axles.map(x => x.location / storageDim.x);
            canvasDrawer.drawTrailer(parcelsCopy, relativeAxles, weightData.axlesInfo.map(n => n.payload));
            break;
        default:
            console.error("Invalid cargospace type: ", cargoSpaceType);
    }
}


const Canvas = props => {
    const canvasRef = useRef(null);

    useEffect(() => {
        drawWeightDistributionToCanvas({
            canvas: canvasRef.current,
            ...props
        })
        // only once on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <canvas ref={canvasRef} width={props.width} height={props.height} />
}


const TCell = withStyles({
    root: {
        border: "1px solid black",

    }
})(TableCell)


const TLabel = withStyles({
    root: {
        border: "1px solid black",
        fontWeight: 500
    }
})(TableCell)


const WeightDistributionDialog = (props) => {
    const { cargoSpace, open, onClose, classes } = props;
    if (!open)
        return <div />

    const displayCargoSpace = convertCargoSpaceToDisplayCargoSpace(cargoSpace);

    const weightData = getWeightDistributionData(displayCargoSpace);

    const storageDim = displayCargoSpace.storages[0].dimensions;
    const parcels = displayCargoSpace.packedParcels;

    const cargoSpaceType = displayCargoSpace.storages[0].cargoSpaceType || cargoSpaceTypes.container;

    let firstTableContainer, secondTableContainer, title, axles, relativeAxles;
    if (cargoSpaceType === cargoSpaceTypes.container) {
        title = "Center of gravity";
        firstTableContainer = (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TCell colSpan={2}>Center of gravity offsets</TCell>
                            <TCell >Max offset</TCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TLabel >Lengthwise</TLabel>
                            <TCell >{weightData.cogDiff.x.toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;m</TCell>
                            <TCell>+/-{weightData.permissibleCogDiff.x}&nbsp;m</TCell>
                        </TableRow>
                        <TableRow>
                            <TLabel >Crosswise</TLabel>
                            <TCell >{weightData.cogDiff.y.toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;m</TCell>
                            <TCell>--</TCell>
                        </TableRow>
                        <TableRow>
                            <TLabel >Vertical</TLabel>
                            <TCell >{weightData.cogDiff.z.toLocaleString('fi-FI', {minimumFractionDigits: 2, maximumFractionDigits: 2})}&nbsp;m</TCell>
                            <TCell>--</TCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
        secondTableContainer = (
                        <div style={{ marginLeft: "50px" }}>
                            <TableContainer style={{ minWidth: "300px", maxWidth: "400px" }}>
                                <Table size="small" className={classes.payloadTable}>
                                    <TableBody >
                                        <TableRow>
                                            <TLabel >Max payload</TLabel>
                                            <TCell >{displayCargoSpace.storages[0].weightLimit.toLocaleString("fi-FI")}&nbsp;kg</TCell>
                                        </TableRow>
                                        <TableRow>
                                            <TLabel>Total used length</TLabel>
                                            <TCell>{(displayCargoSpace.usedLength / 1000).toLocaleString("fi-FI")}&nbsp;m</TCell>
                                        </TableRow>
                                        <TableRow>
                                            <TLabel>Corrected max payload</TLabel>
                                            <TCell>{weightData.correctedMaxPayload.toLocaleString('fi-FI', {maximumFractionDigits: 0})}&nbsp;kg</TCell>
                                        </TableRow>
                                        <TableRow>
                                            <TLabel>Packed payload</TLabel>
                                            <TCell>{displayCargoSpace.packedWeight.toLocaleString("fi-FI")}&nbsp;kg</TCell>
                                        </TableRow>
                                        <TableRow>
                                            <TLabel>Packed payload</TLabel>
                                            <TCell>{weightData.packedPayloadPercentage.toLocaleString('fi-FI',{minimumFractionDigits: 1,maximumFractionDigits: 1})}&nbsp;%</TCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
        );
    } else if (cargoSpaceType === cargoSpaceTypes.trailer) {
        title = "Axle weights"
        axles = displayCargoSpace.storages[0].axles;
        relativeAxles = axles.map(x => x.location / storageDim.x);
        firstTableContainer = (
            <TableContainer>
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TCell />
                            <TCell >Total</TCell>
                            <TCell >Front</TCell>
                            <TCell >Rear</TCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TLabel >Cargo Payload</TLabel>
                            <TCell >{displayCargoSpace.packedWeight.toLocaleString("fi-FI", {maximumFractionDigits: 0})}&nbsp;kg</TCell>
                            <TCell >{weightData.axlesInfo[0].payload.toLocaleString("fi-FI", {maximumFractionDigits: 0})}&nbsp;kg</TCell>
                            <TCell >{weightData.axlesInfo[1].payload.toLocaleString("fi-FI", {maximumFractionDigits: 0})}&nbsp;kg</TCell>
                        </TableRow>
                        <TableRow>
                            <TLabel >Permissible</TLabel>
                            <TCell >{displayCargoSpace.storages[0].weightLimit.toLocaleString("fi-FI")}&nbsp;kg</TCell>
                            <TCell >{weightData.axlesInfo[0].weightLimit.toLocaleString("fi-FI", {maximumFractionDigits: 0})}&nbsp;kg</TCell>
                            <TCell >{weightData.axlesInfo[1].weightLimit.toLocaleString("fi-FI", {maximumFractionDigits: 0})}&nbsp;kg</TCell>
                        </TableRow>
                        <TableRow>
                            <TLabel >Load</TLabel>
                            <TCell >{!displayCargoSpace.storages[0].weightLimit ? 0 : ((displayCargoSpace.packedWeight / displayCargoSpace.storages[0].weightLimit) * 100).toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1})}&nbsp;%</TCell>
                            <TCell>{weightData.axlesInfo[0].payloadPercentage.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1})}&nbsp;%</TCell>
                            <TCell>{weightData.axlesInfo[1].payloadPercentage.toLocaleString("fi-FI", {minimumFractionDigits: 1, maximumFractionDigits: 1})}&nbsp;%</TCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <DialogTitle onClose={onClose}>{title}</DialogTitle>
            <DialogContent>
                <div className={classes.mainContainer}>
                    <Canvas
                        width="1200"
                        height="400"
                        weightData={weightData}
                        storageDims={storageDim}
                        cargoSpaceType={cargoSpaceType}
                        axles={relativeAxles}
                        parcels={parcels}
                        cargoSpace={displayCargoSpace}
                        />
                    <div style={{ display: "flex" }}>
                        {firstTableContainer}
                        {secondTableContainer}
                    </div>
                </div>
            </DialogContent >
            <DialogActions>
                {/* <Button variant="contained" color="secondary" onClick={() => { console.log("NOT IMPLEMENTED !!!") }} >Export PDF</Button> */}
            </DialogActions>
        </Dialog >
    )
}

const styles = theme => ({
    mainContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    payloadTable: {
        maxWidth: "400px"
    },
    cell: {
        border: "1px solid black",
    },
    label: {
        fontWeight: 500
    },
    data: {}
})

export default withStyles(styles)(WeightDistributionDialog)
