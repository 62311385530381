import React from 'react'
import { browserHistory } from 'react-router'

import Octagon from '../core/Octagon';
import Toolbar from '../core/Toolbar';
import SignUpEnabler from '../core/SignUpFeature';
import { colors } from '../theme';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CreateIcon from '@material-ui/icons/Create';

import { withStyles } from '@material-ui/core'



const SuperadminLogsHome = (props) => {

    const menuItems = [];

    menuItems.push((
        <SignUpEnabler key='trialRegistration'>
            <ListItem button onClick={() => browserHistory.push('/admin/logs/trialRegistrations')}>
                <ListItemIcon> {<VpnKeyIcon />} </ListItemIcon>
                <ListItemText primary={'Trial registrations'} />
            </ListItem>
        </SignUpEnabler>
    ))

    menuItems.push((
        <SignUpEnabler key='emailConfirmations'>
            <ListItem button onClick={() => browserHistory.push('/admin/logs/emailConfirmations')}>
                <ListItemIcon> {<EmailIcon />} </ListItemIcon>
                <ListItemText primary={'Email confirmations'} />
            </ListItem>
        </SignUpEnabler>
    ))

    menuItems.push((
        <ListItem button key='passwordResets' onClick={() => browserHistory.push('/admin/logs/passwordResets')}>
            <ListItemIcon> {<RotateLeftIcon />} </ListItemIcon>
            <ListItemText primary={'Password resets'} />
        </ListItem>
    ))

    menuItems.push((
        <ListItem button key='optimizationTasks' onClick={() => browserHistory.push('/admin/logs/optimizationTasks')}>
            <ListItemIcon> {<FormatListBulletedIcon />} </ListItemIcon>
            <ListItemText primary={'Optimization task logs'} />
        </ListItem>
    ))

    menuItems.push((
        <ListItem button key='loadingPlanCreations' onClick={() => browserHistory.push('/admin/logs/loadingPlanCreations')}>
            <ListItemIcon> {<CreateIcon />} </ListItemIcon>
            <ListItemText primary={'Loading plan creation logs'} />
        </ListItem>
    ))



    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title='Superadmin logs' onGoBack={() => browserHistory.push('/admin')} />
            <Paper elevation={4}>
                <List>
                    {menuItems}
                </List>
            </Paper>
        </Octagon>
    )
}


const styles = theme => ({

})


export default withStyles(styles)(SuperadminLogsHome)
