import React, { useState, useEffect, useRef, useCallback } from "react";

import LoadingAssistantTabletHome from "./tablet/LoadingAssistantTabletHome";

import { colors } from "../theme";
import { notificationDialogSeverity } from "../core/Constants";
import { withStyles } from "@material-ui/core/styles";

import { cloneDeep } from "lodash";

import { browserHistory } from "react-router";
import { loadingAssistantApi, organizations, users, solutions as solutionsApi } from "../api";
import { setOrganizations, setUsers } from "../admin/AdminActions";
import { showNotificationDialog } from "../app/AppActions";
import { connect } from "react-redux";

//////////////////////////////////////////////////////////////////////////////

const LoadingAssistant = (props) => {
    const { reduxState, reduxDispatch, classes } = props;

    const [loadingAssistants, setLoadingAssistants] = useState([]);

    // Get the current state of loadingAssistants
    const loadingAssistantsRef = useRef(null);
    loadingAssistantsRef.current = loadingAssistants;


    /**
     * Set all event listeners when entering the page.
     * Clear event listeners when LoadingAssistant unmounts.
     */
    useEffect(() => {
        setSolutionEventListeners();
        return () => {
            // Clear listeners, so we don't have a lot of listeners in the background going on at once
            clearSolutionEventListeners();
        };
    }, []);


    /**
     * Fetch all loading assistants and populate the assignments.
     * Because of limitations in the UI-Backend, an assignment in a loadingAssistant doesn't have the user-field populated.
     * We need to fetch each assignment separately and replace the assignment in loadingAssistant with the fetched one.
     * This function doesn't return anything, it automatically sets the fetched and populated loadingAssistants into loadingAssistants-state.
     */
    const fetchLoadingAssistants = useCallback(async () => {
        loadingAssistantApi
            .list()
            // In each loadingAssistant, the assignments don't have user information populated, so we need to fetch all assignments from /assign-user -service, and replace each assignment in each loadingAssistant with a fetched assignment
            .then((res) => {
                // Get all solutions
                const allSolutions = res.data;

                // Get all assignments from all solutions and flatten them to a 1-dimensional array
                const allAssignmentsForSolution = allSolutions
                    .map((sol) => {
                        return sol.cargoSpaces[sol.cargoSpaceIndex].assignments;
                    })
                    .flat();

                const assignmentIds = allAssignmentsForSolution.map(
                    (assig) => assig._id
                );

                // Return _ids of all assignments and all solutions
                return { assignmentIds, allSolutions };
            })
            .then(async (res) => {
                // Take the assignment _id's and fetch the assignments from /loading-assistant/assign-user service
                const assignments = await findAssignments(res.assignmentIds);
                // Return an array of assignments and all solutions
                return { assignments, allSolutions: res.allSolutions };
            })
            .then((res) => {
                // For each assignment, find the solution this assignment is a part of
                res.assignments.forEach((assig) => {
                    let solutionToUpdate = res.allSolutions.find((sol) => {
                        return (
                            assig.solutionId === sol._id &&
                            assig.cargoSpaceIndex === sol.cargoSpaceIndex
                        );
                    });
                    // From the solution, find the assignment we have to update
                    const assignmentToUpdate = solutionToUpdate.cargoSpaces[
                        solutionToUpdate.cargoSpaceIndex
                    ].assignments.findIndex((a) => a._id === assig._id);
                    // Update the assignment with assig
                    solutionToUpdate.cargoSpaces[
                        solutionToUpdate.cargoSpaceIndex
                    ].assignments[assignmentToUpdate] = assig;
                });
                // Return all (now updated) solutions
                return res.allSolutions;
            })
            .then((res) => {
                // Set all (updated) solutions into loadingAssistants-state
                setLoadingAssistants(res);
            })
            .catch((err) => {
                reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error);
            });
    }, [reduxDispatch]);


    /**
     * Fetch loadingAssistants on the initial render
     */
    useEffect(() => {
        fetchLoadingAssistants();

        // Organizations are fetched for LoadingAssistantTable, to show organization column to superAdmins
        reduxDispatch.onFetchOrganizations(reduxState.user);
        reduxDispatch.onFetchUsers();
    }, [reduxDispatch, reduxState.user, fetchLoadingAssistants]);


    /**
     * When loadingAssistants are fetched, or updated, check if current user has an assignment in any of them.
     * If user does have an assignment in one of the loadingAssistants, automatically
     * redirect user to that loading job.
     */
    useEffect(() => {
        if (loadingAssistants.length === 0) {
            return;
        }

        loadingAssistants.forEach(loadingAssistant => {
            const assignments = loadingAssistant.cargoSpaces[loadingAssistant.cargoSpaceIndex].assignments;
            assignments.forEach(assig => {
                if (assig.user._id === reduxState.user._id) {
                    browserHistory.push(
                        `/loadingAssistant/loading/${loadingAssistant._id}/${loadingAssistant.cargoSpaceIndex}`
                    )
                }
            })
        })
    }, [loadingAssistants, reduxState.user._id])


    /**
     * Fetch the assignments with the given array of assignment._id's
     * @param {Array} ids - array of assignment._id's
     * @returns an array of assignment-objects
     */
    const findAssignments = async function (ids) {
        let listOfAssignments = [];
        for (let i = 0; i < ids.length; i++) {
            const assigs = await loadingAssistantApi.findAssignment({
                query: { _id: ids[i] },
            });
            assigs.data.forEach((a) => listOfAssignments.push(a));
        }
        return listOfAssignments;
    };


    const onStartLoading = (rowData) => {
        browserHistory.push(
            `/loadingAssistant/loading/${rowData._id}/${rowData.cargoSpaceIndex}`
        );
    };


    const setSolutionEventListeners = () => {
        solutionsApi.service().on("patched", onSolutionsPatched.current);
        solutionsApi.service().on("updated", onSolutionsUpdated.current);
        solutionsApi.service().on("removed", onSolutionsRemoved.current);
    };


    const clearSolutionEventListeners = () => {
        solutionsApi.service().removeListener("patched", onSolutionsPatched.current);
        solutionsApi.service().removeListener("updated", onSolutionsUpdated.current);
        solutionsApi.service().removeListener("removed", onSolutionsRemoved.current);
    };


    /**
     * Function that is called when patch event is heard.
     * Take patchedSolution, find all loadingAssistants that have been made from this solution, and replace each loadingAssistant
     * with the new information in patchedSolution.
     * We also need to replace the assignments-array in each cargo space of patchedSolution to get
     * assignments with populated user-objects.
     */
    const onSolutionsPatched = useRef(async function (patchedSolution) {

        if (patchedSolution == null) {
            return;
        }

        // If a patch is heard, but there's no loadingAssistants in the page state, then fetch all loadingAssistants
        if (loadingAssistantsRef.current.length < 1) {
            await fetchLoadingAssistants();
            return;
        }

        // Clone loadingAssistantsRef.current, because we're making changes to it
        let currentLoadingAssistants = cloneDeep(loadingAssistantsRef.current);

        // Find all loadingAssistants made from solution
        let loadingAssistantsToUpdate = loadingAssistantsRef.current.filter(
            (la) => {
                return la._id === patchedSolution._id;
            }
        );

        // If solution has new cargo spaces, then fetch all loadingAssistants again
        if (
            patchedSolution.cargoSpaces.length >
            loadingAssistantsToUpdate.length
        ) {
            await fetchLoadingAssistants();
            return;
        }

        // Copy patchedSolution, because we're populating the assignment information for the solution
        let patchedSolCopy = cloneDeep(patchedSolution);

        // In each cargo space in patchedSolution, replace the assignments-array with the assignment-objects with populated user-information.
        for (let i = 0; i < patchedSolCopy.cargoSpaces.length; i++) {
            let cs = patchedSolCopy.cargoSpaces[i];
            let assignments = []; // Initialize new assignments-array that is going to be put into cs after all the assignments have been fetched.

            // Fetch each assignment from loadingAssistantApi to the get the full assignment-object with the populated user-info
            for (let j = 0; j < cs.assignments.length; j++) {
                const assig = await loadingAssistantApi.findAssignment({
                    query: { _id: cs.assignments[j] },
                });
                assig.data.forEach((a) => assignments.push(a));
            }


            cs.assignments = assignments;
        }

        loadingAssistantsToUpdate.forEach((la) => {
            // Take loadingAssistant and update all fields with the information from patchedSolution
            la = {
                ...la,
                ...patchedSolCopy,
            };

            // Replace current loadingAssistant with the new one
            const laIndex = currentLoadingAssistants.findIndex(
                (currentLA) =>
                    currentLA._id === la._id &&
                    currentLA.cargoSpaceIndex === la.cargoSpaceIndex
            );
            currentLoadingAssistants[laIndex] = la;
        });

        setLoadingAssistants(currentLoadingAssistants);
    });


    /**
     * Function that is called when update event is heard.
     * Take the new solution, find it in loadingAssistantsApi, and add it to the state
     */
    const onSolutionsUpdated = useRef(async function (message) {
        // newLoadingAssistant is fetched from loadingAssistantApi, because the message doesn't include all necessary fields eg. cargoSpaceIndex, etc.
        const newLoadingAssistants = await loadingAssistantApi.list({
            _id: message._id,
        });

        setLoadingAssistants(
            loadingAssistantsRef.current.concat(newLoadingAssistants.data)
        );
    });


    /**
     * Function that is called when remove event is heard.
     * Filter out the removed solution from loadingAssistants and update the state
     */
    const onSolutionsRemoved = useRef(async function (message) {
        const removedSolutionsId = message._id;

        const filteredLoadingAssistants = loadingAssistantsRef.current.filter(
            (la) => la._id.toString() !== removedSolutionsId.toString()
        );
        setLoadingAssistants(filteredLoadingAssistants);
    });


//////////////////////////////////////////////////////////////////////////////


    return (
        <div className={classes.tabletStyle}>
            <LoadingAssistantTabletHome
                loadingAssistants={loadingAssistants}
                onStartLoading={onStartLoading}
                isSuperAdmin={reduxState.user.roles.includes("superadmin")}
                users={reduxState.users}
            />
        </div>
    );
};


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => ({
    reduxState: {
        user: state.user,
        // TODO: Redux reducer should be renamed to match new model where all users within same organization can see all other users in that organization
        users: state.admin.users,
        organizations: state.admin.organizations.data,
    },
});


const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            onFetchOrganizations: (user) => {
                if (!user || !user.roles.includes("superadmin")) return;
                organizations
                    .query({})
                    .then((result) => {
                        dispatch(setOrganizations(result));
                    })
                    .catch((err) => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)));
            },
            onFetchUsers: () => {
                users
                    .query({})
                    .then((res) => {
                        dispatch(setUsers(res));
                    })
                    .catch((err) => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)));
            },
            showMessage: (message, title, severity) => {
                dispatch(showNotificationDialog(message, title, severity));
            },
        },
    };
};


const styles = () => ({
    tabletStyle: {
        background: colors.octagon,
        overflow: "hidden auto",
        width: "100%",
        height: "100%",
        padding: "20px",
    },
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoadingAssistant));

//////////////////////////////////////////////////////////////////////////////
