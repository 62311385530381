import React from 'react';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import SolutionsTableRow from './SolutionsTableRow';

import { convertSolutionToDisplaySolution } from '../../../core/common-helpers';

import { isEmpty } from 'lodash';

//////////////////////////////////////////////////////////////////////////////

const SolutionsTableBody = (props) => {
    const { classes } = props;

    // Check if this row has been selected.
    const isRowSelected = (row) => props.selectedRows != null && props.selectedRows.map(sr => sr._id).indexOf(row._id) !== -1;

//////////////////////////////////////////////////////////////////////////////

    return (
        <TableBody>

            {/* 'No entries' -overlay for when there's no data to display */}
            {(props.data == null || isEmpty(props.data) || props.data.data?.length === 0) &&
                <TableRow>
                    <TableCell colSpan={props.columns.length + 1} style={{ border: 'none' }}>
                        <Typography
                            className={classes.noDataText}
                            variant='h6'
                        >
                            No entries
                        </Typography>
                    </TableCell>
                </TableRow>
            }

            {/* Data rows */}
            {props.data.data?.map((row, i) => {
                return (
                    <SolutionsTableRow
                        columns={props.columns}
                        row={row} // "row" is meant for functionality, like row selection, or "view"-button click.
                        displayRow={convertSolutionToDisplaySolution(row)} // "displayRow" is meant for displaying correct information about the solution.
                        key={i}
                        onViewLoadingPlanClick={props.onViewLoadingPlanClick}
                        selected={isRowSelected(row)}
                        selectedRows={props.selectedRows}
                        onSelectRow={() => props.onSelectRow(row)}
                    />
                )
            })}
        </TableBody>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    noDataText: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
});


export default withStyles(styles)(SolutionsTableBody);
