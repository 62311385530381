
import fs from 'fs'
// use raw-loader explicitly
// import Courier from '!!raw-loader!pdfkit/js/data/Courier.afm'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Courier from '!!raw-loader!pdfkit/js/data/Courier.afm'
// use raw-loader implicitly (webpack is configured to load *.afm files using raw loader)
// eslint-disable-next-line import/no-webpack-loader-syntax
import CourierBold from '!!raw-loader!pdfkit/js/data/Courier-Bold.afm'


// eslint-disable-next-line import/no-webpack-loader-syntax
import HelveticaBoldOblique from '!!raw-loader!pdfkit/js/data/Helvetica-BoldOblique.afm'
// eslint-disable-next-line import/no-webpack-loader-syntax
import HelveticaBold from '!!raw-loader!pdfkit/js/data/Helvetica-Bold.afm'
// eslint-disable-next-line import/no-webpack-loader-syntax
import HelveticaOblique from '!!raw-loader!pdfkit/js/data/Helvetica-Oblique.afm'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Helvetica from '!!raw-loader!pdfkit/js/data/Helvetica.afm'

//Add RobotoCondensed from assets
fetch(`/assets/RobotoCondensed-Regular.ttf`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/RobotoCondensed-Regular.ttf', r)
});

//Add RobotoCondensed from assets
fetch(`/assets/RobotoCondensed-Bold.ttf`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/RobotoCondensed-Bold.ttf', r)
});

//Add Roboto-Regular from assets
fetch(`/assets/Roboto-Regular.ttf`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/Roboto-Regular.ttf', r)
});
//Add Roboto-Medium from assets
fetch(`/assets/Roboto-Medium.ttf`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/Roboto-Medium.ttf', r)
});

fetch(`/assets/boxbot-white_vector_roboto.svg`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/boxbot-white_vector_roboto.svg', r)
});

fetch(`/assets/boxbot.svg`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/boxbot.svg', r)
});

fetch(`/assets/box_do_not_stack_icon.png`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/box_do_not_stack_icon.png', r)
    });

fetch(`/assets/box_overstow_icon.png`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/box_overstow_icon.png', r)
});

fetch(`/assets/box_keep_upright_icon.png`)
    .then(r => r.arrayBuffer())
    .then(r => {
        fs.writeFileSync('assets/box_keep_upright_icon.png', r)
});

fs.writeFileSync('data/Helvetica-BoldOblique.afm', HelveticaBoldOblique)
fs.writeFileSync('data/Helvetica-Bold.afm', HelveticaBold)
fs.writeFileSync('data/Helvetica-Oblique.afm', HelveticaOblique)
fs.writeFileSync('data/Helvetica.afm', Helvetica)

// register all files found in assets folder (relative to src)
// registerBinaryFiles(require.context('./assets', true))

// register AFM fonts distributed with pdfkit
// is good practice to register only required fonts to avoid the bundle size increase
// registerAFMFonts(require.context('pdfkit/js/data', false, /Helvetica.*\.afm$/))

// register files imported directly
fs.writeFileSync('data/Courier.afm', Courier)
fs.writeFileSync('data/Courier-Bold.afm', CourierBold)
// console.log(fs);

// console.log(fs.existsSync('data/Courier.afm'));
