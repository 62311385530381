import React, { useState, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

//////////////////////////////////////////////////////////////////////////////

import { showNotification, showNotificationDialog } from '../app/AppActions'
import { signup as signupService } from '../api'
import { validateName, validateEmail, validateOrganization, validatePassword, validatePasswordConfirmation } from './RegisterFormValidations';
import FormTextField from '../core/FormTextField';
import ReCAPTCHA from '../core/ReCAPTCHA'
import PassWordTextField from '../core/PassWordTextField'
import FormResultDialog, { ModalActionButton } from './FormResultDialog'
import { notificationDialogSeverity } from '../core/Constants'

//////////////////////////////////////////////////////////////////////////////

const defaultState = {
    name: '',
    email: '',
    organization: '',
    password: '',
    passwordConfirm: '',
    errors: {
        name: '',
        email: '',
        organization: '',
        password: '',
        passwordConfirm: ''
    },
}

const defaultPromptState = {
    open: false,
    onClose: () => { }
}

const errorTypes = {
    emailInUse: 'emailInUse',
    emailNotVerified: 'emailNotVerified',
    trialExpired: 'trialExpired'
}

const loginButton = <ModalActionButton key="signing-btn" onClick={() => browserHistory.push('/signin')}>Sign in</ModalActionButton>
const resetPassword = <ModalActionButton key="reset-pwd-btn" onClick={() => browserHistory.push('/passwordreset')}>Reset password</ModalActionButton>
const resend = <ModalActionButton key="resend-mail" onClick={() => browserHistory.push('/resendConfirmation')}>Resend</ModalActionButton>
const navigateToBoxbot = <ModalActionButton key="nav-to-boxbot.fi-btn" onClick={() => { window.location.href = 'https://boxbot.fi'; }}>boxbot.fi</ModalActionButton>

const RegisterForm = (props) => {
    const [state, setState] = useState({ ...defaultState });
    const [recaptchaV2Visible, setRecaptchaV2Visible] = useState(false);
    const [resultDialogState, setResultDialogState] = useState({ ...defaultPromptState });
    const { reduxDispatch, classes } = props;
    const recaptchaRef = useRef(null);

    const onInputChanged = event => {
        // Prevents React from resetting its properties:
        event.persist();

        setState(prevState => ({
            ...prevState,
            // Update the changed field
            [event.target.name]: event.target.value,
            errors: {
                ...prevState.errors,
                // Clear the changed field error
                [event.target.name]: '',
            }
        }))
    }

    const submitHandler = async event => {
        event.preventDefault()

        // Don't submit with enter if reCAPTCHA v2 is visible --> submit is disabled
        if (recaptchaV2Visible)
            return;

        // Validate and set possible errors
        const newErrors = {
            name: validateName(state.name),
            email: validateEmail(state.email),
            organization: validateOrganization(state.organization),
            password: validatePassword(state.password),
            passwordConfirm: validatePasswordConfirmation(state.password, state.passwordConfirm)
        }
        setState(prevState => ({ ...prevState, errors: newErrors }))

        // If there's an error, show notification and don't submit the request
        const firstErrorKey = Object.keys(newErrors).find(key => newErrors[key] !== '');
        if (firstErrorKey) {
            reduxDispatch.onShowNotificationDialog(newErrors[firstErrorKey], 'Invalid information', notificationDialogSeverity.warning);
            return;
        }

        // Create request body
        const { name, email, organization, password } = state;
        // Get tokens
        const tokens = await recaptchaRef.current.getTokens();
        const body = { name, email, organization, password, ...tokens };
        sendForm(body);
    }

    const sendForm = (body) => {
        const defaultNewPrompt = {
            open: true,
            onClose: () => setResultDialogState(s => ({ ...s, ...defaultPromptState }))
        };

        signupService.create(body)
            .then(res => {
                reduxDispatch.onShowNotification(`Email verification sent to ${body.email}`);
                // Clear the state
                setState({ ...defaultState })
                // Set result dialog
                setResultDialogState({
                    ...defaultNewPrompt,
                    title: 'Success',
                    content: `Sign up was successful and email was sent to ${body.email} for email verification. Please check your email. Also, don't forget to check your spam folder.`,
                    actions: [loginButton],
                })
            })
            .catch(err => {
                // Pass the error to ReCAPTCHA component for v2 functionality
                const isErrorHandled = recaptchaRef.current.onError(err);
                // Error was already handled, can continue
                if (isErrorHandled)
                    return;

                console.error(err);
                const { type } = err.data || {};
                setState(prevState => ({ ...prevState }));

                // Always reset the token v2
                switch (type) {
                    case errorTypes.emailInUse:
                        setResultDialogState({
                            ...defaultNewPrompt,
                            title: 'Email is already in use',
                            content: 'This email is already in use. Please log in. In case you forgot your password, reset password from below.',
                            actions: [resetPassword, loginButton]
                        })
                        break;
                    case errorTypes.emailNotVerified:
                        setResultDialogState({
                            ...defaultNewPrompt,
                            title: 'Please check your email',
                            content: `An confirmation email has already been sent to email ${body.email}. The confirmation email might have gone to your junk mail folder. In case you didn't receive the email, you can resent the confirmation.`,
                            actions: [resend]
                        })
                        break;
                    case errorTypes.trialExpired:
                        setResultDialogState({
                            ...defaultNewPrompt,
                            title: 'Free trial is already used',
                            content: 'This email has already used a free trial. Please contact boxbot@boxbot.fi or visit boxbot.fi to start using BOXBOT.',
                            actions: [navigateToBoxbot]
                        })
                        break;
                    default:
                        // Unexpected error
                        return reduxDispatch.onShowNotificationDialog(
                            "Something went wrong",
                            "Error",
                            notificationDialogSeverity.error
                        );
                }
            })
    }

    return (
            <div className={classes.container} >
                <Typography className={`${classes.centered} ${classes.titleText}`} variant='h6' gutterBottom>
                    Sign up for free trial
                </Typography>
                <form onSubmit={submitHandler} className={classes.formStyle}>
                    <FormTextField
                        autoFocus
                        name='email'
                        label='E-mail'
                        type='email'
                        onChange={onInputChanged}
                        value={state.email}
                        error={state.errors.email}
                        autoComplete='username'
                        required
                        fullWidth
                    />
                    <FormTextField
                        name='name'
                        label='Name'
                        type='text'
                        value={state.name}
                        onChange={onInputChanged}
                        error={state.errors.name}
                        required
                        fullWidth
                    />
                    <FormTextField
                        name='organization'
                        label='Organization'
                        type='text'
                        onChange={onInputChanged}
                        value={state.organization}
                        error={state.errors.organization}
                        required
                        fullWidth
                    />
                    <PassWordTextField
                        name='password'
                        label='Password'
                        onChange={onInputChanged}
                        value={state.password}
                        error={state.errors.password}
                        autoComplete='new-password'
                        id='new-password'
                        required
                        fullWidth
                    />
                    <PassWordTextField
                        name='passwordConfirm'
                        label='Confirm Password'
                        onChange={onInputChanged}
                        value={state.passwordConfirm}
                        error={state.errors.passwordConfirm}
                        required
                        fullWidth
                    />
                    <div className={classes.bottomDivider} />
                    <div className={`${classes.textFieldWrapper} ${classes.bottomButtonsContainer}`}>
                        <ReCAPTCHA ref={recaptchaRef} actionName='signup' onV2VisibilityChange={setRecaptchaV2Visible}>
                            <Button variant="contained" color="primary" id="g-recaptcha-v2-btn" onClick={submitHandler} disabled={recaptchaV2Visible} >Sign up</Button>
                        </ReCAPTCHA>
                    </div>
                    <div className={classes.backNavigationContainer}>
                        <Typography className={classes.linkHintText} variant='body1' gutterBottom>
                            {'Already on BOXBOT?'}
                        </Typography >
                        <Link component='button' color="primary" variant="body1" onClick={() => browserHistory.push('/signin')} >
                            {'Sign In'}
                        </Link>
                    </div>
                    <div className={classes.bottomDivider} />
                    <input type='submit' style={{ display: 'none' }} />
                </form>
                <FormResultDialog {...resultDialogState} />
            </div>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    reduxDispatch: {
        onShowNotification: message => dispatch(showNotification(message)),
        onShowNotificationDialog: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity));
        }
    }
})


const styles = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    container: theme.containers.twoPaperFlex,
    paper: theme.containers.flexPaper,
    bottomDivider: theme.app.bottomDivider,
    formStyle: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    bottomButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    centered: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleText: theme.userlessPage.titleText,
    infoText: theme.userlessPage.infoText,
    backNavigationContainer: theme.userlessPage.backNavigationContainer,
    linkHintText: theme.userlessPage.linkHintText,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterForm))
