import { useState, useLayoutEffect } from 'react'

// https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
export default () => {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}