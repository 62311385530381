import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button"

const RoundedButton = withStyles({
    root: {
        borderRadius: 25000,
        fontSize: '0.9375rem'
    }
})(Button)

export default RoundedButton
