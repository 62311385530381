import React from 'react';

import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { withStyles } from '@material-ui/core/styles';

import SolutionsTableHeader from './SolutionsTableComponents/SolutionsTableHeader';
import SolutionsTableBody from './SolutionsTableComponents/SolutionsTableBody';
import GeneralDataTableFooter from '../../core/GeneralDataTable/GeneralDataTableComponents/GeneralDataTableFooter';

import { cloneDeep } from 'lodash';

//////////////////////////////////////////////////////////////////////////////

const SolutionsTable = (props) => {
    // const { classes } = props;


    /**
     * When clicking the checkbox on SolutionsTableHeader, select all rows, or de-select all rows depending
     * on if user had already selected some/all rows. If user had some rows selected, then de-select
     * all row selections, if user had no rows selected, then select all rows.
     * Finally, update selectedRows with props.updateSelectedRows().
     * // NOTE: Just like in GeneralDataTable, this table also suffers from the problem that this function
     * cannot select rows that are on other pages. Therefor, selecting all rows only selects rows on the current page.
     * All rows can be selected by setting pagination to show all rows and then clicking the checkbox.
     */
    const onSelectAllRows = () => {
        let selectedRowsCopy = cloneDeep(props.selectedRows);

        // User has selected some, or all, of the rows => de-select all rows.
        if (selectedRowsCopy.length > 0 && selectedRowsCopy.length <= props.data.data.length) {
            selectedRowsCopy = [];
        }

        // User has selected no rows => select all rows.
        else if (selectedRowsCopy.length === 0) {
            selectedRowsCopy = props.data.data;
        }

        // Handle unknown situation
        else {
            console.error('SolutionsTable, onSelectAllRows(), got into an unhandled situation')
        };

        props.updateSelectedRows(selectedRowsCopy);
    };


    /**
     * Handler for LaodingPlansTableRow checkboxes. When selecting a row, find that row in the data
     * and push it to selectedRowsCopy. When de-selecting a row, filter the row out of selectedRowsCopy.
     * Finally, update selectedRows with props.updateSelectedRows().
     * @param {Object} row
     */
    const onSelectSingleRow = (row) => {
        let selectedRowsCopy = cloneDeep(props.selectedRows);

        if (selectedRowsCopy.find(sr => sr._id === row._id) == null) { // Row hasn't been selected yet
            selectedRowsCopy.push(row);
        } else { // Row has already been selected
            selectedRowsCopy = selectedRowsCopy.filter(sr => sr._id !== row._id);
        }

        props.updateSelectedRows(selectedRowsCopy);
    };

//////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <TableContainer style={{ height: '100%' }}>
                <Table
                    stickyHeader
                    style={{ tableLayout: 'auto' }}
                    size='small'
                >

                    {/* Table header */}
                    <SolutionsTableHeader
                        columns={props.columns}
                        data={props.data}
                        selectedRows={props.selectedRows}
                        onSelectAllRows={() => onSelectAllRows()}
                        columnSortingOptions={props.columnSortingOptions}
                        onChangeColumnSortingOptions={props.onChangeColumnSortingOptions}
                    />

                    {/* Table body */}
                    <SolutionsTableBody
                        columns={props.columns}
                        data={props.data}
                        onViewLoadingPlanClick={props.onViewLoadingPlanClick}
                        selectedRows={props.selectedRows}
                        onSelectRow={(row) => onSelectSingleRow(row)}
                    />

                </Table>
            </TableContainer>

            <Divider />

            {/* Table footer with pagination component */}
            <GeneralDataTableFooter
                paginationState={props.paginationState}
                onChangePagination={props.onChangePagination}
                total={props.data?.total}
            />
        </>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
});


export default withStyles(styles)(SolutionsTable);
