import React, { useEffect, useRef, useState } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FilterListIcon from '@material-ui/icons/FilterList';

import BackDialog from '../../core/BackDialog'
import MultiSelectTable from '../../core/GeneralDataTable/MultiSelectTable'
import Toolbar from '../../core/Toolbar'
import Octagon from '../../core/Octagon'
import OrganizationFilterMenu from '../../core/OrganizationFilterMenu'
import { notificationDialogSeverity } from '../../core/Constants'

import { colors } from '../../theme'
import { getLoadingDirectionObj } from '../../optimize/cargoSpace/OptimizerStorageView'
import { setPresets, setOrganizations } from '../AdminActions'
import { showNotification, showNotificationDialog } from '../../app/AppActions'
import { cargoSpacePreset, organizations as organizationService } from '../../api'

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

import { cloneDeep } from 'lodash'


//////////////////////////////////////////////////////////////////////////////


const loadingDirection = cp => {
    const obj = getLoadingDirectionObj(cp)
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span>{obj.text}</span>
            <img src={obj.img} alt=" " style={{ height: '50px', marginLeft: "5px" }} />
        </div>
    )
}


//////////////////////////////////////////////////////////////////////////////

const CargoSpacePresets = (props) => {


    const {
        cargoPresets,
        onAddCargoPreset,
        onFetchCargoPresets,
        onFetchOrganizations,
        onGoBack,
        onRemoveSelectedPresets,
        onSelectCargoPreset,
        organizations,
        user,
    } = props


    const [paginationOptions, setPaginationOptions] = useState({
        page: 0,
        rowsPerPage: 25,
    });
    const [organizationFilterState, setOrganizationFilterState] = useState({
        filterList: [],
        open: false
    });
    const [tableData, setTableData] = useState([]);
    const [selectedPresets, setSelectedPresets] = useState([]);
    const [removeConfirmationDialogOpen, setRemoveConfirmationDialogOpen] = useState(false);

    let organizationFilterRef = useRef(null);


    const isSuperAdmin = user?.roles.includes("superadmin")


    /**
     * Remove all given cargo space presets. After presets have been removed,
     * reset selectedPresets back to an empty array and fetch all presets again
     * to refresh the table.
     * @param {Array} presets
     */
    const removeSelectedPresets = async (presets) => {
        if (presets.length === 0) {
            showNotificationDialog('Cannot remove presets, because no presets were selected', 'Error', notificationDialogSeverity.error)
            return;
        }
        await onRemoveSelectedPresets(presets)
        setSelectedPresets([]);
        await fetchCargoPresets()
    }


    /**
     * Fetch all cargo space presets.
     * If user is superadmin, then fetch presets with the organization filter, if user
     * is just a normal user, then the fetch returns presets in the users own
     * organization only.
     */
    const fetchCargoPresets = async () => {
        try {
            if (isSuperAdmin) {
                if (organizationFilterState.filterList.length > 0) {
                    onFetchCargoPresets(
                        paginationOptions.page,
                        paginationOptions.rowsPerPage,
                        user,
                        organizationFilterState.filterList
                    )
                } else {
                    onFetchCargoPresets(
                        paginationOptions.page,
                        paginationOptions.rowsPerPage,
                        user,
                        organizations
                    )
                }
            } else {
                onFetchCargoPresets(
                    paginationOptions.page,
                    paginationOptions.rowsPerPage,
                    user
                )
            }
        }
        catch (err) {
            console.error('ERROR', err);
        }
    }


    /**
     * On the initial render, check if the URL has a page-query.
     * If yes, set it to page-state.
     */
    useEffect(() => {
        const { query } = props.location;
        const pageNumber = (query && query.page) ? parseInt(query.page) : 0;
        const limit = (query && query.limit) ? parseInt(query.limit) : 25;
        setPaginationOptions(s => ({ ...s, page: pageNumber, rowsPerPage: limit }))

        // Fetch organizations on the initial render
        if (isSuperAdmin) {
            onFetchOrganizations(user)
        }

    // We only want to run this hook on the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
     * Fetch cargo preset when page is changed, or organization filter is applied.
     */
    useEffect(() => {
        fetchCargoPresets()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        paginationOptions.page,
        paginationOptions.rowsPerPage,
        organizationFilterState.filterList,
        isSuperAdmin,
        onFetchCargoPresets,
        user,
        organizations
    ])


    /**
     * Set up table rows and check if the page has to be reset if there's not enough data to present
     * rows on multiple pages.
     */
    useEffect(() => {

        let cargoPresetsCopy = cloneDeep(cargoPresets);

        // Set up table rows
        cargoPresetsCopy.data = cargoPresetsCopy.data.map(preset => {
            return {
                _id: preset._id,
                name: preset.text,
                dimensions: {
                    x: preset.x,
                    y: preset.y,
                    z: preset.z
                },
                loadingDirection: preset.loadingDirection,
                weightLimit: preset.weightLimit,
                organization: organizations.find(o => o._id === preset.organization),
            }
        })

        setTableData(cargoPresetsCopy);

    }, [cargoPresets, organizations, paginationOptions.rowsPerPage]);


    // Set up table columns
    const columns = [
        { label: 'Name', field: 'name', conversion: (name) => { return name == null ? 'Unknown' : name }},
        { label: 'Dimensions (L × W × H)', field: 'dimensions', conversion: (dims) => { return `${dims.x} × ${dims.y} × ${dims.z}` }},
        { label: 'Packing direction', field: 'loadingDirection', conversion: (dir) => { return loadingDirection(dir) }},
        { label: 'Weight limit', field: 'weightLimit', conversion: (wl) => { return wl > 0 ? wl : '∞'} }
    ]


    // If user is a superadmin, then display the organization-column
    if (isSuperAdmin) {
        columns.push({ label: 'Organization', field: 'organization.name' })
    }


//////////////////////////////////////////////////////////////////////////////

    return (
        <Octagon
            w='80rem'
            color={colors.octagon}
        >
            {/* Toolbar component */}
            <Toolbar
                title='Cargo Space Presets'
                onGoBack={onGoBack}
            >
                <Button
                    startIcon={<AddIcon />}
                    onClick={onAddCargoPreset}
                >
                    Add Cargo Space Preset
                </Button>
                <OrganizationFilterMenu
                    open={organizationFilterState.open}
                    setOpen={(value) => setOrganizationFilterState(s => ({ ...s, open: value }))}
                    anchorRef={organizationFilterRef}
                    organizations={organizations}
                    setOrgFilterList={(value) => setOrganizationFilterState(s => ({ ...s, filterList: value }))}
                />
                <Button
                    ref={organizationFilterRef}
                    startIcon={<FilterListIcon />}
                    onClick={() => setOrganizationFilterState(s => ({ ...s, open: true }))}
                    aria-controls={organizationFilterState.open ? 'menu-list' : undefined}
                    aria-haspopup='true'
                >
                    Organizations
                </Button>
            </Toolbar>

            {/* Remove preset(s) confirmation dialog */}
            <BackDialog
                backDialogOpen={removeConfirmationDialogOpen}
                onConfirmGoBack={() => {
                    setRemoveConfirmationDialogOpen(false)
                    removeSelectedPresets(selectedPresets)
                }}
                onCloseBackDialog={() => setRemoveConfirmationDialogOpen(false)}
                confirmButtonText={'Remove'}
                title='Remove selected presets?'
                text={'Are you sure you want to remove selected cargo space presets? This action cannot be undone.'}
            />

            {/* Table component */}
            <MultiSelectTable
                columns={columns}
                data={tableData}
                paginationState={paginationOptions}
                onChangePagination={(options) => setPaginationOptions(options)}
                selectedRows={selectedPresets}
                updateSelectedRows={(presets) => setSelectedPresets(presets)}
                onClickRow={(preset) => onSelectCargoPreset(preset)}
            >
                <Box>
                    <Tooltip title={'Remove selected'}>
                        <span style={{ fontFamily: 'inherit' }}>
                            <IconButton
                                onClick={() => setRemoveConfirmationDialogOpen(true)}
                                disabled={selectedPresets.length === 0}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </MultiSelectTable>
        </Octagon>
    )

}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        cargoPresets: state.admin.cargoPresets,
        organizations: state.admin.organizations.data,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectCargoPreset: (preset) => {
            browserHistory.push('/admin/cargospacepresets/' + preset._id)
        },
        onFetchCargoPresets: (page, limit, user, organizationFilter) => {
            browserHistory.replace({
                pathname: '/admin/cargospacepresets',
                query: { page, limit }
            })
            const isSuperAdmin = user.roles.includes("superadmin")
            const id = isSuperAdmin ? { $in: organizationFilter.map(x => x._id) } : user.organization._id;
            cargoSpacePreset.list(page, limit, id)
                .then(result => {
                    if (!isSuperAdmin) {
                        // Admin doesn't need to see preset organization names
                        dispatch(setPresets(result))
                        return
                    }
                    // Fetch and map organization names to presets
                    result.data.forEach(preset => {
                        const organizationName = organizationFilter.find(x => x._id === preset.organization)
                        preset.organizationName = organizationName ? organizationName.name : "Unknown"
                    })
                    dispatch(setPresets(result))
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onFetchOrganizations: (user) => {
            if (!user || !user.roles.includes("superadmin")) return
            organizationService.query({})
                .then(res => dispatch(setOrganizations(res)))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onAddCargoPreset: () => {
            browserHistory.push('/admin/cargospacepresets/new')
        },
        onGoBack: () => {
            browserHistory.push('/admin')
        },
        onRemoveSelectedPresets: (selectedPresets) => {
            const ids = selectedPresets.map(preset => preset._id);
            cargoSpacePreset.removeMany(ids)
                .then(() => dispatch(showNotification('Cargo space preset(s) removed succesfully')))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        }
    }
}

const styles = theme => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CargoSpacePresets))

//////////////////////////////////////////////////////////////////////////////
