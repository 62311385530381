import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const checkEditUserOrganizationCountLimit = (editUserId, editUserOrganization, organizationUsers) => {
    const officeUsersCount = organizationUsers.data.filter(user => user.roles.some(role => role.startsWith('office'))).length;
    const loadingOperatorsCount = organizationUsers.data.filter(user => user.roles.includes('loadingOperationsUser')).length;

    let officeUsersAllowed = editUserOrganization.officeUserCountLimit === 0 || officeUsersCount < editUserOrganization.officeUserCountLimit;
    let loadingOperatorsAllowed = editUserOrganization.loadingOperationsUserCountLimit === 0 || loadingOperatorsCount < editUserOrganization.loadingOperationsUserCountLimit;

    // Modifying existing user
    if (editUserId)
    {
        // Check if this user has one of the office user roles --> the user roles can be modified
        officeUsersAllowed = officeUsersAllowed || organizationUsers.data.find(user => user._id === editUserId)?.roles.some(role => role.startsWith('office'));

        // Check if this user has loading operator roles --> the user role can be modified
        loadingOperatorsAllowed = loadingOperatorsAllowed || organizationUsers.data.find(user => user._id === editUserId)?.roles.includes('loadingOperationsUser');
    }

    // If the organization does NOT have loading assistant as enabled feature, loading operator user is not allowed
    loadingOperatorsAllowed = editUserOrganization.enabledFeatures?.includes('loadingAssistant') && loadingOperatorsAllowed;

    return { officeUsersAllowed, loadingOperatorsAllowed };
}


/**
 * Custom hook to handle the user count limit checking to ease access across components
 * @returns { officeUsersAllowed: {Boolean}, loadingOperatorsAllowed: {Boolean} }
 */
const useOrganizationUserCountLimits = () => {
    const [state, setState] = useState({
        officeUsersAllowed: false,
        loadingOperatorsAllowed: false
    });

    // useSelector is redux hook to get access redux state
    const { editUser, organizationUsers } = useSelector(state => ({
        editUser: state.admin.editUser, organizationUsers: state.admin.organizationUsers
    }))

    // Whenever users new organization or the user being edited changes, calculate if the officeUsers and loadingOperationsUser are allowed
    useEffect(() => {
        setState(checkEditUserOrganizationCountLimit(editUser._id, editUser.organization, organizationUsers));
    }, [editUser._id, editUser.organization, organizationUsers])

    return [state.officeUsersAllowed, state.loadingOperatorsAllowed];
}


export default useOrganizationUserCountLimits;
