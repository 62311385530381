import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogTitle from "../../core/DialogTitle";

import ErrorIcon from "@material-ui/icons/Error";

import { withStyles } from "@material-ui/core/styles";

//////////////////////////////////////////////////////////////////////////////

/**
 * This is a custom error dialog used in LoadingAssistantTabletLoadingOperationsView.
 * The reason to have this custom dialog, instead of using notificationDialog, is that this dialog has special onClose-functionality.
 * This dialog is shown if assigning user to a loading job fails, and closing dialog navigates user back to tablet home-page.
 * NOTE: If we add configurable onClose-function ot notificationDialog, then this component isn't needed.
 */
const LOVErrorDialog = (props) => {
    const { classes } = props;

//////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            classes={{ paper: classes.dialog }}
        >
            <Box className={classes.titleContainer}>
                <ErrorIcon className={classes.errorIcon} />
                <DialogTitle onClose={props.onClose} className={classes.title}>
                    {props.errorTitle}
                </DialogTitle>
            </Box>
            <DialogContent>
                <DialogContentText>{props.errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    dialog: {
        minWidth: '300px'
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    errorIcon: {
        height: "40px",
        width: "40px",
        marginRight: "4px",
        marginLeft: "16px",
        color: "red",
    },
    title: {
        paddingLeft: "0px",
    },
});

export default withStyles(styles)(LOVErrorDialog);
