import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "../core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export default (props) => {
    const { open, dialog, onClose } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle onClose={onClose}>
                {"Unsaved work will be lost"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {"Recalculate and overwrite loading plan?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        onClose();
                        if (dialog) dialog.callback();
                    }}
                >
                    Recalculate
                </Button>
            </DialogActions>
        </Dialog>
    );
};
