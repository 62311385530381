// Enable the feature always for Development and for production only when ENV says it's enabled
export const IsSignUpEnabled = process.env.NODE_ENV !== "production" || process.env.REACT_APP_ALLOW_SIGNUP === "true"

/**
* Shows or hides child components based on whether the sign up feature is enabled
*/
const SignUpEnabler = (props) => {
    return IsSignUpEnabled ? props.children : null;
}

export default SignUpEnabler
