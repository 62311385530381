export default (state = {}, action) => {
  switch (action.type) {
    case 'AUTH':
      return action.user
    case 'UPDATE':
      return Object.assign({}, action.user)
    case 'DEAUTH':
      return {}
    case 'TOGGLE_TABLET_MODE':
      return Object.assign({}, state, { tabletMode: action.value })
    default:
      return state
  }
}
