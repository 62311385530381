/**
 * Check parcels dimension and location against all other parcels in cargo space and
 * validate that the parcel will fit into the cargo space.
 * @param {Object} parcel
 * @param {Object} solution
 * @returns inputValidations - Object of different validation metrics. Tells if parcel is invalid in some way.
 */
export const validateTransformedParcelDimensions = (parcel, cargoSpace) => {

    // This object tells us which parcel dimension is invalid
    let inputValidations = {
        xOutOfBounds: false,
        yOutOfBounds: false,
        zOutOfBounds: false,
        parcelOverlap: false,
    };

    // Get dimensions and location of the parcel we want to validate
    const parcelDims = parcel.packedDimensions;
    const parcelLoc = parcel.location;

    // Get dimensions and location of the cargo space
    const storageDims = cargoSpace.storages[0].dimensions;
    const storageLoc = cargoSpace.storages[0].location;


    /**
     * Depending on if the given parcel is loaded or not, we use, or ignore, parcels location in the dimension validation.
     * Loaded parcels need to use parcels location, becuase LMC doesn't move, or re-shuffle loaded parcels.
     * NOTE: These are used ONLY when checking positive direction, negative direction is validated by checking parcels location in relation to cargo spaces location.
     */
    let parcelDimX, parcelDimY, parcelDimZ
    if (parcel.loaded) {
        parcelDimX = parcelDims.x + parcelLoc.x;
        parcelDimY = parcelDims.y + parcelLoc.y;
        parcelDimZ = parcelDims.z + parcelLoc.z;

        // Validate, that parcel isn't out-of-bounds in the x-axis
        if (
            parcelDimX > storageDims.x || // Positive direction
            parcelLoc.x < storageLoc.x    // Negative direction
        ) {
            inputValidations.xOutOfBounds = true
        };

        // Validate, that parcel isn't out-of-bounds in the y-axis
        if (
            parcelDimY > storageDims.y ||
            parcelLoc.y < storageLoc.y
        ) {
            inputValidations.yOutOfBounds = true
        };

        // Validate, that parcel isn't out-of-bounds in the z-axis
        if (
            parcelDimZ > storageDims.z ||
            parcelLoc.z < storageLoc.z
        ) {
            inputValidations.zOutOfBounds = true
        };


    /**
     * For unloaded parcels dimension validation is a little different.
     * LMC can rotate un-loaded parcel freely, so we can let user input dimensions that would force LMC to rotate parcel.
     * E.g. (12000, 2000) and (2000, 12000) are both valid inputs.
     * Also, validate that parcel isn't larger than the cargo space in any direction.
     */
    } else {
        parcelDimX = parcelDims.x;
        parcelDimY = parcelDims.y;
        parcelDimZ = parcelDims.z;

        // Parcels X-axis fits the storage, but Y-axis is larger than the minimum of storage dimensions (x, y) => parcel doesn't fit
        if (
            parcelDimX > Math.min(storageDims.x, storageDims.y) &&
            parcelDimY > Math.min(storageDims.x, storageDims.y)
        ) {
            inputValidations.yOutOfBounds = true
            inputValidations.xOutOfBounds = true
        }


        // Validate that parcels X-axis isn't larger than storageDims.x
        if (
            parcelDimX > Math.max(storageDims.x, storageDims.y) ||
            parcelLoc.x < storageLoc.x
        ) {
            inputValidations.xOutOfBounds = true
        }

        // Validate that parcels Y-axis isn't larger than storageDims.y
        if (
            parcelDimY > Math.max(storageDims.x, storageDims.y) ||
            parcelLoc.y < storageLoc.y
        ) {
            inputValidations.yOutOfBounds = true
        }


        // Validate, that parcel isn't out-of-bounds in the z-axis
        if (
            parcelDimZ > storageDims.z ||
            parcelLoc.z < storageLoc.z
        ) {
            inputValidations.zOutOfBounds = true
        };
    };


    // Get all loaded parcels from cargo space, don't include the given parcel.
    const otherLoadedParcels = cargoSpace.packedParcels.filter((p) => {
        if (p.loaded && p._id !== parcel._id) {
            return p;
        }
        return null;
    });

    // Validate that the given parcel doesn't clip with the other loaded parcels
    otherLoadedParcels.forEach((otherParcel) => {
        const otherParcelDims = otherParcel.packedDimensions;
        const otherParcelLoc = otherParcel.location;

        // If two cuboids overlap, they overlap on all dimensional axes
        if (
            !(
                // Check if x-axis overlaps
                (
                    otherParcelLoc.x + otherParcelDims.x <= parcelLoc.x ||
                    parcelLoc.x + parcelDims.x <= otherParcelLoc.x
                )
            ) &&
            !(
                // Check if y-axis overlaps
                (
                    otherParcelLoc.y + otherParcelDims.y <= parcelLoc.y ||
                    parcelLoc.y + parcelDims.y <= otherParcelLoc.y
                )
            ) &&
            !(
                // Check if z-axis overlaps
                (
                    otherParcelLoc.z + otherParcelDims.z <= parcelLoc.z ||
                    parcelLoc.z + parcelDims.z <= otherParcelLoc.z
                )
            )
        ) {
            inputValidations.parcelOverlap = true;
        }
    });

    return inputValidations;
};


/**
 * Validate parcels weight.
 * Count transformed parcels weight + all other parcels weights and compare result against storage weight limit.
 * @param {Object} parcel
 * @param {Object} cargoSpace
 */
export const validateTransformedParcelWeight = (parcel, cargoSpace) => {

    // Initialize input validations
    let inputValidations = {
        weightLimitBroken: false
    }

    // Get all other parcels in this cargo space
    const allOtherParcels = cargoSpace.packedParcels.filter(p => {
        if (p._id !== parcel._id) {
            return p;
        }
        return null;
    });

    // Initialize the summed weight of all parcels in this cargo space
    let summedWeight = parcel.weight;

    // Sum together the weights of all parcels in this cargo space
    allOtherParcels.forEach(p => {
        summedWeight = summedWeight + p.weight;
    });

    // Check that the summed weight doesn't go over the storage weight limit
    if (
        cargoSpace.storages[0].weightLimit > 0 && // Weight limit 0 == unlimited weight limit
        summedWeight > cargoSpace.storages[0].weightLimit
    ) {
        inputValidations.weightLimitBroken = true;
    }


    return inputValidations;
}
