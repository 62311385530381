import React from 'react'

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import ActionSettings from '@material-ui/icons/Settings'
import QueueIcon from '@material-ui/icons/Queue';
import ViewListIcon from '@material-ui/icons/ViewList';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import DoneIcon from '@material-ui/icons/Done';

import FeatureEnabler from '../FeatureEnabler';

//////////////////////////////////////////////////////////////////////////////

export const Home = ({ onNavigate }) => (
    <ListItem button key='home' onClick={() => onNavigate('/home')}>
        <ListItemIcon><HomeIcon /></ListItemIcon>
        <ListItemText primary='Home' />
    </ListItem>
)

//////////////////////////////////////////////////////////////////////////////

export const NewPlan = ({ onNavigate, location, unsaved, onResetOptimizer }) => (
    <ListItem button key='New Plan' onClick={() => {
        // If the user is already at '/optimizer' page -> LeaveHook is not triggered, prompt about unsaved changes
        if (location.pathname.indexOf('/optimizer') > -1) {
            if (unsaved) {
                if (!window.confirm('Create a new loading plan? All unsaved changes will be lost.'))
                    return
            }
            onResetOptimizer()
        }
        onNavigate('/optimizer')
    }}>

        <ListItemIcon><QueueIcon /></ListItemIcon>
        <ListItemText primary="New Plan" />
    </ListItem>
)

//////////////////////////////////////////////////////////////////////////////

export const LoadingPlans = ({ onNavigate }) => (
    <ListItem button key='Loading Plans' onClick={() => onNavigate('/solutions')}>
        <ListItemIcon><ViewListIcon /></ListItemIcon>
        <ListItemText primary="Loading Plans" />
    </ListItem>
)

//////////////////////////////////////////////////////////////////////////////

export const LoadingAssistant = ({ onNavigate, user }) => (
    <FeatureEnabler featureId='loadingAssistant' key='Loading Assistant'>
        <ListItem button key='Loading Assistant' onClick={() => onNavigate('/loadingAssistant')}>
            <ListItemIcon><SlideshowIcon /></ListItemIcon>
            <ListItemText primary={'Active Jobs'} />
        </ListItem>
    </FeatureEnabler>
)

//////////////////////////////////////////////////////////////////////////////

export const LoadingAssistantCompletedJobs = ({ onNavigate }) => (
    <ListItem button key='loadingAssistantCompletedJobs' onClick={() => onNavigate('/loadingAssistant/completedJobs')}>
        <ListItemIcon><DoneIcon /></ListItemIcon>
        <ListItemText primary='Completed Jobs' />
    </ListItem>
)

//////////////////////////////////////////////////////////////////////////////

export const Admin = ({ onNavigate }) => (
    <ListItem button key='officeAdmin' onClick={() => onNavigate('/admin')}>
        <ListItemIcon><ActionSettings /></ListItemIcon>
        <ListItemText primary='Admin' />
    </ListItem>
)
