import React, { forwardRef } from 'react'
import MenuItem from '@material-ui/core/MenuItem'

const TabSelectionMenuItem = forwardRef(
    ({ "data-value": valueForEvent, value, onClick, ...other}, ref) => {
        const handleKeyDown = event => {
            const triggerKeys = ["Tab", "Enter", "ArrowRight", "ArrowLeft"]
            if (triggerKeys.includes(event.key)) {
                event.target = { value: valueForEvent };
                onClick(event)
            }
        }
        return <MenuItem ref={ref} value={value} onKeyDown={handleKeyDown} onClick={onClick} {...other} />
    }
)


export default TabSelectionMenuItem