import React from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

import CargoSpacesTableBody from './CargoSpacesTableBody';
import CargoSpacesTableFooter from './CargoSpacesTableFooter';
import CargoSpacesTableHeader from './CargoSpacesTableHeader';

import { usePreviewThreer } from '../../../three/SolutionPreviewThreer';

import { withStyles } from '@material-ui/core/styles';
import { cargoSpaceArrangement } from '../../../core/Constants';

//////////////////////////////////////////////////////////////////////////////

const CargoSpacesTable = (props) => {
    // const { classes } = props;

    // Create the preview images for the cargo spaces-table.
    const previewImages = usePreviewThreer(props.displaySolution);


    /**
     * Format storage dimensions into a clear label.
     * Add storage preset name to the label too.
     * @param {Object} storage
     * @returns span-component with the formatted dimensions
     */
    const formatStorageDimensions = (storage) => {
        const dimensions = storage.dimensions;
        const meters = d => (d / 1000).toFixed(1);
        return (
            <span>
                {storage.presetName &&
                    <span>
                        {storage.presetName} <br />
                    </span>
                }
                {meters(dimensions.x)} &times; {meters(dimensions.y)} &times; {meters(dimensions.z)}
            </span>
        )
    }

    /**
     * Columns for the upper table header.
     */
    const columns = [
        { label: 'Packed', field: 'packedParcels', conversion: (packedParcels) => { return packedParcels.length } },
        { label: 'Used Length (m)', field: 'usedLength', conversion: (length) => { return (length / 1000).toFixed(1).toLocaleString("fi-FI").replace(',', '.')} },
        { label: 'Weight (kg)', field: 'packedWeight', conversion: (weight) => { return weight.toFixed(0).toLocaleString('fi-FI')} },
        {
            label: <span style={{ font: 'inherit' }}>Volume (m<sup style={{ font: 'inherit' }}>3</sup>)</span>,
            field: 'packedVolume',
            conversion: (vol) => { return ((vol / (1000 * 1000 * 1000)).toFixed(2).toLocaleString('fi-FI').replace(',', '.'))}
        },
        {
            label: <span style={{ font: 'inherit' }}>Cargo Space (X &times; Y &times; Z)</span>,
            field: 'storages',
            conversion: (storages) => { return formatStorageDimensions(storages[0]) }
        }
    ];

    /**
     * Columns for the bottom table header.
     * Total used length has to be calculated from cargo spaces, becuase used lengths might
     * vary depending on if cargo space has been spread or not.
     */
    const totalColumns = [
        {
            // Total packed parcels
            field: 'cargoSpaces',
            conversion: (cargoSpaces) => {
                const packedParcelAmounts = cargoSpaces.map(cs => cs.packedParcels.length)
                const totalPackedParcels = packedParcelAmounts.reduce(
                    (prev, curr) => prev + curr, 0
                )
                return (
                    `${totalPackedParcels} / ${totalPackedParcels + props.displaySolution.notPackedParcels.length} (${(((totalPackedParcels + props.displaySolution.notPackedParcels.length) / totalPackedParcels) * 100).toFixed(0)}%)`
                )
            }
        },
        {
            // Total used length
            field: 'cargoSpaces',
            conversion: (cargoSpaces) => {
                const usedLengths = cargoSpaces.map(cs => cs.usedLength);
                const totalUsedLength = usedLengths.reduce(
                    (prev, curr) => prev + curr, 0
                )
                return (
                    parseFloat((totalUsedLength / 1000).toFixed(2).toLocaleString('fi-FI'))
                )
            }
        },
        {
            field: 'totalPackedWeight',
            conversion: (packedWeight) => { return parseInt(packedWeight.toFixed(0).toLocaleString('fi-FI')) }
        },
        {
            field: 'totalPackedVolume',
            conversion: (packedVolume) => { return parseFloat((packedVolume / (1000 * 1000 * 1000)).toFixed(2).toLocaleString('fi-FI')) }
        }
    ];


    /**
     * Possible alternate arrangment choises that are used in the packing goal selectors on each row
     * of the cargo space -table.
     */
    const alternateArrangements = [
        {
            label: 'Standard',
            value: cargoSpaceArrangement.standard
        },
        {
            label: 'Spread',
            value: cargoSpaceArrangement.spread
        }
    ];

//////////////////////////////////////////////////////////////////////////////

    return (
        <TableContainer style={{ height: '100%' }}>
            <Table
                stickyHeader
                style={{ tableLayout: 'auto' }}
            >

                <CargoSpacesTableHeader
                    solution={props.displaySolution}
                    columns={columns}
                    totalColumns={totalColumns}
                />

                <CargoSpacesTableBody
                    solution={props.displaySolution}
                    onRowClick={(i) => props.onRowClick(i, props.displaySolution)}
                    previewImages={previewImages}
                    columns={columns}
                    onExportPdfClick={(i) => props.onExportPdfClick(i)}
                    alternateArrangements={alternateArrangements}
                    onSelectedArrangementChange={(i, selectedArrangement) => props.onSelectedArrangementChange(i, selectedArrangement, props.solution)}
                    disableChanges={props.disableChanges}
                    isArrangementDisabled={props.isArrangementDisabled}
                />

                <CargoSpacesTableFooter
                    solution={props.displaySolution}
                    totalColumns={totalColumns}
                />
            </Table>
        </TableContainer>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
});


export default withStyles(styles)(CargoSpacesTable);
