import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import SwipeableViews from 'react-swipeable-views';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import AssignmentIcon from '@material-ui/icons/Assignment';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';


import PdfExportModal from '../../pdf/PdfExportModal';
import WeightDistributionDialog from '../../core/WeightDistributionDialog/weightDistributionDialog';
import Threer from '../../three/Threer';
import { Info, infoTexts } from '../InfoDialog';
import { colors } from '../../theme';
import { notificationDialogSeverity, cargoSpaceArrangement } from '../../core/Constants';
import { solutionPackingStatus } from '../../loadingAssistant/Constants';
import { convertSolutionToDisplaySolution } from '../../core/common-helpers';

import { connect } from 'react-redux';
import { setSolution } from '../OptimizeActions';
import { showNotificationDialog } from '../../app/AppActions';

import CargoSpacesTable from './CargoSpacesTable/CargoSpacesTable';

import { cloneDeep } from 'lodash';

//////////////////////////////////////////////////////////////////////////////


const TabPanel = props => {
    const { children, value, index, boxProps, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ height: "100%" }}
            {...other}
        >
            <Box {...boxProps} p={3}>{children}</Box>
        </div>
    )
}


//////////////////////////////////////////////////////////////////////////////


const OptimizerCargoSpacesTable = props => {

    const [pdfExportModal, setPdfExportModal] = useState({ open: false, cargoSpaceIndexes: [] })
    const [state, setState] = useState({
        tabIndex: 0,
        sliderValue: props.solution ? props.solution.cargoSpaces[0].packedParcels.length : 0,
        infoPopupOpen: false,
    })
    const [displaySolution, setDisplaySolution] = useState(null);

    const { tabIndex, sliderValue, infoPopupOpen } = state
    const {
        cancel,
        classes,
        clearInfoChild,
        fetching,
        isActive,
        openInfoChild,
        optimize,
        progressStatus,
        queueStatus,
        selectedCargo,
        selectedParcel,
        setInfoDialog,
        setUnsaved,
        setSelectedCargo,
        showCalculationOverlay,
        solution,
        unpackableParcels,
    } = props

    const { onSetSolution, onShowNotificationDialog } = props.reduxDispatch;

    const sliderDisabled = !Boolean(solution)
    const sliderTextStyle = { width: "3rem" }
    if (sliderDisabled) sliderTextStyle.color = "rgba(0, 0, 0, 0.26)"



    // Whenever showCalculationOverlay is set, reset tabIndex to 0
    // Kinda hacky way to avoid passing extra parameters / functions for when "pressing recalculate, set tabIndex to 0"
    useEffect(() => {
        if (!showCalculationOverlay)
            return;

        setState(s => ({ ...s, tabIndex: 0 }))
    }, [showCalculationOverlay])


    /**
     * When user enters the Optimize & Inspect -page, make a solution that is used
     * to create the preview images on the cargo spaces -table.
     */
     useEffect(() => {
        if (solution != null) {
            setDisplaySolution(convertSolutionToDisplaySolution(solution));
        }
    }, [solution]);


    /**
     * When user clicks on a cargo space on the cargo spaces-table, change to the
     * "visualize"-panel, reset the slider value, and open the info-child.
     * @param {Number} index
     * @param {Object} sol
     */
    const onRowClick = (index, sol) => {
        setSelectedCargo(index);
        setState(s => ({
            ...s,
            tabIndex: 1,
            sliderValue: sol.cargoSpaces[index].packedParcels.length
        }));
        openInfoChild();
    }


    /**
     * Event handler for selecting a different packing optimization goal for a cargo space.
     * This function updates the new selected arrangement-info to a cargo space
     * in the solution in optimizer-redux state.
     * @param {Number} index
     * @param {Number} selectedArrangement
     * @param {Object} sol
     */
    const onSelectedArrangementChange = (index, selectedArrangement, sol) => {

        // Check if loading plan has already been sent to loading.
        if (props.disableChanges) {
            onShowNotificationDialog(
                'Changing cargo space packing goal is not allowed when loading plan has been sent to loading.',
                'Error',
                notificationDialogSeverity.error
            );
            return;
        }

        // Make copy of the cargo space that user has modified.
        let cargoSpace = cloneDeep(sol.cargoSpaces[index]);

        // Update the selectedArrangement information with the selected value.
        cargoSpace.selectedArrangement = selectedArrangement;

        // Replace the old cargo space with the updated one.
        sol.cargoSpaces[index] = cargoSpace;

        // Update the solution to redux.
        onSetSolution(sol);

        // Update displaySolution.
        setDisplaySolution(convertSolutionToDisplaySolution(sol));

        // Set optimizer-redux-state "Unsaved"-field to true.
        setUnsaved(true);
    }


    /**
     * Check if a specific arrangement of cargo space is invalid. Invalid arrangements
     * don't have any packed parcels.
     * @param {Number} arrangementId
     * @param {Number} cargoSpaceIndex
     * @returns true / false depending on if arrangement is invalid or not.
     */
    const isArrangementDisabled = (arrangementId, cargoSpaceIndex) => {

        if (props.solution == null) {
            return;
        }

        const cargoSpace = props.solution.cargoSpaces[cargoSpaceIndex];

        // Disable all arrangement selectors if cargo space is in loading.
        if (cargoSpace.packingStatus > solutionPackingStatus.undefined) {
            return true;
        };

        // If cargo space doesn't have alternate arrangments, then only enable selector for the existing arrangement.
        if (
            !cargoSpace.alternateArrangements ||
            cargoSpace.alternateArrangements.length === 0
        ) {
            // If given arrangement isn't the one cargo space is using
            if (arrangementId !== cargoSpace.selectedArrangement) {
                return true;
            }
            return false;
        };

        // Selector for standard arrangement is disabled when cargoSpace.packedParcels is empty or when cargoSpace isn't using "Standard" packingOptimizationGoal.
        if (arrangementId === 0) {
            if (
                cargoSpace.packedParcels.length === 0 ||
                cargoSpace.packingOptimizationGoal !== 'Standard'
            ) {
                return true;
            }
            return false;
        }

        // Selectors for alternate arrangements are disabled if altArrangement.packedParcels is null or empty.
        const altArrangement = cargoSpace.alternateArrangements.find(aa => aa.arrangementId === arrangementId);
        if (
            !altArrangement ||
            !altArrangement.packedParcels ||
            altArrangement.packedParcels.length === 0
        ) {
            return true;
        }
        return false;
    }


    /**
     * Check cargo spaces selected arrangements packingOptimizationGoal, and return it.
     * @param {Object} cargoSpace
     * @returns cargo spaces selected arrangements packingOptimizationGoal
     */
    const checkToggleButtonGroupValue = (cargoSpace) => {
        if (cargoSpace == null) {
            return;
        }

        if (!cargoSpace.selectedArrangement) {
            return cargoSpace.packingOptimizationGoal;
        } else {
            if (cargoSpace.selectedArrangement > 0) { // selectedArrangement > 0 ==> an alternative arrangement is selected
                const altArrangement = cargoSpace.alternateArrangements.find(aa => aa.arrangementId === cargoSpace.selectedArrangement);
                return altArrangement.packingOptimizationGoal;
            }
            return cargoSpace.packingOptimizationGoal;
        }
    }


//////////////////////////////////////////////////////////////////////////////


    const LinearProgressWithLabel = (
        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: 10 }}>
            <Box style={{ width: '90%', alignItems: 'center' }}>
                <LinearProgress variant='determinate' value={progressStatus.progress} />
            </Box>
            <Box style={{ width: '10%', alignItems: 'center' }}>
                <Typography variant='body2'>
                    {`${Math.round(progressStatus.progress)}%`}
                </Typography>
            </Box>
        </Box>
    )


    // Slider component on the bottom of the visualization window. This component controls how many of the parcels are shown on the visualization.
    const slider = (
        <div style={{ display: "flex", width: "calc(100% - 24px)", justifyContent: "space-between", margin: "32px 0px 0px 12px" }}>
            <span style={sliderTextStyle}>Empty</span>
            <Slider
                ThumbComponent={props => (
                    <div className={classes.thumb}>
                        <div {...props}>
                            <img src='/assets/hexagon_boxbot_orange.svg' alt=" " style={{ height: "20px" }} />
                        </div>
                    </div>
                )}
                disabled={sliderDisabled}
                style={{ margin: "-6px 24px 0px 24px" }}
                value={sliderValue}
                defaultValue={Number.MAX_SAFE_INTEGER}
                max={solution ? solution.cargoSpaces[selectedCargo].packedParcels.length : 100}
                color="primary"
                onChange={(e, value) => setState({ ...state, sliderValue: value })}
            />
            <span className={classes.textStyle} style={sliderTextStyle}>Packed</span>
        </div>
    )


    const solutionOverlay = (
        <SwipeableViews
            axis={tabIndex === 1 ? 'x' : 'x-reverse'}
            index={tabIndex}
            style={{ height: "100%" }}
            containerStyle={{ height: "100%" }}
            disableLazyLoading={true}
        >
            <TabPanel value={tabIndex} index={0} style={{ width: "100%", height: "100%" }} boxProps={{ style: { height: "100%", padding: "0px" } }} >
                <CargoSpacesTable
                    solution={solution}
                    displaySolution={displaySolution}
                    onRowClick={(i, sol) => onRowClick(i, sol)}
                    onExportPdfClick={(i) => setPdfExportModal({ open: true, cargoSpaceIndexes: [i] })}
                    onSelectedArrangementChange={(i, selectedArrangement, sol) => onSelectedArrangementChange(i, selectedArrangement, sol)}
                    disableChanges={props.disableChanges}
                    isArrangementDisabled={isArrangementDisabled}

                />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} style={{ height: "100%", overflow: "hidden" }} boxProps={{ style: { height: "100%", padding: "0px" } }}>
                <Box className={classes.visualizeButtonsContainer}>

                    <Paper style={{ padding: '6px'}} elevation={2}>
                        <FormHelperText style={{ marginLeft: '6px' }}>
                            Packing optimization goal
                        </FormHelperText>
                        <ToggleButtonGroup
                            exclusive
                            value={checkToggleButtonGroupValue(solution?.cargoSpaces[selectedCargo])}
                        >
                            <ToggleButton
                                value={'Standard'}
                                onClick={() => onSelectedArrangementChange(selectedCargo, cargoSpaceArrangement.standard, solution)}
                                disabled={
                                    isArrangementDisabled(cargoSpaceArrangement.standard, selectedCargo)
                                    || props.disableChanges
                                }
                            >
                                <Box className={classes.toggleButtonTextContainer}>
                                    <ControlCameraIcon className={classes.toggleButtonIcon} />
                                    Standard
                                </Box>
                            </ToggleButton>
                            <ToggleButton
                                value={'Spread'}
                                onClick={() => onSelectedArrangementChange(selectedCargo, cargoSpaceArrangement.spread, solution)}
                                disabled={
                                    isArrangementDisabled(cargoSpaceArrangement.spread, selectedCargo)
                                    || props.disableChanges
                                }
                            >
                                <Box className={classes.toggleButtonTextContainer}>
                                    <ZoomOutMapIcon className={classes.toggleButtonIcon} />
                                    Spread
                                </Box>
                            </ToggleButton>
                        </ToggleButtonGroup>

                    </Paper>


                    <Button
                        startIcon={<AssignmentIcon style={{ color: colors.bbOrange }} />}
                        variant="contained"
                        onClick={() => setState({ ...state, infoPopupOpen: true })}
                    >
                        Load Info
                    </Button>
                </Box>
                <div style={{ height: "calc(100% - 60px)" }}>
                    <Threer
                        selectedParcel={selectedParcel}
                        solution={displaySolution}
                        parcelUpdate={displaySolution?.cargoSpaces[selectedCargo].packedParcels}
                        index={selectedCargo}
                        slider={sliderValue}
                        zoomFactor={0.63}
                    />
                </div>
                {slider}
            </TabPanel>
        </SwipeableViews>
    )


    const calculatingOverlay = (
        <div className={classes.centered}>
            <div className={classes.innerCenter}>
                { // In case there are unpackableParcels show warnings about them
                    unpackableParcels &&
                    <div style={{ display: "flex", color: "red", marginLeft: "16px" }}>
                        <h3>{unpackableParcels.buttonProps.errorLabel}</h3>
                        <Info info={infoTexts.unpackableParcels} onClick={setInfoDialog} />
                    </div>
                }
                {isActive ? // Have we sent the solution to calculating? Show cancel button regardless of queueStatus
                    // Solution sent to calculation
                    <>
                        {queueStatus ? // Is calculation in queue?
                            // Calculation is in queue waiting its turn
                            <>
                                <CircularProgress color='primary' size={48} thickness={3} />
                                <h3 className={classes.headerLoadingPlanText}>
                                    {queueStatus}
                                </h3>
                            </>
                            :
                            // Calculation not in queue. Calculation is in progress
                            <>
                                {LinearProgressWithLabel}
                                <h3 className={classes.headerLoadingPlanText}>
                                    {"BOXBOT is creating a loading plan for you..."}
                                </h3>
                                {progressStatus.solvedStorageCount > 0 &&
                                    <h3 className={classes.headerLoadingPlanText}>
                                        {`${progressStatus.solvedStorageCount} Cargo space${progressStatus.solvedStorageCount > 1 ? "s" : ""} packed`}
                                    </h3>
                                }
                            </>
                        }
                        <Button variant="contained" color="secondary" className={classes.raisedButton} disabled={!isActive} onClick={() => cancel()}>Cancel</Button>
                    </>
                    :
                    // Solution not in calculation
                    <>
                        {fetching ? // Are we fetching a saved solution?
                            // Yes we are, show CircularProgress while fetching
                            // This shows when user refreshes the Optimize & Inspect page
                            <Box className={classes.centered} style={{ flexDirection: 'column', rowGap: 15 }}>
                                <Box>
                                    <CircularProgress />
                                </Box>
                                <Box>
                                    <FormLabel>
                                        Loading cargo spaces...
                                    </FormLabel>
                                </Box>
                            </Box>
                            :
                            // We are not fetching a saved solution
                            <>
                                {solution ? // Is there a solution currently present?
                                    // Yes there is
                                    // This is the gap when showCalculatingOverlay is set to true and isActive is still false
                                    // Without this empty gap, we would see the start-button flash on the screen before the calculatingOverlay is shown on the screen
                                    <></>
                                    :
                                    // No there is no solution present
                                    // Show start-button
                                    // This is shown only when user is calculating a brand new solution
                                    <Button style={{ width: "85px", height: "42px" }} size="large" variant="contained" color="secondary" disabled={isActive} onClick={() => optimize()}>Start</Button>
                                }
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )



    return (
        <Paper style={{ width: "100%", margin: '32px 0 16px 0', height: `calc(100% - 95px)` }}>

            <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={(e, value) => {
                    setState({ ...state, tabIndex: value, sliderValue: Number.MAX_SAFE_INTEGER })
                    if (value === 0) clearInfoChild()
                    else openInfoChild()
                }}>
                <Tab label={`Cargo Spaces (${solution ? solution.cargoSpaces.length : 0})`} value={0} />
                <Tab label={'Visualize'} value={1} disabled={!Boolean(solution) || showCalculationOverlay} />
            </Tabs>

            <div className={classes.textStyle} style={{ height: "calc(100% - 46px)", overflow: "hidden auto", width: "100%" }}>
                {(solution && !showCalculationOverlay) ? // Is there a solution present and we aren't calculating a new one?
                    solutionOverlay  // Yes, show the solution
                    :
                    calculatingOverlay // No, show calculating overlay
                }
            </div>

            {solution &&
                <WeightDistributionDialog
                    cargoSpace={solution?.cargoSpaces[selectedCargo]}
                    open={infoPopupOpen}
                    onClose={() => setState({ ...state, infoPopupOpen: false })}
                />
            }

            <PdfExportModal
                open={pdfExportModal.open}
                onClose={() => setPdfExportModal({ open: false, cargoSpaceIndexes: [] })}
                cargoSpaceIndexes={pdfExportModal.cargoSpaceIndexes}
                solution={solution}
            />

        </Paper >
    )
}


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
    return {};
};


const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            onSetSolution: (solution) => dispatch(setSolution(solution)),
            onShowNotificationDialog: (message, title, severity) => dispatch(showNotificationDialog(message, title, severity)),
        }
    }
}


const styles = theme => ({
    textStyle: theme.textStyle,
    thumb: { color: colors.octagon },
    raisedButton: theme.raisedButton,
    centered: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    innerCenter: {
        width: "350px",
        height: "250px",
        padding: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    headerLoadingPlanText: theme.optimizer.headerLoadingPlanText,
    visualizeButtonsContainer: {
        position: 'fixed',
        width: '100%',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toggleButtonTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    toggleButtonIcon: {
        fontSize: '20px',
        marginRight: '4px'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OptimizerCargoSpacesTable));
