import React, { useState, useEffect, useRef } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { cloneDeep } from "lodash";
import { withStyles } from "@material-ui/core/styles";

import Units from "../../core/Units/Units";
import NumberTextField from "../../core/NumberTextField";
import { arrowDirections } from "../../three/Arrow";

import { validateTransformedParcelDimensions } from "../LMCUtils";


//////////////////////////////////////////////////////////////////////////////

const getDimension = (parcel, direction) => {
    switch (direction) {
        case arrowDirections.zPlus:
            return parcel?.packedDimensions.z ?? 1;
        case arrowDirections.xMinus:
        case arrowDirections.xPlus:
            return parcel?.packedDimensions.x ?? 1;
        case arrowDirections.yMinus:
        case arrowDirections.yPlus:
            return parcel?.packedDimensions.y ?? 1;
        default:
            return 1;
    }
}


const LMCParcelDimensionTransformCard = (props) => {
    const { classes } = props;

    const [originalParcel, setOriginalParcel] = useState(props.selectedParcel);
    const [dimensionUnit, setDimensionUnit] = useState(Units.mm.value);
    const [resetTextField, setResetTextField] = useState(0);
    const [directionLabel, setDirectionLabel] = useState('None');
    const [error, setError] = useState(false);

    const inputRef = useRef(null);


    // Set focus on every render
    useEffect(() => {
        inputRef.current.focus()
    });

    /**
     * Whenever direction changes, set the current parcel as the origin purely for the "Cancel" functionality.
     * Also determine the label for the NumberTextField-component.
     */
    useEffect(() => {
        setOriginalParcel(props.selectedParcel);

        let newDirectionLabel;
        switch (props.direction) {
            case arrowDirections.zPlus:
                newDirectionLabel = 'Height'
                break
            case arrowDirections.xMinus:
            case arrowDirections.xPlus:
                newDirectionLabel = 'Width'
                break
            case arrowDirections.yMinus:
            case arrowDirections.yPlus:
                newDirectionLabel = 'Depth'
                break
            default:
                newDirectionLabel = 'None'
                break
        }

        setDirectionLabel(newDirectionLabel)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.direction])


    // Whenever direction or unit changes, reset the text field ref value
    useEffect(() => {
        setResetTextField(s => (s + 1) % Number.MAX_SAFE_INTEGER);
    }, [props.direction, dimensionUnit])


    const handleParcelDimensionChange = (newParcelDimension) => {
        let newManipulatedParcel = cloneDeep(props.selectedParcel);

        const { x, y } = newManipulatedParcel.dimensions;
        const { x: px, y: py } = newManipulatedParcel.packedDimensions;
        let dim;

        // Replace parcels appropriate dimension with the new value
        switch (props.direction) {
            case arrowDirections.zPlus:
                // z is always up - algorithm can't turn boxes to their side so no need check correctness of dimensions
                newManipulatedParcel.packedDimensions.z = newParcelDimension;
                newManipulatedParcel.dimensions.z = newParcelDimension;
                break;
            case arrowDirections.xMinus:
                newManipulatedParcel.location.x = originalParcel.location.x + originalParcel.packedDimensions.x - newParcelDimension;
                newManipulatedParcel.packedDimensions.x = newParcelDimension;
                // dimensions isn't always the same as packedDimensions - rotations might have changed these --> modify the correct dimension
                dim = x === px ? 'x' : 'y';
                newManipulatedParcel.dimensions[dim] = newParcelDimension;
                break;
            case arrowDirections.xPlus:
                newManipulatedParcel.packedDimensions.x = newParcelDimension;
                // dimensions isn't always the same as packedDimensions - rotations might have changed these --> modify the correct dimension
                dim = x === px ? 'x' : 'y';
                newManipulatedParcel.dimensions[dim] = newParcelDimension;
                break;
            case arrowDirections.yMinus:
                newManipulatedParcel.location.y = originalParcel.location.y + originalParcel.packedDimensions.y - newParcelDimension;
                newManipulatedParcel.packedDimensions.y = newParcelDimension;
                // dimensions isn't always the same as packedDimensions - rotations might have changed these --> modify the correct dimension
                dim = y === py ? 'y' : 'x';
                newManipulatedParcel.dimensions[dim] = newParcelDimension;
                break;
            case arrowDirections.yPlus:
                newManipulatedParcel.packedDimensions.y = newParcelDimension;
                // dimensions isn't always the same as packedDimensions - rotations might have changed these --> modify the correct dimension
                dim = y === py ? 'y' : 'x';
                newManipulatedParcel.dimensions[dim] = newParcelDimension;
                break;
            default:
                return;
        }

        props.setManipulatedObjectState({ manipulatedParcel: newManipulatedParcel });

        const parcelValidations = validateTransformedParcelDimensions(newManipulatedParcel, props.solution?.cargoSpaces[props.solution.cargoSpaceIndex])
        if (
            parcelValidations.xOutOfBounds ||
            parcelValidations.yOutOfBounds ||
            parcelValidations.zOutOfBounds ||
            parcelValidations.parcelOverlap
        ) {
            setError(true);
        } else {
            setError(false);
        }
    };

    //////////////////////////////////////////////////////////////////////////////

    let newDim = getDimension(props.selectedParcel, props.direction);
    return (
        <Card className={classes.card} onClick={() => inputRef.current.focus()}>
            <CardActions>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <NumberTextField
                            key={`NumberTextField_${props.direction}`}
                            autoFocus
                            unit={dimensionUnit}
                            label={directionLabel}
                            value={newDim}
                            onChange={(value) => handleParcelDimensionChange(value)}
                            resetValue={resetTextField}
                            inputProps={{ ref: inputRef }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleParcelDimensionChange(newDim);
                                    props.onClose();
                                }
                            }}
                            error={error}
                            helperText={error ? 'Dimension invalid' : ''}
                        />
                        <TextField
                            id="unit"
                            select
                            label="Select unit"
                            value={dimensionUnit}
                            onChange={(event) => {
                                inputRef.current.focus();
                                setDimensionUnit(event.target.value);
                            }}
                            SelectProps={{
                                // For some unknown reason, focusing it immediately doesn't work --> HACK
                                onClose: () => setTimeout(() => inputRef.current.focus(), 10)
                            }}
                        >
                            {Object.keys(Units).map((key) => (
                                <MenuItem
                                    key={Units[key].value}
                                    value={Units[key].value}
                                >
                                    {Units[key].text}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className={classes.buttons}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={e => {
                                e.stopPropagation();
                                const originalDim = getDimension(originalParcel, props.direction);
                                handleParcelDimensionChange(originalDim)
                                props.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={e => {
                                e.stopPropagation();
                                props.onClose();
                            }}
                            disabled={error}
                        >
                            Accept
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    card: {
        width: "550px",
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
});

export default withStyles(styles)(LMCParcelDimensionTransformCard);
