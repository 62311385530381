import { cargoSpaceTypes } from '../Constants';

export const getWeightDistributionData = function(cargoSpace) {
    //Helpers
    const storageDim = cargoSpace.storages[0].dimensions;
    const storageWeightLimit = cargoSpace.storages[0].weightLimit;

    //TODO: Units: mm vs m, g vs kg vs mt !?
    const cog = calculateCenterOfGravity(cargoSpace);
    const cogDiff = getCoGDiff(cog, storageDim);
    const maxWeightForPackedWidth = maxWeightForWidth(storageWeightLimit, storageDim.x, cargoSpace.usedLength);
    const pWeightPackedAdjusted = !storageWeightLimit ? 0 : ((cargoSpace.packedWeight / maxWeightForPackedWidth) * 100)//TODO: .toFixed(1);

    let weightData = {
        maxPayload: storageWeightLimit,
        totalUsedLength: cargoSpace.usedLength / 1000,
        correctedMaxPayload: maxWeightForPackedWidth,
        packedPayload: cargoSpace.packedWeight,
        //TODO: Return as % or decimal?
        packedPayloadPercentage: pWeightPackedAdjusted,
        cog: cog,
        cogDiff: cogDiff
    };

    //TODO: What to do about a cargo space with no type? The line below just defaults to 'container'.
    const cargoSpaceType = cargoSpace.storages[0].cargoSpaceType || cargoSpaceTypes.container;
    if (cargoSpaceType === cargoSpaceTypes.container) {

        //TODO: Replace with a real rule
        const maxXDeviation = storageDim.x > 10000 ? 0.9 : 0.6;

        const permissibleCogDiff = {
            x: maxXDeviation,
            y: null,
            z: null
        };
        weightData = {...weightData, permissibleCogDiff};
    }
    else if (cargoSpaceType === cargoSpaceTypes.trailer){
        const axles = cargoSpace.storages[0].axles;
        const axleWeights = calculateAxleWeights(cog, axles.map(x => x.location), cargoSpace.packedWeight)
        const weightFront = axleWeights[0];
        const weightRear = axleWeights[1];

        const axlesInfo = [
            {
                payload: weightFront,
                weightLimit: axles[0].weightLimit,
                payloadPercentage: (weightFront / axles[0].weightLimit) * 100
            },
            {
                payload: weightRear,
                weightLimit: axles[1].weightLimit,
                payloadPercentage: (weightRear / axles[1].weightLimit) * 100
            }
        ];

        weightData = {...weightData, axlesInfo};
    }
    return weightData;
};

const calculateCenterOfGravity = (cargoSpace) => {
    let massCenter = { x: 0, y: 0, z: 0 }
    let totalMass = 0;

    const dims = ['x', 'y', 'z']
    cargoSpace.packedParcels.forEach(p => {
        const w = p.weight
        totalMass += w
        dims.forEach(d => {
            // Parcel's CoG
            const parcelCoGDim = p.location[d] + p.packedDimensions[d] / 2;
            massCenter[d] += w * parcelCoGDim
        })
    })
    dims.forEach(d => { massCenter[d] *= (1 / totalMass) })
    return massCenter
}

const getCoGDiff = (coG, storageDim) => {
    const dims = ['x', 'y', 'z'];
    const relativeCoG = dims.reduce((a, b) => {
        const diff = ((coG[b] - storageDim[b] / 2) / 1000)//TODO: .toFixed(2);
        return { ...a, [b]: diff }
    }, {})
    return relativeCoG
}

const maxWeightForWidth = (maxCrossWeight, palletWidth, packedWidth) => {
    const maxWeight = 0.4334 * Math.pow(Math.E, 0.831 * packedWidth / palletWidth);
    return maxWeight * maxCrossWeight;
}

const calculateAxleWeights = (coG, axles, totalWeight) => {
    const frontAxle = Math.min(axles[0], axles[1]);
    const rearAxle = Math.max(axles[0], axles[1]);

    const wheelBase = rearAxle - frontAxle;
    if (wheelBase === 0 || isNaN(coG.x))
        return [0, 0];

    const cgf = Math.max(0, coG.x - frontAxle);
    const weightRear = (totalWeight * cgf) / wheelBase
    const weightFront = totalWeight - weightRear
    return [weightFront, weightRear]
}
