export const reOptimizingStatus = {
    notActive: 0,
    ready: 1,
    error: 2
}

export const solutionPackingStatus = {
    undefined: 0,
    readyToPack: 1,
    inProgress: 2,
    packed: 3,
    recalculating: 4,
}

export const parcelPackingStatus = {
    notPacked: 0,
    selected: 1,
    packed: 2
}

export const lmcStatus = {
    none: 0,
    preparing: 1,
    recalculating: 2,
    ready: 3,
    locked: 4,
}

export const lmcTypes = {
    undefined: 0,
    single: 1,
    multi: 2
}

export const lmcStrategies = {
    undefined: 'Undefined',
    none: 'None',
    restrictToSingle: 'RestrictToSingle',
    distributeFreely: 'DistributeFreely'
}
