import { addWeightDistributionImage } from "../core/WeightDistributionDialog/weightDistributionDialog";
import threeEntryPoint from "../three/threeEntryPoint";
import { createPdf, replaceAll } from "./pdfUtils";
import { cloneDeep } from 'lodash'
import { convertSolutionToDisplaySolution } from "../core/common-helpers";

// const PDF_LIMIT = 50; // TODO
// showOverlay, hideOverlay from AppActions

/**
 *
 * @param {*} solution - Full loading plan
 * @param {[Number]} cargoSpaceIndex - list of cargoSpace indexes to export
 * @param {*} organization - Organization of the user, used for displaying the logo
 * @param {Boolean} cargoList - include cargo lists in the pdf
 * @param {Boolean} overViewPage - include over view image pages in the export
 * @param {Boolean} stepByStepPage - include step by step pages in the export
 * @param {Boolean} weightPage - include over weight distribution page in the export
 * @param {function (Number) -> void} onProgressUpdate - callback function for progress updates
 * @param {function () -> void} onFinish - callback function when export is finished
 */
export const asyncPdfExport = async ({ solution, cargoSpaceIndexes, organization, cargoList, overViewPage, stepByStepPage, weightPage, onProgressUpdate, onFinish }) => {
    if (cargoSpaceIndexes.some(idx => solution.cargoSpaces[idx].packedParcels.some(parcel => parcel.packedDimensions.r > 0))) {
        throw new Error("Cylinders are not implemented in PdfExport");
    }

    const displaySolution = convertSolutionToDisplaySolution(solution);

    const three = threeEntryPoint({
        style: { width: 3400, height: 1200 },
        noBorders: true,
        preventResize: true
    })

    // TODO cargpSpaceIndexes length and an error message to shown ?

    const assetImages = weightPage ? [
        await LoadImage('container_side_blue'),
        await LoadImage('container_rear_blue'),
        await LoadImage('boxbot_hex')
    ] : [];

    const overViewImageDatas = {};
    const stepByStepDatas = {};
    const weightImageDatas = {};

    // Estimate the amount of images needed for the pdf export
    const len = cargoSpaceIndexes.length;
    let overViewPageCount = (overViewPage ? 1 : 0) * len;
    let weightPageCount = (weightPage ? 1 : 0) * len;
    let stepByStepImagesCount = stepByStepPage ? cargoSpaceIndexes.reduce((sum, idx) => sum += Math.ceil(displaySolution.cargoSpaces[idx].packedParcels.length / 2.0), 0) : 0;


    let totalPages = overViewPageCount + weightPageCount + stepByStepImagesCount;
    let createdPages = 0;

    const updateProgressAndNotify = () => {
        createdPages++;
        onProgressUpdate(Math.min(100, 100 * createdPages / totalPages));
    }

    for (let index = 0; index < cargoSpaceIndexes.length; index++) {
        const idx = cargoSpaceIndexes[index];

        if (overViewPage) {
            let overViewImage = LoadOverViewImage(displaySolution, idx, three);
            overViewImageDatas[idx] = overViewImage;

            // Notify about updated progress
            updateProgressAndNotify();
        }

        if (stepByStepPage) {
            let stepByStepData = await LoadStepByStepData(displaySolution, idx, three, updateProgressAndNotify);
            stepByStepDatas[idx] = stepByStepData;
        }

        if (weightPage) {
            let weightData = addWeightDistributionImage(displaySolution, idx, assetImages);
            weightImageDatas[idx] = weightData;

            // Notify about updated progress
            updateProgressAndNotify();
        }
    }

    three.dispose();

    const pdfData = await createPdf(cargoSpaceIndexes, cargoList, overViewImageDatas, stepByStepDatas, weightImageDatas, displaySolution, organization);

    // Create a filename
    const timestamp = replaceAll(replaceAll(new Date().toISOString(), ':', ''), '-', '');
    const cargospace = cargoSpaceIndexes.length === 1 && displaySolution.cargoSpaces.length > 1 ? ` cargo space ${cargoSpaceIndexes[0] + 1} of ${displaySolution.cargoSpaces.length}` : '';
    const filename = `${displaySolution.name}_${timestamp}${cargospace}.pdf`;

    // Create a link and click it
    var a = document.createElement("a"); //Create <a>
    a.href = pdfData; //Image Base64 Goes here
    a.download = filename; //File name Here
    a.click(); //Downloaded file

    onFinish();
}

const LoadImage = function (src) {
    return new Promise(resolve => {
        let img = new Image();
        img.id = src;
        img.src = '/assets/' + src + '.png';
        img.onload = () => {
            resolve(img);
        }
    })
}

const LoadOverViewImage = (solution, index, three) => {
    three.clear();
    three.drawCargoSpace(solution, index);
    // Ratio W/H: 40'=5, 20'=2.5, 0.3<=pal<=1.2
    const aspectRatio = solution.cargoSpaces[index].storages[0].dimensions.x / solution.cargoSpaces[index].storages[0].dimensions.z
    const axles = solution.cargoSpaces[index].storages[0].axles
    const images = three.exportThree2(aspectRatio, axles)
    return ({ images, aspectRatio })
}

const sleep = (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

const LoadStepByStepData = async (solution, cargoSpaceIndex, three, updateProgressAndNotify) => {
    three.clear();

    let orderedParcelList = solution.cargoSpaces[cargoSpaceIndex].packedParcels;

    // Sort parcel list. The list should be sorted already, but this is done just in case to ensure that there aren't any bugs in the future.
    orderedParcelList = orderedParcelList.sort((a, b) => a.loadingOrder - b.loadingOrder);

    // Create a copy of the solution, clear all parcels and alternate arrangements and draw empty cargo space
    let sol = cloneDeep(solution);
    sol.cargoSpaces[cargoSpaceIndex].packedParcels = [];
    three.drawCargoSpace(sol, cargoSpaceIndex)
    // TODO These need to be eased somehow, the 'setLoadingDirection' call goes to threeEntryPoint -> SceneManager -> CameraHelper.setLoadingDirection
    // three.setLoadingDirection(sol.cargoSpaces[cargoSpaceIndex].storages[0].loadingDirection)

    // Default camera view is from 'FRONT' - we wan't to view it from 'BACK'
    const aspectRatio = solution.cargoSpaces[cargoSpaceIndex].storages[0].dimensions.x / solution.cargoSpaces[cargoSpaceIndex].storages[0].dimensions.z
    const axles = solution.cargoSpaces[cargoSpaceIndex].storages[0].axles
    three.setCameraForStepByStep(aspectRatio, axles);

    // Map the ordered parcel list to two-dimensional array up to 3 parcels per sub-array.
    const parcelList = orderedParcelList.reduce((array, currentValue) => {
        if(array.length === 0 || array[array.length-1].length === 3)
            array.push([]);

        array[array.length-1].push(currentValue);

        return array;
    }, []);

    const data = []

    for (let index = 0; index < parcelList.length; index++) {
        const parcels = parcelList[index];

        // Add new parcels to three with StepByStep colors
        let colorDictionary = three.addParcelsStepByStep(parcels);
        // Create a image of the current three
        let imgData = three.exportThree(false);
        // Set the parcels into gray
        three.grayScaleParcels(parcels);
        // Add image and parcels to stepByStepData
        data.push({ imgData, parcels, colorDictionary })
        // Notify about update
        updateProgressAndNotify();
        // Sleep for 10 ms to allow progress updates to render
        await sleep(10);
    }

    // Swap the camera back to normal
    three.resetCamera();
    return data;
}
