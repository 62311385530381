import * as THREE from "three";

export const arrowDirections = {
    xPlus: 'xPlus',
    xMinus: 'xMinus',
    yPlus: 'yPlus',
    yMinus: 'yMinus',
    zPlus: 'zPlus'
}

export default (pos, dim, material, id, rotation) => {

    /**
     * CylinderBufferGeometry dimensions:
     * dim.r = cylinder bottom/top radius
     * dim.height = cylinder height
     *
     * arrowStem is 80% of the total height, and arrowHead is 20%
     *
     * CylinderBufferGeometry radialSegments is set to 32 to make the cylinders smoothly round
     */
    let arrowStem = new THREE.CylinderBufferGeometry(
        dim.r,
        dim.r,
        dim.height * 0.6,
        32
    );
    let arrowHead = new THREE.CylinderBufferGeometry(
        0,
        dim.r * 2,
        dim.height * 0.4,
        32
    );


    // Determine arrow object rotation direction and amount
    if (rotation != null) {
        if (rotation.direction === "x") {
            arrowStem.rotateX(rotation.amount * Math.PI);
            arrowHead.rotateX(rotation.amount * Math.PI);
        }
        if (rotation.direction === 'z') {
            arrowStem.rotateZ(rotation.amount * Math.PI);
            arrowHead.rotateZ(rotation.amount * Math.PI)
        }
    }


    // Set up the mesh for arrowStem
    let arrowStemMesh = new THREE.Mesh(arrowStem, material);
    arrowStemMesh.position.x = pos.x;
    arrowStemMesh.position.y = pos.y;
    arrowStemMesh.position.z = pos.z;
    arrowStemMesh.arrowId = id.toString() + "_STEM";
    arrowStemMesh.name = 'arrowStem'

    // Set up the mesh for arrowHead
    let arrowHeadMesh = new THREE.Mesh(arrowHead, material);
    arrowHeadMesh.position.x = pos.x;
    arrowHeadMesh.position.y = pos.y + dim.height / 2;
    arrowHeadMesh.position.z = pos.z;

    if (rotation != null) {
        if (rotation.direction === 'x') {
            arrowHeadMesh.position.x = pos.x;
            arrowHeadMesh.position.y = pos.y;
            arrowHeadMesh.position.z =
                rotation.amount < 1
                    ? pos.z + dim.height / 2
                    : pos.z - dim.height / 2;
        }
        if (rotation.direction === 'z') {
            arrowHeadMesh.position.x =
                rotation.amount < 1
                    ? pos.x - dim.height / 2
                    : pos.x + dim.height / 2;
            arrowHeadMesh.position.y = pos.y;
            arrowHeadMesh.position.z = pos.z;
        }
    }

    arrowHeadMesh.arrowId = id.toString() + "_HEAD";
    arrowHeadMesh.name = 'arrowHead';

    function getMesh() {
        return [arrowStemMesh, arrowHeadMesh];
    }

    return {
        getMesh,
    };
};
