import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'

import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import DialogTitle from '../core/DialogTitle'
import { notificationDialogSeverity } from '../core/Constants'

import { connect } from 'react-redux'
import { hideNotificationDialog } from './AppActions'

//////////////////////////////////////////////////////////////////////////////

export const NotificationDialog = (props) => {

    const {
        classes,
        reduxState,
        reduxDispatch
    } = props;

    const [latestTitle, setLatestTitle] = useState(null);
    const [latestSeverity, setLatestSeverity] = useState(0);
    const [latestText, setLatestText] = useState(null);


    /**
     * Store latest text, title and severity.
     * This is done so that when user closes the dialog, the states don't get set to nulls.
     * Setting the states to nulls would make the dialog contents disappear before the dialog has closed completely.
     */
    useEffect(() => {
        // undefined = text property is not assigned correctly
        if (reduxState.text === undefined)
            console.error('ShowNotificationDialog(text) "text" property is not assigned correctly. Expecting null or a string value.')

        // null = text property is empty in purpose
        if (reduxState.text !== null) {
            setLatestText(reduxState.text)
        }
        if (reduxState.title) {
            setLatestTitle(reduxState.title)
        }
        if (reduxState.severity) {
            if (
                reduxState.severity > notificationDialogSeverity.error ||
                reduxState.severity < notificationDialogSeverity.none
            ) {
                setLatestSeverity(notificationDialogSeverity.none)
            } else {
                setLatestSeverity(reduxState.severity)
            }
        }
    }, [reduxState.text, reduxState.title, reduxState.severity])


    /**
     * Initialize dialog title.
     * Include an icon with the title, icon depends on given message severity.
     * If severity == none, then show no icon.
     */
    const dialogTitleWithIcon = (
        <Box className={classes.titleContainer}>
            {
                (
                    latestSeverity === notificationDialogSeverity.none ||
                    latestSeverity == null
                ) &&
                <></>
            }
            {latestSeverity === notificationDialogSeverity.success &&
                <CheckIcon className={classes.iconStyle} style={{ color: 'green' }} />
            }
            {latestSeverity === notificationDialogSeverity.info &&
                <InfoIcon className={classes.iconStyle} style={{ color: 'blue' }} />
            }
            {latestSeverity === notificationDialogSeverity.warning &&
                <WarningIcon className={classes.iconStyle} style={{ color: 'orange' }} />
            }
            {latestSeverity === notificationDialogSeverity.error &&
                <ErrorIcon className={classes.iconStyle} style ={{ color: 'red' }} />
            }
            <DialogTitle
                onClose={props.onHide}
                className={latestSeverity === notificationDialogSeverity.none ? '' : classes.title}
            >
                {latestTitle != null ?
                    latestTitle
                    :
                    'Error'
                }
            </DialogTitle>
        </Box>
    )


    return (
        <Dialog
            open={Boolean(reduxState.text)}
            keepMounted
            onClose={reduxDispatch.onHide}
            style={{ zIndex: 9999 }}
        >
            {dialogTitleWithIcon}
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'break-spaces' }}>
                    {latestText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={reduxDispatch.onHide} color='primary'>
                    {'OK'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    iconStyle: {
        height: '40px',
        width: '40px',
        marginRight: '4px',
        marginLeft: '16px'
    },
    title: {
        paddingLeft: '0px'
    }
})

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    reduxState: {
        text: state.notificationDialog.text,
        title: state.notificationDialog.title,
        severity: state.notificationDialog.severity,
    }
})

const mapDispatchToProps = (dispatch) => ({
    reduxDispatch: {
        onHide: () => dispatch(hideNotificationDialog()),
    }
})

//////////////////////////////////////////////////////////////////////////////

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationDialog))
