import React from 'react'

import LMCSuccessDialog from '../LMCComponents/LMCSuccessDialog'
import LMCFailedDialog from '../LMCComponents/LMCFailedDialog'
import LMCDistributeFailedDialog from '../LMCComponents/LMCDistributeFailedDialog'
import { lmcStatus, lmcTypes } from '../Constants'

import PropTypes from 'prop-types'

//////////////////////////////////////////////////////////////////////////////

const LMCResultHandler = (props) => {

    // Extract variables
    const thisCargoSpace = props.solution?.cargoSpaces[props.solution?.cargoSpaceIndex];
    const thisLMC = thisCargoSpace?.lastMinuteChange;

    // Extract states
    const isFinishedLMC = thisLMC?.status === lmcStatus.ready;
    const isThisUser = thisLMC?.user === props.user._id.toString();
    const isError = thisLMC?.error;
    const isFail = thisLMC?.failure;

    // Convert states into dialog open values
    const showSuccess = !(isError || isFail) && isFinishedLMC && isThisUser;
    const showError = isError && isThisUser;
    const showSingleFailed = isFail && isThisUser && thisLMC?.lmcType === lmcTypes.single;
    const showMultiFailed = isFail && isThisUser && thisLMC?.lmcType === lmcTypes.multi;

    if (showMultiFailed) {
        throw new Error("This should never happen");
    }


    return (
        <>
            <LMCSuccessDialog
                open={Boolean(showSuccess)}
                onClose={props.cancelCallback}
                onUpdate={props.confirmCallback}
                solution={props.solution}
                changeLMCNewCargoSpaceSelectedArrangement={(index, newPackingGoal) => props.changeLMCNewCargoSpaceSelectedArrangement(thisCargoSpace, index, newPackingGoal)}
            />
            <LMCFailedDialog
                open={Boolean(showSingleFailed)}
                onClose={props.cancelCallback}
                onCalculate={props.onRecalculate}
            />
            <LMCDistributeFailedDialog
                open={Boolean(showError)}
                onClose={props.cancelCallback}
            />
        </>
    )
}

LMCResultHandler.propTypes = {
    user: PropTypes.any.isRequired,
    solution: PropTypes.any,
    onRecalculate: PropTypes.func.isRequired,
}

//////////////////////////////////////////////////////////////////////////////

export default LMCResultHandler;
