import React, { useState } from 'react'

import Octagon from '../../core/Octagon'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import OptimizerCargoDataTable from '../cargoData/OptimizerCargoDataTable'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import SettingsIcon from '@material-ui/icons/Settings';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import Units from '../../core/Units/Units'
import OptimizerHeader from '../OptimizerHeader'
import DropzoneArea from '../cargoData/DropzoneArea'
import DeletedView from '../cargoData/DeletedView'
import NextPageButton from '../components/NextPageButton'
import { colors } from '../../theme'
import { excel } from '../../api'
import { getFirstInvalidParcel } from '../../core/Validations'
import { setCargoDataUnit } from '../../core/Units/UnitActions'
import { setDeletedParcels } from '../OptimizeActions'
import { sendModes, importModes, planStates, notificationDialogSeverity } from '../../core/Constants'
import { stepLabels } from '../Optimize'

import { downloadTemplate, exportParcelExcel } from '../../core/DownloadExcelTemplate'
import { showNotificationDialog } from '../../app/AppActions'


//////////////////////////////////////////////////////////////////////////////


const erpIcon = <CloudUploadIcon />

const appendLabel = (
    <span style={{ display: "flex", alignItems: "baseline" }}>
        <Typography variant="h6">
            <b>Yes</b>,
        </Typography>
        <Typography variant="body1">
            keep existing data
        </Typography>
    </span>
)

const overWriteLabel = (
    <span style={{ display: "flex", alignItems: "baseline" }}>
        <Typography variant="h6">
            <b>No</b>,
        </Typography>
        <Typography variant="body1">
            overwrite existing data
        </Typography>
    </span>)


//////////////////////////////////////////////////////////////////////////////


const OptimizerCargoView = (props) => {
    const [selectedRow, setSelectedRow] = useState(null)
    const [child, setChild] = useState(null)
    const [refToFileInput, setRefToFileInput] = useState(null)
    const [importDialog, setImportDialog] = useState(null)
    const [importMode, setImportMode] = useState(importModes.append)
    const [settingsAnchor, setSettingsAnchor] = useState(null)
    const [exportAnchor, setExportAnchor] = useState(null)
    const [importAnchor, setImportAnchor] = useState(null)
    const [showDeleted, setShowDeleted] = useState(false);

    const {
        infoDialog,
        setInfoDialog,
        onSetCargoDataUnit,
        name,
        checkUnpackableParcels,
        unpackable,
        setUnpackable,
        disableChanges,
        classes,
        parcels,
        setParcels,
        deletedParcels,
        onSetDeletedParcels,
        sendMode,
        setSendMode,
        planState,
        onShowNotificationDialog
    } = props

    const {
        cargoDataUnit,
        optimizer
    } = props.reduxState;

    const { storages } = optimizer;

    const isSettingsOpen = Boolean(settingsAnchor)
    const isExportOpen = Boolean(exportAnchor)
    const isImportOpen = Boolean(importAnchor)


    const openDeleted = () => setShowDeleted(true)

    const closeDeleted = () => setShowDeleted(false)

    const excelIcon = <FontAwesomeIcon icon={faFileExcel} style={{ color: "green", fontSize: "1.7rem", padding: "0px 3px 3px 3px" }} />

    const importParcelExcel = (fileInput, close) => {
        fileInput.click()
        if (close) close()
    }

    const importMenu = (
        <Menu
            anchorEl={importAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isImportOpen}
            onClose={() => setImportAnchor(null)}
        >
            <List>
                <ListItem>
                    <Button
                        disabled={sendMode === sendModes.active || disableChanges}
                        onClick={() => importParcelExcel(refToFileInput, setImportAnchor)}
                        startIcon={excelIcon}
                    >Import Excel</Button>
                </ListItem>
                <ListItem>
                    <Button disabled startIcon={<InsertDriveFileIcon />}
                    >Import CSV</Button>
                </ListItem>
                <ListItem>
                    <Button disabled startIcon={erpIcon}
                    >Import from ERP</Button>
                </ListItem>
            </List>
        </Menu>
    )

    const exportMenu = (
        <Menu
            anchorEl={exportAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isExportOpen}
            onClose={() => setExportAnchor(null)}
        >
            <List>
                <ListItem style={{ fontWeight: "bold" }}>Exports:</ListItem>
                <ListItem>
                    <Button
                        startIcon={excelIcon}
                        onClick={() => exportParcelExcel(parcels, `${name} cargo list`, cargoDataUnit, setExportAnchor)}>
                        Cargo List
                    </Button>
                </ListItem>
                <ListItem style={{ fontWeight: "bold" }}>Templates:</ListItem>
                <ListItem>
                    <Button
                        startIcon={excelIcon}
                        onClick={() => downloadTemplate("mm", setExportAnchor)}>
                        Excel template (mm)
                    </Button>
                </ListItem>
                <ListItem>
                    <Button
                        startIcon={excelIcon}
                        onClick={() => downloadTemplate("cm", setExportAnchor)}>
                        Excel template (cm)
                    </Button>
                </ListItem>
            </List>
        </Menu>
    )

    const settingsMenu = (
        <Menu
            anchorEl={settingsAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isSettingsOpen}
            onClose={() => setSettingsAnchor(null)}
        >
            <List>
                <ListItem>Cargo data</ListItem>
                <ListItem>
                    Units:
                    <ToggleButtonGroup style={{ marginLeft: "16px" }} value={cargoDataUnit} exclusive onChange={(event, newUnit) => onSetCargoDataUnit(newUnit)}>
                        {Object.keys(Units).map(u => (
                            <ToggleButton key={Units[u].text} value={Units[u]} selected={Units[u].value === cargoDataUnit.value}>
                                {Units[u].text}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </ListItem>
            </List>
        </Menu>
    )

    const overWriteDialog = (
        <Dialog
            open={Boolean(importDialog)}
            classes={{ paper: classes.overWriteDialog }}
        >
            <DialogTitle onClose={() => setImportDialog(null)}>
                Keep existing data?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Loading plan includes some data. Do you want to keep existing data?
                </DialogContentText>
                <FormControl component="fieldset">
                    <RadioGroup
                        value={importMode}
                        onChange={e => setImportMode(Number.parseInt(e.target.value))}
                    >
                        <FormControlLabel
                            value={importModes.append}
                            control={
                                <Radio color="primary" />
                            }
                            label={appendLabel}
                        />
                        <FormControlLabel
                            value={importModes.overWrite}
                            control={
                                <Radio color="primary" />
                            }
                            label={overWriteLabel}
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={() => setImportDialog(null)}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        handleFileRead(importDialog.file)
                        setImportDialog(null)
                    }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

    const handleFileRead = (inputFile) => {
        let fr = new FileReader();
        fr.onload = e => processExcel(e.target.result);
        fr.readAsArrayBuffer(inputFile)
    }

    const loadFile = (file) => {
        if (typeof window.FileReader !== 'function') {
            alert("The file API isn't supported on this browser yet.");
            return;
        }

        let input = document.getElementById('fileinput');
        if (!file) {
            if (!input) {
                alert("Um, couldn't find the fileinput element.");
            }
            else if (!input.files) {
                alert("This browser doesn't seem to support the 'files' property of file inputs.");
            }
            else if (!input.files[0]) {
                alert("Please select an Excel file before clicking 'Process'");
            }
        }


        const selectedFile = input.files[0] || file

        input.files = null // Without these the second upload try won't fire the onChange event
        input.value = ''

        if (parcels.length) {
            setImportDialog({
                open: true,
                file: selectedFile
            })
            return
        }
        handleFileRead(selectedFile)

    }

    const processExcel = (data) => {
        setSendMode(sendModes.active)
        excel.create({ data: Array.from(new Uint8Array(data)) })
            .then(res => {
                const base = importMode === importModes.append ? parcels.length + 1 : 0
                let parcs = res.parcels.map((parcel, i) => {
                    parcel.tableData = { id: base + i }
                    return parcel
                });
                const newParcels = importMode === importModes.append ? parcels.concat(parcs) : parcs

                checkUnpackableParcels(newParcels, null, storages[0].dimensions, storages[0].weightLimit) // Uses setParcels internally
                onSetCargoDataUnit(res.unit)
                setSendMode(sendModes.ready)
                if (selectedRow) setSelectedRow(null)
                if (child) setChild(null)

                // TODO Disabled due to Cylinders
                const invalidParcel = getFirstInvalidParcel(newParcels)
                if (invalidParcel) {
                    setSendMode(sendModes.error)
                    onShowNotificationDialog(`Parcel with id ${invalidParcel.id} has invalid data: ${invalidParcel.field} ${invalidParcel.message}`, 'Invalid parcel data', notificationDialogSeverity.warning)
                }
                if (res.warnings.length > 0) {
                    onShowNotificationDialog(res.warnings, 'Warning', notificationDialogSeverity.warning)
                }
            })
            .catch(err => {
                let errorMessage = err.message;
                let errorTitle = 'Error'
                if (errorMessage.startsWith("Cannot read")) {
                    errorMessage = "Improperly formatted Excel file.";
                }
                else if (err.code === 500 && errorMessage.startsWith("Can't find end of central directory")) {
                    errorMessage = "Input format is not supported. Please select an Excel file (.xlsx)"
                }
                else if (err.name === 'ExcelImportError') {
                    errorTitle = 'Excel-file import failed'
                }
                setSendMode(sendModes.error)
                onShowNotificationDialog(errorMessage, errorTitle, notificationDialogSeverity.error)
            });
    }


    /**
     * Restore parcel from deleted parcels to the loading plan.
     * First make sure that the parcel user is restoring has an unique tableData.id.
     * Then if parcel has tableData.deleting-field, remove the field. This is done to
     * ensure that when the parcel is restored, the delete-overlay isn't displayed immediately.
     * Finally, add parcel to existing parcels and validate the set of parcels in the loading plan using
     * checkUnpackableParcels(). Also, remove the restored parcel from deletedParcels-state.
     * @param {Object} parcel
     */
    const unDeleteParcel = parcel => {

        const nonDeletedIdList = parcels.map(x => x.tableData.id);
        const deletedIdList = deletedParcels.map(x => x.tableData.id);
        while (nonDeletedIdList.includes(parcel.tableData.id) || deletedIdList.includes(parcel.tableData.id)) {
            parcel.tableData.id = parseInt(parcel.tableData.id) + 1
        };

        if (parcel.tableData.deleting) delete parcel.tableData.deleting
        const newParcels = parcels.concat(parcel)
        checkUnpackableParcels(newParcels, null, storages[0].dimensions, storages[0].weightLimit) // Re-sets parcels internally

        onSetDeletedParcels(deletedParcels.filter(p => p.tableData.id !== parcel.tableData.id))
    }

    const deletedView = showDeleted && <DeletedView z={props.z - 1} deletedParcels={deletedParcels} unDeleteParcel={unDeleteParcel} close={closeDeleted} />

    const isThisRowSelected = (i) => selectedRow === i;

    const buttonAndIndicator = () => {
        let text;
        if (disableChanges && planState !== planStates.loading) {
            text = <div style={{ width: '100%', height: '40px', lineHeight: '48px', fontSize: "1.17em", fontWeight: "bold", textAlign: 'center' }}>{'Calculating loading plan. Editing loading plan disabled.'}</div>
        } else if (planState === planStates.loading) {
            text = <div style={{ width: '100%', height: '40px', lineHeight: '48px', fontSize: "1.17em", fontWeight: "bold", textAlign: 'center' }}>{'Loading plan sent to Loading Assistant. Editing loading plan disabled.'}</div>
        }

        return <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Button style={{ width: "120px" }} startIcon={<PublishIcon />} variant="contained" color="primary" onClick={event => setImportAnchor(event.currentTarget)}>
                Upload
            </Button>
            <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 10rem)' }} >
                {text}
            </div>
            <Button style={{ width: "140px" }} startIcon={<GetAppIcon />} variant="contained" color="secondary" onClick={event => setExportAnchor(event.currentTarget)}>
                Download
            </Button>
            <div>
                <IconButton onClick={event => setSettingsAnchor(event.currentTarget)}>
                    <SettingsIcon />
                </IconButton>
                <Tooltip title='Deleted items'>
                    <span><IconButton disabled={deletedParcels.length < 1} onClick={() => openDeleted()}>
                        {deletedParcels.length < 1 ?
                            <DeleteOutlineIcon /> // If deletedParcels.length === 0, show DeleteOutlineIcon
                            :
                            // If deletedParcels.length > 0, show DeleteIcon with a badge that shows deletedParcel.length
                            <Badge classes={{ badge: classes.deletedBadge }} badgeContent={deletedParcels.length}>
                                <DeleteIcon />
                            </Badge>
                        }
                    </IconButton>
                    </span>
                </Tooltip>
            </div>
        </div>;
    };


    const setErrorWithAutoSendmode = (message) => {
        onShowNotificationDialog(message, 'Error', notificationDialogSeverity.error)
        if (message) {
            setSendMode(sendModes.error)
        } else if (sendMode === sendModes.error) {
            setSendMode(sendModes.ready)
        }
    }

    // TODO: Move to CargoDataTableBody (or should this component remain as a prop for ParcelTableEdit?)
    const excelDropzone = (
        <DropzoneArea
            dropzoneText={'Drag an Excel file here to import cargo list.'}
            acceptedFiles={['.xls', '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
            onDrop={(files) => loadFile(files[0])}
            onDropRejected={(files) => {
                if (files.length > 1)
                    onShowNotificationDialog('Only one file at a time is allowed', 'Too many files', notificationDialogSeverity.warning)
                else
                    onShowNotificationDialog('Please select an Excel file (.xlsx)', 'Wrong file type', notificationDialogSeverity.warning)
                setSendMode(sendModes.error)
            }}

        />
    )

    return (
        <Octagon child={deletedView} z={props.z} w='65rem' color={colors.octagon} isOpen={props.octagonOpen}>
            <div className={classes.octagonContainer}>
                <OptimizerHeader progress={props.progress} />
                <div className={classes.viewContainer} >
                    <div >
                        {buttonAndIndicator()}
                    </div>
                    <div style={{ marginTop: '1.5rem', marginBottom: '0.5rem', height: 'calc(100% - 74px)' }}>
                        <OptimizerCargoDataTable
                            parcels={parcels}
                            isThisRowSelected={isThisRowSelected}
                            setData={setParcels}
                            setSelectedRow={setSelectedRow}
                            isLoading={sendMode === sendModes.active}
                            infoDialog={infoDialog}
                            setInfoDialog={setInfoDialog}
                            cargoDataUnit={cargoDataUnit}
                            setErrorMessage={setErrorWithAutoSendmode}
                            checkUnpackableParcels={checkUnpackableParcels}
                            unpackable={unpackable}
                            setUnpackable={setUnpackable}
                            disabled={disableChanges}
                            excelDropzone={excelDropzone}
                            deletedRows={deletedParcels}
                            setDeleted={onSetDeletedParcels}
                        />
                    </div>
                </div>
                <NextPageButton
                    tooltipText="Cargo space can't be empty"
                    nextButtonProps={props.nextButtonProps}
                >
                    {stepLabels.cargoSpace}
                </NextPageButton>
            </div>
            <input
                type='file'
                id='fileinput'
                style={{ display: 'none' }}
                accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                ref={elem => setRefToFileInput(elem)}
                onChange={() => loadFile()}
            />
            {overWriteDialog}
            {settingsMenu}
            {exportMenu}
            {importMenu}
        </Octagon >
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        reduxState: {
            cargoDataUnit: state.unit.cargoData,
            optimizer: state.optimizer,
        }
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowNotificationDialog: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity))
        },
        onSetCargoDataUnit: unit => {
            if (!unit) return
            dispatch(setCargoDataUnit(unit))
        },
        onSetDeletedParcels: parcels => {
            dispatch(setDeletedParcels(parcels));
        }
    };
}

const styles = theme => ({
    errorTextStyle: theme.errorTextStyle,
    statusTextStyle: { display: 'inline-block', verticalAlign: 'middle' },
    octagonContainer: theme.containers.octagonChild,
    viewContainer: theme.optimizer.viewContainer,
    deletedBadge: { color: theme.palette.common.white, backgroundColor: colors.bbOrange },
    textStyle: theme.textStyle,
    overWriteDialog: { width: '420px' }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OptimizerCargoView))

//////////////////////////////////////////////////////////////////////////////
