import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';

import get from 'lodash.get';

//////////////////////////////////////////////////////////////////////////////

const CargoSpacesTableFooter = (props) => {
    const { classes } = props;

//////////////////////////////////////////////////////////////////////////////

    return (
        <TableHead className={classes.totalColumnsRow}>
            <TableRow key={'totalColumnsRow'} className={classes.footerRow}>
                {props.solution != null &&
                    <>
                        {/* Cargo space index -column*/}
                        <TableCell>
                            <b>Total</b>
                        </TableCell>

                        {/* Preview image -column */}
                        <TableCell />

                        {/* Packed parcels, used length, packed weight and packed volume -columns */}
                        {props.totalColumns.map((col, i) => {

                            const cellValue = col.conversion ?
                            col.conversion(get(props.solution, col.field))
                            :
                            get(props.solution, col.field)

                            return (
                                <TableCell key={i}>
                                    <b>
                                        {cellValue}
                                    </b>
                                </TableCell>
                            )
                        })}

                        {/* Cargo space dimensions -column */}
                        <TableCell />

                        {/* Packing optimization goal -goal */}
                        <TableCell />

                        {/* Export PDF -column */}
                        <TableCell />
                    </>
                }
            </TableRow>
        </TableHead>
    );

};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    totalColumnsRow: {
        position: 'sticky',
        bottom: 0,
    },
    footerRow: {
        outline: '1px lightgray solid'
    }
});


export default withStyles(styles)(CargoSpacesTableFooter);
