import React from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

const FormTextField = ({ name, label, type, onChange, error, classes, ...other }) => (
    <div className={classes.textFieldWrapper}>
        <TextField
            id={name}
            label={label}
            type={type}
            onChange={onChange}
            name={name}
            error={Boolean(error)}
            helperText={error}
            {...other}
        />
    </div>
)

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    textFieldWrapper: theme.textFieldWrapper
})

export default withStyles(styles)(FormTextField);
