import { useCallback, useEffect, useState, useRef } from 'react'


// Custom hook listening to drag & drop events
export default () => {

    const [isDragging, setIsDragging] = useState(false);
    const dragCounter = useRef(0);


    const onDragEnter = useCallback((e) => {
        // If the counter is zero before the enter happens, we know we have entered the page.
        if (dragCounter.current++ === 0) {
            setIsDragging(true);
        }

        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);


    const onDragLeave = useCallback((e) => {
        // If the counter is zero after leave happens, we know we have exited the page.
        if (--dragCounter.current === 0) {
            setIsDragging(false);
        }

        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);


    const onDrop = useCallback((e) => {
        e.preventDefault();

        dragCounter.current = 0;

        //NOTE: This causes a react Warning to appear because the dropzone is removed immediately
        //      while some functionality inside the dropzone is still processing the drop event.
        //      Thus an internal update at the end of the drop within the dropzone fails.
        //      It's only a warning and results in a harmless no-op.
        //This behavior did not originate during the changes made when this comment was added.
        //Instead this has existed since the introduction of the drag&drop feature.
        setIsDragging(false);

        return false;
    }, []);


    useEffect(() => {
        window.addEventListener('dragenter', onDragEnter);
        window.addEventListener('drop', onDrop);
        window.addEventListener('dragleave', onDragLeave);
        return () => {
            window.removeEventListener('dragenter', onDragEnter);
            window.removeEventListener('drop', onDrop);
            window.removeEventListener('dragleave', onDragLeave);
        };
    }, [onDragEnter, onDragLeave, onDrop]);


    return isDragging
}
