import * as THREE from "three";
import MeshFactory from "./MeshFactory";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import CameraHelper from "./CameraHelper";
import { disposeMaterialAndTextures } from "./ThreeUtils";

export default (options) => {
    const {
        canvas,
        zoomFactor,
        loadingDirection,
        parcelManipulationCallbacks,
    } = options;


    let hlBox;
    let containerInfos = [];
    let loadingAssistantMode = false;
    let theta = 0;

    const screenDimensions = {
        width: canvas.width,
        height: canvas.height,
    };

    const origin = new THREE.Vector3(0, 0, 0);
    const scene = buildScene();
    let renderer = buildRenderer(screenDimensions);
    const camera = buildCamera(screenDimensions);

    const cameraHelper = new CameraHelper({
        origin,
        loadingDirection: loadingDirection
    });

    let sceneSubjects = createSceneSubjects();

    const meshFactory = new MeshFactory(scene);
    const controls = new OrbitControls(camera, canvas);
    controls.addEventListener("start", () => {
        cameraHelper.clearTargets();
    });

    let hlBoxDisposeList = [];

    let pointerCoordinatesOnPointerDown = null; // Where on the screen was the mouse/pointer clicked down?
    let draggedDistance = 0; // How much dragging has happened?
    const draggedThreshold = 0.02; // How much pointer can be moved before click events are ignored?

    function buildScene() {
        const scene = new THREE.Scene();
        return scene;
    }

    function buildRenderer({ width, height }) {
        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
        });
        const dpr = window.devicePixelRatio ? window.devicePixelRatio : 1;
        renderer.setPixelRatio(dpr);
        renderer.setSize(width, height);
        renderer.outputEncoding = THREE.GammaEncoding;
        renderer.localClippingEnabled = true;
        canvas.addEventListener("pointerup", handlePointerUp);
        canvas.addEventListener("pointerdown", handlePointerDown);
        canvas.addEventListener("pointermove", onPointerMove);

        return renderer;
    }

    function buildCamera({ width, height }) {
        const aspectRatio = width / height;
        const fieldOfView = 45;
        const nearPlane = 8;
        const farPlane = 16384;
        const camera = new THREE.PerspectiveCamera(
            fieldOfView,
            aspectRatio,
            nearPlane,
            farPlane
        );

        camera.lookAt(origin);
        return camera;
    }

    function addBox(pos, dim, spaceDim, parcel) {
        sceneSubjects.push(
            dim.r > 0 ? meshFactory.createCylinder(pos, dim, spaceDim, parcel) : meshFactory.createBox(pos, dim, spaceDim, parcel)
        );
    }

    function addSpace(
        pos,
        dim,
        previewCargoSpace,
        negativeSpaces,
        visualAids,
        axles
    ) {
        sceneSubjects.push(
            meshFactory.createSpace(
                pos,
                dim,
                previewCargoSpace,
                negativeSpaces,
                visualAids,
                axles
            )
        );
    }

    function createPresetSpace(pos, dim) {
        sceneSubjects.push(meshFactory.createPresetSpace(pos, dim));
    }

    function addSpaceDimensions(pos, dim, expandedDim) {
        containerInfos.push({
            position: pos,
            dimensions: dim,
            renderedDimensions: expandedDim,
        });
    }

    function setViewByBounds(x, y, z) {
        const X_FACTOR = 1.2;
        const Y_FACTOR = 3;
        const Z_FACTOR = 1.5;
        const ZOOM_FACTOR = zoomFactor || 1;

        // Set the rotation for camera
        camera.position.y = z * Z_FACTOR * ZOOM_FACTOR;
        camera.position.x = y * Y_FACTOR * ZOOM_FACTOR;
        camera.position.z = x * X_FACTOR * ZOOM_FACTOR;

        // Adjust position based on viewPortRatio
        let viewPort = new THREE.Vector4();
        renderer.getViewport(viewPort);
        const viewPortRatio = (viewPort.z / viewPort.w) / (944.0 / 375.0)
        camera.position.x += camera.position.x * (1 - viewPortRatio) * 1.044073
        camera.position.z += camera.position.z * (1 - viewPortRatio) * 0.832842

        // save the initial camera view
        cameraHelper.setDefaultCameraView(camera.position);

        // Set how far the camera will render before object vanishes
        const mult = 4;
        const cameraFar =
            Math.max(X_FACTOR * x, Z_FACTOR * z) * ZOOM_FACTOR * 2;
        camera.far = Math.max(camera.far, mult * Math.abs(cameraFar));

        camera.updateProjectionMatrix();
        addLights();
    }

    function addLights() {
        const d = 0.125 * camera.far;
        const light = (x, z, y) => {
            let light = new THREE.PointLight(0x888888, 2, 0, 1);
            light.position.set(x * d, y * d, z * d);
            scene.add(light);
        };
        light(3, -2, -2);
        light(-3, 2, 4);
        scene.add(new THREE.AmbientLight(0x282828));
    }

    function createSceneSubjects() {
        const sceneSubjects = [];
        return sceneSubjects;
    }

    function update() {
        renderUpdatedCameraView();
        controls.update();

        if (
            renderer &&
            !renderer.domElement.getContext("webgl").isContextLost()
        )
            renderer.render(scene, camera);
            addRaycaster();
    }

    function onWindowResize() {
        const { width, height } = canvas;

        screenDimensions.width = width;
        screenDimensions.height = height;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        if (renderer) {
            renderer.setSize(width, height);
        }
    }

    /**
     * Clears the scene and disposes all object related to it
     * Does NOT remove renderer and scene - the same renderer can be used again
     */
    function clear() {
        // Reset initialized values
        containerInfos = [];
        loadingAssistantMode = false;

        controls.reset();

        disposeRecursive(scene);
        sceneSubjects = [];
        hlBoxDisposeList = [];

        while (scene.children.length > 0) {
            scene.remove(scene.children[0]);
        }
    }

    /**
     * Dispose EVERYTHING - calls clear() internally and disposes scene and renderer
     * After this, renderer and scene must be re-created
     */
    function dispose() {
        // For further details, see: https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
        clear();

        // Dispose Scene
        scene.dispose();

        // Remove additional listeners
        canvas.removeEventListener("pointerup", handlePointerUp);
        canvas.removeEventListener("pointerdown", handlePointerDown);
        canvas.removeEventListener("pointermove", onPointerMove);

        // Dispose renderer and force ContextLoss
        // https://stackoverflow.com/questions/21548247/clean-up-threejs-webgl-contexts
        if (renderer) {
            renderer.forceContextLoss();
            renderer.domElement = null;
            renderer.dispose();
        }
        renderer = null;
    }

    // https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
    function disposeObject(parentObject) {
        parentObject.traverse(function (node) {
            if (node instanceof THREE.Mesh) {
                if (node.geometry) {
                    node.geometry.dispose();
                }

                if (node.material) {
                    // MeshFaceMaterial has the material array at material.materials
                    if (node.material instanceof THREE.MeshFaceMaterial) {
                        disposeMaterialAndTextures(node.material.materials);
                    }
                    // Otherwise the material is in material itself which can be either an array of materials or single material
                    else {
                        disposeMaterialAndTextures(node.material);
                    }
                }
            }
        });
    }

    function disposeRecursive(node) {
        for (var i = node.children.length - 1; i >= 0; i--) {
            disposeRecursive(node.children[i]);
            disposeObject(node.children[i]);
        }
    }

    function onMouseMove(event) {
        // Actually a mouse click at the moment, used for debugging camera views etc
        console.log("Rendered before info: ", renderer?.info);
        // console.log("Camera aspect: ", camera.aspect)
        // console.log("Camera position", camera.position.x, camera.position.y, camera.position.z)
        // console.log("getViewPort: ", renderer.getViewport(new THREE.Vector4()))
        // console.log("Curr ViewPort: ", renderer.getCurrentViewport(new THREE.Vector4()))
        // console.log("Scene pos: ", scene.position)
        // console.log("controls ", controls)
    }

    function makeParcelVisible(parcel) {
        parcel.material.forEach((material) => {
            material.transparent = false;
            material.opacity = 1;
            material.depthWrite = true;
        });
    }

    function makeParcelTransparent(parcel, opacity, depthWrite) {
        parcel.material.forEach((material) => {
            material.transparent = true;
            material.opacity = opacity;
            material.depthWrite = depthWrite;
        });
    };

    /**
     * When user puts LoadingAssistantTableLoadingOperationsView into edit mode, turn all parcels opacity to 1.
     * When user selects a parcel for manipulation, keep that parcels opacity at 1, but turn all other parcels opacity to 0.1
     * @param {String} selectedParcelsId is the parcelId of the parcel user has selected for manipulation
     */
    function applyParcelEditModeHighlight(selectedParcelsId) {
        const changeParcelVisibility = (parcel, visibility) => {
            if (parcel.name !== 'highlightBox') {
                parcel.material.forEach((material) => {
                    material.visible = visibility
                })
            }
        }


        const loadedSceneChildren = scene.children.filter(child => child.parcel?.loaded);
        const unloadedSceneChildren = scene.children.filter(child => !child.parcel?.loaded);


        // If user has selected a parcel for manipulation, turn all other scene children transparent
        if (selectedParcelsId != null) {
            loadedSceneChildren.forEach(child => {
                // Check that child parcelId isn't null, so we don't affect the cargo space -child
                if (child.parcelId !== null && child.parcelId !== selectedParcelsId) {
                    makeParcelTransparent(child, 0.45, true);
                }
            });
            unloadedSceneChildren.forEach(child => {
                if (child.parcelId != null) {
                    changeParcelVisibility(child, false);
                }
            })
        };

        // If user hasn't selected any parcels for manipulation, turn all parcels visible
        if (selectedParcelsId == null) {
            loadedSceneChildren.forEach(child => {
                if (child.parcelId != null && child.name !== 'highlightBox') {
                    makeParcelVisible(child)
                }
            });
            unloadedSceneChildren.forEach(child => {
                if (child.parcelId != null && child.name !== 'highlightBox') {
                    changeParcelVisibility(child, true);
                }
            })
        }

    }


    function applyCompletedHighlight() {

        const loadedSceneChildren = scene.children.filter(child => child.parcel?.loaded);
        const unloadedSceneChildren = scene.children.filter(child => !child.parcel?.loaded);

        loadedSceneChildren.forEach(child => {
            if (child.parcelId != null && child.name !== 'highlightBox') {
                makeParcelVisible(child);
            }
        });

        unloadedSceneChildren.forEach(child => {
            if (child.parcelId != null && child.name !== 'highlightBox') {
                makeParcelTransparent(child, 0.4, true)
            }
        })
    }


    /**
     * Each time solution in loadingOperationsView is updated, or changed in some way, go through all scene children and update the parcel-field for each
     * @param {Array} parcels is array fo all parcels in the the solution
     */
    function updateParcelInfos(parcels) {
        scene.children.forEach(child => { // For each child in scene
            if (child.parcelId != null) { // If child is a parcel
                const childParcel = parcels.find(parcel => parcel.id === child.parcelId); // Find the current parcel information
                child.parcel = childParcel // And set the current parcel information to child
            }
        })
    }


    function updateParcels(currSelectedId, slider, loadingAssistantSol) {
        const handleSlider = (sliderValue, opacity) => {
            // Different visualization effects when loadingAssistant
            if (loadingAssistantSol) {
                loadingAssistantMode = true;
                if (loadingAssistantSol.packingStatus < 2)
                    return;

                const alreadyPacked = loadingAssistantSol.packedParcels
                    .filter((x) => x.loaded)
                    .map((x) => x.id);

                scene.children.forEach((x) => {
                    if (x.parcelId && x.name !== "highlightBox") {
                        if (alreadyPacked.includes(x.parcelId)) {
                            makeParcelVisible(x);
                        } else {
                            makeParcelTransparent(x, opacity, false);
                        }
                    }
                });
                return;
            }

            const parcels = scene.children.filter(
                (x) => x.parcelId && x.name !== "highlightBox"
            );

            // Sort parcels based on loadingOrder
            // parcels.sort((a, b) => a.parcel.loadingOrder - b.parcel.loadingOrder)

            parcels.forEach((x, i) => {
                if (i < sliderValue) {
                    makeParcelVisible(x);
                } else {
                    makeParcelTransparent(x, opacity);
                }
            });

        };

        if (!currSelectedId) handleSlider(slider, 0.1);

        const highlightBox = () => {
            const hBox = scene.children.find((x) => x.name === "highlightBox");
            if (hBox) {
                // Delete previous highLightBox, this includes disposing of the object
                // These needs to be disposed explicitly, because they are removed from the scene
                // Without this, the disposal of scene child won't dispose these because they are not part of the scene anymore
                hlBoxDisposeList.forEach((x) => x.dispose());
                hlBoxDisposeList = [];
                scene.remove(hBox);
            }

            if (currSelectedId) {
                const selected = scene.children.find(
                    (x) => x.parcelId && x.parcelId === currSelectedId
                );
                if (selected) {
                    handleSlider(-1, 0.1);
                    const { width, height, depth } =
                        selected.geometry.parameters;

                    let box = new THREE.BoxBufferGeometry(
                        width + 1,
                        height + 1,
                        depth + 1
                    );
                    let material = new THREE.MeshBasicMaterial({
                        wireframe: true,
                        color: 0xffffff,
                    }); // 0xf3740e = boxbot orange, B22222 also pretty good
                    let highlightBox = new THREE.Mesh(box, material);

                    hlBoxDisposeList.push(box);
                    hlBoxDisposeList.push(material);

                    highlightBox.parcelId = selected.parcelId;
                    highlightBox.name = "highlightBox";
                    highlightBox.position.copy(selected.position);
                    highlightBox.rotation.copy(selected.rotation);
                    highlightBox.scale.copy(selected.scale);
                    scene.add(highlightBox);

                    selected.material.forEach((material) => {
                        material.transparent = false;
                        material.opacity = 0.6; // this make's it look lighter
                        material.depthWrite = true;
                    });
                }
                hlBox = selected;
            } else {
                hlBox = null;
            }
        };
        highlightBox();
    }

    function updateCameraView(packingParcel, highLighted) {
        const findId = packingParcel
            ? packingParcel.id
            : highLighted
                ? highLighted.id
                : null;

        if (!findId) {
            cameraHelper.setTargetsToDefault();
            return;
        }

        const child = scene.children.find((x) => x.parcelId === findId);
        if (!child) return;

        const pos = child.position;
        const dim = child.geometry.parameters;

        // HACK To fix cameraHelper crash with cylinder geometries
        if (!dim.width) dim.width = dim.radiusTop * 2
        if (!dim.depth) dim.depth = dim.radiusTop * 2

        // Only one cargo space supported ATM
        if (containerInfos.length > 1)
            console.error(
                "Threer is having multiple cargo spaces - ONLY ONE IS SUPPORTED"
            );

        const containerInfo = containerInfos[0];
        cameraHelper.updateCameraView(
            packingParcel,
            highLighted,
            pos,
            dim,
            containerInfo
        );
    }

    function renderUpdatedCameraView() {
        if (loadingAssistantMode && hlBox) {
            theta += 0.05;
            const op = Math.sin(theta) * 0.25 + 0.75;
            hlBox.material.forEach((material) => {
                material.opacity = op;
            });
        }

        let target = cameraHelper.renderUpdatedControlView(controls.target);
        controls.target.x += target.x;
        controls.target.y += target.y;
        controls.target.z += target.z;

        let position = cameraHelper.renderUpdatedCameraView(camera.position);
        camera.position.x += position.x;
        camera.position.y += position.y;
        camera.position.z += position.z;

        camera.updateProjectionMatrix();
    }

    function setFieldOfView(fov) {
        camera.fov = fov;
        camera.updateProjectionMatrix();
    }

    function translateCamera({ x = 0, y = 0, z = 0 }) {
        controls.target.x += x;
        controls.target.y += y;
        controls.target.z += z;
        controls.update();
    }

    function swapCamera() {
        camera.position.z = -camera.position.z;
    }

    function reconstructParcels(originalParcels) {

        const parcels = originalParcels.map((p) => Object.assign({}, p));
        const spaceDim = containerInfos[0].dimensions;

        const removeSceneObject = (id) => {
            const sceneObj = scene.children.find((x) => x.parcelId === id);
            if (sceneObj) {
                scene.remove(sceneObj);
            }
        };

        // Remove all parcels from scene.
        sceneSubjects.forEach(subject => {
            if (subject.getMesh().parcelId) {
                const mesh = subject.getMesh();
                disposeObject(mesh);
                removeSceneObject(mesh.parcelId);
            }
        })

        // Reconstruct all parcels. Replace existing sceneSubjects with the newly reconstructed objects.
        const newSceneSubjects = [];
        parcels.forEach(parcel => {

            // Find sceneSubject that was done from parcel
            const subject = sceneSubjects.find(sub => sub.getMesh().parcelId === parcel.id);

            // Check for if a subject was found. If not, then skip box reconstructing.
            // This situation only happens when parcels-array has a larger set of parcels than what's in sceneSubjects.
            // TODO: Should we add a function that creates a new parcel to a scene if subject wasn't found?
            if (subject == null) {
                const box = meshFactory.createBox(parcel.location, parcel.packedDimensions, spaceDim, parcel);
                newSceneSubjects.push(box);
                return;
            };

            const mesh = subject.getMesh();

            // Copy mesh materials
            const materials = mesh.material.map(m => m.clone());

            // Create new box from parcel
            //TODO: We actually should create a new material. Not necessarily always, but at least whenever any parcel properties are changed. (dimensions or stacking icons)
            const newBox = meshFactory.createParcelFromMaterial(
                parcel,
                materials,
                spaceDim
            );

            // Add newBox to scene
            scene.add(newBox.getMesh());

            // Add newBox to newSceneSubjects
            newSceneSubjects.push(newBox);
        });

        // Replace old sceneSubjects with newSceneSubjects
        sceneSubjects = newSceneSubjects;
    }

    function addParcelsStepByStep(parcels) {
        const containerInfo = containerInfos[0];

        // Create parcels with specific color range
        let meshParcels = parcels.map((parcel, parcelIndexInStep) => {
            return meshFactory
                .createStepBoxFromRawData(
                    parcel,
                    parcelIndexInStep,
                    containerInfo.dimensions
                )
                .getMesh();
        });

        // Return the dictionary { parcelId: usedColor }
        return meshParcels.reduce(
            (dict, meshParcel) => ({
                ...dict,
                [meshParcel.parcelId]: meshParcel.parcelColor,
            }),
            {}
        );
    }

    function grayScaleParcels(parcels) {
        parcels.forEach((parcel) => {
            const sceneObject = scene.children.find(
                (s) => s.parcelId === parcel.id
            ); // sceneSubjects.find(s => s.getMesh().parcelId === parcel.id);
            if (!sceneObject) {
                console.error("Could not find the mesh with id: ", parcel.id); // TODO What to do, shouldn't happen but..
            } else {
                meshFactory.grayScaleBox(sceneObject, parcel);
            }
        });
    }

    function setCameraForStepByStep() {
        cameraHelper.setCameraForStepByStep();
    }

    function resetCamera() {
        cameraHelper.clearTargets();
    }

    /**
     * RAYCASTER FUNCTIONALITY FOR THREER
     */
    let intersectedObjects = [];
    let [pointer, raycaster] = setupRaycaster();

    /**
     * Event handler for mouse move on canvas.
     * Get pointer coordinates from the relative size and position of the canvas.
     * @param {Object} event
     */
    function onPointerMove(event) {
        if (pointer == null) {
            return;
        }

        let rect = event.target.getBoundingClientRect(); // Get canvas relative size and position of the canvas
        pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1; // Normalize both coordinates to range [-1, 1]
        pointer.y = -(((event.clientY - rect.top) / rect.height) * 2) + 1;
    }

    /**
     * Event handler for parcel select.
     * @param {Object} event
     */
    function handleArrowClick(arrow) {
        const arrowId = arrow.object.arrowId.split("_"); // Get the first word of the arrowId to determine which direction arrow user has clicked
        // disposeArrows(arrowId[0]);
        parcelManipulationCallbacks.onClickArrow(
            arrowId[0]
        ); // Send direction-string to loadingOperationsView
    }

    /**
     * Send information to LoadingAssistantTableLoadingOperationsView that a parcel has been clicked.
     * Information contains only parcels parcelId.
     * Use optional chaining to check for null parcel
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining
     * @param {Object} parcel is the scene.children object that has been clicked
     */
    function handleParcelClick(parcel) {
        parcelManipulationCallbacks.onClickParcel(
            parcel.object.parcelId
        ); // Call the callback function that is going to trigger addArrowsToParcel with the parcelId of the clicked object
    }

    /**
     * Send information to LoadingAssistantTableLoadingOperationsView that an empty space has been clicked.
     * Clicking on an empty space or an empty part of the cargo space should dispose of any existing arrows in the scene.
     */
    function handleEmptyClick() {
        parcelManipulationCallbacks.onClickParcel(
            null
        );
    }

    /**
     * Add manipulation arrows to a parcel with parcelId === id.
     * This function is called from threeEntryPoint setClickedParcel()
     * @param {String} id for the parcel we want to arrows to
     * @param {String} direction tells which arrow we want to add. Giving 'all' draws all arrows.
     * @returns if received id == null
     */
    function addArrowsToParcel(id, direction) {
        // If we receive null parcel id, then dispose arrows and return.
        // null id is received when user clicks on empty space, or an empty part of the cargo space.
        if (id == null) {
            disposeArrows();
            cameraHelper.setTargetsToDefault();
            return;
        }

        // Find which scene child id refers to
        const sceneChild = scene.children.find((child) => child.parcelId === id);
        // Dispose any existing arrows before creating new ones
        disposeArrows();
        // Create new arrows
        meshFactory.createArrows(sceneChild, direction);
    }

    /**
     * Dispose arrows from the scene.
     * Use while-loop to dispose all objects that have arrowStem or arrowHead as the name.
     * While-loop, because we're iterating over an array that we're also manipulating, so using forEach or for-loop could lead to a situtation
     * where we're skipping objects in the array and not disposing the arrows correctly.
     *
     * E.g. situation where indexes 4, 5 and 6 are all arrow-parts.
     * 4 is removed, array resets so that indexes 5 and 6 are now indexes 4 and 5.
     * We move on to index 5.
     *
     * --> object originally in index 5 is skipped and not disposed.
     *
     * @param {String} keepDirection - Dispose all arrows except the given direction arrow. By default, all arrows are disposed
     */
    function disposeArrows(keepDirection) {

        if (keepDirection == null) {
            keepDirection = 'all'
        }

        let idx = 0;
        while (idx < scene.children.length) {

            let child = scene.children[idx];
            if (child.name === "arrowStem" || child.name === "arrowHead") {

                const arrowId = child.arrowId.split("_")[0];

                if (keepDirection === 'all') {
                    disposeObject(child);
                    scene.remove(child);
                    continue;
                }

                if (keepDirection !== 'all' && arrowId !== keepDirection) {
                    disposeObject(child);
                    scene.remove(child);
                    continue;
                }
            }

            idx++;
        }
    }

    /**
     * Event handler for when users pointer happens to be over an object when releasing pointer.
     * Which parcel is either passed from LoadingAssistantTabletLoadingOperationsView or determined by the raycaster
     *
     * When a parcel is clicked arrows should be added to the scene surrounding the parcel.
     * If user clicks on empty space or an empty part of the cargo space, then dispose the arrows.
     */
    function handlePointerUp() {
        if (pointer == null) {
            return;
        }

        // Calculate how much the pointer has moved since clicking down
        if (pointer != null && pointerCoordinatesOnPointerDown != null) {
            const a =
                Math.max(pointer.x, pointerCoordinatesOnPointerDown.x) -
                Math.min(pointer.x, pointerCoordinatesOnPointerDown.x);
            const b =
                Math.max(pointer.y, pointerCoordinatesOnPointerDown.y) -
                Math.min(pointer.y, pointerCoordinatesOnPointerDown.y);
            draggedDistance = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
        }

        // If users click hit an empty space
        if (
            draggedDistance <= draggedThreshold &&
            intersectedObjects.length === 0
        ) {
            handleEmptyClick();
            return;
        }

        if (
            draggedDistance <= draggedThreshold &&
            intersectedObjects.length > 0
        ) {
            // If users click hit an arrow, filter out all arrows from intersectedObjects and handleArrowClick on the first arrow hit
            const arrowsHit = intersectedObjects.filter(
                (n) =>
                    n.object.name === "arrowStem" ||
                    n.object.name === "arrowHead"
            );
            if (arrowsHit.length > 0) {
                handleArrowClick(arrowsHit[0]);
                return;
            }

            // If users click hit a parcel and we are listening for parcel clicks, filter out all parcels from intersectedObjects, apply parcelFilter and then handleParcelClick on the first parcel hit
            if (parcelManipulationCallbacks) {
                const parcelsHit = intersectedObjects.filter((n) => {
                    if (!n.object.parcel) return false;
                    return parcelManipulationCallbacks.parcelFilter(n.object);
                });

                if (parcelsHit.length > 0) {
                    handleParcelClick(parcelsHit[0]);
                    return;
                }
            }

            // If users click hit an empty part of the cargo space
            if (draggedDistance <= draggedThreshold) {
                handleEmptyClick();
                return;
            }
        }
    }

    /**
     * Set up both the mouse pointer and raycaster
     * @returns pointer and raycaster
     */
    function setupRaycaster() {
        // If parcelManipulationCallbacks haven't been given, then don't set up raycaster or pointer
        if (parcelManipulationCallbacks == null) {
            return [null, null];
        }

        const pointer = new THREE.Vector2();
        const raycaster = new THREE.Raycaster();

        return [pointer, raycaster];
    }

    /**
     * Add raycaster to the scene.
     * Put the array of objects the raycaster intersects to intersectedObjects
     */
    function addRaycaster() {
        // If raycaster hasn't been set up, then don't add it to the scene
        if (pointer == null || raycaster == null) {
            return;
        }

        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(scene.children, false);
        if (intersects.length > 0) {
            intersectedObjects = intersects;
        } else {
            intersectedObjects = [];
        }
    }

    function handlePointerDown(event) {
        if (pointer == null) {
            return;
        }

        /**
         * Set pointer coordinates on pointerdown-event.
         * This fixes coordinates not updating when using a touch-screen, and this fixes a bug on desktop, where the pointer isn't updated when the parcelTransformDialog is open
         * so clicking the same place reacts to the pointers last coordinates before opening the dialog.
         */
        let rect = event.target.getBoundingClientRect(); // Get canvas relative size and position of the canvas
        pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1; // Normalize both coordinates to range [-1, 1]
        pointer.y = -(((event.clientY - rect.top) / rect.height) * 2) + 1;

        pointerCoordinatesOnPointerDown = { ...pointer };
    }

    function zoomToParcel(parcelId, direction) {
        const child = scene.children.find(x => x.parcelId && x.parcelId === parcelId);
        cameraHelper.zoomToParcel(child, direction);
    }

    return {
        clear, // External, Clear scene children
        setViewByBounds, // External, Allows customization of zoom
        update, // External, Explicit re-render call, e.g. after adjusting canvas size
        createPresetSpace, // External, creates a preset view of cargo space
        updateParcels, // External, Toggle visibility of parcels (e.g. slider)
        updateCameraView, //External, Rotate camera (e.g. loading assistant)
        reconstructParcels, // External, Update loading plan. This is implemented as a separate "update" function avoid a new initialization (e.g. reset colors)
        dispose, // External dispose
        addArrowsToParcel, // External, adds control arrows to parcel
        applyParcelEditModeHighlight, // External, controls parcel opacity and transparency for loadingOperationsView edit mode
        updateParcelInfos, // External, update parcel-field for each scene-children when solution is updated
        applyCompletedHighlight, // External, controls parcels opacity when loading job has finished

        addParcelsStepByStep, // External, Same as above but a special version for StepByStep
        grayScaleParcels, // External, Same as above but a special version for StepByStep
        setCameraForStepByStep, // External, special version for StepByStep
        resetCamera, //External, reset camera to default position for StepByStep

        addBox, // Internal parcel add, called from threeEntryPoint
        buildCamera, // Internal initialization step, should not be exposed
        addSpace, // "Internal" Add cargo space (no parcels), called from threeEntryPoint and PresetThreer ("Internal" --> Could just the standard threeEntryPoint.drawCargoSpace)
        addSpaceDimensions, // Internal setter to store original cargo space dimensions for future use, called from threeEntryPoint
        setFieldOfView, // "Internal"/Split functionality, set camera FOV, called from threeEntryPoint
        swapCamera, // "Internal"/Split functionality, rotate camera to the other side, called from threeEntryPoint
        translateCamera, // "Internal"/Split functionality, rotate camera to the other side, called from threeEntryPoint
        onWindowResize, // Internal, does something?, called from threeEntryPoint

        onMouseMove, // DEBUGGING PURPOSES

        zoomToParcel, // External, zooms close to parcel from the given direction
    };
};
