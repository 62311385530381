import React, { useState } from 'react'

import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router'

//////////////////////////////////////////////////////////////////////////////

import FormTextField from '../core/FormTextField';
import UserlessPage from '../app/UserlessPage';
import { emailConfirmation } from '../api'
import { notificationDialogSeverity } from '../core/Constants';
import { showNotification, showNotificationDialog } from '../app/AppActions'
import { validateEmail } from '../signup/RegisterFormValidations'

//////////////////////////////////////////////////////////////////////////////

const ResendEmailConfirmation = (props) => {
    const [state, setState] = useState({
        email: '',
        errors: {
            email: ''
        }
    });

    const { reduxDispatch, classes } = props;

    const submitHandler = event => {
        event.preventDefault()

        const validationResult = validateEmail(state.email);
        if (validationResult !== "") {
            return setState(prevState => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    email: validationResult,
                }
            }))
        }

        emailConfirmation.resend(state.email)
            .then(() => {
                reduxDispatch.showNotification(`Email was sent to ${state.email}. Remember to check junkmail folder.`);
            })
            .catch(err => {
                reduxDispatch.showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error);
            })
    }

    const onChange = event => {
        // Prevents React from resetting its properties:
        event.persist();

        setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
            errors: {
                ...prevState.errors,
                [event.target.name]: ''
            }
        }))
    }

    return (
        <UserlessPage
            title='Resend Email Confirmation'
            infoText='Please fill in the email that you used to register. You will be sent an email to verify you have access to that email.'
            link='Sign In'
            linkLabel='Email already confirmed?'
            onLinkClick={() => browserHistory.push('/signin')}
        >
            <form onSubmit={submitHandler} style={{ width: '100%' }}>
                <FormTextField
                    id='email'
                    label='Email'
                    type='text'
                    value={state.email}
                    onChange={onChange}
                    name='email'
                    error={Boolean(state.errors.email)}
                    helperText={state.errors.email}
                    fullWidth
                />
                <div className={classes.bottomDivider} />
                <div className={`${classes.textFieldWrapper} ${classes.horizontalCenter}`} >
                    <Button variant="contained" color="primary" startIcon={<EmailIcon />} onClick={submitHandler}>Request reset</Button>
                </div>
                <div className={classes.bottomDivider} />
                <input type='submit' style={{ display: 'none' }} />
            </form>
        </UserlessPage>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    reduxDispatch: {
        showNotification: message => dispatch(showNotification(message)),
        showNotificationDialog: (message, title, severity) => dispatch(showNotificationDialog(message, title, severity)),
    }
})

const styles = theme => ({
    bottomDivider: theme.app.bottomDivider,
    textFieldWrapper: theme.textFieldWrapper,
    container: theme.containers.twoPaperFlex,
    paper: theme.containers.flexPaper,
    horizontalCenter: {
        display: 'flex',
        justifyContent: 'center'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResendEmailConfirmation))
