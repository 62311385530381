import React, { useCallback, useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
// NOTE: ExpansionPanel was renamed to Accordion in Material-UI 4.11.0
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import CheckIcon from '@material-ui/icons/Check';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import { withStyles } from "@material-ui/styles";

import { tabletTheme } from "../../theme";
import DialogTitle from "../../core/DialogTitle";
import { lmcTypes } from "../Constants";

import LMCPreviewDialog from "./LMCPreviewDialog";

import PropTypes from "prop-types";

//////////////////////////////////////////////////////////////////////////////

const LMCSuccessDialog = (props) => {
    const { classes } = props;

    // States holding components for result of successful LMC inside just one cargo space
    const [singleSuccessContent, setSingleSuccessContent] = useState(null);

    // States holding components for result of successful LMC affecting multiple cargo spaces
    const [affectedCards, setAffectedCards] = useState([]);
    const [unaffectedCards, setUnaffectedCards] = useState([]);
    const [multiSuccessContent, setMultiSuccessContent] = useState(null);

    // State controlling opening/closing the Collapse-container in Multi-success dialog content
    const [collapseContainerOpen, setCollapseContainerOpen] = useState(false);

    // State object controlling the 3D overview -dialog
    const [previewDialogState, setPreviewDialogState] = useState({
        open: false,
        csIndex: null
    })


    /**
     * If LMC only targeted one cargo space and was successful, create DialogContent
     * showing the results of the successful LMC.
     */
    useEffect(() => {
        const lmc =
            props.solution?.cargoSpaces[props.solution?.cargoSpaceIndex]
                .lastMinuteChange;

        if (lmc?.newCargoSpaces == null || lmc?.lmcType !== lmcTypes.single) {
            return;
        }

        if (multiSuccessContent != null) {
            setMultiSuccessContent(null);
        }

        const singleSuccessResults = (
            <DialogContent>
                <DialogContentText>
                    Please confirm the changes using the CONFIRM button.
                </DialogContentText>
            </DialogContent>
        );

        setSingleSuccessContent(singleSuccessResults);
    }, [props.solution, multiSuccessContent]);


    /**
     * Return the right icon we want for the selected packing goal for the ExpansionPanelSummaries.
     */
    const getPackingGoalIcon = useCallback(cargoSpace => {
        if (
            cargoSpace.selectedArrangement &&
            cargoSpace.selectedArrangement > 0
        ) {
            const altArrangement = cargoSpace.alternateArrangements.find(aa => aa.arrangementId === cargoSpace.selectedArrangement);
            if (altArrangement.packingOptimizationGoal === 'Spread') {
                return <ZoomOutMapIcon className={classes.packingGoalIcon} />
            } else {
                return <ControlCameraIcon className={classes.packingGoalIcon} />
            }
        }
        else {
            if (cargoSpace.packingOptimizationGoal === 'Standard') {
                return <ControlCameraIcon className={classes.packingGoalIcon} />
            } else {
                return <ZoomOutMapIcon className={classes.packingGoalIcon} />
            }
        }
    }, [classes.packingGoalIcon]);


    /**
     * Create a ExpansionPanel (card) for each cargo space that tells if the cargo space was
     * modified/created after an LMC.
     * Get LMC-object of the current cargo space and then go through all cargo spaces in solution.
     * If cargo space is included in lmc.affectedCargoSpace, create an orange card (modified cs).
     * If cargo space is included in lmc.newCargoSpaces, create a blue card (new cs).
     * If cargo space isn't included in either, create a grey card (unmodified cs).
     */
    useEffect(() => {

        setCollapseContainerOpen(false);

        const lmc = props.solution?.cargoSpaces[props.solution?.cargoSpaceIndex].lastMinuteChange;
        if (lmc?.newCargoSpaces == null || lmc?.lmcType !== lmcTypes.multi) {
            return;
        }

        if (singleSuccessContent != null) {
            setSingleSuccessContent(null);
        }

        const newAffectedCards = [];
        const newUnaffectedCards = [];

        const newCargoSpaceCount = Object.keys(lmc.cargoSpaceChanges).filter(key => lmc.cargoSpaceChanges[key].type === "New").length;
        const cargoSpaceCount = props.solution.cargoSpaces.length + newCargoSpaceCount;

        Object.keys(lmc.cargoSpaceChanges).forEach(id => {
            const meta = lmc.cargoSpaceChanges[id];

            // Find the index of this cargo space, if it's new one -> "last index"
            const index = meta.type === "New" ? cargoSpaceCount - 1 : props.solution.cargoSpaces.findIndex(x => x._id.toString() === id);

            // Get the new cargo space
            let newCargoSpace = lmc.newCargoSpaces.find(ncs => ncs.cargoSpaceId === id);
            if (meta.type === 'Unaffected') { // Unaffected cargo spaces aren't in newCargoSpaces-array
                newCargoSpace = props.solution?.cargoSpaces.find(cs => cs._id.toString() === id);
            }

            // Get the preset name of the cargo space storage
            let storageName
            if (newCargoSpace?.storages[0].presetName) {
                storageName = newCargoSpace.storages[0].presetName
            } else { // If storage has no presetName, then use storage dimensions as the name
                const meters = d => (d / 1000).toFixed(1)
                storageName = `${meters(newCargoSpace?.storages[0].dimensions.x)} × ${meters(newCargoSpace?.storages[0].dimensions.y)} × ${meters(newCargoSpace?.storages[0].dimensions.z)} m`
            }

            /**
             * Initialize all the changing components of the ExpansionPanels
             */
            let buttonColor = null;
            let color = null;
            let icon = null;
            let header = "";
            let variant = "";
            let isUnaffected = false;

            switch (meta.type) {
                case "Unaffected":
                    isUnaffected = true;
                    header = `Cargo space ${index + 1} / ${cargoSpaceCount}`;
                    variant = "Unaffected";
                    buttonColor = 'default'
                    color = tabletTheme.palette.grey[500];
                    break;
                case "New":
                    header = "New cargo space";
                    variant = `Added`;
                    color = tabletTheme.palette.secondary.main;
                    buttonColor = 'secondary'
                    icon = (
                        <NewReleasesIcon
                            fontSize="large"
                            className={classes.variantIcon}
                            style={{ color: color }}
                        />
                    );
                    break;
                case "Changed":
                    header = `Cargo space ${index + 1} / ${cargoSpaceCount}`;
                    variant = "Modified";
                    color = tabletTheme.palette.primary.main;
                    buttonColor = 'primary'
                    icon = (
                        <EditIcon
                            fontSize="large"
                            className={classes.variantIcon}
                            style={{ color: color }}
                        />
                    );
                    break;
                default:
                    throw new Error(`Unknown meta.type ${meta.type}`);
            }

            // Select which deck the card will be pushed depending on if cargo space was affected by LMC or not
            const deck = isUnaffected ? newUnaffectedCards : newAffectedCards;

            // Construct a new ExpansionPanel with the given information
            deck.push(
                <ExpansionPanel
                    key={id}
                    elevation={4}
                    className={classes.expansionPanel}
                    style={{ border: `2px solid ${color}`, margin: '0px' }}
                >
                    <ExpansionPanelSummary
                        classes={{ content: classes.expansionPanelSummary }}
                        expandIcon={!isUnaffected ? <ExpandMoreIcon /> : null}
                    >
                        <Box className={classes.headerContainer}>
                            <Typography
                                className={classes.headerText}
                                variant="h6"
                                style={{
                                    color: isUnaffected ? "#989898" : "",
                                }}
                            >
                                {header}
                            </Typography>
                            <Typography
                                className={classes.headerText}
                                variant="subtitle1"
                                style={{
                                    color: isUnaffected ? "#989898" : "",
                                }}
                            >
                                {storageName}
                            </Typography>
                        </Box>
                        <Box className={classes.buttonContainer}>
                            <Button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setPreviewDialogState({ open: true, csIndex: index })
                                }}
                                color={buttonColor}
                                style={{
                                    display: isUnaffected ? 'none' : '',
                                    maxHeight: '95px'

                                }}
                                variant="outlined"
                            >
                                3D preview
                            </Button>
                        </Box>
                        <Box className={classes.headerContainer}>
                            <Typography
                                className={classes.headerText}
                                variant="subtitle1"
                                style={{
                                    color: isUnaffected ? "#989898" : "",
                                }}
                            >
                                Selected packing goal
                            </Typography>
                            <Box className={classes.packingGoalTitleContainer}>
                                <Typography
                                    className={classes.headerText}
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 'bold',
                                        color: isUnaffected ? "#989898" : "",
                                    }}
                                >
                                    {
                                        newCargoSpace.alternateArrangements &&
                                        newCargoSpace.selectedArrangement > 0 &&
                                        newCargoSpace.alternateArrangements.length > 0 ?
                                            newCargoSpace?.alternateArrangements.find(aa => aa.arrangementId === newCargoSpace.selectedArrangement)?.packingOptimizationGoal
                                            :
                                            newCargoSpace.packingOptimizationGoal
                                    }
                                </Typography>
                                {getPackingGoalIcon(newCargoSpace)} {/* Get the correct icon using this function */}
                            </Box>
                        </Box>
                        <Box className={classes.variantContainer}>
                            {icon}
                            <Typography
                                className={classes.variantText}
                                variant="subtitle1"
                                style={{
                                    color: color,
                                    marginRight:
                                        isUnaffected ? "35px" : "",
                                }}
                            >
                                {variant}
                            </Typography>
                        </Box>
                    </ExpansionPanelSummary>
                    {/* Add ExpansionPanelDetails only if cargo space has been affected/added by LMC */}
                    {!isUnaffected &&
                        <ExpansionPanelDetails className={classes.expansionPanelDetails} >
                            <Box className={classes.detailsMessageBox}>
                                <Typography variant="h6" className={classes.expansionPanelDetailsHeader}>
                                    Removed cargo items
                                </Typography>
                                <Typography className={classes.expansionPanelDetailsTypography}>
                                    {meta.removedParcelIds?.length > 0 ? meta.removedParcelIds.join('\r\n ') : '-'}
                                </Typography>
                            </Box>
                            <Box className={classes.detailsMessageBox}>
                                <Typography variant="h6" className={classes.expansionPanelDetailsHeader}>
                                    Added cargo items
                                </Typography>
                                <Typography className={classes.expansionPanelDetailsTypography}>
                                    {meta.addedParcelIds?.length > 0 ? meta.addedParcelIds.join('\r\n ') : '-'}
                                </Typography>
                            </Box>
                        </ExpansionPanelDetails>}
                </ExpansionPanel>
            );
        });

        setAffectedCards(newAffectedCards);
        setUnaffectedCards(newUnaffectedCards);
    }, [
        classes.buttonContainer,
        classes.detailsMessageBox,
        classes.expansionPanel,
        classes.expansionPanelDetails,
        classes.expansionPanelDetailsHeader,
        classes.expansionPanelDetailsTypography,
        classes.expansionPanelDiv,
        classes.expansionPanelSummary,
        classes.header,
        classes.headerContainer,
        classes.headerText,
        classes.packinGoalIcon,
        classes.packingGoalIcon,
        classes.packingGoalTitleContainer,
        classes.variantContainer,
        classes.variantIcon,
        classes.variantText,
        getPackingGoalIcon,
        props.solution,
        singleSuccessContent
    ]);

    /**
     * Create DialogContent for successful LMC affecting multiple cargo spaces from
     * the ExpansionPanels we created and added to cards-state.
     */
    useEffect(() => {
        if (props.solution == null) {
            return;
        }

        const lmc =
            props.solution.cargoSpaces[props.solution.cargoSpaceIndex]
                .lastMinuteChange;
        if (lmc == null) {
            return;
        }

        if (affectedCards.length < 1) {
            return;
        }

        const multiSuccessResults = (
            <div>
                {/* Container for the 'modified' and 'added' ExpansionPanels */}
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                        Please review the changes below and confirm them using the CONFIRM button.
                    </DialogContentText>
                    {affectedCards}
                    {/* Collapse container for the 'unaffected' cargo space ExpansionPanels */}
                    <div className={classes.collapseContainer}>
                        <Box className={classes.collapseContainerHeader}>
                            <Typography
                                variant="h6"
                                style={{ color: "black" }}
                                onClick={() =>
                                    {
                                        if (unaffectedCards.length > 0) {
                                            setCollapseContainerOpen(
                                                !collapseContainerOpen
                                            )
                                        }
                                    }
                                }
                            >
                                {`Unaffected cargo spaces (${unaffectedCards.length})`}
                            </Typography>
                            <IconButton
                                onClick={() =>
                                    {
                                        setCollapseContainerOpen(
                                            !collapseContainerOpen
                                        )
                                    }
                                }
                                style={{
                                    transform: collapseContainerOpen
                                        ? "rotate(180deg)"
                                        : "",
                                    display: unaffectedCards.length > 0 ? '' : 'none'
                                }}
                            >
                                <ExpandMoreIcon fontSize="large" />
                            </IconButton>
                        </Box>
                        <Collapse in={collapseContainerOpen}>
                            <Box className={classes.dialogContent}>
                                {unaffectedCards}
                            </Box>
                        </Collapse>
                    </div>
                </DialogContent>
            </div>
        );

        setMultiSuccessContent(multiSuccessResults);
    }, [
        affectedCards,
        classes.collapseContainer,
        classes.collapseContainerHeader,
        classes.dialogContent,
        collapseContainerOpen,
        props.solution,
        unaffectedCards,
    ]);

    return (
        <>
            <LMCPreviewDialog
                open={previewDialogState.open}
                onClose={() => {
                    setPreviewDialogState(s => ({ ...s, open: false }))
                }}
                solution={props.solution}
                index={previewDialogState.csIndex == null ? 0 : previewDialogState.csIndex}
                changeLMCNewCargoSpaceSelectedArrangement={props.changeLMCNewCargoSpaceSelectedArrangement}
            />

            <Dialog open={props.open} classes={{ paper: singleSuccessContent == null ? classes.multiSuccessDialog : classes.singleSuccessDialog }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon style={{ color: "green", height: "40px", width: "40px", marginRight: '4px', marginLeft: "16px" }} />
                    <DialogTitle onClose={props.onClose} style={{ paddingLeft: '0px' }}>
                        Last minute change was successful
                    </DialogTitle>
                </div>

                {singleSuccessContent}
                {multiSuccessContent}

                <DialogActions>
                    <Button color="primary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={props.onUpdate}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

LMCSuccessDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    collapseContainer: {
        height: "100%",
        width: "100%",
    },
    collapseContainerHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "0px",
        padding: "16px 0px 16px 0px",
    },
    multiSuccessDialog: {
        width: '900px',
        maxWidth: '900px ! important'
    },
    singleSuccessDialog: {
        width: '600px',
        maxWidth: '600px ! important'
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        rowGap: "15px",
        height: "auto",
        width: "100%",
        paddingTop: "10px",
    },
    expansionPanel: {
        width: "100%",
        // height: "100%",
        margin: "0px",
    },
    expansionPanelDetails: {
        backgroundColor: "#F0F0F0",
        color: "black",
        padding: "20px",
        paddingLeft: "15px",
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    expansionPanelDetailsHeader: {
        marginLeft: '0px',
        fontSize: '1.1rem'
    },
    expansionPanelDetailsTypography: {
        display: "block",
        fontSize: "15px",
        whiteSpace: "pre-line",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '30px'

    },
    expansionPanelSummary: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        maxWidth: '185px'
    },
    headerText: {
        margin: "0px ! important",
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    variantContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "26%",
    },
    variantIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "auto",
    },
    variantText: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        textAlign: "right",
        width: "40%",
    },
    detailsMessageBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%'
    },
    packingGoalTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: '6px'
    },
    packingGoalIcon: {
        fontSize: '24px'
    }
});

export default withStyles(styles)(LMCSuccessDialog);
