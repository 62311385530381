import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DoneIcon from '@material-ui/icons/Done'

import { notificationDialogSeverity } from '../../core/Constants'

import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotificationDialog, showNotification } from '../../app/AppActions'
import { openDeleteDialog, closeDeleteDialog } from '../AdminActions'
import { browserHistory } from 'react-router'
import { cargoSpacePreset } from '../../api'

//////////////////////////////////////////////////////////////////////////////

const deleteDialog = (editCargoPreset, deleteDialogOpen, onDeletePreset, onCloseDeleteDialog) => (
    <Dialog open={deleteDialogOpen} onClose={onCloseDeleteDialog}>
        <DialogTitle onClose={onCloseDeleteDialog}>{'Delete cargo preset'}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                This will completely delete the preset.
        </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onDeletePreset(editCargoPreset)}>Delete</Button>
        </DialogActions>
    </Dialog>
)



const CargoSpacePresetEditHeader = (props) => {
    const { editCargoPreset, deleteDialogOpen, classes } = props
    const { onCreateCargoPreset, onUpdatePreset, onDeletePreset } = props
    const { onOpenDeleteDialog, onCloseDeleteDialog } = props

    if (editCargoPreset.text == null) {
        return (
            <div />
        )
    }

    let deleteButton, storeButton, deleteButton2, storeButton2
    if (editCargoPreset._id == null) {
        storeButton = (
            <Button startIcon={<DoneIcon />} onClick={() => onCreateCargoPreset(editCargoPreset)} >Create</Button>
        )
        storeButton2 = (
            <IconButton onClick={() => onCreateCargoPreset(editCargoPreset)} >
                <DoneIcon />
            </IconButton>
        )
    } else {
        deleteButton = (
            <Button startIcon={<DeleteForeverIcon />} onClick={onOpenDeleteDialog}  >
                Delete
            </Button>
        )
        deleteButton2 = (
            <IconButton onClick={onOpenDeleteDialog} >
                <DeleteForeverIcon />
            </IconButton>
        )
        storeButton = (
            <Button startIcon={<DoneIcon />} onClick={() => onUpdatePreset(editCargoPreset)}>
                Save
            </Button>
        )
        storeButton2 = (
            <IconButton onClick={() => onUpdatePreset(editCargoPreset)}>
                <DoneIcon />
            </IconButton>
        )
    }

    return (
        <Toolbar>
            <div className={classes.desktop}>
                {deleteButton}
                {storeButton}
            </div>
            <div className={classes.tablet}>
                {deleteButton2}
                {storeButton2}
            </div>
            {deleteDialog(editCargoPreset, deleteDialogOpen, onDeletePreset, onCloseDeleteDialog)}
        </Toolbar>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        editCargoPreset: state.admin.editCargoPreset,
        deleteDialogOpen: state.admin.deleteDialogOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateCargoPreset: (p) => {
            let preset = Object.assign({}, p);
            delete preset.modified;
            if (preset._id) {
                dispatch(showNotificationDialog("A cargo preset already exists", 'Invalid preset', notificationDialogSeverity.warning));
                return
            }
            delete preset.isWeightLimited

            cargoSpacePreset.create(preset)
                .then(() => {
                    dispatch(showNotification(`${preset.text} created`));
                    browserHistory.push('/admin/cargospacepresets#0');
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onUpdatePreset: (p) => {
            let preset = Object.assign({}, p)
            delete preset.modified
            delete preset.isWeightLimited
            cargoSpacePreset.patch(preset._id, preset)
                .then(() => {
                    dispatch(showNotification(`${preset.text} updated`))
                    browserHistory.push('/admin/cargospacepresets#0')
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onDeletePreset: (preset) => {
            cargoSpacePreset.remove(preset._id)
                .then(() => {
                    dispatch(closeDeleteDialog())
                    dispatch(showNotification(`${preset.text} deleted`))
                    browserHistory.push('/admin/cargospacepresets#0')
                })
                .catch(err => {
                    dispatch(closeDeleteDialog())
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                });
        },
        onOpenDeleteDialog: () => {
            dispatch(openDeleteDialog())
        },
        onCloseDeleteDialog: () => {
            dispatch(closeDeleteDialog())
        },
    }
}

const styles = theme => ({
    desktop: theme.desktop,
    tablet: theme.tablet
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CargoSpacePresetEditHeader))

//////////////////////////////////////////////////////////////////////////////
