
export const setProgress = progress => {
    return { type: "SET_PROGRESS", progress }
}

export const setPlanState = value => {
    return { type: "SET_PLANSTATE", value }
}

export const setParcels = parcels => {
    return { type: "SET_PARCELS", parcels }
}

export const setDeletedParcels = deletedParcels => {
    return { type: 'SET_DELETED_PARCELS', deletedParcels }
}

export const setStorages = storages => {
    return { type: "SET_STORAGES", storages }
}

export const setRules = rules => {
    return { type: "SET_RULES", rules }
}

export const setSolution = solution => {
    return { type: "SET_SOLUTION", solution }
}

export const setSelectedParcel = parcel => {
    return { type: "SET_SELECTED_PARCEL", parcel }
}

export const resetOptimizer = () => {
    return { type: "RESET_OPTIMIZER" }
}

export const setInitialInfo = (autoSave, name) => {
    return { type: "SET_INIT_INFO", autoSave, name }
}

export const setMaxAvailableCount = setMaxAvailableCount => {
    return { type: "SET_MAX_AVAILABLE_COUNT", setMaxAvailableCount }
}

export const setSavedSolution = solution => {
    return { type: "SET_SAVED_SOLUTION", solution }
}

export const setAutoSave = value => {
    return { type: "SET_AUTOSAVE", value }
}

export const setNewJobId = id => {
    return { type: "SET_NEW_JOB_ID", id }
}

export const setUnsaved = value => {
    return { type: "SET_UNSAVED", value }
}

export const setCalculated = value => {
    return { type: "SET_CALCULATED", value }
}

export const setBasicInfoOpen = value => {
    return { type: "SET_BASIC_INFO_OPEN", value }
}

export const setDisableChanges = value => {
    return { type: 'SET_DISABLE_CHANGES', value }
}

export const setSendMode = value => {
    return { type: 'SET_SEND_MODE', value }
}

export const setShowCalculationOverlay = value => {
    return { type: 'SET_SHOW_CALC_OVERLAY', value }
}
