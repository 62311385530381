import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../theme'
import { stepLabels } from './Optimize';

import CheckIcon from '@material-ui/icons/Check';

const Connector = withStyles({
    active: {
        '& $line': {
            backgroundImage:
                `linear-gradient( 95deg,${colors.bbOrangeLight} 0%,${colors.bbOrange} 100%)`,
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                `linear-gradient( 95deg,${colors.bbOrangeLight} 0%,${colors.bbOrange} 100%)`,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(props => {
    const { isLoading, ...other } = props;
    // HACK set the line to use 'completed' class style eventhough it's not completed but rather "in progress", which isn't supported by MUI Steps.
    if (isLoading) {
        other.completed = true;
    }
    return <StepConnector {...other} />
});

const BoxbotStepIcon = props => {
    const { active, completed, icon, error, isLoading } = props;

    const src = error ? '/assets/hexagon_boxbot_red.svg' : (completed || active || isLoading ? '/assets/hexagon_boxbot_orange.svg' : '/assets/hexagon_boxbot_gray.svg')

    const isCompletedHight = completed && active
    const needsHighLighting = active

    let childStyle = { color: "white", zIndex: 2, position: "relative", top: "-87%", fontSize: "0.85rem" }

    let size = "30px";
    let containerStyle = { height: size, width: size }
    let shadowStyle = {}

    if (needsHighLighting) {
        size = "45px";
        containerStyle = { height: size, width: size, marginTop: "-11px", zIndex: 1 }
        if (!isCompletedHight) {
            // This is for text component style
            childStyle.top = "-81%"
            childStyle.fontSize = "1.25rem"
        }
        shadowStyle = {
            zIndex: -1,
            boxShadow: "10px 10px 20px 8px rgba(0, 0, 0, 0.45)",
            width: "20px",
            position: "relative",
            top: "24px",
            left: "8px"
        }
    }

    let child = <div style={childStyle}>{icon}</div>

    if (completed) {
        const overrideStyle = isCompletedHight ? { fontSize: "2rem", top: "-16.5%", left: "-86%", bottom: "6px" } : { fontSize: "1.25rem", top: "-16.5%", left: "-84%" }
        const iconStyle = Object.assign({}, childStyle, overrideStyle)
        child = <CheckIcon style={iconStyle} />
    }

    return (
        <div style={containerStyle}>
            {needsHighLighting && <div style={shadowStyle} />}
            <img src={src} alt=" " style={{ height: size, zIndex: 1 }} />
            {child}
        </div>
    )
}


const ProgressSteps = (props) => {
    const { step, buttonProps, stepProps, user, isLoading, classes } = props
    const loadingEnabled = (user.organization?.enabledFeatures && user.organization?.enabledFeatures.includes('loadingAssistant'));

    const labelActive = { fontSize: "1.25rem", fontWeight: "bold", marginTop: "-12px" };
    const errorInactive = { fontSize: "1.17em", fontWeight: "bold", marginTop: "-1px" };

    return (
        <div className={classes.progressContainer}>
            <Stepper activeStep={step} alternativeLabel connector={<Connector isLoading={isLoading} />} className={classes.stepper}>
                {Object.keys(stepLabels).map((label, i) => {
                    let { disabled, error, errorLabel, ...props } = buttonProps[i];
                    let errorStyle = i !== step ? errorInactive : {};
                    let labelStyle = i === step ? labelActive : {};
                    if (i !== step && isLoading) {
                        labelStyle = { color: "rgba(0,0,0,0.87", fontWeight: "500" };
                    }

                    return (
                        (i < 3 || loadingEnabled) &&
                        <Tooltip key={i} title={props.tooltip || ''} >
                            <Step key={stepLabels[label]} {...stepProps[i]} className={classes.stepRoot}>
                                <StepButton
                                    disabled={disabled || i === step}
                                    {...props}
                                >
                                    {/* Step component passes active, completed and disabled props to it's children, additional props are given in StepIconProps */}
                                    <StepLabel error={error} StepIconComponent={BoxbotStepIcon} StepIconProps={{ isLoading: isLoading }}>
                                        <div style={errorLabel && i !== step ? errorStyle : labelStyle}>{stepLabels[label]}</div>
                                        {errorLabel && <div style={errorStyle}>{errorLabel}</div>}
                                    </StepLabel>
                                </StepButton>
                            </Step>
                        </Tooltip >
                    );
                })}
            </Stepper>
        </div >
    )
}

const styles = theme => {
    return {
        progressContainer: {
            minWidth: '576px',
            maxWidth: '592px',
            height: '134px',
        },
        stepRoot: {
            minWidth: '132px',
            zIndex: 1
        },
        stepper: {
            backgroundColor: colors.octagon
        },
        label: {
            marginTop: "-12px"
        }
    }
}

export default withStyles(styles)(ProgressSteps)
