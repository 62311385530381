import seedrandom from 'seedrandom'

const greyColors4 = [
    // 0xE0E0E0,0xDCDCDC,0xD8D8D8,0xD4D4D4,0xD0D0D0,0xCCCCCC,0xC8C8C8,0xC4C4C4,0xC0C0C0,0xBCBCBC, 0xB8B8B8, 0xB4B4B4,
    0xB0B0B0, 0xACACAC, 0xA8A8A8, 0xA4A4A4, 0xA0A0A0, 0x9C9C9C, 0x989898, 0x949494,
    0x909090, 0x8C8C8C, 0x888888, 0x848484, 0x808080, 0x7C7C7C, 0x787878, 0x747474, 0x707070, 0x6C6C6C,
    0x686868, 0x646464, 0x606060, 0x5C5C5C, 0x585858, 0x545454, 0x505050, 0x4C4C4C, 0x484848, 0x444444,
    // 0x404040,0x3C3C3C,0x383838,0x343434,0x303030,0x2C2C2C,0x282828,0x242424,0x202020,
];

const stepByStepColors = {

    // Colors for the boxes
    boxColors: [
        0xDC3205, // orange
        0x256475, // dark blue
        0x4FCBEC, // light blue
    ],

    //Colors for the parcel tables
    tableColors: [
        0xE96F23, // orange
        0x609DAA, // dark blue
        0x8BDEF0 // light blue
     ]
};

const random = seedrandom('BoxBot');

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex > 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const colorPickerHelper = []
const generateColors = (base, darkest, n) => {
    const d = darkest / Math.max(base.r, base.g, base.b);
    const i = (1 - d) / (n - 1);
    let colors = [];
    for (let c = d; c <= 1; c += i) {
        const color = (c * base.r << 16) | (c * base.g << 8) | (c * base.b);
        colors.push(color);
    }
    // Shuffle the color to NOT be in color scale order
    colors = shuffleArray(colors);
    colorPickerHelper.push(colors);
    return colors;
};

let grayColors, colorsLoaded;
const loadColors = () => {
    if (!colorsLoaded) {
        generateColors({ r: 249, g: 57, b: 6 }, 155, 21); // orange
        generateColors({ r: 79, g: 212, b: 248 }, 167, 10); // light teal
        generateColors({ r: 52, g: 139, b: 162 }, 85, 27); // dark teal
        generateColors({ r: 164, g: 164, b: 164 }, 84, 30); // grey
        grayColors = shuffleArray(greyColors4); // StepByStep gray colors
    }
    colorsLoaded = true;
}
loadColors();

let colorMap = {}
let colorPaletteIndex = 0;
let colorIndexCounter = 0;

export const getNextColor = (id, noGrayScales) => {
    // If noGrayScales is asked - don't return color from colorMap bc it can be in grayScale
    if (!noGrayScales && id in colorMap) {
        return colorMap[id];
    }

    // If no gray scales allowed, move to next color palette index
    if (noGrayScales && colorPaletteIndex === colorPickerHelper.length - 1)
        colorPaletteIndex = 0;

    const col = colorPickerHelper[colorPaletteIndex][colorIndexCounter];
    colorPaletteIndex = (colorPaletteIndex + 1) % colorPickerHelper.length;
    colorIndexCounter = (colorIndexCounter + 1) % colorPickerHelper[colorPaletteIndex].length;

    // Don't store noGrayScale requested colors
    if (id && !noGrayScales)
        colorMap[id] = col;

    return col;
}

export const getNextStepColor = (parcelIndexInStep) => {
    if (parcelIndexInStep > stepByStepColors) {
        console.error('stepByStepColors only has 3 colors available');
    }
    const colors = [stepByStepColors.boxColors[parcelIndexInStep], stepByStepColors.tableColors[parcelIndexInStep]];
    return colors;
};

let grayScaleIndex = 0;
export const getNextGrayColor = () => {
    const color = grayColors[grayScaleIndex];
    // Update grayScaleIndex
    grayScaleIndex = (grayScaleIndex + 1) % grayColors.length;
    return color;
}

export const cBoxbotOrange = 0xf3740e;
export const cBoxBlue = "#256372";
