import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DoneIcon from '@material-ui/icons/Done'
import LockIcon from '@material-ui/icons/Lock';

import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotificationDialog, showNotification, authUser } from '../../app/AppActions'
import { openDeleteDialog, closeDeleteDialog, setEditUser } from '../AdminActions'
import { browserHistory } from 'react-router'
import { users } from '../../api'
import useOrganizationUserCountLimits from './useOrganizationUserCountLimits'
import { notificationDialogSeverity } from '../../core/Constants'

//////////////////////////////////////////////////////////////////////////////

const DeleteDialog = ({ editUser, deleteDialogOpen, onDeleteUser, onCloseDeleteDialog }) => (
    <Dialog open={deleteDialogOpen} onClose={onCloseDeleteDialog}>
        <DialogTitle onClose={onCloseDeleteDialog}>{'Delete user'}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                This will completely delete the user.
        </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onDeleteUser(editUser)}>Delete</Button>
        </DialogActions>
    </Dialog>
)


const UsersEditHeader = (props) => {
    const { user, editUser, deleteDialogOpen, classes } = props
    const { onCreateUser, onUpdateUser, onResetPass, onDeleteUser } = props
    const { onOpenDeleteDialog, onCloseDeleteDialog } = props

    const [officeUsersAllowed, loadingOperatorsAllowed] = useOrganizationUserCountLimits();
    if (editUser.email == null) {
        return (
            <div />
        )
    }

    let deleteButton, storeButton, deleteButton2, storeButton2, resetButton, resetButton2, infoLabel


    // Creating a new user
    if (!editUser._id) {
        storeButton = (
            <Button disabled={!officeUsersAllowed && !loadingOperatorsAllowed} startIcon={<DoneIcon />} onClick={() => onCreateUser(editUser)} >Create</Button>
        )
        storeButton2 = (
            <IconButton disabled={!officeUsersAllowed && !loadingOperatorsAllowed} onClick={() => onCreateUser(editUser)} >
                <DoneIcon />
            </IconButton>
        )

    // Modifying existing user
    } else {
        deleteButton = (
            <Button startIcon={<DeleteForeverIcon />} onClick={onOpenDeleteDialog} disabled={user.email === editUser.email || editUser.roles.includes('superadmin')}  >
                Delete
            </Button>
        )
        deleteButton2 = (
            <IconButton onClick={onOpenDeleteDialog} disabled={user.email === editUser.email || editUser.roles.includes('superadmin')} >
                <DeleteForeverIcon />
            </IconButton>
        )
        resetButton = (
            <Button startIcon={<LockIcon />} onClick={() => onResetPass(editUser)} disabled={editUser.oauth} >
                Reset password
            </Button>
        )
        resetButton2 = (
            <IconButton onClick={() => onResetPass(editUser)} disabled={editUser.oauth} >
                <LockIcon />
            </IconButton>
        )
        storeButton = (
            <Button disabled={!officeUsersAllowed && !loadingOperatorsAllowed} startIcon={<DoneIcon />} onClick={() => onUpdateUser(editUser)}>
                Save
            </Button>
        )
        storeButton2 = (
            <IconButton disabled={!officeUsersAllowed && !loadingOperatorsAllowed} onClick={() => onUpdateUser(editUser)}>
                <DoneIcon />
            </IconButton>
        )
    }

    if (!officeUsersAllowed && !loadingOperatorsAllowed) {
        infoLabel = <p className={classes.textStyle}>Maximum user count limit reached</p>
    }

    return (
        <Toolbar>
            <div className={classes.desktop}>
                {deleteButton}
                {resetButton}
                {storeButton}
                {infoLabel}
            </div>
            <div className={classes.tablet}>
                {deleteButton2}
                {resetButton2}
                {storeButton2}
                {infoLabel}
            </div>
            <DeleteDialog editUser={editUser} deleteDialogOpen={deleteDialogOpen} onDeleteUser={onDeleteUser} onCloseDeleteDialog={onCloseDeleteDialog} />
        </Toolbar>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        user: state.user,
        editUser: state.admin.editUser,
        deleteDialogOpen: state.admin.deleteDialogOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateUser: (user) => {
            delete user.error;
            let errors = []

            if (!user.email.includes("@")) errors.push("@")
            if (user.name.length < 1) errors.push("name length")
            if (user.manualSet) {
                // Manual password set
                if (user.password.length < 1) errors.push("password length")
                if (user.password !== user.passwordConfirmation) errors.push("password conf")
            }
            if (errors.length) {
                user.error = errors
                dispatch(setEditUser(user))
                return
            }

            let sendEmail = "full";
            if (user.manualSet) {
                if (user.sendEmail) sendEmail = "welcome"
                else sendEmail = null
            }
            const manualSet = user.manualSet

            user = {
                email: user.email,
                password: user.password,
                roles: user.roles,
                name: user.name,
                organization: user.organization,
                sendEmail: sendEmail,
                tabletMode: ((user.roles.includes('loadingOperationsUser') && user.roles.length === 1) ? true : false) // Check if users ONLY role is loadingOperationsUser
            }

            const query = { email: user.email };
            users.query(query)
                .then(result => {
                    if (result.data.length === 0) {
                        if (!manualSet) {
                            user.password = Math.random().toString(36).slice(2)
                        }
                        users.create(user)
                            .then(() => {
                                dispatch(showNotification(`${user.email} created`));
                                browserHistory.push('/admin/users#0');
                            })
                            .catch(err => {
                                dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                            });
                    }
                    else {
                        dispatch(showNotificationDialog("A user with an identical e-mail address already exists", 'Invalid e-mail', notificationDialogSeverity.warning));
                    }
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                });
        },
        onUpdateUser: (user) => {
            delete user.modified;
            delete user.tabletMode;

            users.patch(user._id, user)
                .then(() => {
                    dispatch(showNotification(`${user.email} updated`))
                    users.authenticate()
                        .then(auth => dispatch(authUser(auth.user)))
                    browserHistory.push('/admin/users#0')
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onResetPass: (user) => {
            users.requestPasswordResetAdmin(user.email)
                .then(() => {
                    dispatch(showNotification(`${user.email} password reset requested`))
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onDeleteUser: (user) => {
            users.remove(user._id)
                .then(() => {
                    dispatch(closeDeleteDialog())
                    dispatch(showNotification(`${user.email} deleted`))
                    browserHistory.push('/admin/users#0')
                })
                .catch(err => {
                    dispatch(closeDeleteDialog())
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                });
        },
        onOpenDeleteDialog: () => {
            dispatch(openDeleteDialog())
        },
        onCloseDeleteDialog: () => {
            dispatch(closeDeleteDialog())
        },
    }
}

const styles = theme => ({
    desktop: theme.desktop,
    tablet: theme.tablet,
    textStyle: { ...theme.textStyle, margin: theme.spacing(1) }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersEditHeader))

//////////////////////////////////////////////////////////////////////////////
