import React, { forwardRef } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import { cargoSpaceArrangement } from '../../../core/Constants';

import { withStyles } from '@material-ui/core/styles';
import get from 'lodash.get';

//////////////////////////////////////////////////////////////////////////////

const CargoSpacesTableBody = (props) => {
    const { classes } = props;

    /**
     * Special ToggleButton component.
     * This had to be done, because Material UI ToggleButton toggle highlight breaks when the component
     * is wrapped in a Tooltip-component.
     */
    const ToggleButtonWithTooltip = forwardRef(({ tooltipProps, ...props }, ref) => {
        return (
            <Tooltip { ...tooltipProps }>
                <span>
                    <ToggleButton ref={ref} { ...props } />
                </span>
            </Tooltip>
        )
    })

//////////////////////////////////////////////////////////////////////////////

    return (
        <TableBody>

            {/* Rows for each cargo space */}
            {props.solution?.cargoSpaces.map((row, i) => {
                return (
                    <TableRow
                        key={i}
                        hover
                        onClick={() => props.onRowClick(i)}
                    >
                        {/* Cargo space index */}
                        <TableCell>
                            {`${i + 1} / ${props.solution?.cargoSpaces.length}`}
                        </TableCell>

                        {/* Preview image */}
                        <TableCell className={classes.previewImageCell} padding='none'>
                            <div className={classes.previewImageContainer}>
                                {props.previewImages.length > 0 ?
                                    <img
                                        className={classes.previewImageStyle}
                                        src={props.previewImages[i]}
                                        alt={`Solution preview ${i}`}
                                    />
                                    :
                                    <CircularProgress
                                        disableShrink
                                        color='primary'
                                        style={{  alignSelf: 'center' }}

                                    />
                                }
                            </div>
                        </TableCell>

                        {/* Data cells */}
                        {props.columns.map((col, j) => {

                            const cellValue = col.conversion ?
                                col.conversion(get(row, col.field))
                                :
                                get(row, col.field)

                            return (
                                <TableCell key={j}>
                                    <span>
                                        {cellValue}
                                    </span>
                                </TableCell>
                            )
                        })}

                        {/* Packing optimization goal selector */}
                        <TableCell onClick={(e) => e.stopPropagation()} padding='none'>
                            <Box className={classes.toggleButtonGroupContainer}>
                                <ToggleButtonGroup
                                    exclusive
                                    value={row.packingOptimizationGoal}
                                >
                                    <ToggleButtonWithTooltip
                                        tooltipProps={{
                                            title: 'Standard packing goal'
                                        }}
                                        value={'Standard'}
                                        onClick={() => props.onSelectedArrangementChange(i, cargoSpaceArrangement.standard)}
                                        disabled={props.isArrangementDisabled(cargoSpaceArrangement.standard, i) || props.disableChanges}
                                        style={
                                            props.isArrangementDisabled(cargoSpaceArrangement.standard, i) ?
                                                { pointerEvents: 'none', borderRadius: '5px 0px 0px 5px' }
                                                :
                                                { borderRadius: '5px 0px 0px 5px' }
                                        }
                                    >
                                        <ControlCameraIcon />
                                    </ToggleButtonWithTooltip>

                                    <ToggleButtonWithTooltip
                                        tooltipProps={{
                                            title: 'Spread packing goal'
                                        }}
                                        value={'Spread'}
                                        onClick={() => props.onSelectedArrangementChange(i, cargoSpaceArrangement.spread)}
                                        disabled={props.isArrangementDisabled(cargoSpaceArrangement.spread, i) || props.disableChanges}
                                        style={
                                            props.isArrangementDisabled(cargoSpaceArrangement.spread, i) ?
                                                { pointerEvents: 'none', borderRadius: '0px 5px 5px 0px' }
                                                :
                                                { borderRadius: '0px 5px 5px 0px' }
                                        }
                                    >
                                        <ZoomOutMapIcon />
                                    </ToggleButtonWithTooltip>

                                </ToggleButtonGroup>
                            </Box>
                        </TableCell>


                        {/* Export PDF -button */}
                        <TableCell padding='checkbox'>
                            <Button
                                color='secondary'
                                variant='outlined'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    props.onExportPdfClick(i);
                                }}
                            >
                                Export pdf
                            </Button>
                        </TableCell>

                    </TableRow>
                )
            })}
        </TableBody>
    );

};

//////////////////////////////////////////////////////////////////////////////

CargoSpacesTableBody.defaultProps = {
    previewImages: [],
    columns: [],
    solution: {}
}


const styles = (theme) => ({
    previewImageCell: {
        width: '14rem',
        height: '7rem'
    },
    previewImageContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    previewImageStyle: {
        height: 'auto',
        width: '100%',
        alignSelf: 'center'
    },
    toggleButtonGroupContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    }
});


export default withStyles(styles)(CargoSpacesTableBody);
