import React from 'react'
import CargoDataTable from './CargoDataTable/CargoDataTable'

import { Info, infoTexts } from '../InfoDialog'
import { getFirstInvalidParcel } from '../../core/Validations'
import { stackabilityLookup, lashingLookup } from '../../core/Constants'


//////////////////////////////////////////////////////////////////////////////


export default function ParcelTableEdit(props) {

    // const { classes } = props;


    /**
     * Initialize the header title for the parcel.lashing column
     * @returns the desired header title
     */
    const lashingTitle = (
        <div style={{ display: "flex", width: "80px", fontFamily: 'inherit' }}>
            Reserve fullwidth
            <Info info={infoTexts.lashing} onClick={props.setInfoDialog} />
        </div>
    )


    /**
     * Delete parcel from selected row
     */
    const onParcelDelete = () => {
        const deleted = props.parcels.find(x => x.tableData.deleting)
        const newData = props.parcels.filter(x => !x.tableData.deleting)

        // Update error message if one was present previously
        const invalidParcel = getFirstInvalidParcel(newData)
        if (invalidParcel) {
            props.setErrorMessage(`Parcel with id ${invalidParcel.id} has invalid data: ${invalidParcel.field} ${invalidParcel.message}`)
        }
        props.checkUnpackableParcels(newData, deleted) // Set's data internally
    }



    /**
     * Initialize the table columns
     */
    const columns = [
        {
            label: 'Id',
            field: 'id',
            align: 'left'
        },
        {
            label: `Length (${props.cargoDataUnit.text})`,
            field: 'dimensions.x',
            conversion: value => { return value / props.cargoDataUnit.value },
            align: 'right',
        },
        {
            label: `Width (${props.cargoDataUnit.text})`,
            field: 'dimensions.y',
            conversion: value => { return value / props.cargoDataUnit.value },
            align: 'right',
        },
        {
            label: `Height (${props.cargoDataUnit.text})`,
            field: 'dimensions.z',
            conversion: value => { return value / props.cargoDataUnit.value },
            align: 'right',
        },
        {
            label: 'Weight (kg)',
            field: 'weight',
            align: 'right',
        },
        {
            label: 'Quantity',
            field: 'quantity',
            align: 'right'
        },
        {
            label: 'Stackability',
            field: 'stackability',
            conversion: (s) => { return stackabilityLookup[s] },
            align: 'left'
        },
        {
            label: lashingTitle,
            field: 'lashing',
            conversion: (l) => { return lashingLookup[l] },
            align: 'left'
        },
        {
            label: 'Description',
            field: 'description',
            align: 'left'
        }
    ];


//////////////////////////////////////////////////////////////////////////////


    return (
        <CargoDataTable
            columns={columns}
            data={props.parcels}
            setData={props.setData}
            unpackable={props.unpackable}
            setUnpackable={props.setUnpackable}
            onRowDelete={onParcelDelete}
            loadingData={props.isLoading}
            disabled={props.disabled}
            cargoDataUnit={props.cargoDataUnit}
            checkUnpackableParcels={props.checkUnpackableParcels}
            excelDropzone={props.excelDropzone}
        />
    )
}
