import React, { useEffect, useState, useRef } from 'react'

import OptimizerCargoView from './optimize&inspect/OptimizerCargoView'
import OptimizerStorageView from './cargoSpace/OptimizerStorageView'
import OptimizerOptimizeView from './optimize&inspect/OptimizerOptimizeView'
import OptimizerLoadingView from './OptimizerLoadingView'
import ProgressSteps from './ProgressSteps'
import InitialInfoDialog from './InitialInfoDialog'
import BackDialog from './BackDialog'
import InfoDialog from './InfoDialog'

import Units from '../core/Units/Units'
import { getFirstInvalidParcel } from '../core/Validations'
import { progressSteps, planStates, sendModes, notificationDialogSeverity } from "../core/Constants"
import { solutionPackingStatus } from '../loadingAssistant/Constants'
import { addButtonPropsDisabledAndTooltips } from './utils'

import { showNotificationDialog } from '../app/AppActions'
import {
    setProgress,
    setParcels,
    setDeletedParcels,
    setStorages,
    setSolution,
    setSelectedParcel,
    resetOptimizer,
    setInitialInfo,
    setAutoSave,
    setUnsaved,
    setBasicInfoOpen,
    setPlanState,
    setSavedSolution,
    setDisableChanges,
    setSendMode,
    setShowCalculationOverlay,
    setNewJobId,
} from './OptimizeActions'
import { setUnits } from '../core/Units/UnitActions'

import { solutions, checkAuth, loadingAssistantApi, optimize as optimizeApi } from '../api'

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import { cloneDeep } from 'lodash'


//////////////////////////////////////////////////////////////////////////////


const defaultZ = 9

export const stepLabels = {
    cargoData: 'Cargo Data',
    cargoSpace: 'Cargo Space',
    optimizeAndInspect: 'Optimize & Inspect',
    loading: 'Loading'
}


//////////////////////////////////////////////////////////////////////////////


const Optimize = (props) => {
    const [unpackableParcels, setUnpackableParcels] = useState(null) // parcels that won't fit to cargo space
    const [faultyParcels, setFaultyParcels] = useState(null) // parcels that have invalid data
    const [multipleFaultyParcels, setMultipleFaultyParcels] = useState(null) // state where there are both unpackable and faulty parcels
    const [backDialog, setBackDialog] = useState({ open: false, callback: null })
    const [octagonOpen, setOctagonOpen] = useState(true)
    const [infoDialog, setInfoDialog] = useState(null)
    const [tempName, setTempName] = useState('')
    const [fetching, setFetching] = useState(true) // State that indicates if we are fetching a saved solution. Passed to OptimizerOptimizeView, and from there to OptimizeSolutionsView

    const { user } = props;
    const {
        parcels,
        deletedParcels,
        rules,
        solution,
        storages,
        progress,
        planState,
        autoSave,
        name,
        selectedParcel,
        newJobId,
        unsaved,
        basicInfoOpen,
        disableChanges,
        sendMode,
        showCalculationOverlay
    } = props.optimizer


    const {
        onSetProgress,
        onSetPlanState,
        onSetParcels,
        onSetDeletedParcels,
        onSetStorages,
        onSetSolution,
        onSetSavedSolution,
        onResetOptimizer,
        onSetInitialInfo,
        onSetAutoSave,
        onSetSelectedParcel,
        onCheckSolutionName,
        onSetUnsaved,
        onSetBasicInfoOpen,
        onSendToLoading,
        onResetUnits,
        onSetDisableChanges,
        onSetSendMode,
        onSetShowCalculationOverlay,
        onSetNewJobId,
        onShowNotificationDialog
    } = props.reduxDispatch;

    const preventReset = props.location.state ? props.location.state.preventReset : false
    const urlId = props.params.id

    // unsaved put to a ref for alertUser()-function
    const unsavedRef = useRef(null);
    unsavedRef.current = unsaved;



    useEffect(() => {
        if (!preventReset && !urlId) onSetBasicInfoOpen(true) // Open up the 'New Loading Plan'-dialog.
        checkAuth() // Check that user is still authenticated.

        /**
         * Add an event listerner to window that listens to 'beforeunload' -events.
         * This event listener detects when the page is being unloaded, for example
         * when user is trying to navigate out of the page.
         * This event listener uses alertUser()-function that opens up an alert-popup
         * where user has to either confirm, or cancel, navigation out of the page.
         * More info on the 'beforeunload'-event: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
         */
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        }

    // Disable exhaustive-deps becuase we intentionally want to run this hook only once at the initial render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // Load solution from the id in url.
    useEffect(() => {
        if (urlId && !solution) {
            setFetching(true)
            onSetSavedSolution(urlId)
        } else {
            setFetching(false);
        }
        // Needs to listen only urlId changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlId, solution])


    useEffect(() => {
        if (progress !== progressSteps.optimize && Boolean(selectedParcel))
            onSetSelectedParcel(null)
    }, [progress, selectedParcel, onSetSelectedParcel])


    useEffect(() => {
        // preventReset is set when Loading Plan redirects the user into 'New Plan' page
        if (preventReset) {
            // "Clear" browserHistory state, otherwise it will stay even whit browser refresh
            browserHistory.replace({
                pathname: `/optimizer/${solution._id}`,
                state: {}
            })
            if (basicInfoOpen)
                onSetBasicInfoOpen(false);
            return
        }

        // if the url contains a solution id don't reset the optimizer
        if (urlId) {
            if (basicInfoOpen)
                onSetBasicInfoOpen(false);
            return
        }
        // If the user has pending solution being optimized, don't reset the optimizer state and redirect straight to inspection page
        const isOptimizerPollingOn = newJobId !== null;
        if (isOptimizerPollingOn) {
            navigate(progressSteps.optimize)
            enterState(planStates.inspect)
            onSetBasicInfoOpen(false)
        } else {
            if (basicInfoOpen) {
                resetErrors()
                onResetUnits()
                onResetOptimizer()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basicInfoOpen, onResetOptimizer])


    /**
     * When loading plan is moved to loading, disable changes so that user
     * cannot make any changes to the loading plan after the fact.
     */
    useEffect(() => {
        if (planState === planStates.loading) {
            onSetDisableChanges(true);
        }
        onSetDisableChanges(false);
    }, [onSetDisableChanges, planState])


    /**
     * This use effect keeps track when user is moving away from /optimizer to another boxbot-page.
     * setRouteLeaveHook doesn't handle navigation out of boxbot as a whole, that is handled by 'beforeunload'-eventlistener.
     * If user is trying to navigate out of /optimizer while having unsaved changes, then show alert
     * that asks user to confirm if they want to re-navigate.
     */
     useEffect(() => {
        props.router.setRouteLeaveHook(props.route, confirmNavigation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsaved, disableChanges, props.route, props.router]);


    /**
     * This helper function is called when window detects a 'beforeunload'-event.
     * The purpose of this function is to show the alert-popup when user is trying to
     * navigate out of boxbot as a whole.
     * // NOTE: The event.returnValue string that is returned here might not display on the prompt.
     * On newer versions of different browsers the prompt includes a pre-determined message that
     * cannot be changed. On older browsers the prompt shows the returned string.
     * @param {Object} event
     * @returns a string
     */
    const alertUser = (event) => {
        if (!unsavedRef.current) { // No unsaved changes made
            return false;
        }

        event.preventDefault();
        return event.returnValue = "Are you sure you want to leave? Changes you've made might not be saved";
    }


    /**
     * Helper function for setRouteLeaveHook that is used to confirm navigation when user
     * is trying to navigate out of /optimize when user has unsaved changes, or when
     * the loading plan calculation/recalculation is ongoing.
     * @param {Object} nextLocation
     * @returns true/false depending on if user selects to navigate out of /optimizer.
     */
    const confirmNavigation = (nextLocation) => {

        // If user is navigating to '/signin' when de-authenticated, don't prompt user.
        if (nextLocation.pathname === '/signin' && nextLocation.state?.deauthenticated) {
            return null
        };

        // User is trying to navigate out of /optimize with unsaved changes
        if (unsaved && !disableChanges && nextLocation.pathname !== '/optimizer') {
            // If user tries to navigate out of /optimizer, show user a confirmation window
            if (window.confirm("Are you sure you want to leave? Changes you've made might not be saved")) { // If user selects to navigate out of /optimizer
                return true;
            } else { // If user selects cancel in the confirm-window
                props.router.goForward(); // Go "back" to /optimizer
                return false;
            }
        }

        // User is trying to navigate out of /optimize while loading plan is still being calculated.
        else if (disableChanges && planState !== planStates.loading) {
            if (window.confirm('Loading plan is still being calculated. Do you want to leave?')) {
                cancelCalculation();
                return true;
            } else {
                props.router.goForward();
                return false;
            }
        }
    };


    // TODO: Move functions for loading plan calculations to Optimize

    /**
     * Cancel loading plan calculation
     */
    const cancelCalculation = () => {

        // When user presses the Cancel-button, immediately stop showing the calculating overlay and show the cargo spaces again
        onSetShowCalculationOverlay(false);

        // Also set disableChanges to false so that the 'Save'- and 'Export PDF'-buttons are enabled after the 'Cancel'-button press instead of when the cancel operation is done
        onSetDisableChanges(false);

        optimizeApi.cancel(newJobId)
            .then(res => {
                onShowNotificationDialog('Loading plan optimization cancelled by user', 'Calculation cancelled', notificationDialogSeverity.info)
                onSetSendMode(sendModes.canceled)
                onSetNewJobId(null);
            })
            .catch(err => {
                onShowNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)
                onSetSendMode(sendModes.error)
            });
    }



    const navigate = value => {
        setOctagonOpen(false)
        setTimeout(() => {
            onSetProgress(value)
            setOctagonOpen(true)
        }, 10)
    }

    function enterState(value) {
        if (planState !== value)
            onSetPlanState(value)
    }

    function resetErrors() {
        setUnpackableParcels(null)
        setFaultyParcels(null)
        setMultipleFaultyParcels(null)
    }


    /**
     * Set unpackableParcels-state with an error-message that is shown on the optimizer navigation-component and with
     * an array of parcels that don't fit the cargo space.
     * @param {Array} data - Array of parcels that don't fit into the cargo space
     */
    const setUnpack = data => {
        if (data == null) {
            setUnpackableParcels(null)
            return;
        }

        const quantity = data.reduce((a, b) => a + b.quantity, 0) // Count how many parcels don't fit to the cargo space
        setUnpackableParcels({
            buttonProps: {
                error: true,
                errorLabel: `${quantity} parcel${quantity > 1 ? "s" : ''} won't fit`,
            },
            data: data
        })
    }

    /**
     * Set multipleFaultyParcels-state with an error message that is shown on the optimizer navigation component.
     * Parcel that is multipleFaulty is a parcel that doesn't fit the cargo space, and has invalid id.
     * By default in this case there are multiple parcels that are invalid, that's why the message says 'Multiple parcels have errors'
     */
    const setMultipleFaulty = () => {
        setMultipleFaultyParcels({
            buttonProps: {
                error: true,
                errorLabel: `Multiple parcels have errors`
            }
        });
    };

    /**
     * Set faultyParcels with an error-message that is shown on the optimizer navigation-component.
     * Faulty parcels are ones that have either invalid Id, or invalid dimensions/weight determined by getFirstInvalidParcel()
     */
    const setFaulty = () => {
        setFaultyParcels({
            buttonProps: {
                error: true,
                errorLabel: `Invalid parcels`
            }
        });
    };


    /**
     * // TODO: This function is way too large, cut it into smaller separate sub-functions.
     * Check if parcel(s) fit the cargo space user has selected
     * @param {Array} parcels - All parcels in a loading plan
     * @param {Object} changedParcel - Parcel that user changed
     * @param {Object} storageDimensions - Dimension-object of the cargo space
     * @param {Number} storageWeightLimit - Weight limit of the cargo space
     * @param {*} loadingDirection - Loading direction of the cargo space
     * @returns an array of unpackable parcels
     */
    const checkUnpackableParcels = (parcels, changedParcel, storageDimensions, storageWeightLimit, loadingDirection) => {

        // If changed parcel is being deleted, then add changedParcel to deletedParcels
        if (changedParcel?.tableData?.deleting) {
            let deletedParcelsCopy = cloneDeep(deletedParcels);
            deletedParcelsCopy.push(changedParcel);
            onSetDeletedParcels(deletedParcelsCopy);
        }

        /**
         * Always validate that parcels have unique id's.
         * First go through all parcels and check each parcel for duplicate id's.
         * If there's duplicate id's, add an Id-error to parcel.errors.
         * Then take all parcels that have an errors-field and check if all parcels have only Id-errors.
         * If yes, then use setFaulty() to display an error-message on the optimizer navigation-component.
         */
        let unpackable = parcels.filter(p => p.errors != null) || [];
        parcels.forEach(p => {
            const errors = p.errors != null ? p.errors : [];
            if (parcels.find(parc => parc.id === p.id && parc.tableData.id !== p.tableData.id) != null) {
                errors.push({
                    field: 'Id',
                    message: 'Must be unique'
                })
            }

            if (errors.length > 0) {
                p.submitted = true;
                p.errors = errors;
                unpackable.push(p);
            } else {
                if (p.errors) delete p.errors
                if (p.submitted) delete p.submitted
            }
        })

        // Get all the parcels that have errors-field
        let parcelsWithErrors = parcels.filter(p => p.errors != null && p.errors.length > 0);

        /**
         * Check if there's parcels that have unique Id, and an Id-error.
         * If these kinds of parcels are found, they need to be removed from parcelsWithErrors,
         * and the Id-errors must be removed.
         */
        let parcelsWithIdErrors = parcelsWithErrors.filter(p => p.errors.some(err => err.field === 'Id'))
        parcelsWithIdErrors.forEach(p => {

            // If parcel with an invalid id is the only parcel with a specific id, then it's no longer invalid
            if (parcelsWithIdErrors.filter(parc => parc.id === p.id).length === 1) {
                if (p.errors.map(err => err.field).every(field => field === 'Id')) {
                    parcelsWithErrors = parcelsWithErrors.filter(parc => parc.tableData.id !== p.tableData.id); // Remove parcel from parcelsWithErrors if parcel only has Id-error(s)
                }

                // Remove the 'Id'-error from parcel
                const idx = parcels.map(parc => parc.tableData.id).indexOf(p.tableData.id);
                parcels[idx].errors = parcels[idx].errors.filter(err => err.field !== 'Id');
            }
        })

        /**
         * Display an error-message on the optimizer navigation-component, if all parcels
         * that have errors only have Id-errors
         */
        if (parcelsWithErrors.length > 0) {
            if (parcelsWithErrors.map(p => p.errors.map(err => err.field)).flat().every(field => field === 'Id')) {
                setFaulty();
                setMultipleFaultyParcels(null);
                setUnpack(null)
            }
        } else {
            setFaultyParcels(null);
            setMultipleFaultyParcels(null);
            setUnpack(null);
        }


        /**
         * // HACK:
         * loadingDirection is passed as hacky solution to prevent loadingDirection setting bug.
         * Correct way to handle this would require complete refactoring of this function, which requires refactoring to whole Optimizer and it's children.
         */
        if (!loadingDirection && storages[0].loadingDirection === null) {
            // Cargo Space isn't set yet, don't bother with checking for unpackable parcels
            onSetParcels(parcels)
            return
        }

        if (!storageDimensions) storageDimensions = storages[0].dimensions;
        if (!storageWeightLimit) storageWeightLimit = storages[0].weightLimit;
        const biggerCargoSide = Math.max(storageDimensions.x, storageDimensions.y)
        const smallerCargoSide = Math.min(storageDimensions.x, storageDimensions.y)


        /**
         * Check if parcel(s) fit the cargo space.
         * When user edits/duplicates a parcel, check that the changed parcel fits the cargo space.
         * If user removes a parcel, then no need to check any parcels for fits.
         * If user restores a parcel, then check all parcels that they fit the cargo space.
         */
        const parcelsToCheck = changedParcel ? (changedParcel.tableData.deleting ? [] : [changedParcel]) : parcels;
        parcelsToCheck.forEach(p => {
            let submitted;
            const errors = p.errors != null ? p.errors : []

            // Helper function that adds an error-field to a parcel that doesn't fit the cargo space
            const addError = (field, message = 'Unpackable') => {
                submitted = true;
                errors.push({ field: field, message: message })
            }

            const smallerBoxSide = Math.min(p.dimensions.x, p.dimensions.y)
            const biggerBoxSide = Math.max(p.dimensions.x, p.dimensions.y)

            if (p.dimensions.z > storageDimensions.z) addError("Height")
            if (storageWeightLimit > 0 && p.weight > storageWeightLimit) addError("Weight")
            if (biggerBoxSide > biggerCargoSide || (biggerBoxSide < biggerCargoSide && smallerBoxSide > smallerCargoSide)) {
                addError("Width")
                addError("Length")
            }

            if (submitted) {
                p.submitted = true;
                p.errors = errors;
                unpackable.push(p);
            } else {
                if (p.errors?.length === 0) delete p.errors
                if (p.submitted) delete p.submitted
            }
        })

        let newParcelsWithErrors = parcels.filter(p => p.errors != null && p.errors.length > 0);

        // Depending on what errors parcels include, show one of different error-messages on the optimizer navigation-component
        if (newParcelsWithErrors.length > 0) {
            const errorFields = newParcelsWithErrors.map(p => p.errors.map(err => err.field)).flat(); // Get all error fields from all parcels
            if (!errorFields.includes('Id')) { // If every invalid parcel has something else than Id-errors
                setFaultyParcels(null);
                setMultipleFaultyParcels(null);
                setUnpack(newParcelsWithErrors);
            } else if (!errorFields.every(f => f === 'Id') && errorFields.some(f => f === 'Id')) { // If there's both types of errors, dimension/weight and Id
                setFaultyParcels(null);
                setMultipleFaulty();
                setUnpack(null);
            }
        } else {
            setFaultyParcels(null);
            setMultipleFaultyParcels(null);
            setUnpack(null);
        }


        onSetParcels(parcels)
        return unpackable
    }



    let buttonProps = [
        {
            ...unpackableParcels?.buttonProps,
            ...faultyParcels?.buttonProps,
            ...multipleFaultyParcels?.buttonProps,
            onClick: () => navigate(0)
        },
        {
            onClick: () => {
                // TODO Disabled due to Cylinders
                const invalidParcel = getFirstInvalidParcel(parcels)
                if (invalidParcel) {
                    onShowNotificationDialog(`Parcel with id ${invalidParcel.id} has invalid data: ${invalidParcel.field} ${invalidParcel.message}`, 'Invalid parcel data', notificationDialogSeverity.warning)
                    return
                }

                navigate(progressSteps.cargoSpace)
            }
        },
        {
            onClick: () => {
                const invalidParcel = getFirstInvalidParcel(parcels)
                // If there is invalidParcel, don't let user progress to optimize & inspect page
                if (invalidParcel) {
                    onShowNotificationDialog(`Parcel with id ${invalidParcel.id} has invalid data: ${invalidParcel.field} ${invalidParcel.message}`, 'Invalid parcel data', notificationDialogSeverity.warning)
                    return
                }
                // Storage and unpackableParcels validation is done on every render - if they are invalid, this button is disabled

                // Make sure that storages have unique id's and priorities are in correct order
                onSetStorages([...storages.map((storage, i) => {
                    const id = storage.id ?? i.toString()

                    // Higher the value, higher the priority
                    const priority = storages.length - (i + 1);
                    return { ...storage, id, priority }
                })])

                if (!solution && planState < planStates.optimize) {
                    enterState(planStates.optimize)
                }

                navigate(progressSteps.optimize)
            }
        },
        {
            onClick: () => {
                navigate(progressSteps.loading)
            }
        }
    ]
    buttonProps = addButtonPropsDisabledAndTooltips(buttonProps, solution, planState, parcels, unpackableParcels, rules, unsaved, storages, disableChanges)

    // const disableChanges = planState === planStates.loading
    const stepProps = [
        { completed: planState > planStates.clear || progress > progressSteps.cargoData },
        { completed: planState >= planStates.optimize },
        { completed: planState >= planStates.inspect },
        { completed: solution && !solution.cargoSpaces.some((s => s.packingStatus !== solutionPackingStatus.packed)) }
    ];

    const progressBar = <ProgressSteps
        step={progress}
        state={planState}
        progressSteps={progressSteps}
        buttonProps={buttonProps}
        stepProps={stepProps}
        isLoading={solution && solution.cargoSpaces.some(s => s.packingStatus >= solutionPackingStatus.readyToPack)}
        user={user} />

    let component;
    if (progress === progressSteps.cargoData) {
        component = <OptimizerCargoView
            octagonOpen={octagonOpen}
            parcels={parcels}
            setParcels={onSetParcels}
            deletedParcels={deletedParcels}
            setDeletedParcels={onSetDeletedParcels}
            progress={progressBar}
            infoDialog={infoDialog}
            setInfoDialog={setInfoDialog}
            name={name}
            nextButtonProps={buttonProps[1]}
            z={defaultZ}
            checkUnpackableParcels={checkUnpackableParcels}
            unpackable={unpackableParcels}
            setUnpackable={setUnpackableParcels}
            disableChanges={disableChanges}
            sendMode={sendMode}
            setSendMode={value => onSetSendMode(value)}
            planState={planState}
        />
    } else if (progress === progressSteps.cargoSpace) {
        component = <OptimizerStorageView
            octagonOpen={octagonOpen}
            infoDialog={infoDialog}
            setInfoDialog={setInfoDialog}
            checkUnpackableParcels={(dim, weight, loadingDirection) => checkUnpackableParcels(parcels, null, dim, weight, loadingDirection)}
            progress={progressBar}
            z={defaultZ}
            disableChanges={disableChanges}
            nextButtonProps={buttonProps[2]}
            planState={planState}
        />
    } else if (progress === progressSteps.optimize) {
        component = <OptimizerOptimizeView
            {...props.optimizer}
            octagonOpen={octagonOpen}
            warnAboutUnsaved={setBackDialog}
            setSolution={(solution) => {
                onSetSolution(solution)
                enterState(planStates.inspect)
                onSetProgress(progressSteps.optimize)
            }}
            setUrlId={solution => {
                if (solution)
                    browserHistory.replace({
                        pathname: `/optimizer/${solution._id}`
                    })
            }}
            setAutoSave={value => onSetAutoSave(value)}
            setUnsaved={value => onSetUnsaved(value)}
            setName={newName => onSetInitialInfo(autoSave, newName)}
            progress={progressBar}
            onInitRender={() => checkUnpackableParcels(parcels, null, storages[0].dimensions, storages[0].weightLimit)}
            unpackableParcels={unpackableParcels}
            setInfoDialog={setInfoDialog}
            visualAids={props.optimizer.visualAids}
            disableChanges={disableChanges}
            setDisableChanges={value => onSetDisableChanges(value)}
            nextButtonProps={buttonProps[3]}
            fetching={fetching}
            sendMode={sendMode}
            setSendMode={value => onSetSendMode(value)}
            showCalculationOverlay={showCalculationOverlay}
            setShowCalculationOverlay={value => onSetShowCalculationOverlay(value)}
            cancel={cancelCalculation}
            setNewJobId={value => onSetNewJobId(value)}
            planState={planState}
        />
    }
    else {
        component = <OptimizerLoadingView
            {...props.optimizer}
            setSolution={(solution) => {
                onSetSolution(solution)
            }}
            progress={progressBar}
            z={defaultZ}
            onSendToLoading={onSendToLoading}
        />
    }

    return (
        <React.Fragment>
            {!preventReset &&
                <InitialInfoDialog
                    open={basicInfoOpen}
                    onClose={() => {
                        onSetBasicInfoOpen(false)
                        onSetUnsaved(false)
                        browserHistory.push('/home')
                    }}
                    onCheckSolutionName={onCheckSolutionName}
                    onConfirm={(autoSave, name) => {
                        onSetBasicInfoOpen(false)
                        onSetInitialInfo(autoSave, name)
                        setTempName('')
                    }}
                    name={tempName}
                    setName={setTempName}
                    user={user}
                />
            }
            {component}
            <BackDialog open={backDialog.open} dialog={backDialog} onClose={() => setBackDialog({ open: false, callback: null })} />
            <InfoDialog info={infoDialog} onClose={() => setInfoDialog(null)} />
        </React.Fragment>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        user: state.user,
        optimizer: state.optimizer,
        cargoSpaceUnit: state.unit.cargoSpace
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            onSetProgress: (progress) => dispatch(setProgress(progress)),
            onSetPlanState: (value) => dispatch(setPlanState(value)),
            onSetParcels: (parcels) => dispatch(setParcels(parcels)),
            onSetDeletedParcels: (deletedParcels) => dispatch(setDeletedParcels(deletedParcels)),
            onSetStorages: (storages) => dispatch(setStorages(storages)),
            onSetSolution: (solution) => dispatch(setSolution(solution)),
            onResetOptimizer: () => dispatch(resetOptimizer()),
            onSetUnsaved: (value) => dispatch(setUnsaved(value)),
            onSetAutoSave: (value) => dispatch(setAutoSave(value)),
            onSetInitialInfo: (autoSave, name) => dispatch(setInitialInfo(autoSave, name)),
            onSetSelectedParcel: (parcel) => dispatch(setSelectedParcel(parcel)),
            onSetBasicInfoOpen: (value) => dispatch(setBasicInfoOpen(value)),
            onSetDisableChanges: (value) => dispatch(setDisableChanges(value)),
            onSetSendMode: (value) => dispatch(setSendMode(value)),
            onSetShowCalculationOverlay: (value) => dispatch(setShowCalculationOverlay(value)),
            onSetNewJobId: (id) => dispatch(setNewJobId(id)),
            onShowNotificationDialog: (message, title, severity) => {
                dispatch(showNotificationDialog(message, title, severity))
            },
            onCheckSolutionName: (name, user) => {
                return new Promise((resolve) => {
                    solutions.list({ name: name, organization: user.organization._id })
                        .then(res => {
                            resolve(res.data.length === 0)
                        })
                        .catch(() => resolve(false))
                })
            },
            onSendToLoading: (solution) => {
                loadingAssistantApi.create(solution)
                    .then(res => {
                        dispatch(setPlanState(planStates.loading))
                        dispatch(setSolution(res))
                    })
                    .catch(err => {
                        dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                    })
            },
            onSetSavedSolution: (id) => {
                dispatch(setBasicInfoOpen(false))
                dispatch(setProgress(progressSteps.optimize))
                solutions.get(id)
                    .then(res => {
                        dispatch(setInitialInfo(false, res.name))
                        dispatch(setUnits(res.viewModel.units))
                        dispatch(setSavedSolution(res))
                    })
                    .catch(err => {
                        dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                        browserHistory.push('/home')
                    })
            },
            onResetUnits: () => dispatch(setUnits({ parcels: Units.cm, cargoSpace: Units.m }))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Optimize)

//////////////////////////////////////////////////////////////////////////////
