import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';

import get from 'lodash.get';

//////////////////////////////////////////////////////////////////////////////

const CargoSpacesTableHeader = (props) => {
    const { classes } = props;

//////////////////////////////////////////////////////////////////////////////

    return (
        <TableHead>
            <TableRow key={'normalColumnsRow'}>

                {/* Add two empty cells for the cargo space index, and the preview image */}
                <TableCell />
                <TableCell />

                {props.columns.map((col, i) => {
                    return (
                        <TableCell key={i}>
                            {col.label}
                        </TableCell>
                    )
                })}

                <TableCell><span style={{ font: 'inherit' }}>Packing goal</span></TableCell>
                <TableCell />

            </TableRow>



            {/* Solution totals -row */}
            <TableRow key={'totalColumnsRow'} className={classes.totalColumnsRow}>
                {props.solution != null &&
                    <>
                        {/* Cargo space index -column*/}
                        <TableCell>
                            <b>Total</b>
                        </TableCell>

                        {/* Preview image -column */}
                        <TableCell />

                        {/* Packed parcels, used length, packed weight and packed volume -columns */}
                        {props.totalColumns.map((col, i) => {

                            const cellValue = col.conversion ?
                            col.conversion(get(props.solution, col.field))
                            :
                            get(props.solution, col.field)

                            return (
                                <TableCell key={i}>
                                    <b>
                                        {cellValue}
                                    </b>
                                </TableCell>
                            )
                        })}

                        {/* Cargo space dimensions -column */}
                        <TableCell />

                        {/* Packing optimization goal -goal */}
                        <TableCell />

                        {/* Export PDF -column */}
                        <TableCell />
                    </>
                }
            </TableRow>
        </TableHead>
    );

};

//////////////////////////////////////////////////////////////////////////////

CargoSpacesTableHeader.defaultProps = {
    columns: []
}

const styles = (theme) => ({

    /**
     * This style is required to make sure that when user scrolls the table,
     * the headers don't merge together.
     */
    totalColumnsRow: {
        backgroundColor: '#fff',
        top: 63,
        position: 'sticky',
        zIndex: 99
    }
});


export default withStyles(styles)(CargoSpacesTableHeader);
