import React, { useState, useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/styles';


/**
 * TextField wrapper with integrated custom Number value validation
 * Triggers onChanged event only with valid values
 * @param {Number} unit - unit value
 * @param {Any} resetValue - Any type of value that triggers the value to be reset from props
 * @param {Boolean} acceptZero - is "can't be 0" error text displayed
 * @param {Boolean} acceptNegative - Determines if negative values are accepted
 * @param {*} rest - Passed forward to TextField component
 * @returns
 */
const NumberTextField = (props) => {

    const {
        classes,
        value,
        onChange,
        unit,
        resetValue,
        acceptZero,
        acceptNegative,
        ...rest
    } = props;

    const [viewValue, setViewValue] = useState(props.value / unit);
    const [errorLabel, setErrorLabel] = useState(props.helperText || (!acceptZero && value <= 0 ? "can't be 0" : ''));

    // const thisElement = useRef(null);


    useEffect(() => {
        if (!acceptZero && acceptNegative) {
            console.error('Prop acceptNegative in component NumberTextField cannot be enabled without enabling prop acceptZero');
        }
    }, [acceptZero, acceptNegative]);


    useEffect(() => {
        setViewValue(props.value / unit)
        // Intentionally disregard props.value depency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unit, resetValue])


    const handleChange = e => {

        // Replace all commas with dots
        const newValue = e.target.value.replace(/[/,]/g, ".");

        setViewValue(newValue);

        // Pass mmValue to be passed parent
        const mmValue = parseFloat(newValue) * unit;
        const validMmValue = Number.isNaN(mmValue) ? 0 : parseInt(mmValue);

        // gap the resolution to millimeters by parsing the value into int
        onChange(validMmValue);

        // Set Error messages if input is less than 0
        if (!acceptZero && !acceptNegative) {
            setErrorLabel(validMmValue <= 0 ? "Value cannot be 0 or negative" : null);
        }
        if (acceptZero && !acceptNegative) {
            setErrorLabel(validMmValue < 0 ? "Value cannot be negative" : null);
        }
    }


    return (
        <TextField
            {...rest}
            className={classes.numberTextField}
            error={Boolean(errorLabel) || props.error}
            helperText={errorLabel || props.helperText}
            value={viewValue || ''}
            onChange={handleChange}
            inputProps={{
                ...props.inputProps
            }}
            type='number'
        />
    )
}


const styles = theme => ({
    numberTextField: theme.numberTextField
})

export default withStyles(styles)(NumberTextField)
