import { useState, useEffect } from 'react'
import threeEntryPoint from './threeEntryPoint';

const presetThreerImage = (three, solution) => {
    three.clear();
    three.drawCargoSpace(solution, 0, true);
    return three.exportThree();
}

/**
 *
 * @param {object} preset - cargoSpacePreset object
 * @returns {object} solution - Three acceptable solution
 */
const presetToSolution = preset => ({
    cargoSpaces: [{
        storages: [{
            dimensions: {
                x: preset.x,
                y: preset.y,
                z: preset.z
            },
            location: {
                x: 0,
                y: 0,
                z: 0
            },
            // TODO trailer ??
        }],
        packedParcels: []
    }],
})

/**
 *
 * @param {[Object]} cargoPresets - array of cargo space objects
 * @returns {{[key: string]: string}} - dictionary of {cargoPreset.text: imageData }
 */
const usePresetThreer = (cargoPresets) => {
    const [three, setThree] = useState(null);
    const [state, setState] = useState({
        images: {},
        imageIndex: 0
    })

    // Create only one three and dispose it when unmounting
    useEffect(() => {
        let entryPoint = threeEntryPoint({
            containerElement: null,
            zoomFactor: 1,
            style: { width: 120, height: 80 },
            preventResize: true,
            noBorders: true,
        })
        setThree(entryPoint);

        // Dispose when unmounting
        return () => {
            entryPoint.dispose();
        }
    }, [])

    useEffect(() => {
        let timeout;

        if (three && cargoPresets && state.imageIndex < cargoPresets.length) {
            timeout = setTimeout(() => {
                // Get current preset
                const preset = cargoPresets[state.imageIndex];
                // Convert preset to solution
                const sol = presetToSolution(preset);
                // Create a image of the solution
                const presetImage = presetThreerImage(three, sol);
                // Save the image to state and update state
                state.images[preset.text] = presetImage;
                setState({ images: state.images, imageIndex: state.imageIndex + 1 })
            }, 0)
        }

        // Clear timeout when unmounting
        return () => {
            clearTimeout(timeout);
        }
    }, [cargoPresets, three, state.images, state.imageIndex])

    return state.images;
}

export default usePresetThreer
