import React, { useState, useEffect, useCallback } from 'react'

import LoadingAssistantTabletTableInfoDialog from './LoadingAssistantTabletLoadingPlanInfoDialog';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/core';

import useWindowSize from '../../core/useWindowSize';
import RoundedButton from '../components/RoundedButton';


const solutionPackingStatus = {
    undefined: 0,
    readyToPack: 1,
    inProgress: 2,
    packed: 3
}

const LoadingAssistantTabletHome = props => {


    const {
        classes
    } = props;


    const [infoDialogState, setInfoDialogState] = useState({
        open: false,
        content: null
    });

    const [inProgressDeck, setInProgressDeck] = useState([])
    const [readyDeck, setReadyDeck] = useState([])

    const [mounted, setMounted] = useState(false);




    /**
     * Component has mounted
     */
    useEffect(() => {
        setMounted(true);
    }, [])





    /**
     * Card for loading plans in progress
     * @param {Object} solution
     * @returns a card
     */
    const inProgressCard = useCallback((solution) => {
        const loadingPlan = solution.cargoSpaces[solution.cargoSpaceIndex];
        const assigneeNames = loadingPlan.assignments.map(a => a.user.name).filter(name => name) // filter added to make sure no undefined's can get in

        return (
            <Card className={classes.card} key={`${solution._id} ${solution.cargoSpaceIndex}`} elevation={4} square={true}>

                <Box className={classes.cardHeaderContainer}>
                    <CardHeader
                        className={classes.inProgressCardHeader}
                        title={
                            <Typography noWrap variant='h5'>
                                {solution.name}
                            </Typography>
                        }
                        subheader={`Cargo Space: ${solution.cargoSpaceIndex + 1} / ${solution.cargoSpaces.length}`}
                    />
                    <Box className={classes.cardAssigneeChipContainer}>
                        {assigneeNames.map(name => {
                            return (
                                <Chip
                                    style={{ margin: 2 }}
                                    key={assigneeNames.findIndex(n => n === name)}
                                    label={name}
                                    size='small'
                                />
                            )
                        })}
                    </Box>
                </Box>


                <CardActions className={classes.cardActionsContainer}>
                    <IconButton
                        onClick={() => setInfoDialogState(s => ({ open: true, content: solution }))}
                    >
                        <InfoOutlinedIcon />
                    </IconButton>
                    <Box className={classes.progressContainer}>
                        <Box>
                            <Typography>
                                {`Progress: ${Math.round((loadingPlan.progress * loadingPlan.packedParcels.length) / 100)} / ${loadingPlan.packedParcels.length} parcels`}
                            </Typography>
                        </Box>
                        <Box>
                            <LinearProgress color="primary" className={classes.progress} variant="determinate" value={loadingPlan.progress} />
                        </Box>
                    </Box>
                    <RoundedButton
                        variant='contained'
                        color='primary'
                        onClick={() => props.onStartLoading(solution)}
                        endIcon={<ArrowForwardIosIcon />}
                    >
                        continue loading
                    </RoundedButton>
                </CardActions>
            </Card>
        )
    }, [classes.card, classes.cardActionsContainer, classes.cardAssigneeChipContainer, classes.cardHeaderContainer, classes.inProgressCardHeader, classes.progress, classes.progressContainer, props])




    /**
     * Card for loading plans that are ready for loading
     * @param {Object} solution
     * @returns a card
     */
    const readyCard = useCallback((solution) => {

        return (
            <Card className={classes.card} key={`${solution._id} ${solution.cargoSpaceIndex}`} elevation={4} square={true}>
                {/* Card information content */}
                <CardHeader
                    className={classes.readyForLoadingCardHeader}
                    title={
                        <Typography noWrap variant='h5'>
                            {solution.name}
                        </Typography>
                    }
                    subheader={`Cargo Space: ${solution.cargoSpaceIndex + 1} / ${solution.cargoSpaces.length}`}
                />
                <CardActions className={classes.cardActionsContainer}>
                    <IconButton
                        onClick={() => setInfoDialogState(s => ({ open: true, content: solution }))}
                    >
                        <InfoOutlinedIcon />
                    </IconButton>
                    <RoundedButton
                        variant='contained'
                        color='primary'
                        onClick={() => props.onStartLoading(solution)}
                        endIcon={<ArrowForwardIosIcon />}
                    >
                        start loading
                    </RoundedButton>
                </CardActions>
            </Card>
        )
    }, [classes.card, classes.cardActionsContainer, classes.readyForLoadingCardHeader, props])



    /**
     * Create the card decks from the array of loadingAssistants
     * @param {Array} loadingAssistants is an array of solutions
     */
    const createDecks = useCallback((loadingAssistants) => {

        let inProgressCards = [];
        let readyCards = [];

        loadingAssistants.forEach(x => {
            switch (x.cargoSpaces[x.cargoSpaceIndex].packingStatus) {
                case solutionPackingStatus.inProgress:
                    inProgressCards.push(inProgressCard(x));
                    break;
                case solutionPackingStatus.readyToPack:
                    readyCards.push(readyCard(x));
                    break;
                default:
                    break;
            }
        })

        setInProgressDeck(inProgressCards);
        setReadyDeck(readyCards);

    }, [inProgressCard, readyCard]);



    /**
     * After component has mounted, create decks from the solutions we get from LoadingAssistant.
     */
    useEffect(() => {
        if (mounted) {
            createDecks(props.loadingAssistants)
        }
    }, [props.loadingAssistants, mounted, createDecks])



    return (
        <div>
            <div>
                <LoadingAssistantTabletTableInfoDialog
                    infoDialogOpen={infoDialogState.open}
                    closeInfoDialog={() => setInfoDialogState(s => ({ ...s, open: false }))}
                    infoDialogContent={infoDialogState.content}
                />
            </div>
            <div className={classes.tabletHomePageContainer} style={{ height: `calc(${useWindowSize()[1]}px - 105px)` }}>
                <div className={classes.column}>
                    {/* In Progress */}
                    <Typography className={classes.cardDeckHeader} variant='h5'>
                        In Progress:
                    </Typography>
                    <Card className={classes.cardDeckContainer} square={true}>
                        {inProgressDeck.length > 0 ?
                            <List className={classes.cardDeckList} dense={true}>
                                {inProgressDeck.map((inProgressCard, i) => {
                                    return (
                                        <ListItem key={i}>
                                            {inProgressCard}
                                        </ListItem>
                                    )
                                })}
                            </List>
                            :
                            <CardContent className={classes.cardDeckContainerEmptyMessage}>
                                <Typography variant='h5'>
                                    No jobs currently in progress
                                </Typography>
                            </CardContent>
                        }
                    </Card>
                </div>

                <div className={classes.column}>
                    {/* Ready For Loading */}
                    <Typography className={classes.cardDeckHeader} variant='h5'>
                        Ready For Loading:
                    </Typography>
                    <Card className={classes.cardDeckContainer} square={true}>
                        {readyDeck.length > 0 ?
                            <List className={classes.cardDeckList} dense={true}>
                                {readyDeck.map((readyCard, i) => {
                                    return (
                                        <ListItem key={i}>
                                            {readyCard}
                                        </ListItem>
                                    )
                                })}
                            </List>
                            :
                            <CardContent className={classes.cardDeckContainerEmptyMessage}>
                                <Typography variant='h5'>
                                    No jobs ready for loading
                                </Typography>
                            </CardContent>
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}





const styles = theme => ({

    // CARDS
    card: {
        // margin: `${theme.spacing(2)}px`,
        width: `calc(100% - 2rem)`,
    },
    cardHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        // backgroundColor: 'lightblue'
    },
    inProgressCardHeader: {
        display: 'block',
        width: '50%',
        // backgroundColor: 'lightgreen'
    },
    cardAssigneeChipContainer: {
        display: 'flex',
        width: '50%',
        justifyContent: 'flex-end',
        padding: 10,
        flexWrap: 'wrap',
        // backgroundColor: 'lightblue'
    },
    readyForLoadingCardHeader: {
        display: 'block',
        width: '100%'
    },
    cardContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        width: '100%',
        justifyContent: 'space-between',
        // backgroundColor: 'green'
    },
    cardInfoContainer: {
        display: "flex",
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        fontSize: "1.3rem",
        overflow: "hidden",
        // backgroundColor: 'magenta'
    },
    cardAdditionalInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        width: 'auto',
        height: '100%',
        // backgroundColor: 'cyan'
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        // backgroundColor: 'lightgreen'
    },
    collapseCard: {
        marginTop: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(2)}px`,
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },

    // PROGRESS BAR
    progressContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '50%',
        paddingLeft: `${theme.spacing(2)}px`,
        paddingRight: `${theme.spacing(2)}px`,
        // backgroundColor: 'lightgreen'
    },
    progress: {
        width: '100%',
        height: 8,
        borderRadius: 10
    },

    // CARD DECK CONTAINERS
    tabletHomePageContainer: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        width: '100%',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        maxWidth: '50%'
    },
    cardDeckHeader: {
        marginBottom: 20,
        width: '100%',
    },
    cardDeckList: {
        position: 'relative',
        overflow: 'auto',
    },
    cardDeckContainer: {
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    cardDeckContainerEmptyMessage: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    // JOIN LOADING JOB DIALOG
    dialog: {
        height: 'auto',
        width: 550,
    }
})





export default withStyles(styles)(LoadingAssistantTabletHome)
