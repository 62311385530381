import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

//////////////////////////////////////////////////////////////////////////////

const OptimizerHeader = props => {
    const { name, progress, classes } = props

    return (
        <div className={classes.headerContainer} style={{ justifyContent: "space-between" }}>
            <div className={classes.headerTextContainer}>
                <h3 className={classes.headerLoadingPlanText}>Loading Plan</h3>
                <h2 className={classes.stepHeaderStyle}>{name}</h2>
            </div>
            {progress}
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        name: state.optimizer.name,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const styles = theme => ({
    headerContainer: theme.optimizer.headerContainer,
    headerTextContainer: theme.optimizer.headerTextContainer,
    headerLoadingPlanText: theme.optimizer.headerLoadingPlanText,
    stepHeaderStyle: theme.optimizer.stepHeaderStyle,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OptimizerHeader))

//////////////////////////////////////////////////////////////////////////////
