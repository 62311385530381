import Units from './Units'

const defaults = {
    cargoData: Units.cm,
    cargoSpace: Units.m,
}

export default (state = defaults, action) => {
    switch (action.type) {
        case "SET_CARGODATA_UNIT":
            return Object.assign({}, state, { cargoData: action.unit })
        case "SET_CARGOSPACE_UNIT":
            return Object.assign({}, state, { cargoSpace: action.unit })
        case "SET_UNITS":
            return Object.assign({}, state, {
                cargoData: action.units.parcels,
                cargoSpace: action.units.cargoSpace
            })
        default:
            return state
    }
}