import React from "react";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { withStyles } from "@material-ui/styles";

const GroupedButtons = (props) => {

    const {
        classes,
        count,
        onChange,
        containerStyle,
        disabled,
        textFieldStyle,
        textFieldInputProps,
        hideLabel,
    } = props;

    let iconButtonStyle = {
        marginTop: "21px",
        height: "28px",
        width: "28px",
        minHeight: "0px",
    };

    if (hideLabel)
        iconButtonStyle = {
            marginTop: "8px",
            height: "22px",
            width: "22px",
            minHeight: "0px",
        };

    return (
        <div style={containerStyle}>
            <Fab
                color="secondary"
                style={iconButtonStyle}
                disabled={disabled || count === 1}
                onClick={() => onChange(count - 1)}
            >
                <RemoveIcon />
            </Fab>
            <TextField
                className={classes.numberInputStyle}
                style={textFieldStyle}
                inputProps={{
                    style: { ...textFieldInputProps, textAlign: "center" },
                    type: "number",
                }}
                value={count}
                label={hideLabel ? "" : "Quantity"}
                onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value > 0) onChange(value);
                }}
            >
                {count}
            </TextField>
            <Fab
                color="secondary"
                style={iconButtonStyle}
                disabled={disabled}
                onClick={() => onChange(count + 1)}
            >
                <AddIcon />
            </Fab>
        </div>
    );
};


const styles = theme => ({
    numberInputStyle: theme.numberTextField
})

export default withStyles(styles)(GroupedButtons);
