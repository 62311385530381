import React, { useRef } from 'react';

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { tabletTheme } from '../theme';

/**
 * Wrapped <Button> element for dispalying a <CircularProgress> element when next state updates is expected to take a while
 * Automatically handles the CircularProgress sizing from the Button element
 *
 * @param {any} props - props passed to the MUI Button element
 * @param {Bool} loading - is api call etc in progress. when true circular progess is shown
 * @param {any} circularProps - props passed to the MUI Circular progress element
 * @returns
 */
const ResponsiveButton = (props) => {
    const { loading, circularProps, children, ...buttonProps } = props;
    const btnRef = useRef();

    buttonProps.style = buttonProps.style || {};
    let size;
    if (btnRef?.current) {
        // Keep the btn width and height same regardless of the content
        buttonProps.style.height = btnRef.current.offsetHeight;
        buttonProps.style.width = btnRef.current.offsetWidth;
        // Set the circular progress to be 85% of the buttons height
        size = circularProps?.size || buttonProps.style.height * 0.85;
    }

    return (
        <Button {...buttonProps} ref={btnRef} >
            {loading ? <CircularProgress style={{ color: props.variant === 'contained' ? '#ffffff' : tabletTheme.palette.primary.main }} size={size} {...circularProps} /> : children}
        </Button>
    )
}

export default ResponsiveButton;
