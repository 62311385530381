import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import CheckIcon from '@material-ui/icons/Check';

import set from 'lodash.set'

import DialogTitle from '../core/DialogTitle'
import NumberTextField from '../core/NumberTextField'
import TabSelectionMenuItem from '../core/TabSelectionMenuItem'
import { toUint, toNumber, validateParcelInput } from '../core/Validations'
import { stackabilityLookup, lashingLookup } from '../core/Constants'

import { validateTransformedParcelDimensions, validateTransformedParcelWeight } from './LMCUtils'

//////////////////////////////////////////////////////////////////////////////

import { withStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash'

//////////////////////////////////////////////////////////////////////////////

const isBoxChanged = (thisBox, originalBox) => {
    if (originalBox.dimensions.r > 0) {
        if (thisBox.dimensions.r.toString() !== originalBox.dimensions.r.toString()) return true;
    } else {
        if (thisBox.dimensions.x.toString() !== originalBox.dimensions.x.toString()) return true;
        if (thisBox.dimensions.y.toString() !== originalBox.dimensions.y.toString()) return true;
    }
    if (thisBox.dimensions.z.toString() !== originalBox.dimensions.z.toString()) return true;
    if (thisBox.weight.toString() !== originalBox.weight.toString()) return true;
    if (thisBox.stackability !== originalBox.stackability) return true;
    if (thisBox.lashing !== originalBox.lashing) return true;
    return false;
}


const DeleteDialog = props => {
    const { open, onClose, onDelete, title, classes, ...rest } = props;
    return (
        <Dialog open={open} onClose={onClose} {...rest}>
            <DialogTitle onClose={onClose}>
                Remove cargo item
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove cargo item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" className={classes.errorButton}
                    onClick={onDelete}
                    startIcon={<DeleteForeverIcon />}
                >
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const LoadingAssistantEditModal = props => {
    const { open, editBox, onClose, onDeleteBox, onBoxUpdate, classes } = props;
    const [state, setState] = useState({
        box: JSON.parse(JSON.stringify(editBox)),
        boxChanged: false,
        errors: [],
        isDeleting: false,
    })

    const [resetTextFields, setResetTextFields] = useState(false);

    const { box, boxChanged, errors, isDeleting } = state;

    const handleUpdate = box => {
        const validationErrors = validateParcelInput(box, box.dimensions, [])
        if (validationErrors.length) {
            setState({ ...state, errors: validationErrors })
            return
        }

        box.weight = toUint(box.weight) || 0
        if (box.dimensions.r > 0){
            box.dimensions.r = toNumber(box.dimensions.r);
            delete box.dimensions.x;
            delete box.dimensions.y;
        } else {
            box.dimensions.x = toNumber(box.dimensions.x);
            box.dimensions.y = toNumber(box.dimensions.y);
            delete box.dimensions.r;
        }
        box.dimensions.z = toNumber(box.dimensions.z);
        box.packedDimensions = { ...box.dimensions };

        onBoxUpdate(box)
    }



    const handleChange = (prop) => (event) => {

        // Set new value to box dimension
        set(box, prop, event);

        // Make a copy of box and then set boxes packedDimensions based on dimensions
        let boxCopy = cloneDeep(box);
        let axisFlipped = false;

        if (editBox.dimensions.x !== editBox.packedDimensions.x) {
            boxCopy.packedDimensions.y = boxCopy.dimensions.x;
            axisFlipped = true;
        } else {
            boxCopy.packedDimensions.x = boxCopy.dimensions.x
        }

        if (editBox.dimensions.y !== editBox.packedDimensions.y) {
            boxCopy.packedDimensions.x = boxCopy.dimensions.y;
            axisFlipped = true;
        } else {
            boxCopy.packedDimensions.y = boxCopy.dimensions.y
        }

        boxCopy.packedDimensions.z = boxCopy.dimensions.z;

        // Get box dimension and weight validations
        const boxDimensionValidations = validateTransformedParcelDimensions(boxCopy, props.solution?.cargoSpaces[props.solution.cargoSpaceIndex])
        const boxWeightValidation = validateTransformedParcelWeight(boxCopy, props.solution?.cargoSpaces[props.solution.cargoSpaceIndex])

        let newErrors = cloneDeep(errors);



        // Validate parcels weight
        if (boxWeightValidation.weightLimitBroken) {
            newErrors = newErrors.filter(el => el.field !== 'Weight')
            newErrors.push({ field: 'Weight', message: 'Weight over weight limit' })
        } else if (!boxWeightValidation.weightLimitBroken) {
            newErrors = newErrors.filter(el => el.field !== 'Weight')
        }

        /**
         * Validate parcels x- and y-axis.
         * This is a little more complicated, because validations are made on parcels packedDimensions.
         * If one or the other is out-of-bounds, then check which prop was changed and depending on that show error on the right textfield.
         */

        let xDim, yDim;
        if (!axisFlipped) {
            xDim = 'Length'
            yDim = 'Width'
        } else if (axisFlipped) {
            xDim = 'Width'
            yDim = 'Length'
        }

        if (boxDimensionValidations.xOutOfBounds) {
            newErrors = newErrors.filter(el => el.field !== xDim)
            newErrors.push({ field: xDim, message: 'Dimension invalid' })
        } else if (!boxDimensionValidations.xOutOfBounds) {
            newErrors = newErrors.filter(el => el.field !== xDim)
        }
        if (boxDimensionValidations.yOutOfBounds) {
            newErrors = newErrors.filter(el => el.field !== yDim)
            newErrors.push({ field: yDim, message: 'Dimension invalid' })
        } else if (!boxDimensionValidations.yOutOfBounds) {
            newErrors = newErrors.filter(el => el.field !== yDim)
        }

        // Validate parcels z-axis
        if (boxDimensionValidations.zOutOfBounds) {
            newErrors = newErrors.filter(el => el.field !== 'Height')
            newErrors.push({ field: 'Height', message: 'Dimension invalid' })
        } else if (!boxDimensionValidations.zOutOfBounds) {
            newErrors = newErrors.filter(el => el.field !== 'Height')
        }

        // If user inputs 0, then push a special error to newErrors to ensure that the apply-button is disabled in any error situation
        if (event <= 0) {
            newErrors = newErrors.filter(el => el.field !== 'zeroValue')
            newErrors.push({ field: 'zeroValue', message: '' })
        } else if (event > 0) {
            newErrors = newErrors.filter(el => el.field !== 'zeroValue')
        }

        setState({ ...state, box: box, errors: newErrors, boxChanged: isBoxChanged(boxCopy, editBox) })
    }


    useEffect(() => {
        setResetTextFields(r => !r);
    }, [editBox])


    return (
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} >
            <DialogTitle onClose={onClose}>
                {`Edit cargo item ${box.id}`}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>

                    <Grid item xs={4}>
                        <NumberTextField
                            className={classes.textFieldStyle}
                            value={box.dimensions.x}
                            unit={1}
                            onChange={(value) => {
                                handleChange('dimensions.x')(value)
                            }}
                            label='Length'
                            disabled={isDeleting}
                            error={Boolean(errors.find(e => e.field === 'Length'))}
                            helperText={errors.find(e => e.field === 'Length')?.message}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>mm</InputAdornment>
                            }}
                            resetValue={resetTextFields}

                        />
                    </Grid>

                    <Grid item xs={4}>
                        <NumberTextField
                            className={classes.textFieldStyle}
                            value={box.dimensions.y}
                            unit={1}
                            onChange={(value) => {
                                handleChange('dimensions.y')(value)
                            }}
                            label='Width'
                            disabled={isDeleting}
                            error={Boolean(errors.find(e => e.field === 'Width'))}
                            helperText={errors.find(e => e.field === 'Width')?.message}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>mm</InputAdornment>
                            }}
                            resetValue={resetTextFields}

                        />
                    </Grid>

                    <Grid item xs={4}>
                        <NumberTextField
                            className={classes.textFieldStyle}
                            value={box.dimensions.z}
                            unit={1}
                            onChange={(value) => {
                                handleChange('dimensions.z')(value)
                            }}
                            label='Height'
                            disabled={isDeleting}
                            error={Boolean(errors.find(e => e.field === 'Height'))}
                            helperText={errors.find(e => e.field === 'Height')?.message}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>mm</InputAdornment>
                            }}
                            resetValue={resetTextFields}

                        />
                    </Grid>

                    <Grid item xs={4}>
                        <NumberTextField
                            className={classes.textFieldStyle}
                            value={box.weight}
                            unit={1}
                            onChange={(value) => {
                                handleChange('weight')(value)
                            }}
                            label='Weight'
                            disabled={isDeleting}
                            error={Boolean(errors.find(e => e.field === 'Weight'))}
                            helperText={errors.find(e => e.field === 'Weight')?.message}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>Kg</InputAdornment>
                            }}
                            acceptZero
                            resetValue={resetTextFields}

                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            className={classes.textFieldStyle}
                            label="Stackability"
                            select
                            value={box.stackability}
                            onChange={(event) => handleChange('stackability')(event.target.value)}
                            disabled={isDeleting}
                        >
                            {Object.keys(stackabilityLookup).map((key) => (
                                <TabSelectionMenuItem key={key} value={key}>{stackabilityLookup[key]}</TabSelectionMenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            className={classes.textFieldStyle}
                            label="Reserve Fullwidth"
                            select
                            value={box.lashing}
                            onChange={(event) => handleChange("lashing")(event.target.value)}
                            disabled={isDeleting}
                        >
                            {Object.keys(lashingLookup).map((key) => (
                                <TabSelectionMenuItem key={key} value={key}>{lashingLookup[key]}</TabSelectionMenuItem>
                            ))}
                        </TextField>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="contained"
                    startIcon={<DeleteForeverIcon />}
                    className={classes.errorButton}
                    onClick={() => setState({ ...state, isDeleting: true })}
                    disabled={isDeleting}
                >
                    Remove
                </Button>
                <Box>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!boxChanged || errors.length > 0}
                        onClick={() => handleUpdate(box)}
                        startIcon={<CheckIcon />}
                    >
                        Apply
                    </Button>
                </Box>
            </DialogActions>
            <DeleteDialog
                open={isDeleting}
                title="Remove item"
                onClose={() => setState({ ...state, isDeleting: false })}
                onDelete={() => {
                    setState({ ...state, isDeleting: false })
                    onDeleteBox()
                }}
                classes={classes}
            />
        </Dialog>
    )
}

const styles = theme => ({
    largerFont: { fontSize: "1.5rem" },
    dialogContent: {
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    dialogActions: {
        justifyContent: "space-between",
        padding: '16px 24px',
    },
    textFieldStyle: {
        width: '90%'
    },
    errorButton: theme.errorButton,
})

export default withStyles(styles)(LoadingAssistantEditModal)
