import React, { useState, useEffect } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormLabel from '@material-ui/core/FormLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import ListSubheader from '@material-ui/core/ListSubheader'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import clsx from 'clsx';

import startCase from 'lodash.startcase'
import Toolbar from '../../core/Toolbar'
import Octagon from '../../core/Octagon'
import Checkbox from '../../core/Checkbox'
import { defaultCargoSpaceRulesObject, cargoSpaceTypes, cargoSpaceRules, notificationDialogSeverity } from '../../core/Constants'
import { colors } from '../../theme'
import { getLoadingDirectionObj } from '../../optimize/cargoSpace/OptimizerStorageView'
import { toUint } from '../../core/Validations'
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CargoSpacePresetEditHeader from './CargoSpacePresetEditHeader'
import CargoSpaceVisualAid from './CargoSpaceVisualAid'
import Threer from '../../three/Threer'

import FeatureEnabler from '../../app/FeatureEnabler'
import InfoDialog, { infoTexts, Info } from '../../optimize/InfoDialog'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotificationDialog } from '../../app/AppActions'
import { setEditPreset, closeBackDialog, openBackDialog, setOrganizations } from '../AdminActions'
import { browserHistory } from 'react-router'
import { users, cargoSpacePreset, organizations as organizationService } from '../../api'
import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

const backDialog = (backDialogOpen, onConfirmGoBack, onCloseBackDialog) => {
    return (
        <Dialog open={backDialogOpen} onClose={onCloseBackDialog}>
            <DialogTitle onClose={onCloseBackDialog}>{'Unsaved changes'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    All unsaved changes will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onConfirmGoBack()} >OK</Button>
            </DialogActions>
        </Dialog>
    )
}

const CargoSpacePresetEdit = (props) => {
    const [visualAidOpen, setVisualAidOpen] = useState(false)
    const [update, setUpdate] = useState(0)
    const [presetSol, setPresetSol] = useState(null)
    const [helperInfo, setHelperInfo] = useState(null)
    const { user, editCargoPreset, organizations, onFetchOrganizations } = props
    const { backDialogOpen, onConfirmGoBack, onCloseBackDialog, onGoBack, onSetPreset, onUpdateCargoPreset, params, classes } = props
    const { visualAids, negativeSpaces } = editCargoPreset;

    //
    // Former componentWillMount / DidMount etc are replaced with reacts useEffect function
    // See: https://reactjs.org/docs/hooks-effect.html for further information about using the Effect Hook
    //
    useEffect(() => {
        onSetPreset(params.id)
        if (!organizations.length)
            onFetchOrganizations(user)
    }, [onSetPreset, params.id, onFetchOrganizations, user, organizations.length]) // useEffect will be called at the initial rendering and whenever 'onSetPreset' or 'params.id' change

    useEffect(() => {
        setPresetSol(null)
        setTimeout(() =>
            setPresetSol({
                cargoSpaces: [
                    {
                        storages: [
                            {
                                dimensions: { x: editCargoPreset.x, y: editCargoPreset.y, z: editCargoPreset.z },
                                location: { x: 0, y: 0, z: 0 },
                                visualAids: visualAids,
                                negativeSpaces: negativeSpaces,
                                axles: editCargoPreset.cargoSpaceType === cargoSpaceTypes.trailer ? editCargoPreset.axles : []
                            },
                        ],
                        packedParcels: [],
                    }
                ]
            }), 0)
    }, [editCargoPreset.x, editCargoPreset.y, editCargoPreset.z, editCargoPreset.text, visualAids, negativeSpaces, editCargoPreset.axles, editCargoPreset.cargoSpaceType, update])

    if (!editCargoPreset || editCargoPreset === {})
        return <div />

    const parseAndUpdateVisualAidsAndNegativeSpaces = data => {
        editCargoPreset.negativeSpaces = data.filter(x => x.type === 0);
        editCargoPreset.visualAids = data.filter(x => x.type === 1);
        editCargoPreset.modified = true;
        onUpdateCargoPreset(editCargoPreset)
    }

    const GeneralSection = (editCargoPreset) => {
        const { text, x, y, z, loadingDirection, weightLimit } = editCargoPreset

        const organizationDisabled = !user.roles || !user.roles.includes("superadmin")
        let organizationName, organizationOptions;
        if (organizationDisabled) {
            organizationName = user.organization.name;
            organizationOptions = [{ _id: user.organization._id, name: organizationName }]
        } else {
            const orgName = organizations.find(x => x._id === editCargoPreset.organization)
            organizationName = orgName ? orgName.name : "";
            organizationOptions = organizations
        }

        const organizationSelection = (
            <div className={classes.textFieldWrapper}>
                <TextField
                    id="organization"
                    disabled={organizationDisabled}
                    select
                    label="Organization"
                    fullWidth={true}
                    value={organizationName}
                    type="text"
                    onChange={(value, child) => {
                        editCargoPreset.organization = organizations.find(x => x._id === child.key)._id;
                        editCargoPreset.modified = true;
                        onUpdateCargoPreset(editCargoPreset)
                    }}>
                    {organizationOptions.map(option => (
                        <MenuItem key={option._id} value={option.name}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        )

        const constrTextField = (label, value, stringId) => (
            <div className={classes.textFieldWrapper}>
                <TextField
                    id={stringId}
                    label={label}
                    fullWidth={true}
                    value={value}
                    onChange={e => {
                        editCargoPreset[stringId] = e.target.value;
                        editCargoPreset.modified = true;
                        onUpdateCargoPreset(editCargoPreset)
                    }}
                />
            </div>
        )

        const onDimensionChange = (e, dim) => {
            const value = toUint(e.target.value);
            if (value === null || value < 0) return;
            editCargoPreset[dim] = value;
            editCargoPreset.modified = true;
            onUpdateCargoPreset(editCargoPreset)
        }

        const textField = (text, value, onChange) => {
            return <TextField label={text} value={value} onChange={onChange} style={{ width: '8rem' }} >{text}</TextField>;
        };

        const times = <span style={{ display: 'inline-block', width: '5%', textAlign: 'center', marginTop: "27px" }}>&times;</span>;

        const weightLimitSection = (
            <FormGroup className={classes.noLeftPadding} >
                <FormControlLabel
                    style={{ marginLeft: "1px", color: colors.grey }}
                    control={
                        <Switch
                            checked={editCargoPreset.isWeightLimited}
                            color="primary"
                            onChange={() => {
                                editCargoPreset.isWeightLimited = !editCargoPreset.isWeightLimited
                                onDimensionChange({ target: { value: 0 } }, 'weightLimit')
                            }}
                        />
                    }
                    label="Weight Limit" />
                < Collapse in={editCargoPreset.isWeightLimited} style={{ marginBottom: "8px" }}>
                    <TextField
                        label="Weight Limit (kg)"
                        value={weightLimit}
                        onChange={e => onDimensionChange(e, 'weightLimit')}
                    />
                </Collapse>
            </FormGroup>
        )

        return (
            <form onSubmit={event => event.preventDefault()}>
                <Typography variant={'subtitle1'} >
                    <List ></List>
                </Typography>
                {organizationSelection}
                {constrTextField("Name", text, "text")}
                <div style={{ display: "flex" }}>
                    <div style={{ flexDirection: "row" }}>
                        <div className={classes.textFieldWrapper} style={{ dispaly: "flex", flexWrap: "wrap", width: "50%" }}>
                            {textField("Length (mm)", x, (e) => onDimensionChange(e, "x"))}{times}
                            {textField("Width (mm)", y, (e) => onDimensionChange(e, "y"))}{times}
                            {textField("Height (mm)", z, (e) => onDimensionChange(e, "z"))}
                            <IconButton onClick={() => setHelperInfo({ content: <div><img src='/assets/container_dimensions-alt.png' alt=" " style={{ paddingLeft: '4.5rem', paddingBottom: '3.25rem' }} /></div> })}>
                                <InfoIcon style={{ color: colors.bbTeal, paddingLeft: "0px" }} />
                            </IconButton>
                        </div>
                        <div className={classes.textFieldWrapper}>
                            {weightLimitSection}
                        </div>
                        <div className={classes.textFieldWrapper}>
                            <FormLabel style={{ margin: "4.9px" }}>Packing Direction:</FormLabel><br />
                            {["Top", "Back", "Side"].map(d => {
                                const direction = getLoadingDirectionObj(d)
                                let style = { margin: "10px" }
                                const image = <img src={direction.img} alt=" " style={{ height: '80px', display: 'block', margin: '10px', marginTop: '0px' }} />
                                if (loadingDirection === direction.strValue)
                                    style.backgroundColor = colors.bbTealLighter

                                return (
                                    <Button
                                        key={d}
                                        variant="contained"
                                        style={style}
                                        onClick={() => {
                                            editCargoPreset.loadingDirection = direction.strValue;
                                            editCargoPreset.modified = true;
                                            onUpdateCargoPreset(editCargoPreset)
                                        }}>
                                        <div>
                                            {direction.text}<br />
                                            {image}
                                        </div>
                                    </Button>
                                )
                            })}
                        </div>
                    </div>
                    <div className={classes.textFieldWrapper} style={{ width: "100%", height: "317px" }}>
                        {presetSol && <Threer solution={presetSol} index={0} noBorders />}
                    </div>
                </div>
            </form>
        )
    }

    const visualAidsSection = editCargoPreset => {
        visualAids.forEach(v => {
            v.type = 1;
            v.allowStacking = false;
        })
        const convertedNegSpaces = negativeSpaces.map(n => {
            n.type = 0;
            return n;
        });
        const data = visualAids.concat(convertedNegSpaces)
        const toggleVisualAidOpen = () => setVisualAidOpen(!visualAidOpen)
        return (
            <React.Fragment>
                <ListSubheader className={classes.visualAid} disableSticky >
                    <div style={{ width: "100%", display: "flex" }}>
                        <span onClick={toggleVisualAidOpen}>Visual Aids (Beta)</span>
                        <Info info={infoTexts.visualAids} onClick={setHelperInfo} />
                        <InfoDialog info={helperInfo} onClose={() => setHelperInfo(null)} />
                        <div style={{ width: "100%", height: "40px" }} onClick={toggleVisualAidOpen} />
                        <IconButton className={clsx(classes.expand, {
                            [classes.expandedOpen]: visualAidOpen
                        })}
                            onClick={toggleVisualAidOpen}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                    {/* </div> */}
                </ListSubheader>
                <Collapse in={visualAidOpen} timeout="auto" unmountOnExit>
                    <CargoSpaceVisualAid
                        data={data}
                        setData={data => {
                            setUpdate(update + 1)
                            parseAndUpdateVisualAidsAndNegativeSpaces(data)
                        }}
                    />
                </Collapse>
            </React.Fragment>
        )
    }

    const cargoSpaceTypeSection = editCargoPreset => (
        <div className={classes.textFieldWrapper}>
            <List subheader={<ListSubheader disableSticky>Cargo Space Type</ListSubheader>}>
                <RadioGroup onChange={e => {
                    editCargoPreset.cargoSpaceType = cargoSpaceTypes[e.target.name];
                    editCargoPreset.modified = true;
                    onUpdateCargoPreset(editCargoPreset);
                }} className={classes.radioGroup}>
                    {Object.keys(cargoSpaceTypes).filter(x => x !== "undefined").map(type => {
                        const isChecked = editCargoPreset.cargoSpaceType === cargoSpaceTypes[type];
                        return (
                            <FormControlLabel
                                key={type}
                                style={isChecked ? { color: "rgba(0, 0, 0, 0.87)" } : null}
                                name={type}
                                control={<Radio color="primary" checked={isChecked} />}
                                label={type.substr(0, 1).toUpperCase() + type.substr(1)}
                            />
                        )
                    })}
                </RadioGroup>
            </List>
        </div>
    )

    const axlesSection = editCargoPreset => {
        const axles = editCargoPreset.axles.length ? editCargoPreset.axles : [{ location: 0, weightLimit: 0 }, { location: 0, weightLimit: 0 }];
        const handleChange = (index, e) => {
            const name = e.target.name;
            const value = toUint(e.target.value);
            if (value === null || (name === "weightLimit" && value < 0))
                return;

            axles[index][name] = value;
            editCargoPreset.axles = axles;
            editCargoPreset.modified = true;
            onUpdateCargoPreset(editCargoPreset);
            setUpdate(update + 1)
        }
        return (
            <Collapse in={editCargoPreset.cargoSpaceType === cargoSpaceTypes.trailer} unmountOnExit >
                <div className={classes.textFieldWrapper}>
                    <List subheader={<ListSubheader disableSticky>Axles</ListSubheader>}>
                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                            <Typography style={{ width: '8rem', display: "flex", justifyContent: "center" }}>Front axle</Typography>
                            <Typography style={{ width: '8rem', display: "flex", justifyContent: "center" }}>Rear axle</Typography>
                        </div>
                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                            <TextField name="location" label={"Location (mm)"} value={axles[0].location} onChange={e => handleChange(0, e)} style={{ width: '8rem' }} />
                            <TextField name="location" label={"Location (mm)"} value={axles[1].location} onChange={e => handleChange(1, e)} style={{ width: '8rem' }} />
                        </div>
                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                            <TextField name="weightLimit" label={"Weight limit (kg)"} value={axles[0].weightLimit} onChange={e => handleChange(0, e)} style={{ width: '8rem' }} />
                            <TextField name="weightLimit" label={"Weight limit (kg)"} value={axles[1].weightLimit} onChange={e => handleChange(1, e)} style={{ width: '8rem' }} />
                        </div>
                    </List>
                </div>
            </Collapse >
        )
    }


    const rulesSection = (
        <div className={classes.textFieldWrapper}>
            <List subheader={<ListSubheader disableSticky>Rules</ListSubheader>}>
                {Object.keys(cargoSpaceRules).map(key => (
                    <Checkbox
                        key={key}
                        checked={editCargoPreset[cargoSpaceRules[key].name]}
                        label={startCase(cargoSpaceRules[key].displayName)}
                        secondary={startCase(cargoSpaceRules[key].description)}
                        onChange={value => {
                            editCargoPreset[cargoSpaceRules[key].name] = value.target.checked;
                            editCargoPreset.modified = true;
                            onUpdateCargoPreset(editCargoPreset);
                        }} />
                ))}
            </List>
        </div>
    )

    var toolBarTitle = editCargoPreset._id == null ? 'Create Cargo Space Preset' : 'Edit Cargo Space Preset'

    if (user.email == null || editCargoPreset.text == null) return <div />

    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title={toolBarTitle}
                onGoBack={() => onGoBack(editCargoPreset.modified)}>
                {backDialog(backDialogOpen, onConfirmGoBack, onCloseBackDialog)}
                <CargoSpacePresetEditHeader />
            </Toolbar>
            <div id="presetEditContainer" className={classes.adminContainer} >
                <Paper elevation={4}>
                    {GeneralSection(editCargoPreset)}
                    <Divider />
                    {/* Temporary hiding this features, see issue  #731*/}
                    {/* {visualAidsSection(editCargoPreset)} */}
                    <FeatureEnabler featureId={"cargoSpaceType"} >
                        <Divider />
                        {cargoSpaceTypeSection(editCargoPreset)}
                        {axlesSection(editCargoPreset)}
                    </FeatureEnabler>
                    <FeatureEnabler featureId={"optimizerRules"} >
                        <Divider />
                        {rulesSection}
                        <Divider />
                    </FeatureEnabler>
                </Paper>
            </div>
        </Octagon>
    )
}

//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
    return {
        user: state.user,
        editCargoPreset: state.admin.editCargoPreset,
        backDialogOpen: state.admin.backDialogOpen,
        organizations: state.admin.organizations.data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPreset: (id) => {
            users.get()
                .then(result => {
                    const user = result.user;
                    dispatch(setEditPreset({}))

                    if (!id) {
                        setTimeout(() => {
                            let newPreset = {
                                text: '',
                                x: 0,
                                y: 0,
                                z: 0,
                                loadingDirection: getLoadingDirectionObj(0).strValue,
                                weightLimit: 0,
                                isWeightLimited: false,
                                organization: user.organization._id,
                                ...defaultCargoSpaceRulesObject(),
                                negativeSpaces: [],
                                visualAids: [],
                                axles: [],
                                cargoSpaceType: cargoSpaceTypes.container,
                            }
                            dispatch(setEditPreset(newPreset))
                        }, 0)
                    } else {
                        cargoSpacePreset.get(id)
                            .then(res => {
                                res.isWeightLimited = res.weightLimit !== undefined && res.weightLimit > 0
                                dispatch(setEditPreset(res))
                            })
                            .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
                    }
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onUpdateCargoPreset: (preset) => {
            dispatch(setEditPreset(Object.assign({}, preset)))
        },
        onConfirmGoBack: () => {
            dispatch(closeBackDialog())
            browserHistory.go(-1)
        },
        onCloseBackDialog: () => {
            dispatch(closeBackDialog())
        },
        onGoBack: (modified) => {
            if (modified) {
                dispatch(openBackDialog())
            } else {
                browserHistory.go(-1)
            }
        },
        onFetchOrganizations: (user) => {
            if (!user || !user.roles.includes("superadmin")) return
            organizationService.query({})
                .then(res => {
                    dispatch(setOrganizations(res))
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
    }
}

const styles = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    adminContainer: theme.containers.admin,
    radioGroup: theme.radioGroup,
    noLeftPadding: {
        paddingLeft: "0px",
        marginLeft: "0px"
    },
    expand: theme.expand,
    expandedOpen: theme.expandedOpen,
    visualAid: {
        margin: theme.spacing(1, 1, 1, 1),
        padding: theme.spacing(0, 1, 0, 3),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        zIndex: 1,
    },
    container: {
        margin: theme.spacing(1),
        padding: theme.spacing(0, 1, 0, 1),
        display: "flex",
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CargoSpacePresetEdit))

//////////////////////////////////////////////////////////////////////////////
