import React from 'react'

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

//////////////////////////////////////////////////////////////////////////////

import { closeDrawer, openDrawer } from '../AppActions'
import { resetOptimizer } from '../../optimize/OptimizeActions'
import {
    Home,
    NewPlan,
    Admin,
    LoadingPlans,
    LoadingAssistant,
    LoadingAssistantCompletedJobs
} from './NavBardComponents';

//////////////////////////////////////////////////////////////////////////////

const Nav = (props) => {
    const { classes, location } = props
    const { drawerOpen, user, unsaved, onOpenDrawer, onCloseDrawer, onNavigate, onResetOptimizer } = props

    if (user._id) {

        const getMenuItems = () => {
            const commonProps = { onNavigate };
            const itemList = [];

            itemList.push(<Divider key='divider1' className={classes.menuDivider} />);

            // No roles
            if (!user.roles.length) {
                if (!user.tabletMode) {
                    itemList.push(<Home key="home" {...commonProps} />)
                }

                return itemList;
            }

            // Desktop mode
            if (!user.tabletMode) {
                itemList.push(<Home key="home" {...commonProps} />)
                itemList.push(<NewPlan key="newPlan" onNavigate={onNavigate} onResetOptimizer={onResetOptimizer} location={location} unsaved={unsaved} />)
                itemList.push(<LoadingPlans key="loadingPlans" {...commonProps} />)

                if (user.roles?.includes('officeAdmin') || user.roles?.includes('superadmin')) {
                    itemList.push(<Admin key="admin" {...commonProps} />)
                }

                return itemList;
            }

            // Tablet mode
            itemList.push(<LoadingAssistant key="loadingAssistant" {...commonProps} user={user} />)
            itemList.push(<LoadingAssistantCompletedJobs key="loadingAssistantCompletedJobs" {...commonProps} />)
            return itemList;
        }

        const menuItems = getMenuItems();
        menuItems.push(<Divider key='divider2' className={classes.menuDivider} />)
        menuItems.push(<Divider key='divider3' className={classes.menuDivider} />)

        return (
            <Drawer open={drawerOpen} className={`${classes.drawer}`}
                classes={{ paper: classes.drawerPaper }}
                onClose={open => open ? onCloseDrawer() : onOpenDrawer()}>
                <img src='/assets/boxbot.svg' className={classes.logo} alt=" " />
                <Divider />
                <List>
                    {menuItems}
                </List>
                <Divider />
            </Drawer>
        )
    }
    return <div />
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.drawer.open,
        user: state.user,
        unsaved: state.optimizer.unsaved
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenDrawer: () => {
            dispatch(openDrawer())
        },
        onCloseDrawer: () => {
            dispatch(closeDrawer())
        },
        onNavigate: pathname => {
            browserHistory.push(pathname)
            dispatch(closeDrawer())
        },
        onResetOptimizer: () => dispatch(resetOptimizer())
    }
}

const styles = theme => ({
    drawer: theme.navBar.drawer,
    menuDivider: theme.navBar.menuDivider,
    drawerPaper: theme.navBar.mobile,
    logo: theme.icons.mobileNavBarIcon,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Nav))

//////////////////////////////////////////////////////////////////////////////
