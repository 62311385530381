import React from 'react'

import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper'
import Zoom from '@material-ui/core/Zoom';


import { colors } from '../theme'
import { boxCardWidth, boxCardStyle } from './LoadingAssistantBoxCards'

import { CircularProgress, withStyles } from '@material-ui/core';

const displayDim = (parcel, dim, unit) => {
    const value = parcel.dimensions[dim] / unit.value;
    return `${Math.round(value, 1)}`
}

const times = <span style={{ display: 'inline-block', width: '5%', textAlign: 'center', margin: "4px" }}>&times;</span>;

const LoadingAssistantBoxCard = (props) => {
    const { parcel, img, unit, onBoxEdit, onSelectParcelToPack, previewParcel, setPreviewParcel, classes } = props;

    const thisParcelIsSelected = previewParcel && parcel.id === previewParcel.id
    const imageComponent = img ? <img src={img} alt={`parcelImage_${parcel.id}`} style={boxCardStyle} />
        // For some reason, div shows up 4 pixels lower than threer image --> height = 184 instead of 180
        : <div style={{ ...boxCardStyle, height: "184px", display: "flex", justifyContent: "center" }}><CircularProgress disableShrink color="primary" style={{ alignSelf: "center" }} /></div>

    return (
        <div id={`BoxCard${parcel.id}`}>
            <Paper key={parcel.id}
                className={`${classes.paperStyle} ${thisParcelIsSelected ? classes.paperStyleSelected : ''}`}
                onClick={() => {
                    if (!props.parcelSelectedByOtherUser) {
                        // Set this parcel this to be packed next
                        if (thisParcelIsSelected) {
                            onSelectParcelToPack(parcel);
                        } else {
                            // Changed parcel selection -> scroll to the selected parcel
                            let thisElement = document.getElementById(`BoxCard${parcel.id}`)
                            thisElement.scrollIntoView({ behavior: "smooth", inline: "center" })
                            setPreviewParcel(parcel);
                        }
                    }
                }}
            >
                {imageComponent}
                <div className={`${classes.largeText} ${classes.idText}`}>{parcel.id}</div>
                <div className={classes.largeText}>
                    {`${displayDim(parcel, 'x', unit)}`}{times}{`${displayDim(parcel, 'y', unit)}`}{times}{`${displayDim(parcel, 'z', unit)} ${unit.text}`}
                </div>
                <div className={classes.largeText}>{`Weight: ${parcel.weight} kg`}</div>
                <div style={{ display: thisParcelIsSelected ? "flex" : "none" }} className={classes.overlay}>
                    <span className={classes.selectTextStyle}>
                        SELECT
                    </span>
                </div>
                <div style={{ display: props.parcelSelectedByOtherUser ? 'flex' : 'none' }} className={classes.inLoadingOverlay}>
                    <div className={classes.inLoadingTextStyle}>{`This parcel is locked by another user`}</div>
                    <Chip
                        color='secondary'
                        label={props.parcelAssignment?.user}
                    />
                </div>
            </Paper>
            <Zoom in={thisParcelIsSelected} timeout={300}>
                <Fab
                    className={classes.editButton}
                    onClick={() => {
                        if (!props.parcelSelectedByOtherUser) {
                            onBoxEdit(parcel)
                        }
                    }}
                >
                    <EditIcon />
                </Fab>
            </Zoom>
        </div>
    )
}

const styles = theme => ({
    largeText: {
        paddingLeft: '0.6rem',
        fontSize: "1.2rem",
        marginTop: '-0.3rem'
    },
    idText: {
        fontSize: "1.5rem",
        marginTop: "-0.3rem",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    packButton: {
        position: "relative",
        maxHeight: "40px",
    },
    overlay: {
        position: "relative",
        height: "224px",
        width: "100%",
        bottom: "212px",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.2)",
        transition: 'all 1s ease'
    },
    inLoadingOverlay: {
        flexDirection: 'column',
        position: "relative",
        height: "224px",
        width: "100%",
        bottom: "212px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    xlButton: theme.xlButton,
    selectTextStyle: {
        fontSize: "2.8rem",
        color: colors.bbOrange,
        marginBottom: '10px',
        '-webkit-text-stroke': '2px rgb(121, 58, 7)',
    },
    inLoadingTextStyle: {
        fontSize: 22,
        display: 'block',
        overflow: 'auto',
        whiteSpace: 'normal',
        textAlign: 'center',
        padding: 10,
    },
    editButton: {
        float: "right",
        margin: "-60px 10px 0px 0px",
        background: theme.palette.common.white
    },
    paperStyle: {
        maxWidth: `${boxCardWidth}px`,
        maxHeight: '225px',
        margin: "16px",
        height: "225px",
        transition: "all 300ms ease",
    },
    paperStyleSelected: {
        border: `2px solid ${colors.bbOrange}`,
        transform: 'scale(1.15)',
        transition: "all 300ms ease",
    }
})

export default withStyles(styles)(LoadingAssistantBoxCard)
