import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import ActionLock from '@material-ui/icons/Lock'

import { users } from '../api'
import { browserHistory } from 'react-router'
import UserlessPage from '../app/UserlessPage';

const PasswordChange = (props) => {
    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [resetRequest, setResetRequest] = useState(null)

    const { id, token, onPasswordChange, classes } = props

    if (!resetRequest) {
        users.getResetRequest(id)
            .then(res => {
                setResetRequest(res)
            })
            .catch(err => {
                setResetRequest({ notFound: true })
            })
        return (
            <div />
        )
    }


    const submitHandler = event => {
        event.preventDefault()
        onPasswordChange(id, token, newPassword, passwordConfirmation)
            .then(() => {
                setNewPassword('')
                setPasswordConfirmation('')
            })
    }

    const changePassword = (
        <form onSubmit={submitHandler} style={{ width: '100%' }}>
            <div className={classes.textFieldWrapper}>
                <TextField
                    id='password'
                    label='New password'
                    fullWidth={true}
                    value={newPassword}
                    onChange={(event, value) => setNewPassword(event.target.value)}
                    type='password'
                    required
                />
            </div>
            <div className={classes.textFieldWrapper}>
                <TextField
                    id='passwordConfirmation'
                    label='Password confirmation'
                    fullWidth={true}
                    value={passwordConfirmation}
                    onChange={(event, value) => setPasswordConfirmation(event.target.value)}
                    type='password'
                    required
                />
            </div>
            <div className={classes.bottomDivider} />
            <div className={`${classes.textFieldWrapper} ${classes.horizontalCenter}`} >
                <Button variant="contained" color='primary' startIcon={<ActionLock />} onClick={submitHandler} >Change password</Button>
            </div>
            <div className={classes.bottomDivider} />
            <input type='submit' style={{ display: 'none' }} />
        </form>
    )

    const signinLink = (
        <Link component='button' color='primary' variant='body1' onClick={() => browserHistory.push('/signin')}>Sign In</Link>
    )

    const resetLink = (
        <Link component='button' color='primary' variant='body1' onClick={() => {
            browserHistory.push('/signin')
            setImmediate(() => {
                browserHistory.push({
                    pathname: '/passwordreset',
                    search: ''
                })
            })
        }}>
            new password reset link
        </Link>
    )

    const invalidLink = (
        <div className={classes.invalidLink}>
            <Typography className={classes.linkHintText} variant='body1' gutterBottom>
                Please
            </Typography>
            {signinLink}
            <Typography className={classes.linkHintText} variant='body1' gutterBottom>
                or request a
            </Typography>
            {resetLink}
        </div>
    )

    let content, infoText, link, linkLabel;
    if (resetRequest.notFound) {
        content = invalidLink
        infoText = "The reset link was invalid";
    } else if (new Date() > new Date(resetRequest.expiresAt)) {
        content = invalidLink
        infoText = "The reset link was expired";
    } else if (resetRequest.used) {
        content = invalidLink
        infoText = "The reset link was already used";
    } else {
        content = changePassword
        infoText = `Password reset for ${resetRequest.email}`
        link = 'Sign In'
        linkLabel = 'Remember your password?'
    }

    return (
        <UserlessPage
            title='Reset password'
            infoText={infoText}
            link={link}
            linkLabel={linkLabel}
            onLinkClick={() => browserHistory.push('/signin')}
        >
            {content}
        </UserlessPage>
    )
}

const styles2 = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    bottomDivider: theme.app.bottomDivider,
    horizontalCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    invalidLink: {
        display: 'flex',
        alignItems: 'baseline',
        margin: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    linkHintText: {
        color: 'rgba(0, 0, 0, 0.77)',
        marginLeft: '0.35rem',
        marginRight: '0.35rem'
    }
})

export default withStyles(styles2)(PasswordChange)
