import React, { useState, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import { withStyles } from '@material-ui/core/styles'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import ActionLock from '@material-ui/icons/Lock'

import Toolbar from '../core/Toolbar'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { notificationDialogSeverity } from '../core/Constants'
import { showNotification, showNotificationDialog, authUser } from '../app/AppActions'
import { users } from '../api'
import SignUpEnabler from '../core/SignUpFeature'
import FormResultDialog, { ModalActionButton } from '../signup/FormResultDialog'
import ReCAPTCHA from '../core/ReCAPTCHA'

//////////////////////////////////////////////////////////////////////////////

const errors = {
    unknown: 0,
    emptyEmail: 1,
    emptyPassword: 2,
    emptyEmailAndPassword: 3,
    emailNotValid: 4,
    emailNotVerified: 5,
    trialExpired: 6
}

const resendPwdConfEmail = <ModalActionButton key="resend-mail" onClick={() => browserHistory.push('/resendConfirmation')}>Resend</ModalActionButton>
// const navigateToBoxbot = <ModalActionButton key="nav-to-boxbot.fi-btn" onClick={() => { window.location.href = 'https://boxbot.fi'; }}>boxbot.fi</ModalActionButton>

const SignIn = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(0)
    const [dialogState, setDialogState] = useState({ open: false, onClose: () => { } });
    const [anchorEl, setAnchorEl] = useState(null)
    const recaptchaRef = useRef(null);

    const { onSignIn, onSignInGoogle, onResetPassword, classes } = props
    const open = Boolean(anchorEl)

    const errorHandler = errorCode => {
        switch (errorCode) {
            case errors.emptyEmail:
            case errors.emptyPassword:
            case errors.emptyEmailAndPassword:
            case errors.emailNotValid:
                return setError(errorCode); // TODO Legacy code - not refactored
            case errors.emailNotVerified:
                return setDialogState({
                    open: true,
                    onClose: () => setDialogState(s => ({ ...s, open: false, onClose: () => { } })),
                    title: 'Email is not verified',
                    content: "Please check your email for verification link. In case you haven't received such email, try resending it.",
                    actions: [resendPwdConfEmail]
                })
            case errors.trialExpired:
                return setDialogState({
                    open: true,
                    onClose: () => setDialogState(s => ({ ...s, open: false, onClose: () => { } })),
                    title: 'Trial is expired',
                    content: "Your free trial has expired. Please contact boxbot@boxbot.fi to continue using BOXBOT.",
                    actions: []
                })
            default:
                console.error(`Unknown signin error: ${errorCode}`)
        }
    }

    const submitHandler = async event => {
        event.preventDefault()

        // Create v3 token to gather data for recaptcha
        await recaptchaRef.current.getTokens();

        onSignIn(email, password)
            .catch(errorHandler)
    }

    const googleHandler = event => {
        event.preventDefault()
        onSignInGoogle()
            .catch(errorHandler)
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const iconButtonElement = (
        <IconButton onClick={handleClick} style={{ zIndex: '2' }} >
            <MoreVertIcon />
        </IconButton>
    )
    const rightIconMenu = (
        <div style={{ float: 'right' }}>
            {iconButtonElement}
            <Menu anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={onResetPassword}>Reset password</MenuItem>
            </Menu>
        </div>
    )
    return (
        <div>
            <Toolbar />
            <div className={classes.container} >
                <Paper elevation={4} className={classes.paper} >
                    <div className={classes.logoContainer} >
                        <img src='/assets/boxbot.svg' alt=" "
                            className={classes.signInLogo} />
                    </div>
                </Paper>
                <Paper elevation={4} className={classes.paper}>
                    {rightIconMenu}
                    <List subheader={<ListSubheader>Sign In</ListSubheader>}>
                        <form onSubmit={submitHandler} >
                            <div className={classes.textFieldWrapper}>
                                <TextField
                                    id='email'
                                    label='E-mail'
                                    fullWidth={true}
                                    onChange={e => setEmail(e.target.value)}
                                    type='email'
                                    name='email'
                                    error={!!error}
                                    helperText={error === 1 || error === 3 ? 'E-mail is required' : error === 4 ? 'E-mail does not contain "@" symbol' : null}
                                />
                            </div>
                            <div className={classes.textFieldWrapper}>
                                <TextField
                                    id='password'
                                    label='Password'
                                    fullWidth={true}
                                    onChange={e => setPassword(e.target.value)}
                                    type='password'
                                    name='password'
                                    error={!!error}
                                    helperText={error === 2 || error === 3 ? 'Password is required' : null}
                                />
                            </div>
                            <div className={classes.bottomDivider} />
                            <div className={classes.textFieldWrapper}>
                                <Button variant="contained" color="primary" startIcon={<ActionLock />} onClick={submitHandler} >Sign in</Button>
                                <Button startIcon={<img src='/assets/google.svg' alt=" " />} onClick={googleHandler}>Sign in with Google</Button>
                                <SignUpEnabler>
                                    <Button variant='outlined' color='primary' style={{ float: 'right' }} onClick={() => browserHistory.push('/signup')}>
                                        sign up for Free trial
                                    </Button>
                                </SignUpEnabler>
                                <ReCAPTCHA ref={recaptchaRef} actionName='signin' v2Disabled />
                            </div>
                            <div className={classes.bottomDivider} />
                            <input type='submit' style={{ display: 'none' }} />
                        </form>
                    </List>
                </Paper>
            </div>
            <FormResultDialog {...dialogState} />
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSignIn: (email, password) => {
            if (email.length === 0 && password.length === 0) {
                dispatch(showNotificationDialog('E-mail and password cannot be empty', 'E-mail and password invalid', notificationDialogSeverity.warning))
                return Promise.reject(errors.emptyEmailAndPassword)
            }
            if (email.length === 0) {
                dispatch(showNotificationDialog('E-mail cannot be empty', 'E-mail invalid', notificationDialogSeverity.warning))
                return Promise.reject(errors.emptyEmail)
            }
            if (email.indexOf('@') === -1) {
                dispatch(showNotificationDialog('E-mail does not contain "@" symbol', 'E-mail invalid', notificationDialogSeverity.warning))
                return Promise.reject(errors.emailNotValid)
            }
            if (password.length === 0) {
                dispatch(showNotificationDialog('Password cannot be empty', 'Password invalid', notificationDialogSeverity.warning))
                return Promise.reject(errors.emptyPassword)
            }
            dispatch(showNotification('Signing in...'))
            const timeout = setTimeout(() => {
                dispatch(showNotificationDialog('Connection timeout during sign in.', 'Connection timed out', notificationDialogSeverity.warning))
            }, 5000)
            return users.signIn(email, password)
                .then(result => {
                    clearTimeout(timeout)
                    dispatch(showNotification('Signed in.'));
                    dispatch(authUser(result.user));
                    browserHistory.push('/home')
                }).catch(err => {
                    clearTimeout(timeout)
                    if (err.name === 'MongoError') {
                        dispatch(showNotificationDialog('Server error. Contact system administrator if problem persists.', 'Server error', notificationDialogSeverity.error))
                    } else if (err.name === 'NotAuthenticated') {
                        if (err.data?.message) {
                            if (err.data.message === "Email not verified") {
                                dispatch(showNotificationDialog(`Unable to sign in. ${err.data.message}.`, 'Unable to sign in', notificationDialogSeverity.warning))
                                return Promise.reject(errors.emailNotVerified);
                            }
                            if (err.data.message === "Trial expired") {
                                dispatch(showNotificationDialog(`Unable to sign in. ${err.data.message}.`, 'Unable to sign in', notificationDialogSeverity.warning))
                                return Promise.reject(errors.trialExpired);
                            }
                        }
                        dispatch(showNotification('Unable to sign in. Invalid e-mail or password.', 'Unable to sign in', notificationDialogSeverity.warning))
                    } else {
                        dispatch(showNotificationDialog('Error: ' + err.name + err.message, 'Error', notificationDialogSeverity.error))
                    }
                })
                .then(() => null)
        },
        onSignInGoogle: () => {
            dispatch(showNotification('Signing in...'))
            return users.signInGoogle()
                .catch(err => {
                    if (err.name === 'MongoError') {
                        dispatch(showNotificationDialog('Server error. Contact system administrator if problem persists.', 'Server error', notificationDialogSeverity.error))
                    } else if (err.name === 'NotAuthenticated') {
                        if (err.data?.message) {
                            if (err.data.message === "Email not verified") {
                                dispatch(showNotificationDialog(`Unable to sign in. ${err.data.message}.`, 'Unable to sign in', notificationDialogSeverity.warning))
                                return Promise.reject(errors.emailNotVerified);
                            }
                            if (err.data.message === "Trial expired") {
                                dispatch(showNotificationDialog(`Unable to sign in. ${err.data.message}.`, 'Unable to sign in', notificationDialogSeverity.warning))
                                return Promise.reject(errors.trialExpired);
                            }
                        } else {
                            dispatch(showNotificationDialog('Unable to sign in. Invalid e-mail or password.', 'Unable to sign in', notificationDialogSeverity.warning))
                        }
                    } else {
                        dispatch(showNotificationDialog('Error: ' + err.name + err.message, 'Error', notificationDialogSeverity.error))
                    }
                })
        },
        onResetPassword: () => {
            browserHistory.push('/passwordreset')
        },
    }
}

const styles = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    container: theme.containers.twoPaperFlex,
    logoContainer: theme.containers.logo,
    signInLogo: theme.icons.signInLogo,
    paper: theme.containers.flexPaper,
    bottomDivider: theme.app.bottomDivider
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn))

//////////////////////////////////////////////////////////////////////////////
