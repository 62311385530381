import React, { useState, useEffect } from 'react'

import startCase from 'lodash.startcase'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import Collapse from '@material-ui/core/Collapse';

import moment from 'moment'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

//////////////////////////////////////////////////////////////////////////////

import Toolbar from '../../core/Toolbar'
import Octagon from '../../core/Octagon'
import Checkbox from '../../core/Checkbox'
import BackDialog from '../../core/BackDialog'
import AdminUserEditHeader from './AdminUserEditHeader'
import { users, organizations } from '../../api'
import { showNotificationDialog } from '../../app/AppActions'
import { setEditUser, closeBackDialog, openBackDialog, setOrganizations, setOrganizationUsers } from '../AdminActions'
import useOrganizationUserCountLimits, { checkEditUserOrganizationCountLimit } from './useOrganizationUserCountLimits'
import { colors } from '../../theme'
import SignUpEnabler from '../../core/SignUpFeature'
import { roleObjects, notificationDialogSeverity } from '../../core/Constants'

//////////////////////////////////////////////////////////////////////////////


const UsersEdit = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [roleRemovalDialog, setRoleRemovalDialog] = useState({ open: false });
    const [showWarning, setShowWarning] = useState(false);
    const [showTrialOrganization, setShowTrialOrganization] = useState(false);

    const { user, editUser, organizations, backDialogOpen } = props.reduxState;
    const { onConfirmGoBack, onCloseBackDialog, onFetchOrganizations, onFetchOrganizationUsers, onGoBack, onSetUser, onUpdateUser, params, classes } = props

    const [officeUsersAllowed, loadingOperatorsAllowed] = useOrganizationUserCountLimits();

    //
    // Former componentWillMount / DidMount etc are replaced with reacts useEffect function
    // See: https://reactjs.org/docs/hooks-effect.html for further information about using the Effect Hook
    //
    useEffect(() => {
        onSetUser(params.id, user);
        onFetchOrganizations(user);
    }, [onSetUser, onFetchOrganizations, params.id, user]) // useEffect will be called at the initial rendering and whenever 'onSetUser' or 'params.id' change

    // Whenever user organization change, get the organization user count for user count limit checks
    useEffect(() => {
        if (!editUser.organization || Object.keys(editUser.organization).length === 0)
            return;

        const fetchOrganizationAsync = async () => {
            // Fetch the organization users
            let newOrganizationUsers = await onFetchOrganizationUsers(editUser.organization)
            if (!newOrganizationUsers)
                return;

            // Get the updated userlimits based on the new organization users and user
            const userLimits = checkEditUserOrganizationCountLimit(editUser._id, editUser.organization, newOrganizationUsers);

            // Filter out roles if they exceed the new organization user count limits
            let newRoles = editUser.roles.slice();

            // Office users are not allowed but user has some office user role checked -> uncheck it
            if (!userLimits.officeUsersAllowed && newRoles.some(x => x.startsWith('office'))) {
                newRoles = newRoles.filter(x => !x.startsWith('office'))
            }

            // LA operator users are not allowed but user has loadingOperatorUser role checked -> uncheck it
            if (!userLimits.loadingOperatorsAllowed && newRoles.includes('loadingOperationsUser')) {
                newRoles = newRoles.filter(x => x !== 'loadingOperationsUser');
            }

            // If the edit user roles has changed, update the editUser
            const rolesChanged = JSON.stringify(editUser.roles) !== JSON.stringify(newRoles);
            if (rolesChanged) {
                editUser.roles = newRoles;
                editUser.modified = true;
                onUpdateUser(editUser);
            }
        }
        fetchOrganizationAsync();

        // This is needed to run ONLY when the editUser.organization changes, the upToDate 'editUser' is still always used
        // and hence we do NOT wan't this to run every time when user types and changes the user name/email etc
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editUser.organization, onFetchOrganizationUsers, onUpdateUser])

    const onOrganizationChange = async (newOrganizationName) => {
        const newOrganization = organizationOptions.find(x => x.name === newOrganizationName);
        if (!Boolean(newOrganization))
            return;

        // Filter roles not to include any roles that selected organization doesn't support / have
        let roles = newOrganization.allowedRoles.slice();
        if (newOrganization.allowSuperadmin)
            roles.push("superadmin")
        roles = editUser.roles.filter(role => roles.includes(role))


        const rolesChanged = JSON.stringify(roles) !== JSON.stringify(editUser.roles)
        if (rolesChanged && editUser._id) {
            const removedRoles = editUser.roles.filter(role => !roles.includes(role));
            const roleText = `User role${removedRoles.length > 1 ? 's' : ''}`;
            const text = <span><span>{`${roleText}: `}</span><span style={{ fontWeight: 'bold' }}>{removedRoles.join(", ")}</span><span>{` will be removed if organization is changed to ${newOrganization.name}`}</span></span>
            setRoleRemovalDialog({
                open: true,
                onCancel: () => setRoleRemovalDialog({ open: false }),
                onOk: () => {
                    editUser.organization = newOrganization;
                    editUser.roles = roles;
                    editUser.modified = true;
                    onUpdateUser(editUser)
                    setRoleRemovalDialog({ open: false })
                },
                title: `${roleText} will be removed`,
                text: text
            })
        } else {
            editUser.organization = newOrganization;
            editUser.roles = roles;
            editUser.modified = true;
            onUpdateUser(editUser)
        }
    }


    const organizationOptions = organizations.length ? organizations : [user.organization]
    let emailError, nameError
    if (editUser.error) {
        if (editUser.error.includes("@")) emailError = "Please include '@' in the email address"
        if (editUser.error.includes("name length")) nameError = "Name can't be empty"
    }

    const GeneralSection = (
        <form onSubmit={event => event.preventDefault()}>
            <Typography variant={'subtitle1'} >
                <List ></List>
            </Typography>
            <div className={classes.textFieldWrapper}>
                <TextField
                    id='email'
                    error={Boolean(emailError)}
                    disabled={editUser._id != null || editUser.oauth}
                    label='E-mail'
                    fullWidth={true}
                    value={editUser.email}
                    onChange={e => {
                        editUser.email = e.target.value;
                        editUser.modified = true;
                        delete editUser.error
                        onUpdateUser(editUser)
                    }}
                    type='email'
                    helperText={emailError}
                />
            </div>
            <div className={classes.textFieldWrapper} >
                <TextField
                    id='name'
                    error={Boolean(nameError)}
                    disabled={editUser.oauth}
                    label='Name'
                    fullWidth={true}
                    value={editUser.name}
                    onChange={e => {
                        editUser.name = e.target.value;
                        editUser.modified = true;
                        delete editUser.error
                        onUpdateUser(editUser)
                    }}
                    type='text'
                    helperText={nameError}
                />
            </div>
            <div className={classes.textFieldWrapper} style={{ display: 'flex' }}>
                <TextField
                    id="organization"
                    disabled={(user.roles && !user.roles.includes('superadmin')) || editUser.oauth}
                    select
                    label="Organization"
                    fullWidth={true}
                    value={editUser.organization.name || ''}
                    type='text'
                    onChange={(value, child) => onOrganizationChange(child.props.value)}
                >
                    {/* Filter to display only "Real" organizations.
                    NOTE: editUser.organization MUST be visible, ie. NOT filtered out of the list --> trial user must see it's own organization name */}
                    {organizationOptions.filter(organization => showTrialOrganization ? true : (!organization.isTrial || organization._id === editUser.organization._id)).map(option => (
                        <MenuItem
                            key={option._id}
                            value={option.name}
                        >
                            {option.isTrial && moment(option.trialExpires).isBefore(moment()) ?
                                // Display warning icon about expired organization
                                <div style={{ fontFamily: 'inherit', display: 'flex', alignItems: 'flex-end' }}>
                                    <span style={{ fontFamily: 'inherit' }}>{option.name}</span>
                                    <WarningIcon className={classes.warningIconMargin} color='error' />
                                    <span style={{ fontFamily: 'inherit', color: colors.red }}>Trial expiry date is in the past</span>
                                </div> :
                                // Just display the organization name
                                option.name
                            }
                        </MenuItem>
                    ))}
                </TextField>
                {user.roles.includes('superadmin') &&
                    <SignUpEnabler>
                        <Checkbox
                            style={{ width: 'fit-content' }}
                            checked={showTrialOrganization}
                            label='Show Trial Organizations'
                            onChange={() => setShowTrialOrganization(!showTrialOrganization)}
                        />
                    </SignUpEnabler>
                }
            </div>
        </form>
    )

    const type = showPassword ? "text" : "password"
    const icon = showPassword ? <Visibility /> : <VisibilityOff />

    let passwordHelperText, passwordConfirmationHelperText
    if (editUser.error) {
        if (editUser.error.includes("password length")) passwordHelperText = "Password can't be empty"
        if (editUser.error.includes("password conf")) passwordConfirmationHelperText = "Passwords doesn't match"
    }
    const password = (
        <form onSubmit={e => e.preventDefault()} style={{ width: "100%", marginTop: "-8px" }}>
            <div className={classes.textFieldWrapper}>
                <TextField
                    id="password"
                    error={Boolean(passwordHelperText)}
                    label="Password"
                    fullWidth={true}
                    value={editUser.password || ''}
                    onChange={e => {
                        editUser.password = e.target.value;
                        delete editUser.error
                        onUpdateUser(editUser)
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {icon}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    type={type}
                    helperText={passwordHelperText}
                />
            </div>
            <div className={classes.textFieldWrapper}>
                <TextField
                    label="Confirm password"
                    error={Boolean(passwordConfirmationHelperText)}
                    fullWidth={true}
                    value={editUser.passwordConfirmation || ''}
                    onChange={e => {
                        editUser.passwordConfirmation = e.target.value
                        delete editUser.error
                        onUpdateUser(editUser)
                    }}
                    type="password"
                    helperText={passwordConfirmationHelperText}
                />
            </div>
        </form>
    )

    const handlePasswordRadioChange = () => {
        editUser.password = ''
        editUser.passwordConfirmation = null
        editUser.sendEmail = true
        editUser.manualSet = !editUser.manualSet
        onUpdateUser(editUser)
    }

    const passwordSetOptions = (!editUser._id &&
        <div>
            <Divider />
            <List subheader={<ListSubheader disableSticky>{"Password"}</ListSubheader>}>
                <RadioGroup onChange={() => handlePasswordRadioChange()} className={classes.radioGroup}>
                    <FormControlLabel
                        className={classes.radioStyle}
                        control={<Radio color="primary" checked={!editUser.manualSet} />}
                        label="Send Password Reset Link"
                    />
                    <FormControlLabel
                        className={classes.radioStyle}
                        control={<Radio color="primary" checked={editUser.manualSet} />}
                        label="Set Password Manually"
                    />
                </RadioGroup>
                <Collapse in={editUser.manualSet}>
                    <Checkbox checked={editUser.sendEmail} label={"Send Welcome Email"} onChange={() => {
                        editUser.sendEmail = !editUser.sendEmail
                        onUpdateUser(editUser)
                    }} />
                    {password}
                </Collapse>
            </List>
        </div>
    )

    const roles = (
        <List subheader={<ListSubheader disableSticky>Roles</ListSubheader>}>
            {/* Filter roles selection not to include superadmin */}
            {editUser.organization.allowedRoles?.filter(role => role !== 'superadmin')
                .map(role => {
                    const disabled = role === 'loadingOperationsUser' ? !loadingOperatorsAllowed : !officeUsersAllowed;
                    return (
                        <div style={{ display: "flex" }} key={role}>
                            <Checkbox
                                style={{ width: '33%' }}
                                disabled={disabled}
                                checked={editUser.roles.includes(role)}
                                label={startCase(roleObjects[role].displayName)}
                                secondary={startCase(roleObjects[role].description)}
                                onChange={value => {
                                    const roles = editUser.roles.slice()
                                    if (value.target.checked) {
                                        roles.push(role)
                                    } else {
                                        roles.splice(roles.indexOf(role), 1);
                                    };
                                    editUser.roles = roles
                                    editUser.modified = true
                                    onUpdateUser(editUser)
                                }}
                            />
                            {disabled && <ListItem disabled>
                                <ListItemText>Users count limit reached</ListItemText>
                            </ListItem>}
                        </div>
                    )
                })}
        </List>
    )

    const superRole = 'superadmin';
    const superadmin = (
        !(!user.roles || !user.roles.includes(superRole) || !editUser.organization.allowSuperadmin) &&
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <List subheader={< ListSubheader disableSticky > Superadmin Privileges</ListSubheader>}>
                <Checkbox
                    disabled={user.email === editUser.email}
                    checked={editUser.roles.includes(superRole)}
                    label={startCase(roleObjects[superRole].displayName)}
                    secondary={startCase(roleObjects[superRole].description)}
                    onChange={e => {
                        const roles = editUser.roles.slice()
                        if (e.target.checked) {
                            roles.push(superRole)
                        } else {
                            roles.splice(roles.indexOf(superRole), 1);
                        }
                        editUser.roles = roles
                        editUser.modified = true
                        onUpdateUser(editUser)
                        setShowWarning(!showWarning)
                    }}
                />
            </List>
            <List style={{ color: "red", marginBottom: "10px" }}>
                {editUser.roles.includes(superRole) && editUser.modified && showWarning &&
                    <ListItem>
                        <ListItemIcon>
                            <WarningIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary='Warning: Superadmin can edit all organizations and users!' />
                    </ListItem>
                }
            </List>
        </div>
    )

    var toolBarTitle = editUser._id == null ? 'Create user' : 'Edit user'

    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title={toolBarTitle} onGoBack={() => onGoBack(editUser.modified)}>
                <BackDialog
                    backDialogOpen={backDialogOpen}
                    onConfirmGoBack={onConfirmGoBack}
                    onCloseBackDialog={onCloseBackDialog}
                />
                {roleRemovalDialog.open && <BackDialog
                    backDialogOpen={roleRemovalDialog.open}
                    onConfirmGoBack={roleRemovalDialog.onOk}
                    onCloseBackDialog={roleRemovalDialog.onCancel}
                    title={roleRemovalDialog.title}
                    text={roleRemovalDialog.text}
                />}
                <AdminUserEditHeader />
            </Toolbar>
            <div className={classes.adminContainer} >
                <Paper elevation={4}>
                    {GeneralSection}
                    {passwordSetOptions}
                    <Divider />
                    {roles}
                    <Divider />
                    {superadmin}
                </Paper>
            </div>
        </Octagon>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    reduxState: {
        user: state.user,
        editUser: state.admin.editUser,
        backDialogOpen: state.admin.backDialogOpen,
        organizations: state.admin.organizations.data
    }
})

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (id, user) => {
            if (!id) {
                let newUser = {
                    email: '',
                    password: '',
                    passwordConfirmation: null,
                    sendEmail: true,
                    manualSet: false,
                    roles: ['officeUser'],
                    name: '',
                    organization: user.organization,
                    tabletMode: false
                }
                dispatch(setEditUser(newUser))
                return;
            }

            users.get(id)
                .then(editUser => dispatch(setEditUser(editUser)))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)));
        },
        onUpdateUser: (user) => {
            dispatch(setEditUser(user))
        },
        onConfirmGoBack: () => {
            dispatch(closeBackDialog())
            browserHistory.push('/admin/users')
        },
        onCloseBackDialog: () => {
            dispatch(closeBackDialog())
        },
        onGoBack: (modified) => {
            if (modified) {
                dispatch(openBackDialog())
            } else {
                browserHistory.push('/admin/users')
            }
        },
        onFetchOrganizations: (user) => {
            if (!user.roles.includes('superadmin'))
                return;

            organizations.query({})
                .then(res => dispatch(setOrganizations(res)))
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onFetchOrganizationUsers: async (organization) => {
            return users.query({ organization: organization._id })
                .then(res => {
                    dispatch(setOrganizationUsers(res))
                    return res;
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                    return null;
                })
        }
    }
}

const styles = theme => ({
    textFieldWrapper: theme.textFieldWrapper,
    adminContainer: theme.containers.admin,
    radioGroup: theme.radioGroup,
    warningIconMargin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    radioStyle: { color: `rgba(0, 0, 0, 0.87)` },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersEdit))

//////////////////////////////////////////////////////////////////////////////
