const solutionsState = {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
    isLoading: true,
}

export default (state = solutionsState, action) => {
    switch (action.type) {
        case "SET_SOLUTIONS":
            if (!action.solutions) {
                const defaultState = Object.assign({}, solutionsState)
                defaultState.isLoading = false;
                return defaultState
            }
            action.solutions.isLoading = false
            return Object.assign({}, action.solutions)
        case "SET_LOADING":
            return Object.assign({}, state, { isLoading: action.value })
        default:
            return state
    }
}