import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../core/DialogTitle'

import InfoIcon from '@material-ui/icons/Info';
import { colors } from '../theme'

export const infoTexts = {
    strategy: {
        title: "Cargo Space Selection Strategy",
        lines: [
            "Single Cargo Space Type: Use the same cargo space type for all cargo.",
            "Two Cargo Space Types: If the last primary cargo space would be left with unused space, try to fit its cargo in one secondary (smaller) one."
        ]
    },
    presets: {
        title: "Search for saved Cargo Space Presets.",
        lines: ["Dimensions, weight limit and packing direction are filled when preset is selected."]
    },
    weightLimit: {
        title: "Enable or disable cargo space weight limit.",
        lines: [
            "When enabled, the optimized loading plan's total packed weight won't exceed the given limit.",
            "When disabled all weights are ignored.",
            "Weight limit of 0 kg is considered as disabled."
        ],
    },
    availableCargoSpace: {
        title: "Loading Plan Mode",
        lines: [
            "Limit the number of cargo spaces available.",
            "If mode is 'Unlimited', new cargo spaces will be created until all parcels have been packed.",
            "If mode is 'Limited', the parcels that didn't fit into any of the cargo spaces will be marked as 'not packed'."
        ]
    },
    lashing: {
        title: "Reserve Fullwidth",
        lines: [
            "When fullwidth is reserved, nothing can be packed next to, or on top of the parcel.",
            "If fullwidth is reserved, stackability is automatically set as non-stackable to ensure that nothing can be packed on top of the parcel."
        ]
    },
    unpackableParcels: {
        title: "Unpackable Parcel(s)",
        lines: [
            "There are one or more parcels that can not be packed because either width, length, height or weight exceeds the cargo space limits.",
            "In case of misspelling, please check cargo data input and/or cargo space",
            "Please note that the loading plan can still be optimized, but these parcels won't be included in the solution."
        ]
    },
    optimizerParameters: {
        title: "Optimizer parameters",
        lines: [
            "Optimizer parameters influence the outcome of optimized loading plans.",
            "Parameters are bound to presets and they can be changed only in preset editing by technical personnel."
        ]
    },
    visualAids: {
        title: "Visual aids and negative space",
        lines: [
            `Visual aid is an experimental feature to help visualize cargo space properties, such as center line or a line for X meters from the front.
            Using visual aids doesn't have any effect on the loading plan optimization.`,
            `Negative space is an additional property attached to the cargo space that can not be used for packing.
            For example a refrigerator can be placed in the cargo space and optimization takes the refrigerator into account as a negative space.`
        ]
    },
    forcedToCompletion: {
        title: "Force completed-status",
        lines: [
            "The loading of this cargo space was not completed successfully.",
            "Instead the cargo space was forcibly marked as completed and the loading of the cargo items was not recorded"
        ]
    }
}

export const Info = ({ info, onClick, style }) => (
    <IconButton
        style={{ padding: "0px inherit", zIndex: 10 }}
        onClick={() => onClick({
            title: info.title,
            content: info.lines.map((x, i) => <div key={i}><br />< Typography key={i} variant="body1" >{x}</Typography ><br /></div>)
        })} >
        <InfoIcon style={{ color: colors.bbTeal, paddingLeft: "0px", ...style }} />
    </IconButton >
)

export default (props) => {
    if (!props.info) return <div />
    const { info, onClose } = props
    const { title, content } = info
    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle onClose={onClose}>{title}</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
        </Dialog>
    )
}
