import React, { useState, useEffect, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList'

import GeneralDataTable from '../../core/GeneralDataTable/GeneralDataTable'
import Toolbar from '../../core/Toolbar'
import Octagon from '../../core/Octagon'
import OrganizationFilterMenu from '../../core/OrganizationFilterMenu'
import { colors } from '../../theme'
import { notificationDialogSeverity } from '../../core/Constants'

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { setUsers, setOrganizations } from '../AdminActions'
import { showNotificationDialog } from '../../app/AppActions'
import { users, organizations } from '../../api'
import { cloneDeep, startCase, capitalize } from 'lodash'

//////////////////////////////////////////////////////////////////////////////


const AdminUsers = (props) => {

    const {
        classes,
        organizations,
        onAddUser,
        onFetchOrganizations,
        onFetchUsers,
        onGoBack,
        onSelectUser,
        user,
        users,
    } = props


    // States for table pagination
    const [paginationState, setPaginationState] = useState({
        rowsPerPage: parseInt(props.location.query?.rowsPerPage ?? 25),
        page: parseInt(props.location.query?.page ?? 0)
    });

    // States for organization filter menu
    const [orgFilterState, setOrgFilterState] = useState({
        filterOpen: false,
        filterList: []
    });

    // State for changing table tabs
    const [tabState, setTabState] = useState({
        tabIndex: parseInt(props.location.query?.tabIndex ?? '0'),
        tabFilter: props.location.query?.tabFilter ?? null
    });

    // States for table rows
    const [tableData, setTableData] = useState({});

    // Count different use roles. These values are shown on the tab labels, like 'All Users(n)'
    const [userRoleCounts, setUserRoleCount] = useState({
        all: 0,
        office: 0,
        loading: 0
    })

    // Anchor for the organization filter menu
    const anchorRef = useRef(null);


    /**
     * On the initial render, fetch all organizations for superadmin.
     */
    useEffect(() => {
        if (!user.roles.includes('superadmin')) {
            return;
        } else {
            onFetchOrganizations(user);
        }
    }, [user, onFetchOrganizations])


    /**
     * Fetch all users from the database.
     */
    useEffect(() => {
        try {
            if (orgFilterState.filterList.length > 0) { // User has chosen the 'all users'-tab
                onFetchUsers(paginationState.page, paginationState.rowsPerPage, tabState.tabFilter, tabState.tabIndex, { organization: orgFilterState.filterList })
            } else {
                onFetchUsers(paginationState.page, paginationState.rowsPerPage, tabState.tabFilter, tabState.tabIndex, {})
            }
        }
        catch (err) {
            console.error('ERROR', err);
        }

    }, [orgFilterState.filterList, onFetchUsers, paginationState.page, paginationState.rowsPerPage, tabState.tabFilter, tabState.tabIndex])


    /**
     * Create table rows from the fetched users.
     * Add tableData-field to each user. tableData controls the row-deleting- and row-editing-overlays.
     * Filter rows with tabState.tabFilter. tabFilter is a string, filter users by cheking if any role in users roles includes that string.
     */
    useEffect(() => {

        // Counts for different user role types
        let all = 0;
        let office = 0;
        let loading = 0;

        // Copy fetched user information
        let usersCopy = cloneDeep(users);

        // Count user types
        usersCopy.data.forEach(user => {
            if (user.roles.includes('officeUser') || user.roles.includes('officeAdmin')) {
                office++;
            }
            if (user.roles.includes('loadingOperationsUser')) {
                loading++;
            }
            all++;
        })

        // If tabFilter isn't null, apply filter to usersCopy.data
        if (tabState.tabFilter != null) {
            usersCopy.data = usersCopy.data.filter(function (row) {
                return row.roles.some(role => role.includes(tabState.tabFilter))
            })
        }

        usersCopy.total = usersCopy.data.length
        usersCopy.data = usersCopy.data.slice(paginationState.page * paginationState.rowsPerPage, paginationState.page * paginationState.rowsPerPage + paginationState.rowsPerPage);
        setTableData(usersCopy)
        setUserRoleCount(state => ({ ...state, all: all, office: office, loading: loading }))

    }, [users, tabState.tabFilter, paginationState.rowsPerPage, paginationState.page])


    /**
     * When superadmin checks a organization from organization filter menu, set pagination page back to 0.
     */
    useEffect(() => {
        setPaginationState(state => ({ ...state, page: 0 }))
    }, [orgFilterState.filterList])


    /**
     * Handle tab click.
     * When user clicks a tab, change tabFilter to a string.
     * We use this string to filter the users.
     * @param {String} tab
     */
    const handleTabClick = (tab) => {
        if (tab === 'all') {
            setTabState(state => ({ ...state, tabFilter: null }))
        }
        else if (tab === 'office') {
            setTabState(state => ({ ...state, tabFilter: 'office' }))
        }
        else if (tab === 'loading') {
            setTabState(state => ({ ...state, tabFilter: 'loading' }))
        }

        setPaginationState(state => ({ ...state, page: 0 }))
    }


    /**
     * Format email-string. If user has logged in with a Google-account, then add a Google-logo next to the email-address.
     * @param {String} email
     * @returns a formatted email-string.
     */
    const formatEmail = (email) => {
        if (email === '' || email == null) {
            return;
        }

        const user = users.data.find(u => u.email === email);
        if (user == null) return;
        if (user.google) {
            return (
                <span style={{ fontFamily: 'inherit', display: 'flex', alignItems: 'center', columnGap: '6px' }}>
                    {user.email}
                    <img src='/assets/google.svg' alt=' '/>
                </span>
            )
        } else {
            return (
                <span style={{ fontFamily: 'inherit' }}>
                    {user.email}
                </span>
            )
        }
    }


    // Table columns
    const columns = [
        { label: 'E-mail', field: 'email', conversion: (email) => { return formatEmail(email) }},
        { label: 'Name', field: 'name' },
        { label: 'Organization', field: 'organization.name' },
        { label: 'Roles', field: 'roles', conversion: roles => roles.map(r => capitalize(startCase(r))).join(', ')}
    ];


    // Check if user is allowed to add new user to organization
    const allowOfficeUserCreation = user.roles.includes("superadmin") || user.organization.officeUserCountLimit === 0 || userRoleCounts.office < user.organization.officeUserCountLimit;
    const allowLoadingOperationsUserCreation = user.roles.includes("superadmin") || user.organization.loadingOperationsUserCountLimit === 0 || userRoleCounts.loadingOperators < user.organization.loadingOperationsUserCountLimit;


//////////////////////////////////////////////////////////////////////////////


    return (
        <Octagon w='80rem' color={colors.octagon}>
            <Toolbar title='Users' onGoBack={onGoBack}>
                {/* Add User -button */}
                <Button disabled={!allowOfficeUserCreation && !allowLoadingOperationsUserCreation} startIcon={<AddIcon />} onClick={onAddUser} >Add user</Button>
                {(!allowOfficeUserCreation && !allowLoadingOperationsUserCreation) && <p className={classes.textStyle}>Maximum number of users reached</p>}
                {/* Organization filter -button */}
                <Button
                    style={{ display: user.roles.includes('superadmin') ? '' : 'none' }}
                    startIcon={<FilterListIcon />}
                    onClick={() => setOrgFilterState(state => ({ ...state, filterOpen: !orgFilterState.filterOpen }))}
                    ref={anchorRef}
                    aria-controls={orgFilterState.filterOpen ? 'menu-list' : undefined}
                    aria-haspopup='true'
                >
                    organizations
                </Button>
                <OrganizationFilterMenu
                    open={orgFilterState.filterOpen}
                    setOpen={(o) => setOrgFilterState(state => ({ ...state, filterOpen: o }))}
                    anchorRef={anchorRef}
                    organizations={organizations}
                    setOrgFilterList={(list) => setOrgFilterState(state => ({ ...state, filterList: list }))}
                />
            </Toolbar>

            {/* Table component */}
            <Paper className={classes.table} elevation={4}>
                <Tabs
                    value={tabState.tabIndex}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='fullWidth'
                    onChange={(e, value) => setTabState(state => ({ ...state, tabIndex: value }))}
                >
                    <Tab label={`All Users (${userRoleCounts.all})`} value={0} onClick={() => handleTabClick('all')} />
                    <Tab label={`Office Users (${userRoleCounts.office})`} value={1} onClick={() => handleTabClick('office')} />
                    <Tab label={`Loading Operators (${userRoleCounts.loading})`} value={2} onClick={() => handleTabClick('loading')} />
                </Tabs>

                <Divider />

                <GeneralDataTable
                    columns={columns}
                    data={tableData}
                    paginationState={paginationState}
                    onChangePagination={(state) => setPaginationState(state)}
                    onClickRow={(user) => onSelectUser(user)}
                />
            </Paper>
        </Octagon>
    )

}



// ---------- REDUX STATES & DISPATCH FUNCTIONS ----------

const mapStateToProps = (state) => {
    return {
        organizations: state.admin.organizations.data,
        users: state.admin.users,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectUser: (user) => {
            browserHistory.push('/admin/users/' + user._id)
        },
        onFetchUsers: (page, rowsPerPage, tabFilter, tabIndex, query) => {
            browserHistory.replace({
                pathname: '/admin/users',
                query: { page, rowsPerPage, tabFilter, tabIndex }
            })
            users.query(query)
                .then(result => {
                    dispatch(setUsers(result))
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onFetchOrganizations: (user) => {
            if (!user || !user.roles.includes("superadmin")) return
            organizations.query({})
                .then(res => {
                    dispatch(setOrganizations(res))
                })
                .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
        },
        onAddUser: () => {
            browserHistory.push('/admin/users/new')
        },
        onGoBack: () => {
            browserHistory.push('/admin')
        },
    }
}



// ---------- COMPONENT STYLINGS ----------

const styles = theme => ({
    textStyle: {
        ...theme.textStyle,
        margin: theme.spacing(1)
    },
    table: theme.containers.dataTable
})





export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminUsers))

//////////////////////////////////////////////////////////////////////////////
