import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

import Clear from '@material-ui/icons/Clear'
import Check from '@material-ui/icons/Check'

import InputFile from '../core/InputFile'
import BackDialog from '../core/BackDialog'
import { notificationDialogSeverity } from '../core/Constants'

import { users, organizations } from '../api'

import { startCase } from 'lodash';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { updateUser, showNotificationDialog } from '../app/AppActions'
import { withStyles } from '@material-ui/styles'
import DeleteForever from '@material-ui/icons/DeleteForever'

//////////////////////////////////////////////////////////////////////////////

const MyOrganization = ({ user, onUpdateOrganization, onShowNotificationDialog, onDeleteLogo, classes }) => {
    const [state, setState] = useState({ officeUserCount: 0, loadingOperatorsCount: 0, newLogo: null, isEdit: false, deleteDialogOpen: false })

    useEffect(() => {
        // Fetch this organizations users first time the page loads
        users.query({ organization: user.organization._id })
            .then(res => {

                // Count the users in the organization
                const userRoles = res.data.map(user => user.roles)

                let count = {
                    office: 0,
                    loadingOp: 0
                };

                // officeUser and officeAdmin are both calculated to office, loadingOperationsUsers are counted to loadingOp
                userRoles.forEach(roles => {
                    if (roles.includes('officeUser') || roles.includes('officeAdmin')) { // If user has both roles, it's still counted as 1 user
                        count.office = count.office + 1;
                    }
                    if (roles.includes('loadingOperationsUser')) {
                        count.loadingOp = count.loadingOp + 1;
                    };
                });


                setState({ ...state, officeUserCount: count.office, loadingOperatorsCount: count.loadingOp })
            })
            .catch(err => console.error(err.message))

        // state dependency is left out on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState, user.organization._id]) // Invoked only when either of these dependencies changes

    const handleLogoLoad = file => {
        const isValidFileExtension = ['png', 'jpg', 'svg'].includes(file.name.split('.').pop())
        if (!isValidFileExtension) {
            onShowNotificationDialog(
                "Invalid file extension. Only .png, .jpg and .svg files are supported.",
                'Error',
                notificationDialogSeverity.error
            )
            return;
        }

        var fr = new FileReader();
        fr.onload = e => setState({ ...state, isEdit: true, newLogo: e.target.result })
        fr.readAsDataURL(file)
    }

    // If cargoSpaceType or optimizerRules is enabled license is Truck & trailer - otherwise Basic
    let license = ['cargoSpaceType', 'optimizerRules'].some(x => user.organization.enabledFeatures.includes(x)) ? "Truck & Trailer" : "Basic"
    // If loadingAssistant is enabled, license has also Loading operations
    if (user.organization.enabledFeatures.includes("loadingAssistant"))
        license += " + Loading Operations";

    const informationSection = (
        <List dense={true}>
            <ListItem key="OrganizationName">
                <ListItemText primary={startCase("Organization name")} secondary={user.organization.name} />
            </ListItem>
            <ListItem key="OfficeUsersCountLimit"> {/* Check if organizations user count limit is more than 0, if yes, show user count as 'users / limit', if no, show as 'users / infinity' */}
                <ListItemText primary={startCase("Office user count")} secondary={user.organization.officeUserCountLimit > 0 ? `${state.officeUserCount} / ${user.organization.officeUserCountLimit}` : `${state.officeUserCount} / \u221e`} /> {/* \u221e is the infinity-symbol */}
            </ListItem>
            <ListItem key="LoadingOperatorsCountLimit">
                <ListItemText primary={startCase("Loading operators count")} secondary={user.organization.loadingOperationsUserCountLimit > 0 ? `${state.loadingOperatorsCount} / ${user.organization.loadingOperationsUserCountLimit}` : `${state.loadingOperatorsCount} / \u221e`} />
            </ListItem>
            <ListItem key="License">
                <ListItemText primary={startCase("License")} secondary={license} />
            </ListItem>
        </List>
    )

    // Display the new logo if such exists, otherwise display the current logo
    // If no logo is available, display "no-logo" image
    const displayLogoSrc = state.newLogo || user.organization.logo

    const logoSection = (
        <List>
            <ListItem key="OrganizationLogo">
                <ListItemText primary={startCase("Organization logo")} />
            </ListItem>
            <ListItem key="logo">
                <div className={classes.logoBorder} >
                    {displayLogoSrc ?
                        <img className={classes.logoImageStyle} src={displayLogoSrc} alt={`${user.organization.name} logo`} /> :
                        <p className={`${classes.itemTextSecondaryLike}`} >No logo available</p>}
                </div>
            </ListItem>
            <ListItem key="ImageButtons">
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <InputFile
                        id="LoadImageInput"
                        inputProps={{ accept: '.png, .jpg, jpeg, .svg' }}
                        onLoad={handleLogoLoad}
                        render={openFileDialog =>
                            <div style={{ display: "inherit" }}>
                                <Button disabled={state.isEdit} variant="contained" color="primary" onClick={openFileDialog} >
                                    Choose file
                                </Button>
                                <p className={classes.itemTextSecondaryLike} style={{ alignSelf: "flex-end", paddingBottom: "4px" }}>Supported files: png, jpg and svg</p>
                            </div>}
                    />
                    <div>
                        {state.isEdit ?
                            <>
                                <Button startIcon={<Clear />} onClick={() => setState({ ...state, isEdit: false, newLogo: null })}                        >
                                    Cancel
                                </Button>
                                <Button startIcon={<Check />} color="primary" variant="contained"
                                    onClick={() => {
                                        onUpdateOrganization(user, { logo: state.newLogo })
                                        // NOT setting newLogo: null is INTENTIONAL.
                                        // onUpdateOrganization saves the new logo to database, then updates redux and then tells
                                        // react that state have changed, which is when the new logo would be rendered.
                                        // But before this operation is ready, React has time to render the old logo for short period of time...
                                        setState({ ...state, isEdit: false })
                                    }}>
                                    Apply
                                </Button>
                            </>
                            : <Button className={classes.errorButton} startIcon={<DeleteForever />}
                                onClick={() => setState({ ...state, deleteDialogOpen: true })} >
                                Delete
                            </Button>
                        }
                    </div>
                </div>
            </ListItem>
        </List>
    )

    return (
        <div className={classes.mainContainer}>
            <Paper elevation={4} style={{ width: "40%" }}>
                {informationSection}
            </Paper>
            <Paper elevation={4} style={{ width: "60%" }}>
                {logoSection}
            </Paper>
            <BackDialog
                backDialogOpen={state.deleteDialogOpen}
                onConfirmGoBack={() => {
                    onDeleteLogo(user);
                    // Set newLogo to null in case to NOT leave previously loaded logo to be displayed
                    setState({ ...state, deleteDialogOpen: false, newLogo: null })
                }}
                onCloseBackDialog={() => setState({ ...state, deleteDialogOpen: false })}
                title="Delete organization logo?"
                noContent
                confirmButtonText="Delete"
            />
        </ div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateOrganization: (user, data) => {
            organizations.patch(user.organization._id, data)
                .then(res => {
                    // Update user organization to match the updated organization
                    user.organization = res;
                    dispatch(updateUser(user));
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onDeleteLogo: (user) => {
            organizations.patch(user.organization._id, { logo: null })
                .then(res => {
                    user.organization = res;
                    dispatch(updateUser(user));
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
        onShowNotificationDialog: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity));
        }
    }
}

const styles = theme => ({
    mainContainer: {
        width: "100%",
        display: "flex"
    },
    logoBorder: {
        width: "100%",
        height: "135px",
        border: `1px solid rgba(255, 160, 91, 0.4)`, // Same color as colors.bbOrangeLight but with opacity
        borderRadius: '8px',
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logoImageStyle: {
        height: "101px", // Setting only width or height will scale the image automatically
        maxWidth: "100%"
    },
    marginButton: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4)
    },
    buttonSmallMargin: {
        marign: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    errorButton: {
        // ...theme.errorButton
    },
    itemTextSecondaryLike: {
        fontFamily: 'Robot, Helvetica, Arial, sans-serif',
        color: "rgba(0, 0, 0, 0.54)",
        margin: "0px"
    },
    noLogoText: {
        alignSelf: "flex-end",
        paddingBottom: theme.spacing(1)
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyOrganization))
