import React, { useState, useRef } from 'react'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import ActionLock from '@material-ui/icons/Lock'
import UserlessPage from '../app/UserlessPage';
import ReCAPTCHA from '../core/ReCAPTCHA';
import { showNotification, showNotificationDialog } from '../app/AppActions'
import { users } from '../api'
import { notificationDialogSeverity } from '../core/Constants';

//////////////////////////////////////////////////////////////////////////////

const RequestReset = (props) => {
    const [email, setEmail] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(false);
    const recaptchaRef = useRef(null);
    const { reduxDispatch, classes } = props

    const submitHandler = async event => {
        event.preventDefault()

        // Don't submit with enter if reCAPTCHA v2 is visible --> submit is disabled
        if (disableSubmit)
            return;

        const tokens = await recaptchaRef.current.getTokens();
        reduxDispatch.onRequestReset(email, tokens)
            .then(() => setEmail(''))
            .catch(err => {
                const isHandled = recaptchaRef.current.onError(err);
                // Error was recaptcha validation related and was handled
                if (isHandled)
                    return;

                console.error(err);
                reduxDispatch.onShowNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)
            })
    }

    return (
        <UserlessPage
            title='Forgot your password?'
            infoText='Please fill in the email that you used to register. You will be sent an email with instructions on how to reset your password.'
            link={"Sign In"}
            linkLabel={"Remember your password?"}
            onLinkClick={() => browserHistory.push('/signin')}
        >
            <form onSubmit={submitHandler} style={{ width: '100%' }}>
                <div className={classes.textFieldWrapper}>
                    <TextField
                        id='email'
                        label='E-mail'
                        fullWidth={true}
                        value={email}
                        onChange={(event, value) => setEmail(event.target.value)}
                        type='email'
                        required
                    />
                </div>
                <div className={classes.bottomDivider} />
                <div className={`${classes.textFieldWrapper} ${classes.horizontalCenter}`}>
                    <ReCAPTCHA ref={recaptchaRef} actionName={'passwordReset'} onV2VisibilityChange={setDisableSubmit}>
                        <Button disabled={disableSubmit} variant="contained" color="primary" startIcon={<ActionLock />} onClick={submitHandler}>Request reset</Button>
                    </ReCAPTCHA>
                </div>
                <div className={classes.bottomDivider} />
                <input type='submit' style={{ display: 'none' }} />
            </form>
        </UserlessPage>
    )
}

//////////////////////////////////////////////////////////////////////////////

const styles = theme => ({
    bottomDivider: theme.app.bottomDivider,
    textFieldWrapper: theme.textFieldWrapper,
    horizontalCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
})

const mapStateToProps = state => ({
    reduxState: {}
})

const mapDispatchToProps = dispatch => ({
    reduxDispatch: {
        onRequestReset: (email, tokens) => {
            if (email.length === 0) {
                dispatch(showNotificationDialog('E-mail is required', 'Invalid e-mail', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            return users.requestPasswordReset({ email, ...tokens })
                .then(() => {
                    dispatch(showNotification('Password reset requested'))
                    setTimeout(() => browserHistory.push('/signin'), 50)
                })
        },
        onShowNotification: message => {
            dispatch(showNotification(message));
        },
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestReset))
