import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

/**
 *
 * @param {String} tooltipText
 * @param {any} nextButtonProps
 */
const NextPageButton = ({ tooltipText, nextButtonProps, children, classes }) => {

    return (
        <div className={classes.container}>
            {nextButtonProps.disabled && nextButtonProps.tooltip &&
                <p style={{ marginRight: "16px", whiteSpace: "normal", overflow: "hidden", maxHeight: "2.5rem" }} className={classes.textStyle}>
                    {tooltipText}
                </p>}
            <div className={classes.buttonContainer} >
                <Tooltip title={nextButtonProps.tooltip || ''}>
                    <span>
                        <Button {...nextButtonProps} variant="contained" color="primary" endIcon={<NavigateNextIcon />}>{children}</Button>
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////

const styles = theme => ({
    textStyle: theme.textStyle,
    container: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    buttonContainer: {
        float: "right",
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    }
})

export default withStyles(styles)(NextPageButton);
