import React from 'react'

import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../theme'


const RotatingSettingsIcon = ({ classes }) => {
    return <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <SettingsIcon className={classes.rotating} />
    </div>
}

const styles = () => ({
    rotating: {
        fontSize: 120,
        color: colors.bbOrange,
        animationName: "$infiniteRotation",
        animationDuration: "1.5s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear"
    },
    "@keyframes infiniteRotation": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(180deg)"
        }
    }
})

export default withStyles(styles)(RotatingSettingsIcon)
