import React, { useEffect, useState } from "react";

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { emailConfirmation } from '../api';
import { notificationDialogSeverity } from "../core/Constants";
import { showNotification, showNotificationDialog } from '../app/AppActions'
import UserlessPage from "../app/UserlessPage";

//////////////////////////////////////////////////////////////////////////////

const states = {
    unmounted: 0,
    waiting: 1,
    success: 2,
    suggestLogin: 3,
    suggestResend: 4
}

const EmailConfirmation = (props) => {
    const [state, setState] = useState(states.unmounted)
    const { reduxDispatch, classes } = props;

    useEffect(() => {
        // set the progress to be shown at 0.5s delay
        setState(states.waiting);
        // Send the token to backend verification
        const token = props.params?.token;
        emailConfirmation.validate(token)
            .then(res => {
                // Display message
                reduxDispatch.showMessage('Email confirmed. You can now sign in.');
                // Change the content
                setState(states.success);
            })
            .catch(err => {
                const { type } = err.data || {};
                switch (type) {
                    case 'alreadyUsed':
                        setState(states.suggestLogin);
                        break;
                    case 'expired':
                        setState(states.suggestResend);
                        break;
                    default:
                        // Display error and redirect to signin page
                        console.error(err);
                        reduxDispatch.showNotificationDialog("Something went wrong", 'Error', notificationDialogSeverity.error);
                        browserHistory.push('/signin')
                }
            })
    }, [props.params, reduxDispatch])

    const invalidLink = (
        <div className={classes.invalidLink}>
            <Typography className={classes.linkHintText} variant='body1' gutterBottom>
                Please
            </Typography>
            <Link component='button' color='primary' variant='body1' onClick={() => browserHistory.push('/signin')}>
                Sign In
            </Link>
            <Typography className={classes.linkHintText} variant='body1' gutterBottom>
                or request a new
            </Typography>
            <Link component='button' color='primary' variant='body1' onClick={() => browserHistory.push('/resendConfirmation')}>
                Confirmation email
            </Link>
        </div>
    )

    let component, infoText, link, linkLabel, onLinkClick;
    switch (state) {
        case states.unmounted:
            break;
        case states.waiting:
            component = <>
                <CircularProgress />
                <Typography variant="h6">Validating</Typography>
            </>;
            break;
        case states.success:
            infoText = 'Thank you for confirming your email. You can now sign in using your email and password'
            link = 'Sign in'
            linkLabel = ''
            onLinkClick = () => browserHistory.push('/signin')
            break;
        case states.suggestLogin:
            infoText = 'Email was already confirmed. You can sign in using your email and password'
            link = 'Sign in'
            linkLabel = ''
            onLinkClick = () => browserHistory.push('/signin')
            break;
        case states.suggestResend:
            infoText = 'The email confirmation link was expired.'
            component = invalidLink;
            break;
        default:
            console.error(`Unknown state in EmailConfirmation: ${state}`);
    }

    return (
        <UserlessPage
            title={'Email confirmation'}
            infoText={infoText}
            link={link}
            linkLabel={linkLabel}
            onLinkClick={onLinkClick}
        >
            <div className={classes.componentContainer}>
                {component}
            </div>
        </UserlessPage>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    reduxDispatch: {
        showMessage: message => dispatch(showNotification(message)),
        showNotificationDialog: (message, title, severity) => dispatch(showNotificationDialog(message, title, severity))
    }
})

const styles = theme => ({
    componentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '2em',
    },
    invalidLink: {
        display: 'flex',
        margin: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    linkHintText: {
        color: 'rgba(0, 0, 0, 0.77)',
        marginLeft: '0.35rem',
        marginRight: '0.35rem',
        marginBottom: '0px'
    },
    container: {
        ...theme.containers.flexPaper,
        minHeight: '14rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '80px' // The Toolbar is 80px in height --> adjust the bottom so that content is actually in center of the paper
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailConfirmation))
