import React from 'react'

import Octagon from '../../core/Octagon'
import Checkbox from '../../core/Checkbox'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import startCase from 'lodash.startcase'

import { colors } from '../../theme'
import { showNotification } from '../../app/AppActions'
import { cargoSpaceTypes, cargoSpaceRules } from '../../core/Constants'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import { ListItemText } from '@material-ui/core'


const OptimizerStorageDetailsView = (props) => {
    const { classes } = props
    const { cargoSpaceType, presetName, axles, useWeightDistributionFitness } = props.storage

    const cargoSpaceTypeName = Object.keys(cargoSpaceTypes).find(key => cargoSpaceTypes[key] === cargoSpaceType)
    const rules = { useWeightDistributionFitness }
    const enabledRules = Object.keys(rules).filter(key => rules[key] === true)


    const editableDetails = <div className={classes.adminContainer}>
        <br />
        <FormLabel style={{ margin: '4.9px' }}>Cargo space type:</FormLabel><br />
        <RadioGroup>
            {Object.keys(cargoSpaceTypes).filter(x => x !== 'undefined').map(type => {
                const isChecked = cargoSpaceType === cargoSpaceTypes[type]
                return (
                    <FormControlLabel
                        name={type} key={type}
                        control={<Radio color='primary' checked={isChecked} />}
                        label={type.substr(0, 1).toUpperCase() + type.substr(1)} />)
            })}
        </RadioGroup>
        <br />
        {(cargoSpaceType === cargoSpaceTypes.trailer) &&
            <div>
                <FormLabel style={{ margin: '4.9px' }}>Axle weight limits:</FormLabel>
                <Box margin='4.9px' width='100%' display='flex' flexWrap='wrap' alignContent='space-between'>
                    {axles.map((axle, index) =>
                        <Box display='flex' flexDirection='column' height='155px' key={index}>
                            <Typography style={{ justifyContent: 'left' }}>Axle {index + 1}</Typography>
                            <TextField disabled name='location' label={'Location (mm)'} value={axle.location} />
                            <TextField disabled name='weightLimit' label={'Weight limit (kg)'} value={axle.weightLimit} />
                        </Box >
                    )}
                </Box>
            </div>}
        <FormLabel style={{ margin: '4.9px' }}>Optimization rules:</FormLabel><br />
        <List>
            {Object.keys(cargoSpaceRules).map(key => (
                <Checkbox
                    key={key}
                    disabled
                    checked={rules[cargoSpaceRules[key].name]}
                    label={startCase(cargoSpaceRules[key].displayName)}
                    secondary={startCase(cargoSpaceRules[key].description)}
                    onChange={() => { console.error('not implemented')}} />
            ))}
        </List>
    </div>

    const staticDetails = <div>
        {presetName && <>
            <Paper>
                <div className={classes.subheader}>Cargo Space Preset:</div>
                <ListItem key='preset'>
                    <ListItemText primary={presetName} />
                </ListItem>
            </Paper>
            <br />
        </>}

        <Paper>
            <div className={classes.subheader}>Cargo Space Type:</div>
            <ListItem key='type'>
                <ListItemText primary={startCase(cargoSpaceTypeName)} />
            </ListItem>
        </Paper>
        <br />

        {(cargoSpaceType === cargoSpaceTypes.trailer) &&
            <>
                <TableContainer component={Paper}>
                    <div className={classes.subheader}>Axle Weight Limits:</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Axle Location (mm)</TableCell>
                                <TableCell>Weight Limit (kg)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {axles.map((axle, index) => (
                                <TableRow key={index}>
                                    <TableCell>{axle.location}</TableCell>
                                    <TableCell>{axle.weightLimit}</TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br /> {/* Add br only when trailer */}
            </>
        }
        {enabledRules.length > 0 &&
            <Paper>
                <div className={classes.subheader}>Optimization Rules:</div>
                <List>
                    {enabledRules.map(rule =>
                        <ListItem key={rule}>
                            <ListItemText primary={startCase(cargoSpaceRules[rule].displayName)} secondary={cargoSpaceRules[rule].description} />
                        </ListItem>
                    )}
                </List>
            </Paper>
        }
    </div >

    // Can user edit the details?
    const isEditable = false;

    return (
        <Octagon z={props.z} w='35rem' color={colors.octagon}>
            <h3 className={classes.headerLoadingPlanText}>Cargo space details:</h3>
            {isEditable ? editableDetails : staticDetails}
            <Button className={classes.closeButton} variant='contained' color='secondary' onClick={() => props.onClose()} >Close</Button>
        </Octagon >
    );
}


//////////////////////////////////////////////////////////////////////////////

const mapDispatchToProps = (dispatch) => {
    return {
        onShowNotification: notification => {
            dispatch(showNotification(notification));
        },
    };
}

const styles = theme => ({
    closeButton: {
        float: 'right',
        marginTop: theme.spacing(2)
    },
    stepHeaderStyle: theme.optimizer.stepHeaderStyle,
    adminContainer: theme.containers.admin,
    subheader: theme.subheader,
    headerLoadingPlanText: theme.optimizer.headerLoadingPlanText,
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(OptimizerStorageDetailsView))

//////////////////////////////////////////////////////////////////////////////
