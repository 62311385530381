export const validateName = name => {
    if (name.length === 0)
        return 'Name cannot be empty';

    return '';
}

export const validateEmail = email => {
    if (email.length === 0)
        return 'E-mail cannot be empty';

    if (email.indexOf('@') === -1)
        return 'E-mail does not contain "@" symbol';

    return '';
}

export const validateOrganization = organization => {
    if (organization.length === 0)
        return 'Organization cannot be empty';

    return '';
}

export const validatePassword = password => {
    if (password.length === 0)
        return 'Password cannot be empty';

    return '';
}

export const validatePasswordConfirmation = (password, passwordConfirm) => {
    if (password !== passwordConfirm)
        return "Password doesn't match";

    return '';
}
