import React, { useState, useEffect } from 'react'

import Octagon from '../../core/Octagon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import WarningIcon from '@material-ui/icons/Warning';

import usePresetThreer from '../../three/PresetThreer'
import Units from '../../core/Units/Units'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../theme'
import { cargoSpacePreset } from '../../api'
import { showNotificationDialog } from '../../app/AppActions'
import { setPresets } from '../../admin/AdminActions'
import { setStorages, setRules } from '../OptimizeActions'
import { Info, infoTexts } from '../InfoDialog'
import { setCargoSpaceUnit } from '../../core/Units/UnitActions'
import { loadingPlanRules, loadingDirections as loadingDirs, planStates, notificationDialogSeverity } from '../../core/Constants'

import OptimizerHeader from '../OptimizerHeader'
import OptimizerStorageDetailsView from './OptimizerStorageDetailsView';
import { connect } from 'react-redux'
import { CargoSpaceSelection } from './CargoSpaceSelection'
import { Box, Typography } from '@material-ui/core'
import { validateStorage } from '../../core/Validations'

import { cloneDeep } from "lodash"
import NextPageButton from '../components/NextPageButton'
import { stepLabels } from '../Optimize'

export const loadingDirections = {
    top: {
        text: "Top",
        value: 0,
        strValue: loadingDirs.top,
        img: '/assets/LoadingDirection-Top.png',
    },
    back: {
        text: "Back",
        value: 1,
        strValue: loadingDirs.back,
        img: '/assets/LoadingDirection-Front.png',
    },
    side: {
        text: "Side",
        value: 2,
        strValue: loadingDirs.side,
        img: '/assets/LoadingDirection-Side.png',
    },
};

/**
 * Returns object { text : Top/Back/Side, value: int, img: str(path)}
 * @param {int or string} pd Value of loadingDirection z=0=top, y=1=back, x=2=side
 */
export const getLoadingDirectionObj = (pd) => {
    if (typeof pd === typeof 0) {
        if (pd === 0) return loadingDirections.top
        if (pd === 1) return loadingDirections.back
        return loadingDirections.side
    } else if (typeof pd === typeof "string") {
        if (pd === "Top") return loadingDirections.top
        if (pd === "Back") return loadingDirections.back
        return loadingDirections.side
    }

}

const InputComponent = ({ label, children, info, classes }) => (
    <List subheader={<div className={classes.subheader}>{label}</div>}>
        {info}
        <ListItem className={classes.listItem}>
            {children}
        </ ListItem>
    </List>
)

const SettingsMenu = ({ cargoSpaceUnit, handleUnitChange }) => {
    const [settingsAnchor, setSettingsAnchor] = useState(null)
    const isSettingsOpen = Boolean(settingsAnchor)
    return (<>
        <IconButton onClick={event => setSettingsAnchor(event.currentTarget)}>
            <SettingsIcon />
        </IconButton>
        <Menu
            anchorEl={settingsAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isSettingsOpen}
            onClose={() => setSettingsAnchor(null)}
        >
            <List>
                <ListItem>Cargo space</ListItem>
                <ListItem>
                    Units:
                    <ToggleButtonGroup style={{ marginLeft: "16px" }} value={cargoSpaceUnit} exclusive onChange={handleUnitChange}>
                        {Object.keys(Units).map(u => (
                            <ToggleButton key={Units[u].text} value={Units[u]} selected={Units[u].value === cargoSpaceUnit.value}>
                                {Units[u].text}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </ListItem>
            </List>
        </Menu>
    </>)
}

//TODO step-to-step validation update

const OptimizerStorageView = (props) => {
    const [state, setState] = useState({
        child: null,
        scroll: false,
        expanded: false,
    })

    const { child, scroll, expanded } = state;
    const { user, storages, cargoPresets, cargoSpaceUnit, rules } = props.reduxState;
    const { onFetchCargoPresets, onSetCargoSpaceUnit, onSetStorages, onSetRules } = props.reduxDispatch;
    const { setInfoDialog, disableChanges, checkUnpackableParcels, classes, planState } = props
    const strategy = rules.storageSelectionStrategy

    // Custom hook to get preset images with non-thread-blocking manner
    const presetImages = usePresetThreer(cargoPresets);

    useEffect(() => {
        onFetchCargoPresets(user)
    }, [onFetchCargoPresets, user])

    useEffect(() => {
        if (scroll) {
            // 300ms = Collapses default time to fully collapse. Can't scroll to bottom before
            // setTimeout(() => document.getElementById("scrollContainer").scroll(0, 2160), 350)
            setTimeout(() => document.getElementById("scrollContainer").scroll({ top: 2160, behavior: "smooth" }), 350)
            setState({ ...state, scroll: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scroll])

    const onCloseChild = () => setState({ ...state, child: null })

    /**
     * When storage details are changed, update storages-state
     * @param {Number} i
     * @returns
     */
    const onChangeStorage = (i) => (storage) => {
        let newStorages = storages.map((x, idx) => {
            if (idx === i)
                return {...x, ...storage};
            return x;
        })

        // storages[i] = storage
        onSetStorages(newStorages)
    }

    /**
     * Find storage from storages-state using index, then update cargo space details-page component and set it to child.
     * @param {Number} index
     */
    const openStorageDetails = (index = 0) => {
        let component = <OptimizerStorageDetailsView storage={storages[index]} z={props.z - 1} onClose={onCloseChild} />
        setState({ ...state, child: component })
    }

    /**
     * When storages are updated we also need to update the content of the storage details-component
     */
    useEffect(() => {
        if (child != null) {

            // Find which storage is used in child
            const idx = storages.findIndex(s => s.id === child.props.storage.id)

            // Update component inside child
            openStorageDetails(idx)

        }

        /**
         * We don't want to listen to changes in child, because openStorageDetails() updates child.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storages])

    const renderDimensions = (data) => {
        if (data.presetName)
            return <>
                <div style={{ height: "70px", width: "90px" }}>{(presetImages && presetImages[data.presetName]) && <img src={presetImages[data.presetName]} alt=" " style={{ height: "70px", width: "auto" }} />}</div>
                <div style={{ marginLeft: '2rem' }}>{data.presetName}</div>
            </>
        const dim = data.dimensions;
        const meters = d => (d / 1000).toFixed(1)
        return <>
            <div style={{ marginLeft: '2rem', flex: '0 0 8rem' }}>
                {meters(dim.x)} &times; {meters(dim.y)} &times; {meters(dim.z)}
            </div>
        </>
    }

    const handleUnitChange = (event, newUnit) => {
        if (!newUnit)
            return

        onSetCargoSpaceUnit(newUnit)
    }

    const handleStrategyChange = (event) => {
        const newRule = { storageSelectionStrategy: event.target.value }
        onSetRules(newRule)
        setState({ ...state, child: null })
    }

    const strategySelection = (
        <Paper elevation={4} style={{ paddingBottom: '0.5rem' }}>
            <div className={classes.subheader}>
                Cargo Space Selection Strategy
            </div>
            <Info info={infoTexts.strategy} onClick={setInfoDialog} />
            <RadioGroup row name='strategy' value={strategy} onChange={handleStrategyChange} style={{ justifyContent: 'space-around' }}>
                {Object.keys(loadingPlanRules.storageSelectionStrategy.values).map(ruleName => {
                    const rule = loadingPlanRules.storageSelectionStrategy.values[ruleName];
                    return (<Box key={rule.name}>
                        <FormControlLabel
                            disabled={disableChanges}
                            value={rule.name}
                            key={rule.name}
                            label={rule.displayName}
                            control={<Radio color='primary' />} style={rule.name === strategy ? { color: "rgba(0, 0, 0, 0.87)" } : null}
                        />
                        <img src={rule.img} style={{ display: 'block', height: '40px', margin: 'auto' }} alt='' />
                    </Box>)
                })}
            </RadioGroup>
        </Paper>
    )

    const handleExpand = panelId => (event, isExpanded) => {
        setState({ ...state, expanded: isExpanded ? panelId : false, child: null })
        checkUnpackableParcels(storages[0].dimensions, storages[0].weightLimit)
    };


    const panelSummary = (index, id, label) => {
        if (expanded !== id) return <>
            <Typography className={classes.panelHeader} variant='body1'>{label}</Typography>
            {renderDimensions(storages[index])}
            {!validateStorage(storages[index]) && <><WarningIcon color="error" style={{ margin: '0 11px 0 22px' }} />
                <span style={{ color: 'red' }}>Can't be empty</span></>}
        </>
        else return <>
            <Typography className={classes.panelHeader} variant='body1'>{label}</Typography>
        </>
    }

    const commonProps = (idx) => ({
        cargoSpaceUnit, cargoPresets, presetImages, disableChanges, classes, setInfoDialog,
        // Only check unpackable parcels for the main storage
        checkUnpackableParcels: (storage) => idx === 0 ? checkUnpackableParcels(storage.dimensions, storage.weightLimit, storage.loadingDirection) : null
    })


    const expansionPanel = (index, id, label) => <ExpansionPanel elevation={4} classes={{ root: classes.panelRoot, expanded: 'expanded' }} expanded={expanded === id} onChange={handleExpand(id)}>
        <ExpansionPanelSummary classes={{ content: classes.panelSummaryContent, expanded: 'expanded' }} expandIcon={<ExpandMoreIcon />}>
            {panelSummary(index, id, label)}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelContents}>
            <CargoSpaceSelection storage={storages[index]} onChange={onChangeStorage(index)} {...commonProps(index)}
                openStorageDetails={() => openStorageDetails(index)}
                closeButton={<Button variant="contained" color="primary" onClick={handleExpand(id)} style={{ float: "right", margin: '0.5rem 0' }}>Close</Button>} />
        </ExpansionPanelDetails>
    </ExpansionPanel>

    const cargoSpaceSelectionGroup = (
        (strategy === 'UseSecondaryOnLast' && (storages[1])) ?
            <>
                {expansionPanel(0, 'primary', 'Primary')}
                {expansionPanel(1, 'secondary', 'Secondary')}
            </>
            : <CargoSpaceSelection storage={storages[0]} onChange={onChangeStorage(0)} openStorageDetails={() => openStorageDetails()} {...commonProps(0)} />
    )

    return (
        <Octagon child={child} z={props.z} w='65rem' color={colors.octagon} isOpen={props.octagonOpen}>
            <div className={classes.octagonContainer}>
                <OptimizerHeader progress={props.progress} />
                <div className={classes.viewContainer} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: "calc(100% - 52px)" }}>
                        <div style={{ flexGrow: 1, height: '48px', lineHeight: '48px', fontSize: "1.17em", fontWeight: "bold", textAlign: 'center' }}>
                            {(disableChanges && planState !== planStates.loading) &&
                                'Calculating loading plan. Editing loading plan disabled.'
                            }
                            {(planState === planStates.loading) &&
                                'Loading plan sent to Loading Assistant. Editing loading plan disabled'
                            }
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <SettingsMenu cargoSpaceUnit={cargoSpaceUnit} handleUnitChange={handleUnitChange} />
                        </div>
                    </div>
                    <div id="scrollContainer" style={{ width: '944px', overflowX: 'hidden', overflowY: 'auto', paddingLeft: '0.5rem', paddingRight: '1.5rem' }}  >
                        <div id="contentContainer" style={{ width: '912px' }}>
                            {strategySelection}
                            <br />
                            {cargoSpaceSelectionGroup}
                            <div style={{ marginTop: '0.5rem' }} />
                        </div>
                    </div>
                </div>
                <NextPageButton
                    tooltipText='Cargo space is invalid' // TODO More proper description of what's needed
                    nextButtonProps={props.nextButtonProps}
                >
                    {stepLabels.optimizeAndInspect}
                </NextPageButton>
            </div>
        </Octagon >
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        reduxState: {
            user: state.user,
            storages: cloneDeep(state.optimizer.storages),
            rules: state.optimizer.rules,
            cargoPresets: state.admin.cargoPresets.data,
            cargoSpaceUnit: state.unit.cargoSpace,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            onFetchCargoPresets: (user) => {
                cargoSpacePreset.query({ organization: user.organization?._id })
                    .then(result => {
                        dispatch(setPresets(result))
                    })
                    .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
            },
            onSetCargoSpaceUnit: unit => dispatch(setCargoSpaceUnit(unit)),
            onSetStorages: (storages) => dispatch(setStorages(storages)),
            onSetRules: rules => dispatch(setRules(rules)),
        }
    }
}

const styles = theme => ({
    octagonContainer: theme.containers.octagonChild,
    viewContainer: theme.optimizer.viewContainer,
    raisedButton: theme.raisedButton,
    textStyle: theme.textStyle,
    noPadding: { padding: "0px" },
    noLeftPadding: {
        paddingLeft: "0px",
        marginLeft: "0px"
    },
    panelRoot: {
        width: 'calc(100% - 32px)',
        margin: '0.35rem 1rem',
        '&.expanded': {
            margin: '0.35rem 1rem',
        }
    },
    panelSummaryContent: {
        alignItems: 'center',
        minHeight: '70px',
        '&.expanded': { minHeight: '60px', margin: '0' }
    },
    panelHeader: {
        flexBasis: '50%',
    },
    panelContents: {
        display: "block"
    },
    autoComplete: {
        width: '100%',
        '& > span': {
            marginRight: 10,
            fontSize: 18
        }
    },
    paperWrapper: {
        width: "calc(100% - 28px)",
    },
    subheader: {
        display: "inline-flex",
        margin: theme.spacing(1)
    },
    listItem: {
        paddingLeft: theme.spacing(2)
    },
    autoCompleteTxtField: {
        width: "calc(100% - 8px)"
    },
    fieldset: { ...theme.fieldset, display: 'flex' }
})

export const Input = withStyles(styles)(InputComponent)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OptimizerStorageView))
