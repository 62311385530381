import React, { } from 'react'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles'
import ReplyIcon from '@material-ui/icons/Reply';
import Octagon from '../../core/Octagon'
import { colors } from '../../theme'

const DeletedView = (props) => {
    const { classes } = props

    return <Octagon z={props.z} w='35rem' color={colors.octagon}>
        <h3 className={classes.headerLoadingPlanText}>Deleted items:</h3>
        <TableContainer component={Paper} style={{ width: '464px', maxHeight: 'calc(100% - 40px)', marginBottom: '0.5rem' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.deletedParcels.map((p, i) =>
                        <TableRow key={i}>
                            <TableCell className={classes.tableCell} >{p.id}</TableCell>
                            <TableCell className={classes.tableCell}>{p.description}</TableCell>
                            <TableCell align='right'><Button variant='outlined' size='small' startIcon={<ReplyIcon />} onClick={() => props.unDeleteParcel(p)} >Restore</Button></TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        <Button style={{ float: 'right' }} variant='contained' color='secondary' onClick={() => props.close()} >Close</Button>
    </Octagon >
}

const styles = theme => ({
    tableCell: { maxWidth: '155px', textOverflow: 'ellipsis', overflow: 'hidden' },
    headerLoadingPlanText: theme.optimizer.headerLoadingPlanText,
})

export default withStyles(styles)(DeletedView)
