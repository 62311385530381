export const setCargoDataUnit = unit => {
    return { type: "SET_CARGODATA_UNIT", unit }
}

export const setCargoSpaceUnit = unit => {
    return { type: "SET_CARGOSPACE_UNIT", unit }
}

export const setUnits = units => {
    return { type: "SET_UNITS", units}
}