import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "../../core/DialogTitle";

import PropTypes from "prop-types";

//////////////////////////////////////////////////////////////////////////////

const LMCNotAllowedDialog = (props) => {

    return (
        <Dialog open={props.open} maxWidth="sm">
            <DialogTitle onClose={props.onClose} >
                Not allowed
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This would remove the only cargo item and the loading plan would be removed. Loading plan can be removed only by office users. Please contact office user of force complete
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    cancel
                </Button>
                <Button color="primary" onClick={props.onForceComplete}>
                    Force complete
                </Button>
            </DialogActions>
        </Dialog>
    );

};

//////////////////////////////////////////////////////////////////////////////

LMCNotAllowedDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onForceComplete: PropTypes.func.isRequired,
};

//////////////////////////////////////////////////////////////////////////////

export default LMCNotAllowedDialog;
