import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TabletIcon from '@material-ui/icons/Tablet';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';

import moment from 'moment';
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

//////////////////////////////////////////////////////////////////////////////

import FeatureEnabler from './FeatureEnabler';
import { closeDrawer, openDrawer, openProfileMenu, closeProfileMenu, toggleTabletMode } from './AppActions'
import { users } from '../api';
import { pathRequiresAuthentication } from '..';
import { momentDateFormat } from '../core/dateUtils';
import ProfileMenu from '../profile/ProfileMenu';
import { roleObjects } from '../core/Constants';

//////////////////////////////////////////////////////////////////////////////

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        margin: 0
    },
    logo: {
        height: '2.0rem',
        marginLeft: '0.4rem'
    },
    tablet: theme.tablet,
    desktop: theme.desktop,
    white: {
        color: theme.palette.common.white,
    },
    profileDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    profileMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: {
        height: "4rem"
    },
    trialTimerStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(4),
        textAlign: 'center'
    }
}));


export const Header = ({ drawerOpen, user, onOpenDrawer, onCloseDrawer, location, profileOpen, onOpenProfile, onCloseProfile, onToggleTabletMode }) => {
    const classes = useStyles();
    let mobileNavIcon, profileButton, tabletButton, tabletButtonIcon, trialTimer;
    let tabletButtonToolTipText = '';


    if (!pathRequiresAuthentication(location.pathname)) {
        //do nothing
    }
    else {
        /* classes.tablet have display of 'None' with wide enough screen */
        // If user is at tabletMode, always display the mobileNavIcon,
        // otherwise display it according to screen size configured by classes.tablet
        const tabletClass = user?.tabletMode ? '' : classes.tablet;
        mobileNavIcon =
            <IconButton className={`${tabletClass} ${classes.white}`} edge="start" onClick={drawerOpen ? onCloseDrawer : onOpenDrawer} >
                <MenuIcon />
            </IconButton>

        profileButton = (
            user._id != null &&
            <div>
                <Button
                    // Custom, static fontSize because sizings change from tablet to desktop theme
                    style={{ fontSize: '0.9375rem' }}
                    className={`${classes.white} ${classes.profileDesktop}`}
                    onClick={profileOpen ? onCloseProfile : onOpenProfile}
                    endIcon={<AccountCircleIcon fontSize={"large"} />}
                >
                    {user.name}
                </Button>
                <IconButton
                    className={`${classes.white} ${classes.profileMobile}`}
                    onClick={profileOpen ? onCloseProfile : onOpenProfile}>
                    <AccountCircleIcon fontSize={"large"} />
                </IconButton>
            </div>
        )

        let userHasRequiredRole = user.roles.includes('superadmin');
        if (user.tabletMode) {
            tabletButtonIcon = <LaptopChromebookIcon fontSize="large" />
            tabletButtonToolTipText = "Change to desktop mode"
            userHasRequiredRole = userHasRequiredRole || user?.roles.includes(roleObjects.officeUser.name) || user?.roles.includes(roleObjects.officeAdmin.name);
        } else {
            tabletButtonIcon = <TabletIcon fontSize="large" />
            tabletButtonToolTipText = "Change to tablet mode"
            userHasRequiredRole = userHasRequiredRole || user?.roles.includes(roleObjects.loadingOperationsUser.name);
        }


        tabletButton = (
            // If user isn't superadmin OR trial user, don't even create the button
            user._id && userHasRequiredRole &&
            <FeatureEnabler featureId='loadingAssistant'>
                <Tooltip title={tabletButtonToolTipText} >
                    <IconButton
                        className={`${classes.white}`}
                        onClick={() => onToggleTabletMode(user)}>
                        {tabletButtonIcon}
                    </IconButton>
                </Tooltip>
            </FeatureEnabler>
        )

        if (user?.organization?.isTrial) {
            // trialExpires is Date type at MongooSchema, but parsed from json (from request), it's a string -> convert to moment
            const expiresMoment = moment(user.organization.trialExpires);
            const daysLeft = expiresMoment.diff(moment(), 'days') // diff truncates the time by default
            let remainingTimeText;
            if (daysLeft < 1) {
                const hoursLeft = expiresMoment.diff(moment(), 'hours');
                remainingTimeText = `${hoursLeft} hour${hoursLeft > 1 ? 's' : ''} of trial remaining`;
            } else {
                remainingTimeText = `${daysLeft} day${daysLeft > 1 ? 's' : ''} of trial remaining`;
            }

            trialTimer = (
                <div className={classes.trialTimerStyle} >
                    <Typography className={classes.white}>{remainingTimeText}</Typography>
                    <Typography variant='caption' className={classes.white}>{`Expires at ${expiresMoment.format(momentDateFormat)}`}</Typography>
                </div>
            )
        }
    }

    const theAppBar = (
        <AppBar position="fixed" className={`${classes.appBar}`}>
            <Toolbar className={`${user.tablet ? classes.toolbar : ''}`}>
                {mobileNavIcon}
                <img src='/assets/BOXBOT_white.svg' className={classes.logo} alt=" " />
                {trialTimer}
                <div className={classes.grow} />
                {tabletButton}
                {profileButton}
            </Toolbar>
        </AppBar >
    )

    return (
        <>
            {theAppBar}
            <ProfileMenu />
            <Toolbar />
        </>
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.drawer.open,
        user: state.user,
        profileOpen: state.profileMenu.open
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseDrawer: () => {
            dispatch(closeDrawer())
        },
        onOpenDrawer: () => {
            dispatch(openDrawer())
        },
        onOpenProfile: (event) => {
            dispatch(openProfileMenu(event.currentTarget))
        },
        onCloseProfile: () => {
            dispatch(closeProfileMenu())
        },
        onNavigate: pathname => {
            browserHistory.push(pathname)
            dispatch(closeDrawer())
        },
        onToggleTabletMode: (user) => {
            if (!user.organization.enabledFeatures.includes('loadingAssistant'))
                return
            const value = user.tabletMode || false;
            const updateUser = {
                tabletMode: !value
            }
            users.patch(user._id, updateUser)
                .then((res) => {
                    dispatch(toggleTabletMode(res.tabletMode))
                    if (res.tabletMode) {
                        browserHistory.push('/loadingAssistant')
                    }
                    if (!res.tabletMode) {
                        browserHistory.push('/home')
                    }
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

//////////////////////////////////////////////////////////////////////////////
