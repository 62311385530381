import React from 'react';
import PropTypes from 'prop-types'

import TablePagination from '@material-ui/core/TablePagination';

import { withStyles } from '@material-ui/core/styles';

//////////////////////////////////////////////////////////////////////////////

const GeneralDataTableFooter = (props) => {
    const { classes } = props;


    const handlePageChange = (event, page) => {
        props.onChangePagination(s => ({ ...s, page: page }))
    }

    const handleRowsPerPageChange = (event) => {
        props.onChangePagination(s => ({ ...s, rowsPerPage: event.target.value, page: 0 }))
    }

//////////////////////////////////////////////////////////////////////////////

    return (
        <TablePagination
            component='div'
            className={classes.paginationStyle}
            count={props.total}
            page={props.paginationState.page}
            rowsPerPage={props.paginationState.rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: Number.MAX_SAFE_INTEGER }]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
        />
    );
};

//////////////////////////////////////////////////////////////////////////////

GeneralDataTableFooter.propTypes = {
    total: PropTypes.number.isRequired,
}

GeneralDataTableFooter.defaultProps = {
    paginationState: {},
    total: 0,
}

const styles = (theme) => ({
    paginationStyle: {
        overflow: 'visible'
    }
});


export default withStyles(styles)(GeneralDataTableFooter);
