import React, { useState, useEffect, useRef } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText'
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckIcon from "@material-ui/icons/Check";
import HeightIcon from '@material-ui/icons/Height';
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import UndoIcon from '@material-ui/icons/Undo';

import DialogTitle from "../../core/DialogTitle";
import NumberTextField from "../../core/NumberTextField";
import TabSelectionMenuItem from "../../core/TabSelectionMenuItem";
import { stackabilityLookup, lashingLookup } from "../../core/Constants";

import { colors } from '../../theme'

import { validateTransformedParcelWeight } from "../LMCUtils";

//////////////////////////////////////////////////////////////////////////////

const LMCSelectedParcelCard = (props) => {
    const { classes } = props;

    /**
     * resetParcelChanges is a Boolean-state that is used to check if user has pressed the 'Reset changes'-button.
     * When the button is pressed the state is set to !resetParcelChanges.
     * This state change triggers useEffect()-hook which sets numberTextFieldReset to !numberTextFieldReset.
     * numberTextFieldReset is used as a resetValue in numberTextField.
     * When this state changes, the value that is shown on the numberTextField is reset to the initial value.
     * This is admittedly little hack-y, but this is required to make sure that numberTextField works correctly without making rectorings into the numberTextField component.
     */
    const [resetParcelChanges, setResetParcelChanges] = useState(false);
    const [numberTextFieldReset, setNumberTextFieldReset] = useState(false);

    const weightField = useRef(null);


    /**
     * State-object for values in the selectors used in the card.
     * The main ones user should be able to change are stackability and lashing (reserve fullwidth).
     */
    const [selectorValues, setSelectorValues] = useState({
        stackability: "",
        lashing: "",
    });

    /**
     * State controlling open/close of Unload Parcel -dialog
     */
    const [unloadDialogOpen, setUnloadDialogOpen] = useState(false);

    /**
     * Show error on weight-textfield if weight is over the cargo space weight limit
     */
    const [error, setError] = useState(false)

    /**
     * Object that controls which fields are highlighted.
     * Fields are highlighted depending on if they're edited or not.
     */
    const allParcels = props.solution.cargoSpaces[props.solution.cargoSpaceIndex].packedParcels;
    const originalParcel = allParcels.find(parcel => parcel.id === props.selectedParcel?.id);

    let editHighlights;
    if (originalParcel != null && props.selectedParcel != null) {
        editHighlights = {
            weight: (props.selectedParcel?.weight !== originalParcel?.weight),
            stackability: (props.selectedParcel?.stackability !== originalParcel?.stackability),
            lashing: (props.selectedParcel?.lashing !== originalParcel?.lashing),
            height: (props.selectedParcel?.packedDimensions.z !== originalParcel?.packedDimensions.z) || (props.selectedParcel?.location.z !== originalParcel?.location.z),
            width: (props.selectedParcel?.packedDimensions.x !== originalParcel?.packedDimensions.x ) || (props.selectedParcel?.location.x !== originalParcel?.location.x),
            depth: (props.selectedParcel?.packedDimensions.y !== originalParcel?.packedDimensions.y) || (props.selectedParcel?.location.y !== originalParcel?.location.y)
        };
    } else {
        editHighlights = {
            weight: false,
            stackability: false,
            lashing: false,
            height: false,
            width: false,
            depth: false
        };
    };


    /**
     * When props.selectedParcel changes, set selectorValues depending on parcels information.
     * These fields are needed to control 'Stackability' and 'Reserve fullwidth' -selectors.
     *
     * Also, validate props.selectedParcels weight information.
     * Don't make this into it's own useEffect, we don't need multiple useEffects reacting to same changes in same props.
     */
    useEffect(() => {
        if (
            props.solution == null ||
            props.selectedParcel == null
        ) {
            return;
        };
        setSelectorValues(() => ({
            stackability: props.selectedParcel.stackability,
            lashing: props.selectedParcel.lashing,
        }));

        // Validate selected parcels weight.
        const weightValidation = validateTransformedParcelWeight(props.selectedParcel, props.solution?.cargoSpaces[props.solution.cargoSpaceIndex])
        setError(weightValidation.weightLimitBroken)
    }, [props.selectedParcel, props.solution]);


    /**
     * If user selects another parcel, or presses the 'Reset changes'-button then update numberTextFieldReset,
     * which triggers NumberTextField resetValue, which resets the textField
     */
    useEffect(() => {
        setNumberTextFieldReset(s => !s);
    }, [props.selectedParcel, resetParcelChanges]);


    /**
     * Handler function for the 'Reset changes'-button.
     * First, set resetParcelChanges to !resetParcelChanges so that numberTextFieldReset can be reset.
     * Then take all parcels from props.solution and find selected parcels original counterpart.
     * Then, use props.updateParcel() -function to update the selected parcels data fields back to the original ones.
     */
    const handleResetClick = () => {
        setResetParcelChanges(!resetParcelChanges);
        const allParcels =
            props.solution?.cargoSpaces[props.solution?.cargoSpaceIndex]
                .packedParcels;
        const originalParcel = allParcels.find(
            (parcel) => parcel.id === props.selectedParcel.id
        );
        props.updateParcel({ ...props.selectedParcel, ...originalParcel });
    };


    const unloadParcelConfirmationDialog = (
        <Dialog
            open={unloadDialogOpen}
            onClose={() => setUnloadDialogOpen(false)}
        >
            <DialogTitle
                onClose={() => setUnloadDialogOpen(false)}
            >
                Unload parcel?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Are you sure you want to unload parcel ${props.selectedParcel?.id}?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={() => setUnloadDialogOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    onClick={async () => {
                        await props.unloadParcel()
                        setUnloadDialogOpen(false);
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )


    //////////////////////////////////////////////////////////////////////////////

    return props.selectedParcel == null ? (
        <Card className={classes.card}>
            <Typography variant="h5" className={classes.placeholderTitle}>
                {props.solution.cargoSpaces[props.solution.cargoSpaceIndex].forcedToCompletion ?
                    'Loading job was forced to complete.'
                    :
                    'Start editing a cargo item by clicking one on the cargo space'
                }
            </Typography>
        </Card>
    ) : (
        <div>
            {unloadParcelConfirmationDialog}
            <Card className={classes.card}>
                <CardActions className={classes.cardActions}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.textFieldContainer}
                    >
                        <Grid item xs={12}>
                            <CardHeader
                                className={classes.cardHeader}
                                title={
                                    <Typography
                                        variant="h5"
                                        className={classes.headerText}
                                    >
                                        {props.selectedParcel?.id}
                                    </Typography>
                                }
                                subheader={
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.headerText}
                                    >
                                        {props.selectedParcel?.description}
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberTextField
                                className={classes.textFieldStyle}
                                inputProps={{
                                    className: editHighlights.weight && !error ? classes.editHighlight : null,
                                    ref: weightField
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>Kg</InputAdornment>
                                }}
                                unit={1}
                                label={"Weight"}
                                value={props.selectedParcel?.weight}
                                resetValue={numberTextFieldReset}
                                error={error}
                                helperText={error ? 'Weight over weight limit' : ''}
                                acceptZero
                                onChange={(value) => {
                                    props.updateParcel({
                                        ...props.selectedParcel,
                                        weight: value,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        weightField.current.blur(); // Unfocus NumberTextField when pressing Enter
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                className={classes.textFieldStyle}
                                inputProps={{
                                    className: editHighlights.stackability ? classes.editHighlight : null
                                }}
                                value={selectorValues.stackability}
                                label="Stackability"
                                select
                                onChange={(event) => {
                                    props.updateParcel({
                                        ...props.selectedParcel,
                                        stackability: event.target.value,
                                    });
                                }}
                            >
                                {Object.keys(stackabilityLookup).map((key) => (
                                    <TabSelectionMenuItem key={key} value={key}>
                                        {stackabilityLookup[key]}
                                    </TabSelectionMenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                className={classes.textFieldStyle}
                                inputProps={{
                                    className: editHighlights.lashing ? classes.editHighlight : null
                                }}
                                label="Reserve Fullwidth"
                                select
                                value={selectorValues.lashing}
                                onChange={(event) => {
                                    props.updateParcel({
                                        ...props.selectedParcel,
                                        lashing: event.target.value,
                                    });
                                }}
                            >
                                {Object.keys(lashingLookup).map((key) => (
                                    <TabSelectionMenuItem key={key} value={key}>
                                        {lashingLookup[key]}
                                    </TabSelectionMenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    <Grid
                        container
                        spacing={1}
                        className={classes.parcelManipulationContainer}
                    >
                        <Grid
                            item
                            xs={12}
                            className={classes.dimensionEditContainer}
                        >
                            <Box className={classes.dimensionEditTextContainer}>
                                <Typography className={classes.dimensionEditLabel}>
                                    {"Height"}
                                </Typography>
                                <Typography variant="subtitle1" className={editHighlights.height ? classes.editHighlight : ''}>
                                    {`${props.selectedParcel?.packedDimensions.z} mm`}
                                </Typography>
                            </Box>
                            <Tooltip
                                title='Touch arrow on the cargo item to start editing'
                                enterTouchDelay={5}
                                leaveTouchDelay={3000}
                            >
                                <ArrowRightAltIcon
                                    style={{ color: "#E84646", transform: 'rotate(-90deg)', fontSize: '3.5rem' }}
                                />
                            </Tooltip>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            className={classes.dimensionEditContainer}
                        >
                            <Box className={classes.dimensionEditTextContainer}>
                                <Typography className={classes.dimensionEditLabel}>
                                    {"Width"}
                                </Typography>
                                <Typography variant="subtitle1" className={editHighlights.width ? classes.editHighlight : ''}>
                                    {`${props.selectedParcel?.packedDimensions.x} mm`}
                                </Typography>
                            </Box>
                            <Tooltip
                                title='Touch arrow on the cargo item to start editing'
                                enterTouchDelay={5}
                                leaveTouchDelay={3000}
                            >
                                <HeightIcon
                                    style={{ color: "#00AC15", transform: 'rotate(90deg)', fontSize: '3.5rem' }}
                                />
                            </Tooltip>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            className={classes.dimensionEditContainer}
                        >
                            <Box className={classes.dimensionEditTextContainer}>
                                <Typography className={classes.dimensionEditLabel}>
                                    {"Depth"}
                                </Typography>
                                <Typography variant="subtitle1" className={editHighlights.depth ? classes.editHighlight : ''}>
                                    {`${props.selectedParcel?.packedDimensions.y} mm`}
                                </Typography>
                            </Box>
                            <Tooltip
                                title='Touch arrow on the cargo item to start editing'
                                enterTouchDelay={5}
                                leaveTouchDelay={3000}
                            >
                                <HeightIcon
                                    style={{ color: "#3880E4", fontSize: '3.5rem' }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    <Box className={classes.applyChangesContainer}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => props.onBoxUpdate(props.selectedParcel)}
                            endIcon={<CheckIcon />}
                            // Apply changes -button should be disabled when no changes have been made
                            disabled={
                                (!editHighlights.weight &&
                                !editHighlights.stackability &&
                                !editHighlights.lashing &&
                                !editHighlights.height &&
                                !editHighlights.width &&
                                !editHighlights.depth) ||
                                error
                            }
                        >
                            Apply changes
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleResetClick()}
                            endIcon={<RotateLeftIcon />}
                        >
                            Reset changes
                        </Button>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => setUnloadDialogOpen(true)}
                            endIcon={<UndoIcon />}
                        >
                            Unload parcel
                        </Button>
                    </Box>
                </CardActions>
            </Card>
        </div>
    );
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "850px",
    },
    placeholderTitle: {
        margin: '20px',
        width: 'auto',
        height: "75px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cardHeader: {
        display: "block",
        width: "90%",
        padding: "0 !important",
    },
    headerText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    cardActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    textFieldContainer: {
        padding: "10px",
    },
    parcelManipulationContainer: {
        width: "min-content",
        padding: "10px",
        rowGap: "10px"
    },
    dimensionEditContainer: {
        display: "flex",
        flexDirection: "row",
        height: "50px",
        alignItems: "center",
        // justifyContent: "space-between",
        columnGap: "25px",
    },
    dimensionEditTextContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    dimensionEditLabel: theme.labelTextStyle,
    heightButton: { // Red button
        backgroundColor: "#E84646",
        color: "white",
        "&:hover": {
            backgroundColor: "#FF6E6E",
        },
    },
    widthButton: { // Green button
        backgroundColor: "#72E563",
        color: "black",
        "&:hover": {
            backgroundColor: "#95F289",
        },
    },
    depthButton: { // Blue button
        backgroundColor: "#3880E4",
        color: "white",
        "&:hover": {
            backgroundColor: "#74ABF7",
        },
    },
    applyChangesContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: "10px",
        padding: "10px",
    },
    textFieldStyle: {
        width: '100%'
    },
    editHighlight: {
        color: colors.bbOrange,
        fontWeight: 500,
    },
});

export default withStyles(styles)(LMCSelectedParcelCard);
