import React, { useState, useEffect, useCallback, forwardRef } from 'react'
import { Collapse } from '@material-ui/core';

//////////////////////////////////////////////////////////////////////////////

const loadScriptByURL = (id, url, callback) => {
    const scriptExists = document.getElementById(id);

    if (!scriptExists) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = () => {
            if (callback) callback();
        }
        document.body.appendChild(script);
    }

    if (scriptExists && callback)
        callback();
}

const setReCAPTCHAVisibility = show => {
    const badgeElements = document.getElementsByClassName("grecaptcha-badge");
    if (badgeElements.length === 0) {
        return;
    }

    if (badgeElements.length > 1) {
        throw new Error("Found multiple badgeElements, which is a no-op. DO NOT USE grecaptcha-badge className - it's reserved  for google recaptcha!");
    }

    badgeElements[0].style.visibility = show ? '' : 'hidden';
}

/**
 *
 * @param {ref} ref: reference
 * @param {string} actionName: action name to be used with v3 to identify usage
 * @param {boolean} v2Disabled: disable v2 checkbox
 * @param {(boolean) => void}. onV2VisibilityChange: callback fired whenever v2 checkbox visibility is changed. Fn(boolean) => void
 *
 * NOTE - ReCAPTCHA populates provided ref with functions:
 * @function getTokens: async () => ({ string: v3Token, string: v2Token })
 * @function onError: (error) => boolean. Returns true if the provided error was reCAPTCHA v3 validation error. NOTE: MUST BE CALLED when v3 validation error occurs in order to show v2 checkbox.
 *
 * @returns
 */
const ReCAPTCHA = forwardRef((props, ref) => {
    const [state, setState] = useState({
        v2token: null,
        v2rendered: false,
        displayV2: false,
    })

    // ===========================
    // Load ReCATPCHA v3
    // ===========================
    useEffect(() => {
        // https://www.cluemediator.com/how-to-implement-recaptcha-v3-in-react
        loadScriptByURL("g-recaptcha", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY_V3}`, () => {
            // Make sure the reCAPTCHA is visible when this component is mounted
            setReCAPTCHAVisibility(true);
        });

        const onError = error => {
            const { name, className } = error;
            const isV3Error = name === 'ReCaptchaValidationError' && className?.startsWith('v3');
            setState(s => ({ ...s, displayV2: !props.v2Disabled && isV3Error }))
            return isV3Error;
        }

        ref.current = {
            ...ref.current,
            onError: onError,
        }
    }, [ref, props.v2Disabled])


    // ===========================
    // Hide reCAPTCHA badge when component unmounts
    // ===========================
    useEffect(() => {
        return () => {
            setReCAPTCHAVisibility(false);
        }
    }, [])


    // ===========================
    // Load ReCATPCHA v2
    // ===========================
    useEffect(() => {
        if (state.v2rendered || props.v2Disabled)
            return;

        // Load recaptcha v2
        loadScriptByURL("g-recaptcha-v2", `https://www.google.com/recaptcha/api.js?render=explicit`, () => {
            window.grecaptcha.ready(() => {
                setState(s => ({ ...s, v2rendered: true }))
                try {
                    const el = document.getElementById("g-recaptcha-v2-div");
                    window.grecaptcha.render(el, {
                        'sitekey': process.env.REACT_APP_RECAPTCHA_KEY_V2,
                        'callback': v2token => {
                            setState(s => ({ ...s, v2token: v2token, displayV2: false }))

                            // Clear the rendering in order to re-enable checkbox checking after a press
                            setTimeout(() => {
                                window.grecaptcha.reset();
                            }, 500)
                        },
                        'expired-callback': () => setState(s => ({ ...s, v2token: null })),
                        'error-callback': error => {
                            console.error(error);
                            setState(s => ({ ...s, v2token: null }))
                        }
                    })

                } catch (error) {
                    console.error("Caught error: ", error)
                }
            });
        });
    }, [state, props.v2Disabled])


    // ===========================
    // Notify about the v2 visibility change
    // ===========================
    const { onV2VisibilityChange } = props;
    useEffect(() => {
        if (typeof onV2VisibilityChange === 'function') {
            onV2VisibilityChange(state.displayV2)
        }
    }, [onV2VisibilityChange, state.displayV2])


    // ===========================
    // getTokens callback
    // ===========================
    const getTokens = useCallback(() => {
        return new Promise((resolve => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY_V3, { action: props.actionName || 'submit' })
                    .then(v3token => {
                        resolve({ v3Token: v3token, v2Token: state.v2token })
                    })
                    .catch(error => {
                        console.error(error);
                        resolve({ v3Token: null, v2Token: null })
                    })
            });
        }))
    }, [state.v2token, props.actionName])

    // Set the needed functions into ref for parent usage
    ref.current = {
        ...ref.current,
        getTokens: getTokens
    }

    return (
        <>
            {props.children}
            <Collapse in={state.displayV2} timeout={500} >
                <div id="g-recaptcha-v2-div" />
            </Collapse>
        </>
    )
})

export default ReCAPTCHA
