import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'

import get from 'lodash.get'
import uniqueId from 'lodash/uniqueId'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import CargoDataTableRowEditOverlay from './CargoDataTableRowEditOverlay'
import useDragOver from '../../../core/useDragOver'

import { cloneDeep } from 'lodash'


//////////////////////////////////////////////////////////////////////////////


const CargoDataTableBody = (props) => {

    const { classes } = props;

    const isDragging = useDragOver()


    /**
     * Function that is called when user presses the 'Delete'-button on a row
     * @param {Object} rowData is the data for this one particular row. The right row is determined with an id-value that is in data.tableData
     */
    const onRowDeleteClick = rowData => {

        const dataClone = cloneDeep(props.data);

        // Find the row on which user clicked the delete button and change rows tableData.deleting to true
        const index = dataClone.map(d => d.tableData.id).indexOf(rowData.tableData.id)
        let row = dataClone[index];
        row.tableData = { ...row.tableData, deleting: true };

        // Assign edited row back to data
        dataClone[index] = row;

        props.setData(dataClone)
    }


    /**
     * Function that is called when user presses the 'Cancel'-button.
     * This function is used in the 'Cancel'-buttons for all row actions; editing, deleting and duplicating.
     * @returns
     */
    const onCancelRowAction = () => {

        const dataClone = cloneDeep(props.data);

        let newData = dataClone.find(x => x.tableData.deleting || x.tableData.editing)

        // If user duplicated a row and then cancelled, the duplicated row should be deleted
        if (newData.tableData.duplicate) {
            const index = dataClone.indexOf(newData);
            const parcs = Array.from(dataClone);
            parcs.splice(index, 1);

            props.setData(parcs)
            return
        }

        newData.tableData = { ...newData.tableData, editing: false, deleting: false }
        props.setData(dataClone)
    }


    /**
     * Function that is called when user presses the 'Edit'-button on a row
     * @param {Object} rowData is the data for this row
     * @param {String} title is the name of a column. Edit should focus on the column user pressed on a row.
     */
    const onRowEditClick = (rowData, title) => {

        const dataClone = cloneDeep(props.data);

        // Find the row on which user clicked the edit button and change rows tableData.editing to true
        const index = dataClone.map(d => d.tableData.id).indexOf(rowData.tableData.id)
        let row = dataClone[index];
        row.tableData = { ...row.tableData, editing: true };

        // Assign the edited row back to data
        dataClone[index] = row;

        if (title) row.tableData.focus = title;
        props.setData(dataClone);
    }


    /**
     * Function that is called when user presses the 'Duplicate'-button on a row.
     * Create a new row under the duplicated one, and copy all values EXCEPT THE ID.
     * @param {Object} rowData is the data for this particular row
     */
    const onRowDuplicate = rowData => {

        const dataClone = cloneDeep(props.data);

        const idx = dataClone.map(d => d.tableData.id).indexOf(rowData.tableData.id)
        let newData = Object.assign({}, dataClone[idx])

        newData.id = ''
        newData.tableData = { ...newData.tableData, editing: true, duplicate: true, id: uniqueId() }

        let newArr = dataClone.slice(0, idx + 1)
        newArr.push(newData)
        newArr = newArr.concat(dataClone.slice(idx + 1))
        props.setData(newArr)
    }

    let editingOrDeleting = null;
    if (props.data) {
        editingOrDeleting = props.data.find(x => x.tableData.editing || x.tableData.deleting)
    }
    const disabled = !(!editingOrDeleting) || props.isLoading || props.disabled

//////////////////////////////////////////////////////////////////////////////


    return (
        <TableBody>

            {/* Show excel dropzone if: 1. there isn't data, or, 2. user is dragging content */}
            <TableRow>
                <TableCell
                    colSpan={props.columns ? props.columns.length + 1 : 0} // The cell that contains the overlay spans over all columns
                    style={{ borderBottom: 'none', padding: 0 }} // Hide the bottom-border to make the dividers on the table look uniform.
                >
                    <div
                        style={{
                            height: 'calc(100% - 487px)',
                            width: '-webkit-fill-available',
                            position: 'absolute', // excelDropzone is placed on top of the content on the table
                            zIndex: 999, // Make sure that overlay is higher than any other component on the table
                            display: isDragging || props.data?.length === 0 ? '' : 'none', // If there's no data, or user is dragging something on the page, show overlay.
                        }}
                    >
                        {props.excelDropzone}
                    </div>
                </TableCell>
            </TableRow>

            {/* Map data rows */}
            {props.data?.map((d, i) => {

                // ---------- Edit Overlay ----------
                if (d.tableData.editing) {
                    return (
                        <CargoDataTableRowEditOverlay
                            key={i}
                            rowData={d}
                            data={props.data}
                            onCancelEdit={onCancelRowAction}
                            cargoDataUnit={props.cargoDataUnit}
                            errorMessage={props.errorMessage}
                            setErrorMessage={props.setErrorMessage}
                            checkUnpackableParcels={props.checkUnpackableParcels}
                        />
                    );
                };



                // ---------- Delete Overlay ----------
                if (d.tableData.deleting) {
                    return (
                        //TODO: What if we could detect when this is hidden and "manually" trigger dragLeave event on de-render?
                        <TableRow key={i}>
                            <TableCell colSpan={props.columns.length + 1}>
                                <div style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Tooltip title={'Confirm'}>
                                        <IconButton
                                            onClick={() => props.onRowDelete(d)}
                                            size='small'
                                        >
                                            <CheckIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Cancel'}>
                                        <IconButton
                                            onClick={() => onCancelRowAction()}
                                            size='small'
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='h5' className={classes.deleteOverlayText}>
                                        Are you sure you want to delete this row?
                                    </Typography>
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                }


                // ---------- Normal Row ----------
                return (
                    <TableRow
                        key={i}
                        hover={true}
                        style={{ opacity: disabled ? 0.4 : 1 }}
                        className={classes.tableRow}
                    >

                        {/* ---------- Row action buttons ---------- */}
                        <TableCell className={classes.actionColumnCells}>
                            <Tooltip title={disabled ? '' : 'Edit'}>
                                <IconButton
                                    className={classes.actionIconButton}
                                    onClick={() => onRowEditClick(d, 'id')}
                                    disabled={disabled}
                                    size='small'
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={disabled ? '' : 'Delete'}>
                                <IconButton
                                    className={classes.actionIconButton}
                                    onClick={() => onRowDeleteClick(d)}
                                    disabled={disabled}
                                    size='small'
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={disabled ? '' : 'Duplicate'}>
                                <IconButton
                                    className={classes.actionIconButton}
                                    onClick={() => onRowDuplicate(d)}
                                    disabled={disabled}
                                    size='small'
                                >
                                    <AddToPhotosIcon />
                                </IconButton>
                            </Tooltip>
                            {d.fromExcel &&
                                <Tooltip title={disabled ? '' : "Imported from excel"}>
                                    <IconButton
                                        // className={classes.actionIconButton}
                                        size='small'
                                        disabled={disabled}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} style={{ color: "green" }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </TableCell>


                        {/* ---------- Row data cells ---------- */}
                        {props.columns.map((column, j) => {

                            const value = column.conversion ?
                                column.conversion(get(d, column.field))
                                :
                                get(d, column.field)


                            const style = (
                                /**
                                 * If row has an errors-field and it's not empty, color each value in the erronious columns red.
                                 */
                                d.errors != null &&
                                d.errors.length > 0 &&
                                typeof column.label === 'string' &&
                                d.errors.map(e => e.field).some(f => column.label.includes(f))
                            ) ?
                                { color: 'red' }
                                :
                                {}


                            return (
                                <Tooltip
                                    key={j}
                                    title={value?.toString().length > 40 ? value : ''}
                                >
                                    <TableCell
                                        key={j}
                                        style={style}
                                        className={classes.tableCell}
                                        align={column.align || 'inherit'}
                                        onClick={() => {
                                            // The row is disabled do nothing
                                            if (disabled)
                                                return;

                                            onRowEditClick(d, column.field);
                                        }}
                                    >
                                        {typeof column.render === "function" ? column.render(d) : value}
                                    </TableCell>
                                </Tooltip>
                            )
                        })}
                    </TableRow>
                )
            })}
        </TableBody>
    )
}


//////////////////////////////////////////////////////////////////////////////


const styles = theme => ({
    tableRow: {
        height: '42px' // This acts like minHeight when tableCell.
    },
    tableCell: {
        height: 'auto ! important',
        maxWidth: '10rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '6px 12px'
    },
    actionColumnCells: {
        padding: '0px 9px 0px 12px'
    },
    actionIconButton: {
        padding: '3px 0px'
    },
    emptyOverlay: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deleteOverlayText: {
        paddingLeft: '32px',
        fontSize: '20px'
    }
})


export default withStyles(styles)(CargoDataTableBody)
