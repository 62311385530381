import React, { useEffect, useState } from 'react'

/**
 * File input wrapper
 *
 * - id should be unique and usable by document.getElementById
 * - render is a function that passes a function which triggers the input window to open as argument.
 *      render function argument must be called within the returned React element
 *
 *  Example usage:
 *      <InputFile
 *      id="LoadImageInput"
 *      inputProps={{ accept: '.png,.jvg,.jpeg,.svg' }}
 *      onLoad={handleLogoLoad}
 *      render={openFileDialog =>
 *              <Button onClick={openFileDialog} >
 *                  Load file
 *              </Button>
 *              }
 *      />
 *
 * @param {id} identifier for the input element.
 * @param {render} function (fn) => React element
 * @param {onLoad} function (file) => void
 * @param {inputProps} props for the input element
 */
const InputFile = ({ id, render, onLoad, inputProps }) => {
    const [refToFileInput, setRefToFileInput] = useState(null)

    useEffect(() => {
        if (typeof onLoad !== 'function') {
            console.error("InputFile onLoad prop is required to be a function.")
        }
        if (!id) {
            console.error("InputFile id is required.")
        }
    }, [onLoad, id]) // Only once

    const loadFile = (file) => {
        if (typeof window.FileReader !== 'function') {
            alert("The file API isn't supported on this browser yet.");
            return;
        }

        let input = document.getElementById(id);
        if (!file) {
            if (!input) {
                alert("Um, couldn't find the fileinput element.");
            }
            else if (!input.files) {
                alert("This browser doesn't seem to support the 'files' property of file inputs.");
            }
            else if (!input.files[0]) {
                alert("Please select an Excel file before clicking 'Process'");
            }
        }

        const selectedFile = input.files[0]

        input.files = null // Without these the second upload try won't fire the onChange event
        input.value = ''

        if (typeof onLoad === 'function') {
            onLoad(selectedFile)
        }
    }

    return (
        <>
            {render(() => refToFileInput?.click())}
            <input
                type='file'
                id={id}
                style={{ display: 'none' }}
                {...inputProps}
                ref={elem => setRefToFileInput(elem)}
                onChange={() => loadFile()}
            />
        </>
    )
}

export default InputFile
