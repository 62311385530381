import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router'

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { loadingAssistantApi, solutions as solutionsApi } from '../../api';
import { showNotificationDialog } from '../../app/AppActions';
import { solutionPackingStatus } from '../Constants';
import { usePreviewThreer } from '../../three/SolutionPreviewThreer';

import InfoDialog, { infoTexts, Info } from '../../optimize/InfoDialog';
import LoadingAssistantTabletTableInfoDialog from './LoadingAssistantTabletLoadingPlanInfoDialog';
import RoundedButton from '../components/RoundedButton';
import useWindowSize from '../../core/useWindowSize';
import { colors } from '../../theme'
import { dateAndTimeToFormattedString } from '../../core/dateUtils'
import { notificationDialogSeverity } from '../../core/Constants';

import { isEqual } from 'lodash'



const LoadingAssistantTabletCompletedJobs = (props) => {

    const {
        classes,
        reduxDispatch
    } = props;


    const [state, setState] = useState({
        allLoadingAssistants: [], // Array of all available solutions
        completedJobs: [], // Array of loading assistants that have been completed already
        completedDeck: [], // Deck of cards made from completedJobs
        mounted: false, // Has the component mounted yet?
    });

    /**
     * State object that controls the pagination of this page.
     * Limit is how many items we want to show on the list of cards.
     * Total is the total number of completed jobs.
     */
    //TODO: Temporarily commented out the non-working pagination implementation. See issue #519
    // const [paginationState, setPaginationState] = useState({
    //     page: 1,
    //     total: 0,
    //     limit: 25,
    // })

    const [infoDialogState, setInfoDialogState] = useState({
        open: false,
        content: null
    });

    const [previewSolution, setPreviewSolution] = useState(null);

    const [forcedToCompletionInfoDialogText, setForcedToCompletionInfoDialogText] = useState(null);



    // Take state.allLoadingAssistants into a useRef for solutionsService patch listener
    const allLoadingAssistantsRef = useRef(null);
    allLoadingAssistantsRef.current = state.allLoadingAssistants;



    useEffect(() => {
        if (state.completedJobs == null) {
            return
        }

        let cargoSpaces = [];
        state.completedJobs.forEach(cj => {
            cargoSpaces.push(cj.cargoSpaces[cj.cargoSpaceIndex])
        })
        setPreviewSolution({cargoSpaces})
    }, [state.completedJobs])



    let previewImages = usePreviewThreer(previewSolution)


    /**
     * Set up the solutionsService emit listeners on the initial render.
     * Clear up listeners when component unmounts
     */
    useEffect(() => {
        setSolutionEventListeners();
        return () => {
            clearSolutionEventListeners();
        }
    }, [])



    /**
     * Fetch loading assistants on the initial render and find all completed loading jobs.
     * Fetch loading assistants based on the paginationState.page, and paginationState.limit
     */
    useEffect(() => {
        loadingAssistantApi.list({
            // $limit: paginationState.limit,
            // $skip: (paginationState.page - 1) * paginationState.limit
        })
            .then(res => {
                const allLoadingAssistants = res.data; // Get all solutions
                let completed = []
                allLoadingAssistants.forEach(sol => { // Map out all the completed jobs
                    if (sol.cargoSpaces[sol.cargoSpaceIndex].packingStatus === 3) {
                        completed.push(sol)
                    }
                })
                setPreviewSolution(null); // This empties previewImages, so old images aren't shown when changing pages.
                setState(s => ({
                    ...s,
                    mounted: true,
                    previewImages: [],
                    allLoadingAssistants: allLoadingAssistants,
                    completedJobs: completed
                }));
                // setPaginationState(s => ({ ...s, total: res.total + 1}))
            })
            .catch(err => {
                reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error)
            });
    // }, [reduxDispatch, paginationState.page, paginationState.limit]);
    }, [reduxDispatch]);

    /**
     * Make the cards for each completed loading job each time state.completedJobs changes
     */
    useEffect(() => {
        if (state.mounted && state.completedJobs?.length > 0) {

            let completedCards = [];

            state.completedJobs.forEach((sol, i) => {
                completedCards.push(
                    <Card
                        className={classes.card}
                        key={`${sol._id}, ${sol.cargoSpaceIndex}`}
                        elevation={4}
                        square={true}
                    >
                        <Box className={classes.cardContainer}>
                            {/* Header and completion date -container */}
                            <Box className={classes.cardHeaderContainer}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title={
                                        <Typography noWrap variant='h5'>
                                            {sol.name}
                                        </Typography>
                                    }
                                    // subheader={`Cargo Space: ${sol.cargoSpaceIndex + 1} / ${sol.cargoSpaces.length}`}
                                    subheader={
                                        <Typography variant='body1' className={classes.cardSubHeader}>
                                            {`Cargo Space: ${sol.cargoSpaceIndex + 1} / ${sol.cargoSpaces.length}`}
                                            <IconButton onClick={() => setInfoDialogState(s => ({ open: true, content: sol }))} style={{ marginLeft: 5 }}>
                                                <InfoOutlinedIcon />
                                            </IconButton>
                                        </Typography>
                                    }
                                />
                                <Typography variant='body1' className={classes.cardHeaderDateInfo}>
                                    {`${sol.cargoSpaces[sol.cargoSpaceIndex].loadingCompletedDate === null ? 'Unknown' : dateAndTimeToFormattedString(sol.cargoSpaces[sol.cargoSpaceIndex].loadingCompletedDate)}`}
                                </Typography>
                            </Box>
                            {/* Preview-image container */}
                            <Box className={classes.previewImageContainer}>
                                {previewImages[i] ?
                                    <img
                                        src={previewImages[i]}
                                        className={classes.previewImageStyle}
                                        alt={''}
                                    />
                                    :
                                    <CircularProgress
                                        disableShrink
                                        color='primary'
                                        style={{ alignSelf: 'center', marginRight: '110px' }}
                                    />
                                }
                            </Box>
                            {/* Inspect-button & completion-text */}
                            <CardActions className={classes.cardActions}>
                                {sol.cargoSpaces[sol.cargoSpaceIndex].forcedToCompletion &&
                                    <div className={classes.completionLabelContainer}>
                                        <Typography className={classes.forcedToCompletionWarningLabel}>
                                            FORCED TO COMPLETE
                                        </Typography>
                                        <Info
                                            info={infoTexts.forcedToCompletion}
                                            onClick={setForcedToCompletionInfoDialogText}
                                            style={{ color: colors.bbOrange }}
                                        />
                                    </div>
                                }
                                {sol.cargoSpaces[sol.cargoSpaceIndex].progress === 100 &&
                                    <div className={classes.completionLabelContainer}>
                                        <Typography className={classes.completedWarningLabel}>
                                            COMPLETED
                                        </Typography>
                                    </div>
                                }


                                <RoundedButton
                                    variant='contained'
                                    color='primary'
                                    onClick={() => browserHistory.push(`/loadingAssistant/loading/${sol._id}/${sol.cargoSpaceIndex}`)}
                                >
                                    inspect
                                    <ArrowForwardIosIcon />
                                </RoundedButton>
                            </CardActions>
                        </Box>
                    </Card>
                );
            });

            setState(s => ({ ...s, completedDeck: completedCards }))
        };
    }, [
        previewImages.length,
        previewImages,
        state.completedJobs,
        state.mounted,
        classes.card, classes.cardActions,
        classes.cardHeader,
        classes.cardHeaderContainer,
        classes.cardContainer,
        classes.cardHeaderDateInfo,
        classes.cardSubHeader,
        classes.forcedToCompletionWarningLabelContainer,
        classes.completedWarningLabel,
        classes.completionLabelContainer,
        classes.forcedToCompletionWarningLabel,
        classes.previewImageContainer,
        classes.previewImageStyle
    ]);



    const setSolutionEventListeners = () => {
        solutionsApi.service().on('patched', onSolutionsPatched.current);
        solutionsApi.service().on('removed', onSolutionsRemoved.current);
    };

    const clearSolutionEventListeners = () => {
        solutionsApi.service().removeListener('patched', onSolutionsPatched.current);
        solutionsApi.service().removeListener('removed', onSolutionsRemoved.current);
    }



    /**
     * Function that is called when ever a solutionsService patch emit is heard
     * Check which cargo space in solution was patched and then check if that patch was to set cargospace.packingstatus to 3 (completed)
     */
    const onSolutionsPatched = useRef(async function (patchedSolution) {

        if (patchedSolution === null || patchedSolution === undefined) {
            return;
        };


        // If organization doesn't have any solutions, then any time there's a solution patch emit, re-fetch loadingAssistants
        if (allLoadingAssistantsRef.current.length < 1) {
            loadingAssistantApi.list({})
                .then(res => {
                    const completed = res.data.forEach(sol => {
                        if (sol.cargoSpaces[sol.cargoSpaceIndex].packingStatus === solutionPackingStatus.packed) {
                            return sol
                        };
                    })
                    setState(s => ({ ...s, allLoadingAssistants: res.data, completedJobs: completed.reverse() }))
                })
                .catch(err => reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error))
            return;
        };


        // Find which cargo space was patched
        const oldSolution = allLoadingAssistantsRef.current.find(sol => { return sol._id === patchedSolution._id });



        // Find which cargo space in patchSolution was patched
        let patchedCargoSpaceIndex;
        for (let i = 0; i < patchedSolution.cargoSpaces.length; i++) {
            if (!isEqual(oldSolution.cargoSpaces[i], patchedSolution.cargoSpaces[i])) {
                patchedCargoSpaceIndex = i;
            };
        };


        // Patched cargo space was already finished
        if (oldSolution.cargoSpaces[patchedCargoSpaceIndex].packingStatus === solutionPackingStatus.packed) {
            return;
        }


        // Cargo space has been patched from unfinished to finished
        if (
            patchedSolution.cargoSpaces[patchedCargoSpaceIndex].packingStatus === solutionPackingStatus.packed &&
            oldSolution.cargoSpaces[patchedCargoSpaceIndex].packingStatus !== solutionPackingStatus.packed
        ) {
            loadingAssistantApi.list({})
                .then(res => {
                    const newCompleted = [];
                    res.data.forEach(sol => {
                        if (sol.cargoSpaces[sol.cargoSpaceIndex].packingStatus === solutionPackingStatus.packed) {
                            newCompleted.push(sol)
                        };
                    })
                    setState(s => ({ ...s, allLoadingAssistants: res.data, completedJobs: newCompleted.reverse() }))
                })
                .catch(err => reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error))
        }
    });


    /**
     * Function that is called when a remove-emit is heard from solutionsService
     * If a remove is heard, then re-fetch all loadingAssistants
     */
    const onSolutionsRemoved = useRef(async function (message) {
        // When solution is removed, re-fetch and re-set loadingAssistants
        loadingAssistantApi.list({})
            .then(res => {
                const newCompleted = res.data.forEach(sol => {
                    if (sol.cargoSpaces[sol.cargoSpaceIndex].packingStatus === 3) {
                        return sol
                    };
                })
                setState(s => ({ ...s, allLoadingAssistants: res.data, completedJobs: newCompleted.reverse() }))
            })
            .catch(err => reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error))
    })



    return (
        <div className={classes.tabletStyle}>
            <div>
                <LoadingAssistantTabletTableInfoDialog
                    infoDialogOpen={infoDialogState.open}
                    closeInfoDialog={() => setInfoDialogState(s => ({ ...s, open: false }))}
                    infoDialogContent={infoDialogState.content}
                />
                <InfoDialog
                    info={forcedToCompletionInfoDialogText}
                    onClose={() => setForcedToCompletionInfoDialogText(null)}
                />
            </div>
            <div>
                <div className={classes.deckHeader}>
                    <Typography variant='h5'>
                        Completed Loading Jobs:
                    </Typography>
                    {/* <Box className={classes.deckHeaderPagination}>
                        <TablePager
                            page={paginationState.page}
                            total={paginationState.total}
                            limit={paginationState.limit}
                            onChangePage={(page) => setPaginationState(s => ({ ...s, page: page }))}
                        />
                    </Box> */}
                </div>
                <Card className={classes.deckContainer} style={{ height: `calc(${useWindowSize()[1]}px - 160px)` }}>
                    {state.completedDeck.length > 0 ?
                        <List className={classes.cardList}>
                            {state.completedDeck.map((card, i) => {
                                return (
                                    <ListItem key={i}>
                                        {card}
                                    </ListItem>
                                )
                            })}
                        </List>
                        :
                        <CardContent className={classes.deckContainerEmptyMessage}>
                            <Typography variant='h5'>
                                No completed jobs
                            </Typography>
                        </CardContent>
                    }
                </Card>
            </div>
        </div>
    )



}



const mapStateToProps = (state) => ({
    reduxState: {

    }
});



const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            showMessage: (message, title, severity) => {
                dispatch(showNotificationDialog(message , title, severity));
            }
        }
    }
};



const styles = theme => ({
    tabletStyle: {
        background: colors.octagon,
        width: '100%',
        height: '100%',
        padding: '20px',
    },
    deckHeader: {
        width: '100%',
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deckHeaderPagination: {
        width: '20%',
        left: '40%', // Centers the component on the page
        position: 'absolute'
    },
    deckContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
    },
    deckContainerEmptyMessage: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardList: {
        overflow: 'auto'
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: '140px',
        overflow: 'hidden'
    },
    cardHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '33%'
    },
    cardHeaderDateInfo: {
        paddingLeft: '16px',
        paddingBottom: '16px',
    },
    cardHeader: {
        display: 'block',
        width: '100%'
    },
    cardSubHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: '-12px'
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: '400px'
    },
    completionLabelContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        columnGap: '10px'
    },
    completedWarningLabel: {
        color: colors.green,
        fontWeight: 'bold'
    },
    forcedToCompletionWarningLabel: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    previewImageContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '20%'
    },
    previewImageStyle: {
        height: '100%',
        width: 'auto',
    },
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoadingAssistantTabletCompletedJobs))
