import React, { useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//////////////////////////////////////////////////////////////////////////////

import FormTextField from './FormTextField';

//////////////////////////////////////////////////////////////////////////////

const PassWordTextField = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    return <FormTextField
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex={-1} // Skip the visibility toggling button from Tab selection
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            )
        }}
        type={showPassword ? 'text' : 'password'}
        {...props}
    />
}

//////////////////////////////////////////////////////////////////////////////

export default PassWordTextField
