import React, { useEffect } from 'react'

import Slide from '@material-ui/core/Slide';

const WIDTH = '16rem';
const Z_MAX = 16;

const OCTAGON_CORNER = 64;

const COLOR = '#ffffff';
const SHADOW = {
    color: '#404040',
    offset: 2,
    radius: 8,
};

export default (props) => {
    const w = props.w || WIDTH;
    const z = props.z || Z_MAX;
    const color = props.color || COLOR;
    const display = props.display || "inline-block"
    const contentDivStyle = props.contentDivStyle || {}

    const shadow = isDiagonal => `${SHADOW.offset}px ${isDiagonal ? -SHADOW.offset : 0}px ${SHADOW.radius}px ${SHADOW.color}`;

    const r = props.r || OCTAGON_CORNER;
    const sqrt2 = 1.41; // WTF AND PI IS = 3 ??

    let scroll = props.scroll;


    if (props.scroll !== true && props.scroll !== false && props.scroll !== undefined) { // Check that scroll prop is a Boolean or undefined
        console.warn('Octagon prop "scroll" only accepts a Boolean value')
    };

    if (scroll === undefined) { // If scroll prop is not defined, set scroll to true
        scroll = true;
    };

    useEffect(() => {
        if (scroll === true) {
            document.getElementById("main").scroll({ left: 1920 * 2, behavior: "smooth" });
        }
    }, [scroll]);


    return (
        <Slide direction="right" in={props.isOpen !== undefined ? props.isOpen : true} timeout={props.disableSlide ? 0 : 300} mountOnEnter unmountOnExit>
            <div style={{ height: '100%', display: display }}>
                {/* <div style={{ height: 'calc(100% - 4rem - 48px)', float: 'left', marginLeft: -r, marginRight: !props.child ? 2 * r : 0, position: 'relative', zIndex: z }}> */}
                <div style={{ height: '100%', float: 'left', marginLeft: -r, marginRight: 0, position: 'relative', zIndex: z }}>
                    <div>
                        <div style={{
                            backgroundColor: color,
                            float: 'left',
                            width: w,
                            height: `${r}px`,
                            position: 'relative',
                            boxShadow: shadow(false),
                        }}>
                            <div style={{
                                backgroundColor: color,
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                zIndex: 2,
                            }}>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: color,
                            float: 'left',
                            width: `${sqrt2 * r}px`,
                            height: `${sqrt2 * r}px`,
                            position: 'relative',
                            margin: `${(1 - 0.5 * sqrt2) * r}px ${-0.5 * sqrt2 * r}px`,
                            transform: 'rotate(45deg)',
                            boxShadow: shadow(true),
                            zIndex: 1,
                        }}>
                            <div style={{
                                backgroundColor: color,
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                zIndex: 1,
                            }}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: color,
                        width: `calc(${w} + ${r}px)`,
                        height: `calc(100% -  ${2 * r}px)`,
                        minHeight: `${2 * r}px`,
                        position: 'relative',
                        top: `${r}px`,
                        boxShadow: shadow(false),
                    }}>
                        <div style={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: 3,
                        }}>
                            <div style={{
                                marginLeft: 1.5 * r,
                                marginRight: r,
                                height: '100%',
                                ...contentDivStyle
                            }}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{
                            backgroundColor: color,
                            clear: 'left',
                            float: 'left',
                            width: w,
                            height: `${2 * r}px`,
                            position: 'relative',
                            boxShadow: shadow(false),
                        }}>
                            <div style={{
                                backgroundColor: color,
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                zIndex: 1,
                            }}>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: color,
                            float: 'left',
                            width: `${sqrt2 * r}px`,
                            height: `${sqrt2 * r}px`,
                            position: 'relative',
                            margin: `${(1 - 0.5 * sqrt2) * r}px ${-0.5 * sqrt2 * r}px`,
                            transform: 'rotate(45deg)',
                            boxShadow: shadow(true),
                        }}>
                        </div>
                    </div>
                </div>
                {/* If slider is being used and it has a child component, this closes it automatically */}
                {props.isOpen === undefined ? props.child : props.isOpen && props.child}
            </div>
        </Slide>
    )
}
