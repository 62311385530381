import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogTitle from "../../core/DialogTitle";

import { withStyles } from "@material-ui/core/styles";

//////////////////////////////////////////////////////////////////////////////

const ForceCompletionDialog = (props) => {

//////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle onClose={props.onClose}>
                {`Set loading job as completed?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Loading job is still in progress. Are you sure you want to set loading job as completed?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={props.onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({});

export default withStyles(styles)(ForceCompletionDialog);
