import React, { useState } from 'react'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';

import ActionLock from '@material-ui/icons/Lock'

const PasswordChange = (props) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const { onPasswordChange, classes } = props

  const submitHandler = event => {
    event.preventDefault();
    onPasswordChange(oldPassword, newPassword, passwordConfirmation)
      .then(() => {
        setOldPassword('')
        setNewPassword('')
        setPasswordConfirmation('')
      })
  }

  return (
    <List subheader={<ListSubheader>Password Change</ListSubheader>}>
      <form onSubmit={submitHandler}>
        <div className={classes.textFieldWrapper}>
          <TextField
            id='oldPassword'
            label='Old password'
            fullWidth={true}
            value={oldPassword}
            onChange={(event, value) => setOldPassword(event.target.value)}
            type='password'
          />
          <br />
          <TextField
            id='newPassword'
            label='New password'
            fullWidth={true}
            value={newPassword}
            onChange={(event, value) => setNewPassword(event.target.value)}
            type='password'
          />
          <br />
          <TextField
            id='passwordConfirmation'
            label='Password confirmation'
            fullWidth={true}
            value={passwordConfirmation}
            onChange={(event, value) => setPasswordConfirmation(event.target.value)}
            type='password'
          />
        </div>
        <div className={classes.bottomDivider} />
        <div className={classes.textFieldWrapper}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ActionLock />}
            onClick={submitHandler}>
            Change password
            </Button>
        </div>
        <div className={classes.bottomDivider} />
        <input type='submit' style={{ display: 'none' }} />
      </form>
    </List>
  )
}

const styles = theme => ({
  textFieldWrapper: theme.textFieldWrapper,
  bottomDivider: theme.app.bottomDivider
})

export default withStyles(styles)(PasswordChange)
