import React from 'react';

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/Error'

import DialogTitle from '../../core/DialogTitle'

import PropTypes from 'prop-types'

//////////////////////////////////////////////////////////////////////////////

export const LMCDistributeFailedDialog = (props) => {

//////////////////////////////////////////////////////////////////////////////

    return (
        <Dialog open={props.open} maxWidth="sm" fullWidth>
            <div style={{ display: "flex", alignItems: "center" }}>
                <ErrorIcon style={{ color: "red", height: "40px", width: "40px", marginRight: '4px', marginLeft: "16px" }} />
                <DialogTitle onClose={props.onClose} style={{ paddingLeft: '0px'}}>
                    Re-calculation failed
                </DialogTitle>
            </div>
            <DialogContent>
                <DialogContentText>
                    Something went wrong.
                    <br /><br />
                    The changes made to cargo items were not saved and loading plan remains unchanged.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={props.onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );

};

LMCDistributeFailedDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

//////////////////////////////////////////////////////////////////////////////

const styles = (theme) => ({
});


export default withStyles(styles)(LMCDistributeFailedDialog);
