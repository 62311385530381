import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from './DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

export default (props) => {
    const { backDialogOpen, onConfirmGoBack, onCloseBackDialog, noContent } = props
    let { title, text, confirmButtonText } = props
    if (!title) title = "Unsaved changes"
    if (!text) text = "All unsaved changes will be lost."
    if (!confirmButtonText) confirmButtonText = "OK"

    return (
        <Dialog open={backDialogOpen} onClose={onCloseBackDialog} >
            <DialogTitle onClose={onCloseBackDialog}>{title}</DialogTitle>
            <DialogContent>
                {!noContent &&
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onConfirmGoBack()}>{confirmButtonText}</Button>
            </DialogActions>
        </Dialog>
    )
}
