import React, { useEffect, useState } from 'react'

import { withStyles } from '@material-ui/core/styles'

import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'


const OrganizationFilterMenu = (props) => {

    const {
        classes,
    } = props;


    /**
     * State for the checkboxes.
     * Each organization has an object {organization: organization, checked: true/false}.
     * This state is very important in keeping track which organizations have been checked in the menu.
     */
    const [checkboxState, setCheckboxState] = useState([]);



    /**
     * Maps organizations to checkboxStates
     */
    useEffect(() => {
        let checkedObj = [];
        props.organizations.forEach(org => checkedObj.push({'organization': org, 'checked': false}))
        setCheckboxState(checkedObj)
    }, [props.organizations])



    const handleMenuClosing = (event) => {
        if (props.anchorRef.current && props.anchorRef.current.contains(event.target)) {
            return;
        }
        props.setOpen(false);
    };



    /**
     * Event handler for each checkbox in the menu.
     * @param {Object} event
     */
    const handleCheck = (event) => {
        const checkObj = checkboxState.find(obj => obj.organization._id === event.target.name);
        checkObj.checked = !(checkObj.checked);
        setCheckboxState([...checkboxState])
        const checkedOrgs = checkboxState.filter(obj => obj.checked).map(n => n.organization);
        props.setOrgFilterList(checkedOrgs);
    }



    return (
        <Popper
            className={classes.popper}
            open={props.open}
            anchorEl={props.anchorRef.current}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                    <Paper className={classes.menuPaper}>
                        <ClickAwayListener onClickAway={handleMenuClosing}>
                            <MenuList className={classes.list} autoFocusItem={props.open} id='menu-list'>
                                {checkboxState.map((obj, i) => {
                                    return (
                                        <FormControlLabel
                                            className={classes.controlLabel}
                                            key={i}
                                            control={
                                                <Checkbox
                                                    checked={checkboxState[i].checked} // Checked organizations are kept checked even if user closes the menu
                                                    name={obj.organization._id}
                                                    onChange={handleCheck}
                                                />
                                            }
                                            label={obj.organization.name}
                                        />
                                    )
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
};



const styles = theme => ({
    popper: {
        zIndex: 3
    },
    controlLabel: {
        color: 'black'
    },
    list: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuPaper: {
        padding: 10
    }
})


export default withStyles(styles)(OrganizationFilterMenu)
