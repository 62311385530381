import React, { useState, useRef } from 'react';

import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import TabSelectionMenuItem from '../../../core/TabSelectionMenuItem'
import CounterButtonGroup from '../../../core/CounterButtonGroup'
import { validateParcelInput, toUint, toNumber } from '../../../core/Validations'
import { stackabilityLookup, lashingLookup } from '../../../core/Constants';

import uniqueId from 'lodash/uniqueId'


//////////////////////////////////////////////////////////////////////////////


const mabLabelToName = label => {
    if (label === "Length") return 'x'
    if (label === "Width") return 'y'
    if (label === "Height") return 'z'
    return label.toString().toLowerCase()
}


//////////////////////////////////////////////////////////////////////////////


const CargoDataTableParcelInput = (props) => {

    const { classes } = props

    const defaultParcel = {
        id: '',
        dimensions: {},
        weight: '',
        description: '',
        quantity: 1,
        tableData: { id: uniqueId() },
        stackability: Object.keys(stackabilityLookup)[0],
        lashing: lashingLookup.No
    }

    const [parcel, setParcel] = useState(Object.assign({}, defaultParcel));
    const [dimensions, setDimensions] = useState({ x: '', y: '', z: '' });
    const [errors, setErrors] = useState([])


    // Ref for the id-field. Focus will be automatically set to this field when user enters the page.
    const idFieldRef = useRef(null);



    const updateField = e => {
        setParcel({
            ...parcel,
            [e.target.name]: e.target.value
        })
    }


    const updateWeight = e => {
        const value = e.target.value;
        setParcel({
            ...parcel,
            [e.target.name]: value
        })
    }


    const updateDimensions = e => {
        const value = e.target.value;
        setDimensions({
            ...dimensions,
            [e.target.name]: value
        })
        const dims = {
            ...dimensions,
            [e.target.name]: value
        }
        setParcel({
            ...parcel,
            dimensions: dims
        })
    }


    const setStackingRule = value => {
        let newParcel = Object.assign({}, parcel)
        newParcel.stackability = value
        setParcel(newParcel)
    }


    const setLashingRule = value => {
        let newParcel = Object.assign({}, parcel)
        newParcel.lashing = value
        setParcel(newParcel)
    }


    const clearInput = () => {
        setParcel(defaultParcel)
        setDimensions({ x: '', y: '', z: '' })
        if (errors) setErrors([])
    }


    const handleAdd = () => {
        const validationErrors = validateParcelInput(parcel, dimensions, props.parcels, true, props.cargoDataUnit.value)
        if (validationErrors.length) {
            setErrors(validationErrors)
            return
        }
        parcel.weight = toUint(parcel.weight) || 0                  // These are basically for stripping the input from extra 0's
        parcel.dimensions.x = toNumber(parcel.dimensions.x) * props.cargoDataUnit.value
        parcel.dimensions.y = toNumber(parcel.dimensions.y) * props.cargoDataUnit.value
        parcel.dimensions.z = toNumber(parcel.dimensions.z) * props.cargoDataUnit.value
        parcel.tableData = { id: uniqueId() }

        const newParcels = props.parcels.concat(parcel)
        idFieldRef.current.focus()
        props.checkUnpackableParcels(newParcels) // Uses setData internally
        setErrors([])
        clearInput()
    };


    const handleKeyDown = e => {
        if (e.key === 'Enter') {

            // This prevents adding new parcels with Enter press when user is in selectable textField (Stackability or Reserve Fullwidth)
            if (Object.keys(e.target).length === 2) {
                return
            }

            handleAdd()
        }
    }


    const textF = (label, unit, value, onChange, required, w = '16%', inputRef = null, autoFocus = false, numeric = false) => (
        <TextField
            className={classes.numberTextFieldStyle}
            label={label + (unit ? ` (${unit})` : '')}
            disabled={props.disabled || props.isLoading}
            value={value}
            key={parcel.id + label}
            id={label}
            name={mabLabelToName(label)}
            onChange={onChange}
            size="small"
            margin="dense"
            style={{ width: w }}
            inputRef={inputRef}
            autoFocus={autoFocus}
            required={required}
            InputLabelProps={{
                shrink: true,
            }}
            type={
                numeric ? 'number' : ''
            }
            error={Boolean(errors.find(x => x.field === label))}
            helperText={Boolean(errors.find(x => x.field === label)) && errors.find(x => x.field === label).message}
        />
    )


    const onLashingChange = e => {
        setLashingRule(e.target.value)
    }


//////////////////////////////////////////////////////////////////////////////


    return (
        <div className={classes.textFieldWrapper}>
            <form
                onSubmit={event => event.preventDefault()}
                onKeyDown={handleKeyDown}
                className={classes.noTopMargins}
            >
                <fieldset
                    disabled={props.disabled}
                    className={classes.fieldset}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: 'space-between'
                        }}
                    >
                        {textF("Id", '', parcel.id, updateField, true, "25%", idFieldRef, true)}
                        {textF("Length", props.cargoDataUnit.text, dimensions.x, updateDimensions, true, '16%', null, false, true)}
                        {textF("Width", props.cargoDataUnit.text, dimensions.y, updateDimensions, true, '16%', null, false, true)}
                        {textF("Height", props.cargoDataUnit.text, dimensions.z, updateDimensions, true, '16%', null, false, true)}
                        {textF("Weight", "kg", parcel.weight, updateWeight, false, '16%', null, false, true)}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: 'space-between'
                        }}
                    >
                        <CounterButtonGroup
                            disabled={props.disabled}
                            count={parcel.quantity}
                            onChange={newValue => setParcel({ ...parcel, quantity: newValue })}
                            containerStyle={{ width: "8rem" }}
                            textFieldStyle={{ width: "3rem" }}
                        />
                        <TextField
                            disabled={props.disabled || props.isLoading}
                            label="Stackability"
                            select
                            value={parcel.stackability}
                            style={{ width: "10rem" }}
                            onChange={(e) => setStackingRule(e.target.value)}
                        >
                            {Object.keys(stackabilityLookup).map((key, i) => (
                                <TabSelectionMenuItem
                                    key={key}
                                    value={key}
                                >
                                    {stackabilityLookup[key]}
                                </TabSelectionMenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Reserve Fullwidth"
                            select
                            value={parcel.lashing}
                            disabled={props.disabled || props.isLoading}
                            style={{ width: "10rem" }}
                            onChange={onLashingChange}
                        >
                            {Object.keys(lashingLookup).map((key, i) => (
                                <TabSelectionMenuItem
                                    key={key}
                                    value={key}
                                >
                                    {lashingLookup[key]}
                                </TabSelectionMenuItem>
                            ))}
                        </TextField>
                        {textF("Description", "", parcel.description, updateField, false, "25%")}
                    </div>
                </fieldset>
            </form>
            <div className={classes.addButton}>
                <Tooltip title="Add Cargo Data">
                    <span>
                        <Fab
                            color="secondary"
                            disabled={props.disabled}
                            onClick={handleAdd}
                        >
                            <AddIcon />
                        </ Fab>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}


//////////////////////////////////////////////////////////////////////////////


const styles = theme => {
    return {
        textFieldWrapper: {
            display: 'flex',
            padding: '1rem'
        },
        raisedButton: theme.raisedButton,
        errorTextStyle: theme.errorTextStyle,
        TextFieldDuplicate: {
            margin: "8px 4.9px 4px 4.9px",
            height: "48px",
            width: "6rem",
            display: "inline"
        },
        addButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "8rem"
        },
        noTopMargins: {
            marginTop: "-12px",
            marginBottom: "0px",
            width: "100%"
        },
        fieldset: theme.fieldset,
        numberTextFieldStyle: theme.numberTextField
    }
}


export default withStyles(styles)(CargoDataTableParcelInput)
