import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import LaunchIcon from '@material-ui/icons/Launch'

//////////////////////////////////////////////////////////////////////////////

import DialogTitle from '../core/DialogTitle'

//////////////////////////////////////////////////////////////////////////////

/**
 * ModalActionButton wrapper - has default color and icon to be used as one of the FormResultDialog actions
 * @param {any} props - Props passed to Button
 * @returns
 */
export const ModalActionButton = props => {
    return <Button color="primary" endIcon={<LaunchIcon />} {...props} />
}

/**
 *
 * @param {Boolean} open - required
 * @param {function() : void} onClose - required
 * @param {String} title - dialog title
 * @param {String | HTML | React component} content - dialog content
 * @param {...any} actions - dialog actions
 * @returns
 */
const FormResultDialog = ({ open, onClose, title, content, actions }) => {
    return (
        <Dialog open={open} onClose={onClose} >
            <DialogTitle onClose={onClose} >{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    )
}

export default FormResultDialog

//////////////////////////////////////////////////////////////////////////////
