import React, { useState, useEffect, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Octagon from '../../core/Octagon'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import SaveIcon from '@material-ui/icons/Save';
import GetAppIcon from '@material-ui/icons/GetApp';

import { jobStatus, planStates, sendModes, notificationDialogSeverity } from '../../core/Constants'

import CargoSpaceDetails from './CargoSpaceDetailsOctagon/CargoSpaceDetails';
import NextPageButton from '../components/NextPageButton';
import OptimizerCargoSpacesTableWrapper from './OptimizerCargoSpacesTableWrapper';
import OptimizerHeader from '../OptimizerHeader'
import PdfExportModal from '../../pdf/PdfExportModal';
import SaveSolutionDialog from './SaveSolutionDialog';

import { colors } from '../../theme'

import { connect } from 'react-redux'
import { showNotification, showNotificationDialog } from '../../app/AppActions'
import { setSelectedParcel, setCalculated } from '../OptimizeActions'
import { setSolutions } from '../../solutions/SolutionActions'

import FeatureEnabler from '../../app/FeatureEnabler';
import { optimize as optimizeApi, solutions, users, checkAuth } from '../../api'


//////////////////////////////////////////////////////////////////////////////


const OptimizerOptimizeView = (props) => {

    const [exportDialog, setExportDialog] = useState({ open: false, cargoSpaceIndexes: [] });
    const [progressStatus, setProgressStatus] = useState({ progress: 0, solvedStorageCount: 0 });
    const [queueStatus, setQueueStatus] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [selectedCargo, setSelectedCargo] = useState(0)
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    let onOptimizerUpdated = useRef(null);
    let onOptimizerError = useRef(null);

    const {
        parcels,
        deletedParcels,
        storages,
        solutions,
        solution,
        setSolution,
        setUrlId,
        autoSave,
        setAutoSave,
        unsaved,
        setUnsaved,
        calculated,
        setName,
        name,
        rules,
        selectedParcel,
        warnAboutUnsaved,
        cargoDataUnit,
        cargoSpaceUnit,
        onInitRender,
        unpackableParcels,
        setInfoDialog,
        disableChanges,
        setDisableChanges,
        classes,
        fetching,
        sendMode,
        setSendMode,
        showCalculationOverlay,
        setShowCalculationOverlay,
        cancel,
        newJobId,
        setNewJobId,
        planState
    } = props

    const {
        onShowNotificationDialog,
        onShowNotification,
        onSetSelectedParcel,
        onSaveSolution,
        onFetchSolutions,
        onSetCalculated
    } = props.reduxDispatch;



    /**
     * On the initial render of OptimizerOptimizeView:
     * 1. Check unpackable parcels for current loading plan.
     * 2. Fetch all solutions.
     * 3. Set sendMode depending on if loading plan is in calculation or not.
     *
     * On unload clear validationTimeout
     */
    useEffect(() => {
        onInitRender()
        onFetchSolutions();
        if (props.newJobId && props.waitLoop) {
            setSendMode(sendModes.active)
        } else {
            setSendMode(sendModes.ready)
        }

    // Disable exhaustive-deps becuase we want to run this hook only once on the initial render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
     * Show calculation overlay and disable changes into the loading depending on which state the calculation is in.
     */
    useEffect(() => {
        if (sendMode === sendModes.active) { // active means that the calculation is in progress
            setShowCalculationOverlay(true);
            setDisableChanges(true)
        } else {
            setShowCalculationOverlay(false);
            setDisableChanges(false)
        }

    // Disable exhaustive-deps becuase adding setShowCalculationOverlay() and setDisableChanges() in dependencies will result in infinite loop here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMode])



    const setOptimizerEventCallbacks = id => {
        // Create a reference to callback functions
        onOptimizerUpdated = newJobListener(id);
        onOptimizerError = onError;
        // Add the callbacks to events
        optimizeApi.service().on('updated', onOptimizerUpdated)
        optimizeApi.service().on('error', onOptimizerError)
    }

    const clearOptimizerEventCallbacks = () => {
        // Remove events callback references
        optimizeApi.service().removeListener('updated', onOptimizerUpdated)
        optimizeApi.service().removeListener('error', onOptimizerError)
        // Clear callback references
        onOptimizerUpdated = null;
        onOptimizerError = null;
    }

    const onError = (err) => {
        onShowNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)
        setSendMode(sendModes.error);
        clearOptimizerEventCallbacks();
    }

    const clearInfoChild = () => {
        setShowDetails(false)
        if (selectedParcel) onSetSelectedParcel(null)
    }

    const stopWithError = message => {
        if (queueStatus) setQueueStatus(null)
        setSendMode(sendModes.error)
        onShowNotificationDialog(message, 'Error', notificationDialogSeverity.error)
        setNewJobId(null)
        clearOptimizerEventCallbacks()
    }

    const newJobListener = (newJobId) => {
        return function handler(res) {
            setProgressStatus({ progress: res.progress, solvedStorageCount: res.solvedStorageCount });


            const responseId = res.id;
            if (newJobId !== responseId) {
                onShowNotificationDialog(`JobId doesn't match with the invoked id result. JobId: ${newJobId}, result Id: ${responseId}`, "Ids don't match", notificationDialogSeverity.error)
                return;
            }

            switch (res.jobStatus) {
                case jobStatus.canceled:
                    setNewJobId(null);
                    if (queueStatus) setQueueStatus(null);
                    clearOptimizerEventCallbacks();
                    return;
                case jobStatus.failed:
                    stopWithError(res.errorMessage);
                    onSetCalculated(true); // If calculation fails, set calculated to true, so that user can still save whatever solution has previously completed correctly
                    return;
                case jobStatus.created:
                    setQueueStatus(res.queueStatus);
                    return;
                case jobStatus.started:
                    setQueueStatus(null);
                    return;
                case jobStatus.completed:
                    clearOptimizerEventCallbacks();
                    setProgressStatus({ progress: 0, solvedStorageCount: 0 })
                    fetchAndParseResult(res, stopWithError)
                    onSetCalculated(true); // Once optimization has finished, set calculated to true.
                    break;
                default:
                    onShowNotificationDialog(`Received a job with unknown jobStatus: ${jobStatus}`, 'Unknown job status', notificationDialogSeverity.error)
                    break;
            }
        }
    }

    const fetchAndParseResult = (res, stopWithError) => {
        if (res.result && !res.result.cargoSpaces.length) {
            return stopWithError("Cargo space cannot fit any parcel, please check input")
        }

        setNewJobId(null)
        if (selectedParcel) onSetSelectedParcel(null)

        const addStorageDetails = (resStorages) => {
            resStorages.forEach(storage => {

                let viewModelStorage = storages.find(s => s.id === storage.id);
                // Preset name is set to 'null' when storage properties are changed, so this ~works
                // Allthough when all properties are equal to some preset, the name is still null
                storage.presetName = viewModelStorage.presetName;
                storage.visualAids = viewModelStorage.visualAids;

                // resStorage has negativeSpaces but DON'T have color's / wireframes
                // So the color/wireframe need to be mapped just just copied
                storage.negativeSpaces = viewModelStorage.negativeSpaces;
            })
            return resStorages
        }

        let resSolutions = {
            name: name,
            solutionId: res.result.cargoSpaces[0].solutionId,
            version: res.result.cargoSpaces[0].version,
            notPackedParcels: res.result.notPackedParcels,
            notPackedWeight: res.result.notPackedWeight,
            notPackedVolume: res.result.notPackedVolume,
            totalPackedVolume: res.result.totalPackedVolume,
            totalPackedWeight: res.result.totalPackedWeight,
            totalUsedLength: res.result.totalUsedLength,
            viewModel: {
                parcels: parcels,
                deletedParcels: deletedParcels,
                units: {
                    parcels: cargoDataUnit,
                    cargoSpace: cargoSpaceUnit
                },
                storages: storages,
                rules: rules
            },
            cargoSpaces: res.result.cargoSpaces.map(r => {

                if (r.alternateArrangements.length > 1) {
                    throw new RangeError("Boxbot doesn't support cargo spaces that have more than 1 alternate arrangement! Arrangements are searched by arrangementId using core/constants.packingOptimizationGoal. This will break if cargo space has more than 1 alternate arrangment.")
                }

                return {
                    assignments: [],
                    rules: r.rules,
                    storages: addStorageDetails(r.storages),
                    packedParcels: r.packedParcels,
                    packedVolume: r.packedVolume,
                    packedWeight: r.packedWeight,
                    usedLength: r.usedLength,
                    cargoSpaceId: r.cargoSpaceId,
                    forcedToCompletion: false,
                    loadingCompletedDate: null,
                    maxAvailableCount: r.maxAvailableCount || 0,
                    progress: 0,
                    alternateArrangements: r.alternateArrangements,
                    arrangementId: r.arrangementId,
                    selectedArrangement: r.selectedArrangement || 0,
                    packingOptimizationGoal: r.packingOptimizationGoal
                }
            }),
        }

        const setSolutionState = (sol) => {
            setSolution(sol)
            setSendMode(sendModes.ready)
            if (selectedCargo !== 0) setSelectedCargo(0)
            if (showDetails) setShowDetails(false)
            if (queueStatus) setQueueStatus(null)
        }

        // If autosave is on, save the calculated solution immediately
        if (autoSave) {
            onSaveSolution(resSolutions)
                .then(saveResult => {
                    if (saveResult) { // If solution was saved succesfully
                        onShowNotification(`Done! New Loading Plan '${resSolutions.name}' created`) // Show snackbar notification
                        resSolutions._id = saveResult
                        setSolutionState(resSolutions)
                        setAutoSave(false)
                        setUrlId(resSolutions)
                    } else { // Save result came back empty for some reason
                        setSendMode(sendModes.error) // Set sendMode to error
                    }
                })
                .catch(err => { // Save was unsuccesful
                    setSolutionState(resSolutions)
                    setAutoSave(false);
                    setUnsaved(true);
                })
        } else { // No autosave
            setSolutionState(resSolutions)
            setUnsaved(true);
        }

        if (resSolutions.notPackedParcels.length > 0) {
            onShowNotificationDialog(`${resSolutions.notPackedParcels.length} parcels not packed`, 'Warning', notificationDialogSeverity.warning)
        }

        onFetchSolutions();
    }


    /**
     * Take an array of parcels and separate parcels into separate parcels.
     * If we have a parcel that has quantity of n, then we separate this one parcel into
     * n separate parcels.
     * @param {Array} parcels
     */
    const createSingletonParcels = (parcels) => {

        // Get ids for all parcels. Filter out parcels with quantity of 0.
        // NOTE: A parcel should never have quantity of 0, so this filter might be useless.
        const parcelIds = parcels.filter(p => p.quantity > 0).map(p => p.id);

        // Go through all parcels, if parcel has quantity > 1, then separate parcel into multiple parcels.
        const singletonParcels = [].concat.apply([], parcels.map(p => {

            if (p.quantity === 1)
                return p

                return [...Array(p.quantity).keys()].map(x => {
                let parc = Object.assign({}, p)
                let newId = `${p.id}_`
                while (true) {
                    if (!parcelIds.includes(`${newId}${x + 1}`)) {
                        newId = `${newId}${x + 1}`
                        break
                    }
                    newId += "_"
                }
                parcelIds.push(newId)
                parc.id = newId
                delete parc.quantity
                return parc
            })
        }))

        return singletonParcels;
    }


    /**
     * Send solution to Service Backend for optimization.
     */
    const optimize = () => {

        // Separate parcels into single parcels. If parcel has quantity n, then we return n separate parcels.
        const singletonParcels = createSingletonParcels(parcels);

        // Create the object we'll send to Service Backend.
        let newJobObject = {
            cargoSpaces: [{
                rules: {
                    ...rules,
                    lmcStrategy: 'None',
                },
                storages: storages.map(storage => ({
                    ...storage,
                    location: { x: 0, y: 0, z: 0 },
                })),
                packingOptimizationGoal: 'None'
            }],
            notPackedParcels: singletonParcels
        };

        /**
         * Check that user is logged in with a valid access token, and then send newJobObject
         * for optimization.
         * Re-authenticating user will reset users accessToken, which resets users accessToken
         * expiration date.
         */
        checkAuth(() => {

            users.authenticate()

            if (showDetails) setShowDetails(false)
            if (queueStatus) setQueueStatus(null)
            if (selectedCargo !== 0) setSelectedCargo(0)

            setSendMode(sendModes.active)
            optimizeApi.create(newJobObject)
                .then(res => {
                    if (res.errorMessage || res.errorCode) {
                        let message = res.errorMessage || 'Unknown error - no error message received';
                        setSendMode(sendModes.error)
                        onShowNotificationDialog(message, 'Error', notificationDialogSeverity.error)
                        return;
                    }

                    if (!res.id) {
                        setSendMode(sendModes.error)
                        onShowNotificationDialog("Unknown error when starting a new optimize job - no job id received", 'No job id received', notificationDialogSeverity.error)
                    }

                    if (res.queueStatus) {
                        setQueueStatus(res.queueStatus)
                    }

                    setNewJobId(res.id)
                    // Bind event listeners
                    setOptimizerEventCallbacks(res.id)
                })
                .catch(err => {
                    onShowNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)
                    setSendMode(sendModes.error)
                });
        });
    };


    const buttonAndIndicator = () => {
        let text;
        if (disableChanges && planState !== planStates.loading) {
            text = (
                <div className={classes.indicatorTextStyle}>
                    {'Calculating loading plan. Editing loading plan disabled.'}
                </div>
            )
        } else if (planState === planStates.loading) {
            text = (
                <div className={classes.indicatorTextStyle}>
                    {'Loading plan sent to Loading Assistant. Editing loading plan disabled.'}
                </div>
            )
        }

        const active = sendMode === sendModes.active;
        let btnText = "Save";
        let tooltipText = "Save a new loading plan, or overwrite an existing one";
        if (!solution || (!calculated && unsaved)) {
            tooltipText = "Optimize loading plan first"
        } else if (solution._id && !unsaved) {
            tooltipText = "Save a duplicate loading plan, or overwrite an existing one";
            btnText = 'Save as'
        }

        const saveButton = (
            <Tooltip title={tooltipText}>
                <span>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!solution || autoSave || (!calculated && unsaved) || (disableChanges && planState !== planStates.loading)}
                        startIcon={<SaveIcon />}
                        onClick={() => setSaveDialogOpen(true)}
                    >
                        {btnText}
                    </Button>
                </span>
            </Tooltip>
        )

        // Export PDF (all) -button
        // When user presses button, opens modal that has some options for different PDF-exports
        const pdfExportButton = (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<GetAppIcon />}
                disabled={!solution || autoSave || (!calculated && unsaved) || (disableChanges && planState !== planStates.loading)} // Keep button disabled the same way as the 'Save As'-button
                onClick={() => setExportDialog({
                    open: true,
                    cargoSpaceIndexes: [...Array(solution.cargoSpaces.length).keys()]
                })}
            >
                Export pdf (all)
            </Button>
        )

        return (
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                {solution &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.raisedButton}
                        disabled={active || disableChanges}
                        onClick={() => {
                            if (!solution._id && calculated) {
                                warnAboutUnsaved({ open: true, callback: optimize })
                                return
                            }
                            optimize();
                        }}
                    >
                        recalculate
                    </Button>
                }
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} >
                    {text}
                    <div style={{ display: 'flex', marginLeft: 'auto' }}>
                        {pdfExportButton}
                        {saveButton}
                    </div>
                </div>
            </div>
        );
    };

    const detailView = showDetails && <CargoSpaceDetails solution={solution} index={selectedCargo} name={name} onClose={() => clearInfoChild()} />

    /**
     * Event handler that is called when either a new solution is saved, or an existing solution is overwritten.
     * This function updates the necessary redux-states so that the correct information is displayed on the
     * Optimize & Inspect -page. Also, navigate user to the newly saved / overwritten solution.
     * @param {Object} sol
     */
    const onSolutionSaved = (sol) => {
        setSolution(sol);
        setName(sol.name);
        setUnsaved(false);
        setUrlId(sol);
        setSaveDialogOpen(false);
    }


//////////////////////////////////////////////////////////////////////////////


    return (
        <Octagon w='80rem' color={colors.octagon} child={detailView} isOpen={props.octagonOpen}>
            <div className={classes.octagonContainer}>

                <OptimizerHeader progress={props.progress} />

                <div className={classes.viewContainer}>
                    {buttonAndIndicator()}
                    <OptimizerCargoSpacesTableWrapper
                        selectedParcel={selectedParcel}
                        queueStatus={queueStatus}
                        progressStatus={progressStatus}
                        solution={solution}
                        showCalculationOverlay={showCalculationOverlay}
                        optimize={optimize}
                        cancel={cancel}
                        isActive={sendMode === sendModes.active}
                        unpackableParcels={unpackableParcels}
                        setInfoDialog={setInfoDialog}
                        selectedCargo={selectedCargo}
                        setSelectedCargo={setSelectedCargo}
                        clearInfoChild={clearInfoChild}
                        openInfoChild={() => setShowDetails(true)}
                        fetching={fetching} // Passed on from Optimize
                        jobId={newJobId}
                        setUnsaved={(value) => setUnsaved(value)}
                        disableChanges={disableChanges}
                    />
                </div>

                <FeatureEnabler featureId='loadingAssistant'>
                    <NextPageButton
                        tooltipText={!calculated && unsaved ? 'Loading plan has to be calculated and saved before proceeding' : 'Loading plan has to be saved before proceeding'}
                        nextButtonProps={props.nextButtonProps}
                    >
                        Send to loading
                    </NextPageButton>
                </FeatureEnabler>

                {/* Save solution dialog */}
                <SaveSolutionDialog
                    open={saveDialogOpen}
                    onClose={() => setSaveDialogOpen(false)}
                    solution={solution}
                    allSolutions={solutions}
                    disableChanges={disableChanges}
                    onSolutionSaved={onSolutionSaved}
                    planState={planState}
                />

                {/* PDF Export dialog */}
                <PdfExportModal
                    open={exportDialog.open}
                    cargoSpaceIndexes={exportDialog.cargoSpaceIndexes}
                    onClose={() => setExportDialog({ open: false, cargoSpaceIndexes: [] })}
                    solution={solution}
                />
            </div>
        </Octagon >
    )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        solutions: state.solutions.data,
        selectedParcel: state.optimizer.selectedParcel,
        axles: state.optimizer.axles,
        cargoSpaceType: state.optimizer.cargoSpaceType,
        waitLoop: state.optimizer.waitLoop,
        cargoDataUnit: state.unit.cargoData,
        cargoSpaceUnit: state.unit.cargoSpace,
        unsaved: state.optimizer.unsaved,
        calculated: state.optimizer.calculated,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduxDispatch: {
            onShowNotificationDialog:(message, title, severity) => {
                dispatch(showNotificationDialog(message, title, severity));
            },
            onShowNotification: notification => {
                dispatch(showNotification(notification));
            },
            onSetSelectedParcel: parcel => {
                dispatch(setSelectedParcel(parcel));
            },
            onSetCalculated: value => {
                dispatch(setCalculated(value));
            },
            onSaveSolution: solution => {
                return new Promise((resolve, reject) => {
                    solutions.create(solution)
                        .then(res => {
                            if (!res) {
                                resolve(null);
                            } else {
                                dispatch(showNotification(`Loading plan saved as ${solution.name}`))
                                resolve(res._id);
                                solutions.list()
                                    .then(res => dispatch(setSolutions(res)))
                                    .catch(err => dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error)))
                            }
                        })
                        .catch(err => {
                            dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                            reject(err.message);
                        })
                })
            },
            onFetchSolutions: () => {
                return solutions.list()
                    .then(res => {
                        dispatch(setSolutions(res))
                        return res
                    })
                    .catch(err => {
                        dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error));
                        throw err
                    })
            },
        }
    }
}

const styles = theme => ({
    octagonContainer: theme.containers.octagonChild,
    viewContainer: theme.optimizer.viewContainer,
    raisedButton: theme.raisedButton,
    textStyle: theme.textStyle,
    errorTextStyle: theme.errorTextStyle,
    statusTextStyle: { display: 'inline-block', verticalAlign: 'middle' },
    navigateButton: { padding: "0px", margin: "0px" },
    savePlanDialog: { minHeight: 240, maxHeight: 240 },
    savePlanInLoadingDialog: { minHeight: 350, maxHeight: 350 },
    apiCallProgress: { marginRight: 10, marginBottom: -8 },

    // Styling for the modal 'window'
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.common.white,
        boxShadow: 24,
        p: 4,
        alignItems: 'center',
    },

    // Styling for the indicator text that is shown when solution is in calculation, or has been sent to loading.
    indicatorTextStyle: {
        width: '100%',
        height: '40px',
        lineHeight: '48px',
        fontSize: "1.17em",
        fontWeight: "bold",
        textAlign: 'center'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OptimizerOptimizeView))
