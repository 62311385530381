import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/';

import GeneralDataTable from './GeneralDataTable'

//////////////////////////////////////////////////////////////////////////////

const MultiSelectTable = (props) => {
    const { classes } = props;

//////////////////////////////////////////////////////////////////////////////

    return (
        <Paper className={classes.paper} elevation={4}>
            <Toolbar
                className={classes.toolbar}
                style={{
                    backgroundColor: props.selectedRows?.length > 0 ? 'rgba(52, 139, 162, 0.2)' : ''
                }}
            >
                <Collapse in={props.selectedRows?.length > 0} className={classes.collapse}>
                    <Box className={classes.toolbarContent}>
                        <Typography variant='subtitle1'>
                            {props.selectedRows?.length} selected
                        </Typography>
                        {props.children}
                    </Box>
                </Collapse>
            </Toolbar>

            <Divider/>

            <GeneralDataTable
                columns={props.columns}
                columnSortingOptions={props.columnSortingOptions}
                data={props.data}
                dense={props.dense}
                onChangeColumnSortingOptions={props.onChangeColumnSortingOptions}
                onChangePagination={props.onChangePagination}
                onClickRow={props.onClickRow}
                updateSelectedRows={props.updateSelectedRows}
                paginationState={props.paginationState}
                selectedRows={props.selectedRows}
            />
        </Paper>
    );
};

//////////////////////////////////////////////////////////////////////////////

MultiSelectTable.propTypes = {
    selectedRows: PropTypes.array.isRequired
}

const styles = (theme) => ({
    toolbar: {
        padding: '0px 32px'
    },
    collapse: {
        width: '100%'
    },
    toolbarContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paper: theme.containers.dataTable
});


export default withStyles(styles)(MultiSelectTable);
