import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer';
import { withStyles } from '@material-ui/core'

import CargoDataTableHeader from './CargoDataTableHeader'
import CargoDataTableBody from './CargoDataTableBody'
import CargoDataTableParcelInput from './CargoDataTableParcelInput';


//////////////////////////////////////////////////////////////////////////////


const CargoDataTable = (props) => {

    const { classes } = props

//////////////////////////////////////////////////////////////////////////////


    return (
        <Paper elevation={4} className={classes.paper}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader size={'small'} style={{ tableLayout: 'auto', width: '100%' }}>

                    <CargoDataTableHeader columns={props.columns} />

                    <CargoDataTableBody
                        cargoDataUnit={props.cargoDataUnit}
                        checkUnpackableParcels={props.checkUnpackableParcels}
                        columns={props.columns}
                        data={props.data}
                        disabled={props.disabled}
                        onRowDelete={props.onRowDelete}
                        setData={props.setData}
                        unpackable={props.unpackable}
                        excelDropzone={props.excelDropzone}
                    />

                </Table>
            </TableContainer>

            <Divider />

            <CargoDataTableParcelInput
                cargoDataUnit={props.cargoDataUnit}
                checkUnpackableParcels={props.checkUnpackableParcels}
                disabled={props.disabled}
                isLoading={props.loadingData}
                parcels={props.data}
            />

            {props.loadingData &&
                <div className={classes.loadingContainer} >
                    <CircularProgress />
                </div>
            }
        </Paper>
    )
}


//////////////////////////////////////////////////////////////////////////////


const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
        height: 'calc(100% - 8px)'
    },
    tableContainer: {
        height: '100%',
        overflow: "auto",
        margin: 0,
        padding: 0,

        // Custom styling for the table scrollbar
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.6em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: "2px",
            border: "1px solid white"
        }
    },
    loadingContainer: {
        position: "absolute",
        top: 0,
        height: "100%",
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: 10,
    }
})


export default withStyles(styles)(CargoDataTable)
