import React from 'react'

import PasswordChange from './PasswordChange'
import RequestReset from './RequestReset'

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { showNotification, showNotificationDialog } from '../app/AppActions'
import { users } from '../api'
import { notificationDialogSeverity } from '../core/Constants'

//////////////////////////////////////////////////////////////////////////////

const PasswordReset = (props) => {
    const query = props.location.query || {};
    const id = query.id || ''
    const token = query.token || ''

    const { onPasswordChange } = props

    let content;
    if (id === '' || token === '') {
        content = <RequestReset />
    } else {
        content = <PasswordChange id={id} token={token} onPasswordChange={onPasswordChange} />
    }

    return content;
}


//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        onPasswordChange: (id, token, newPassword, passwordConfirmation) => {
            if (newPassword.length === 0) {
                dispatch(showNotificationDialog('New password is required', 'Invalid password', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            if (passwordConfirmation.length === 0) {
                dispatch(showNotificationDialog('Password confirmation is required', 'Confirmation required', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            if (newPassword !== passwordConfirmation) {
                dispatch(showNotificationDialog('New password and password confirmation do not match', 'Password and confirmation invalid', notificationDialogSeverity.warning))
                return Promise.resolve(null)
            }
            return users.resetPassword(id, token, newPassword)
                .then(() => {
                    dispatch(showNotification('Password changed'))
                    setTimeout(() => browserHistory.push('/signin'), 50)
                })
                .catch(err => {
                    dispatch(showNotificationDialog(err.message, 'Error', notificationDialogSeverity.error))
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)

//////////////////////////////////////////////////////////////////////////////
