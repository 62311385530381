import React, { useState, useEffect, useRef } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import DialogTitle from '../core/DialogTitle';
import GeneralDataTable from '../core/GeneralDataTable/GeneralDataTable';
import Octagon from '../core/Octagon';
import OrganizationFilterMenu from '../core/OrganizationFilterMenu';
import Toolbar from '../core/Toolbar';
import { momentDateFormat } from '../core/dateUtils';
import { colors } from '../theme';
import { notificationDialogSeverity } from '../core/Constants';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';


import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import AssignmentIcon from '@material-ui/icons/Assignment';
import EventIcon from '@material-ui/icons/Event';

import { loggerService, organizations } from '../api';
import { showNotification, showNotificationDialog } from '../app/AppActions';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { cloneDeep } from 'lodash';

//////////////////////////////////////////////////////////////////////////////

const SuperadminLogsOptimizationTasks = (props) => {
    const { reduxDispatch, classes } = props;


    // Has component mounted yet? This is used when fetching logs to make sure that we don't make an unnecessary fetch at the initial render
    const [mounted, setMounted] = useState(false)

    // State for optimization task logs
    const [optimizationTasks, setOptimizationTasks] = useState([]);

    // State-object for organization filter
    const [orgFilterState, setOrgFilterState] = useState({
        allOrganizations: [],
        filterListOpen: false,
        filterList: []
    });

    // State-object for table pagination
    const [paginationState, setPaginationState] = useState({
        rowsPerPage: 25,
        page: 0
    });

    // State-object for table column sorting
    const [columnSortingOptions, setColumnSortingOptions] = useState({
        direction: 'desc',
        column: 'createdAt'
    })

    // Anchor for the organization filter menu
    const anchorRef = useRef(null);

    // State-object for datefilter dialog
    const [dateFilterState, setDateFilterState] = useState({
        open: false, // Dialog open/close
        from: moment.utc().day(moment.utc().day() - 7), // Filter from-time
        to: moment.utc() // Filter to-time
    })


    /**
     * Fetch all organizations
     */
    useEffect(() => {
        organizations.query({})
            .then(res => {
                setOrgFilterState(s => ({ ...s, allOrganizations: res.data }))
                setMounted(true);
            })
            .catch(err => {
                reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error)
            })
    }, [reduxDispatch])


    /**
     * If organization-filter, or date-filter are changed, reset pagination to page 0
     */
    useEffect(() => {
        setPaginationState(s => ({ ...s, page: 0 }))
    }, [orgFilterState.filterList, dateFilterState.to, dateFilterState.from])


    /**
     * Fetch optimization task logs
     */
    useEffect(() => {
        if (mounted) {
            const order = columnSortingOptions.direction === 'desc' ? -1 : 0 ;
            const orderObj = {}
            orderObj[columnSortingOptions.column] = order;

            loggerService.findOptimizationTaskLogs({
                $sort: orderObj,
                $limit: paginationState.rowsPerPage,
                $skip: paginationState.rowsPerPage * paginationState.page,
                organization: { $in: orgFilterState.filterList.length > 0 ? orgFilterState.filterList : orgFilterState.allOrganizations },
                createdAt: { $gte: dateFilterState.from, $lte: dateFilterState.to }
            })
                .then(res => {
                    setOptimizationTasks(res);
                })
                .catch(err => {
                    reduxDispatch.showMessage(err.message, 'Error', notificationDialogSeverity.error)
                })
        }
    }, [
        mounted,
        paginationState.rowsPerPage,
        paginationState.page,
        reduxDispatch,
        columnSortingOptions.direction,
        columnSortingOptions.column,
        orgFilterState.allOrganizations,
        orgFilterState.filterList,
        dateFilterState.from,
        dateFilterState.to
    ])


    /**
     * Take the optimization task log, and copy the data-field to system clipboard.
     * More about copying to clipboard: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
     * @param {Object} rowData
     */
    const copyToClipboard = (rowData) => {
        navigator.clipboard.writeText(JSON.stringify(rowData.data, null, '  '))
            .then(() => reduxDispatch.showNotification('Data copied to clipboard'))
    }


    // Initialize table columns
    const columns = [
        { label: '', field: 'copyToClipboardButton' }, // Cannot sort by button-components
        { label: 'User', field: 'user.name' }, //Sorting would require aggregation involving subdocuments
        { label: 'Organization', field: 'organization.name' }, //Sorting would require aggregation involving subdocuments
        { label: 'Created', field: 'createdAt', conversion: date => moment(date).format('DD.MM.YYYY HH:mm:ss'), sortable: true }
    ];


    // Add a copy-to-clipboard -button to each row.
    let optimizationTasksCopy = cloneDeep(optimizationTasks);
    optimizationTasksCopy.data = optimizationTasksCopy.data?.map(task => {
        task['copyToClipboardButton'] = (
            <Tooltip title='Copy to clipboard'>
                <IconButton onClick={() => copyToClipboard(task)} size='small'>
                    <AssignmentIcon />
                </IconButton>
            </Tooltip>
        );
        return task;
    })


//////////////////////////////////////////////////////////////////////////////


    /**
     * Set up a date filter dialog
     */
    const dateFilterDialog = (
        <Dialog
            open={dateFilterState.open}
            onClose={() => setDateFilterState(s => ({ ...s, open: false }))}
        >
            <DialogTitle onClose={() => setDateFilterState(s => ({ ...s, open: false }))}>
                {`Filter logs by date`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Select date range:`}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingLeft: 20, paddingRight: 20 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        label={'From'}
                        format={momentDateFormat}
                        value={dateFilterState.from}
                        onChange={(newDate, _) => {
                            setDateFilterState(s => ({ ...s, from: newDate.endOf('day') }));
                        }}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        label={'To'}
                        format={momentDateFormat}
                        value={dateFilterState.to}
                        onChange={(newDate, _) => {
                            setDateFilterState(s => ({ ...s, to: newDate.endOf('day') }));
                        }}
                    />
                </MuiPickersUtilsProvider>
            </DialogActions>
            <DialogActions style={{ paddingRight: 10 }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setDateFilterState(s => ({ ...s, open: false }))}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )


    return (
        <Octagon w='80rem' color={colors.octagon}>
            {dateFilterDialog}
            <Toolbar title='Optimization task logs' onGoBack={() => browserHistory.push('/admin/logs')}>
                <Button
                    startIcon={<FilterListIcon />}
                    onClick={() => setOrgFilterState(s => ({ ...s, filterListOpen: true }))}
                    ref={anchorRef}
                    aria-controls={orgFilterState.filterListOpen ? 'menu-list' : undefined}
                    aria-haspopup='true'
                >
                    Organizations
                </Button>
                <OrganizationFilterMenu
                    open={orgFilterState.filterListOpen}
                    setOpen={(value) => setOrgFilterState(s => ({ ...s, filterListOpen: value }))}
                    anchorRef={anchorRef}
                    organizations={orgFilterState.allOrganizations}
                    setOrgFilterList={(value) => setOrgFilterState(s => ({ ...s, filterList: value }))}
                />
                <Button
                    startIcon={<EventIcon />}
                    onClick={() => setDateFilterState(s => ({ ...s, open: true }))}
                >
                    Date
                </Button>
            </Toolbar>


            <Paper className={classes.table} elevation={4}>
                <GeneralDataTable
                    columns={columns}
                    data={optimizationTasksCopy}
                    dense
                    columnSortingOptions={columnSortingOptions}
                    onChangeColumnSortingOptions={(newOptions) => setColumnSortingOptions(newOptions)}
                    paginationState={paginationState}
                    onChangePagination={(state) => setPaginationState(state)}
                />
            </Paper>

        </Octagon>
    )
}


//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => ({
    reduxState: {}
});



const mapDispatchToProps = (dispatch) => ({
    reduxDispatch: {
        showMessage: (message, title, severity) => {
            dispatch(showNotificationDialog(message, title, severity))
        },
        showNotification: (message) => {
            dispatch(showNotification(message));
        }
    }
})


const styles = (theme) => ({
    table: theme.containers.dataTable
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuperadminLogsOptimizationTasks))
