import moment from 'moment'

/**
 * Default moment format that resolves to 'dd.MM.yyyy'
 */
export const momentDateFormat = 'DD.MM.YYYY';

/**
 *
 * @param {Date || string} date
 * @returns date string in format 'dd.MM.yyyy'
 */
export const dateToFormattedString = date => (typeof date === 'string' ? new Date(date) : date).toLocaleDateString("fi-FI");



export const dateAndTimeToFormattedString = date => {
    const d = typeof date === 'string' ? new Date(date) : date;
    return moment(d).format('DD.MM.YYYY HH:mm:ss');
}
