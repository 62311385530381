import React, { useState, useEffect } from 'react'
import LoadingAssistantBoxCard from './LoadingAssistantBoxCard'
import { boxThreerImage } from '../three/BoxThreer'
import threeEntryPoint from '../three/threeEntryPoint'

import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'



export const boxCardWidth = 210.6;
export const boxCardStyle = {
    width: boxCardWidth,
    minWidth: `${boxCardWidth}px`,
    height: 150,
    marginTop: "-33px"
}

const LoadingAssistantBoxCards = (props) => {
    const { unit, nextPackableParcels, onBoxEdit, onSelectParcelToPack, previewParcel, setPreviewParcel, classes } = props;

    const [three, setThree] = useState(null)
    const [{ boxCards, update }, setState] = useState({ boxCards: {}, update: false })

    const selectedParcelIds = props.selectedParcels.map(sp => sp.id);


    useEffect(() => {
        // Create only single three component that is used for every box rendering
        let threer = threeEntryPoint({
            containerElement: null,
            preventResize: true,
            noBorders: false,
            style: boxCardStyle
        })
        setThree(threer)

        // Dispose the threer when component unmounts
        return () => {
            threer.dispose()
        }
        // Run only once on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Non-blocking rendering of box three images
    useEffect(() => {
        // No three created yet, wait for next render
        if (!three)
            return;

        // Find the first parcel that doesn't have image created
        const imageIds = Object.keys(boxCards);
        const nextParcel = nextPackableParcels.find(x => !imageIds.includes(x.id));

        if (!nextParcel)
            return;

        let timeout;
        timeout = setTimeout(() => {
            boxCards[nextParcel.id] = boxThreerImage(nextParcel, three)
            setState({ boxCards: boxCards, update: !update })
        }, 0)

        // This prevents the component to update an unmounted component
        return () => {
            clearTimeout(timeout)
        };
    }, [nextPackableParcels, boxCards, update, three])

    return (
        <div className={classes.boxCardContainer}>
            <List style={{ display: "flex", maxWidth: "100%" }} dense={true}>
                {nextPackableParcels.map(parcel =>
                    <ListItem
                        key={parcel.id}
                        disabled={selectedParcelIds.includes(parcel._id.toString()) || props.enableLmcLock}
                        classes={{ disabled: classes.disabledStyle }}
                    >
                        <LoadingAssistantBoxCard
                            key={parcel.id}
                            previewParcel={previewParcel}
                            setPreviewParcel={setPreviewParcel}
                            img={boxCards[parcel.id]}
                            parcel={parcel}
                            unit={unit}
                            onBoxEdit={onBoxEdit}
                            onSelectParcelToPack={onSelectParcelToPack}
                            parcelSelectedByOtherUser={selectedParcelIds.includes(parcel._id.toString())}
                            parcelAssignment={props.selectedParcels.find(p => p.id === parcel._id.toString())}
                        />
                    </ListItem>
                )}
            </List>
            <div style={{ display: props.enableLmcLock ? 'flex' : 'none' }} className={classes.lmcOverlay}>
                <Typography variant='h5'>
                    {`Loading plan is locked. Another user is making changes to this loading plan.`}
                </Typography>
                <Chip
                    color='secondary'
                    label={props.lmcUser?.name}
                />
            </div>
        </div>
    )
}


const styles = theme => ({
    boxCardContainer: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    disabledStyle: {
        '&.Mui-disabled': {
            opacity: 1
        }
    },
    lmcOverlay: {
        flexDirection: 'column',
        position: 'fixed',
        height: '285px',
        width: '100%',
        bottom: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(230, 238, 238, 0.8)',
        rowGap: '5px'
    }
})



export default withStyles(styles)(LoadingAssistantBoxCards)
